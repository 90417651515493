const APPLICATION_INFO_RECEIVED = 'APPLICATION/SET_APP_STORE';
const USER_COUNTRY_DETECTED = 'APPLICATION/USER_COUNTRY_DETECTED';
const COUNTRY_CODE_OVERRIDEN = 'APPLICATION/COUNTRY_CODE_OVERRIDEN';
const SET_BACKUP_MODE = 'APPLICATION/SET_BACKUP_MODE';

export const PLATFORM = {
  darwin: 'darwin',
  win32: 'win32',
};

export const applicationInfoReceived = (isAppStore, platform) => ({
  type: APPLICATION_INFO_RECEIVED,
  isAppStore,
  platform,
});

export const setBackupMode = isBackupMode => ({
  type: SET_BACKUP_MODE,
  isBackupMode,
});

export const userCountryDetected = userCountryCode => ({
  type: USER_COUNTRY_DETECTED,
  userCountryCode,
});

export const countryCodeOverriden = countryCodeOverride => ({
  type: COUNTRY_CODE_OVERRIDEN,
  countryCodeOverride,
});

const initialState = {
  isAppStore: false,
  platform: null,
  isBackupMode: false,
  userCountryCode: null,
  countryCodeOverride: null,
};

export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BACKUP_MODE: {
      return {
        ...state,
        isBackupMode: action.isBackupMode,
      };
    }
    case COUNTRY_CODE_OVERRIDEN: {
      return {
        ...state,
        countryCodeOverride: action.countryCodeOverride,
      };
    }
    case USER_COUNTRY_DETECTED: {
      return {
        ...state,
        userCountryCode: action.userCountryCode,
      };
    }
    case APPLICATION_INFO_RECEIVED:
      return {
        ...state,
        isAppStore: action.isAppStore,
        platform: action.platform,
      };
    default:
      return state;
  }
}
