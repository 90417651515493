import * as Sentry from '@sentry/react';
import { getBySpaceId } from 'DataLayer/LinkedItems/get';
import { getSpaceById } from 'store/Spaces/selectors';

import {
  loadLinkedItemsRequest,
  loadLinkedItemsSuccess,
  loadLinkedItemsError,
  linkedItemsLoaded,
} from './actions';
import { isFetching } from './selectors';

export const loadLinkedItems = spaceId => async (dispatch, getState) => {
  const state = getState();
  const channel = getSpaceById(state, spaceId);

  /* if this folder is already being requested, skip it! */
  if (isFetching(state, spaceId)) {
    return Promise.resolve();
  }

  dispatch(loadLinkedItemsRequest(spaceId, true));
  try {
    const { cachedLinkedItems, fetchedPromise } = await getBySpaceId(
      spaceId,
      undefined,
      channel.WorkspaceId
    );
    if (cachedLinkedItems) {
      dispatch(loadLinkedItemsSuccess(cachedLinkedItems, spaceId));
    }
    const networkLinkedItems = await fetchedPromise;
    dispatch(loadLinkedItemsSuccess(networkLinkedItems, spaceId));
    dispatch(linkedItemsLoaded(spaceId));
  } catch (error) {
    Sentry.captureException(error);
    dispatch(loadLinkedItemsError(spaceId, false));
  }
};

export default loadLinkedItems;
