import { sortAlphabetical } from 'DataLayer/Adapters/sort';
import { createSelector } from 'reselect';

export function getAdapterById(state, id) {
  return state.adapters.find(adapter => id === adapter.Id);
}

const getAdaptersInternal = state => state.adapters;

export const getAdapters = createSelector(
  [getAdaptersInternal],
  adapters => adapters
);

export const getCreatableAdapters = createSelector(
  [getAdaptersInternal],
  adapters => adapters.filter(adapter => adapter.CanCreate)
);

export const getCreatableAdaptersSortedPopular = createSelector(
  [getCreatableAdapters],
  adapters => adapters.sort(sortAlphabetical)
);
