import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { countryCodeOverriden } from 'store/Application';
import { withRouter } from 'react-router';
import { WORKSPACES } from 'pages';

function SimulateCountry({
  onCountryDetected,
  router: {
    push,
    params: { countryCode },
  },
}) {
  useEffect(() => {
    onCountryDetected(countryCode);
    push(WORKSPACES);
  }, [push, onCountryDetected, countryCode]);
  return null;
}

SimulateCountry.propTypes = {
  onCountryDetected: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
    params: PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(undefined, {
  onCountryDetected: countryCodeOverriden,
})(withRouter(SimulateCountry));
