import { createSelector } from 'reselect';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import { getWorkspacesObject } from 'store/Workspaces/selectors';
import workspaceType from '../../DataLayer/Workspaces/workspaceType';
import { STAR_TYPE } from '.';

const getStarred = state => state.starred;

const enhanceNotificationStar = (star, workspaces) => {
  const notificationEnhanced = {
    ...star.value,
  };
  if (notificationEnhanced.Workspace) {
    if (workspaces[notificationEnhanced.Workspace.WorkspaceId]) {
      notificationEnhanced.WorkspaceName =
        workspaces[notificationEnhanced.Workspace.WorkspaceId].Name;
    }
  }
  return {
    ...star,
    value: notificationEnhanced,
  };
};

const enhanceFileStar = (star, workspaces) => {
  const starEnhanced = {
    ...star.value,
  };

  const workspaceId = get(star.value, 'Workspace.WorkspaceId');
  if (workspaces[workspaceId]) {
    starEnhanced.WorkspaceName = workspaces[workspaceId].Name;
  }
  starEnhanced.ChannelId = get(star.value, 'Workspace.Id');
  return {
    ...star,
    value: starEnhanced,
  };
};

export const getStarredMessages = createSelector(
  [getStarred, getWorkspacesObject],
  (starred, workspaces) => {
    const starredOredered = orderBy(
      Object.values(starred),
      ['value.StarredTimestamp'],
      ['desc']
    );
    return starredOredered.map(star => {
      if (star.StarType === STAR_TYPE.NOTIFICATION) {
        return enhanceNotificationStar(star, workspaces);
      } else {
        return enhanceFileStar(star, workspaces);
      }
    });
  }
);

export const getStarredByWorkspaceId = createSelector(
  [getStarredMessages, (_, workspaceId) => workspaceId],
  (starred, workspaceId) =>
    starred.filter(
      star =>
        get(star, 'value.Workspace.WorkspaceId') === workspaceId ||
        star.value.WorkspaceId === workspaceId
    )
);

export const getStarredForPrivateChat = createSelector(
  [getStarredMessages],
  starred =>
    starred.filter(star => {
      const workspaceId = get(star, 'value.Workspace.WorkspaceId');
      return (
        workspaceId === null || workspaceId === workspaceType.CONVERSATIONS
      );
    })
);
