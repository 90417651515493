/**
 * Created by user on 02/03/2017.
 */
function single(task) {
  const newTask = Object.assign({}, task);
  /*
  if (newTask.Type === 'encrypt-in-cloud' || newTask.Type === 'decrypt-in-cloud' || newTask.Type === 'upload-file' ||
      newTask.Type === 'sync' || newTask.Type === 'deep-sync' || newTask.Type === 'disc-qouta') {
    newTask.Type = 'hide';
  }
*/
  return newTask;
}

function all(tasks) {
  if (!tasks) {
    return [];
  }
  return tasks.map(task => single(task));
}

export default {
  all,
  single,
};
