/**
 * Created by user on 02/03/2017.
 */

// import lodashArray from 'lodash/array';
import { LOAD_TRANSFERS_SUCCESS } from './loadTransfers';
import {
  CHANGE_TRANSFER_STATUS,
  REMOVE_TRANSFER,
  ADD_NEW_TASK,
} from './actions';

function changeTaskStatus(state, id, status) {
  const transfer = Object.assign({}, state[id]); // clone our object
  transfer.task.Status = status; // update our object
  return Object.assign({}, state, { [id]: transfer });
}

function createTaskObject(task, resourceIds) {
  if (resourceIds) {
    return {
      task,
      resourceIds,
    };
  }
  return {
    task,
  };
}

function addTask(state, newTask, resourceIds) {
  return Object.assign({}, state, {
    [newTask.Id]: {
      ...state[newTask.Id],
      ...createTaskObject(newTask, resourceIds),
    },
  });
}

function removeTask(state, id) {
  const transfers = Object.assign({}, state);
  delete transfers[id];
  return transfers;
}

function transfersReducer(state = {}, action) {
  switch (action.type) {
    case CHANGE_TRANSFER_STATUS:
      return changeTaskStatus(state, action.id, action.status);
    // case CHANGE_TRANSFER_PROGRESS: return changeTaskProgress(state, action.id, action.task);
    case ADD_NEW_TASK:
      return addTask(state, action.newTask, action.resourceIds);
    case REMOVE_TRANSFER:
      return removeTask(state, action.id);
    case LOAD_TRANSFERS_SUCCESS: {
      return action.transfers.reduce((object, value) => {
        object[value.Id] = createTaskObject(value);
        return object;
      }, {});
    }
    default:
      return state;
  }
}

export default transfersReducer;
