import axios from 'axios';
import fields from './fields';
import process from './process';
import {
  getShareToGroupBySpaceIdUrl,
  getShareToGroupSearchUrl,
  getShareToGroupByIdUrl,
} from '../endpoints';

import {
  saveOne,
  getByChannelId as getFromDb,
  saveMany as saveToDb,
} from 'DataLayer/database/linkedItems';
import { isFeatureEnabled } from 'config/features';
import { FEATURES } from 'config/features.config';

export async function getById(spaceId, linkedItemId) {
  const { data } = await axios.get(
    getShareToGroupByIdUrl(spaceId, linkedItemId, fields)
  );
  const linkedItemFinal = process.single(data);
  saveOne(linkedItemFinal);
  return linkedItemFinal;
}

export async function getFromCache(channelId, workspaceId) {
  if (isFeatureEnabled(FEATURES.CLIENT_CACHING, workspaceId)) {
    return await getFromDb(channelId);
  }
  return Promise.resolve(undefined);
}

export async function getFromNetwork(channelId, next) {
  const { data } = await axios.get(
    getShareToGroupBySpaceIdUrl(channelId, next, fields)
  );
  const itemsFinal = process.all(data.Items);
  saveToDb(itemsFinal, channelId);
  return itemsFinal;
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function getBySpaceId(channelId, next, workspaceId) {
  const cachedLinkedItems = await getFromCache(channelId, workspaceId);
  const fetchedPromise = getFromNetwork(channelId, next);
  return { cachedLinkedItems, fetchedPromise };
}

export function searchLinkedItems(
  workspaceId,
  query,
  channelIds,
  memberEmails,
  startDate,
  endDate
) {
  return axios
    .get(
      getShareToGroupSearchUrl(
        workspaceId,
        query,
        channelIds,
        memberEmails,
        startDate,
        endDate
      )
    )
    .then(response => process.all(response.data.Items));
}
