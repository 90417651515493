import { FormattedMessage } from 'react-intl';

import React, { useMemo, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import { intlShape, injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';

import loadConversations from 'store/Conversations/loadConversations';
import { loadConversationStats } from 'store/Notifications/loadStats';
import SectionHeader from 'Components/Header/SectionHeader';
import ScrollPositionTracker from 'Components/ScrollPositionTracker/ScrollPositionTracker';
import ElementOutOfViewBanner from 'Components/ScrollPositionTracker/ElementOutOfViewBanner';
import SectionTitle from 'Components/Elements/Titles/SectionTitle';
import SpaceSorter from 'Components/Sidebar/SpaceTree/SpaceSorter';
import SpaceNode from 'Components/Sidebar/SpaceTree/SpaceNode';
import SidebarWrapper from 'Components/Layouts/SidebarLayout/SidebarWrapper';
import PrivateChatInviteField from './PrivateChatInviteField';

import { getActivePrivateChats } from 'store/Spaces/selectors';
import { PRIVATECHAT_NOTIFICATIONS } from 'pages';
import { SPACES_LIST_TYPE } from 'store/UI';
import {
  getPrivateSpaceSortType,
  getPrivateArchivedSpaceSortType,
} from 'store/UI/selectors';
import { getJoinedMeetingRoomId } from 'store/Meeting/selectors';
import { SortableContainer } from 'react-sortable-hoc';
import workspaceType from '../../../DataLayer/Workspaces/workspaceType';

function PrivateChatSidebar({
  dispatch,
  spaces,
  sort,
  archivedSort,
  meetingChannelId,
}) {
  useEffect(() => {
    dispatch(loadConversations());
    dispatch(loadConversationStats());
  }, [dispatch]);

  const conversations = useMemo(() => {
    const teamSpaces = spaces.filter(space => !space.Filtered);
    return orderBy(
      teamSpaces,
      ['ProvisionalSpace', sort.column],
      ['desc', sort.direction]
    );
  }, [spaces, sort]);

  const archivedSpacesOrdered = useMemo(() => {
    const archivedSpaces = spaces.filter(space => space.Filtered);
    return orderBy(archivedSpaces, archivedSort.column, archivedSort.direction);
  }, [spaces, archivedSort]);

  return (
    <>
      <SectionHeader hideBorder>
        <SidebarWrapper.SidebarTitle>
          <FormattedMessage id="PrivateChatSidebar.private-chat" />
        </SidebarWrapper.SidebarTitle>
        <Link to={PRIVATECHAT_NOTIFICATIONS} className="icon-link">
          <Icon name="setting" size="large" />
        </Link>
      </SectionHeader>
      <div className="px-3">
        <PrivateChatInviteField />
      </div>
      <div className="flex flex-auto position-relative min-height-0">
        <ScrollPositionTracker
          className="sidebar-scroller"
          renderBanner={position => {
            return (
              <ElementOutOfViewBanner position={position}>
                <FormattedMessage id="PrivateChatSidebar.new-messages" />
              </ElementOutOfViewBanner>
            );
          }}
        >
          <div className="pb-4">
            <div className="title">
              <div className="flex">
                <SectionTitle>
                  <FormattedMessage id="PrivateChatSidebar.conversations" />
                </SectionTitle>
              </div>
              <SpaceSorter
                listType={SPACES_LIST_TYPE.private}
                workspaceId={workspaceType.CONVERSATIONS}
                selectedItem={sort}
                showCustomOption={false}
              />
            </div>
            {conversations.map(space => (
              <SpaceNode
                key={space.Id}
                space={space}
                isMeetingInProgress={meetingChannelId === space.Id}
              />
            ))}
          </div>
          {archivedSpacesOrdered.length > 0 && (
            <React.Fragment>
              <div className="title">
                <div className="flex">
                  <SectionTitle>
                    <FormattedMessage id="PrivateChatSidebar.archived" />
                  </SectionTitle>
                </div>
                <SpaceSorter
                  listType={SPACES_LIST_TYPE.privateArchived}
                  workspaceId={workspaceType.CONVERSATIONS}
                  selectedItem={archivedSort}
                  showCustomOption={false}
                />
              </div>
              {archivedSpacesOrdered.map(space => (
                <SpaceNode
                  key={space.Id}
                  space={space}
                  isMeetingInProgress={meetingChannelId === space.Id}
                />
              ))}
            </React.Fragment>
          )}
        </ScrollPositionTracker>
      </div>
    </>
  );
}

PrivateChatSidebar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  spaces: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  sort: PropTypes.string.isRequired,
  archivedSort: PropTypes.string.isRequired,
  meetingChannelId: PropTypes.string,
};

export default withRouter(
  connect((state, props) => {
    return {
      spaces: getActivePrivateChats(state),
      sort: getPrivateSpaceSortType(state),
      archivedSort: getPrivateArchivedSpaceSortType(state),
      meetingChannelId: getJoinedMeetingRoomId(state),
    };
  })(injectIntl(SortableContainer(PrivateChatSidebar)))
);
