export const isInIframe = () => {
  return window.self !== window.top;
};

export const navigateIframeParent = url => {
  window.top.location.href = url;
};

export const open = url => {
  window.open(url);
};

export const navigate = url => {
  window.location = url;
};

export const getLanguageFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('lang');
};
