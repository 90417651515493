import React, { useEffect } from 'react';
import { Popup, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StarredMessagesList from './StarredMessagesList';

import { getStarredMessages } from 'store/Starred/selectors';
import loadStarred from 'store/Starred/loadStarred';

import { FormattedMessage } from 'react-intl';
import FullScreenPopup from 'Components/Layouts/SidebarLayout/FullScreenPopup';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { StarIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

const AllStarredMessagesButton = ({ dispatch, stars }) => {
  useEffect(() => {
    dispatch(loadStarred());
  }, [dispatch]);

  const onButtonClick = event => {
    dispatch(loadStarred());
  };
  const isBackupMode = useIsBackupMode();

  return (
    <Popup
      basic
      disabled={isBackupMode}
      flowing
      on="click"
      position="bottom right"
      onOpen={onButtonClick}
      trigger={
        <span>
          <Popup
            on="hover"
            inverted
            disabled={isBackupMode}
            size="mini"
            position="bottom right"
            trigger={
              <span className="position-relative">
                <DisableInBackupMode>
                  <WeTeamIcon Icon={StarIcon} />
                </DisableInBackupMode>

                {stars.length > 0 && (
                  <Label
                    color="blue"
                    floating
                    circular
                    size="mini"
                    style={{
                      top: -18,
                    }}
                  >
                    {stars.length}
                  </Label>
                )}
              </span>
            }
          >
            <FormattedMessage id="AllStarredMessagesButton.all-starred" />
          </Popup>
        </span>
      }
      style={{
        paddingRight: 0,
      }}
    >
      <FullScreenPopup
        header={<FormattedMessage id="AllStarredMessagesButton.all-starred" />}
      >
        <StarredMessagesList
          stars={stars}
          showWorkspaceName
          showAvatar={false}
        />
      </FullScreenPopup>
    </Popup>
  );
};
AllStarredMessagesButton.propTypes = {
  stars: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  stars: getStarredMessages(state),
}))(AllStarredMessagesButton);
