import React from 'react';
import PropTypes from 'prop-types';

import { STAR_TYPE } from 'store/Starred';
import StarredNotification from './StarredNotification';
import StarredResource from './StarredResource';
import StarredLinkedItem from './StarredLinkedItem';
import { FormattedMessage } from 'react-intl';

import EmptyImage from './Favoriten.png';

function StarredMessagesList({ stars, showWorkspaceName, showAvatar }) {
  if (!stars.length) {
    return (
      <div className="flex column center justify-content-space-between">
        <img height={300} alt="reminder illustration" src={EmptyImage} />
        <p>
          <strong>
            <FormattedMessage id="StarredMessagesList.EmptyTitle" />
          </strong>
        </p>
        <p>
          <FormattedMessage id="StarredMessagesList.EmptyMessage" />
        </p>
      </div>
    );
  }

  return (
    <>
      {stars.map(star => (
        <React.Fragment key={star.value.Id}>
          {star.StarType === STAR_TYPE.NOTIFICATION && (
            <StarredNotification
              notification={star.value}
              showWorkspaceName={showWorkspaceName}
              showAvatar={showAvatar}
            />
          )}
          {star.StarType === STAR_TYPE.RESOURCES && (
            <StarredResource
              resource={star.value}
              showWorkspaceName={showWorkspaceName}
            />
          )}
          {star.StarType === STAR_TYPE.LINKEDITEMS && (
            <StarredLinkedItem
              linkedItem={star.value}
              showWorkspaceName={showWorkspaceName}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
}

StarredMessagesList.propTypes = {
  stars: PropTypes.array.isRequired,
  showWorkspaceName: PropTypes.bool,
  showAvatar: PropTypes.bool,
};

export default StarredMessagesList;
