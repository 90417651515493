import { createSelector } from 'reselect';
import { getJoinedWorkspaceMembersByWorkspaceId } from 'store/Workspaces/Members/selectors';
import { getWorkspaceById } from 'store/Workspaces/selectors';

export const shouldShowOffer = createSelector(
  [
    getWorkspaceById,
    getJoinedWorkspaceMembersByWorkspaceId,
    (_, workspaceId, memberLimit) => memberLimit,
  ],
  (workspace, members, memberLimit) => {
    // only show for owners
    if (!workspace.IsOwner) {
      return false;
    }

    // only show for workspaces with no subscription
    if (workspace.Subscription?.CurrentSubscription) {
      return false;
    }

    if (members.length >= memberLimit || members.length === 0) {
      return false;
    }

    return true;
  }
);
