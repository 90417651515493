import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import loadWorkspaces from 'store/Workspaces/loadWorkspaces';
import { enterBillingInformationReminderPostponed } from 'store/UI';
import { isBillingInformationReminderPostponed } from 'store/UI/selectors';
import {
  getPastDueWorkspace,
  getPaymentMethodExpiringWorkspace,
} from 'store/Workspaces/selectors';

import useAsyncFn from 'Hooks/useAsyncFn';

import BillingInformationModal from 'Components/Workspace/BillingInformationModal/BillingInformationModal';
import { Button } from 'semantic-ui-react';
import TerminateSubscriptionButton from 'Components/Workspace/BillingInformationModal/TerminateSubscriptionButton';
import { isDesktopApp } from 'config/config';
import InvoicePastDueDesktopApp from 'Components/Workspace/BillingInformationModal/InvoicePastDueDesktopApp';

function BillingInformationModalContainer() {
  const dispatch = useDispatch();

  const pastDueWorkspace = useSelector(getPastDueWorkspace);
  const paymentMethodExpiringWorkspace = useSelector(
    getPaymentMethodExpiringWorkspace
  );

  const [state, onLoadWorkspaces] = useAsyncFn(() => {
    return dispatch(loadWorkspaces());
  });

  const onComplete = () => {
    onLoadWorkspaces();
  };

  const postponed = useSelector(state =>
    isBillingInformationReminderPostponed(
      state,
      paymentMethodExpiringWorkspace?.Id
    )
  );

  const onDismiss = useCallback(() => {
    dispatch(
      enterBillingInformationReminderPostponed(
        paymentMethodExpiringWorkspace?.Id
      )
    );
  }, [dispatch, paymentMethodExpiringWorkspace?.Id]);

  if (state.loading) {
    return null;
  }

  if (pastDueWorkspace) {
    if (isDesktopApp()) {
      return (
        <InvoicePastDueDesktopApp
          workspace={pastDueWorkspace}
          onComplete={onComplete}
        />
      );
    }

    return (
      <BillingInformationModal
        workspace={pastDueWorkspace}
        onComplete={onComplete}
        dismissButton={
          <TerminateSubscriptionButton
            workspaceId={pastDueWorkspace.Id}
            size="tiny"
            basic
          />
        }
        title={
          <FormattedMessage id="BillingInformationModalContainer.InvoicePastDueTitle" />
        }
        message={
          <FormattedMessage
            id="BillingInformationModalContainer.InvoicePastDueMessage"
            values={{
              teamspace: <strong>{pastDueWorkspace.Name}</strong>,
            }}
          />
        }
      />
    );
  }

  if (isDesktopApp()) {
    return null;
  }

  if (postponed) {
    return null;
  }

  if (paymentMethodExpiringWorkspace) {
    return (
      <BillingInformationModal
        workspace={paymentMethodExpiringWorkspace}
        onComplete={onComplete}
        dismissButton={
          <Button basic onClick={onDismiss} type="button">
            <FormattedMessage id="BillingInformationModal.MaybeLater" />
          </Button>
        }
        title={
          <FormattedMessage id="BillingInformationModalContainer.PaymentExpiringTitle" />
        }
        message={
          <FormattedMessage
            id="BillingInformationModalContainer.PaymentExpiringMessage"
            values={{
              teamspace: <strong>{paymentMethodExpiringWorkspace.Name}</strong>,
            }}
          />
        }
      />
    );
  }

  return null;
}

export default BillingInformationModalContainer;
