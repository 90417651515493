import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePreviewLink from './ResourcePreviewLink';
import UserMessage from 'Components/Chat/UserMessage';

const ResourceComment = ({ resource, comment, spaceId }) => (
  <span className="wrappable-text">
    <span className="light-text">
      <FormattedMessage
        id="SpaceChatItems.CommentedOnResource"
        values={{
          item: <ResourcePreviewLink resource={resource} spaceId={spaceId} />,
        }}
      />
    </span>
    <br />
    <blockquote>
      <UserMessage message={comment.Text} />
    </blockquote>
  </span>
);

ResourceComment.propTypes = {
  resource: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  spaceId: PropTypes.string.isRequired,
};

export default ResourceComment;
