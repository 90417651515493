import PropTypes from 'prop-types';
import React from 'react';

import FileIcon from 'Components/Icons/FileIcon';
import { DEFAULT_SIZE } from 'Components/Thumbnail/Thumbnail';

function ThumbnailIcon({ type, size }) {
  const fileIconSize = Math.max(size - 10, DEFAULT_SIZE);
  return <FileIcon size={fileIconSize} type={type} />;
}

ThumbnailIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default ThumbnailIcon;
