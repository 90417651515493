import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

import { filterDisplayNotifications } from 'Constants/notificationType';

import { getSpacesInternal } from '../Spaces/selectors';
import { getWorkspacesObject } from '../Workspaces/selectors';

const getActivitiesObject = state => state.activities.activities;
export const activitiesLoading = state => state.activities.loading;
export const activitiesHasOlder = state => state.activities.hasOlderActivities;

export const getActivities = createSelector(
  [getActivitiesObject, getSpacesInternal, getWorkspacesObject],
  (activities, spaces, workspaces) => {
    const enhancedActivities = Object.values(activities)
      .filter(filterDisplayNotifications)
      .map(n => {
        const notificationEnhanced = {
          ...n,
        };
        if (n.Workspace) {
          if (spaces[n.Workspace.Id]) {
            notificationEnhanced.ChannelName = spaces[n.Workspace.Id].Name;
          }
          if (workspaces[n.Workspace.WorkspaceId]) {
            notificationEnhanced.WorkspaceName =
              workspaces[n.Workspace.WorkspaceId].Name;
          }
        }
        return notificationEnhanced;
      });
    return orderBy(enhancedActivities, 'NotificationTimestamp', 'desc');
  }
);
