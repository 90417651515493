import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import loadMembersByWorkspaceId from 'store/Workspaces/Members/loadMembersByWorkspaceId';
import { getPendingApprovalMembersByWorkspaceId } from 'store/Workspaces/Members/selectors';
import { getWorkspaceById } from 'store/Workspaces/selectors';
import { getSpaceById } from 'store/Spaces/selectors';

import PendingWorkspaceMember from './PendingWorkspaceMember';

class PendingWorkspaceMembers extends React.Component {
  componentDidMount() {
    const { dispatch, workspace } = this.props;
    if (workspace.Id) {
      dispatch(loadMembersByWorkspaceId(workspace.Id));
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, workspace } = this.props;
    if (
      this.props.workspace.Id &&
      prevProps.workspace.Id !== this.props.workspace.Id
    ) {
      dispatch(loadMembersByWorkspaceId(workspace.Id));
    }
  }

  render() {
    const { members, workspace } = this.props;

    if (workspace && !workspace.IsOwner) {
      return null;
    }

    return (
      <React.Fragment>
        {members.map(member => (
          <PendingWorkspaceMember
            key={member.Id}
            member={member}
            workspace={workspace}
          />
        ))}
      </React.Fragment>
    );
  }
}

PendingWorkspaceMembers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  workspace: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
};

export default withRouter(
  connect((state, props) => {
    const space = getSpaceById(state, props.params.spaceId);
    return {
      members: getPendingApprovalMembersByWorkspaceId(state, space.WorkspaceId),
      workspace: getWorkspaceById(state, space.WorkspaceId),
    };
  })(PendingWorkspaceMembers)
);
