import { SET_PATH } from './setPath';

function mergePaths(state, id, path) {
  return Object.assign({}, state, {
    [id]: path,
  });
}

const initialState = {
  paths: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PATH:
      return mergePaths(state, action.id, action.path);
    default:
      return state;
  }
}
