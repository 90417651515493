import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetSpaceById } from 'store/Spaces/selectors';
import { getWorkspaceById } from 'store/Workspaces/selectors';

/*
Attempt to get the workspace using either:
- the Workspace Id
- the Channel's Workspace Id
*/
export const useWorkspace = (workspaceId, channelId) => {
  const getSpaceById = useMemo(makeGetSpaceById, []);

  const channel = useSelector(state => {
    return getSpaceById(state, channelId);
  });

  const workspace = useSelector(state => {
    return getWorkspaceById(state, workspaceId || channel.WorkspaceId);
  });

  return workspace;
};
