import { FormattedMessage } from 'react-intl';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser } from 'store/User/selectors';
import {
  getRecurlyAccountUrl,
  getSupportEmailMailToLink,
  getSupportEmail,
} from 'config/config';
import logout from 'Helpers/Logout';
import { Button } from 'semantic-ui-react';

import 'PublicSharing/Password.scss';
import Logo from 'assets/images/logo/Horizontal/WeTeam-Logo-Horizontal.svg';

const LOCK_REASON = {
  Payment: {
    Message:
      "Your account is locked because we haven't received the payment for your subscription. Please update your billing information.",
    Button: 'Update Billing Information',
  },
  Coupon: {
    Message:
      'Your account is locked because you used a coupon then cancelled your Subscription. Please re-activate your Subscription.',
    Button: 'Re-activate your Subscription',
  },
};

const getMessage = reason => {
  const reasonInfo = LOCK_REASON[reason];
  if (reasonInfo) {
    return reasonInfo.Message;
  }
  return LOCK_REASON.Payment.Message;
};

const getButtonText = reason => {
  const reasonInfo = LOCK_REASON[reason];
  if (reasonInfo) {
    return reasonInfo.Button;
  }
  return LOCK_REASON.Payment.Button;
};

function Lockscreen({ user }) {
  const recurlyPageTokenLink = getRecurlyAccountUrl(user.PageToken);
  return (
    <div className="full-screen-app">
      <div className="full-screen-centered-container">
        <div className="ui segment flex column align-items-center">
          <a href="https://we.team" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="We.Team logo" width={200} />
          </a>
          <div className="link_password_text">
            <h2 className="warning-title">
              <FormattedMessage id="Lockscreen.your-account-is-locked" />
            </h2>
            <p>{getMessage(user.LockedReason)}</p>
          </div>
          <a
            className="ui button primary"
            href={recurlyPageTokenLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getButtonText(user.LockedReason)}
          </a>
          <p className="adapter_more_info">
            <FormattedMessage
              id="Lockscreen.if-you-have-any-questions-please-contact-us-here"
              values={{
                link: (
                  <a href={getSupportEmailMailToLink()}>{getSupportEmail()}</a>
                ),
              }}
            />
          </p>
          <Button
            basic
            compact
            size="small"
            onClick={() => {
              logout(window.location.href);
            }}
          >
            <FormattedMessage id="Lockscreen.logout" />
          </Button>
        </div>
      </div>
    </div>
  );
}

Lockscreen.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(state => ({
  user: getUser(state),
}))(Lockscreen);
