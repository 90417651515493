import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';

import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import styles from './Spring2024PromotionMilestonesButton.module.scss';
import { getActiveOffer } from 'DataLayer/Workspaces/Offers/get';
import { OFFERS } from 'DataLayer/Workspaces/Offers/config';
import { useDispatch } from 'react-redux';
import { showSpring2024MilestonesModal } from 'store/Modals/actions';

function Spring2024PromotionMilestonesButton({ workspaceId }) {
  const dispatch = useDispatch();

  const { data } = useQuery(['offers/active', workspaceId], () =>
    getActiveOffer(workspaceId)
  );

  if (!data?.some(offer => offer.offer === OFFERS.spring_2024)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        fluid
        primary
        onClick={() => {
          dispatch(showSpring2024MilestonesModal(workspaceId));
        }}
      >
        <FormattedMessage id="Spring2024PromotionMilestonesModal.Title" />
      </Button>
    </div>
  );
}

Spring2024PromotionMilestonesButton.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default Spring2024PromotionMilestonesButton;
