import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'semantic-ui-react';

import {
  showExportChannelModal,
  showLeavePrivateChatModal,
} from 'store/Modals/actions';

import archiveSpace from 'store/Spaces/archiveSpace';
import unarchiveSpace from 'store/Spaces/unarchiveSpace';

import './SingleSpaceOptions.scss';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { LogoutIcon } from 'Components/Icons/WeTeamIcon';
import { FolderIcon } from 'Components/Icons/WeTeamIcon';
import { DownloadIcon } from 'Components/Icons/WeTeamIcon';
import MarkAsUnreadOption from 'Components/Spaces/MarkAsUnreadOption';
import ManageTagsOption from 'Components/Spaces/ManageTagsOption';

export const SingleSpaceOptions = ({
  space,
  onShowLeaveModal,
  onShowExportChannelModal,
  onArchiveSpace,
  onUnarchiveSpace,
}) => {
  if (!space.Id || space.ProvisionalSpace) {
    return null;
  }
  const showLeave = space.ACL.Leave;
  const showArchive = !space.Filtered;
  return (
    <React.Fragment>
      <Menu.Item
        data-testid="export"
        onClick={event => {
          onShowExportChannelModal(space);
        }}
      >
        <WeTeamIcon Icon={DownloadIcon} link={false} />
        <FormattedMessage id="SingleSpaceOptions.Export" />
      </Menu.Item>
      {showArchive ? (
        <Menu.Item
          data-testid="archive"
          onClick={() => {
            onArchiveSpace(space);
          }}
        >
          <WeTeamIcon Icon={FolderIcon} link={false} />
          <FormattedMessage id="Single.SpaceOptions.Archive" />
        </Menu.Item>
      ) : (
        <Menu.Item
          data-testid="unarchive"
          onClick={() => {
            onUnarchiveSpace(space);
          }}
        >
          <WeTeamIcon Icon={FolderIcon} link={false} />
          <FormattedMessage id="SpaceOptionsSingle.Unarchive" />
        </Menu.Item>
      )}
      {showLeave && (
        <Menu.Item
          data-testid="Leave"
          onClick={event => {
            onShowLeaveModal(space);
          }}
        >
          <WeTeamIcon Icon={LogoutIcon} link={false} />
          <FormattedMessage id="SingleSpaceOptions.Leave" />
        </Menu.Item>
      )}
      <MarkAsUnreadOption
        channelId={space.Id}
        workspaceId={space.WorkspaceId}
      />
      <ManageTagsOption channelId={space.Id} />
    </React.Fragment>
  );
};

SingleSpaceOptions.propTypes = {
  space: PropTypes.object.isRequired,
  onShowLeaveModal: PropTypes.func.isRequired,
  onShowExportChannelModal: PropTypes.func.isRequired,
  onArchiveSpace: PropTypes.func.isRequired,
  onUnarchiveSpace: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onShowLeaveModal: showLeavePrivateChatModal,
  onShowExportChannelModal: showExportChannelModal,
  onArchiveSpace: archiveSpace,
  onUnarchiveSpace: unarchiveSpace,
})(SingleSpaceOptions);
