import { getById } from 'DataLayer/LinkedItems/get';
import { normalize } from 'normalizr';

import linkedItemSchema from './linkedItemSchema';
import { addLinkedItems } from './actions';

export const loadLinkedItem = (spaceId, linkedItemId) => async dispatch => {
  const linkedItem = await getById(spaceId, linkedItemId);

  const normalizedLinkedItems = normalize([linkedItem], [linkedItemSchema]);

  dispatch(
    addLinkedItems(
      normalizedLinkedItems.result,
      normalizedLinkedItems.entities.linkedItems,
      spaceId
    )
  );
};

export default loadLinkedItem;
