import { FormattedMessage } from 'react-intl';

import {
  MEETING_STATUS_ICON,
  useMeetingStatus,
} from 'Hooks/data/meeting/useMeetingStatus';
import { memo } from 'react';

export function MeetingIconStatus() {
  const status = useMeetingStatus();

  if (status === MEETING_STATUS_ICON.CAMERA) {
    return <FormattedMessage id="MeetingToolbar.MeetingStatus.Camera" />;
  } else if (status === MEETING_STATUS_ICON.SHARING_SCREEN) {
    return <FormattedMessage id="MeetingToolbar.MeetingStatus.Desktop" />;
  } else if (status === MEETING_STATUS_ICON.MICROPHONE) {
    return <FormattedMessage id="MeetingToolbar.MeetingStatus.Microphone" />;
  } else {
    return (
      <FormattedMessage id="MeetingToolbar.MeetingStatus.MicrophoneDisabled" />
    );
  }
}

export default memo(MeetingIconStatus);
