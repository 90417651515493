import axios from 'axios';
import fields from './fields';
import process from './process';
import { getShareToGroupPostUrl } from '../endpoints';
import { saveMany } from 'DataLayer/database/linkedItems';

export default function create(spaceId, resourceIdsArray) {
  const resourceIds = resourceIdsArray.join(',');
  return axios
    .post(getShareToGroupPostUrl(spaceId, fields), {
      resourceId: resourceIds,
    })
    .then(response => {
      if (response.data.Items) {
        return {
          hasTask: false,
          items: response.data.Items,
        };
      }
      return {
        hasTask: true,
        taskId: response.data.TaskId.replace(/['"]+/g, ''), //https://stackoverflow.com/a/19156197
      };
    })
    .then(data => {
      if (data.hasTask) {
        return data;
      }
      const finalItems = process.all(data.items);
      saveMany(finalItems, spaceId);
      return {
        ...data,
        items: finalItems,
      };
    });
}
