import {
  LOAD_PUBLIC_RESOURCE_SUCCESS,
  LOAD_PUBLIC_RESOURCE_FAILED,
} from './loadPublicResource';

import { SET_PUBLIC_LINK, SET_PUBLIC_LINK_PASSWORD } from './actions';

const initialState = {
  publicLinkId: null,
  publicPassword: null,
  publicResourceId: null,
  loaded: false,
};

export default function publicReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PUBLIC_RESOURCE_SUCCESS:
      return {
        ...state,
        publicResourceId: action.resource.Id,
        loaded: true,
      };
    case LOAD_PUBLIC_RESOURCE_FAILED:
      return {
        ...state,
        loaded: true,
      };
    case SET_PUBLIC_LINK:
      return {
        ...state,
        publicLinkId: action.id,
      };
    case SET_PUBLIC_LINK_PASSWORD:
      return {
        ...state,
        publicPassword: action.password,
      };
    default:
      return state;
  }
}
