import axios from 'axios';
import { TASK } from 'DataLayer/endpoints';
import process from './process';

export function getTransfers() {
  return axios
    .get(TASK)
    .then(response => response.data.Tasks)
    .then(tasks => process.all(tasks));
}

export function getTaskById(id) {
  return axios.get(`${TASK}?id=${id}`).then(response => response.data.Task);
}
