import axios from 'axios';
import { resourceFields } from './fields';
import process from './process';

export default function rename(id, name) {
  return axios
    .put(
      `/resources/${id}?name=${encodeURIComponent(
        name
      )}&fields=${resourceFields}`
    )
    .then(response => response.data.File || response.data.Folder)
    .then(resource => process.single(resource));
}

export function star(channelId, id, Starred) {
  return axios
    .put(
      `/resources/${id}?ChannelId=${channelId}&Starred=${Starred}&fields=${resourceFields}`
    )
    .then(response => response.data.File || response.data.Folder)
    .then(resource => process.single(resource));
}
