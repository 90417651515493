import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { isDesktopApp } from 'config/config';

import { OFFERS } from 'DataLayer/Workspaces/Offers/config';

import { showAddWorkspaceMemberModal } from 'store/Modals/actions';

import { useWorkspaceOfferAvailable } from 'Hooks/workspace/useWorkspaceOfferAvailable';

import InviteMemberPromotionModal from 'Components/WorkspaceOffer/InviteMemberPromotionModal/InviteMemberPromotionModal';
import ThreeMonthTeamspaceUpgradePromotionModal from 'Components/WorkspaceOffer/ThreeMonthTeamspaceUpgradePromotionModal/ThreeMonthTeamspaceUpgradePromotionModal';
import Spring2024PromotionModal from 'Components/WorkspaceOffer/Spring2024Trial/Spring2024PromotionModal';

function WorkspaceOffers({ workspaceId }) {
  const dispatch = useDispatch();
  const workspaceOffer = useWorkspaceOfferAvailable(workspaceId);

  if (isDesktopApp()) {
    return null;
  }

  if (workspaceOffer === OFFERS.spring_2024) {
    return <Spring2024PromotionModal workspaceId={workspaceId} />;
  } else if (workspaceOffer === OFFERS.free_trial_aug_2023) {
    return (
      <ThreeMonthTeamspaceUpgradePromotionModal workspaceId={workspaceId} />
    );
  } else if (workspaceOffer === OFFERS.trial_test) {
    return (
      <InviteMemberPromotionModal
        workspaceId={workspaceId}
        onOfferAccepted={() => {
          dispatch(showAddWorkspaceMemberModal(workspaceId));
        }}
      />
    );
  }
  return null;
}

WorkspaceOffers.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default WorkspaceOffers;
