import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ADAPTERS from 'Constants/adapters';

import { getRepository } from 'store/Repositories/selectors';
import { Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import styles from './LinkedItemLocation.module.css';

const LinkedItemLocation = ({ linkedItem, repository, router }) => {
  if (!repository.Id) {
    return null;
  }
  if (repository.AdapterId === ADAPTERS.OTIXO_DRIVE) {
    return null;
  }

  return (
    <Popup
      inverted
      size="mini"
      trigger={
        <img
          src={repository.adapterIcon}
          alt="cloud logo"
          className={styles.adapterIcon}
        />
      }
    >
      <FormattedMessage
        id="LinkedItemLocation.Tooltip"
        values={{
          name: repository.Name,
        }}
      />
    </Popup>
  );
};

LinkedItemLocation.propTypes = {
  linkedItem: PropTypes.object.isRequired,
  repository: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

LinkedItemLocation.defaultProps = {
  repository: {},
};

export default connect((state, props) => ({
  repository: getRepository(state, props.linkedItem.Resource.RepoId),
}))(withRouter(LinkedItemLocation));
