import { SET_SORT } from './sort';
import RESOURCES_SORT_COLUMNS from 'store/Resources/resourcesSortColumns';

export const LINKED_ITEM_SORT_COLUMNS = {
  NameLowerCase: 'NameLowerCase',
  OwnerName: 'OwnerName',
  Created: 'Created',
  Size: 'Resource.Size',
  Type: 'Resource.Type',
};

export const SCREENS = {
  REPOSITORIES: 'REPOSITORIES',
  SPACES: 'SPACES',
  CHATS: 'CHATS',
  RESOURCES: 'RESOURCES',
  LINKEDITEMS: 'LINKEDITEMS',
};

const initialState = {
  REPOSITORIES: {
    column: 'NameLowerCase',
    direction: 'asc',
  },
  SPACES: {
    column: 'LastUpdateTimestamp',
    direction: 'desc',
  },
  CHATS: {
    column: 'NameLowerCase',
    direction: 'asc',
  },
  RESOURCES: {
    column: RESOURCES_SORT_COLUMNS.NameLowerCase,
    direction: 'asc',
  },
  LINKEDITEMS: {
    column: LINKED_ITEM_SORT_COLUMNS.Created,
    direction: 'desc',
  },
};

function setSort(state, screen, direction, column) {
  return Object.assign({}, state, { [screen]: { column, direction } });
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SORT:
      return setSort(state, action.screen, action.direction, action.column);
    default:
      return state;
  }
}
