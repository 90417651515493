import axios from 'axios';
import {
  TRANSFER_SUBSCRIPTION,
  TRANSFER_SUBSCRIPTION_PRICE,
} from 'DataLayer/endpoints';

export function getTransferSubscriptions() {
  return axios.get(`${TRANSFER_SUBSCRIPTION}`).then(response => response.data);
}

export function getTransferPrice(credit, coupon = null) {
  return axios
    .get(`${TRANSFER_SUBSCRIPTION_PRICE}?credit=${credit}&coupon=${coupon}`)
    .then(response => response.data);
}
