export const initialState = {
  loading: false,
  error: null,
  success: false,
};

export const actionTypes = {
  REQUESTING: 'REQUESTING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  RESET: 'RESET',
};

export function requestReducer(state, action) {
  switch (action.type) {
    case actionTypes.REQUESTING: {
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    }
    case actionTypes.ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    case actionTypes.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
      };
    }
    case actionTypes.RESET: {
      return initialState;
    }
    default:
      return state;
  }
}
