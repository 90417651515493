import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Header,
  Modal,
  Message,
  Table,
  Segment,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { withRouter } from 'react-router';
import { OFFERS } from 'DataLayer/Workspaces/Offers/config';
import { isDesktopApp } from 'config/config';
import { useAcceptOffer } from 'Components/WorkspaceOffer/useAcceptOffer';
import { useDispatch } from 'react-redux';
import { showSpring2024PromitionAcceptedModal } from 'store/Modals/actions';
import usePrefersColorScheme from 'use-prefers-color-scheme';
import RecurlyPaymentFormContainer from 'Components/Recurly/RecurlyPaymentFormContainer';

function Spring2024PromotionModal({ workspaceId }) {
  const dispatch = useDispatch();
  const prefersColorScheme = usePrefersColorScheme();

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const { postpone, accept, error, loading, visible } = useAcceptOffer(
    workspaceId,
    OFFERS.spring_2024,
    () => {
      dispatch(showSpring2024PromitionAcceptedModal(workspaceId));
    }
  );

  const onComplete = async () => {
    await accept();
    dispatch(showSpring2024PromitionAcceptedModal(workspaceId));
  };

  return (
    <Modal size="small" open={visible}>
      <Header>
        <FormattedMessage id="Spring2024PromotionModal.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={postpone} />
        </div>
      </Header>
      <Modal.Content>
        {!showPaymentForm && (
          <>
            <p>
              <FormattedMessage id="Spring2024PromotionModal.Message.1" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="Spring2024PromotionModal.Points.Title" />
              </strong>
            </p>
            <ol>
              <li>
                <FormattedMessage id="Spring2024PromotionModal.Point.1" />
              </li>
              <li>
                <FormattedMessage id="Spring2024PromotionModal.Point.2" />
              </li>
            </ol>
            <p>
              {isDesktopApp() ? (
                <FormattedMessage id="Spring2024PromotionModal.Message.2" />
              ) : (
                <FormattedMessage id="Spring2024PromotionModal.Message.2" />
              )}
            </p>
            <Table inverted={prefersColorScheme === 'dark' ? true : false}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage id="Spring2024PromotionModal.Milestone.1" />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedMessage id="Spring2024PromotionModal.Milestone.1.Length" />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage id="Spring2024PromotionModal.Milestone.2" />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedMessage id="Spring2024PromotionModal.Milestone.2.Length" />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage id="Spring2024PromotionModal.Milestone.3" />
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedMessage id="Spring2024PromotionModal.Milestone.3.Length" />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {isDesktopApp() && (
              <Message>
                <FormattedMessage id="Spring2024PromotionModal.Desktop" />
              </Message>
            )}
          </>
        )}
        {showPaymentForm && (
          <Segment>
            <RecurlyPaymentFormContainer
              workspaceId={workspaceId}
              onComplete={onComplete}
              onError={setPaymentError}
              submitButtonText={
                <FormattedMessage id="Spring2024PromotionModal.Next" />
              }
            />
          </Segment>
        )}
        {error && (
          <Message error>
            <FormattedMessage id="Spring2024PromotionModal.Error" />
          </Message>
        )}
        {paymentError && (
          <Message error>
            <FormattedMessage id="Spring2024PromotionModal.PaymentError" />
          </Message>
        )}
      </Modal.Content>
      {!showPaymentForm && (
        <Modal.Actions>
          <Button basic onClick={postpone} disabled={loading}>
            <FormattedMessage id="Spring2024PromotionModal.MaybeLaterButtonText" />
          </Button>
          {!isDesktopApp() && (
            <Button
              primary
              onClick={() => {
                setShowPaymentForm(true);
              }}
              loading={loading}
              disabled={loading}
            >
              <FormattedMessage id="Spring2024PromotionModal.AcceptOfferButtonText" />
            </Button>
          )}
        </Modal.Actions>
      )}
    </Modal>
  );
}

Spring2024PromotionModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default withRouter(Spring2024PromotionModal);
