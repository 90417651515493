import { getActivities } from 'DataLayer/Activities/get';

export const ACTIVITIES_LOADED = 'ACTIVITIES_LOADED';
export const ACTIVITIES_REQUESTED = 'ACTIVITIES_REQUESTED';

export const activitiesLoaded = (activities, hasOlderActivities) => ({
  type: ACTIVITIES_LOADED,
  activities,
  hasOlderActivities,
});

export const activitiesRequested = () => ({
  type: ACTIVITIES_REQUESTED,
});

export const loadActivities = notificationId => async dispatch => {
  dispatch(activitiesRequested());
  const activities = await getActivities(notificationId);
  dispatch(
    activitiesLoaded(activities.Notifications, activities.HasOlderMessages)
  );
};

export const loadOldActivities = notificationId => dispatch =>
  dispatch(loadActivities(notificationId));

export const loadNewActivities = () => async dispatch => {
  dispatch(activitiesRequested());
  const activities = await getActivities();
  dispatch(
    activitiesLoaded(
      activities.Notifications.filter(n => !n.NotificationReadState),
      activities.HasOlderMessages
    )
  );
};
