export default function(firstName, lastName, email, fallbackName = '') {
  let initials;

  if (firstName) {
    initials = firstName.trim().substring(0, 1);
    if (lastName) {
      initials += lastName.trim().substring(0, 1);
    }
  } else if (email) {
    initials = email.trim().substring(0, 1);
  } else {
    initials = fallbackName.trim().substring(0, 1);
  }
  try {
    return initials.toUpperCase();
  } catch (error) {
    return '';
  }
}
