import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Segment, Message } from 'semantic-ui-react';

import styles from './Meeting.module.scss';

import { isMeetingJoined } from 'store/Meeting/selectors';

import MeetingsRestrictedMessage from 'Components/Meeting/MeetingsRestrictedMessage';
import MeetingToolbar from 'Components/Meeting/MeetingToolbar';
import ParticipantAvatars from 'Components/Meeting/ParticipantAvatars';
import JoinMeetingButton from 'Components/Meeting/JoinMeetingButton';
import CurrentMeetingWillEndMessage from 'Components/Meeting/CurrentMeetingWillEndMessage';
import MeetingUserAvatars from 'Components/Meeting/MeetingUserAvatars';

export function Meeting({ activeMeeting, isMeetingJoined }) {
  const showMeetingToolbar = isMeetingJoined;
  const showJoinButton = !showMeetingToolbar;
  return (
    <>
      <p>
        <FormattedMessage id="Meeting.Started" />
      </p>
      <div className={styles.meetingInvitation}>
        <Segment padded>
          <div className="mb-3">
            <MeetingUserAvatars meeting={activeMeeting} />
          </div>
          <div>
            <ParticipantAvatars meeting={activeMeeting} />
          </div>
          <CurrentMeetingWillEndMessage roomId={activeMeeting.roomId} />
          <MeetingsRestrictedMessage
            As={Message}
            meeting={activeMeeting}
            showUpgradeButton
          />
        </Segment>
        {showJoinButton && (
          <div className={styles.joinButton}>
            <JoinMeetingButton roomId={activeMeeting.roomId} />
          </div>
        )}
        {showMeetingToolbar && <MeetingToolbar />}
      </div>
    </>
  );
}

Meeting.propTypes = {
  activeMeeting: PropTypes.shape({
    roomId: PropTypes.string.isRequired,
    callerId: PropTypes.string.isRequired,
    participantUserIds: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    caller: PropTypes.object.isRequired,
    channelName: PropTypes.string.isRequired,
    workspaceName: PropTypes.string.isRequired,
  }).isRequired,
  isMeetingJoined: PropTypes.bool.isRequired,
};

export default connect((state, props) => ({
  isMeetingJoined: isMeetingJoined(state, props.activeMeeting.roomId),
}))(Meeting);
