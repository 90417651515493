import { call, put, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { get } from 'DataLayer/Me/get';
import { USER_GET_SUCCESS, userLoaded } from 'store/User/actions';

/*
  If the user is locked, check every 5 seconds to see if they have become unlocked
*/
function* checkUser(action) {
  if (!action.user.Locked) {
    return;
  }
  /* eslint-disable no-constant-condition */
  while (true) {
    /* eslint-enable no-constant-condition */
    yield delay(5000);

    const latestUser = yield call(get);

    if (!latestUser.Locked) {
      yield put(userLoaded(latestUser));
      return;
    }
  }
}

export default function* userUnlockSaga() {
  yield takeLatest(USER_GET_SUCCESS, checkUser);
}
