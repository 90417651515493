export function sortAlphabetical(a, b) {
  if (a.Caption.toLowerCase() < b.Caption.toLowerCase()) {
    return -1;
  } else if (a.Caption.toLowerCase() > b.Caption.toLowerCase()) {
    return 1;
  }

  return 0;
}

export function sortStandard(a, b) {
  if (a.Popularity < b.Popularity) {
    return -1;
  } else if (a.Popularity > b.Popularity) {
    return 1;
  }

  if (a.Caption < b.Caption) {
    return -1;
  } else if (a.Caption > b.Caption) {
    return 1;
  }

  return 0;
}
