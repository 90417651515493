import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import COLLABORATOR_TYPE from 'Constants/collaboratorType';
import WORKSPACE_MEMBER_STATUS from 'DataLayer/Constants/workspaceMemberStatus';

import { NUMBER_OF_GUESTS_PER_WORK_SPACE_MEMBER } from 'config/config';

import { getWorkspacesObject } from '../selectors';
import { getUserId } from '../../User/selectors';

const getWorkspaceMembersObject = state => state.workspaces.members;

const getWorkspaceMembers = createSelector(
  [getWorkspaceMembersObject, getWorkspacesObject],
  (members, workspaces) =>
    Object.values(members).map(member => {
      const workspace = workspaces[member.WorkgroupId];
      if (!workspace) return {};
      const isOwner =
        workspaces[member.WorkgroupId].OwnerMail === member.UserMail;

      let attachedUser = {};
      if (member.AttachedUser) {
        attachedUser = {
          ...member.AttachedUser,
          FullName: `${member.AttachedUser.firstName} ${member.AttachedUser.lastName}`,
        };
      }
      return {
        ...member,
        IsOwner: isOwner,
        DisplayName: attachedUser.FullName || member.UserMail,
        AttachedUser: attachedUser,
      };
    })
);

export const getWorkspaceMemberById = createSelector(
  [getWorkspaceMembers, (_, id) => id],
  (members, id) => Object.values(members).find(member => member.Id === id)
);

export const getWorkspaceMembersByWorkspaceId = createSelector(
  [getWorkspaceMembers, (_, workspaceId) => workspaceId],
  (members, workspaceId) => {
    const filteredMembers = Object.values(members).filter(
      member => member.WorkgroupId === workspaceId
    );
    return orderBy(filteredMembers, ['AttachedUser.FullName']);
  }
);

export const getJoinedWorkspaceMembersByWorkspaceId = createSelector(
  [getWorkspaceMembersByWorkspaceId],
  members =>
    members.filter(member => member.Status === WORKSPACE_MEMBER_STATUS.JOINED)
);

export const getJoinedWorkspaceMembersWithoutOwnerByWorkspaceId =
  createSelector([getWorkspaceMembersByWorkspaceId], members =>
    Object.values(members)
      .filter(member => member.Status === WORKSPACE_MEMBER_STATUS.JOINED)
      .filter(member => member.Type === COLLABORATOR_TYPE.REGULAR)
      .filter(member => !member.IsOwner)
  );

const getMembersForSubscription = createSelector(
  [getWorkspaceMembersByWorkspaceId],
  members =>
    members
      .filter(
        member =>
          member.Type === COLLABORATOR_TYPE.REGULAR ||
          member.Type === COLLABORATOR_TYPE.ADMIN
      )
      .filter(member => member.Status === WORKSPACE_MEMBER_STATUS.JOINED)
);

const getGuestsByWorkSpaceId = createSelector(
  [getWorkspaceMembersByWorkspaceId],
  members =>
    members
      .filter(member => member.Type === COLLABORATOR_TYPE.SINGLE)
      .filter(member => member.Status === WORKSPACE_MEMBER_STATUS.JOINED)
);

export const getNumberOfMembersForSubscription = createSelector(
  [getMembersForSubscription],
  members => members.length
);

export const getNumberOfGuestSlotsByWorkspaceId = createSelector(
  [getNumberOfMembersForSubscription, getGuestsByWorkSpaceId],
  (numberOfMembers, guests) => {
    const currentGuestSlots =
      numberOfMembers * NUMBER_OF_GUESTS_PER_WORK_SPACE_MEMBER;
    const guestSlotsIfRemovingMember =
      (numberOfMembers - 1) * NUMBER_OF_GUESTS_PER_WORK_SPACE_MEMBER;
    return {
      currentGuestSlots,
      guestSlotsIfRemovingMember,
      numberOfExcessGuestsIfRemovingMember:
        guests.length - guestSlotsIfRemovingMember,
    };
  }
);

export const getPendingApprovalMembersByWorkspaceId = createSelector(
  [getWorkspaceMembersByWorkspaceId],
  members =>
    members.filter(
      member => member.Status === WORKSPACE_MEMBER_STATUS.PENDING_APPROVAL
    )
);

export const getNumberOfPendingMembersByWorkspaceId = createSelector(
  [getWorkspaceMembersByWorkspaceId],
  members =>
    members.filter(
      member =>
        member.Status === WORKSPACE_MEMBER_STATUS.PENDING_APPROVAL ||
        member.Status === WORKSPACE_MEMBER_STATUS.INVITED
    ).length
);

export const getWorkspaceMembersForDropdown = createSelector(
  [getWorkspaceMembers, getUserId],
  (members, userId) =>
    orderBy(uniqBy(members, 'UserMail'), 'UserMail').filter(
      c => c.UserMail !== userId
    )
);
