import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import NavButton from './Components/NavButton';

import styles from './AppAreaButton.module.scss';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

function AppAreaButton({ onClick, icon, name, tooltip, sidebarOpen }) {
  return (
    <Popup
      position="right center"
      inverted
      disabled={!sidebarOpen}
      trigger={
        <DisableInBackupMode>
          <NavButton
            noBackgroundOnHover
            onClick={onClick}
            icon={
              <Popup
                position="right center"
                inverted
                disabled={sidebarOpen}
                trigger={<span className={styles.icon}>{icon}</span>}
                content={tooltip}
                size="mini"
              />
            }
          >
            {name}
          </NavButton>
        </DisableInBackupMode>
      }
      content={tooltip}
      size="mini"
    />
  );
}

AppAreaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
};

export default AppAreaButton;
