import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import logout from 'Helpers/Logout';
import { isCypressTestRun } from 'config/config';

const HTTP_STATUS = {
  UNAUTHORIZED: 401,
};

class ResponseInterceptor extends Component {
  constructor(props) {
    super(props);
    this.interceptorId = null;
  }

  componentDidMount() {
    const { redirectToLoginOnUnauthorized } = this.props;
    // Intercept all AJAX errors.
    // Attempt to extract the error message from the response.
    this.interceptorId = axios.interceptors.response.use(undefined, error => {
      if (error.response) {
        try {
          if (
            error.response.status === HTTP_STATUS.UNAUTHORIZED &&
            redirectToLoginOnUnauthorized &&
            !isCypressTestRun()
          ) {
            logout(window.location.href);
          }
          return Promise.reject(error.response.data.error);
        } catch (e) {
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    });
  }

  componentWillUnmount() {
    axios.interceptors.response.eject(this.interceptorId);
  }

  render() {
    return null;
  }
}

ResponseInterceptor.propTypes = {
  redirectToLoginOnUnauthorized: PropTypes.bool,
};

ResponseInterceptor.defaultProps = {
  redirectToLoginOnUnauthorized: true,
};

export default ResponseInterceptor;
