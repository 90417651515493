import React from 'react';
import logout from 'Helpers/Logout';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { desktopAuthentication } from 'store/User/desktopAuthentication';

class ElectronAuthListeners extends React.PureComponent {
  componentDidMount() {
    window.ipcRenderer?.on('social-login', this.onSocialLogin);
    window.ipcRenderer?.on('logout-event', this.onLogout);
  }

  componentWillUnmount() {
    window.ipcRenderer?.removeListener('social-login', this.onSocialLogin);
    window.ipcRenderer?.removeListener('logout-event', this.onLogout);
  }

  onSocialLogin = (event, action, token) => {
    this.props.onDesktopAuthentication(action, token);
  };

  onLogout = event => {
    logout();
  };

  render() {
    return null;
  }
}

ElectronAuthListeners.propTypes = {
  onDesktopAuthentication: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onDesktopAuthentication: desktopAuthentication,
})(ElectronAuthListeners);
