import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Thumbnail from 'Components/Thumbnail/Thumbnail';
import LinkedItemLocation from 'Components/FileExplorer/LinkedItems/LinkedItemLocation';

function LinkedItemThumbnail({ linkedItem, size = 40 }) {
  return (
    <Thumbnail
      size={size}
      id={linkedItem.Resource.Id}
      type={linkedItem.Resource.Type}
      corner={
        linkedItem.IsOwner &&
        linkedItem.Resource.RepoId && (
          <LinkedItemLocation linkedItem={linkedItem} imageOnly />
        )
      }
    />
  );
}

LinkedItemThumbnail.propTypes = {
  linkedItem: PropTypes.object.isRequired,
  size: PropTypes.number,
};

export default memo(LinkedItemThumbnail);
