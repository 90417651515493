import { Label } from 'semantic-ui-react';
import classNames from 'classnames/bind';

import styles from './Tag.module.css';

const getTagStyles = (color = '#d5d9dc') => {
  return {
    border: `3px solid ${color}`,
  };
};

const Tag = ({ children, color, className, ...props }) => {
  return (
    <Label
      size="small"
      style={getTagStyles(color)}
      className={classNames(styles.tag, className)}
      {...props}
    >
      {children}
    </Label>
  );
};

export default Tag;
