import { ACTIVITIES_LOADED, ACTIVITIES_REQUESTED } from './actions';

const initialState = {
  loading: false,
  hasOlderActivities: false,
  activities: {},
};

const activitiesLoaded = (state, activities, hasOlderActivities) => {
  const activitiesById = activities.reduce((obj, value) => {
    obj[value.Id] = value;
    return obj;
  }, {});

  return {
    loading: false,
    hasOlderActivities,
    activities: {
      ...state.activities,
      ...activitiesById,
    },
  };
};

export default function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVITIES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ACTIVITIES_LOADED:
      return activitiesLoaded(
        state,
        action.activities,
        action.hasOlderActivities
      );
    default:
      return state;
  }
}
