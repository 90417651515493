import { getReminders } from 'DataLayer/Reminders/get';
import { remindersLoaded } from './index';

export const loadReminders = () => dispatch => {
  return getReminders().then(reminders => {
    dispatch(remindersLoaded(reminders));
  });
};

export default loadReminders;
