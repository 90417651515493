import useGetProtectedFile from 'Hooks/files/useGetProtectedFile';
import { useEffect, useState } from 'react';

export default function useGetProtectedFileBlobUrl(
  url,
  options = { asBlob: true, cacheEnabled: false }
) {
  const { data, loading, fetchFile, error } = useGetProtectedFile(url, {
    asBlob: options.asBlob,
    cacheEnabled: options.cacheEnabled,
  });
  const [blobUrl, setBlobUrl] = useState(null);

  useEffect(() => {
    if (data) {
      setBlobUrl(URL.createObjectURL(data));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [blobUrl]);

  return { blobUrl, loading, fetchFile, error };
}
