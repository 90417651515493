import { push } from 'react-router-redux';
import { leave } from 'DataLayer/Spaces/update';
import { getOtixoDownloadUrl } from 'config/config';
import { setSpaceRequestInProgress } from '../Requests/setRequestInProgress';
import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';
import * as spaceActions from './actions';
import { addDownload } from '../Download/addDownload';
import { PRIVATECHAT } from 'pages';

const leavePrivateChat = (space, exportHistory) => dispatch => {
  dispatch(setSpaceRequestInProgress(space.Id, true));
  return leave(space.Id, exportHistory).then(
    exportResourceId => {
      if (exportHistory && exportResourceId) {
        dispatch(addDownload(getOtixoDownloadUrl(exportResourceId)));
      }
      dispatch(spaceActions.removeSpace(space.Id));
      dispatch(
        displaySuccessToast('toast.leavePrivateChat.success', {
          name: space.Name,
        })
      );
      dispatch(setSpaceRequestInProgress(space.Id, false));
      if (window.location.href.includes(space.Id)) {
        dispatch(push(PRIVATECHAT));
      }
      return space;
    },
    error => {
      dispatch(displayErrorToast('toast.leavePrivateChat.error'));
      dispatch(setSpaceRequestInProgress(space.Id, false));
      throw error;
    }
  );
};

export default leavePrivateChat;
