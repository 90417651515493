import PropTypes from 'prop-types';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import min from 'lodash/min';
import max from 'lodash/max';

import './ScrollPositionTracker.scss';

export const ScrollPositionContext = React.createContext();

export const BANNER_POSITION = {
  top: 'top',
  bottom: 'bottom',
};

class ScrollPositionTracker extends React.PureComponent {
  state = {
    positions: {},
  };

  constructor(props) {
    super(props);
    this.scrollableContainerRef = React.createRef();
    this.elementRefs = {};
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      2500
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    /**
     * Positions are only set when scrolling occurs.
     * Because the list can also change when a user filters
     * via a text field, we should trigger an update
     * to the positions manually. This means when the filter is applied
     * and then removed, there will be no banners visible until the user scrolls again
     */
    if (prevProps.disableBanner !== this.props.disableBanner) {
      this.setState({ positions: {} });
    }
  }

  onPositionChange = (position, id, ref) => {
    this.setState({
      positions: {
        ...this.state.positions,
        [id]: {
          ...this.state.positions[id],
          id,
          position,
          offsetTop: ref.current._ref.offsetTop,
        },
      },
    });
  };

  onBannerClick = position => {
    const offsets = Object.values(this.state.positions)
      .filter(position =>
        (position.position === position) === BANNER_POSITION.top
          ? Waypoint.above
          : Waypoint.below
      )
      .map(p => p.offsetTop);
    const offset =
      position === BANNER_POSITION.top ? min(offsets) : max(offsets);
    this.scrollableContainerRef.current.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  };

  render() {
    const hasBadgeAbove =
      Object.values(this.state.positions).filter(
        position => position.position === Waypoint.above
      ).length > 0;
    const hasBadgeBelow =
      Object.values(this.state.positions).filter(
        position => position.position === Waypoint.below
      ).length > 0;

    const { children, renderBanner, renderBottomBanner, ...rest } = this.props;
    return (
      <ScrollPositionContext.Provider
        value={{
          onPositionChange: this.onPositionChange,
          onSetRef: this.onSetRef,
        }}
      >
        <div
          onClick={() => {
            this.onBannerClick(BANNER_POSITION.top);
          }}
          className={`scroll-position-tracker-banner top ${
            hasBadgeAbove ? 'visible' : ''
          }`}
        >
          {renderBanner(BANNER_POSITION.top)}
        </div>
        <div ref={this.scrollableContainerRef} {...rest}>
          {this.props.children}
        </div>
        <div
          onClick={() => {
            this.onBannerClick(BANNER_POSITION.bottom);
          }}
          className={`scroll-position-tracker-banner bottom ${
            hasBadgeBelow ? 'visible' : ''
          }`}
        >
          {renderBanner(BANNER_POSITION.bottom)}
        </div>
      </ScrollPositionContext.Provider>
    );
  }
}

ScrollPositionTracker.propTypes = {
  children: PropTypes.node.isRequired,
  renderBanner: PropTypes.func.isRequired,
  disableBanner: PropTypes.bool,
};

ScrollPositionTracker.defaultProps = {
  renderBanner: () => {},
};

export default ScrollPositionTracker;
