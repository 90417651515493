import { makeGetUnreadNotificationsCountBySpaceId } from 'store/Notifications/selectors';
import { getSpacesOrderedByName } from 'store/Spaces/selectors';
import { channelMarkedAsRead } from 'store/UI/channelMarkedAsRead';
import { makeGetIsChannelMarkedAsUnread } from 'store/UI/selectors';

export const markWorkspaceAsRead =
  workspaceId => async (dispatch, getState) => {
    const state = getState();
    const channels = getSpacesOrderedByName(state, workspaceId);
    const channelIds = channels.map(channel => channel.Id);

    channelIds.forEach(async channelId => {
      const isChannelMarkedAsUnread = makeGetIsChannelMarkedAsUnread()(
        state,
        channelId
      );

      const unreadNotificationsCount =
        makeGetUnreadNotificationsCountBySpaceId()(state, channelId);

      if (isChannelMarkedAsUnread || unreadNotificationsCount) {
        dispatch(channelMarkedAsRead(channelId, workspaceId));
      }
    });
  };
