import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isWebApp } from 'config/config';

import {
  getAppsPromotionBannerHidden,
  getDesktopAppPromotionBannerHidden,
  getBackupPromotionBannerHidden,
} from 'store/UI/selectors';
import {
  appsPromotionBannerHidden,
  desktopAppPromotionBannerHidden,
  backupPromotionBannerHidden,
} from 'store/UI';
import loadMembersByWorkspaceId from 'store/Workspaces/Members/loadMembersByWorkspaceId';

import useNewVersionAvailable from 'Components/NewVersionAvailable/useNewVersionAvailable';
import AppsPromotionBanner from 'Components/Banner/AppsPromotionBanner';
import NewVersionAvailableBanner from 'Components/NewVersionAvailable/NewVersionAvailableBanner';

import DesktopAppPromotionBanner from 'Components/DesktopAppPromotionBanner/DesktopAppPromotionBanner';
import BackupModeBanner from 'Components/Banner/BackupModeBanner/BackupModeBanner';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';
import BackupPromotionBanner from 'Components/BackupPromotionBanner/BackupPromotionBanner';

export function BannerManager({
  isAppsPromotionBannerVisible,
  onClosePromotionBanner,
  isDesktopAppPromotionBannerVisible,
  onCloseDesktopAppPromotionBanner,
  isBackupPromotionBannerVisible,
  onCloseBackupPromotionBanner,
  onLoadMembers,
  params: { spaceId, workspaceId },
}) {
  const isNewVersionAvailable = useNewVersionAvailable();

  const isWebAppResult = isWebApp();

  const backupModeActive = useIsBackupMode();

  if (backupModeActive) {
    return <BackupModeBanner />;
  }

  if (isWebAppResult && isNewVersionAvailable) {
    return <NewVersionAvailableBanner />;
  }

  if (isWebAppResult && isDesktopAppPromotionBannerVisible) {
    return (
      <DesktopAppPromotionBanner onClose={onCloseDesktopAppPromotionBanner} />
    );
  }

  if (isWebAppResult && isBackupPromotionBannerVisible) {
    return <BackupPromotionBanner onClose={onCloseBackupPromotionBanner} />;
  }

  if (isWebAppResult && isAppsPromotionBannerVisible) {
    return <AppsPromotionBanner onClose={onClosePromotionBanner} />;
  }
  return null;
}

BannerManager.propTypes = {
  isAppsPromotionBannerVisible: PropTypes.bool.isRequired,
  onClosePromotionBanner: PropTypes.func.isRequired,
  isDesktopAppPromotionBannerVisible: PropTypes.bool.isRequired,
  onCloseDesktopAppPromotionBanner: PropTypes.func.isRequired,
  isBackupPromotionBannerVisible: PropTypes.bool.isRequired,
  onCloseBackupPromotionBanner: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state, props) => ({
      isAppsPromotionBannerVisible: !getAppsPromotionBannerHidden(state),
      isDesktopAppPromotionBannerVisible:
        !getDesktopAppPromotionBannerHidden(state),
      isBackupPromotionBannerVisible: !getBackupPromotionBannerHidden(state),
    }),
    {
      onClosePromotionBanner: appsPromotionBannerHidden,
      onCloseDesktopAppPromotionBanner: desktopAppPromotionBannerHidden,
      onCloseBackupPromotionBanner: backupPromotionBannerHidden,
      onLoadMembers: loadMembersByWorkspaceId,
    }
  )(BannerManager)
);
