export const LOAD_APP = 'APP/LOAD_APP';

export function finish() {
  return {
    type: LOAD_APP,
  };
}

const initialState = {
  loaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_APP:
      return Object.assign({}, state, { loaded: true });
    default:
      return state;
  }
}
