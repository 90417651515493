import PropTypes from 'prop-types';
import React from 'react';
import { Popup, Menu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import SpaceOptions from '../../Spaces/SpaceOptions';
import SingleSpaceOptions from '../../Spaces/SingleSpaceOptions';

import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import ContextMenu from 'Components/ContextMenu/ContextMenu';

import styles from './ChannelOptions.module.scss';

function ChannelOptions({ space, trigger }) {
  const renderMenuOptions = () => {
    if (space.IsChat) {
      return <SingleSpaceOptions space={space} />;
    } else if (space.IsConversation) {
      return <SingleSpaceOptions space={space} />;
    }
    return <SpaceOptions space={space} />;
  };

  return (
    <ContextMenu
      trigger={
        <div className="flex center text-ellipsis cursor-pointer">
          <Popup
            position="bottom center"
            inverted
            trigger={
              <DisableInBackupMode>
                <>{trigger}</>
              </DisableInBackupMode>
            }
            content={<FormattedMessage id="WorkspaceTitle.Settings" />}
            size="mini"
          />
        </div>
      }
    >
      <div>
        <Menu borderless vertical fluid className={styles.menuItems}>
          {renderMenuOptions()}
        </Menu>
      </div>
    </ContextMenu>
  );
}

ChannelOptions.propTypes = {
  space: PropTypes.object.isRequired,
};

export default ChannelOptions;
