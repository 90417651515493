import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedTime, FormattedMessage } from 'react-intl';

import { Segment, Message } from 'semantic-ui-react';
import ReminderDate from './ReminderDate';
import removeReminder from 'store/Reminders/removeReminder';
import { showSaveReminderModal } from 'store/Modals/actions';
import ReminderTarget from './ReminderTarget';

import styles from './Reminder.module.scss';
import useAsyncFn from 'Hooks/useAsyncFn';
import SnoozeReminderButton from './SnoozeReminderButton';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { EditIcon } from 'Components/Icons/WeTeamIcon';
import { DeleteIcon } from 'Components/Icons/WeTeamIcon';

function Reminder({
  reminder,
  onDelete,
  onEdit,
  showWorkspaceName,
  showAvatar,
  onShowSnoozeReminderModal,
}) {
  const [state, onDeleteClick] = useAsyncFn(async () => {
    await onDelete(reminder);
  }, [reminder.Id]);

  const edit = async () => {
    onEdit(reminder.Id);
  };

  return (
    <>
      {state.error && (
        <Message error>
          <FormattedMessage id="Reminder.Delete.Error" />
        </Message>
      )}
      <Segment.Group className={styles.reminder}>
        <Segment
          className={reminder.Active ? styles.future : styles.completed}
          loading={state.loading}
        >
          <div>
            <div>
              <strong>
                <ReminderDate date={reminder.Time} />
                {/* eslint-disable react/jsx-no-literals */}
                &nbsp;@&nbsp;
                {/* eslint-enable react/jsx-no-literals */}
                <FormattedTime
                  value={reminder.Time}
                  updateInterval={false}
                  minute="2-digit"
                  hour="2-digit"
                />
              </strong>
            </div>
            {reminder.Note && (
              <div className={styles.note}>
                <FormattedMessage id="Reminder.note" />
                {reminder.Note}
              </div>
            )}
            <div className={styles.actions}>
              <WeTeamIcon
                color={reminder.Active ? 'white' : undefined}
                onClick={onDeleteClick}
                Icon={DeleteIcon}
                className="mr-2"
              />
              <WeTeamIcon
                color={reminder.Active ? 'white' : undefined}
                onClick={edit}
                Icon={EditIcon}
              />
            </div>
          </div>
        </Segment>
        <ReminderTarget
          canClick={reminder.TargetPresent}
          target={reminder.Target}
          channelId={reminder.ChannelId}
          reminderType={reminder.TargetType}
          showAvatar={showAvatar}
          present={reminder.TargetPresent}
        />
        {!reminder.Active && (
          <Segment>
            <SnoozeReminderButton id={reminder.Id} />
          </Segment>
        )}
      </Segment.Group>
    </>
  );
}

Reminder.propTypes = {
  reminder: PropTypes.object.isRequired,
  showWorkspaceName: PropTypes.bool,
  showAvatar: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onDelete: removeReminder,
  onEdit: showSaveReminderModal,
})(Reminder);
