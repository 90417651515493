import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import MeetingInvitation from './MeetingInvitation';
import { getActiveMeetingInvitations } from 'store/Meeting/selectors';
import { Portal } from 'semantic-ui-react';

import styles from './MeetingInvitations.module.scss';
import { MEETING_STATUS } from 'store/Meeting';
import LazyWrapper from 'Components/Loading/LazyWrapper';

const MeetingInvitationAudio = React.lazy(() =>
  import(
    /* webpackChunkName: "MeetingInvitationAudio" */ 'Components/Modals/Meeting/MeetingInvitations/MeetingInvitationAudio'
  )
);

export const MeetingInvitations = ({ activeMeetings }) => {
  const isAudioPlaying =
    activeMeetings.filter(meeting => meeting.state === MEETING_STATUS.INVITED)
      .length > 0;
  return (
    <Portal open>
      <div className={styles.meetingInvitations}>
        <LazyWrapper>
          {isAudioPlaying && <MeetingInvitationAudio />}
        </LazyWrapper>
        {activeMeetings.map(activeMeeting => (
          <MeetingInvitation
            key={activeMeeting.roomId}
            activeMeeting={activeMeeting}
          />
        ))}
      </div>
    </Portal>
  );
};

MeetingInvitations.propTypes = {
  activeMeetings: PropTypes.array.isRequired,
};

export default connect(state => ({
  activeMeetings: getActiveMeetingInvitations(state),
}))(MeetingInvitations);
