import axios from 'axios';
import { SHARE } from 'DataLayer/endpoints';
import process from './process';

export default function(id) {
  return axios
    .post(`${SHARE}?ResourceId=${id}`)
    .then(response => response.data.Share)
    .then(share => process(share));
}
