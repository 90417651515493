import { post } from 'DataLayer/Meeting/Users/post';
import { activeMeetingUpdated } from 'store/Meeting';

export const sendInvitation = (meetingId, user) => async dispatch => {
  try {
    const meeting = await post(meetingId, user);
    dispatch(activeMeetingUpdated(meeting));
  } catch (ex) {
    throw ex;
  }
};
