import deleteLink from 'DataLayer/Share/delete';

import { hideRemoveResourceLink } from 'store/Modals/actions';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

import { updateResourceLinked } from '../Entities/actions';
import { removeSharedResource } from '../Resources/removeResource';

export const REMOVE_LINK_SUCCESS = 'LINK/REMOVE_SUCCESS';

export const removeLink = id => dispatch => {
  deleteLink(id).then(
    () => {
      dispatch(hideRemoveResourceLink());
      dispatch(updateResourceLinked(id, false));
      // TODO: think of a more reactive way of doing this
      dispatch(removeSharedResource(id));
      dispatch({
        type: REMOVE_LINK_SUCCESS,
        id,
      });
      dispatch(displaySuccessToast('toast.removeLink.success'));
    },
    () => {
      dispatch(displayErrorToast('toast.removeLink.error'));
    }
  );
};
