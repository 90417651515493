import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getWorkspaceSubscriptionTerminateUrl } from 'DataLayer/endpoints';

export function terminateSubscription(workspaceId, subscriptionId) {
  return axios
    .put(
      getWorkspaceSubscriptionTerminateUrl(workspaceId, subscriptionId),
      {},
      {
        baseURL: getOtixoBackendApiUrl(),
      }
    )
    .then(({ data }) => data);
}
