import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Popup } from 'semantic-ui-react';
import {
  DRIVES,
  getCreateWorkspaceUrl,
  isInPrivateChat,
  WORKSPACES,
} from 'pages';

import { updateUIProductArea, PRODUCT_AREA } from 'store/User/updateUser';

import './AppNavigation.scss';

import WorkSpaceList from './Components/WorkSpaceList';
import AppNavigationToggle from './AppNavigationToggle';
import { FormattedMessage } from 'react-intl';
import { PRIVATECHAT } from '../../pages';

import PrivateChatButton from './PrivateChatButton';
import {
  getAppNavitationMenuOpen,
  getWorkspacesSidebarOpen,
} from 'store/UI/selectors';
import { appNavitationMenuChanged, workspacesSidebarChanged } from 'store/UI';
import withWindowSize from 'Components/Utilities/withWindowSize';
import SCREEN_SIZE from 'Constants/screenSize';
import AppAreaButton from './AppAreaButton';
import { isYourContactsEnabled } from 'config/features';
import WorkspaceSorter from 'Components/AppNavigation/Components/WorkspaceSorter';
import WeTeamIcon, { ArrowDown2Icon } from 'Components/Icons/WeTeamIcon';
import { PlusIcon } from 'Components/Icons/WeTeamIcon';
import { FolderIcon } from 'Components/Icons/WeTeamIcon';
import MeetingRoomButton from 'Components/AppNavigation/MeetingRoomButton';
import AppsButtons from 'Components/AppNavigation/AppsButtons';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import styles from './AppNavigation.module.css';
import classNames from 'classnames/bind';
class AppNavigation extends React.Component {
  onWorkSpaceSelected = () => {
    this.props.onAreaChanged(PRODUCT_AREA.Collaboration);
    this.onAutoClose();
  };

  onFileManagerClick = () => {
    this.props.router.push(DRIVES);
    this.props.onAreaChanged(PRODUCT_AREA.Clouds);
    this.onAutoClose();
  };

  onWorkspacesClick = () => {
    this.props.router.push(WORKSPACES);
    this.props.onAreaChanged(PRODUCT_AREA.Collaboration);
    this.onAutoClose();
  };

  onPrivateChatClick = () => {
    this.props.router.push(PRIVATECHAT);
    this.props.onAreaChanged(PRODUCT_AREA.PrivateChat);
    this.onAutoClose();
  };

  onAddTeamspace = () => {
    this.props.router.push(getCreateWorkspaceUrl(this.props.location.pathname));
    this.onAutoClose();
  };

  onToggle = () => {
    this.props.onSidebarChanged(!this.props.sidebarOpen);
  };

  onToggleMenu = () => {
    this.props.onMenuChanged(!this.props.menuOpen);
  };

  onAutoClose = () => {
    if (this.props.windowWidth < SCREEN_SIZE.largeMonitor) {
      this.onClose();
    }
  };
  onClose = () => {
    this.props.onSidebarChanged(false);
  };

  render() {
    const { sidebarOpen, menuOpen } = this.props;

    const showPrivateChatButton =
      !isInPrivateChat(this.props.location.pathname) && isYourContactsEnabled();
    return (
      <div className="flex">
        <div
          className={sidebarOpen ? 'nav-bar open' : 'nav-bar'}
          data-test="nav-bar"
        >
          <div className="nav-bar-content">
            <div className="nav-header">
              <AppNavigationToggle open={sidebarOpen} onClick={this.onToggle} />
              <div className="flex flex-auto space-between center pr-2">
                <div className="flex center">
                  <FormattedMessage id="AppNavigation.work-spaces" />
                  <Popup
                    position="right center"
                    inverted
                    trigger={
                      <DisableInBackupMode>
                        <WeTeamIcon
                          Icon={PlusIcon}
                          className="add-teamspace-plus"
                          color="white"
                          onClick={this.onAddTeamspace}
                        />
                      </DisableInBackupMode>
                    }
                    content={
                      <FormattedMessage id="CreateWorkspace.create-a-workspace" />
                    }
                    size="mini"
                  />
                </div>
                <WorkspaceSorter />
              </div>
            </div>
            <WorkSpaceList
              activeSpaceId={this.props.params.spaceId}
              activeWorkspaceId={this.props.params.workspaceId}
              onWorkSpaceSelected={this.onWorkSpaceSelected}
              open={sidebarOpen}
            />
            <div
              className={classNames(styles.bottomButtonContainer, {
                [styles.bottomButtonContainerClosed]: !sidebarOpen,
              })}
            >
              {menuOpen && (
                <>
                  {showPrivateChatButton && (
                    <AppAreaButton
                      onClick={this.onPrivateChatClick}
                      icon={
                        <PrivateChatButton
                          spaceId={this.props.params.spaceId}
                        />
                      }
                      name={
                        <FormattedMessage id="PrivateChatSidebar.private-chat" />
                      }
                      sidebarOpen={sidebarOpen}
                    />
                  )}
                  <MeetingRoomButton sidebarOpen={sidebarOpen} />
                  <AppAreaButton
                    onClick={this.onFileManagerClick}
                    icon={
                      <WeTeamIcon
                        Icon={FolderIcon}
                        color="white"
                        link={false}
                      />
                    }
                    name={<FormattedMessage id="AppNavigation.YourFiles" />}
                    tooltip={
                      <FormattedMessage id="AppNavigation.YourFiles.Tooltip" />
                    }
                    sidebarOpen={sidebarOpen}
                  />
                  <AppsButtons sidebarOpen={sidebarOpen} />
                </>
              )}
              <div
                className={classNames(styles.drawer, {
                  [styles.drawerCollapsed]: !sidebarOpen,
                })}
                onClick={this.onToggleMenu}
              >
                <WeTeamIcon
                  color="white"
                  Icon={ArrowDown2Icon}
                  className={classNames(styles.collapseIcon, {
                    [styles.collapsed]: !menuOpen,
                  })}
                />
                {sidebarOpen && <FormattedMessage id="AppNavigation.Tools" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AppNavigation.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  onSidebarChanged: PropTypes.func.isRequired,
  onAreaChanged: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  params: PropTypes.shape({
    spaceId: PropTypes.string,
    workspaceId: PropTypes.string,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default connect(
  state => ({
    sidebarOpen: getWorkspacesSidebarOpen(state),
    menuOpen: getAppNavitationMenuOpen(state),
  }),
  {
    onMenuChanged: appNavitationMenuChanged,
    onSidebarChanged: workspacesSidebarChanged,
    onAreaChanged: updateUIProductArea,
  }
)(withRouter(withWindowSize(AppNavigation)));
