export const RESOURCES_ADD = 'RESOURCES_ADD';
export const RESOURCES_SET = 'RESOURCES_SET';
export const LOAD_RESOURCES_SUCCESS = 'RESOURCES/LOAD_SUCCESS';
export const LOAD_RESOURCES_REQUEST = 'RESOURCES/LOAD_REQUEST';
export const LOAD_RESOURCES_ERROR = 'RESOURCES/LOAD_ERROR';

export const RESOURCES_TAG_FILTER_SELECTED = 'RESOURCES/TAG_FILTER_SELECTED';

export function addResources(parentId, resourceIds) {
  return {
    type: RESOURCES_ADD,
    parentId,
    resourceIds,
  };
}

export function setResources(parentId, resourceIds) {
  return {
    type: RESOURCES_SET,
    parentId,
    resourceIds,
  };
}

export function resourcesLoaded(parentId) {
  return {
    type: LOAD_RESOURCES_SUCCESS,
    parentId,
  };
}

export function loadResourcesError(parentId, error) {
  return {
    type: LOAD_RESOURCES_ERROR,
    parentId,
    error,
  };
}

export function loadResourcesRequest(parentId) {
  return {
    type: LOAD_RESOURCES_REQUEST,
    parentId,
  };
}

export function resourcesTagFilterSelected(spaceId, resourceIds) {
  return {
    type: RESOURCES_TAG_FILTER_SELECTED,
    spaceId,
    resourceIds,
  };
}
