/*
  If there are spaces available, redirect to the system space
  If there are no spaces available and they are loading, wait
  If spaces have loaded and there are none, go to drives

*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { getWorkspaceLink, CREARTE_WORKSPACE } from 'pages';
import { isLoading, getActiveWorkspaces } from 'store/Workspaces/selectors';
import { getLatestWorkspaceId } from 'store/UI/selectors';
import { getShowWelcomeScreen } from 'store/User/selectors';

class RedirectToWorkspace extends Component {
  componentDidMount() {
    this.goToWorkspace();
  }

  componentDidUpdate(/*prevProps*/) {
    this.goToWorkspace();
  }

  goToWorkspace() {
    // If there are spaces available, go to the system space
    if (this.props.workspaces.length) {
      this.goToLastUsedWorkspace(this.props.workspaces);
    } else if (!this.props.isFetchingWorkspaces) {
      this.props.router.replace(CREARTE_WORKSPACE);
    }
  }

  /*
    If the users last used workspace still exists, go there.
  */
  goToLastUsedWorkspace(workspaces) {
    if (workspaces.length > 0) {
      const latestWorkspace = workspaces.find(
        w => w.Id === this.props.latestWorkspaceId
      );
      if (latestWorkspace) {
        this.redirectToWorkspace(latestWorkspace);
      } else {
        this.redirectToWorkspace(workspaces[0]);
      }
    }
  }

  redirectToWorkspace(workspace) {
    this.props.router.replace(
      getWorkspaceLink(workspace.Id, this.props.router.location.query)
    );
  }

  render() {
    return <Loader active />;
  }
}
RedirectToWorkspace.propTypes = {
  workspaces: PropTypes.array.isRequired,
  isFetchingWorkspaces: PropTypes.bool.isRequired,
  latestWorkspaceId: PropTypes.string,
  showWelcomeScreen: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(state => ({
  workspaces: getActiveWorkspaces(state),
  isFetchingWorkspaces: isLoading(state),
  latestWorkspaceId: getLatestWorkspaceId(state),
  showWelcomeScreen: getShowWelcomeScreen(state),
}))(withRouter(RedirectToWorkspace));
