import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { getWorkspaceLink } from 'pages';
import ActionableNotification from '../ActionableNotification';
import { markAsSeen } from 'DataLayer/Workspaces/update';

class RecentlyAddedWorkspace extends React.Component {
  state = {
    open: true,
  };

  onGoToWorkspace = () => {
    const { workspace, router } = this.props;
    router.push(getWorkspaceLink(workspace.Id));
    this.close();
  };

  close = () => {
    const { workspace } = this.props;
    markAsSeen(workspace.Id);
    this.setState({
      open: false,
    });
  };

  render() {
    const { workspace, invitationMessage } = this.props;

    return (
      <ActionableNotification
        isOpen={this.state.open}
        loading={this.state.loading}
        onClose={this.close}
        title={
          <FormattedMessage
            id="RecentlyAddedWorkspace.Title"
            values={{
              name: <strong>{workspace.Name}</strong>,
            }}
          />
        }
        body={
          <>
            <FormattedMessage
              id={
                workspace.fullMember
                  ? 'RecentlyAddedWorkspace.Message.Member'
                  : 'RecentlyAddedWorkspace.Message.Guest'
              }
              values={{
                name: <strong>{workspace.Name}</strong>,
              }}
            />
            {invitationMessage && (
              <>
                <Divider hidden />
                <blockquote className="wrappable-text">
                  {invitationMessage}
                </blockquote>
              </>
            )}
            <Divider hidden />
            <div className="flex justify-content-space-between">
              <Button primary onClick={this.onGoToWorkspace}>
                <FormattedMessage id="RecentlyAddedWorkspace.GoToWorkspaceButtonText" />
              </Button>
            </div>
          </>
        }
      />
    );
  }
}

RecentlyAddedWorkspace.propTypes = {
  workspace: PropTypes.object.isRequired,
  invitationMessage: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect()(withRouter(RecentlyAddedWorkspace));
