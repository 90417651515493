import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup, MenuItem, Menu } from 'semantic-ui-react';

import WeTeamIcon from 'Components/Icons/WeTeamIcon';

import styles from './Sorter.module.css';
import { SwapIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

function Sorter({ items, whiteIcon }) {
  const [open, setOpen] = useState(false);

  const isBackupMode = useIsBackupMode();

  return (
    <Popup
      disabled={isBackupMode}
      flowing
      basic
      on="click"
      open={open}
      onClose={event => {
        event.stopPropagation();
        setOpen(false);
      }}
      onOpen={event => {
        event.stopPropagation();
        setOpen(true);
      }}
      onClick={event => {
        event.stopPropagation();
      }}
      position="bottom right"
      trigger={
        <span className={styles.sorter}>
          <Popup
            inverted
            on="hover"
            size="mini"
            position="right center"
            trigger={
              <DisableInBackupMode>
                <WeTeamIcon
                  Icon={SwapIcon}
                  color={whiteIcon ? 'white' : null}
                />
              </DisableInBackupMode>
            }
          >
            <FormattedMessage id="sort.orderBy" />
          </Popup>
        </span>
      }
      style={{
        padding: 0,
        border: 'none',
      }}
    >
      <Menu borderless vertical compact>
        {items.map(item => (
          <MenuItem
            key={item.id}
            active={item.active}
            onClick={event => {
              setOpen(false);
              item.onSort();
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Popup>
  );
}

Sorter.propTypes = {
  items: PropTypes.array.isRequired,
  whiteIcon: PropTypes.bool,
};

export default Sorter;
