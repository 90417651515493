import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(channel) {
  try {
    await commonDb.channels.put({
      channelId: channel.Id,
      workspaceId: channel.WorkspaceId,
      channel,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function clearRemovedChannels(networkChannels, workspaceId) {
  const databaseChannels = await getChannelsByWorkspaceId(workspaceId);

  if (!databaseChannels) {
    return null;
  }

  const networkChannelIds = networkChannels.map(channel => channel.Id);

  // check db channel is inside response Channels
  for (const databaseChannel of databaseChannels) {
    if (!networkChannelIds.includes(databaseChannel.Id)) {
      await remove(databaseChannel.Id);
    }
  }
}

export async function saveMany(networkChannels, workspaceId) {
  try {
    await clearRemovedChannels(networkChannels, workspaceId);

    await commonDb.channels.bulkPut(
      networkChannels.map(channel => ({
        channelId: channel.Id,
        workspaceId: channel.WorkspaceId,
        channel,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.channels.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function getChannelsByWorkspaceId(workspaceId) {
  try {
    const channels = await commonDb.channels
      .where('workspaceId')
      .equals(workspaceId)
      .toArray();
    if (Array.isArray(channels) && channels.length > 0) {
      return channels.map(channel => channel.channel);
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
