import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getWorkspaceById } from 'store/Workspaces/selectors';
import { Button, Divider } from 'semantic-ui-react';
import { getWorkspaceUpgradeLink } from 'pages';
import { withRouter } from 'react-router';
import { isMeetingRestrictedByRoomId } from 'store/Meeting/selectors';

export const MeetingsRestrictedMessage = ({
  meeting,
  isMeetingRestricted,
  workspace,
  showUpgradeButton,
  onUpgradeClick = () => {},
  router,
  As = 'div',
}) => {
  if (!isMeetingRestricted) {
    return null;
  }
  return (
    <As>
      <FormattedMessage
        id={
          workspace.IsOwner
            ? 'MeetingsRestricted.TooManyParticipantsOwner'
            : 'MeetingsRestricted.TooManyParticipants'
        }
        values={{ numberOfPaticipants: meeting.users?.length }}
      />
      {workspace.IsOwner && showUpgradeButton && (
        <>
          <Divider />
          <Button
            size="tiny"
            primary
            onClick={() => {
              onUpgradeClick();
              router.push(getWorkspaceUpgradeLink(workspace.Id));
            }}
          >
            <FormattedMessage id="MeetingsRestricted.UpgradeButtonText" />
          </Button>
        </>
      )}
    </As>
  );
};

MeetingsRestrictedMessage.propTypes = {
  meeting: PropTypes.object.isRequired,
  isMeetingRestricted: PropTypes.bool.isRequired,
  workspace: PropTypes.object.isRequired,
  showUpgradeButton: PropTypes.bool.isRequired,
  onUpgradeClick: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, props) => {
  return {
    workspace: getWorkspaceById(state, props.meeting.workspaceId),
    isMeetingRestricted: isMeetingRestrictedByRoomId(
      state,
      props.meeting.roomId
    ),
  };
})(withRouter(MeetingsRestrictedMessage));
