import React from 'react';
import PropTypes from 'prop-types';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';
import { FormattedDate, FormattedMessage } from 'react-intl';

const FriendlyDate = ({ date }) => {
  const dayDifference = differenceInCalendarDays(
    format(new Date()),
    format(date)
  );
  let formattedDate;
  if (dayDifference === 0) {
    formattedDate = <FormattedMessage id="Comments.Today" />;
  } else if (dayDifference < 7) {
    formattedDate = <FormattedDate value={date} weekday="long" />;
  } else {
    formattedDate = (
      <FormattedDate
        value={date}
        year="2-digit"
        month="2-digit"
        day="2-digit"
      />
    );
  }
  return formattedDate;
};

FriendlyDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FriendlyDate;
