import { connect } from 'react-redux';

import { makeGetCollabratorByEmailAndSpaceId } from 'store/Spaces/Collaborators/selectors';
import addSpaceComment from 'store/Comments/addSpaceComment';

import CollaboratorCardPopup from './CollaboratorCardPopup';
import {
  makeGetSingleSpaceByEmailAndWorkspaceId,
  makeGetSpaceById,
} from 'store/Spaces/selectors';

/*
  Each Collaborator in a Work Space has a direct chat. Try and find it here. 
*/
export const makeMapStateToProps = () => {
  const getSingleSpaceByEmailAndWorkspaceId = makeGetSingleSpaceByEmailAndWorkspaceId();
  const getCollabratorByEmailAndSpaceId = makeGetCollabratorByEmailAndSpaceId();
  const getSpaceById = makeGetSpaceById();

  const mapStateToProps = (state, props) => {
    const space = getSpaceById(state, props.spaceId);
    const collaborator = getCollabratorByEmailAndSpaceId(state, {
      email: props.email,
      spaceId: props.spaceId,
    });
    const directChat = getSingleSpaceByEmailAndWorkspaceId(state, {
      workspaceId: space.WorkspaceId,
      email: props.email,
    });
    const directChatId = directChat ? directChat.Id : null;
    return {
      collaborator: collaborator,
      directChatId,
    };
  };
  return mapStateToProps;
};

export const mapDispatchToProps = dispatch => {
  return {
    onAddSpaceComment: (spaceId, message) => {
      return dispatch(addSpaceComment(spaceId, null, message));
    },
  };
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CollaboratorCardPopup);
