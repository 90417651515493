import { connect } from 'react-redux';

import CollaboratorAvatarWithCard from './CollaboratorAvatarWithCard';
import {
  makeMapStateToProps,
  mapDispatchToProps,
} from './CollaboratorCardPopupContainer';

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CollaboratorAvatarWithCard);
