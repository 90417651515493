import { useEffect, useState } from 'react';
import { get as getMaintenanceMode } from 'DataLayer/Maintenance/get';

function useMaintenanceMode() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    const checkForMaintenanceMode = async () => {
      try {
        const result = await getMaintenanceMode();
        if (result) {
          setIsMaintenanceMode(true);
        }
      } catch (ex) {
        setIsMaintenanceMode(false);
      }
    };

    checkForMaintenanceMode();
  }, []);

  return isMaintenanceMode;
}

export default useMaintenanceMode;
