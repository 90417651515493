import React from 'react';
import { connect } from 'react-redux';

import { getChatMessages } from 'store/ChatMessage/selectors';
import { getChatSendMessageOnEnter } from 'store/User/selectors';
import LazyWrapper from '../Loading/LazyWrapper';

const AddComment = React.lazy(() =>
  import('./AddComment' /* webpackChunkName: "AddComment" */)
);

const AddCommentLazy = props => (
  <LazyWrapper hideLoader>
    <AddComment {...props} />
  </LazyWrapper>
);

export default connect(state => ({
  sendMessageOnEnter: getChatSendMessageOnEnter(state),
  chatMessages: getChatMessages(state),
}))(AddCommentLazy);
