import { getCurrencySymbol } from 'Helpers/Recurly/recurly';
import { createSelector } from 'reselect';
import { PLATFORM } from 'store/Application';

export const isBackupMode = state => state.application.isBackupMode;

export const isAppStore = state => state.application.isAppStore;

export const isMac = state => state.application.platform === PLATFORM.darwin;

export const isMacStore = state => isAppStore(state) && isMac(state);

export const getUserCountryCode = state =>
  state.application.countryCodeOverride || state.application.userCountryCode;

export const getCurrencySymbolForUserCountry = createSelector(
  [getUserCountryCode],
  countryCode => getCurrencySymbol(countryCode)
);
