import React from 'react';
import PropTypes from 'prop-types';
import styles from './CloseIcon.module.css';

const CloseIcon = ({ className, ...props }) => (
  <svg
    className={`cursor-pointer ${className} ${styles.icon}`}
    width={16}
    height={16}
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="none"
      strokeWidth={2}
      strokeMiterlimit={16}
      d="M.346.346l21.308 21.308M.346 21.654L21.654.346"
    />
  </svg>
);

CloseIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default CloseIcon;
