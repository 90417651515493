import CrowdInInContext from 'Components/i18n/CrowdInInContext';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import GoogleAnalytics from 'Components/GoogleAnalytics/GoogleAnalytics';
import Favicon from 'Components/Favicon/Favicon';

import { getUserLocked } from 'store/User/selectors';

import Lockscreen from 'Components/Lockscreen/Lockscreen';
import ToastContainer from 'Components/Toast/ToastContainer';
import NetworkStatus from 'Components/Network/NetworkStatus';

import ModalRoot from 'Components/Modals/ModalRoot';
import DownloadRoot from './Components/Download/DownloadRoot';

import { getLocale, getMessages } from './store/Locale/selectors';
import { Loader } from 'semantic-ui-react';
import IfElectron from 'Components/Electron/IfElectron';
import ElectronAuthListeners from 'Components/Electron/ElectronAuthListeners';
import ElectronHistoryListeners from 'Components/Electron/ElectronHistoryListeners';
import ElectronPlatformListener from 'Components/Electron/ElectronPlatformListener';
import ElectronBlurFocusListener from 'Components/Electron/ElectronBlurFocusListener';

import ElectronLanguageChange from 'Components/Electron/ElectronLanguageChange';
import WindowFocus from 'Components/Window/WindowFocus';
import { isNodeEnvDevelopment } from 'config/config';
import MeetingInvitations from 'Components/Modals/Meeting/MeetingInvitations/MeetingInvitations';
import RequestInterceptor from 'Components/Axios/RequestInterceptor';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import BackupModeRequestInterceptor from 'Components/Axios/BackupModeRequestInterceptor';
import useMaintenanceMode from 'Hooks/maintenance/useMaintenanceMode';
import Maintenance from 'Views/Maintenance';
import ElectronDomainChange from 'Components/Electron/ElectronDomainChange';

const queryClient = new QueryClient();
function App({ isUserLocked, messages, locale, children }) {
  const isMaintenanceMode = useMaintenanceMode();
  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <IntlProvider
          messages={messages}
          locale={locale}
          key={locale}
          onError={e => {
            if (isNodeEnvDevelopment()) {
              console.debug(e);
            }
          }}
        >
          <React.Fragment>
            <RequestInterceptor />
            <BackupModeRequestInterceptor />
            <CrowdInInContext locale={locale} />
            <ErrorBoundary showFallback={false}>
              <MeetingInvitations />
            </ErrorBoundary>
            <Suspense fallback={<Loader active />}>
              {isUserLocked ? (
                <Lockscreen />
              ) : isMaintenanceMode ? (
                <Maintenance />
              ) : (
                children
              )}
            </Suspense>
            <ModalRoot />
            <ToastContainer />
            <DownloadRoot />
            <GoogleAnalytics />
            <Favicon />
            <NetworkStatus />
            <WindowFocus />
            <IfElectron>
              <ElectronLanguageChange />
              <ElectronDomainChange />
              <ElectronAuthListeners />
              <ElectronHistoryListeners />
              <ElectronBlurFocusListener />
              <ElectronPlatformListener />
            </IfElectron>
          </React.Fragment>
        </IntlProvider>
      </DndProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

App.propTypes = {
  messages: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  isUserLocked: PropTypes.bool,
};

const ConnectedApp = connect(state => ({
  locale: getLocale(state),
  messages: getMessages(state),
  isUserLocked: getUserLocked(state),
}))(App);

export default ConnectedApp;
