import { push } from 'react-router-redux';

import {
  getPublicPasswordLink,
  getPublicLinkExpiredLink,
  getPublicResourceBrowseLink,
  getPublicViewLink,
} from 'pages';

import { getById } from '../../DataLayer/Public/PublicResources/get';

import { setPublicResourceRequestInProgress } from '../Requests/setRequestInProgress';

import { mergeResource } from '../Entities/mergeResources';

import { setPublicLinkId, setPublicLinkPassword } from './actions';

export const LOAD_PUBLIC_RESOURCE_SUCCESS = 'PUBLIC/RESOURCE/LOAD_SUCCESS';
export const LOAD_PUBLIC_RESOURCE_FAILED = 'PUBLIC/RESOURCE/LOAD_FAILED';

function loadPublicResource(publicLinkId, publicLinkPassword) {
  return dispatch => {
    dispatch(setPublicResourceRequestInProgress(true));
    dispatch(setPublicLinkId(publicLinkId));
    dispatch(setPublicLinkPassword(publicLinkPassword));
    return getById(publicLinkId, publicLinkPassword).then(
      result => {
        const { resource } = result;
        dispatch(mergeResource(resource));
        dispatch(setPublicResourceRequestInProgress(false));

        if (resource.Folder) {
          dispatch(
            push(getPublicResourceBrowseLink(publicLinkId, resource.Id))
          );
        } else {
          dispatch(push(getPublicViewLink(publicLinkId, resource.Id)));
        }

        dispatch({
          type: LOAD_PUBLIC_RESOURCE_SUCCESS,
          resource,
        });

        return resource;
      },
      error => {
        if (error.code === 'unauthorized') {
          dispatch(push(getPublicPasswordLink(publicLinkId)));
        } else {
          dispatch(push(getPublicLinkExpiredLink(publicLinkId)));
        }
        dispatch({
          type: LOAD_PUBLIC_RESOURCE_FAILED,
        });
        dispatch(setPublicResourceRequestInProgress(false));
        throw new Error(error.code);
      }
    );
  };
}

export default loadPublicResource;
