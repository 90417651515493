import { combineReducers } from 'redux';
import lodashArray from 'lodash/array';

import {
  RESOURCES_ADD,
  RESOURCES_SET,
  LOAD_RESOURCES_ERROR,
  LOAD_RESOURCES_REQUEST,
  LOAD_RESOURCES_SUCCESS,
  RESOURCES_TAG_FILTER_SELECTED,
} from './actions';
import {
  REMOVE_RESOURCE_REQUEST,
  REMOVE_RESOURCE_SUCCESS,
  REMOVE_RESOURCE_FAILED,
} from './removeResource';
import { REMOVE_NOTIFICATION } from 'store/Notifications/actions';
import notificationType from 'Constants/notificationType';

function union(state, resourceIds, parentId) {
  const resourcesById = lodashArray.union(state[parentId], resourceIds);

  return Object.assign({}, state, {
    [parentId]: resourcesById,
  });
}

function setResources(state, resourceIds, parentId) {
  return Object.assign({}, state, {
    [parentId]: resourceIds,
  });
}

function remove(state, ids, parentId) {
  if (!state[parentId]) {
    return state;
  }
  const resources = state[parentId].filter(id => ids.indexOf(id) === -1);
  const deletingResourceIds = state.deletingResourceIds.filter(
    id => ids.indexOf(id) === -1
  );

  return Object.assign({}, state, {
    [parentId]: resources,
    deletingResourceIds,
  });
}

function unmarkAsDeleting(state, ids) {
  const resources = state.deletingResourceIds.filter(
    id => ids.indexOf(id) === -1
  );

  return Object.assign({}, state, {
    deletingResourceIds: resources,
  });
}

function markAsDeleting(state, ids) {
  return Object.assign({}, state, {
    deletingResourceIds: state.deletingResourceIds.concat(ids),
  });
}

function removeByNotification(state, notification) {
  try {
    if (notification.Type === notificationType.ChannelNewResource) {
      return remove(
        state,
        [notification.Resource.Id],
        notification.Resource.ParentId
      );
    }
  } catch (ex) {
    return state;
  }
  return state;
}

const initialState = {
  selectedResourceIds: [],
  deletingResourceIds: [],
};

function resourcesReducer(state = initialState, action) {
  switch (action.type) {
    case RESOURCES_SET:
      return setResources(state, action.resourceIds, action.parentId);
    case RESOURCES_ADD:
      return union(state, action.resourceIds, action.parentId);
    case REMOVE_RESOURCE_REQUEST:
      return markAsDeleting(state, action.ids);
    case REMOVE_RESOURCE_SUCCESS:
      return remove(state, action.ids, action.parentId);
    case REMOVE_RESOURCE_FAILED:
      return unmarkAsDeleting(state, action.ids);
    case REMOVE_NOTIFICATION: {
      return removeByNotification(state, action.notification);
    }
    default:
      return state;
  }
}

function fetching(state = {}, action) {
  switch (action.type) {
    case LOAD_RESOURCES_SUCCESS:
    case LOAD_RESOURCES_ERROR:
      return Object.assign({}, state, { [action.parentId]: false });
    case LOAD_RESOURCES_REQUEST:
      return Object.assign({}, state, { [action.parentId]: true });
    default:
      return state;
  }
}

function error(state = {}, action) {
  switch (action.type) {
    case RESOURCES_SET:
    case RESOURCES_ADD:
    case LOAD_RESOURCES_REQUEST:
      return Object.assign({}, state, { [action.parentId]: false });
    case LOAD_RESOURCES_ERROR:
      return Object.assign({}, state, { [action.parentId]: action.error });
    default:
      return state;
  }
}

function tagFilter(state = {}, action) {
  switch (action.type) {
    case RESOURCES_TAG_FILTER_SELECTED:
      return Object.assign({}, state, {
        [action.spaceId]: action.resourceIds,
      });
    default:
      return state;
  }
}

export default combineReducers({
  ids: resourcesReducer,
  fetching,
  error,
  tagFilter,
});
