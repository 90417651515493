export const isImagePreview = type => {
  switch (type) {
    case BMP:
    case GIF:
    case JPEG:
    case PNG:
      return true;
    default:
      return false;
  }
};

export const isPDF = type => type === PDF;

export const BINARY = 'BINARY';
export const OTIXO_ENCRYPTED = 'OTIXO_ENCRYPTED';
export const BMP = 'BMP';
export const GIF = 'GIF';
export const JPEG = 'JPEG';
export const PNG = 'PNG';
export const TGA = 'TGA';
export const TIFF = 'TIFF';
export const SVG = 'SVG';
export const ICO = 'ICO';
export const PSD = 'PSD';
export const AI = 'AI';
export const EPS = 'EPS';
export const PS = 'PS';
export const M4A = 'M4A';
export const MIDI = 'MIDI';
export const MP3 = 'MP3';
export const OGG = 'OGG';
export const WAV = 'WAV';
export const AAC = 'AAC';
export const M3U = 'M3U';
export const RA = 'RA';
export const WMA = 'WMA';
export const OGV = 'OGV';
export const M2V = 'M2V';
export const THREEGP = '_3GP';
export const THREEG2 = '_3G2';
export const M4V = 'M4V';
export const WEBM = 'WEBM';
export const MPEG = 'MPEG';
export const AVI = 'AVI';
export const FLV = 'FLV';
export const MOV = 'MOV';
export const MP4 = 'MP4';
export const WMV = 'WMV';
export const M3U8 = 'M3U8';
export const RM = 'RM';
export const WMF = 'WMF';
export const SWF = 'SWF';
export const CSV = 'CSV';
export const HTML = 'HTML';
export const TAB = 'TAB';
export const TEXT = 'TEXT';
export const XML = 'XML';
export const CSS = 'CSS';
export const JAVASCRIPT = 'JAVASCRIPT';
export const JSON = 'JSON';
export const MS_PRESENTATION = 'MS_PRESENTATION';
export const MS_PRESENTATION_XML = 'MS_PRESENTATION_XML';
export const MS_SPREADSHEET = 'MS_SPREADSHEET';
export const MS_SPREADSHEET_XML = 'MS_SPREADSHEET_XML';
export const MS_WORD = 'MS_WORD';
export const MS_WORD_XML = 'MS_WORD_XML';
export const XPS = 'XPS';
export const OO_SPREADSHEET = 'OO_SPREADSHEET';
export const OO_TEXT = 'OO_TEXT';
export const OO_PRESENTATION = 'OO_PRESENTATION';
export const PDF = 'PDF';
export const RTF = 'RTF';
export const SUN_WRITER = 'SUN_WRITER';
export const GOOGLE_DOCUMENT = 'GOOGLE_DOCUMENT';
export const GOOGLE_PRESENTATION = 'GOOGLE_PRESENTATION';
export const GOOGLE_SPREADSHEET = 'GOOGLE_SPREADSHEET';
export const GOOGLE_DRAWING = 'GOOGLE_DRAWING';
export const APPLE_PAGES = 'APPLE_PAGES';
export const AUTOCAD = 'AUTOCAD';
export const ONE_NOTE = 'ONE_NOTE';
export const EPUB = 'EPUB';
export const EVERNOTE = 'EVERNOTE';
export const KINDLE = 'KINDLE';
export const MOBI = 'MOBI';
export const GZIP = 'GZIP';
export const ZIP = 'ZIP';
export const Z7 = 'Z7';
export const DEB = 'DEB';
export const RPM = 'RPM';
export const PKG = 'PKG';
export const RAR = 'RAR';
export const TAR = 'TAR';
export const FNT = 'FNT';
export const FON = 'FON';
export const OTF = 'OTF';
export const TTF = 'TTF';
export const APP = 'APP';
export const BAT = 'BAT';
export const COM = 'COM';
export const EXE = 'EXE';
export const JAR = 'JAR';
export const SH = 'SH';
export const QUICKTIME = 'QUICKTIME';
export const PLAIN = 'PLAIN';
export const XLSX = 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET';
export const X_COMPRESSED_ZIP = 'X-COMPRESSED-ZIP';
export const DOCX =
  'VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT';
export const XTAR = 'X-TAR';
export const XGZIP = 'X-GZIP';
export const MSWORD = 'MSWORD';
export const XWMV = 'X-MS-WMV';
export const FOLDER = 'FOLDER';
