import update from 'DataLayer/Share/update';

import {
  hideResourceLinkSettings,
  showResourceLink,
} from 'store/Modals/actions';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

export const UPDATE_LINK_SUCCESS = 'LINK/UPDATE_SUCCESS';

const updateLink = (id, password, days) => dispatch => {
  update(id, password, days).then(
    link => {
      dispatch(hideResourceLinkSettings());
      dispatch(showResourceLink(id));
      dispatch({
        type: UPDATE_LINK_SUCCESS,
        link,
      });
      dispatch(displaySuccessToast('toast.updateLink.success'));
    },
    () => {
      dispatch(displayErrorToast('toast.updateLink.error'));
    }
  );
};

export default updateLink;
