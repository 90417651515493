import { supportedLocales } from 'translations/getMessageByLocale';

const TOUR_IDS = {
  Files: {
    en: '9a85af22-2805-4f8c-a7f1-4b951e39ec5d',
    de: 'f46ebc0b-b9f7-463e-8698-2a090eb0f94b',
  },
  Intro: {
    en: '7357e9fb-3e01-41cf-aed5-f507cc4b2e5b',
    de: '821ac45d-8007-4890-a867-a3b5442da91a',
  },
  Chat: {
    en: '6d3c3564-27f8-4594-a56c-2fb61863c389',
    de: 'a3895cb5-36f7-4c45-8329-04f26f86b5cd',
  },
  Meet: {
    en: '92c4ce04-6257-438a-ba0d-6a6d8d8b4481',
    de: '695adcc4-4c89-4c8d-be66-9cf1db5e4c03',
  },
};

export const showIntroFlow = locale => {
  try {
    if (locale === supportedLocales.de) {
      window.Appcues.show(TOUR_IDS.Intro.de);
    } else {
      window.Appcues.show(TOUR_IDS.Intro.en);
    }
  } catch (e) {}
};

export const showFilesFlow = locale => {
  try {
    if (locale === supportedLocales.de) {
      window.Appcues.show(TOUR_IDS.Files.de);
    } else {
      window.Appcues.show(TOUR_IDS.Files.en);
    }
  } catch (e) {}
};

export const showChatFlow = locale => {
  try {
    if (locale === supportedLocales.de) {
      window.Appcues.show(TOUR_IDS.Chat.de);
    } else {
      window.Appcues.show(TOUR_IDS.Chat.en);
    }
  } catch (e) {}
};

export const showMeetFlow = locale => {
  try {
    if (locale === supportedLocales.de) {
      window.Appcues.show(TOUR_IDS.Meet.de);
    } else {
      window.Appcues.show(TOUR_IDS.Meet.en);
    }
  } catch (e) {}
};

export const startAppCues = () => {
  try {
    window.Appcues.start();
  } catch (e) {}
};

export const identify = (userId, locale) => {
  try {
    window.Appcues.identify(userId, { locale });
  } catch (e) {}
};
