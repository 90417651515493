import { WORKSPACE_SEARCH_LOADED } from './actions';

const intitialState = {
  notifications: [],
  files: [],
};

export default function(state = intitialState, action) {
  switch (action.type) {
    case WORKSPACE_SEARCH_LOADED:
      return {
        notifications: action.notifications,
        files: action.files,
      };
    default:
      return state;
  }
}
