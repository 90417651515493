import {
  showDecryptAndDownloadModal,
  showCloudDownloadRestrictedModal,
} from 'store/Modals/actions';
import { getUserFeatureDownloadFromClouds } from 'store/User/selectors';
import { getRepositoryByResourceId } from 'store/Repositories/selectors';
import { isBandwidthExceeded } from '../../DataLayer/error';
import { showBandwidthExceededModal } from '../Modals/actions';

import addSingle from './addSingle';
import addArchive from './addArchive';
import { loadResource } from '../Resources/loadResource';
import {
  displayErrorToast,
  hideToast,
  displayLoadingToast,
} from '../Toast/displayToast';
import { getResourceById } from 'store/Resources/selectors';
import { fileDownloadedAnalyticsEvent } from 'Components/GoogleAnalytics/events';

const doDownload = (resourceIds, spaceId) => async (dispatch, getState) => {
  const toastId = dispatch(
    displayLoadingToast('toast.addDownload.downloadCalculating')
  );

  const getResource = async resourceId => {
    const state = getState();
    const resource = getResourceById(state, resourceId);
    if (resource.Id) {
      return Promise.resolve(resource);
    }
    return await dispatch(loadResource(resourceId));
  };

  const getDownloadFunction = async () => {
    if (resourceIds.length === 1) {
      const resource = await getResource(resourceIds[0]);
      if (resource.isEncrypted) {
        return showDecryptAndDownloadModal(resource.Id, spaceId, resource.Name);
      }
      if (resource.Folder) {
        return addArchive(resourceIds, spaceId);
      } else {
        return addSingle(resource.Id, spaceId, resource.Name);
      }
    } else {
      return addArchive(resourceIds, spaceId);
    }
  };

  try {
    const downloadFunction = await getDownloadFunction();
    await dispatch(downloadFunction);
    fileDownloadedAnalyticsEvent();
    setTimeout(() => {
      dispatch(hideToast(toastId));
    }, 2000);
  } catch (error) {
    dispatch(hideToast(toastId));
    if (isBandwidthExceeded(error)) {
      dispatch(showBandwidthExceededModal());
    } else {
      dispatch(displayErrorToast('toast.downloadSingleFile.generalError'));
      throw error;
    }
  }
};

export const startDownload = (resourceIds, spaceId) => (dispatch, getState) => {
  const state = getState();

  /*
    #6443
    Free users only allowed to download from Otixo Drive
  */
  const canDownload = getUserFeatureDownloadFromClouds(state);
  const repository = getRepositoryByResourceId(state, resourceIds[0]);

  /*
    Always allow download if:
    - User is in a Space
    - User's plan allows download
    - User is inside their Otixo Drive
  */
  if (canDownload || spaceId || repository.AdapterId === 'otixo-drive') {
    dispatch(doDownload(resourceIds, spaceId));
  } else {
    dispatch(showCloudDownloadRestrictedModal());
  }
};
