import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WorkspaceSidebar from 'Components/Sidebar/SpaceTree/WorkspaceSidebar';
import SpaceChatHeader from 'Components/Chat/SpaceChat/SpaceChatHeader';
import SidebarLayout from 'Components/Layouts/SidebarLayout/SidebarLayout';

import PrivateChatSidebar from '../PrivateChat/Sidebar/PrivateChatSidebar';

class SpaceHomeLayout extends Component {
  render() {
    const { space, children } = this.props;

    return (
      <SidebarLayout
        sidebar={
          space.IsConversation ? (
            <PrivateChatSidebar />
          ) : (
            <WorkspaceSidebar workspaceId={space.WorkspaceId} />
          )
        }
        header={<SpaceChatHeader spaceId={space.Id} />}
      >
        <div className="flex flex-row flex-auto min-height-0 position-relative">
          {children}
        </div>
      </SidebarLayout>
    );
  }
}
SpaceHomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.object.isRequired,
};

export default SpaceHomeLayout;
