export const RESOURCE_PERMISSIONS = {
  DOWNLOAD: 'download',
  DELETE: 'delete',
  SHARE: 'share',
};

const canPerformActionOnResource = (resource, permission) => {
  if (!resource.Deny) {
    return true;
  }
  return !resource.Deny.includes(permission);
};

export const canDownloadResource = resource =>
  canPerformActionOnResource(resource, RESOURCE_PERMISSIONS.DOWNLOAD);

export const canDeleteResource = resource =>
  canPerformActionOnResource(resource, RESOURCE_PERMISSIONS.DELETE);

export const canShareResource = resource =>
  canPerformActionOnResource(resource, RESOURCE_PERMISSIONS.SHARE);
