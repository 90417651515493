import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getResourceLink } from 'pages';
import { Loader } from 'semantic-ui-react';

import ADAPTERS from 'Constants/adapters';

import { getRepositories } from 'store/Repositories/selectors';
import { getFileManagerLocation } from 'store/UITemporary/selectors';

function RedirectToDrive({ repositories, latestResourceId, router }) {
  useEffect(
    () => {
      const goToOtixoDriveHome = () => {
        const ownDrive = repositories.find(
          repository => repository.AdapterId === ADAPTERS.OTIXO_DRIVE
        );
        if (ownDrive) {
          router.replace(getResourceLink(ownDrive.Id));
        }
      };
      if (latestResourceId) {
        router.replace(getResourceLink(latestResourceId));
      } else {
        goToOtixoDriveHome(repositories);
      }
    },
    [latestResourceId, repositories, router]
  );

  return <Loader active />;
}

RedirectToDrive.propTypes = {
  repositories: PropTypes.array.isRequired,
  latestResourceId: PropTypes.string,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(state => ({
  repositories: getRepositories(state),
  latestResourceId: getFileManagerLocation(state),
}))(withRouter(RedirectToDrive));
