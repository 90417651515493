import { changeStatus } from 'DataLayer/Workspaces/Members/put';
import WORKSPACE_MEMBER_STATUS from 'DataLayer/Constants/workspaceMemberStatus';
import { displayErrorToast } from '../../Toast/displayToast';
import loadMembersByWorkspaceId from './loadMembersByWorkspaceId';
import { captureException } from '../../../Helpers/sentry';

const approveMember = (workspaceId, memberId, message) => dispatch =>
  changeStatus(
    workspaceId,
    memberId,
    WORKSPACE_MEMBER_STATUS.INVITED,
    message
  ).then(
    () => {
      dispatch(loadMembersByWorkspaceId(workspaceId));
    },
    error => {
      dispatch(displayErrorToast('toast.approveMember.error'));
      captureException(error);
      throw error;
    }
  );

export default approveMember;
