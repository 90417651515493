import React from 'react';
import PropTypes from 'prop-types';
import LazyWrapper from 'Components/Loading/LazyWrapper';

const FileIconByContentType = React.lazy(() =>
  import('./FileIconByContentType' /* webpackChunkName: "FileIconByContentType" */)
);

function FileIcon({ type, size = 32 }) {
  return (
    <LazyWrapper hideLoader>
      <FileIconByContentType type={type} size={size} />
    </LazyWrapper>
  );
}

FileIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
};

export default FileIcon;
