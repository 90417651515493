import {
  OFFERS,
  getNumberOfMilestones,
  getSpring2024SubOffers,
} from 'DataLayer/Workspaces/Offers/config';
import { getOfferPostponeDurationMinutes } from 'config/config';
import { differenceInMinutes } from 'date-fns';
import { createSelector } from 'reselect';

export const isTrialAutoAccepted = state => state.offer.autoAcceptTrial;

export const getWorkspaceOffer = (state, workspaceId) => {
  try {
    return state.offer?.[workspaceId][0];
  } catch (ex) {
    return null;
  }
};

export const getOffer = state => state.offer;

export const getOffersByWorkspaceId = createSelector(
  [getOffer, (_, workspaceId) => workspaceId],
  (offer, workspaceId) => {
    return offer?.[workspaceId] ?? [];
  }
);

export const getSpring2024OfferData = createSelector(
  [getOffersByWorkspaceId],
  offers => {
    const availableSuboffers = getSpring2024SubOffers();
    const remainingSubOffers =
      offers.filter(offer => availableSuboffers.includes(offer)) ?? [];

    const milestones = getNumberOfMilestones(OFFERS.spring_2024);
    const completed = milestones - remainingSubOffers.length;

    return {
      remainingSubOffers,
      milestones,
      completed,
    };
  }
);

export const isDownloadDesktopOfferAvailable = (state, workspaceId) => {
  try {
    return state.offer?.[workspaceId].some(
      offer => offer === OFFERS.download_desktop
    );
  } catch (ex) {
    return null;
  }
};

export const isTenMembersOfferAvailable = (state, workspaceId) => {
  try {
    return state.offer?.[workspaceId].some(
      offer => offer === OFFERS.add_ten_members
    );
  } catch (ex) {
    return null;
  }
};

export const isWorkspaceOfferPostponed = (state, workspaceId, offerId) => {
  try {
    const datePostponed = state.offer.postponed[workspaceId][offerId];
    return (
      differenceInMinutes(Date(), datePostponed) <
      getOfferPostponeDurationMinutes()
    );
  } catch (ex) {
    return false;
  }
};
