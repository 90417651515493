export const SOURCE_SELECTED = 'COPY/SOURCE_SELECTED';

export const selectSource = (sourceItems, withDelete) => dispatch => {
  dispatch({
    type: SOURCE_SELECTED,
    sourceItems,
    withDelete,
  });
};

export default selectSource;
