export const ALL = 'ALL';

export const TYPE = {
  LINKED: 'LINKED',
  IMAGES: 'image/*',
  AUDIO: 'audio/*',
  VIDEO: 'video/*',
  DOCUMENTS: 'application/vnd.otixo.document',
  PDF: 'application/pdf',
  ARCHIVE: 'application/zip',
  ENCRYPTED: 'application/vnd.otixo.encrypted',
};

export const DATE = {
  LASTDAY: '1',
  LASTWEEK: '7',
  LASTMONTH: '30',
  LASTYEAR: '365',
};

export const SIZE = {
  TINY: 'tn',
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  HUGE: 'hg',
};
