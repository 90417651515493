import axios from 'axios';
import {
  getUnverifiedUserRequestAllowedConfig,
  getAuthenticationPostUrl,
} from '../endpoints';
import { setAuthenticationToken } from 'DataLayer/Authentication/setAuthenticationToken';
import { HTTP_STATUS } from 'DataLayer/error';
import { getOtixoBackendApiUrl } from 'config/config';

export const LOGIN_RESULT = {
  SESSION_CREATED: 'SESSION_CREATED',
  CONTINUE_REGISTRATION: 'CONTINUE_REGISTRATION',
};

export const REGISTRATION_ACTION = {
  VERIFY_PIN: 'verify',
  CREATE_WORK_SPACE: 'create-work-space',
};

export function post(email, password, invitationToken) {
  const config = getUnverifiedUserRequestAllowedConfig();

  config.baseURL = getOtixoBackendApiUrl();

  const params = [];
  params.push(`user=${encodeURIComponent(email)}`);
  params.push(`password=${encodeURIComponent(password)}`);
  params.push(`appid=id`);

  return axios
    .post(
      `${getAuthenticationPostUrl(invitationToken)}?${params.join('&')}`,
      null,
      config
    )
    .then(({ data, status }) => {
      if (status === HTTP_STATUS.OK) {
        return {
          result: LOGIN_RESULT.CONTINUE_REGISTRATION,
          token: data.token,
          action: data.action,
          selectedWorkspacePlan: data.selectedWorkspacePlan,
        };
      }
      setAuthenticationToken(
        data.Authentication.AuthenticationIdentifier,
        data.Authentication.AuthenticationToken
      );
      return {
        result: LOGIN_RESULT.SESSION_CREATED,
      };
    })
    .catch(error => {
      throw new Error(error.response?.status);
    });
}
