import * as React from 'react';

function Microphone(props) {
  return (
    <svg
      data-testid="microphone-icon"
      width={23}
      height={23}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M23.063 14.688h2.25c0 4.563-3.625 8.313-8 8.938v4.375h-2.625v-4.375c-4.375-.625-8-4.375-8-8.938h2.25c0 4 3.375 6.75 7.063 6.75s7.063-2.75 7.063-6.75zm-7.063 4c-2.188 0-4-1.813-4-4v-8c0-2.188 1.813-4 4-4s4 1.813 4 4v8c0 2.188-1.813 4-4 4z" />
    </svg>
  );
}

export default Microphone;
