import axios from 'axios';
import { RESOURCES } from 'DataLayer/endpoints';
import { resourceFields, linkedResourcefields } from './fields';
import process from './process';

export function createFolder(parentId, spaceId, name) {
  return axios
    .post(`${RESOURCES}/folder`, {
      ParentId: parentId,
      SpaceId: spaceId,
      name,
      fields: resourceFields,
    })
    .then(response => response.data.Folder)
    .then(resource => process.single(resource));
}

export function createSpaceFolder(spaceId, name) {
  return axios
    .post(`${RESOURCES}/folder`, {
      SpaceId: spaceId,
      name,
      fields: resourceFields,
    })
    .then(response => response.data.Folder)
    .then(resource => process.single(resource));
}

export function createFile(parentId, spaceId, formData, password, hint, path) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  if (password) {
    const params = [];
    if (parentId) params.push(`parentId=${parentId}`);
    if (spaceId) params.push(`spaceId=${spaceId}`);
    params.push(`password=${password}`);
    params.push(`hint=${hint}`);
    params.push(`fields=${linkedResourcefields}`);
    const paramsString = params.join('&');
    return {
      promise: axios
        .post(`${RESOURCES}/content.encrypt?${paramsString}`, formData, {
          cancelToken: source.token,
        })
        .then(response => response.data.Resources)
        .then(resources => process.all(resources)),
      cancel: source.cancel,
    };
  }

  const params = [];
  if (parentId) params.push(`parentId=${parentId}`);
  if (spaceId) params.push(`spaceId=${spaceId}`);
  if (path) params.push(`path=${encodeURIComponent(path)}`);
  params.push(`fields=${linkedResourcefields}`);
  const paramsString = params.join('&');

  return {
    promise: axios
      .post(`${RESOURCES}/content?${paramsString}`, formData, {
        cancelToken: source.token,
      })
      .then(response => response.data.Resources)
      .then(resources => process.all(resources)),
    cancel: source.cancel,
  };
}
