import axios from 'axios';
import { SHARE } from 'DataLayer/endpoints';
import process from './process';

export function getbyId(id) {
  return axios
    .get(`${SHARE}?ResourceId=${id}`)
    .then(response => response.data.Shares)
    .then(shares => {
      if (shares && shares.length === 1) {
        return process(shares[0]);
      }
      return null;
    });
}
