import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Divider as SemanticUiDivider } from 'semantic-ui-react';
import styles from './Divider.module.scss';

const Divider = ({ children, color }) => (
  <div
    className={classNames(styles.divider, {
      [styles.blue]: color === 'blue',
    })}
  >
    <SemanticUiDivider horizontal>{children}</SemanticUiDivider>
  </div>
);

Divider.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['blue']),
};

export default Divider;
