import { createSelector } from 'reselect';

import { getUser } from '../User/selectors';

import {
  getArchivedWorkspaces,
  getWorkspacePositions as getWorkspacePositionsFromUI,
  getWorkspaceSortType,
  getWorkspacesMarkedAsUnread,
} from '../UI/selectors';
import { getUnreadPerWorkspace } from 'store/Notifications/selectors';
import { WORKSPACE_SORT_TYPE } from 'store/UI';
import { orderBy } from 'lodash';
import { getSpaces } from 'store/Spaces/selectors';

export const getWorkspacesObject = state =>
  state.workspaces.workspaces.workspaces;

export const recentlyAddedWorkspaces = state =>
  state.workspaces.recentlyAddedWorkspaces.workspaceIds;

export const recentlyAddedWorkspaceInvitationMessages = state =>
  state.workspaces.recentlyAddedWorkspaces.invitationMessages;

export const getWorkspaces = createSelector(
  [getWorkspacesObject, getArchivedWorkspaces, getUser],
  (workspaces, archivedWorkspaces, user) =>
    Object.values(workspaces).map(workspace => ({
      ...workspace,
      IsOwner: user.Guid === workspace.OwnerId,
      Archived: archivedWorkspaces.includes(workspace.Id),
    }))
);

export const getProvisionalWorkspaces = createSelector(
  [getWorkspaces],
  workspaces => workspaces.filter(workspace => workspace.Provisional)
);

export const getRecentlyAddedWorkspaces = createSelector(
  [getWorkspaces, recentlyAddedWorkspaces],
  (workspaces, recentlyAddedWorkspaceIds) =>
    recentlyAddedWorkspaceIds
      .map(recentlyAddedWorkspaceId =>
        workspaces.find(workspace => workspace.Id === recentlyAddedWorkspaceId)
      )
      .filter(workspace => workspace)
);

export const getRecentlyAddedWorkspaceInvitationMessages = createSelector(
  [recentlyAddedWorkspaceInvitationMessages],
  invitationMessages => invitationMessages
);

export const getActiveWorkspaces = createSelector(
  [
    getWorkspaces,
    getWorkspacePositionsFromUI,
    getWorkspaceSortType,
    getUnreadPerWorkspace,
    getWorkspacesMarkedAsUnread,
  ],
  (
    workspaces,
    workspacePositions,
    workspaceSortType,
    unreadPerWorkspace = {},
    workspacesMarkedAsUnread
  ) => {
    const activeWorkspaces = workspaces.filter(
      workspace => !workspace.Provisional
    );
    if (workspaceSortType === WORKSPACE_SORT_TYPE.custom) {
      return activeWorkspaces.sort(
        (a, b) =>
          workspacePositions.indexOf(a.Id) - workspacePositions.indexOf(b.Id)
      );
    }

    const workspacesWithUnread = activeWorkspaces.map(workspace => ({
      ...workspace,
      unread: workspacesMarkedAsUnread[workspace.Id]
        ? Number.MAX_SAFE_INTEGER
        : unreadPerWorkspace[workspace.Id] || 0,
    }));

    return orderBy(
      workspacesWithUnread,
      ['unread', workspace => workspace.Name.toLowerCase()],
      ['desc', 'asc']
    );
  }
);

export const getOwnedWorkspaces = createSelector([getWorkspaces], workspaces =>
  workspaces.filter(workspace => workspace.IsOwner)
);

export const getFirstOwnedWorkspaces = createSelector(
  [getOwnedWorkspaces],
  workspaces => workspaces?.[0]
);

export const makeGetWorkspaceById = () =>
  createSelector(
    [getWorkspaces, (_, id) => id],
    (workspaces, id) => workspaces.find(workspace => workspace.Id === id) || {}
  );

export const getWorkspaceById = createSelector(
  [getWorkspaces, (_, id) => id],
  (workspaces, id) => workspaces.find(workspace => workspace.Id === id) || {}
);

export const getWorkspaceSubscriptionById = createSelector(
  [getWorkspaceById],
  workspace => workspace.Subscription
);

export const getWorkspaceByOwnerMail = createSelector(
  [getWorkspaces, (_, email) => email],
  (workspaces, email) =>
    workspaces.filter(workspace => workspace.OwnerMail === email)
);

export const isLoading = state => state.workspaces.workspaces.loading;

export function isWorkspaceFree(state, workspaceId) {
  const workspace = getWorkspaceById(state, workspaceId);
  if (workspace.Subscription && workspace.Subscription.CurrentSubscription) {
    return false;
  }
  return true;
}

export const getCurrentSubscriptionPeriodEnd = createSelector(
  [getWorkspaceById],
  workspace => {
    return workspace.Subscription?.CurrentSubscription?.periodEnd;
  }
);

export const getIsWorkspaceOwner = createSelector(
  [getWorkspaceById],
  workspace => {
    return workspace.IsOwner;
  }
);

export const getFreeOwnedWorkspaces = createSelector(
  [getOwnedWorkspaces],
  workspaces =>
    workspaces.filter(workspace => !workspace.Subscription?.CurrentSubscription)
);

export const getWorkspaceByIdOrChannelId = createSelector(
  [
    getWorkspaces,
    getSpaces,
    (_, workspaceId) => workspaceId,
    (_, __, channelId) => channelId,
  ],
  (workspaces, channels, workspaceId, channelId) => {
    let finalWorkspaceId;
    if (workspaceId) {
      finalWorkspaceId = workspaceId;
    } else if (channelId) {
      const channel = channels.find(channel => channel.Id === channelId);
      finalWorkspaceId = channel?.WorkspaceId;
    }
    const workspace = workspaces.find(
      workspace => workspace.Id === finalWorkspaceId
    );

    return workspace || {};
  }
);

// export const shouldShowUpgradeBanner = createSelector(
//   [getWorkspaceByIdOrChannelId],
//   workspace => {
//     // disable the banner until the next promotion
//     return false;
//     // only show for owners
//     if (!workspace.IsOwner) {
//       return false;
//     }

//     // only show for workspaces with no subscription
//     if (workspace.Subscription?.CurrentSubscription) {
//       return false;
//     }

//     return true;
//   }
// );

export const getPastDueWorkspace = createSelector(
  [getOwnedWorkspaces],
  workspaces => {
    const workspacesOrdered = orderBy(workspaces, 'Id');
    return workspacesOrdered.filter(
      workspace => workspace?.Subscription?.CurrentSubscription.pastDue
    )?.[0];
  }
);

export const getPaymentMethodExpiringWorkspace = createSelector(
  [getOwnedWorkspaces],
  workspaces => {
    const workspacesOrdered = orderBy(workspaces, 'Id');
    return workspacesOrdered.filter(
      workspace =>
        workspace?.Subscription?.CurrentSubscription.paymentMethodExpiring
    )?.[0];
  }
);
