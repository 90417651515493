export function getSortByScreen(state, screen) {
  return state.sorting[screen];
}

export function getSorting(state) {
  return state.sorting;
}

export function getIsColumnSorted(state, screen, newColumn) {
  const { column } = state.sorting[screen];

  return newColumn === column;
}

export default getSortByScreen;
