import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PendingPrivateChat from './PendingPrivateChat';
import { getPendingPrivateChats } from 'store/Spaces/selectors';

const PendingPrivateChats = ({ privateChats }) => (
  <>
    {privateChats.map(privateChat => (
      <PendingPrivateChat key={privateChat.Id} privateChat={privateChat} />
    ))}
  </>
);

PendingPrivateChats.propTypes = {
  privateChats: PropTypes.array.isRequired,
};

export default connect(state => ({
  privateChats: getPendingPrivateChats(state),
}))(PendingPrivateChats);
