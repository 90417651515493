import { SET_REQUEST_IN_PROGRESS } from './setRequestInProgress';

import { REPOSITORIES, SPACES } from './requestTypes';

/*
  These will be true when the app first loads.
  They are added here to prevent a flash of content in the RepositorListContainer.
  If Repos are not loading and there are no repos in the store, it shows a welcome message.
  However we don't want this state to be shown when the app initially starts.
*/
const initialState = {
  [REPOSITORIES]: true,
  [SPACES]: false,
};

function setRequestInProcess(state, id, inProgress) {
  const requestObject = {};
  requestObject[id] = inProgress;
  return Object.assign({}, state, requestObject);
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_REQUEST_IN_PROGRESS:
      return setRequestInProcess(state, action.id, action.inProgress);
    default:
      return state;
  }
}
