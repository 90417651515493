import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { SortableContainer } from 'react-sortable-hoc';
import SpaceNode from './SpaceNode';
import SpaceSorter from './SpaceSorter';
import WeTeamIcon, { ArrowDown2Icon } from 'Components/Icons/WeTeamIcon';

import styles from './Channels.module.css';
import { PlusIcon } from 'Components/Icons/WeTeamIcon';
import CollapsiblePanel from 'Components/CollapsiblePanel/CollapsiblePanel';
import classNames from 'classnames/bind';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

export function Channels({
  channels,
  workspaceId,
  showPlus,
  listType,
  title,
  plusButtonTooltipText,
  onPlusClick,
}) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div>
      <div className="title">
        <div className="flex center">
          <strong className={styles.title}>{title}</strong>

          {showPlus && (
            <Popup
              inverted
              on={'hover'}
              trigger={
                <DisableInBackupMode>
                  <WeTeamIcon
                    Icon={PlusIcon}
                    onClick={e => {
                      e.stopPropagation();
                      onPlusClick();
                    }}
                  />
                </DisableInBackupMode>
              }
              content={plusButtonTooltipText}
              size="tiny"
            />
          )}
        </div>
        <div className={styles.rightIcons}>
          <SpaceSorter
            listType={listType}
            workspaceId={workspaceId}
            showCustom
          />
          <WeTeamIcon
            Icon={ArrowDown2Icon}
            className={classNames(styles.collapseIcon, {
              [styles.collapsed]: collapsed,
            })}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        </div>
      </div>
      <CollapsiblePanel visible={!collapsed}>
        {channels.map((space, index) => (
          <SpaceNode key={space.Id} index={index} space={space} />
        ))}
      </CollapsiblePanel>
    </div>
  );
}

Channels.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  channels: PropTypes.array.isRequired,
  showPlus: PropTypes.bool,
  listType: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  plusButtonTooltipText: PropTypes.node,
  onPlusClick: PropTypes.func,
};

export default SortableContainer(Channels);
