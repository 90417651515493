import axios from 'axios';
import { getTemplatesUrl } from '../../endpoints';
import process from './process';

export function getTemplates() {
  return axios
    .get(getTemplatesUrl())
    .then(response => response.data.templates)
    .then(data => process(data));
}
