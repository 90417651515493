import { retry, skip } from 'DataLayer/Transfers/update';
import { removeTask } from 'DataLayer/Transfers/remove';
import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

export const TRANSFER_CANCELLED = 'TRANSFERS/CANCELLED';
export const CHANGE_TRANSFER_STATUS = 'TRANSFERS/CHANGE_STATUS';
export const REMOVE_TRANSFER = 'TRANSFERS/REMOVE';
export const CHANGE_TRANSFER_PROGRESS = 'TRANSFERS/CHANGE_PROGRESS';
export const ADD_NEW_TASK = 'TRANSFERS/ADD_TASK';

export const TRANSFER_STATUS = {
  // local only
  SKIPPING: 'SKIPPING',
  CANCELLING: 'CANCELLING',
  RETRYING: 'RETRYING',
  // from server
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED',
  RUNNING: 'RUNNING',
  CANCELLED: 'CANCELLED',
};

export const TRANSFER_TYPE = {
  // local only
  DELETE_RESOURCES: 'delete-resources',
  TRANSFER_RESOURCES: 'transfer-resources',
};

export function updateTaskStatus(id, status) {
  return {
    type: CHANGE_TRANSFER_STATUS,
    id,
    status,
  };
}
/*
export function updateTaskProgress(id, task) {
  return {
    type: CHANGE_TRANSFER_PROGRESS,
    id,
    task,
  };
}
*/
export function addTask(newTask, resourceIds) {
  return {
    type: ADD_NEW_TASK,
    newTask,
    resourceIds,
  };
}

export function deleteTask(id) {
  return {
    type: REMOVE_TRANSFER,
    id,
  };
}

export function transferCancelled(id) {
  return {
    type: TRANSFER_CANCELLED,
    id,
  };
}

export const resumeTask = id => dispatch => {
  dispatch(updateTaskStatus(id, TRANSFER_STATUS.RETRYING));
  retry(id);
};

export const skipTask = id => dispatch => {
  dispatch(updateTaskStatus(id, TRANSFER_STATUS.SKIPPING));
  skip(id);
};

export const cancelTask = id => (dispatch, getState) => {
  dispatch(updateTaskStatus(id, TRANSFER_STATUS.CANCELLING));
  removeTask(id).then(
    () => {
      dispatch(transferCancelled(id));
      //dispatch(deleteTask(id));
      dispatch(displaySuccessToast('toast.cancelTask.success'));
    },
    () => {
      dispatch(displayErrorToast('toast.cancelTask.error'));
    }
  );
};
