import CameraIcon from 'Components/Icons/Meeting/Camera';
import ShareDesktop from 'Components/Icons/Meeting/ShareDesktop';
import Microphone from 'Components/Icons/Meeting/Microphone';
import MicDisabled from 'Components/Icons/Meeting/MicDisabled';

import {
  MEETING_STATUS_ICON,
  useMeetingStatus,
} from 'Hooks/data/meeting/useMeetingStatus';
import { memo } from 'react';

export function MeetingIcon() {
  const status = useMeetingStatus();

  if (status === MEETING_STATUS_ICON.CAMERA) {
    return <CameraIcon />;
  } else if (status === MEETING_STATUS_ICON.SHARING_SCREEN) {
    return <ShareDesktop />;
  } else if (status === MEETING_STATUS_ICON.MICROPHONE) {
    return <Microphone />;
  } else {
    return <MicDisabled />;
  }
}

export default memo(MeetingIcon);
