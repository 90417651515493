import { TARGET_SELECTED } from './selectTarget';
import { SOURCE_SELECTED } from './selectSource';

export const RESET_COPY_STATE = 'RESET_COPY_STATE';

function setTarget(state, targetId, isTargetRestricted) {
  return Object.assign({}, state, {
    targetId,
    isTargetRestricted,
  });
}

function setSource(state, sourceItems, withDelete) {
  return Object.assign({}, state, {
    sourceItems,
    withDelete,
    targetId: null,
    isTargetRestricted: true,
  });
}

const initialState = {
  sourceItems: [],
  withDelete: false,
  targetId: null,
  isTargetRestricted: true,
};

export const resetCopyState = () => ({
  type: RESET_COPY_STATE,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TARGET_SELECTED:
      return setTarget(state, action.targetId, action.isTargetRestricted);
    case SOURCE_SELECTED:
      return setSource(state, action.sourceItems, action.withDelete);
    case RESET_COPY_STATE:
      return initialState;
    default:
      return state;
  }
}
