import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import partition from 'lodash/partition';

import clearReminders from 'store/Reminders/clearReminders';

import RemindersListByDate from './RemindersListByDate';

import EmptyImage from './Reminder.png';
import LinkButton from 'Components/Elements/LinkButton';

function RemindersList({
  reminders,
  onClear,
  showWorkspaceName = false,
  showAvatar = false,
}) {
  const [clearing, setClearing] = useState(false);

  const clear = async () => {
    setClearing(true);
    try {
      await onClear();
    } finally {
      setClearing(false);
    }
  };

  const [futureReminders, completedReminders] = partition(
    reminders,
    reminder => reminder.Active
  );

  return (
    <>
      {reminders.length === 0 && (
        <div className="flex column center justify-content-space-between">
          <img height={300} alt="reminder illustration" src={EmptyImage} />
          <p>
            <strong>
              <FormattedMessage id="RemindersList.EmptyTitle" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="RemindersList.EmptyMessage" />
          </p>
        </div>
      )}
      <RemindersListByDate
        reminders={futureReminders}
        showWorkspaceName={showWorkspaceName}
        showAvatar={showAvatar}
        title={<FormattedMessage id="Reminders.Future" />}
      />
      <RemindersListByDate
        reminders={completedReminders}
        showWorkspaceName={showWorkspaceName}
        showAvatar={showAvatar}
        title={
          <div className="flex align-items-center justify-content-space-between">
            <FormattedMessage id="Reminders.Completed" />
            <div>
              {clearing && (
                <Loader inline active size="tiny" className="mr-2" />
              )}
              <LinkButton onClick={clear} dark underlined>
                <FormattedMessage id="Reminders.ClearButtonText" />
              </LinkButton>
            </div>
          </div>
        }
      />
    </>
  );
}

RemindersList.propTypes = {
  reminders: PropTypes.array.isRequired,
  showWorkspaceName: PropTypes.bool,
  showAvatar: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onClear: clearReminders,
})(RemindersList);
