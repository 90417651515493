import * as Sentry from '@sentry/react';
import { remove, saveOne } from 'DataLayer/database/notifications';
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from 'store/Notifications/actions';

export const indexedDbNotificationsMiddleware = store => next => action => {
  try {
    switch (action.type) {
      case ADD_NOTIFICATION: {
        saveOne(action.notification);
        break;
      }
      case REMOVE_NOTIFICATION: {
        remove(action.id);
        break;
      }
      default:
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }

  return next(action);
};
