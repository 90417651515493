import React from 'react';
import PropTypes from 'prop-types';

import './SectionTitle.scss';

const SectionTitle = ({ children, light, className, ...props }) => {
  return (
    <strong
      className={`${className} section-title ${light ? 'light' : ''}`}
      {...props}
    >
      {children}
    </strong>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
  className: PropTypes.string,
};

SectionTitle.defaultProps = {
  className: '', // prevent undefined appearing in the class attribute
};

export default SectionTitle;
