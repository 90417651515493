import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getPricingPageUrl, isWebApp } from 'config/config';

import { OFFERS } from 'DataLayer/Workspaces/Offers/config';

import acceptOffer from 'store/Workspaces/Offer/acceptOffer';
import { isDownloadDesktopOfferAvailable } from 'store/Workspaces/Offer/selectors';
import OfferAcceptedModal from 'Components/WorkspaceOffer/OfferAcceptedModal/OfferAcceptedModal';
import { useMutation } from 'react-query';
import { getLocale } from 'store/Locale/selectors';
import { trialDesktopAppDownloadedAnalyticsEvent } from 'Components/GoogleAnalytics/events';

function AcceptDownloadDesktopOffer({ workspaceId }) {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);

  const locale = useSelector(getLocale);

  const downloadDesktopOfferAvailable = useSelector(state =>
    isDownloadDesktopOfferAvailable(state, workspaceId)
  );

  useEffect(() => {
    setModalVisible(false);
  }, [dispatch, workspaceId]);

  const { mutate } = useMutation({
    mutationFn: () => {
      return dispatch(acceptOffer(workspaceId, OFFERS.download_desktop));
    },
    onSuccess: () => {
      trialDesktopAppDownloadedAnalyticsEvent();
      setModalVisible(true);
    },
  });

  useEffect(() => {
    if (isWebApp()) {
      return;
    }
    if (downloadDesktopOfferAvailable) {
      mutate();
    }
  }, [dispatch, downloadDesktopOfferAvailable, mutate, workspaceId]);

  const onClose = () => {
    setModalVisible(false);
  };

  return (
    <OfferAcceptedModal
      visible={modalVisible}
      workspaceId={workspaceId}
      onClose={onClose}
      message={
        <FormattedMessage
          id="AcceptDownloadDesktopOffer.Message"
          values={{
            pricingPageLink: (
              <a
                href={getPricingPageUrl(locale)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="OfferAcceptedModal.ButtonText.LinkText" />
              </a>
            ),
          }}
        />
      }
      showNextButton={false}
    />
  );
}

AcceptDownloadDesktopOffer.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default AcceptDownloadDesktopOffer;
