import PropTypes from 'prop-types';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import Banner from 'Components/Banner/Banner';

function BackupPromotionBanner({ onClose, intl }) {
  return (
    <Banner
      showExtended={false}
      onClose={event => {
        event.stopPropagation();
        onClose();
      }}
    >
      <div className="mr-4">
        <FormattedHTMLMessage
          id="BackupPromotionBanner.Title"
          values={{ break: <br /> }}
        />
        <a
          href={intl.formatMessage({
            id: 'BackupPromotionBanner.ButtonLink',
          })}
          onClick={onClose}
          rel="noopener noreferrer"
          target="_blank"
          className="ui primary button ml-3 mt-2"
        >
          <FormattedMessage id="BackupPromotionBanner.ButtonText" />
        </a>
      </div>
    </Banner>
  );
}

BackupPromotionBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: intlShape,
};

export default injectIntl(BackupPromotionBanner);
