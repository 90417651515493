import { showUploadProgressModal } from '../Modals/actions';
import { getPendingFilesFromState, getPendingIdsFromState } from './selectors';
import UPLOAD_STATUS from './uploadStatus';

export const UPLOAD_FILES = 'UPLOAD/UPLOAD_FILES';

const doUpload = (parentId, spaceId, password, hint, comment) => (
  dispatch,
  getState
) => {
  const state = getState();
  const fileIds = getPendingIdsFromState(state);
  const files = getPendingFilesFromState(state);

  const filesWithExtraInfo = Object.entries(files).reduce(
    (object, [id, file]) => {
      object[id] = {
        ...file,
        parentId,
        spaceId,
        password,
        hint,
        comment,
        status: UPLOAD_STATUS.PENDING,
      };
      return object;
    },
    {}
  );

  dispatch({
    type: UPLOAD_FILES,
    fileIds,
    files: filesWithExtraInfo,
  });

  dispatch(showUploadProgressModal());
};

const uploadPendingFiles = (
  parentId,
  spaceId,
  password,
  hint,
  comment
) => dispatch => {
  // #6445
  dispatch(doUpload(parentId, spaceId, password, hint, comment));
  /*
  if (spaceId || getBandwidthInformationDontAskAgain()) {
    dispatch(doUpload(parentId, spaceId, password, hint));
  } else {
    dispatch(
      showBandwidthInformationModal(() => {
        dispatch(doUpload(parentId, spaceId, password, hint));
      }),
    );
  }
  */
};

export default uploadPendingFiles;
