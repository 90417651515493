import { combineReducers } from 'redux';
import { LOAD_REPOSITORIES_SUCCESS } from './loadRepositories';
import { LOAD_REPOSITORY_SUCCESS } from './loadRepository';
import { DELETE_REPOSITORY_SUCCESS } from './removeRepository';
import { RENAME_REPOSITORY_SUCCESS } from './updateRepository';
import { ADD_REPOSITORY_SUCCESS } from './addRepository';
import { ONLINE_STATUS_CHANGE, REPOSITORIES_UPDATE_QUOTA } from './actions';

function removeRepositoryFromState(state, id) {
  return state.filter(r => r.Id !== id);
}

function renameRepositoryInState(state, id, name) {
  return state.map(r => {
    if (id !== r.Id) {
      return r;
    }
    return {
      ...r,
      Name: name,
    };
  });
}

function repositoriesReducer(state = [], action) {
  switch (action.type) {
    case RENAME_REPOSITORY_SUCCESS:
      return renameRepositoryInState(state, action.id, action.name);

    case DELETE_REPOSITORY_SUCCESS:
      return removeRepositoryFromState(state, action.id);

    case LOAD_REPOSITORIES_SUCCESS: {
      return action.repositories.map(r => {
        const oldRepository = state.find(repository => repository.Id === r.Id);
        return {
          ...oldRepository,
          ...r,
        };
      });
      // return action.repositories;
    }

    case ADD_REPOSITORY_SUCCESS:
      return state.concat(action.repository);

    case LOAD_REPOSITORY_SUCCESS: {
      return state.map(r => {
        if (action.repository.Id !== r.Id) {
          return r;
        }
        return {
          ...r,
          ...action.repository,
        };
      });
    }

    default:
      return state;
  }
}

function quotasReducer(state = {}, action) {
  switch (action.type) {
    case LOAD_REPOSITORY_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        [action.repository.Id]: {
          ...state[action.repository.Id],
          ...action.repository.Quota,
        },
      });
    }
    case REPOSITORIES_UPDATE_QUOTA: {
      return Object.assign({}, state, {
        ...state,
        [action.id]: {
          ...state[action.id],
          TotalSpace: action.totalSpace,
          UsedSpace: action.usedSpace,
        },
      });
    }

    default:
      return state;
  }
}

function onlineReducer(state = {}, action) {
  switch (action.type) {
    case ONLINE_STATUS_CHANGE:
      return {
        ...state,
        [action.id]: action.status,
      };
    case LOAD_REPOSITORY_SUCCESS: {
      let online = state[action.repository.Id];
      if (
        Object.prototype.hasOwnProperty.call(action.repository, 'Authorized')
      ) {
        online = action.repository.Authorized;
      }
      return Object.assign({}, state, {
        ...state,
        [action.repository.Id]: online,
      });
    }
    case LOAD_REPOSITORIES_SUCCESS: {
      return action.repositories.reduce((object, repository) => {
        let online = state[repository.Id];
        if (online === undefined) {
          online = true;
        }
        if (Object.prototype.hasOwnProperty.call(repository, 'Authorized')) {
          object[repository.Id] = repository.Authorized;
        } else {
          object[repository.Id] = online;
        }
        return object;
      }, {});
    }
    default:
      return state;
  }
}

export default combineReducers({
  repositories: repositoriesReducer,
  quotas: quotasReducer,
  online: onlineReducer,
});
