import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addPendingFiles } from 'store/Upload/addPendingFiles';

import Dropzone from 'react-dropzone';
import './FileDrop.scss';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';

const FileDrop = ({
  children,
  isActive = true,
  onFilesDropped,
  dropzoneClassName,
  resourceId,
  spaceId,
}) => {
  return (
    <div
      className="flex column flex-auto position-relative"
      // https://github.com/react-dnd/react-dnd/issues/457#issuecomment-465901645
      onDragOver={e => e.stopPropagation()}
    >
      <Dropzone
        disabled={!isActive}
        noClick
        onDrop={(acceptedFiles, _fileRejections, dropEvent) => {
          if (acceptedFiles.length === 0) {
            return;
          }
          /*
            - When file(s) are dropped
              - acceptedFiles contains all files
              - dropEvent.dataTransfer.files contains all the files

            - When folder(s) are dropped
              - acceptedFiles contains all files within the folder(s).
              - dropEvent.dataTransfer.files either
                - contains the folder(s)
                - or it is empty

            Therefore the best way to determine if the user dropped a folder is to 
            check if acceptedFiles & dropEvent.dataTransfer.files are the same
          */
          const droppedFiles = Array.from(dropEvent.dataTransfer.files);
          const droppedFilesAndAcceptedFilesMatch =
            acceptedFiles.length === droppedFiles.length &&
            acceptedFiles.every(acceptedFile =>
              droppedFiles.some(
                droppedFile => droppedFile.name === acceptedFile.name
              )
            );

          const containsFolder = !droppedFilesAndAcceptedFilesMatch;

          /*
            Electron always includes an absolute path for file inputs.
            We determine if a file is a folder by checking if path exists.
            Therefore we want to use a different property instead of path.
            We have forked react-dropzone to exclude the absoluate path set by
            Eletron and instead set a relative path.
          */
          acceptedFiles.forEach(file => {
            file.relativePath = file.path;
          });
          onFilesDropped(acceptedFiles, resourceId, spaceId, containsFolder);
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={`flex column flex-auto position-relative ${dropzoneClassName}`}
          >
            {isDragActive && (
              <div className="file-drop-info">
                <Icon size="huge" name="cloud upload" className="upload-icon" />
                <strong>
                  <h1>
                    <FormattedMessage id="FileDrop.DropHelpText" />
                  </h1>
                </strong>
              </div>
            )}
            <input {...getInputProps()} />
            {children}
          </div>
        )}
      </Dropzone>
    </div>
  );
};

FileDrop.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onFilesDropped: PropTypes.func.isRequired,
  dropzoneClassName: PropTypes.string,
  resourceId: PropTypes.string,
  spaceId: PropTypes.string,
};

FileDrop.defaultProps = {
  isActive: true,
};

export default connect(undefined, { onFilesDropped: addPendingFiles })(
  FileDrop
);
