import random from 'lodash/random';

export const COLORS = {
  ourpink: '#ff2f7d',
  ourred: '#b2225d',
  weteamStreetlight: '#ffc700',
  weteamDarkGreen: '#00701f',
  ourlightgreen: '#95e932',
  ourgreen: '#00ae31',
  weteamRoyalBlue: '#00465c',
  weteamSkyBlue: '#00C5E7',
  ourteal: '#00a1b2',
  darkgrey: '#616161',
  lightgrey: '#e8e8e8',
};

export const skeletonGrey = '#d4d8dc';

export const getRandomColor = () => {
  try {
    const { darkgrey, ...colors } = COLORS;
    const colorsArray = Object.values(colors);
    const index = random(colorsArray.length - 1);
    return colorsArray[index];
  } catch (exception) {
    return COLORS.ourgreen;
  }
};
