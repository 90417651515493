import React from 'react';
import PropTypes from 'prop-types';

import styles from './FullScreenPopup.module.css';

const FullScreenPopup = ({ children, header }) => {
  return (
    <div className={styles.fullScreenPopup}>
      <div className={styles.header}>
        <strong>{header}</strong>
      </div>
      <div className={styles.scroller}>{children}</div>
    </div>
  );
};

FullScreenPopup.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
};

export default FullScreenPopup;
