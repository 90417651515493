import { useSelector } from 'react-redux';

import { createMeetingUser } from 'DataLayer/Meeting/post';

import {
  getActiveMeeting,
  getActiveMeetingChannelId,
} from 'store/Meeting/selectors';
import { getMembersAndUserBySpaceId } from 'store/Spaces/Collaborators/selectors';

import CollaboratorAvatar from 'Components/Collaborators/CollaboratorAvatar';
import Participant from 'Components/Meeting/ManageParticipants/Participant';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

const ChannelMembersList = ({ onClose }) => {
  const activeMeeting = useSelector(getActiveMeeting);
  const channelId = useSelector(getActiveMeetingChannelId);
  const { membersExcludingCurrentUser } = useSelector(state =>
    getMembersAndUserBySpaceId(state, channelId)
  );

  if (membersExcludingCurrentUser.length === 0) {
    return null;
  }

  return (
    <>
      {membersExcludingCurrentUser.map(member => {
        const user = createMeetingUser({
          userId: member.UserId,
          firstName: member.FirstName,
          lastName: member.LastName,
          email: member.Email,
          avatarUrl: member.AvatarUrl,
          color: member.Color,
          channelId: channelId,
          invite: true,
        });
        return (
          <ErrorBoundary>
            <Participant
              activeMeeting={activeMeeting}
              user={user}
              key={user.userId}
              icon={
                <CollaboratorAvatar
                  collaborator={member}
                  width={30}
                  height={30}
                  showStatus
                />
              }
            />
          </ErrorBoundary>
        );
      })}
    </>
  );
};

export default ChannelMembersList;
