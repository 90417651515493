import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Divider } from 'semantic-ui-react';

import leavePrivateChat from 'store/Spaces/leavePrivateChat';
import joinPrivateChat from 'store/Spaces/joinPrivateChat';

import ActionableNotification from '../ActionableNotification';

class PendingPrivateChat extends React.Component {
  state = {
    loading: false,
    open: true,
  };

  onLeave = () => {
    const { dispatch, privateChat } = this.props;
    this.setState({
      loading: true,
    });
    dispatch(leavePrivateChat(privateChat)).then(
      () => {
        this.close();
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  onJoin = () => {
    const { dispatch, privateChat } = this.props;
    this.setState({
      loading: true,
    });
    dispatch(joinPrivateChat(privateChat)).then(
      () => {
        this.close();
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { privateChat } = this.props;

    return (
      <ActionableNotification
        isOpen={this.state.open}
        loading={this.state.loading}
        onClose={this.close}
        title={
          <FormattedMessage id="PendingPrivateChat.private-chat-invitation" />
        }
        body={
          <>
            <FormattedMessage
              id="PendingPrivateChat.Title"
              values={{
                name: <strong>{privateChat.Name}</strong>,
              }}
            />
            <Divider hidden />
            <div className="flex justify-content-space-between">
              <Button basic size="mini" onClick={this.onLeave}>
                <FormattedMessage id="PendingPrivateChat.RejectButton" />
              </Button>
              <Button primary onClick={this.onJoin}>
                <FormattedMessage id="PendingPrivateChat.JoinButton" />
              </Button>
            </div>
          </>
        }
      />
    );
  }
}

PendingPrivateChat.propTypes = {
  privateChat: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(PendingPrivateChat);
