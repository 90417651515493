export const RETRY_UPLOAD = 'UPLOAD/RETRY';
export const CANCEL_ACTIVE_UPLOAD = 'UPLOAD/CANCEL_ACTIVE';
export const CANCEL_PENDING_UPLOAD = 'UPLOAD/CANCEL_PENDING';

export const retryUpload = id => ({
  type: RETRY_UPLOAD,
  id,
});

export const cancelActiveUpload = id => ({
  type: CANCEL_ACTIVE_UPLOAD,
  id,
});

export const cancelPendingUpload = id => ({
  type: CANCEL_PENDING_UPLOAD,
  id,
});
