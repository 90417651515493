import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getWorkspaceOffersUrl } from 'DataLayer/endpoints';

export const post = ({ workspaceId, workspaceName, workspaceEmail, offerId }) =>
  axios.post(
    getWorkspaceOffersUrl(workspaceId),
    { workspaceName, email: workspaceEmail, offer: offerId },
    {
      baseURL: getOtixoBackendApiUrl(),
    }
  );
