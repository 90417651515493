import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import Highlighter from 'react-highlight-words';

import FriendlyDate from 'Components/DateTime/FriendlyDate';
import { navigateToLinkedItem } from 'store/LinkedItems/navigateToLinkedItem';
import LinkedItemThumbnail from './LinkedItemThumbnail';

function LinkedItem({
  linkedItem,
  canClick = true,
  showMetaInformation = true,
  showWorkspaceName,
  highlight,
  onClick,
}) {
  const location = useMemo(() => {
    if (!linkedItem || !linkedItem.Workspace) {
      return null;
    }
    if (showWorkspaceName && linkedItem.Workspace.WorkspaceName) {
      return `in ${linkedItem.Workspace.WorkspaceName} → ${linkedItem.Workspace.Name}`;
    }
    return `in ${linkedItem.Workspace.Name}`;
  }, [linkedItem, showWorkspaceName]);

  if (!linkedItem) {
    return null;
  }

  return (
    <div
      className={`${canClick ? 'cursor-pointer' : ''} padding-right`}
      onClick={() => {
        if (canClick) {
          onClick(linkedItem);
        }
      }}
    >
      <div className="flex align-items-center">
        <div>
          {linkedItem.Resource && (
            <LinkedItemThumbnail linkedItem={linkedItem} />
          )}
        </div>
        <div>
          {linkedItem.Resource && (
            <strong>
              <Highlighter
                highlightClassName="highlight-text-color"
                className="wrappable-text"
                searchWords={[highlight]}
                autoEscape
                textToHighlight={linkedItem.Resource.Name}
              />
            </strong>
          )}
          <span className="light-text">
            &nbsp;&nbsp;
            <FormattedDate
              value={linkedItem.Created}
              hour="2-digit"
              minute="2-digit"
            />
          </span>
          {linkedItem.Owner && (
            <div>
              <span className="light-text">{`${linkedItem.Owner.FirstName} ${linkedItem.Owner.LastName}`}</span>
            </div>
          )}
        </div>
      </div>
      {showMetaInformation && (
        <small className="flex justify-content-space-between mt-3 light-text">
          {location}
          <span>
            <FriendlyDate date={linkedItem.Created} />
          </span>
        </small>
      )}
    </div>
  );
}

LinkedItem.propTypes = {
  linkedItem: PropTypes.object.isRequired,
  showWorkspaceName: PropTypes.bool,
  showMetaInformation: PropTypes.bool,
  canClick: PropTypes.bool,
  highlight: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onClick: navigateToLinkedItem,
})(LinkedItem);
