import PropTypes from 'prop-types';
import React from 'react';

class ImageLoader extends React.PureComponent {
  state = {
    loading: false,
    error: false,
    loaded: false,
  };

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  onLoad = () => {
    this.setState({
      loading: false,
      loaded: true,
      error: false,
    });
  };

  onError = () => {
    this.setState({
      error: true,
      loading: false,
    });
  };

  loadImage = () => {
    if (!this.props.src) {
      return;
    }
    this.setState(
      {
        loaded: false,
        error: false,
      },
      () => {
        /*
      Prevent loader showing until the request has taken over 300ms.
      This prevents the loader flashing quickly when the image loads
      quickly.
    */
        setTimeout(() => {
          this.setState(state => {
            if (state.loaded || state.error) {
              /*
           if the image has already loaded/failed, there is no need
           to set the loading state
          */
              return state;
            }
            return {
              loading: true,
            };
          });
        }, this.props.delay);

        const image = new Image();
        this.image = image;
        image.onload = this.onLoad;
        image.onerror = this.onError;
        image.src = this.props.src;
      }
    );
  };

  render() {
    const { error, loading, loaded } = this.state;
    const { children } = this.props;
    //const loaded = !error && !loading;
    return children(loading, error, loaded, this.loadImage);
  }
}

ImageLoader.propTypes = {
  src: PropTypes.string,
  children: PropTypes.func.isRequired,
  delay: PropTypes.number.isRequired,
};

ImageLoader.defaultProps = {
  delay: 300,
};

export default ImageLoader;
