import { supportedLocales } from 'translations/getMessageByLocale';

import { getLanguageFromUrl } from './window';

export const isUserAgentMac = () =>
  navigator.userAgent.toUpperCase().includes('MAC');

function getLanguage() {
  return navigator.language;
}

export function getLocale() {
  try {
    // first check query string
    const urlLanguage = getLanguageFromUrl();
    if (supportedLocales[urlLanguage]) {
      return urlLanguage;
    }

    const language = getLanguage();

    const languageParts = language.split('-');

    if (languageParts) {
      if (supportedLocales[languageParts[0]]) {
        return languageParts[0];
      }
      return supportedLocales.en;
    }
  } catch (e) {
    return supportedLocales.en;
  }
  return supportedLocales.en;
}
