import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from 'semantic-ui-react';

//import Placeholder from './video-placeholder.png';

import PreviewError from './PreviewError';

import './PreviewVideo.scss';

function PreviewVideo({ src, error }) {
  if (error) {
    return <PreviewError />;
  }
  if (!src) {
    return (
      <div className="preview-video">
        <div className="placeholder_video" />
        <Loader active />
      </div>
    );
  }
  return (
    <div className="preview-video">
      <video src={src} controls="controls" />
    </div>
  );
}

PreviewVideo.propTypes = {
  src: PropTypes.string,
  error: PropTypes.object,
};

export default PreviewVideo;
