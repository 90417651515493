import { getStarred } from 'DataLayer/Starred/get';
import { starredLoaded } from './index';

export const loadStarred = () => dispatch => {
  return getStarred().then(stars => {
    dispatch(starredLoaded(stars));
  });
};

export default loadStarred;
