import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { getActiveMeetingRoomId } from 'store/Meeting/selectors';

export function CurrentMeetingWillEndMessage({ activeMeetingRoomId, roomId }) {
  // this message should not appear if no meeting is running
  if (!activeMeetingRoomId) {
    return null;
  }

  // this message should not appear for the running meeting
  if (activeMeetingRoomId === roomId) {
    return null;
  }
  return (
    <Message>
      <FormattedMessage id="MeetingInvitation.CurrentMeetingWillEnd" />
    </Message>
  );
}

CurrentMeetingWillEndMessage.propTypes = {
  roomId: PropTypes.string.isRequired,
  activeMeetingRoomId: PropTypes.bool.isRequired,
};

export default connect((state, props) => ({
  activeMeetingRoomId: getActiveMeetingRoomId(state),
}))(CurrentMeetingWillEndMessage);
