export const SET_PUBLIC_LINK = 'SET_PUBLIC_LINK';
export const SET_PUBLIC_LINK_PASSWORD = 'SET_PUBLIC_LINK_PASSWORD';

export function setPublicLinkId(id) {
  return {
    type: SET_PUBLIC_LINK,
    id,
  };
}

export function setPublicLinkPassword(password) {
  return {
    type: SET_PUBLIC_LINK_PASSWORD,
    password,
  };
}
