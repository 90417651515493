import { star } from 'DataLayer/Resources/update';
import { displayErrorToast } from 'store/Toast/displayToast';
import { resourceStarAdded } from 'store/Entities/actions';
import loadStarred from '../Starred/loadStarred';

const starResource = (channelId, id, starred) => dispatch => {
  dispatch(resourceStarAdded(id, starred));

  return star(channelId, id, starred).then(
    () => {
      dispatch(loadStarred());
    },
    error => {
      dispatch(resourceStarAdded(id, !starred)); // revert to previous
      dispatch(displayErrorToast('toast.star.error'));
      throw error;
    }
  );
};

export default starResource;
