export const CHAT_MESSAGE_UPDATED = 'CHAT_MESSAGE/UPDATED';

export const chatMessageUpdated = (id, value) => ({
  type: CHAT_MESSAGE_UPDATED,
  id,
  value,
});

const initialState = {};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case CHAT_MESSAGE_UPDATED:
      return {
        ...state,
        [action.id]: action.value,
      };
    default:
      return state;
  }
}
