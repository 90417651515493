import PropTypes from 'prop-types';
import React from 'react';

import ParticipantAvatar from 'Components/Meeting/ParticipantAvatar';

function CallerAvatar({ meeting }) {
  if (!meeting.caller) {
    return null;
  }
  return (
    <ParticipantAvatar
      collaborator={meeting.caller}
      width={60}
      height={60}
      showStatus={false}
    />
  );
}

CallerAvatar.propTypes = {
  meeting: PropTypes.shape({
    caller: PropTypes.object.isRequired,
  }).isRequired,
};

export default CallerAvatar;
