import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, Button, Popup, Loader, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import './StarredMessage.scss';

function StarredMessage({ children, onRemoveStar }) {
  const [removing, setRemoving] = useState(false);

  function onRemoveStarClick() {
    if (removing) {
      return;
    }
    setRemoving(true);
    onRemoveStar()
      .then(() => {
        setRemoving(false);
      })
      .catch(() => {
        setRemoving(false);
      });
  }

  return (
    <Segment className="starred-message">
      {children}
      <div className="remove-star">
        <Popup
          data-testid="remove-button-popup"
          inverted
          size="mini"
          position="bottom right"
          trigger={
            <Button
              data-testid="remove-starred-message"
              // loading={removing}
              size="small"
              compact
              icon
              basic
              onClick={onRemoveStarClick}
            >
              <Icon name="star" color="yellow" />
              {removing && (
                <Loader
                  inline
                  active
                  size="mini"
                  className="ml-1"
                  data-testid="loading"
                />
              )}
            </Button>
          }
        >
          <FormattedMessage id="StarredMessage.RemoveStarButtonText" />
        </Popup>
      </div>
    </Segment>
  );
}

StarredMessage.propTypes = {
  children: PropTypes.node.isRequired,
  onRemoveStar: PropTypes.func.isRequired,
};

export default StarredMessage;
