import { CREARTE_WORKSPACE, getWorkspaceLink } from 'pages';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getFreeOwnedWorkspaces, isLoading } from 'store/Workspaces/selectors';
import { withRouter } from 'react-router';
import { trialAutoAccepted } from 'store/Workspaces/Offer';

function AutoAcceptTrial({ router: { replace } }) {
  const workspaces = useSelector(state => getFreeOwnedWorkspaces(state));
  const isFetchingWorkspaces = useSelector(state => isLoading(state));

  const dispatch = useDispatch();

  useEffect(() => {
    if (workspaces.length) {
      dispatch(trialAutoAccepted(true));
      replace(getWorkspaceLink(workspaces[0].Id));
    } else if (!isFetchingWorkspaces) {
      replace(CREARTE_WORKSPACE);
    }
  }, [workspaces, replace, isFetchingWorkspaces, dispatch]);

  return null;
}

AutoAcceptTrial.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(AutoAcceptTrial);
