import * as Sentry from '@sentry/react';
import { getUnseen } from 'DataLayer/Workspaces/get';
import { recentlyAddedWorkspacesLoaded } from './actions';

const loadRecentlyAddedWorkspaces = () => async dispatch => {
  try {
    const unseen = await getUnseen();
    const workspaceIds = unseen.Workspaces?.map(workspace => workspace.Id);
    dispatch(recentlyAddedWorkspacesLoaded(workspaceIds, unseen.Messages));
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default loadRecentlyAddedWorkspaces;
