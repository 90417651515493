import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Header, Modal, Button, Message } from 'semantic-ui-react';

import { terminateSubscription } from 'DataLayer/Workspaces/Subscription/terminate';
import { get } from 'DataLayer/Workspaces/Subscription/get';

import { displaySuccessToast } from 'store/Toast/displayToast';
import loadWorkspaces from 'store/Workspaces/loadWorkspaces';
import useAsyncFn from 'Hooks/useAsyncFn';

const TerminateSubscriptionButton = ({ workspaceId, onComplete, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const [fetchSubscriptionsState, fetchSubscriptions] = useAsyncFn(async () => {
    return await get(workspaceId);
  }, [workspaceId]);

  const [fetchWorkspacesState, fetchWorkspaces] = useAsyncFn(async () => {
    await dispatch(loadWorkspaces());
  }, []);

  const [terminateSubscriptionState, runTerminateSubscription, reset] =
    useAsyncFn(async (workspaceId, subscriptionId) => {
      await terminateSubscription(workspaceId, subscriptionId);
    }, []);

  const onHide = () => {
    setShowModal(false);
  };

  const onShow = () => {
    reset();
    setShowModal(true);
  };

  const onCancelClick = async () => {
    const data = await fetchSubscriptions();
    const subscription = data?.subscriptions.find(
      subscription => subscription.state === 'ACTIVE'
    );
    if (!subscription) {
      return;
    }
    await runTerminateSubscription(workspaceId, subscription.id);
    await fetchWorkspaces();
    onComplete();
    dispatch(displaySuccessToast('TerminateSubscription.Success'));
  };

  const loading =
    terminateSubscriptionState.loading ||
    fetchSubscriptionsState.loading ||
    fetchWorkspacesState.loading;

  return (
    <>
      {showModal && (
        <Modal size="small" open onClose={onHide}>
          <Header>
            <FormattedMessage id="TerminateSubscription.Title" />
            <div className="close_modal">
              <button className="close_modal_button" onClick={onHide} />
            </div>
          </Header>
          <Modal.Content>
            <p>
              <FormattedMessage id="TerminateSubscription.Message" />
            </p>
            {terminateSubscriptionState.error && (
              <Message error>
                <FormattedMessage id="TerminateSubscription.ErrorMessage" />
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            <div className="modal-buttons">
              <Button basic onClick={onHide}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                onClick={onCancelClick}
                color="red"
              >
                <FormattedMessage id="TerminateSubscription.ConfirmButtonText" />
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      )}
      <Button onClick={onShow} {...props}>
        <FormattedMessage id="TerminateSubscription.ButtonText" />
      </Button>
    </>
  );
};

export default TerminateSubscriptionButton;
