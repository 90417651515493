/*
  During compilation WebPack replaces process.env.REACT_APP_ variables with values in the .env file
*/

export const urls = {
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
  desktopDownloadUrl: process.env.REACT_APP_DESKTOP_DOWNLOAD_URL,
  marketingUrl: process.env.REACT_APP_MARKETING_URL,
  otixoDomain: process.env.REACT_APP_DOMAIN,
  otixoZapierOAuthUrl: process.env.REACT_APP_ZAPIER_OAUTH_URL,
  otixoGoogleOAuthUrl: process.env.REACT_APP_GOOGLE_OAUTH_URL,
  otixoAppleOAuthUrl: process.env.REACT_APP_APPLE_OAUTH_URL,
  otixoBasePath: process.env.REACT_APP_BASE_PATH,
  otixoRecurlyAccountUrl: process.env.REACT_APP_RECURLY_ACCOUNT_URL,
  otixoRecurlyPublicKey: process.env.REACT_APP_RECURLY_PUBLIC_KEY,
  otixoGoogleAnalyticsMeasurementId:
    process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
};

export default urls;
