import PropTypes from 'prop-types';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { ScrollPositionContext } from './ScrollPositionTracker';

const ElementPositionTracker = class extends React.Component {
  constructor(props) {
    super(props);
    this.waypointRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.number !== this.props.number && this.props.number === 0) {
      // The badge will be removed if there are no notifications,
      // so let the parent know this badge is no longer outside the scrollable area
      this.props.onPositionChange(
        Waypoint.inside,
        this.props.id,
        this.waypointRef
      );
    }
  }

  componentWillUnmount() {
    // The badge will be removed if there are no notifications,
    // so let the parent know this badge is no longer outside the scrollable area
    this.props.onPositionChange(
      Waypoint.inside,
      this.props.id,
      this.waypointRef
    );
  }

  render() {
    const { id, onPositionChange, children } = this.props;
    return (
      <Waypoint
        onPositionChange={p => {
          if (p.previousPosition === Waypoint.above) {
            onPositionChange(Waypoint.inside, id, this.waypointRef);
          } else if (p.previousPosition === Waypoint.below) {
            onPositionChange(Waypoint.inside, id, this.waypointRef);
          } else if (p.currentPosition === Waypoint.below) {
            onPositionChange(Waypoint.below, id, this.waypointRef);
          } else if (p.currentPosition === Waypoint.above) {
            onPositionChange(Waypoint.above, id, this.waypointRef);
          }
        }}
        ref={this.waypointRef}
      >
        {children}
      </Waypoint>
    );
  }
};
ElementPositionTracker.propTypes = {
  children: PropTypes.node.isRequired,
  onPositionChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

// Ensure our onPositionChange prop is available in the lifecycle methods of <ElementPositionTracker>
export default props => (
  <ScrollPositionContext.Consumer>
    {({ onPositionChange }) => (
      <ElementPositionTracker onPositionChange={onPositionChange} {...props} />
    )}
  </ScrollPositionContext.Consumer>
);
