import {
  take,
  race,
  takeEvery,
  actionChannel,
  call,
  put,
  fork,
  select,
  cancelled,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import ImageBlobReduce from 'image-blob-reduce';
import Pica from 'pica';

import { UPLOAD_FILES } from 'store/Upload/uploadPendingFiles';
import { createComment } from 'DataLayer/Spaces/Comments/create';
import getFormData from 'Helpers/Forms/Files/convertFileToFormData';

import { createFile } from 'DataLayer/Resources/create';
import { normalize } from 'normalizr';
import {
  isBandwidthExceeded,
  isNoQuotaException,
  isNoSpaceQuotaException,
} from '../../DataLayer/error';

import { resourceSchema } from '../Resources/schemas';

import {
  showBandwidthExceededModal,
  showNoQuotaModal,
  showNoSpaceQuotaModal,
} from '../Modals/actions';
import { mergeResources } from '../Entities/mergeResources';

import UPLOAD_STATUS from './uploadStatus';

import { addResources } from '../Resources/actions';
import { RETRY_UPLOAD, CANCEL_ACTIVE_UPLOAD } from './actions';
import { getUploadingFileById } from './selectors';

import { SIZES } from 'Helpers/Forms/Files/convertFilesToObjects';
import { fileAddedAnalyticsEvent } from 'Components/GoogleAnalytics/events';

export const SET_RESIZE = 'UPLOAD/SET_RESIZE';
export const SET_UPLOAD_STATUS = 'UPLOAD/SET_UPLOAD_STATUS';
export const CANCEL_ALL_PENDING = 'UPLOAD/CANCEL_ALL_PENDING';
export const CLEAR_COMPLETED = 'UPLOAD/CLEAR_COMPLETED';

function resizeFile(file) {
  if (!file.resizable || file.resize === SIZES.ORIGINAL) {
    return Promise.resolve(file.file);
  }
  return new Promise((resolve, reject) => {
    const pica = Pica({ features: ['js', 'wasm', 'cib'] });
    const reduce = new ImageBlobReduce({ pica });
    reduce.toBlob(file.file, { max: file.resize }).then(resolve);
  });
}

function* uploadFile(fileId, file) {
  yield put({
    type: SET_UPLOAD_STATUS,
    id: fileId,
    status: UPLOAD_STATUS.UPLOADING,
  });
  let upload;
  try {
    const fileBlob = yield call(resizeFile, file);
    upload = createFile(
      file.parentId,
      file.spaceId,
      getFormData(file.file, fileBlob),
      file.password,
      file.hint,
      file.relativePath
    );

    const resources = yield upload.promise;
    fileAddedAnalyticsEvent();
    yield put({
      type: SET_UPLOAD_STATUS,
      status: UPLOAD_STATUS.COMPLETE,
      id: fileId,
    });

    try {
      const parentId = resources[0]?.ParentId;
      const normalizedResources = normalize(resources, [resourceSchema]);
      yield put(mergeResources(normalizedResources.entities.resources));
      yield put(addResources(parentId, normalizedResources.result));
      if (file.spaceId && file.comment && resources.length === 1) {
        yield createComment(file.spaceId, resources[0].Id, file.comment);
      }
    } catch (error) {
      Sentry.captureMessage('uploadSaga::uploadFile');
      Sentry.captureException(error);
    }
  } catch (error) {
    Sentry.captureMessage('uploadSaga::uploadFile2');
    Sentry.captureException(error);

    if (isBandwidthExceeded(error)) {
      // cancel all
      yield put(showBandwidthExceededModal());
      yield put({
        type: CANCEL_ALL_PENDING,
      });
    } else if (isNoQuotaException(error)) {
      // cancel all
      yield put(showNoQuotaModal());
      yield put({
        type: CANCEL_ALL_PENDING,
      });
    } else if (isNoSpaceQuotaException(error)) {
      // cancel all
      yield put(showNoSpaceQuotaModal(file.spaceId));
      yield put({
        type: CANCEL_ALL_PENDING,
      });
    } else {
      yield put({
        type: SET_UPLOAD_STATUS,
        id: fileId,
        status: UPLOAD_STATUS.FAILED,
      });
    }
  } finally {
    // if this saga was cancelled, we cancel the upload request
    if (yield cancelled()) {
      if (upload.cancel) {
        upload.cancel();
      }
    }
  }

  return;
}

function* uploadSaga() {
  const uploadChannel = yield actionChannel(UPLOAD_FILES);
  while (true) {
    const action = yield take(uploadChannel);
    const { files, fileIds } = action;

    for (const fileId of fileIds) {
      // check the upload has not been cancelled
      const newFile = yield select(getUploadingFileById, fileId);
      if (newFile.status === UPLOAD_STATUS.PENDING) {
        // if the user cancels the upload, the uploadFile saga will be cancelled
        yield race([
          call(uploadFile, fileId, files[fileId]),
          take(CANCEL_ACTIVE_UPLOAD),
        ]);
      }
    }
  }
}

function* retrySaga() {
  yield takeEvery(RETRY_UPLOAD, function* retryUpload(action) {
    const { id } = action;
    const file = yield select(getUploadingFileById, id);
    yield race([call(uploadFile, id, file), take(CANCEL_ACTIVE_UPLOAD)]);
  });
}

export default function* root() {
  yield [fork(uploadSaga), fork(retrySaga)];
}
