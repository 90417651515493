import { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAudioPlayerState,
  resumed,
  paused,
  getCurrentTrack,
  clear,
  AUDIO_PLAYER_STATES,
} from 'store/AudioPlayer';

import CollapsiblePanel from 'Components/CollapsiblePanel/CollapsiblePanel';

import styles from './AudioPlayer.module.css';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { CrossIcon } from 'Components/Icons/WeTeamIcon';
import AudioPlayerTitle from 'Components/AudioPlayer/AudioPlayerTitle';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

function AudioPlayer() {
  const dispatch = useDispatch();
  const {
    state: audioState,
    channelId,
    resourceId,
    notificationId,
  } = useSelector(getAudioPlayerState);
  const currentTrack = useSelector(getCurrentTrack);
  const audioRef = useRef(null);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    if (audioState === AUDIO_PLAYER_STATES.play_requsted) {
      audioRef.current.play();
    } else if (audioState === AUDIO_PLAYER_STATES.pause_requsted) {
      audioRef.current.pause();
    } else if (audioState === AUDIO_PLAYER_STATES.resume_requested) {
      audioRef.current.play();
    }
  }, [audioState]);

  const onPlay = useCallback(() => {
    dispatch(resumed(currentTrack));
  }, [dispatch, currentTrack]);

  const onPause = useCallback(() => {
    dispatch(paused(currentTrack));
  }, [dispatch, currentTrack]);

  const onEnded = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  return (
    <CollapsiblePanel
      visible={!!currentTrack}
      className={styles.collapsiblePanel}
    >
      <div className={styles.container}>
        <div className={styles.audio}>
          <span className={styles.title}>
            <ErrorBoundary showFallback={false}>
              <AudioPlayerTitle
                channelId={channelId}
                resourceId={resourceId}
                notificationId={notificationId}
              />
            </ErrorBoundary>
          </span>
          {currentTrack && (
            <audio
              className={styles.audioPlayer}
              src={currentTrack}
              controls="controls"
              ref={audioRef}
              onPlay={onPlay}
              onEnded={onEnded}
              onPause={onPause}
            />
          )}
        </div>
        <WeTeamIcon Icon={CrossIcon} width={15} height={15} onClick={onEnded} />
      </div>
    </CollapsiblePanel>
  );
}

export default memo(AudioPlayer);
