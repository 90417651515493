import * as Sentry from '@sentry/react';
import { remove } from 'DataLayer/database/resources';
import { REMOVE_RESOURCE_SUCCESS } from 'store/Resources/removeResource';

export const indexedDbResourcesMiddleware = store => next => action => {
  try {
    switch (action.type) {
      case REMOVE_RESOURCE_SUCCESS: {
        action.ids.forEach(id => remove(id));
        break;
      }
      default:
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }

  return next(action);
};
