import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getPutTagUrl, getTagsByChannelIdPutUrl } from 'DataLayer/endpoints';

export const putByResourceId = async ({ channelId, resourceId, tags }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.put(
    getTagsByChannelIdPutUrl(channelId, resourceId),
    { tags },
    config
  );

  return data;
};

export const putByTagId = async ({ channelId, tag }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.put(
    getPutTagUrl(channelId, tag.id),
    tag,
    config
  );

  return data;
};
