import * as Sentry from '@sentry/react';
import { getAll } from 'DataLayer/Repositories/get';
import { loadRepository } from './loadRepository';
import { loadRootFolders } from '../Resources/loadFolders';
import { setRepositoresRequestInProgress } from '../Requests/setRequestInProgress';

export const LOAD_REPOSITORIES_SUCCESS = 'REPOSITORIES/LOADED';

const repositoriesLoaded = repositories => ({
  type: LOAD_REPOSITORIES_SUCCESS,
  repositories,
});

/*
  - loadRepositories() is currently called when the Browser component mounts.
  - We also need to load the associated Resource for each Repository. This is because
    they have important information such as CanAddFile & CanAddFolder.
  - When loading all the Repositories, Quota & Authorized (Online/Offline) are not included. This is because
    including them slows the AJAX load considerably because the cloud needs to be queried.
  - Instead, we make an individual call for each Repository to fetch Quota & Authorized (Online/Offline)
*/
export const loadRepositories = () => async dispatch => {
  dispatch(setRepositoresRequestInProgress(true));
  dispatch(loadRootFolders());
  try {
    const { cachedRepositories, fetchedPromise } = await getAll();

    if (cachedRepositories) {
      dispatch(repositoriesLoaded(cachedRepositories));
    }

    const fetchedRepositories = await fetchedPromise;
    dispatch(setRepositoresRequestInProgress(false));
    if (!fetchedRepositories) {
      return [];
    }
    dispatch(repositoriesLoaded(fetchedRepositories));

    // Now we want to get each repository
    fetchedRepositories.forEach(repository => {
      dispatch(loadRepository(repository.Id));
    });
    return fetchedRepositories;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setRepositoresRequestInProgress(false));
  }
};
