import { TOAST_DISPLAYED, TOAST_HIDDEN } from './displayToast';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case TOAST_DISPLAYED:
      return {
        ...state,
        [action.toast.id]: action.toast,
      };
    case TOAST_HIDDEN:
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    default:
      return state;
  }
};

export default reducer;
