import { connect } from 'react-redux';

import { makeGetCollabratorByEmailAndSpaceId } from 'store/Spaces/Collaborators/selectors';

import Avatar from './Avatar';

export const makeMapStateToProps = state => {
  const getCollabratorByEmailAndSpaceId = makeGetCollabratorByEmailAndSpaceId();

  const mapStateToProps = (state, props) => {
    const { spaceId, email } = props;
    const collaborator = getCollabratorByEmailAndSpaceId(state, {
      spaceId,
      email,
    });

    return {
      avatarUrl: collaborator.AvatarUrl,
      color: collaborator.Color,
      email: collaborator.Email,
      firstName: collaborator.FirstName,
      lastName: collaborator.LastName,
      presence: collaborator.Presence,
      disabled: !collaborator.Email,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(Avatar);
