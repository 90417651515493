export function single(item, spaceId) {
  // Use the cloud url if available.
  if (!item) {
    return item;
  }
  item.url = item.CloudOriginalUrl || item.OtixoOriginalUrl;
  if (spaceId) {
    item.url = `${item.url}?spaceId=${spaceId}`;
  }

  try {
    if (item.Thumbnails) {
      if (item.Thumbnails.length > 1) {
        item.thumbnailUrl = `${item.Thumbnails[1].Url}&resize`;
      } else if (item.Thumbnails.length === 0) {
        item.thumbnailUrl = `${item.Thumbnails[0].Url}&resize`;
      }
    } else {
      item.thumbnailUrl = undefined;
    }
  } catch (e) {
    item.thumbnailUrl = undefined;
  }

  return item;
}
