import { schema } from 'normalizr';

const resourceSchema = new schema.Entity(
  'resources',
  {},
  { idAttribute: 'Id' }
);
const pathSchema = new schema.Entity('path', {}, { idAttribute: 'Id' });

export { pathSchema, resourceSchema };
