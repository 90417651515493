import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetUnreadNotificationsCountBySpaceId } from 'store/Notifications/selectors';
import { makeGetIsChannelMarkedAsUnread } from 'store/UI/selectors';

export const useIsChannelUnread = channelId => {
  const getUnreadNotificationsCountBySpaceId = useMemo(
    makeGetUnreadNotificationsCountBySpaceId,
    []
  );
  const getIsChannelMarkedAsUnread = useMemo(
    makeGetIsChannelMarkedAsUnread,
    []
  );

  const numberOfNotifications = useSelector(state => {
    return getUnreadNotificationsCountBySpaceId(state, channelId);
  });

  const manuallyMarkedAsUnread = useSelector(state => {
    return getIsChannelMarkedAsUnread(state, channelId);
  });

  return {
    manuallyMarkedAsUnread,
    numberOfNotifications,
    isUnread: manuallyMarkedAsUnread || numberOfNotifications > 0,
  };
};
