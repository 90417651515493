import { getFirstChannelByWorkspaceId } from 'store/Spaces/selectors';
import { push } from 'react-router-redux';
import { getSpaceLink } from 'pages';
import { appNavitationMenuChanged, workspacesSidebarChanged } from 'store/UI';

export const prepareWorkspaceTour = space => (dispatch, getState) => {
  if (space.IsChat) {
    const state = getState();
    const channel = getFirstChannelByWorkspaceId(state, space.WorkspaceId);
    if (channel.Id) {
      dispatch(push(getSpaceLink(channel.Id)));
    }
  }
  dispatch(workspacesSidebarChanged(true));
  dispatch(appNavitationMenuChanged(true));
};
