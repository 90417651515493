import ManageParticipantsIcon from 'Components/Icons/Meeting/ManageParticipantsIcon';
import { useState } from 'react';
import ManageParticipantsModal from './ManageParticipantsModal';

function ManageParticipantsButton() {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {modalVisible && (
        <ManageParticipantsModal
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
      <ManageParticipantsIcon
        onClick={() => {
          setModalVisible(true);
        }}
      />
    </>
  );
}

export default ManageParticipantsButton;
