import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { meetingInvitationRejected } from 'store/Meeting';

export function RejectMeetingButton({ roomId, onRejectMeeting }) {
  const onRejectButtonClick = () => {
    onRejectMeeting(roomId);
  };

  return (
    <Button basic onClick={onRejectButtonClick} data-testid="reject">
      <FormattedMessage id="MeetingInvitation.RejectButtonText" />
    </Button>
  );
}

RejectMeetingButton.propTypes = {
  roomId: PropTypes.string.isRequired,
  onRejectMeeting: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onRejectMeeting: meetingInvitationRejected,
})(RejectMeetingButton);
