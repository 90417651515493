import React from 'react';
import { FormattedMessage } from 'react-intl';
import Divider from 'Components/Chat/SpaceChat/Divider';

const NewMessageDivider = () => (
  <Divider color="blue">
    <FormattedMessage id="CommentsByDay.NewMessagesDividerText" />
  </Divider>
);

export default NewMessageDivider;
