import React from 'react';
import PropTypes from 'prop-types';

import LazyWrapper from 'Components/Loading/LazyWrapper';
import BannerManager from 'Components/Banner/BannerManager';

const FullScreenApp = ({ children, enableAppBanner = true }) => {
  return (
    <div className="full-screen-app">
      {enableAppBanner && <BannerManager />}
      <div className="flex flex-row flex-auto min-height-0">
        <LazyWrapper>{children}</LazyWrapper>
      </div>
    </div>
  );
};

FullScreenApp.propTypes = {
  children: PropTypes.element.isRequired,
  enableAppBanner: PropTypes.bool,
};

export default FullScreenApp;
