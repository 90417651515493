import * as requestTypes from './requestTypes';
import createRequestKey from './createRequestKey';

function getInProgressById(state, id) {
  return state.requests[id] || false;
}

export function getUserSaveInProgress(state) {
  return getInProgressById(state, requestTypes.USER_SAVE);
}

export function getResourceLinkLoadInProgress(state) {
  return getInProgressById(state, requestTypes.RESOURCE_LINK);
}

export function getUserLoadInProgress(state) {
  return getInProgressById(state, requestTypes.USER_LOAD);
}

export function getSpaceNotificationInProgress(state) {
  return getInProgressById(state, requestTypes.USER_SAVE);
}
/*
export function getMediaInfoInProgress(state, id) {
  const key = createRequestKey(requestTypes.MEDIAINFO, id);
  return getInProgressById(state, key);
}
*/
export function getResourcesInProgress(state, id) {
  const resourcesKey = createRequestKey(requestTypes.RESOURCES, id);
  const foldersKey = createRequestKey(requestTypes.FOLDERS, id);
  return (
    getInProgressById(state, resourcesKey) ||
    getInProgressById(state, foldersKey)
  );
}

export function getResourceInProgress(state, id) {
  const key = createRequestKey(requestTypes.RESOURCE, id);
  return getInProgressById(state, key);
}

export function getResourcesOrFoldersInProgress(state, id) {
  const resourcesKey = createRequestKey(requestTypes.RESOURCES, id);
  const foldersKey = createRequestKey(requestTypes.FOLDERS, id);
  return (
    getInProgressById(state, resourcesKey) ||
    getInProgressById(state, foldersKey)
  );
}

export function getRepositoryInProgress(state, id) {
  const key = createRequestKey(requestTypes.REPOSITORY, id);
  return getInProgressById(state, key);
}

export function getRepositoriesInProgress(state) {
  return getInProgressById(state, requestTypes.REPOSITORIES);
}

export function getSpacesInProgress(state) {
  return getInProgressById(state, requestTypes.SPACES);
}

export function getPublicResourceInProgress(state) {
  return getInProgressById(state, requestTypes.PUBLIC_RESOURCE);
}

export function getLinkedItemInProgress(state, spaceId, resourceId) {
  const key = createRequestKey(
    requestTypes.LINKED_ITEM,
    `${spaceId}/${resourceId}`
  );
  return getInProgressById(state, key);
}

export function getSpaceInProgress(state, id) {
  const key = createRequestKey(requestTypes.SPACE, id);
  return getInProgressById(state, key);
}

export function getCommentsInProgress(state, spaceId, resourceId) {
  const key = createRequestKey(requestTypes.COMMENTS, spaceId, resourceId);
  return getInProgressById(state, key);
}

export default getInProgressById;
