import { preflightSingle } from '../../DataLayer/Resources/download';
import { getOtixoDownloadUrl, isDesktopApp } from '../../config/config';

import { addDownload } from './addDownload';
import { getToken } from 'Helpers/BrowserApi/localStorage';

export const downloadSingleFile = (resourceId, spaceId, name) => dispatch => {
  return preflightSingle(resourceId, spaceId).then(() => {
    if (isDesktopApp()) {
      window.ipcRenderer?.send('download', {
        url: getOtixoDownloadUrl(resourceId, spaceId),
        name,
        token: getToken(),
      });
    } else {
      dispatch(addDownload(getOtixoDownloadUrl(resourceId, spaceId)));
    }
  });
};

export default downloadSingleFile;
