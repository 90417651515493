import { push } from 'react-router-redux';
import { remove } from 'DataLayer/Repositories/remove';
import { DRIVES } from 'pages';

import { setRepositoryRequestInProgress } from '../Requests/setRequestInProgress';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

export const DELETE_REPOSITORY_SUCCESS = 'REPOSITORY/DELETE_SUCCESS';

export const deleteRepository = repository => dispatch => {
  dispatch(setRepositoryRequestInProgress(repository.Id, true));

  return remove(repository.Id).then(
    () => {
      dispatch({
        type: DELETE_REPOSITORY_SUCCESS,
        id: repository.Id,
      });

      dispatch(
        displaySuccessToast('toast.removeRepository.success', {
          name: repository.Name,
        })
      );
      dispatch(setRepositoryRequestInProgress(repository.Id, false));
      // TODO: Try and move this somewhere else. We may not ALWAYS want to redirect after deleting a space,
      // only when we are currently inside that space
      if (window.location.href.includes(repository.Id)) {
        dispatch(push(DRIVES));
      }
    },
    () => {
      dispatch(displayErrorToast('toast.removeRepository.error'));
      dispatch(setRepositoryRequestInProgress(repository.Id, false));
    }
  );
};

export default deleteRepository;
