import * as Sentry from '@sentry/react';
import { put, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { MEETING_ENDED } from 'store/Meeting';
import { ADD_TEMPORARY_NOTIFICATION } from 'store/Notifications/actions';
import { DOWNLOAD_ADDED } from 'store/Download/addDownload';
import { UPLOAD_FILES } from 'store/Upload/uploadPendingFiles';
import { showDesktopAppReviewModal } from 'store/Modals/actions';
import { STARTED } from 'store/LinkedItems/share';
import { isWebApp } from 'config/config';

export function* displayDesktopReviewModal(action) {
  try {
    yield delay(2000);
    if (!isWebApp()) {
      yield put(showDesktopAppReviewModal());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export default function* desktopAppReviewSaga() {
  yield takeLatest(
    [
      ADD_TEMPORARY_NOTIFICATION,
      MEETING_ENDED,
      DOWNLOAD_ADDED,
      UPLOAD_FILES,
      STARTED,
    ],
    displayDesktopReviewModal
  );
}
