import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(resource, parentId) {
  try {
    await commonDb.resources.put({
      resourceId: resource.Id,
      parentId: parentId,
      resource,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.resources.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function clearRemovedResources(networkResources, parentId, next) {
  const { resources: databaseResources } = await getByParentId(parentId, next);

  if (!databaseResources) {
    return null;
  }

  const networkResourcesIds = networkResources.map(resource => resource.Id);

  // check db notification is inside network response
  for (const databaseResource of databaseResources) {
    if (!networkResourcesIds.includes(databaseResource.Id)) {
      await remove(databaseResource.Id);
    }
  }
}
export async function saveMany(
  networkResources,
  parentId,
  currentPage,
  nextPage
) {
  try {
    await clearRemovedResources(networkResources, parentId, currentPage);

    await commonDb.resources.bulkPut(
      networkResources.map(resource => ({
        resourceId: resource.Id,
        parentId,
        currentPage,
        nextPage,
        resource,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function getByParentId(parentId, currentPage) {
  try {
    if (parentId) {
      const resources = await commonDb.resources
        .where('parentId')
        .equals(parentId)
        .and(resource => resource.currentPage === currentPage)
        .toArray();
      if (Array.isArray(resources) && resources.length > 0) {
        return {
          resources: resources.map(resource => resource.resource),
          nextPage: resources[0].nextPage,
        };
      }
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return [];
}
