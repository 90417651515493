import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useLaunchFileInDesktop from 'Components/Modals/Previewer/Previewers/LaunchFileInDesktop/useLaunchFileInDesktop';
import { FormattedMessage } from 'react-intl';

function LaunchFileInDesktopChild({
  url,
  resourceId,
  onLaunchComplete,
  showText,
}) {
  const { launch } = useLaunchFileInDesktop(url, resourceId, onLaunchComplete);

  useEffect(() => {
    launch();
  }, [launch]);

  if (showText) {
    return <FormattedMessage id="LaunchFileInDesktop.Opening" />;
  }
  return null;
}

LaunchFileInDesktopChild.propTypes = {
  url: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  onLaunchComplete: PropTypes.func,
  showText: PropTypes.func,
};

export default LaunchFileInDesktopChild;
