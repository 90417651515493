import React from 'react';
import PropTypes from 'prop-types';
function Camera({ ...props }) {
  return (
    <svg
      data-testid="camera-icon"
      height={23}
      width={23}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M22.688 14l5.313-5.313v14.625l-5.313-5.313v4.688c0 .75-.625 1.313-1.375 1.313h-16C4.563 24 4 23.437 4 22.687V9.312c0-.75.563-1.313 1.313-1.313h16c.75 0 1.375.563 1.375 1.313V14z" />
    </svg>
  );
}

Camera.propTypes = {
  color: PropTypes.string,
};

export default Camera;
