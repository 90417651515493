import axios from 'axios';
import {
  get as getFromDb,
  saveMany as saveToDb,
} from 'DataLayer/database/workspaces';
import { WORKSPACES, getWorkspaceUnseenUrl } from '../../DataLayer/endpoints';
import { getOtixoBackendApiUrl } from 'config/config';

export async function getFromCache() {
  return await getFromDb();
}

export async function getFromNetwork() {
  return axios
    .get(WORKSPACES, {
      baseURL: getOtixoBackendApiUrl(),
    })
    .then(({ data }) => {
      saveToDb(data);
      return data;
    });
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function get() {
  const cachedWorkspaces = await getFromCache();
  const fetchedPromise = getFromNetwork();
  return { cachedWorkspaces, fetchedPromise };
}

export function getUnseen() {
  return axios.get(getWorkspaceUnseenUrl()).then(result => result.data);
}
