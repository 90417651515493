import { useEffect } from 'react';
import axios from 'axios';

import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

function BackupModeRequestInterceptor() {
  const backupModeActive = useIsBackupMode();

  useEffect(() => {
    if (backupModeActive) {
      const interceptorId = axios.interceptors.request.use(config => {
        /* 
          https://stackoverflow.com/a/50878573
        */
        throw new axios.Cancel('Operation canceled by the user.');
      });
      return () => {
        axios.interceptors.request.eject(interceptorId);
      };
    }
  }, [backupModeActive]);

  return null;
}

export default BackupModeRequestInterceptor;
