import { useSelector } from 'react-redux';

import { getActiveOffer } from 'DataLayer/Workspaces/Offers/get';
import { OFFERS } from 'DataLayer/Workspaces/Offers/config';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { useQuery } from 'react-query';
import {
  getCurrentSubscriptionPeriodEnd,
  getIsWorkspaceOwner,
} from 'store/Workspaces/selectors';
import format from 'date-fns/format';
import { getEnterBillingInformationReminderHidden } from 'store/UI/selectors';

export function useShowBillingInformationReminder(workspaceId) {
  const periodEnd = useSelector(state =>
    getCurrentSubscriptionPeriodEnd(state, workspaceId)
  );
  const isWorkspaceOwner = useSelector(state =>
    getIsWorkspaceOwner(state, workspaceId)
  );

  const enterBillingInformationReminderHidden = useSelector(state =>
    getEnterBillingInformationReminderHidden(state, workspaceId)
  );

  const { data, isLoading } = useQuery(['offers/active', workspaceId], () =>
    getActiveOffer(workspaceId)
  );

  if (enterBillingInformationReminderHidden) {
    return false;
  }

  if (!isWorkspaceOwner) {
    return false;
  }

  if (isLoading) {
    return false;
  }

  if (data?.length === 0) {
    return false;
  }

  if (
    data?.[0].offer === OFFERS.free_trial_aug_2023 &&
    !data?.[0].billingInformationAdded
  ) {
    const dayDifference = differenceInCalendarDays(
      format(periodEnd),
      format(new Date())
    );
    return dayDifference <= 14;
  }

  return false;
}
