import { deleteUser } from 'DataLayer/Meeting/Users/delete';
import { activeMeetingUpdated } from 'store/Meeting';

export const deleteInvitation = (meetingId, userId) => async dispatch => {
  try {
    const meeting = await deleteUser(meetingId, userId);
    dispatch(activeMeetingUpdated(meeting));
  } catch (ex) {
    throw ex;
  }
};
