import axios from 'axios';
import {
  get as getFromDb,
  saveMany as saveToDb,
} from 'DataLayer/database/repositories';
import { REPOSITORIES } from 'DataLayer/endpoints';

import process from './process';

export function getSingle(id) {
  const fields = 'quota,authorized';

  return axios
    .get(`${REPOSITORIES}?id=${id}&fields=${fields}`)
    .then(response => response.data.Repository)
    .then(repository => process.single(repository));
}

export function getMeta(id) {
  return axios
    .get(`${REPOSITORIES}?id=${id}`)
    .then(response => response.data.Repository)
    .then(repository => process.single(repository));
}

export async function getFromCache() {
  return await getFromDb();
}

export async function getFromNetwork() {
  return axios
    .get(REPOSITORIES)
    .then(response => response.data.Repositories)
    .then(repositories => {
      const processedRepositories = process.all(repositories);
      saveToDb(processedRepositories);
      return processedRepositories;
    });
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function getAll() {
  const cachedRepositories = await getFromCache();
  const fetchedPromise = getFromNetwork();
  return { cachedRepositories, fetchedPromise };
}
