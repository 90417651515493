import { getTransferSubscriptions as getTransferSubscriptionsApi } from 'DataLayer/Subscription/get';

export const TRANSFER_SUBSCRIPTIONS_GET_SUCCESS =
  'TRANSFER_SUBSCRIPTIONS_GET_SUCCESS';

export const getTransferSubscriptions = () => dispatch =>
  getTransferSubscriptionsApi().then(subscriptions => {
    dispatch({
      type: TRANSFER_SUBSCRIPTIONS_GET_SUCCESS,
      subscriptions,
    });
    //TODO: Store in state tree
    return Promise.resolve();
  });
