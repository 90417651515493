import { normalize } from 'normalizr';
import * as Sentry from '@sentry/react';
import addLinkedItem from 'DataLayer/LinkedItems/create';
import {
  showNoSpaceQuotaModal,
  showShareLinkedItemsProgressModal,
} from 'store/Modals/actions';

import linkedItemSchema from './linkedItemSchema';

import { isNoSpaceQuotaException } from '../../DataLayer/error';

import { addLinkedItems } from './actions';
import {
  shareStarted,
  shareCompleted,
  shareFailed,
  sharePending,
} from 'store/LinkedItems/share';
import { getTaskById } from 'DataLayer/Transfers/get';
import { TRANSFER_STATUS } from 'store/Transfers/actions';
import { fileSharedAnalyticsEvent } from 'Components/GoogleAnalytics/events';

export const ADD_LINKEDITEMS_SUCCESS = 'LINKEDITEM/ADD_SUCCESS';

const addLinkedItemToSpace = (shareId, spaceId, resourceId) => dispatch => {
  dispatch(showShareLinkedItemsProgressModal());
  return new Promise((resolve, reject) => {
    addLinkedItem(spaceId, [resourceId]).then(
      result => {
        dispatch(shareCompleted(shareId));
        if (result.hasTask) {
          // do something with the task
          const checkTask = () => {
            getTaskById(result.taskId).then(task => {
              if (task.Status === TRANSFER_STATUS.COMPLETED) {
                resolve();
              } else {
                setTimeout(checkTask, 1000);
              }
            });
          };
          checkTask();
        } else {
          const normalizedLinkedItems = normalize(result.items, [
            linkedItemSchema,
          ]);

          dispatch(
            addLinkedItems(
              normalizedLinkedItems.result,
              normalizedLinkedItems.entities.linkedItems,
              spaceId
            )
          );
          resolve();
        }
      },
      error => {
        Sentry.captureException(error);
        dispatch(shareFailed(shareId, error));

        if (isNoSpaceQuotaException(error)) {
          dispatch(showNoSpaceQuotaModal(spaceId));
        }
        /* 
          we don't want to reject here because we want the next item in the list to be processed
        */
        resolve();
      }
    );
  });
};

export const addLinkedItemsToSpace =
  (channelId, resourceIds) => async dispatch => {
    const shares = resourceIds.map(resourceId =>
      dispatch(sharePending(channelId, resourceId))
    );

    dispatch(showShareLinkedItemsProgressModal());

    for (const share of shares) {
      dispatch(shareStarted(share.id));
      await dispatch(
        addLinkedItemToSpace(share.id, share.channelId, share.resourceId)
      );
      fileSharedAnalyticsEvent();
    }
  };

export default addLinkedItemsToSpace;
