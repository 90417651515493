export default function modal(state = [], action) {
  let newState = state.slice();
  switch (action.type) {
    case 'SHOW_MODAL':
      // If the modal is already open, replace it with the new modal
      if (state.find(m => m.type === action.modalType)) {
        newState = state.filter(m => m.type !== action.modalType);
        // return state;
      }

      return newState.concat({
        type: action.modalType,
        props: action.props,
        open: true,
      });
    case 'HIDE_MODAL':
      return state.filter(m => m.type !== action.modalType);
    case 'HIDE_ALL_MODALS':
      return [];
    default:
      return state;
  }
}
