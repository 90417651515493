import en from './en.json';
import de from './de.json';
import crowdin from './ta.json';

export const supportedLocales = {
  en: 'en',
  de: 'de',
  crowdin: 'fr',
};

// const files = {
//   [supportedLocales.en]: () =>
//     import(/* webpackMode: "lazy", webpackChunkName: "i18n-en" */ `./en.json`),
//   [supportedLocales.de]: () =>
//     import(/* webpackMode: "lazy", webpackChunkName: "i18n-de" */ `./de.json`),
// };

export default function getMessageByLocale(locale) {
  if (locale === supportedLocales.de) {
    return de;
  } else if (locale === supportedLocales.crowdin) {
    return crowdin;
  }
  return en;
  // const localeFinal = files[locale] ? locale : supportedLocales.en;
  // const messages = files[localeFinal]();
  // console.log(messages.default);
  // return messages.default;
}
