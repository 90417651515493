import { TYPING_STARTED, TYPING_STOPPED } from './actions';

export default function(state = {}, action) {
  switch (action.type) {
    case TYPING_STARTED:
      return {
        ...state,
        [action.email]: action.channelId,
      };
    case TYPING_STOPPED:
      return {
        ...state,
        [action.email]: null,
      };
    default:
      return state;
  }
}
