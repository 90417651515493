import notificationType from 'Constants/notificationType';
import { combineReducers } from 'redux';
import { DELETE_LINKEDITEM_SUCCESS } from 'store/LinkedItems/actions';
import { REMOVE_RESOURCE_SUCCESS } from 'store/Resources/removeResource';
import { SPACES_REMOVE } from 'store/Spaces/actions';

import {
  ADD_NOTIFICATION,
  ADD_NOTIFICATIONS,
  SET_STATS,
  REMOVE_NOTIFICATION,
  UPDATE_MESSAGE,
  ADD_TEMPORARY_NOTIFICATION,
  REMOVE_NOTIFICATIONS,
  READ_STATE_CHANGED,
  NOTIFICATION_STAR_ADDED,
} from './actions';
// import { REMINDERS_ADDED, REMINDER_REMOVED } from 'store/Reminders';

/*
  18-2-8
  preventClobber:

  Scenario:
  User goes to a Space with messages already loaded
  Space Component requests notifications from server
  User write a message
  Message goes to temp message store
  App receives message over WebSocket
  Real message added to store
  Temp message removed from store
  Notifications response received from server (without new user message)
  Space Notifications overwritten by response
  User message is overwritten

  preventClobber will mark all messages added via websocket so they will
  not be overwritten when notifications are loaded from the server
*/
function addNotificationToState(state, notification) {
  return Object.assign({}, state, {
    [notification.Id]: {
      ...notification,
      preventClobber: true,
    },
  });
}

function addAllNotificationsToState(
  state,
  spaceId,
  notifications,
  overwriteNotifications
) {
  if (overwriteNotifications) {
    Object.values(state).forEach(notification => {
      if (
        notification.Workspace &&
        notification.Workspace.Id === spaceId &&
        !notification.preventClobber
      ) {
        delete state[notification.Id];
      }
    });
  }

  return Object.assign({}, { ...state }, { ...notifications });
}

function clearTemporaryNotifications(state, spaceId, notifications) {
  const temporaryNotifications = { ...state };
  const notificationsArray = Object.values(notifications);

  Object.values(temporaryNotifications).forEach(temporaryNotification => {
    const realNotification = notificationsArray.find(n => {
      if (
        n.Comment &&
        temporaryNotification.Comment &&
        temporaryNotification.Comment.CommentId === n.Comment.CommentId
      ) {
        return true;
      }

      return false;
    });
    if (realNotification) {
      delete temporaryNotifications[temporaryNotification.Id];
    }
  });

  return Object.assign({}, { ...temporaryNotifications });
}

function clearTemporaryNotification(state, notification) {
  if (notification.Temporary) {
    return state;
  }
  const temporaryNotifications = { ...state };

  const temporaryNotification = Object.values(temporaryNotifications).find(
    tn => {
      if (
        notification.Comment &&
        tn.Comment &&
        tn.Comment.CommentId === notification.Comment.CommentId
      ) {
        return true;
      }
      return false;
    }
  );

  if (temporaryNotification) {
    delete temporaryNotifications[temporaryNotification.Id];
  }

  return Object.assign({}, { ...temporaryNotifications });
}

function removeNotificationsBySpaceId(state, spaceId) {
  const notifications = { ...state };

  Object.values(notifications).forEach(notification => {
    if (notification.Workspace && notification.Workspace.Id === spaceId) {
      delete notifications[notification.Id];
    }
  });

  return Object.assign({}, { ...notifications });
}

function removeNotificationById(state, id) {
  const notifications = { ...state };
  delete notifications[id];
  return Object.assign({}, { ...notifications });
}
function updateNotificationMessageById(state, id, message) {
  if (!state[id]) return state;
  const notification = { ...state[id] };
  const comment = { ...notification.Comment };
  comment.Text = message;
  notification.Comment = comment;
  return Object.assign({}, { ...state, [id]: notification });
}

function updateNotificationStarById(state, id, starred) {
  if (!state[id]) return state;
  const notification = { ...state[id] };
  notification.Starred = starred;
  notification.StarredTimestamp = starred ? Date.now() : null;
  return Object.assign({}, { ...state, [id]: notification });
}

function markNotificationsWithFileAsDeleted(state, ids) {
  const notifications = { ...state };

  Object.values(notifications).forEach(notification => {
    if (
      ids.includes(notification.Resource?.Id) &&
      (notification.Type === notificationType.ChannelResourceLinked ||
        notification.Type === notificationType.ChannelNewResource)
    ) {
      const notificationUpdated = { ...notification, FileDeleted: true };
      notifications[notificationUpdated.Id] = notificationUpdated;
    }
  });

  return { ...notifications };
}

function notificationsReducer(state = {}, action) {
  switch (action.type) {
    case DELETE_LINKEDITEM_SUCCESS:
    case REMOVE_RESOURCE_SUCCESS: {
      return markNotificationsWithFileAsDeleted(state, action.ids);
    }
    case ADD_NOTIFICATION:
      return addNotificationToState(state, action.notification);
    case ADD_NOTIFICATIONS:
      return addAllNotificationsToState(
        state,
        action.spaceId,
        action.notifications,
        action.overwriteNotifications
      );
    case SPACES_REMOVE:
    case REMOVE_NOTIFICATIONS:
      return removeNotificationsBySpaceId(state, action.spaceId);
    case REMOVE_NOTIFICATION:
      return removeNotificationById(state, action.id);
    case UPDATE_MESSAGE:
      return updateNotificationMessageById(state, action.id, action.message);
    case NOTIFICATION_STAR_ADDED:
      return updateNotificationStarById(state, action.id, action.starred);
    // case REMINDERS_ADDED:
    //   return updateNotificationReminderById(
    //     state,
    //     action.reminder.TargetId,
    //     action.reminder.Id
    //   );
    // case REMINDER_REMOVED:
    //   return updateNotificationReminderById(state, action.targetId, null);

    default:
      return state;
  }
}

function temporaryNotificationsReducer(state = {}, action) {
  switch (action.type) {
    case ADD_TEMPORARY_NOTIFICATION:
      return addNotificationToState(state, action.notification);
    case ADD_NOTIFICATIONS:
      return clearTemporaryNotifications(
        state,
        action.spaceId,
        action.notifications
      );
    case ADD_NOTIFICATION:
      return clearTemporaryNotification(state, action.notification);
    default:
      return state;
  }
}

const st = {};

function statsReducer(state = st, action) {
  switch (action.type) {
    case SET_STATS:
      return {
        ...state,
        ...action.stats,
      };
    default:
      return state;
  }
}

function hiddenReducer(state = {}, action) {
  switch (action.type) {
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        [action.spaceId]: action.hasHiddenMessages,
      };
    default:
      return state;
  }
}

function pagesReducer(state = {}, action) {
  switch (action.type) {
    case REMOVE_NOTIFICATIONS:
      return {
        ...state,
        [action.spaceId]: null,
      };
    case ADD_NOTIFICATIONS:
      // if (action.pageToken === undefined) {
      //   return state;
      // }
      return {
        ...state,
        [action.spaceId]: {
          hasOlderMessages: action.hasOlderMessages,
          hasNewerMessages: action.hasNewerMessages,
        },
      };
    default:
      return state;
  }
}

function updateLastRead(state, spaceId, email, notificationId, timestamp) {
  const newState = {
    ...state[spaceId],
    [email]: {
      Id: notificationId,
      Timestamp: timestamp,
    },
  };
  return {
    ...state,
    [spaceId]: newState,
  };
}

function lastReadReducer(state = {}, action) {
  switch (action.type) {
    case READ_STATE_CHANGED:
      return updateLastRead(
        state,
        action.spaceId,
        action.email,
        action.notificationId,
        action.timestamp
      );
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        [action.spaceId]: action.lastRead,
      };
    default:
      return state;
  }
}

export default combineReducers({
  notifications: notificationsReducer,
  temporaryNotifications: temporaryNotificationsReducer,
  stats: statsReducer,
  hidden: hiddenReducer,
  pages: pagesReducer,
  lastRead: lastReadReducer,
});
