import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { getSpaceMigrateLink, WORKSPACES } from 'pages';

import { makeGetSpaceById } from 'store/Spaces/selectors';
import loadSpace from 'store/Spaces/loadSpace';
import { channelSelected } from 'store/UI';
import SpaceHomeLayout from 'Views/SpaceHome/SpaceHomeLayout';

class SpaceHomeLayoutConnected extends Component {
  componentDidMount() {
    if (!this.props.space.Id) {
      this.loadSpace();
    }
    this.props.dispatch(
      channelSelected(this.props.space.WorkspaceId, this.props.space.Id)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.space.Id !== this.props.space.Id && this.props.space.Id) {
      this.props.dispatch(
        channelSelected(this.props.space.WorkspaceId, this.props.space.Id)
      );
    } else if (!this.props.space.Id) {
      this.loadSpace();
    }
    if (
      prevProps.space.Locked !== this.props.space.Locked &&
      this.props.space.Locked
    ) {
      this.props.router.push(getSpaceMigrateLink(this.props.space.Id));
    }
  }

  loadSpace() {
    // If the space is not in the store, we need to load it.
    if (!this.props.params.spaceId) {
      return;
    }
    this.props.dispatch(loadSpace(this.props.params.spaceId)).catch(() => {
      // if the channel is not found, simply redirect to the workspaces route and let
      // the app navigate to the last used workspace
      this.props.router.push(WORKSPACES);
    });
  }

  render() {
    const { space, children } = this.props;

    return <SpaceHomeLayout space={space}>{children}</SpaceHomeLayout>;
  }
}

SpaceHomeLayoutConnected.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  params: PropTypes.shape({
    spaceId: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
};

export default connect(() => {
  const getSpaceById = makeGetSpaceById();
  return (state, props) => ({
    space: getSpaceById(state, props.params.spaceId),
  });
})(withRouter(SpaceHomeLayoutConnected));
