import format from 'date-fns/format';
import getInitials from 'Helpers/Strings/getInitials';

function single(comment, spaceId, resourceId) {
  if (comment.CreatedAt) {
    comment.CreatedAtDay = format(comment.CreatedAt, 'YYYY-MM-DD');
  } else {
    comment.CreatedAt = undefined;
  }

  comment.SpaceId = spaceId;
  comment.ResourceId = resourceId;

  if (comment.CreatedBy) {
    comment.CreatedBy.FullName = `${comment.CreatedBy.FirstName} ${
      comment.CreatedBy.LastName
    }`;
    comment.CreatedBy.Initials = getInitials(
      comment.CreatedBy.FirstName,
      comment.CreatedBy.LastName,
      comment.CreatedBy.Email
    );
  } else if (comment.User) {
    comment.CreatedBy = {
      FullName: comment.User.name,
      Initials: 'TD',
    };
  }

  return comment;
}

function all(comments, spaceId, resourceId) {
  if (!comments) {
    return [];
  }
  return comments.map(comment => single(comment, spaceId, resourceId));
}

export default {
  single,
  all,
};
