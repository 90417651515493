// higher-order component that passes the dimensions of the window as props to
// the wrapped component
import React, { Component } from 'react';
import debounce from 'lodash/debounce';

export default ComposedComponent => {
  class windowSize extends Component {
    constructor() {
      super();
      this.state = {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      };
    }

    componentDidMount() {
      // bind window resize listeners
      //this._handleResize = this.handleResize.bind(this);
      window.addEventListener('resize', this.handleResize);
      //this.handleResize();
      //setTimeout(this.handleResize, 1000);
    }

    componentWillUnmount() {
      // clean up listeners
      window.removeEventListener('resize', this.handleResize);
    }

    getWrappedInstance() {
      return this.wrappedInstance;
    }

    handleResize = debounce(() => {
      // set initial state
      this.setState({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      });
    }, 500);

    render() {
      // pass window dimensions as props to wrapped component
      return (
        <ComposedComponent
          {...this.props}
          ref={c => {
            this.wrappedInstance = c;
          }}
          windowWidth={this.state.width}
          windowHeight={this.state.height}
        />
      );
    }
  }

  const composedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component';

  windowSize.displayName = `windowSize(${composedComponentName})`;
  return windowSize;
};
