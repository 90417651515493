import { push } from 'react-router-redux';
import { getWorkspaceLink } from 'pages';
import { join } from 'DataLayer/Workspaces/Members/put';
import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';
import loadWorkspaces from './loadWorkspaces';

const joinWorkspace = workspace => dispatch =>
  join(workspace.Id).then(
    () => {
      dispatch(loadWorkspaces(workspace.Id));
      dispatch(
        displaySuccessToast('toast.joinWorkspace.success', {
          name: workspace.Name,
        })
      );
      dispatch(push(getWorkspaceLink(workspace.Id, workspace.Type)));
      return workspace;
    },
    () => {
      dispatch(displayErrorToast('toast.joinWorkspace.error'));
    }
  );

export default joinWorkspace;
