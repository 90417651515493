import { useState, useEffect, useRef } from 'react';

/*
  When the machine wakes up, the browser's online event is unfortunately NOT fired.      
  Therefore we manually detect when the machine has been hibernating/asleep.
  Components using this HoC will be listening for the following state change:

      offline->online

  to determine when to re-sync the client with the server. 

  Therefore, when we determine that the machine has just woken up, we set the isOnline value to
  offline, then immediately back to online.
*/

const TIMEOUT = 60000; // 1 minute
export default function useSystemAwake() {
  const [awakeStatus, setAwakeStatus] = useState(true);
  const wakeUpInterval = useRef();

  useEffect(() => {
    let lastTime = new Date().getTime();
    wakeUpInterval.current = setInterval(() => {
      const currentTime = new Date().getTime();
      if (currentTime > lastTime + TIMEOUT + 2000) {
        /*
          The application can't detect when the computer goes to sleep. Instead we can only determine when 
          it has woken up. So any listeners are triggered, we need to change the value from true to false, then from 
          false to true so event listeners can see the change
        */
        setAwakeStatus(false);
        setAwakeStatus(true);
      }
      lastTime = currentTime;
    }, TIMEOUT);
    return () => {
      clearInterval(wakeUpInterval.current);
    };
  }, []);

  return awakeStatus;
}
