import { ASCENDING } from '../../Constants/direction';

export const SET_SORT = 'SORTING/SET_SORT';

export function sort(screen, column, direction) {
  let newDirection = direction;
  if (!direction) {
    newDirection = ASCENDING;
  }
  return {
    type: SET_SORT,
    column,
    direction: newDirection,
    screen,
  };
}
