import { createSelector } from 'reselect';
import { xxxgetPlanByName, xxxisStandard } from 'Constants/plans';
import { getZaps } from '../Integrations/Zaps/selectors';

export function isUserLoggedIn(state) {
  return !!state.user.UserID;
}

export function getUser(state) {
  return state.user;
}

export function getUserLocked(state) {
  return state.user.Locked;
}

export function getUserId(state) {
  return state.user.UserID;
}

export function getUserGuid(state) {
  return state.user.Guid;
}

export function getUserGroupId(state) {
  return state.user.GroupId;
}

export function getUserFullName(state) {
  return state.user.FullName;
}

export function getUserFirstName(state) {
  return state.user.FirstName;
}

export function getUserFeaturePostBillingEnabled(state) {
  return state.user.Features && state.user.Features.post_limit_allowed;
}

function getUserFeatureControlPassword(state) {
  return state.user.Features && state.user.Features.control_password;
}

function getUserFeatureControlExpire(state) {
  return state.user.Features && state.user.Features.control_expire;
}

// #6266
/*
  DEPRECATED
  Use space.Features
export function getUserFeatureSpaceAccessControl(state) {
  return state.user.Features && state.user.Features.space_access_control;
}
*/
// # 6254
export function getUserFeatureAppLimit(state) {
  return (
    (state.user.Features && state.user.Features.max_integration_number) ?? 10
  );
}

// #6264
export function getUserFeatureCloudSearch(/*state*/) {
  return true;
  // return state.user.Features && state.user.Features.search_in_clouds;
}

// #6263
export function getUserFeatureSpaceSearch(/*state*/) {
  return true;
  // return state.user.Features && state.user.Features.search_in_workspaces;
}

// #6262
export function getUserFeatureAddAccount(state) {
  // return true;
  return state.user.Features && state.user.Features.add_accounts;
}

// #6443
export function getUserFeatureUploadToClouds(state) {
  return state.user.Features && state.user.Features.upload_to_clouds_allowed;
}

// #6431

// DEPRECATED
// Use space.Features
/*
export function getUserFeatureCreateSpaceFolder(state) {
  return state.user.Features && state.user.Features.create_space_folder;
}
*/

// #6443
export function getUserFeatureDownloadFromClouds(state) {
  return (
    state.user.Features && state.user.Features.download_from_clouds_allowed
  );
}

// #6259
export function getUserFeatureCanFullCopy(state) {
  return state.user.Features && state.user.Features.move_or_copy_to_clouds_full;
}

/*
// #6261
  Deprecate
  Use workspace.ACL
export function getUserFeatureExportChatHistory(state) {
  return state.user.Features && state.user.Features.export_chat_history;
}
*/
// #6281
export function getUserFeatureBandwidthLimit(state) {
  return state.user.Features && state.user.Features.bandwidth_limit > 0;
}

// #6281
/*
export function getUserFeatureShowDataFromLastBillingData(state) {
  return state.user.Features && state.user.Features.show_data_from_last_billing_data;
}
*/

// #6363

// DEPRECATED
// Use space.Features
/*
export function getUserFeatureShareFolderToSpace(state) {
  return state.user.Features && state.user.Features.share_folder_to_space;
}
*/

// #6420
export function getUserFeatureEncryption(state) {
  return state.user.Features && state.user.Features.encryption;
}

// #6577
export function getUserFeatureCopyFilesInChats(state) {
  return state.user.Features.keep_file_copy_single_spaces ? 1 : 2;
}
/*
export function getUsersCurrentPlan(state) {
  if (state.user.Subscription && state.user.Subscription.CurrentPlan) {
    return getPlanByName(state.user.Subscription.CurrentPlan);
  }
  return plans.Free;
}
*/
export function xxxisUserStandard(state) {
  if (state.user.Subscription && state.user.Subscription.CurrentPlan) {
    const plan = xxxgetPlanByName(state.user.Subscription.CurrentPlan);
    return xxxisStandard(plan);
  }
  return false;
}
/*
export function isUserAdvanced(state) {
  if (state.user.Subscription && state.user.Subscription.CurrentPlan) {
    const plan = getPlanByName(state.user.Subscription.CurrentPlan);
    return isAdvanced(plan);
  }
  return false;
}
*/
export function xxxisUserFree(state) {
  if (state.user.Subscription && state.user.Subscription.CurrentPlan) {
    return false;
  }
  return true;
}

export function hasTransfersSubscription(state) {
  try {
    return state.user.TransferSubscription;
  } catch (e) {
    return false;
  }
}
/*
export function getExtraBandwidthPercentage(state) {
  try {
    return state.user.TransferSubscription.ExtraBandwidthPercentage;
  } catch (e) {
    return 0;
  }
}
*/
/*
  2018-01-03
  All users can now transfer
*/
/*
export function canTransferWithoutCharge(state) {
  const plan = getUsersCurrentPlan(state);
  return isOld(plan);
}
*/
// #6285
export function getUserFeaturePublicLinkAutoExpiry(state) {
  return (
    state.user.Features &&
    state.user.Features.public_links_default_expiration_period
  );
}

export function getUserFeatureLinkSharing(state) {
  return (
    getUserFeatureControlExpire(state) && getUserFeatureControlPassword(state)
  );
}

export function getIsZapierConnected(state) {
  return state.user.LoggedInZapier;
}

export function getConfirmedMailsWithoutDefaultEmail(state) {
  const confirmedMails = state.user.RegisteredMailsConfirmed.slice();
  const userIdIndex = confirmedMails.indexOf(state.user.UserID);
  if (userIdIndex > -1) {
    confirmedMails.splice(userIdIndex, 1);
  }
  return confirmedMails;
}

export function getConfirmedMails(state) {
  return state.user.RegisteredMailsConfirmed;
}

export function getUnconfirmedMails(state) {
  return state.user.RegisteredMailsUnconfirmed;
}

export function hasReachedAppLimit(state) {
  const appLimit = getUserFeatureAppLimit(state);
  const integrations = getZaps(state);
  return appLimit > -1 && integrations.length >= appLimit;
}

export function getAllowBandwidthPurchase(state) {
  return state.user.Features && state.user.Features.allow_bandwidth_purchase;
}

export function getShowWelcomeScreen(state) {
  return state.user.ShowWelcomeScreen;
}

export function getWorkSpacesAppCueTourSeen(state) {
  return state.user.WorkSpacesAppCueTourSeen;
}

export function getUIProductArea(state) {
  return state.user.UIProductArea;
}

export function getChatSendMessageOnEnter(state) {
  return state.user.ChatSendMessageOnEnter;
}

export function getUsedBandwidthPercentage(state) {
  if (state.user.BandwidthLimit > 0) {
    return Math.round((state.user.Used / state.user.BandwidthLimit) * 100);
  }
  return 0;
}

export function isUserEmployee(state) {
  try {
    return state.user.AccountType === 'employee';
  } catch (e) {
    return false;
  }
}

export const hasBandwidth = createSelector([getUser], user => {
  try {
    return user.BandwidthLimit - user.Used > 0;
  } catch (e) {
    return false;
  }
});
