import { Formik, Field as FormikField } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as yup from 'yup';
import { Form, Header, Modal } from 'semantic-ui-react';
import { getDomain } from 'config/config';

function ElectronDomainChange({ intl }) {
  const [showDialog, setShowDialog] = useState(false);

  const onClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    const onShowSetDomainDialog = (event, newLocale) => {
      setShowDialog(true);
    };

    window.ipcRenderer?.on('show-set-domain-dialog', onShowSetDomainDialog);

    return () => {
      window.ipcRenderer?.removeListener(
        'domain-changed',
        onShowSetDomainDialog
      );
    };
  }, []);

  const urlValidation = intl.formatMessage({
    id: 'General.Form.Field.Url',
  });

  const schema = yup.object().shape({
    url: yup.string().url(urlValidation),
  });

  if (showDialog) {
    return (
      <Modal size="tiny" open onClose={onClose}>
        <Header>
          <div className="close_modal">
            <button className="close_modal_button" onClick={onClose} />
          </div>
        </Header>
        <Modal.Content>
          <p>
            <strong>
              <FormattedMessage id="ElectronDomainChange.Info" />
            </strong>
          </p>
          <Formik
            initialValues={{
              url: getDomain(),
            }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              await window.ipcRenderer?.invoke('setDomain', values.url);
              /**
               * Reload the app to ensure we get the new domain from storage
               */
              window.location.reload();
            }}
            render={({ errors, touched, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit} error={!!errors} size="huge">
                <Form.Field error={errors.url}>
                  <FormikField type="text" name="url" />
                  {touched.url && errors.url && <div>{errors.url}</div>}
                </Form.Field>
                <Form.Button
                  primary
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  size="huge"
                >
                  <FormattedMessage id="ElectronDomainChange.SaveButtonText" />
                </Form.Button>
              </Form>
            )}
          />
        </Modal.Content>
      </Modal>
    );
  }

  return null;
}

export default injectIntl(ElectronDomainChange);
