import { normalize } from 'normalizr';
import linkedItemSchema from './linkedItemSchema';

export const LINKEDITEM_SET_LOCKED = 'ENTITIES/LINKEDITEM/SET_LOCKED';
export const LINKED_ITEMS_LOADED = 'LINKEDITEMS/LOADED';
export const LOAD_LINKEDITEMS_SUCCESS = 'LINKEDITEMS/LOAD_SUCCESS';
export const LOAD_LINKEDITEMS_REQUEST = 'LINKEDITEMS/LOAD_REQUEST';
export const LOAD_LINKEDITEMS_ERROR = 'LINKEDITEMS/LOAD_ERROR';
export const DELETE_LINKEDITEM_SUCCESS = 'LINKEDITEM/DELETE_SUCCESS';
export const ADD_LINKEDITEMS_SUCCESS = 'LINKEDITEM/ADD_SUCCESS';
export const LINKEDITEM_STAR_ADDED = 'LINKEDITEM/STAR_ADDED';
export const LINKED_ITEM_MEMBER_FILTER_SELECTED =
  'LINKEDITEMS/MEMBER_FILTER_SELECTED';
export const LINKED_ITEM_TAG_FILTER_SELECTED =
  'LINKEDITEMS/TAG_FILTER_SELECTED';
export const LINKED_ITEM_FILTERS_CLEARED = 'LINKEDITEMS/FILTERS_CLEARED';

export const LINKED_ITEM_REMOVE_REQUEST = 'LINKEDITEM/REMOVE_REQUESTED';
export const LINKED_ITEM_REMOVE_ERROR = 'LINKEDITEM/REMOVE_ERROR';

export function removeLinkedItemRequest(ids) {
  return {
    type: LINKED_ITEM_REMOVE_REQUEST,
    ids,
  };
}

export function removeLinkedItemError(ids) {
  return {
    type: LINKED_ITEM_REMOVE_ERROR,
    ids,
  };
}

export function removeLinkedItems(ids, spaceId) {
  return {
    type: DELETE_LINKEDITEM_SUCCESS,
    ids,
    spaceId,
  };
}

export function linkedItemsLoaded(spaceId) {
  return {
    type: LINKED_ITEMS_LOADED,
    spaceId,
  };
}

export function loadLinkedItemsSuccess(linkedItems = [], channelId) {
  const normalizedLinkedItems = normalize(linkedItems, [linkedItemSchema]);
  return {
    type: LOAD_LINKEDITEMS_SUCCESS,
    linkedItemIds: normalizedLinkedItems.result || [],
    linkedItems: normalizedLinkedItems.entities.linkedItems || {},
    spaceId: channelId,
  };
}

export function addLinkedItems(linkedItemIds, linkedItems = {}, spaceId) {
  return {
    type: ADD_LINKEDITEMS_SUCCESS,
    linkedItemIds,
    linkedItems,
    spaceId,
  };
}

export function loadLinkedItemsError(spaceId) {
  return {
    type: LOAD_LINKEDITEMS_ERROR,
    spaceId,
  };
}

export function loadLinkedItemsRequest(spaceId) {
  return {
    type: LOAD_LINKEDITEMS_REQUEST,
    spaceId,
  };
}

export function setLinkedItemLocked(id, locked) {
  return {
    type: LINKEDITEM_SET_LOCKED,
    id,
    locked,
  };
}

export function linkedItemMemberFilterSelected(spaceId, memberId) {
  return {
    type: LINKED_ITEM_MEMBER_FILTER_SELECTED,
    spaceId,
    memberId,
  };
}

export function linkedItemTagFilterSelected(spaceId, linkedItemIds) {
  return {
    type: LINKED_ITEM_TAG_FILTER_SELECTED,
    spaceId,
    linkedItemIds,
  };
}

export function linkedItemFiltersCleared(spaceId) {
  return {
    type: LINKED_ITEM_FILTERS_CLEARED,
    spaceId,
  };
}

export function linkedItemStarAdded(id, starred) {
  return {
    type: LINKEDITEM_STAR_ADDED,
    id,
    starred,
  };
}
