import { THUMBNAIL } from '../../DataLayer/endpoints';

import { getOtixoApiUrl, shouldUseAuthToken } from 'config/config';
import { getTokenWithoutBearer } from 'Helpers/BrowserApi/localStorage';

export const THUMBNAIL_SIZES = {
  64: 64,
  200: 200,
  350: 350,
};

export const getThumbnailUrl = (
  id,
  size = THUMBNAIL_SIZES[64],
  useAuthToken = true
) => {
  const finalSize = Object.values(THUMBNAIL_SIZES).includes(size)
    ? size
    : THUMBNAIL_SIZES[64];
  const url = `${getOtixoApiUrl()}/${THUMBNAIL}?id=${id}&binary&size=${finalSize}`;
  if (shouldUseAuthToken() && useAuthToken) {
    const token = getTokenWithoutBearer();
    if (token) {
      return `${url}&token=${token}`;
    }
  }
  return url;
};
