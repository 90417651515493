export const truncateAround = (text = '', target = '', length) => {
  if (text.length <= length) {
    return text;
  }

  const targetIndex = text.toLowerCase().indexOf(target.toLowerCase());

  if (targetIndex === -1) {
    return `${text.substring(0, length)}...`;
  }

  if (target.length === 0) {
    return `${text.substring(0, length)}...`;
  }

  // we want our target in the middle, so
  // start and end should be half the length either
  // side of our target text
  const startIndex = targetIndex - length / 2;
  const endIndex = startIndex + target.length + length;

  const truncated = text.substring(
    //6, 12
    text.lastIndexOf(' ', startIndex), // don't truncate in the middle of a word
    endIndex
  );

  let newText = truncated;
  if (startIndex > 0) {
    newText = `...${newText}`;
  }

  if (endIndex < text.length) {
    newText = `${newText}...`;
  }

  return newText;
};
