import PropTypes from 'prop-types';
import React from 'react';
import { Button, Header, Modal, Message } from 'semantic-ui-react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import useAsyncFn from 'Hooks/useAsyncFn';
import acceptOffer from 'store/Workspaces/Offer/acceptOffer';
import { useDispatch } from 'react-redux';
import { offerPostponed } from 'store/Workspaces/Offer';
import { OFFERS } from 'DataLayer/Workspaces/Offers/config';

export function InviteMemberPromotionModal({
  workspaceId,
  onOfferAccepted = () => {},
}) {
  const dispatch = useDispatch();

  const [{ error, loading }, onAcceptClick] = useAsyncFn(async () => {
    await dispatch(acceptOffer(workspaceId, OFFERS.trial_test));
    onOfferAccepted();
  }, [workspaceId, dispatch]);

  const onMaybeLaterClick = () => {
    dispatch(offerPostponed(workspaceId, OFFERS.trial_test));
  };

  return (
    <Modal size="tiny" open>
      <Header>
        <div className="close_modal">
          <button className="close_modal_button" onClick={onMaybeLaterClick} />
        </div>
      </Header>
      <Modal.Content>
        <FormattedHTMLMessage id="InviteMemberPromotionModal.Message" />
        {error && (
          <Message error>
            <FormattedMessage id="InviteMemberPromotionModal.Error" />
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onMaybeLaterClick} disabled={loading}>
          <FormattedMessage id="InviteMemberPromotionModal.MaybeLaterButtonText" />
        </Button>
        <Button
          primary
          onClick={onAcceptClick}
          loading={loading}
          disabled={loading}
        >
          <FormattedMessage id="InviteMemberPromotionModal.AcceptOfferButtonText" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

InviteMemberPromotionModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onShowInviteMemberPromotionModal: PropTypes.func.isRequired,
};

export default InviteMemberPromotionModal;
