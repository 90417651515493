import * as Sentry from '@sentry/react';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  MEETING_INVITATION_ACCEPTED,
  meetingInvitationRejected,
  meetingStarting,
  meetingLeft,
  meetingEnded,
  MEETING_START_REQUESTED,
} from '.';
import {
  getIsMeetingActive,
  getActiveMeetingsObject,
} from 'store/Meeting/selectors';

/*
  if the user starts a meeting when a meeting is already in 
  progress, end the current meeting and start a new one.
*/
export function* meetingStartRequested(action) {
  try {
    const isMeetingRunning = yield select(getIsMeetingActive);
    const activeMeetings = yield select(getActiveMeetingsObject);

    // reject all other meeting invitations
    for (let roomId in activeMeetings) {
      if (roomId !== action.roomId) {
        yield put(meetingInvitationRejected(roomId));
      }
    }

    // hang up our existing meeting
    if (isMeetingRunning) {
      /* 
        the browser will block the popup if meetingHangUpRequested takes too long to complete
      */
      yield put(meetingLeft(action.roomId));
      yield put(meetingEnded());
    }
    yield put(
      meetingStarting(action.roomId, action.lobbyEnabled, action.password)
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export default function* activeMeetingsSaga() {
  yield takeLatest(
    [MEETING_INVITATION_ACCEPTED, MEETING_START_REQUESTED],
    meetingStartRequested
  );
}
