const OFFER_LOADED = 'OFFER_LOADED';
const OFFER_POSTPONED = 'OFFER_POSTPONED';
const TRIAL_AUTO_ACCEPTED = 'TRIAL_AUTO_ACCEPTED';

export const offerLoaded = (offers, workspaceId) => ({
  type: OFFER_LOADED,
  offers,
  workspaceId,
});

export const offerPostponed = (workspaceId, offerId) => ({
  type: OFFER_POSTPONED,
  workspaceId,
  offerId,
});

export const trialAutoAccepted = autoAccept => ({
  type: TRIAL_AUTO_ACCEPTED,
  autoAccept,
});

function offerReducer(
  state = {
    postponed: {},
    autoAcceptTrial: false,
  },
  action
) {
  switch (action.type) {
    case TRIAL_AUTO_ACCEPTED:
      return {
        ...state,
        autoAcceptTrial: action.autoAccept,
      };
    case OFFER_LOADED:
      return {
        ...state,
        [action.workspaceId]: action.offers,
      };
    case OFFER_POSTPONED:
      return {
        ...state,
        postponed: {
          ...state.postponed,
          [action.workspaceId]: {
            ...state.postponed[action.workspaceId],
            [action.offerId]: new Date(),
          },
        },
      };
    default:
      return state;
  }
}

export default offerReducer;
