import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Transition, Portal } from 'semantic-ui-react';

import { getLatestToasts } from 'store/Toast/selectors';
import { hideToast } from 'store/Toast/displayToast';

import styles from './ToastContainer.module.scss';
import Toast from 'Components/Toast/Toast';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

const ToastShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'loading']).isRequired,
  translationKey: PropTypes.string.isRequired,
  translationValues: PropTypes.object,
});

export function ToastContainer({ toasts, onHide }) {
  const isBackupMode = useIsBackupMode();

  if (isBackupMode) {
    return null;
  }

  return (
    <Portal open>
      <div className={styles.container}>
        <Transition.Group animation="fly down" duration="400">
          {toasts.map(toast => (
            <div key={toast.id}>
              <Toast toast={toast} onHide={onHide} />
            </div>
          ))}
        </Transition.Group>
      </div>
    </Portal>
  );
}

ToastContainer.propTypes = {
  toasts: PropTypes.arrayOf(ToastShape).isRequired,
  onHide: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    toasts: getLatestToasts(state),
  }),
  {
    onHide: hideToast,
  }
)(ToastContainer);
