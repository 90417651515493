import { clear } from 'DataLayer/Reminders/remove';
import { remindersCleared } from './index';

export const clearReminders = () => dispatch => {
  return clear().then(() => {
    dispatch(remindersCleared());
  });
};

export default clearReminders;
