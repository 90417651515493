import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import { loadMediaInfo } from 'store/MediaInfo/loadMediaInfo';
import {
  getMediaInfoById,
  getMediaInfoErrorById,
} from 'store/MediaInfo/selectors';

import PREVIEWER_COMPONENTS from './allPreviewers';
import PreviewUnavailable from './PreviewUnavailable';

class Preview extends Component {
  load = () => {
    const SpecificPreviewer = this.getPreviewer();
    if (SpecificPreviewer) {
      this.props.dispatch(
        loadMediaInfo(this.props.resourceId, this.props.spaceId)
      );
    }
  };

  getPreviewer = () => PREVIEWER_COMPONENTS[this.props.resourceType];

  render() {
    const SpecificPreviewer = this.getPreviewer() || PreviewUnavailable;
    const src = this.props.mediaInfo && this.props.mediaInfo.url;
    return (
      <Waypoint onEnter={this.load}>
        <span>
          <SpecificPreviewer
            notificationId={this.props.notificationId}
            id={this.props.resourceId}
            channelId={this.props.spaceId}
            size={this.props.resourceSize}
            resourceName={this.props.resourceName}
            type={this.props.resourceType}
            src={src}
            openPreview={this.props.openPreview}
            error={this.props.mediaInfoError}
          />
        </span>
      </Waypoint>
    );
  }
}

Preview.propTypes = {
  notificationId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceSize: PropTypes.number,
  spaceId: PropTypes.string.isRequired,
  openPreview: PropTypes.func.isRequired,
  mediaInfo: PropTypes.object,
  mediaInfoError: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect((state, props) => ({
  mediaInfo: getMediaInfoById(state, props.resourceId),
  mediaInfoError: getMediaInfoErrorById(state, props.resourceId),
}))(Preview);
