import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RightSidebar from 'Components/RightSidebar/RightSidebar';
import { getFutureRemindersByWorkspaceId } from 'store/Reminders/selectors';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { TimeSquareIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

function ReminderButton({ isActive, onClick, futureReminders }) {
  const numberOfReminders = futureReminders.length;
  return (
    <DisableInBackupMode>
      <RightSidebar.Button
        openTooltip="SpaceSidebarButtons.RemindersButton.Hide"
        closeTooltip="SpaceSidebarButtons.RemindersButton.Show"
        open={isActive}
        onClick={onClick}
      >
        <WeTeamIcon Icon={TimeSquareIcon} active={isActive} />
        {numberOfReminders > 0 && <small>{numberOfReminders}</small>}
      </RightSidebar.Button>
    </DisableInBackupMode>
  );
}

ReminderButton.propTypes = {
  futureReminders: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default connect((state, props) => ({
  futureReminders: getFutureRemindersByWorkspaceId(state, props.workspaceId),
}))(ReminderButton);
