import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getUnreadAllSpaceNotificationsCount } from 'store/Notifications/selectors';

export const PRODUCT_TITLE = {
  TEAMS: 'We.Team',
  MEET: 'We.Team Meet',
};

export function DocumentTitle({ product = PRODUCT_TITLE.TEAMS, count }) {
  useEffect(() => {
    if (count > 0) {
      document.title = `(${count}) ${product}`;
    } else {
      document.title = product;
    }
  }, [product, count]);

  return null;
}

DocumentTitle.propTypes = {
  count: PropTypes.number.isRequired,
  product: PropTypes.oneOf([PRODUCT_TITLE.TEAMS, PRODUCT_TITLE.MEET]),
};

export default connect(state => ({
  count: getUnreadAllSpaceNotificationsCount(state),
}))(DocumentTitle);
