import PropTypes from 'prop-types';
import React from 'react';

import ElementPositionTracker from '../../ScrollPositionTracker/ElementPositionTracker';
import { Label } from 'semantic-ui-react';
import { useIsChannelUnread } from 'Components/Spaces/useIsChannelUnread';

const UnreadEventsBadge = ({ spaceId }) => {
  const { manuallyMarkedAsUnread, numberOfNotifications } =
    useIsChannelUnread(spaceId);

  if (numberOfNotifications > 0) {
    return (
      <ElementPositionTracker id={spaceId} number={numberOfNotifications}>
        <span>
          <Label circular color="red" size="tiny" className="ml-2">
            {numberOfNotifications > 0 ? numberOfNotifications : ''}
          </Label>
        </span>
      </ElementPositionTracker>
    );
  }

  if (manuallyMarkedAsUnread) {
    return <Label circular color="red" size="tiny" className="ml-2" />;
  }

  return null;
};

UnreadEventsBadge.propTypes = {
  spaceId: PropTypes.string.isRequired,
};

export default UnreadEventsBadge;
