import React from 'react';
import PropTypes from 'prop-types';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import ColorPresets from 'Components/Avatar/ColorPresets';

const TagColorPicker = ({ className, onColorSelected, selectedColor }) => {
  const [color, setColor] = useColor('hex', selectedColor);

  return (
    <>
      <ColorPresets
        onColorSelected={onColorSelected}
        selectedColor={selectedColor}
      />
      <ColorPicker
        height={60}
        width={360}
        color={color}
        hideHSV
        hideHEX
        hideRGB
        onChange={color => {
          setColor(color);
          onColorSelected(color.hex);
        }}
        dark
      />
    </>
  );
};

TagColorPicker.propTypes = {
  onColorSelected: PropTypes.func.isRequired,
  selectedColor: PropTypes.string,
};

export default TagColorPicker;
