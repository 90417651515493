import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import useAsyncFn from 'Hooks/useAsyncFn';
import acceptOffer from 'store/Workspaces/Offer/acceptOffer';
import { useDispatch, useSelector } from 'react-redux';
import { offerPostponed, trialAutoAccepted } from 'store/Workspaces/Offer';
import { isTrialAutoAccepted } from 'store/Workspaces/Offer/selectors';

export function useAcceptOffer(workspaceId, offer, onAccepted) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);
  const queryClient = useQueryClient();

  const isAutoAccepted = useSelector(state => isTrialAutoAccepted(state));

  const [{ error, loading }, accept] = useAsyncFn(async () => {
    await dispatch(acceptOffer(workspaceId, offer));
    queryClient.invalidateQueries(['offers/active', workspaceId]);
    setVisible(false);
    setIsAccepted(true);
    dispatch(trialAutoAccepted(false));
    onAccepted();
  }, [workspaceId, setVisible, dispatch]);

  const postpone = () => {
    dispatch(offerPostponed(workspaceId, offer));
  };

  useEffect(() => {
    if (isAutoAccepted) {
      accept();
    }
  }, [accept, isAutoAccepted]);

  useEffect(() => {
    if (!isAutoAccepted) {
      setVisible(true);
    }
  }, [isAutoAccepted]);

  return {
    postpone,
    accept,
    error,
    loading,
    visible,
    isAutoAccepted,
    isAccepted,
  };
}
