import * as Sentry from '@sentry/react';
import {
  callEndedAnalyticsEvent,
  callJoinedAnalyticsEvent,
  callLeftAnalyticsEvent,
  callStartedAnalyticsEvent,
} from 'Components/GoogleAnalytics/events';
import {
  MEETING_ENDED,
  MEETING_JOINED,
  MEETING_LEFT,
  MEETING_START_REQUESTED,
} from 'store/Meeting';

export const analyticsMiddleware = store => next => action => {
  try {
    switch (action.type) {
      case MEETING_START_REQUESTED: {
        callStartedAnalyticsEvent();
        break;
      }
      case MEETING_ENDED: {
        callEndedAnalyticsEvent();
        break;
      }
      case MEETING_LEFT: {
        callLeftAnalyticsEvent();
        break;
      }
      case MEETING_JOINED: {
        callJoinedAnalyticsEvent();
        break;
      }
      default:
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }

  return next(action);
};
