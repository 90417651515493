import axios from 'axios';
import { isFeatureEnabled } from 'config/features';
import { RESOURCES } from 'DataLayer/endpoints';
import { resourceFields, linkedResourcefields } from './fields';
import process from './process';

import {
  saveOne,
  getByParentId as getFromDb,
  saveMany as saveToDb,
} from 'DataLayer/database/resources';
import { FEATURES } from 'config/features.config';

export async function byId(id, includeComments) {
  const { data } = await axios.get(
    `${RESOURCES}/${id}?fields=${
      includeComments ? linkedResourcefields : resourceFields
    }`
  );
  const resourceFinal = process.single(data);
  saveOne(resourceFinal);
  return resourceFinal;
}

export function getPathById(id) {
  return axios
    .get(`${RESOURCES}/${id}/path?fields=${resourceFields}`)
    .then(response => response.data.Path)
    .then(path => process.all(path));
}

export async function getFromCache(id, currentPage, workspaceId) {
  if (isFeatureEnabled(FEATURES.CLIENT_CACHING, workspaceId)) {
    const { resources, nextPage } = await getFromDb(id, currentPage);
    return {
      resources,
      next: nextPage,
    };
  }
  return Promise.resolve(null);
}

export async function getChildrenByIdFromNetwork(
  id,
  nextPage,
  includeComments,
  channelId
) {
  let nextPageQuery = '';
  if (nextPage) {
    nextPageQuery = `&next=${nextPage}`;
  }

  let fields = resourceFields;
  if (includeComments) {
    fields = linkedResourcefields;
  }

  let url = `${RESOURCES}/${id}/children?ChannelId=${channelId}&fields=${fields}${nextPageQuery}`;

  if (id === 'ROOT_RESOURCES') {
    url = `${RESOURCES}/children?fields=${fields}${nextPageQuery}`;
  }

  const { data } = await axios.get(url);
  const processedData = process.all(data.Items);

  saveToDb(processedData, id, nextPage, data.Next);

  return {
    resources: processedData,
    next: data.Next,
  };
}
export async function getChildrenById({
  id,
  next,
  includeComments,
  channelId,
  workspaceId,
}) {
  const cachedResources = await getFromCache(id, next, workspaceId);
  const fetchedPromise = getChildrenByIdFromNetwork(
    id,
    next,
    includeComments,
    channelId
  );

  return { cachedResources, fetchedPromise };
}
