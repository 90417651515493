import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { Dropdown, Popup } from 'semantic-ui-react';
import { LOGOUT } from 'pages';
import { getUser } from 'store/User/selectors';
import loadUser from 'store/User/loadUser';
import Avatar from 'Components/Avatar/Avatar';

import { getMarketingDownloadsUrl, isDesktopApp } from 'config/config';
import { getLocale } from 'store/Locale/selectors';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { ChartIcon } from 'Components/Icons/WeTeamIcon';
import { LogoutIcon } from 'Components/Icons/WeTeamIcon';

import styles from './UserAvatarMenu.module.scss';
import AccountSettingsMenuItems from 'Components/Pages/AccountSettingsMenuItems';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

class UserAvatarMenu extends React.Component {
  loadUser = () => {
    this.props.dispatch(loadUser());
  };

  render() {
    const { user, pointing } = this.props;
    return (
      <DisableInBackupMode>
        <Dropdown
          className="flex align-items-center"
          icon={null}
          trigger={
            <div className="flex align-items-center">
              <Popup
                position="bottom right"
                inverted
                trigger={
                  <span>
                    <Avatar
                      initials={user.Initials}
                      src={user.AvatarUrl}
                      color={user.Color}
                      noRightMargin
                      circular
                    />
                  </span>
                }
                content={<FormattedMessage id="UserAvatarMenu.Tooltip" />}
                size="mini"
              />
            </div>
          }
          pointing={pointing || 'top left'}
          onOpen={this.loadUser}
        >
          <Dropdown.Menu className={styles.menuItems}>
            <AccountSettingsMenuItems showIcon />
            {!isDesktopApp() && (
              <a
                className="item"
                href={getMarketingDownloadsUrl(this.props.locale)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <WeTeamIcon Icon={ChartIcon} />
                <FormattedMessage id="Username.Apps" />
              </a>
            )}
            <Link className="item" to={LOGOUT}>
              <WeTeamIcon Icon={LogoutIcon} />
              <FormattedMessage id="Username.SignOut" />
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </DisableInBackupMode>
    );
  }
}

UserAvatarMenu.propTypes = {
  user: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  pointing: PropTypes.string.isRequired,
};

UserAvatarMenu.defaultProps = {
  user: {},
};

export default connect(state => ({
  user: getUser(state),
  locale: getLocale(state),
}))(UserAvatarMenu);
