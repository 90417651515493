import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import LinkedItem from 'Components/LinkedItem/LinkedItem';
import Notification from 'Components/Notification/Notification';
import { REMINDER_TYPE } from 'DataLayer/Reminders/get';
import Comment from 'Components/Comment/Comment';

import styles from './ReminderTarget.module.css';
import { FormattedMessage } from 'react-intl';

function ReminderTarget({
  target,
  channelId,
  reminderType,
  showWorkspaceName,
  showAvatar,
  canClick = true,
  present = true,
}) {
  if (!target) {
    return null;
  }
  return (
    <Segment className={styles.reminderTarget}>
      {(reminderType === REMINDER_TYPE.linkedItem ||
        reminderType === REMINDER_TYPE.resource) && (
        <LinkedItem
          linkedItem={target}
          showWorkspaceName={showWorkspaceName}
          showMetaInformation={false}
          canClick={canClick}
        />
      )}
      {reminderType === REMINDER_TYPE.notification && (
        <Notification
          notification={target.Notification}
          showWorkspaceName={showWorkspaceName}
          showAvatar={showAvatar}
          showMetaInformation={false}
          canClick={canClick}
        />
      )}
      {reminderType === REMINDER_TYPE.comment &&
        target.Notification &&
        target.Notification.Comment && (
          <Comment
            comment={target.Notification.Comment}
            showAvatar={showAvatar}
            channelId={channelId}
            canClick={canClick}
          />
        )}
      {!present && (
        <div className="mt-2 light-text">
          <em>
            <small>
              <FormattedMessage id="Reminder.RemovedSinceCreation" />
            </small>
          </em>
        </div>
      )}
    </Segment>
  );
}

ReminderTarget.propTypes = {
  target: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
  reminderType: PropTypes.oneOf([
    REMINDER_TYPE.linkedItem,
    REMINDER_TYPE.resource,
    REMINDER_TYPE.notification,
    REMINDER_TYPE.comment,
  ]),
  showWorkspaceName: PropTypes.bool,
  showAvatar: PropTypes.bool,
  canClick: PropTypes.bool,
  present: PropTypes.bool,
};

export default ReminderTarget;
