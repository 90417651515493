import React from 'react';
import PropTypes from 'prop-types';

const PrivateChatIcon = ({ fill = '#757575', ...rest }) => (
  <svg viewBox="0 0 800 800" width={30} height={30} {...rest}>
    <path
      d="M399.58 58.48c46.08 0 90.74 9.02 132.76 26.8 40.61 17.19 77.1 41.8 108.45 73.17 31.35 31.36 55.96 67.87 73.14 108.51C731.7 309 740.72 353.7 740.72 399.8s-9.01 90.8-26.79 132.85c-17.18 40.64-41.79 77.15-73.14 108.51s-67.84 55.98-108.45 73.17c-42.02 17.78-86.68 26.8-132.76 26.8s-90.74-9.02-132.76-26.8c-40.62-17.19-77.1-41.8-108.45-73.17-31.35-31.36-55.96-67.87-73.14-108.51-17.78-42.04-26.79-86.74-26.79-132.85s9.01-90.8 26.79-132.85c17.18-40.64 41.79-77.15 73.14-108.51s67.84-55.98 108.45-73.17c42.01-17.77 86.68-26.79 132.76-26.79m0-58C179.14.48.44 179.26.44 399.8s178.7 399.32 399.14 399.32S798.72 620.34 798.72 399.8 620.01.48 399.58.48z"
      fill={fill}
    />

    <path
      d="M528.94 301.31c0 75.26-56.88 139.56-129.52 139.56S270.2 376.56 270.2 301.31s56.58-137.56 129.22-137.56 129.52 62.3 129.52 137.56zM118.47 642.3s50.51-143.55 284.43-143.55c229.94 0 277.79 144.87 277.79 144.87l-29.24 54.49-51.6 39.93L460.9 788.5l-58.41 2.65-76.99-6.19-77.88-24.78-92.92-61.06-36.23-56.82z"
      fill={fill}
    />
  </svg>
);

PrivateChatIcon.propTypes = {
  fill: PropTypes.string,
};

export default PrivateChatIcon;
