import getInitials from 'Helpers/Strings/getInitials';

export default function process(collaborator, spaceId) {
  collaborator.id = `${collaborator.Email}:${spaceId}`;

  collaborator.SpaceId = spaceId;

  if (collaborator.FirstName) {
    collaborator.Name = `${collaborator.FirstName} ${collaborator.LastName}`;
  } else {
    collaborator.Name = collaborator.Email;
  }

  collaborator.Initials = getInitials(
    collaborator.FirstName,
    collaborator.LastName,
    collaborator.Email
  );

  //TODO: Change this
  //collaborator.Status = 'PENDING_APPROVAL';

  switch (collaborator.Status) {
    case 'INVITED':
      collaborator.Pending = true;
      break;
    case 'LEFT':
      collaborator.Left = true;
      break;
    case 'PENDING_APPROVAL':
      collaborator.PendingApproval = true;
      break;
    default:
  }

  //collaborator.AvatarUrl = 'https://wiesmann.codiferes.net/share/bitmaps/test_pattern.svg';

  return collaborator;
}
