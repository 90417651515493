import updateUserRest, {
  updateAvatar,
  update as updateRest,
} from 'DataLayer/Me/update';
import { setLocale } from '../Locale/setLocale';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

import { setRequestInProgress } from '../Requests/setRequestInProgress';
import * as requestTypes from '../Requests/requestTypes';
import { teamspaceIdAddedAnalyticsEvent } from 'Components/GoogleAnalytics/events';

export const UPDATE_USER_SUCCESS = 'USER/UPDATE_SUCCESS';
export const ADD_USER_MAILS_SUCCESS = 'USER/ADD_MAILS_SUCCESS';
export const DELETE_USER_MAILS_SUCCESS = 'USER/DELETE_MAILS_SUCCESS';

export const PRODUCT_AREA = {
  Collaboration: 'collaboration',
  Clouds: 'clouds',
  PrivateChat: 'privatechat',
};

const userUpdateSuccess = user => dispatch => {
  dispatch({
    type: UPDATE_USER_SUCCESS,
    user,
  });
  dispatch(setLocale(user.Language));
  dispatch(displaySuccessToast('toast.updateUser.success'));
  dispatch(setRequestInProgress(requestTypes.USER_SAVE, false));
};

const userUpdateError = () => dispatch => {
  dispatch(setRequestInProgress(requestTypes.USER_SAVE, false));
  dispatch(displayErrorToast('toast.updateUser.error'));
};

export const update = user => dispatch => {
  dispatch(setRequestInProgress(requestTypes.USER_SAVE, true));

  return updateRest(user).then(
    newUser => {
      dispatch(userUpdateSuccess(newUser));
    },
    error => {
      dispatch(userUpdateError());
      throw error;
    }
  );
};

export const updateProfilePicture = (url, color) => dispatch => {
  dispatch(setRequestInProgress(requestTypes.USER_SAVE, true));

  return updateAvatar(url, color).then(
    newUser => {
      dispatch(userUpdateSuccess(newUser));
    },
    error => {
      dispatch(userUpdateError());
      throw error;
    }
  );
};

export const updateUIProductArea = area => () => {
  updateRest({
    uiProductArea: area,
  });
};

export const updateChatSendMessageOnEnter =
  sendMessageOnEnter => async dispatch => {
    const user = await updateRest({
      chatSendMessageOnEnter: sendMessageOnEnter,
    });

    dispatch({
      type: UPDATE_USER_SUCCESS,
      user,
    });
  };

export const updateWorkSpacesAppCueTourSeen =
  workSpacesAppCueTourSeen => async dispatch => {
    const user = await updateRest({
      workSpacesAppCueTourSeen: workSpacesAppCueTourSeen,
    });

    dispatch({
      type: UPDATE_USER_SUCCESS,
      user,
    });
  };

export const updateShowWelcomeScreen = showWelcomeScreen => () => {
  updateRest({
    showWelcomeScreen: showWelcomeScreen,
  });
};

export const addUserMail = userEmail => dispatch =>
  updateUserRest.addUserEmail(userEmail).then(() => {
    teamspaceIdAddedAnalyticsEvent();
    dispatch({
      type: ADD_USER_MAILS_SUCCESS,
      userEmail,
    });
    dispatch(displaySuccessToast('toast.addUserMail.success'));
  });

export const deleteUserMail = (userEmail, isConfirmed) => dispatch =>
  updateUserRest.deleteUserEmail(userEmail).then(() => {
    dispatch({
      type: DELETE_USER_MAILS_SUCCESS,
      userEmail,
      isConfirmed,
    });
    dispatch(displaySuccessToast('toast.deleteUserMail.success'));
  });

export const resendUserMailConfirmation = userEmail => dispatch => {
  dispatch(setRequestInProgress(requestTypes.USER_SAVE, true));
  return updateUserRest.confirmUserEmail(userEmail).then(
    () => {
      dispatch(setRequestInProgress(requestTypes.USER_SAVE, false));
      dispatch(displaySuccessToast('toast.confirmUserMail.success'));
    },
    () => {
      dispatch(setRequestInProgress(requestTypes.USER_SAVE, false));
      dispatch(displayErrorToast('toast.confirmUserMail.error'));
    }
  );
};
