import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import DownloadIframe from './DownloadIframe';

const DownloadRoot = ({ downloads }) => (
  <div style={{ display: 'none' }}>
    {downloads.map(download => (
      <DownloadIframe src={download.url} key={download.id} />
    ))}
  </div>
);

DownloadRoot.propTypes = {
  downloads: PropTypes.array.isRequired,
};

export default connect(state => ({
  downloads: state.downloads,
}))(DownloadRoot);
