import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

function RightSidebarPanelContent({ children, className }) {
  return (
    <div
      className={classNames(className, 'flex column flex-auto min-height-0')}
    >
      {children}
    </div>
  );
}
RightSidebarPanelContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RightSidebarPanelContent;
