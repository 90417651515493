/* eslint-disable no-console */
import orderBy from 'lodash/orderBy';
import * as Sentry from '@sentry/react';
import { getbyWorkspaceId as getChannelsByWorkspaceId } from 'DataLayer/Spaces/get';
import { getNotifications, SET_TYPE } from 'DataLayer/Notifications/get';
import { getBySpaceId } from 'DataLayer/LinkedItems/get';
import { getChildrenById } from 'DataLayer/Resources/get';
import { getComments } from 'DataLayer/Spaces/Comments/get';
import { get as getMediaInfo } from 'DataLayer/MediaInfo/get';
import { getThumbnailUrl, THUMBNAIL_SIZES } from 'DataLayer/Thumbnail/get';
import { isImagePreview, PDF } from 'DataLayer/Resources/Helpers/contentTypes';
import { getFromNetwork as getProtectedFileFromNetwork } from 'DataLayer/MediaInfo/protected/get';
import { fetchNotes } from 'DataLayer/Notes/get';

export class MakeAvailableOffline {
  constructor(backupFolder, workspaceId, progressCallback, addToLog) {
    this.workspaceId = workspaceId;
    this.backupFilesFolder = backupFolder;
    this.progressCallback = progressCallback;
    this.addToLog = addToLog;
  }

  /**
   * All requests are made sequentially so we don't flood the network
   */
  async start() {
    try {
      // get data here
      console.group('makeAvailableOffline');

      const { fetchedPromise } = await getChannelsByWorkspaceId(
        this.workspaceId
      );
      const channels = await fetchedPromise;
      for (const [index, channel] of channels.entries()) {
        this.progressCallback(((index + 1) / channels.length) * 100);
        console.group(channel.Name);
        await this.fetchNotifications(channel.Id);
        await this.fetchLinkedItems(channel.Id);
        await this.fetchNotesFromNetwork(channel.Id);
        console.groupEnd();
      }
      console.groupEnd();
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchComments(channelId, resourceId) {
    try {
      const { fetchedPromise } = await getComments(channelId, resourceId);
      const comments = await fetchedPromise;
      console.log('fetchComments', comments);
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchThumbnails(resourceId) {
    try {
      console.log('fetchThumbnail', resourceId);
      for (const size in THUMBNAIL_SIZES) {
        await getProtectedFileFromNetwork(
          getThumbnailUrl(resourceId, THUMBNAIL_SIZES[size], false),
          this.backupFilesFolder
        );
      }
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchMediaInfo(channelId, resourceId) {
    try {
      const { fetchedPromise } = await getMediaInfo(resourceId, channelId);
      const mediaInfo = await fetchedPromise;
      console.log('fetchMediaInfo', mediaInfo);
      return mediaInfo?.url;
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchProtectedFile(url) {
    try {
      await getProtectedFileFromNetwork(url, this.backupFilesFolder);
      console.log('fetchProtectedFile', url);
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchFileMeta(channelId, id, type) {
    try {
      await this.fetchComments(channelId, id);
      const url = await this.fetchMediaInfo(channelId, id);
      await this.fetchProtectedFile(url);
      const isImage = isImagePreview(type);
      if (isImage || type === PDF) {
        await this.fetchThumbnails(id);
      }
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchNotesFromNetwork(channelId) {
    try {
      const notes = await fetchNotes(channelId);
      console.log('fetchNotesFromNetwork', notes);
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchLinkedItems(channelId) {
    try {
      const { fetchedPromise } = await getBySpaceId(channelId);
      const linkedItems = await fetchedPromise;
      console.log(linkedItems);
      for (const linkedItem of linkedItems) {
        if (linkedItem.Resource.Folder) {
          console.group('folder (linked)', linkedItem.Resource.Name);
          await this.fetchResources(channelId, linkedItem.Resource.Id);
          console.groupEnd();
        } else {
          console.group('file (linked)', linkedItem.Resource.Name);
          this.addToLog(
            `${linkedItem.Resource.Name} (${linkedItem.Resource.SizeFormatted})`
          );
          await this.fetchFileMeta(
            channelId,
            linkedItem.Resource.Id,
            linkedItem.Resource.Type
          );
          console.groupEnd();
        }
      }
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchResources(channelId, parentId, nextPageId) {
    try {
      const { fetchedPromise } = await getChildrenById({
        id: parentId,
        next: nextPageId,
        channelId: channelId,
      });
      const { next, resources } = await fetchedPromise;
      /**
       * Get all pages for this folder
       */
      if (next) {
        await this.fetchResources(channelId, parentId, next);
      }

      /**
       * check for child folders
       */
      for (const resource of resources) {
        if (resource.Folder) {
          console.group('folder', resource.Name);
          await this.fetchResources(channelId, resource.Id);
          console.groupEnd();
        } else {
          console.group('file', resource.Name);
          this.addToLog(`${resource.Name} (${resource.SizeFormatted})`);
          await this.fetchFileMeta(channelId, resource.Id, resource.Type);
          console.groupEnd();
        }
      }
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }

  async fetchNotifications(
    channelId,
    notificationId,
    notificationTimestamp,
    setType
  ) {
    try {
      const { fetchedPromise } = await getNotifications({
        channelId,
        notificationId,
        setType,
        notificationTimestamp,
      });
      const notifications = await fetchedPromise;
      console.log('notifications');
      console.log(notifications);
      /**
       * Recusively get older messages
       */
      if (notifications.HasOlderMessages) {
        const notificationsOrdered = orderBy(
          notifications.Notifications,
          'NotificationTimestamp'
        );
        const oldestNotification = notificationsOrdered.slice(0)[0];
        await this.fetchNotifications(
          channelId,
          oldestNotification.Id,
          oldestNotification.NotificationTimestamp,
          SET_TYPE.before
        );
      }
    } catch (ex) {
      console.error(ex);
      Sentry.captureException(ex);
    }
  }
}
