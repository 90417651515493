import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getThumbnailUrl, THUMBNAIL_SIZES } from 'DataLayer/Thumbnail/get';

import ThumbnailIcon from 'Components/Thumbnail/ThumbnailIcon';

import useGetProtectedFileBlobUrl from 'Hooks/files/useGetProtectedFileBlobUrl';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

function ThumbnailImage({ id, size, type }) {
  const isBackupMode = useIsBackupMode();
  const { blobUrl, loading, fetchFile } = useGetProtectedFileBlobUrl(
    getThumbnailUrl(id, THUMBNAIL_SIZES[200], !isBackupMode),
    {
      asBlob: true,
      cacheEnabled: true,
    }
  );

  useEffect(() => {
    fetchFile();
  }, [id, fetchFile]);

  if (loading || !blobUrl) {
    return <ThumbnailIcon size={size} type={type} />;
  }
  return <img src={blobUrl} alt="media-info" />;
}

ThumbnailImage.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default memo(ThumbnailImage);
