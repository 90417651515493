import generator from '../../Helpers/Strings/generator';
import { displaySuccessToast } from '../Toast/displayToast';

export const DOWNLOAD_ADDED = 'DOWNLOAD/Added';

export const addDownload = url => dispatch => {
  dispatch(displaySuccessToast('toast.addDownload.downloadStarting'));
  dispatch({
    type: DOWNLOAD_ADDED,
    id: generator(8),
    url,
  });
};

export default addDownload;
