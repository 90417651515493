import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeGetChatCollaborator } from 'store/Spaces/Collaborators/selectors';
import Avatar from 'Components/Collaborators/Avatar';

const style = { marginRight: 10 };
class SingleSpaceIcon extends React.PureComponent {
  render() {
    const { showStatus, width, height, collaborator } = this.props;

    return (
      <Avatar
        avatarUrl={collaborator.AvatarUrl}
        color={collaborator.Color}
        email={collaborator.Email}
        firstName={collaborator.FirstName}
        lastName={collaborator.LastName}
        presence={collaborator.Presence}
        showStatus={showStatus}
        width={width}
        height={height}
        style={style}
      />
    );
  }
}

SingleSpaceIcon.propTypes = {
  collaborator: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  showStatus: PropTypes.bool,
};

const makeMapStateToProps = () => {
  const getCollaboratorBySpaceId = makeGetChatCollaborator();
  const mapStateToProps = (state, props) => ({
    collaborator: getCollaboratorBySpaceId(state, props.spaceId),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(SingleSpaceIcon);
