import { post } from 'DataLayer/Analytics/post';
import {
  getGoogleAnalyticsMeasurementId,
  isDesktopApp,
  isNodeEnvDevelopment,
  isOtixoEnvProduction,
} from 'config/config';
import ReactGA from 'react-ga4';

export const initGoogleAnalytics = () => {
  if (isDesktopApp()) {
    return;
  }
  ReactGA.initialize(getGoogleAnalyticsMeasurementId());
};

export const signInAnalyticsEvent = label =>
  sendAnalyticsEvent('user', 'user_login', label);

export const signOutAnalyticsEvent = label =>
  sendAnalyticsEvent('user', 'user_logout', label);

export const accountDeletedAnalyticsEvent = label =>
  sendAnalyticsEvent('user', 'user_account_deleted', label);

export const signUpAnalyticsEvent = label =>
  sendAnalyticsEvent('user', 'user_signup_completed', label);

export const passwordResetRequestedAnalyticsEvent = label =>
  sendAnalyticsEvent('user', 'user_password_reset_requested', label);

export const passwordResetCompleteAnalyticsEvent = label =>
  sendAnalyticsEvent('user', 'user_password_reset_completed', label);

export const teamspaceCreatedAnalyticsEvent = label =>
  sendAnalyticsEvent('teamspace', 'teamspace_created', label);

export const teamspaceDeletedAnalyticsEvent = label =>
  sendAnalyticsEvent('teamspace', 'teamspace_deleted', label);

export const teamspaceArchivedAnalyticsEvent = label =>
  sendAnalyticsEvent('teamspace', 'teamspace_archived', label);

export const teamspaceUnarchivedAnalyticsEvent = label =>
  sendAnalyticsEvent('teamspace', 'teamspace_unarchived', label);

export const teamspaceIdAddedAnalyticsEvent = label =>
  sendAnalyticsEvent('teamspace', 'teamspace_id_added', label);

export const memberInvitedToTeamspaceAnalyticsEvent = label =>
  sendAnalyticsEvent('teamspace', 'teamspace_member_added', label);

export const teamspaceMemberRemovedAnalyticsEvent = label =>
  sendAnalyticsEvent('teamspace', 'teamspace_member_removed', label);

export const channelCreatedAnalyticsEvent = label =>
  sendAnalyticsEvent('channels', 'channel_created', label);

export const channelDeletedAnalyticsEvent = label =>
  sendAnalyticsEvent('channels', 'channel_deleted', label);

export const messageSentAnalyticsEvent = label =>
  sendAnalyticsEvent('chat', 'chat_message_sent', label);

export const chatMessageReadAnalyticsEvent = label =>
  sendAnalyticsEvent('chat', 'chat_message_read', label);

export const chatMessageDeletedAnalyticsEvent = label =>
  sendAnalyticsEvent('chat', 'chat_message_deleted', label);

export const chatMessageRepliedAnalyticsEvent = label =>
  sendAnalyticsEvent('chat', 'chat_message_replied', label);

export const chatMessageStarredAnalyticsEvent = label =>
  sendAnalyticsEvent('chat', 'chat_message_stared', label);

export const chatMessageRemindedAnalyticsEvent = label =>
  sendAnalyticsEvent('chat', 'chat_message_reminded', label);

export const callStartedAnalyticsEvent = label =>
  sendAnalyticsEvent('meetings', 'videocall_started', label);

export const callEndedAnalyticsEvent = label =>
  sendAnalyticsEvent('meetings', 'videocall_ended', label);

export const callJoinedAnalyticsEvent = label =>
  sendAnalyticsEvent('meetings', 'videocall_joined', label);

export const callLeftAnalyticsEvent = label =>
  sendAnalyticsEvent('meetings', 'videocall_left', label);

export const fileAddedAnalyticsEvent = label =>
  sendAnalyticsEvent('files', 'file_added', label);

export const fileDownloadedAnalyticsEvent = label =>
  sendAnalyticsEvent('files', 'file_downloaded', label);

export const fileDeletedAnalyticsEvent = label =>
  sendAnalyticsEvent('files', 'file_deleted', label);

export const fileSharedAnalyticsEvent = label =>
  sendAnalyticsEvent('files', 'file_shared', label);

export const fileCommentedAnalyticsEvent = label =>
  sendAnalyticsEvent('files', 'file_commented', label);

export const teamspaceUpgradedAnalyticsEvent = label =>
  sendAnalyticsEvent('subscription', 'teamspace_upgraded', label);

export const teamspaceDowngradedAnalyticsEvent = label =>
  sendAnalyticsEvent('subscription', 'teamspace_downgraded', label);

export const teamspaceCreditCardChangedAnalyticsEvent = label =>
  sendAnalyticsEvent('subscription', 'teamspace_cc_changed', label);

export const teamspaceBillingAddressChangedAnalyticsEvent = label =>
  sendAnalyticsEvent(
    'subscription',
    'teamspace_billing_address_changed',
    label
  );

export const trialCreditCardAddedAnalyticsEvent = label =>
  sendAnalyticsEvent('trial', 'trial_cc_added', label);

export const trial10MembersAddedAnalyticsEvent = label =>
  sendAnalyticsEvent('trial', 'trial_10_members_added', label);

export const trialDesktopAppDownloadedAnalyticsEvent = label =>
  sendAnalyticsEvent('trial', 'trial_desktop_app_downloaded', label);

export const trialRatedAnalyticsEvent = label =>
  sendAnalyticsEvent('trial', 'trial_rated', label);

export const trialCanceledAnalyticsEvent = label =>
  sendAnalyticsEvent('trial', 'trial_canceled', label);

const sendDesktopAnalyticsEvent = (eventName, params) => {
  const finalParams = isOtixoEnvProduction()
    ? params
    : {
        ...params,
        engagement_time_msec: 1200,
        debug_mode: true,
      };
  post(`desktop_${eventName}`, finalParams);
};

export const pageViewEvent = () => {
  try {
    if (isDesktopApp()) {
      sendDesktopAnalyticsEvent('page_view', {
        page_location: window.location.hash + window.location.search,
        page_title: 'We.Team Desktop',
      });
    } else {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  } catch (e) {}
};

const sendAnalyticsEvent = (category, action, label) => {
  try {
    if (isDesktopApp()) {
      sendDesktopAnalyticsEvent(action);
    } else {
      ReactGA.event({
        category,
        action,
        label, // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: 'xhr', // optional, beacon/xhr/image
        debug_mode: isNodeEnvDevelopment(),
      });
    }
  } catch (e) {}
};
