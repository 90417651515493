import axios from 'axios';
import {
  getMeLogoutUrl,
  getUnverifiedUserRequestAllowedConfig,
} from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from 'config/config';

export default function logout() {
  const config = getUnverifiedUserRequestAllowedConfig();

  config.baseURL = getOtixoBackendApiUrl();

  return axios.delete(getMeLogoutUrl(), config).then(response => response.data);
}
