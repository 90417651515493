import { rename, update } from 'DataLayer/Repositories/update';
import { LOAD_REPOSITORY_SUCCESS } from './loadRepository';

import { setRepositoryRequestInProgress } from '../Requests/setRequestInProgress';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';
import {
  showAppsExceededModal,
  hideEditRepositoryModal,
} from '../Modals/actions';

export const RENAME_REPOSITORY_SUCCESS = 'REPOSITORY/RENAME_SUCCESS';

export const renameRepository = (id, name) => dispatch => {
  dispatch(setRepositoryRequestInProgress(id, true));
  return rename(id, name).then(
    () => {
      dispatch({
        type: RENAME_REPOSITORY_SUCCESS,
        id,
        name,
      });
      dispatch(displaySuccessToast('toast.renameRepository.success'));
      dispatch(setRepositoryRequestInProgress(id, false));
    },
    () => {
      dispatch(displayErrorToast('toast.renameRepository.error'));
      dispatch(setRepositoryRequestInProgress(id, false));
    }
  );
};

export const updateRepo = (id, fields) => dispatch => {
  dispatch(setRepositoryRequestInProgress(id, true));
  return update(id, fields).then(
    repository => {
      dispatch({
        type: LOAD_REPOSITORY_SUCCESS,
        repository,
      });
      dispatch(displaySuccessToast('toast.updateRepo.success'));
      dispatch(setRepositoryRequestInProgress(id, false));
    },
    error => {
      dispatch(setRepositoryRequestInProgress(id, false));
      if (error.code === 'req_fmt') {
        throw new Error(error.message);
      } else if (error.code === 'repository_unauthorized') {
        throw new Error('toast.addRepository.repository_unauthorized');
      } else if (error.code === 'product_limit') {
        dispatch(hideEditRepositoryModal());
        dispatch(showAppsExceededModal());
      }
      throw new Error('toast.addRepository.error');
    }
  );
};

export default renameRepository;
