import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getDeleteTagUrl } from 'DataLayer/endpoints';

export const deleteByTagId = async ({ channelId, tagId }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.delete(
    getDeleteTagUrl(channelId, tagId),
    config
  );

  return data;
};
