import { isDesktopApp, getFrontendApplicatonUrl } from 'config/config';

import userLogout from '../../DataLayer/Me/logout';
import { clear as clearLocalStorage } from 'Helpers/BrowserApi/localStorage';
import { getLoginLink } from 'pages';
import { deleteCommonDb } from 'DataLayer/database/indexedDb';
import { signOutAnalyticsEvent } from 'Components/GoogleAnalytics/events';

const redirect = async continueUrl => {
  clearLocalStorage();
  deleteCommonDb();
  if (isDesktopApp()) {
    try {
      await window.ipcRenderer.invoke('logout');
    } catch {}
    /*
      We need to ensure the local state is destroyed when logging out,
      therefore we need to do a complete refresh
    */

    window.location.reload();
  } else {
    const url =
      getFrontendApplicatonUrl() + getLoginLink(undefined, continueUrl);
    window.location = url;
  }
};

const logout = async continueUrl => {
  try {
    signOutAnalyticsEvent();
    await userLogout();
    redirect(continueUrl);
  } catch (exception) {
    redirect(continueUrl);
  }
};

export default logout;
