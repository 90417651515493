import { createSelector } from 'reselect';
import {
  getCollaboratorsBySpaceId,
  getOwnerBySpaceId,
} from '../Spaces/Collaborators/selectors';

const getIsTyping = state => state.isTyping;

export const getIsTypingByChannelId = createSelector(
  [
    getIsTyping,
    getCollaboratorsBySpaceId,
    getOwnerBySpaceId,
    (_, channelId) => channelId,
  ],
  (isTyping, collaborators, owner, channelId) =>
    Object.entries(isTyping).reduce((arr, [email, cId]) => {
      if (channelId === cId) {
        const collaborator = collaborators
          .concat([owner])
          .find(c => c.Email === email);
        if (collaborator) {
          arr.push(collaborator);
        }
      }
      return arr;
    }, [])
);
