import { createSelector } from 'reselect';
import * as Sentry from '@sentry/react';

const allTtemplates = state => state.storeTemplates;

export const getTemplates = createSelector([allTtemplates], templates => {
  const templatesWithNonOtixoStep = Object.entries(templates).map(
    ([templateKey, template]) => {
      // find otixo step index
      const otixoStepIndex = template.steps.findIndex(
        step => step.slug.toLowerCase() === 'otixo'
      );
      let nonOtixoStep = {};
      try {
        if (otixoStepIndex === 0) {
          nonOtixoStep = template.steps[1];
        } else {
          nonOtixoStep = template.steps[otixoStepIndex - 1];
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      return {
        [templateKey]: {
          ...template,
          nonOtixoStep,
        },
      };
    }
  );
  if (!templatesWithNonOtixoStep.length) {
    return {};
  }
  return Object.assign(...templatesWithNonOtixoStep);
});

export function getTemplatesByApplicationId(state, applicationSlug) {
  const selectedTemplates = Object.values(getTemplates(state)).filter(
    template => {
      const hasAppSlug = template.steps.some(
        step => step.slug === applicationSlug
      );
      return hasAppSlug;
    }
  );
  return selectedTemplates;
}
