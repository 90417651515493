import axios from 'axios';
import { MY_NOTIFICATIONS } from '../../DataLayer/endpoints';

export function updateMessage(id, value) {
  return axios.put(`/${MY_NOTIFICATIONS}`, {
    Id: id,
    message: value,
  });
}

export function starMessage(id, starred) {
  return axios
    .put(`/${MY_NOTIFICATIONS}`, {
      Id: id,
      Starred: starred,
    })
    .then(response => {
      return response.data.Notification;
    });
}
