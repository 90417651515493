import { getChildrenById } from 'DataLayer/Resources/get';
import { normalize } from 'normalizr';

import setPaginateLink from '../Paginate/setPaginateLink';

import { mergeResources } from '../Entities/mergeResources';

import { resourceSchema } from './schemas';
import constants from './constants';

import * as actions from './actions';
import { isFetching } from './selectors';
import { getSpaceById } from 'store/Spaces/selectors';

export const loadFolders =
  (id, next, includeComments, channelId) => async (dispatch, getState) => {
    const state = getState();

    /* if this folder is already being requested, skip it! */
    if (isFetching(state, id)) {
      return Promise.resolve();
    }
    const channel = getSpaceById(state, channelId);

    dispatch(actions.loadResourcesRequest(id, true));

    const setResources = ({ resources, next }) => {
      const normalizedResources = normalize(resources, [resourceSchema]);
      dispatch(mergeResources(normalizedResources.entities.resources));
      dispatch(actions.addResources(id, normalizedResources.result));
      dispatch(setPaginateLink(id, next));
    };

    try {
      const { cachedResources, fetchedPromise } = await getChildrenById({
        id,
        next,
        includeComments,
        channelId,
        workspaceId: channel.WorkspaceId,
      });
      if (cachedResources?.resources) {
        setResources(cachedResources);
      }
      const networkResources = await fetchedPromise;
      setResources(networkResources);
      dispatch(actions.resourcesLoaded(id));
    } catch (error) {
      dispatch(actions.loadResourcesError(id, error));
    }
  };

export const loadRootFolders = () => dispatch =>
  dispatch(loadFolders(constants.ROOT_RESOURCES));

export default loadFolders;
