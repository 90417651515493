import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CollaboratorAvatarWithCardContainer from 'Components/Collaborators/CollaboratorAvatarWithCardContainer';

import styles from './ChatItemAvatar.module.css';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { TimeSquareIcon } from 'Components/Icons/WeTeamIcon';

function ChatItemAvatar({ avatar, email, spaceId, name, isSystem }) {
  if (avatar) {
    return <img src={avatar} alt="bot" className={styles.chatAvatar} />;
  }
  if (isSystem) {
    return (
      <WeTeamIcon
        link={false}
        height={40}
        width={40}
        Icon={TimeSquareIcon}
        className={styles.reminderBot}
      />
    );
  }
  return (
    <CollaboratorAvatarWithCardContainer
      email={email}
      spaceId={spaceId}
      fallbackName={name}
    />
  );
}

ChatItemAvatar.propTypes = {
  avatar: PropTypes.string,
  email: PropTypes.string,
  spaceId: PropTypes.string,
  name: PropTypes.string,
};

export default memo(ChatItemAvatar);
