import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { showCreateMeetingModal } from 'store/Modals/actions';
import { getNumberOfActiveMeetings } from 'store/Meeting/selectors';

import RightSidebar from 'Components/RightSidebar/RightSidebar';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { CameraIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

function MeetingButton({ workspaceId, defaultChannelId }) {
  const dispatch = useDispatch();
  const numberOfActiveMeetings = useSelector(state =>
    getNumberOfActiveMeetings(state)
  );
  const onClick = useCallback(() => {
    dispatch(showCreateMeetingModal(workspaceId, defaultChannelId));
  }, [defaultChannelId, dispatch, workspaceId]);

  return (
    <DisableInBackupMode>
      <RightSidebar.Button
        openTooltip="SelectMeetingMembersButton.Tooltip"
        closeTooltip="SelectMeetingMembersButton.Tooltip"
        onClick={onClick}
      >
        <WeTeamIcon link Icon={CameraIcon} />
        {numberOfActiveMeetings > 0 && <small>{numberOfActiveMeetings}</small>}
      </RightSidebar.Button>
    </DisableInBackupMode>
  );
}

MeetingButton.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  defaultChannelId: PropTypes.string.isRequired,
};

export default MeetingButton;
