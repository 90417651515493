const FOCUSSED = 'WINDOW/FOCUSSED';

export const windowFocussed = isFocussed => ({
  type: FOCUSSED,
  isFocussed,
});

export const isWindowFocussed = state => state.window.isFocussed;

const initialState = {
  isFocussed: true,
};

export default function windowReducer(state = initialState, action) {
  switch (action.type) {
    case FOCUSSED:
      return {
        ...state,
        isFocussed: action.isFocussed,
      };
    default:
      return state;
  }
}
