import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function save(user) {
  try {
    await commonDb.user.put({
      userId: user.Guid,
      user,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.user.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function get() {
  try {
    const users = await commonDb.user.toArray();
    if (Array.isArray(users) && users.length > 0) {
      return users[0].user;
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
