import useOnlineStatus from 'Hooks/useNetworkStatus';
import useSystemAwake from 'Hooks/useSystemAwake';
import useWindowFocus from 'Hooks/useWindowFocus';
/*
  when the computer comes online or wakes up and the browser is focussed,
  we can fetch data from the server to help keep the client in sync
*/
export default function useIsApplicationActive() {
  const online = useOnlineStatus();
  const awake = useSystemAwake();
  const focussed = useWindowFocus();
  return online && awake && focussed;
}
