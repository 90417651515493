import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import lodashGet from 'lodash/get';

import { getSorting } from '../Sorting/selectors';
import { SCREENS } from '../Sorting';

const getLinkedItems = state => state.linkedItems.linkedItems;

export const getSelectedLinkedItems = createSelector(
  [getLinkedItems, (_, selectedIds) => selectedIds],
  (linkedItems, selectedIds) => selectedIds.map(id => linkedItems[id])
);

const internalGetLinkeditemsBySpaceId = (state, spaceId) =>
  state.linkedItems.ids[spaceId];

const getLinkedItemEntities = state => state.linkedItems.linkedItems;

export const makeGetLinkedItemFoldersBySpaceId = () =>
  createSelector(
    [internalGetLinkeditemsBySpaceId, getLinkedItemEntities],
    (linkedItemIds, linkedItems) => {
      if (!linkedItemIds) return [];
      return linkedItemIds
        .map(id => linkedItems[id])
        .filter(linkedItem => linkedItem.Resource.Folder);
    }
  );

export function getLinkedItemIdsBySpaceId(state, id) {
  let linkedItemIds = state.linkedItems.ids[id];
  if (!linkedItemIds) {
    linkedItemIds = [];
  }
  return linkedItemIds;
}

export const getLinkedItemMemberFilter = (state, spaceId) =>
  state.linkedItems.memberFilter[spaceId] || [];

export const getLinkedItemTagFilter = (state, spaceId) =>
  state.linkedItems.tagFilter[spaceId];

export const hasFiltersApplied = createSelector(
  [getLinkedItemMemberFilter, getLinkedItemTagFilter],
  (memberId, taggedLinkedItemIds) => {
    return memberId.length > 0 || taggedLinkedItemIds;
  }
);

export const getLinkedItemBySpaceIdOrdered = createSelector(
  [
    internalGetLinkeditemsBySpaceId,
    getLinkedItems,
    getSorting,
    getLinkedItemMemberFilter,
    getLinkedItemTagFilter,
  ],
  (linkedItemIds, allLinkedItems, sorting, memberId, taggedLinkedItemIds) => {
    if (!linkedItemIds) return [];

    const finalLinkedItemIds = taggedLinkedItemIds
      ? taggedLinkedItemIds.filter(id => linkedItemIds.includes(id))
      : linkedItemIds;

    const linkedItems = finalLinkedItemIds.map(
      linkedItemId => allLinkedItems[linkedItemId]
    );

    const linkedItemsFilteredByMemberId = linkedItems.filter(
      linkedItem =>
        memberId.includes(linkedItem.Owner.Email) || !memberId.length
    );

    const { direction, column } = sorting[SCREENS.LINKEDITEMS];

    return orderBy(
      linkedItemsFilteredByMemberId,
      ['Resource.Folder', linkedItem => lodashGet(linkedItem, column)],
      ['desc', direction]
    );
  }
);

export const getLinkedItemIdsBySpaceIdOrdered = createSelector(
  [getLinkedItemBySpaceIdOrdered],
  linkedItems => linkedItems.map(r => r.ResourceId)
);

export function getLinkedItemById(state, id) {
  return state.linkedItems.linkedItems[id] || {};
}

export function getIsLinkedItemDeleting(state, id) {
  return (
    state.linkedItems.ids.deletingLinkedItemIds.filter(
      linkedItemId => linkedItemId === id
    ).length > 0
  );
}

export function isFetching(state, id) {
  if (state.linkedItems.fetching[id]) {
    return state.linkedItems.fetching[id];
  }
  return false;
}

export function hasError(state, id) {
  if (state.linkedItems.error[id]) {
    return state.linkedItems.error[id];
  }
  return false;
}

export const getLinkedItemBeingDeletedById = (state, id) =>
  state.linkedItems.removing[id];

const linkedItemsBeingDeleted = state => state.linkedItems.removing;

export const getLinkedItemsBeingDeleted = createSelector(
  [linkedItemsBeingDeleted],
  linkedItems => {
    return Object.keys(linkedItems);
  }
);
