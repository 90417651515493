import { useState, useEffect } from 'react';

export default function useWindowFocus() {
  const [isFocussed, setIsFocussed] = useState(document.hasFocus());

  const handleVisibilityChange = () => setIsFocussed(document.hasFocus());

  useEffect(() => {
    //https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
    document.addEventListener('visibilitychange', handleVisibilityChange, {
      capture: true,
    });
    window.addEventListener('focus', handleVisibilityChange, false);
    window.addEventListener('blur', handleVisibilityChange, false);

    return () => {
      //https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
      document.removeEventListener('visibilitychange', handleVisibilityChange, {
        capture: true,
      });
      window.removeEventListener('focus', handleVisibilityChange, false);
      window.removeEventListener('blur', handleVisibilityChange, false);
    };
  }, []);

  return isFocussed;
}
