import { useSelector } from 'react-redux';
import {
  getIsMeetingJoined,
  isMeetingAudioMuted,
  isMeetingVideoMuted,
  isMeetingScreenShared,
} from 'store/Meeting/selectors';

export const MEETING_STATUS_ICON = {
  CAMERA: 'CAMERA',
  SHARING_SCREEN: 'SHARING_SCREEN',
  MICROPHONE: 'MICROPHONE',
  MICROPHONE_MUTED: 'MICROPHONE_MUTED',
};

export const useMeetingStatus = () => {
  const isMeetingJoined = useSelector(getIsMeetingJoined);
  const isAudioMuted = useSelector(isMeetingAudioMuted);
  const isVideoMuted = useSelector(isMeetingVideoMuted);
  const isScreenShared = useSelector(isMeetingScreenShared);

  if (!isMeetingJoined) {
    return MEETING_STATUS_ICON.CAMERA;
  } else if (isScreenShared) {
    return MEETING_STATUS_ICON.SHARING_SCREEN;
  } else if (!isVideoMuted) {
    return MEETING_STATUS_ICON.CAMERA;
  } else if (!isAudioMuted) {
    return MEETING_STATUS_ICON.MICROPHONE;
  } else {
    return MEETING_STATUS_ICON.MICROPHONE_MUTED;
  }
};
