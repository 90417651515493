import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getLinkedItemsLink } from 'pages';
import {
  FOLDER,
  isImagePreview,
  isPDF,
} from 'DataLayer/Resources/Helpers/contentTypes';

import { showPreviewerForChatItems } from 'store/Modals/actions';
import { spaceSidebarPanelSelected, SIDEBAR_ITEMS } from 'store/UI/index';

import PreviewThumbnail from './PreviewThumbnail';
import Preview from './Preview';

export class PreviewContainer extends Component {
  openPreview = () => {
    const {
      resourceType,
      router,
      spaceId,
      resourceId,
      onSpaceSidebarPanelSelected,
      onShowPreviewerForChatItems,
    } = this.props;
    if (resourceType === FOLDER) {
      router.push(getLinkedItemsLink(spaceId, null, resourceId));
      onSpaceSidebarPanelSelected(spaceId, SIDEBAR_ITEMS.files, true);
    } else {
      onShowPreviewerForChatItems(resourceId, spaceId);
    }
  };

  renderPreview() {
    const { resourceType } = this.props;
    if (isImagePreview(resourceType) || isPDF(resourceType)) {
      return (
        <PreviewThumbnail {...this.props} openPreview={this.openPreview} />
      );
    }
    return <Preview {...this.props} openPreview={this.openPreview} />;
  }

  render() {
    const { resourceType } = this.props;

    if (!resourceType) {
      return null;
    }

    return <div data-testid="preview-container">{this.renderPreview()}</div>;
  }
}

PreviewContainer.propTypes = {
  notificationId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceType: PropTypes.string,
  resourceSize: PropTypes.number,
  resourceHeight: PropTypes.number,
  spaceId: PropTypes.string.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onSpaceSidebarPanelSelected: PropTypes.func.isRequired,
  onShowPreviewerForChatItems: PropTypes.func.isRequired,
};

export default withRouter(
  connect(undefined, {
    onSpaceSidebarPanelSelected: spaceSidebarPanelSelected,
    onShowPreviewerForChatItems: showPreviewerForChatItems,
  })(PreviewContainer)
);
