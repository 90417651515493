import axios from 'axios';
import fields from '../Spaces/fields';
import { getConversationUrl } from '../endpoints';

export function get() {
  return axios
    .get(getConversationUrl(fields))
    .then(response => response.data)
    .then(data => data.Spaces);
}
