import { createSelector } from 'reselect';

const allZaps = state => state.zaps.zaps;

/*const exampleZaps = [
  {
    title: 'Send emails from Gmail with new Otixo work space messages',
    url: 'https://zapier.com/app/editor/30766812',
    modified_at: '2018-01-03T06:26:55-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'gmail',
        description: 'One of the most popular email services, Gmail keeps track of all your emails with treaded conversations, tags, and Google-powered search to locate find any message you need.',
        title: 'Gmail',
        url: 'https://zapier.com/apps/gmail/integrations',
        image: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.png',
        id: 20,
        api: 'GoogleMailV2API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.32x32.png',
        },
        hex_color: 'db4437',
      },
      type_of: 'write',
    },
    ],
    id: 30766812,
  },
  {
    title: 'Zapier documentation notifications on edit',
    url: 'https://zapier.com/app/editor/30481308',
    modified_at: '2018-02-06T02:26:45-06:00',
    state: 'on',
    steps: [{
      app: {
        slug: 'google-sheets',
        description: 'Create, edit, and share spreadsheets wherever you are with Google Sheets, and get automated insights from your data.',
        title: 'Google Sheets',
        url: 'https://zapier.com/apps/google-sheets/integrations',
        image: 'https://cdn.zapier.com/storage/services/e37713ab22d82f777575279db97cd805.png',
        id: 1498,
        api: 'GoogleSheetsV2API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/e37713ab22d82f777575279db97cd805.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/e37713ab22d82f777575279db97cd805.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/e37713ab22d82f777575279db97cd805.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/e37713ab22d82f777575279db97cd805.32x32.png',
        },
        hex_color: '00a256',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30481308,
  },
  {
    title: 'New YouTube Videos as notifications in Otixo',
    url: 'https://zapier.com/app/editor/30440192',
    modified_at: '2017-12-22T01:26:06-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'youtube',
        description: 'YouTube allows billions of people to discover, watch and share originally-created videos. YouTube provides a forum for people to connect, inform, and inspire others across the globe and acts as a distribution platform for original content creators and advertisers large and small.',
        title: 'YouTube',
        url: 'https://zapier.com/apps/youtube/integrations',
        image: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.png',
        id: 311,
        api: 'YouTubeV4API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.32x32.png',
        },
        hex_color: 'FF0000',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30440192,
  },
  {
    title: 'PayPal sales as Otixo notifications',
    url: 'https://zapier.com/app/editor/30439898',
    modified_at: '2017-12-21T09:53:35-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'paypal',
        description: 'PayPal is a fast, safe way to send money, make an online payment, receive money or set up a merchant account. With over 150 million active registered accounts, PayPal helps people and businesses receive and make payments in more than 100 currencies in 203 markets.',
        title: 'PayPal',
        url: 'https://zapier.com/apps/paypal/integrations',
        image: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.png',
        id: 1,
        api: 'PayPalAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.32x32.png',
        },
        hex_color: '005ea6',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30439898,
  },
  {
    title: 'Create an Otixo Workspace message when your Azure App Service finishes a job',
    url: 'https://zapier.com/app/editor/30438978',
    modified_at: '2017-12-21T09:32:10-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'windows-azure-web-sites',
        description: 'Windows Azure App Service is a highly scalable website hosting solution with flexible development options including integration with Git, Mercurial and Dropbox.',
        title: 'Azure Web Apps',
        url: 'https://zapier.com/apps/windows-azure-web-sites/integrations',
        image: 'https://cdn.zapier.com/storage/services/3a43f2ce2ccccea008321a1189d04d14.png',
        id: 570,
        api: 'App32280API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/3a43f2ce2ccccea008321a1189d04d14.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/3a43f2ce2ccccea008321a1189d04d14.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/3a43f2ce2ccccea008321a1189d04d14.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/3a43f2ce2ccccea008321a1189d04d14.32x32.png',
        },
        hex_color: '00BAF0',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30438978,
  },
  {
    title: 'Create tweets from new messages in an Otixo Workspace channel',
    url: 'https://zapier.com/app/editor/30438434',
    modified_at: '2017-12-21T09:30:46-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'twitter',
        description: "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
        title: 'Twitter',
        url: 'https://zapier.com/apps/twitter/integrations',
        image: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
        id: 4,
        api: 'TwitterV2API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.32x32.png',
        },
        hex_color: '2DAAE1',
      },
      type_of: 'write',
    },
    ],
    id: 30438434,
  },
  {
    title: 'Get notified in Otixo when a file is added to your Dropbox folder',
    url: 'https://zapier.com/app/editor/30438121',
    modified_at: '2017-12-21T09:20:07-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'dropbox',
        description: 'Dropbox lets you store your files online, sync them to all your devices, and share them easily. Get started for free, then upgrade for more space and security features.',
        title: 'Dropbox',
        url: 'https://zapier.com/apps/dropbox/integrations',
        image: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.png',
        id: 14,
        api: 'DropBoxCLIAPI@1.1.7',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.32x32.png',
        },
        hex_color: '2964f6',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30438121,
  },
  {
    title: 'Receive Google Calendar events in an Otixo Work space',
    url: 'https://zapier.com/app/editor/30437573',
    modified_at: '2017-12-21T09:03:54-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'google-calendar',
        description: "Google Calendar lets you organize your schedule and share events with co-workers and friends. With Google's free online calendar, it's easy to keep track of your daily schedule.",
        title: 'Google Calendar',
        url: 'https://zapier.com/apps/google-calendar/integrations',
        image: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.png',
        id: 36,
        api: 'GoogleCalendarAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.32x32.png',
        },
        hex_color: '3680f7',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30437573,
  },
  {
    title: 'Send messages to a Otixo channel when new Dropbox folders are made',
    url: 'https://zapier.com/app/editor/30436969',
    modified_at: '2017-12-21T09:01:50-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'dropbox',
        description: 'Dropbox lets you store your files online, sync them to all your devices, and share them easily. Get started for free, then upgrade for more space and security features.',
        title: 'Dropbox',
        url: 'https://zapier.com/apps/dropbox/integrations',
        image: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.png',
        id: 14,
        api: 'DropBoxCLIAPI@1.1.7',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.32x32.png',
        },
        hex_color: '2964f6',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30436969,
  },
  {
    title: 'Send new One Drive files to Otixo as new messages',
    url: 'https://zapier.com/app/editor/30436397',
    modified_at: '2017-12-21T08:41:08-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'onedrive',
        description: "OneDrive, Microsoft's file sync app formerly known as SkyDrive, lets you store your files online, edit Office documents in the free Office Web Apps, and easily access your online files from your PC.",
        title: 'OneDrive',
        url: 'https://zapier.com/apps/onedrive/integrations',
        image: 'https://cdn.zapier.com/storage/services/e67867e9f6f5c089e243dad54467ad33.png',
        id: 77,
        api: 'SkyDriveAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/e67867e9f6f5c089e243dad54467ad33.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/e67867e9f6f5c089e243dad54467ad33.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/e67867e9f6f5c089e243dad54467ad33.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/e67867e9f6f5c089e243dad54467ad33.32x32.png',
        },
        hex_color: '094ab2',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 30436397,
  },
  {
    title: 'Support',
    url: 'https://zapier.com/app/editor/29415044',
    modified_at: '2018-01-11T08:12:46-06:00',
    state: 'on',
    steps: [{
      app: {
        slug: 'desk',
        description: 'Desk is the new version of Assistly under the flagship Salesforce brand.\n\nDesk is an all-in-one customer service tool allowing you to keep track of your customers across multiple channels including email, Twitter, Facebook, phone, and chat. Desk also makes it really easy to create knowledge bases about your products and services so customers can support themselves.',
        title: 'Desk',
        url: 'https://zapier.com/apps/desk/integrations',
        image: 'https://cdn.zapier.com/storage/services/91397217fe81d32f3ce1378a8e8bfbf5.png',
        id: 58,
        api: 'DeskV3API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/91397217fe81d32f3ce1378a8e8bfbf5.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/91397217fe81d32f3ce1378a8e8bfbf5.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/91397217fe81d32f3ce1378a8e8bfbf5.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/91397217fe81d32f3ce1378a8e8bfbf5.32x32.png',
        },
        hex_color: '683e98',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 29415044,
  },
  {
    title: 'Redmine',
    url: 'https://zapier.com/app/editor/29413793',
    modified_at: '2018-01-11T08:10:57-06:00',
    state: 'on',
    steps: [{
      app: {
        slug: 'redmine',
        description: 'Redmine is a flexible project management web application built with Ruby on Rails.',
        title: 'Redmine',
        url: 'https://zapier.com/apps/redmine/integrations',
        image: 'https://cdn.zapier.com/storage/services/cd06f9fc7a1f50c1715ef012ea897715.png',
        id: 68,
        api: 'RedmineAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/cd06f9fc7a1f50c1715ef012ea897715.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/cd06f9fc7a1f50c1715ef012ea897715.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/cd06f9fc7a1f50c1715ef012ea897715.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/cd06f9fc7a1f50c1715ef012ea897715.32x32.png',
        },
        hex_color: 'c52f29',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 29413793,
  },
  {
    title: 'Jenkins',
    url: 'https://zapier.com/app/editor/29413333',
    modified_at: '2018-01-25T06:16:29-06:00',
    state: 'on',
    steps: [{
      app: {
        slug: 'jenkins',
        description: 'jenkins is a continuous integration server that monitors executions of repeated jobs, such as building a software project or jobs run by cron.',
        title: 'Jenkins',
        url: 'https://zapier.com/apps/jenkins/integrations',
        image: 'https://cdn.zapier.com/storage/services/c152d4d93c7fce4a651f5f31cdb1c394_2.png',
        id: 623,
        api: 'App1892API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/c152d4d93c7fce4a651f5f31cdb1c394_2.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/c152d4d93c7fce4a651f5f31cdb1c394_2.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/c152d4d93c7fce4a651f5f31cdb1c394_2.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/c152d4d93c7fce4a651f5f31cdb1c394_2.32x32.png',
        },
        hex_color: 'D33833',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 29413333,
  },
  {
    title: 'Test integration',
    url: 'https://zapier.com/app/editor/29015331',
    modified_at: '2017-12-07T01:29:37-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'weather',
        description: 'Trigger off weather conditions in your local area. (Powered by Dark Sky)',
        title: 'Weather by Zapier',
        url: 'https://zapier.com/apps/weather/integrations',
        image: 'https://cdn.zapier.com/storage/developer/a21c6f9bf7e8ce93c090e4dcae51696e.png',
        id: 1929,
        api: 'WeatherDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/a21c6f9bf7e8ce93c090e4dcae51696e.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/a21c6f9bf7e8ce93c090e4dcae51696e.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/a21c6f9bf7e8ce93c090e4dcae51696e.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/a21c6f9bf7e8ce93c090e4dcae51696e.32x32.png',
        },
        hex_color: '499DF3',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 29015331,
  },
  {
    title: '[TODO] Monthly Twitter mentions to notifications in Otixo Copy Copy',
    url: 'https://zapier.com/app/editor/28865620',
    modified_at: null,
    state: 'draft',
    steps: [{
      app: {
        slug: 'schedule',
        description: "Schedule is a native Zapier app you can use for recurring tasks. Trigger an action every day of the month, every day of the week or every single day. You can also select the hour of the day. If you're a developer, this is similar to a crontab, cronjob or cron.",
        title: 'Schedule by Zapier',
        url: 'https://zapier.com/apps/schedule/integrations',
        image: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.png',
        id: 355,
        api: 'ScheduleAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.32x32.png',
        },
        hex_color: 'fc1c74',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'twitter',
        description: "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
        title: 'Twitter',
        url: 'https://zapier.com/apps/twitter/integrations',
        image: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
        id: 4,
        api: 'TwitterV2API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.32x32.png',
        },
        hex_color: '2DAAE1',
      },
      type_of: 'write',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28865620,
  },
  {
    title: '[TODO] Weekly Twitter mentions to notifications in Otixo Copy',
    url: 'https://zapier.com/app/editor/28865583',
    modified_at: null,
    state: 'draft',
    steps: [{
      app: {
        slug: 'schedule',
        description: "Schedule is a native Zapier app you can use for recurring tasks. Trigger an action every day of the month, every day of the week or every single day. You can also select the hour of the day. If you're a developer, this is similar to a crontab, cronjob or cron.",
        title: 'Schedule by Zapier',
        url: 'https://zapier.com/apps/schedule/integrations',
        image: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.png',
        id: 355,
        api: 'ScheduleAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.32x32.png',
        },
        hex_color: 'fc1c74',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'twitter',
        description: "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
        title: 'Twitter',
        url: 'https://zapier.com/apps/twitter/integrations',
        image: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
        id: 4,
        api: 'TwitterV2API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.32x32.png',
        },
        hex_color: '2DAAE1',
      },
      type_of: 'write',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28865583,
  },
  {
    title: '[TODO] Daily Twitter mentions to notifications in Otixo',
    url: 'https://zapier.com/app/editor/28865437',
    modified_at: '2017-11-13T08:34:38-06:00',
    state: 'draft',
    steps: [{
      app: {
        slug: 'schedule',
        description: "Schedule is a native Zapier app you can use for recurring tasks. Trigger an action every day of the month, every day of the week or every single day. You can also select the hour of the day. If you're a developer, this is similar to a crontab, cronjob or cron.",
        title: 'Schedule by Zapier',
        url: 'https://zapier.com/apps/schedule/integrations',
        image: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.png',
        id: 355,
        api: 'ScheduleAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.32x32.png',
        },
        hex_color: 'fc1c74',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'twitter',
        description: "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
        title: 'Twitter',
        url: 'https://zapier.com/apps/twitter/integrations',
        image: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
        id: 4,
        api: 'TwitterV2API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.32x32.png',
        },
        hex_color: '2DAAE1',
      },
      type_of: 'write',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28865437,
  },
  {
    title: 'New YouTube video notification in Otixo',
    url: 'https://zapier.com/app/editor/28865173',
    modified_at: '2017-11-14T10:42:30-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'youtube',
        description: 'YouTube allows billions of people to discover, watch and share originally-created videos. YouTube provides a forum for people to connect, inform, and inspire others across the globe and acts as a distribution platform for original content creators and advertisers large and small.',
        title: 'YouTube',
        url: 'https://zapier.com/apps/youtube/integrations',
        image: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.png',
        id: 311,
        api: 'YouTubeV4API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.32x32.png',
        },
        hex_color: 'FF0000',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28865173,
  },
  {
    title: 'Paypal sale notification in Otixo',
    url: 'https://zapier.com/app/editor/28864872',
    modified_at: '2017-11-13T08:23:04-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'paypal',
        description: 'PayPal is a fast, safe way to send money, make an online payment, receive money or set up a merchant account. With over 150 million active registered accounts, PayPal helps people and businesses receive and make payments in more than 100 currencies in 203 markets.',
        title: 'PayPal',
        url: 'https://zapier.com/apps/paypal/integrations',
        image: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.png',
        id: 1,
        api: 'PayPalAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.32x32.png',
        },
        hex_color: '005ea6',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28864872,
  },
  {
    title: 'New Evernote notifications in Otixo',
    url: 'https://zapier.com/app/editor/28864639',
    modified_at: '2017-11-13T08:19:09-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'evernote',
        description: "Evernote is a powerful note taking application that makes it easy to capture ideas, images, contacts, and anything else you need to remember. Bring your life's work together in one digital workspace—available on all major mobile platforms and devices.\n\nUsing Evernote for work? See Evernote Business .",
        title: 'Evernote',
        url: 'https://zapier.com/apps/evernote/integrations',
        image: 'https://cdn.zapier.com/storage/services/e0a30d6651780fba36903a9af2b768b6.png',
        id: 15,
        api: 'EvernoteAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/e0a30d6651780fba36903a9af2b768b6.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/e0a30d6651780fba36903a9af2b768b6.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/e0a30d6651780fba36903a9af2b768b6.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/e0a30d6651780fba36903a9af2b768b6.32x32.png',
        },
        hex_color: '6fb536',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28864639,
  },
  {
    title: 'Get new email notifications in Otixo',
    url: 'https://zapier.com/app/editor/28864230',
    modified_at: '2018-01-15T11:01:07-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'gmail',
        description: 'One of the most popular email services, Gmail keeps track of all your emails with treaded conversations, tags, and Google-powered search to locate find any message you need.',
        title: 'Gmail',
        url: 'https://zapier.com/apps/gmail/integrations',
        image: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.png',
        id: 20,
        api: 'GoogleMailV2API',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.32x32.png',
        },
        hex_color: 'db4437',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28864230,
  },
  {
    title: 'Instagram media notifications in Otixo',
    url: 'https://zapier.com/app/editor/28863723',
    modified_at: '2017-11-13T08:05:43-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'instagram',
        description: 'Instagram is a social network which is a fast, beautiful and fun way to share your photos with friends and family.',
        title: 'Instagram',
        url: 'https://zapier.com/apps/instagram/integrations',
        image: 'https://cdn.zapier.com/storage/developer/30129480e943e5b3e334394168ae16a2.png',
        id: 894,
        api: 'InstagramDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/30129480e943e5b3e334394168ae16a2.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/30129480e943e5b3e334394168ae16a2.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/30129480e943e5b3e334394168ae16a2.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/30129480e943e5b3e334394168ae16a2.32x32.png',
        },
        hex_color: '3f729b',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28863723,
  },
  {
    title: 'Calendar event to Otixo notifcation',
    url: 'https://zapier.com/app/editor/28691080',
    modified_at: '2017-11-09T08:43:28-06:00',
    state: 'off',
    steps: [{
      app: {
        slug: 'google-calendar',
        description: "Google Calendar lets you organize your schedule and share events with co-workers and friends. With Google's free online calendar, it's easy to keep track of your daily schedule.",
        title: 'Google Calendar',
        url: 'https://zapier.com/apps/google-calendar/integrations',
        image: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.png',
        id: 36,
        api: 'GoogleCalendarAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.32x32.png',
        },
        hex_color: '3680f7',
      },
      type_of: 'read',
    },
    {
      app: {
        slug: 'otixo',
        description: 'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
        title: 'Otixo',
        url: 'https://zapier.com/apps/otixo/integrations',
        image: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
        id: 8714,
        api: 'OtixoDevAPI',
        images: {
          url_128x128: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.128x128.png',
          url_64x64: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.64x64.png',
          url_16x16: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.16x16.png',
          url_32x32: 'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.32x32.png',
        },
        hex_color: '28919a',
      },
      type_of: 'write',
    },
    ],
    id: 28691080,
  },
];
*/

export const getZaps = createSelector(
  [allZaps],
  zaps =>
    Object.values(zaps)
      .filter(zap => zap.state !== 'draft')
      .map(zap => {
        if (!zap.title) {
          return {
            ...zap,
            title: 'Unnamed Zap',
          };
        }
        return zap;
      })
);
