import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'semantic-ui-react';

import { channelMarkedAsUnread } from 'store/UI/index';
import { channelMarkedAsRead } from 'store/UI/channelMarkedAsRead';

import WeTeamIcon, {
  CommentCheckIcon,
  CommentSlashIcon,
} from 'Components/Icons/WeTeamIcon';

import { useIsChannelUnread } from 'Components/Spaces/useIsChannelUnread';

export const MarkAsUnreadOption = ({
  channelId,
  workspaceId,
  onMarkAsUnread,
  onMarkAsRead,
}) => {
  const dispatch = useDispatch();
  const { isUnread } = useIsChannelUnread(channelId);

  return (
    <Menu.Item
      onClick={() => {
        if (isUnread) {
          dispatch(channelMarkedAsRead(channelId, workspaceId));
        } else {
          dispatch(channelMarkedAsUnread(channelId, workspaceId));
        }
      }}
    >
      <WeTeamIcon
        Icon={isUnread ? CommentCheckIcon : CommentSlashIcon}
        link={false}
      />
      <FormattedMessage
        id={
          isUnread
            ? 'MarkAsUnreadOption.MarkAsRead'
            : 'MarkAsUnreadOption.MarkAsUnread'
        }
      />
    </Menu.Item>
  );
};

MarkAsUnreadOption.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default MarkAsUnreadOption;
