import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(id, data) {
  try {
    await commonDb.mediaInfo.put({
      id,
      mediaInfo: data,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function get(id) {
  try {
    const mediaInfos = await commonDb.mediaInfo
      .where('id')
      .equals(id)
      .toArray();
    if (Array.isArray(mediaInfos) && mediaInfos.length > 0) {
      return mediaInfos[0].mediaInfo;
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
