import {
  LOAD_MEDIAINFO_SUCCESS,
  LOAD_MEDIAINFO_REQUEST,
  LOAD_MEDIAINFO_ERROR,
} from './actions';

const mediaInfo = (
  state = {
    isFetching: false,
    mediaInfo: {},
    error: null,
  },
  action
) => {
  switch (action.type) {
    case LOAD_MEDIAINFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case LOAD_MEDIAINFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        mediaInfo: action.mediaInfo,
        error: null,
      };
    case LOAD_MEDIAINFO_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default function mediaInfoReducer(state = {}, action) {
  switch (action.type) {
    case LOAD_MEDIAINFO_SUCCESS:
    case LOAD_MEDIAINFO_REQUEST:
    case LOAD_MEDIAINFO_ERROR:
      return {
        ...state,
        [action.id]: mediaInfo(state[action.id], action),
      };
    default:
      return state;
  }
}
