import axios from 'axios';
import * as Sentry from '@sentry/react';

import { isDesktopApp, isWebApp } from 'config/config';

async function getFromCache(url) {
  try {
    if (isWebApp()) {
      return undefined;
    }
    const result = await window.ipcRenderer.invoke('getFile', url);
    return result ? new Blob([result]) : undefined;
  } catch (ex) {
    return undefined;
  }
}

export async function getFromNetwork(url, targetLocation) {
  const { data } = await axios.get(url, { responseType: 'blob' });
  // https://stackoverflow.com/a/43613242
  function saveBlob(blob) {
    try {
      let reader = new FileReader();
      reader.onload = function () {
        if (reader.readyState === 2) {
          var buffer = new Buffer(reader.result);
          window.ipcRenderer?.send('saveFile', url, buffer, targetLocation);
        }
      };
      reader.readAsArrayBuffer(blob);
    } catch (ex) {
      Sentry.captureException(ex);
    }
  }
  if (isDesktopApp()) {
    saveBlob(data);
  }
  return data;
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function get(url) {
  const cachedFile = await getFromCache(url);
  const fetchedPromise = getFromNetwork(url);
  return { cachedFile, fetchedPromise };
}
