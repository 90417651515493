import React from 'react';
import PropTypes from 'prop-types';
import SpaceHomeLayout from 'Views/SpaceHome/SpaceHomeLayout';
import SpaceHome from 'Views/SpaceHome/SpaceHome';
import FullScreenApp from 'Views/FullScreenApp';
import WorkspaceSidebarLayout from 'Components/Layouts/WorkspaceSidebarLayout/WorkspaceSidebarLayout';

const SkeletonLayout = ({ channel = {}, children }) => {
  return (
    <>
      <FullScreenApp enableAppBanner={false}>
        <WorkspaceSidebarLayout>
          <SpaceHomeLayout space={channel}>
            <SpaceHome isSkeletonApp params={{ spaceId: channel.Id }} />
          </SpaceHomeLayout>
        </WorkspaceSidebarLayout>
      </FullScreenApp>
      {children}
    </>
  );
};

SkeletonLayout.propTypes = {
  channel: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default SkeletonLayout;
