import disconnect from 'DataLayer/Integrations/Zaps/disconnect';

export const ZAPIER_DISCONNECTED = 'APPSTORE/ZAPIER/DISCONNECTED';

export const disconnectZapier = () => async dispatch => {
  await disconnect();
  dispatch({
    type: ZAPIER_DISCONNECTED,
  });
};

export default disconnectZapier;
