import generator from '../../Helpers/Strings/generator';

export const TOAST_DISPLAYED = 'TOAST_DISPLAYED';
export const TOAST_HIDDEN = 'TOAST_HIDDEN';

export const TOAST_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  LOADING: 'loading',
};

export function hideToast(id) {
  return {
    type: TOAST_HIDDEN,
    id,
  };
}

const displayToast = ({
  type,
  translationKey,
  translationValues = {},
  id = generator(8),
}) => dispatch => {
  const toast = {
    id,
    type,
    translationKey,
    translationValues,
  };

  dispatch({
    type: TOAST_DISPLAYED,
    toast,
  });
  return id;
};

export const displayErrorToast = (key, values, id) =>
  displayToast({
    type: TOAST_TYPE.ERROR,
    translationKey: key,
    translationValues: values,
    id,
  });

export const displaySuccessToast = (key, values, id) =>
  displayToast({
    type: TOAST_TYPE.SUCCESS,
    translationKey: key,
    translationValues: values,
    id,
  });

export const displayLoadingToast = (key, values) =>
  displayToast({
    type: TOAST_TYPE.LOADING,
    translationKey: key,
    translationValues: values,
  });
