import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getWorkspaceSubscriptionBillingInformationUrl } from 'DataLayer/endpoints';
import { ThreeDSecureError } from 'Helpers/Recurly/ThreeDSecureError';

export function put({ workspaceId, billingInfoToken, actionResultToken }) {
  return axios
    .put(
      getWorkspaceSubscriptionBillingInformationUrl(workspaceId),
      {
        tokenId: billingInfoToken,
        threeDSecureActionResultTokenId: actionResultToken,
      },
      {
        baseURL: getOtixoBackendApiUrl(),
      }
    )
    .then(({ data }) => {
      if (!data.success) {
        if (data.threeDSecureActionTokenId) {
          throw new ThreeDSecureError(data.threeDSecureActionTokenId);
        }
        throw data;
      }
      return data;
    });
}
