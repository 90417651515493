import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getWorkspaceSubscriptionUrl } from 'DataLayer/endpoints';

export function get(workspaceId) {
  return axios
    .get(getWorkspaceSubscriptionUrl(workspaceId), {
      baseURL: getOtixoBackendApiUrl(),
    })
    .then(({ data }) => data);
}
