import styles from './NewVersionAvailableBanner.module.css';
import { FormattedMessage } from 'react-intl';
import { Button, Transition } from 'semantic-ui-react';

function NewVersionAvailableBanner() {
  const reload = () => {
    window.location.reload();
  };

  return (
    <Transition transitionOnMount visible animation="fade down" duration={200}>
      <div
        className={styles.newVersionAvailable}
        onClick={reload}
        data-testid="new-version-available"
      >
        <strong className="mr-4">
          <FormattedMessage id="NewVersionAvailable.Title" />
        </strong>
        <Button primary>
          <FormattedMessage id="NewVersionAvailable.InstallButtonText" />
        </Button>
      </div>
    </Transition>
  );
}

export default NewVersionAvailableBanner;
