import { star } from 'DataLayer/LinkedItems/update';
import { displayErrorToast } from 'store/Toast/displayToast';
import { linkedItemStarAdded } from 'store/LinkedItems/actions';
import loadStarred from 'store/Starred/loadStarred';

const starLinkedItem = (spaceId, resourceId, starred) => dispatch => {
  dispatch(linkedItemStarAdded(resourceId, starred));

  return star(spaceId, resourceId, starred).then(
    () => {
      dispatch(loadStarred());
    },
    error => {
      dispatch(linkedItemStarAdded(resourceId, !starred)); // revert to previous
      dispatch(displayErrorToast('toast.star.error'));
      throw error;
    }
  );
};

export default starLinkedItem;
