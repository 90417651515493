import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { isOtixoEnvProduction } from 'config/config';
import { supportedLocales } from 'translations/getMessageByLocale';

function CrowdInInContext({ locale }) {
  useEffect(
    () => {
      if (!isOtixoEnvProduction() && locale === supportedLocales.crowdin) {
        window._jipt = [];
        window._jipt.push(['project', 'otixo']);
        const script = document.createElement('script');
        script.setAttribute('src', '//cdn.crowdin.com/jipt/jipt.js');
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    },
    [locale]
  );

  return null;
}

CrowdInInContext.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default CrowdInInContext;
