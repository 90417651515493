import axios from 'axios';
import { TASK } from 'DataLayer/endpoints';

export function skip(id) {
  return axios.put(`${TASK}/${id}/skip`);
}

export function retry(id) {
  return axios.put(`${TASK}/${id}/retry`);
}
