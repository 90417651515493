import { FormattedMessage } from 'react-intl';

import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

function EditDisabledModal({ onClose }) {
  return (
    <Modal size="small" open onClose={onClose}>
      <Header>
        <FormattedMessage id="EditDisabledModal.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onClose} />
        </div>
      </Header>
      <Modal.Content>
        <p>
          <FormattedMessage id="EditDisabledModal.Message" />
        </p>
      </Modal.Content>
      <Modal.Actions>
        <div className="modal-buttons">
          <Button basic onClick={onClose}>
            <FormattedMessage id="Cancel" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default EditDisabledModal;
