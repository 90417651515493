import { updateMessage } from 'DataLayer/Notifications/put';
import {
  updateNotificationMessage,
  addNotification,
} from 'store/Notifications/actions';
import { displayErrorToast } from 'store/Toast/displayToast';
import { getNotificationById } from 'store/Notifications/selectors';
import { isEditExpiryExceeded } from 'DataLayer/error';

const editNotification = (id, value) => (dispatch, getState) => {
  const state = getState();
  const notification = getNotificationById(state, id);
  dispatch(updateNotificationMessage(id, value));

  return updateMessage(id, value).then(
    () => {},
    error => {
      if (isEditExpiryExceeded(error)) {
        dispatch(displayErrorToast('EditDisabledModal.Message'));
      } else {
        dispatch(displayErrorToast('toast.editNotification.error'));
      }
      dispatch(addNotification(notification));
      throw error;
    }
  );
};

export default editNotification;
