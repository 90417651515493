import { sortAlphabetical } from './sort';

function processAdapter(adapter) {
  const adapterFields = adapter.Fields.map(f => {
    if (f.Id.toLowerCase() === 'name') {
      f.value = adapter.Caption;
    } else if (f.Type.toLowerCase() === 'boolean') {
      f.value = false;
    } else {
      f.value = '';
    }
    return f;
  });
  adapter.Fields = adapterFields;
  adapter.CanDelete = adapter.Deny.indexOf('delete') === -1;
  adapter.CanRename = adapter.Deny.indexOf('rename') === -1;
  adapter.CanCreate = adapter.Deny.indexOf('create') === -1;

  return adapter;
}

export default function(adapters) {
  return adapters
    .map(adapter => processAdapter(adapter))
    .sort(sortAlphabetical);
}
