import getMessageByLocale, {
  supportedLocales,
} from 'translations/getMessageByLocale';
import { multiLanguageEnabled } from 'config/config';

export const SET_LOCALE = 'LOCALE/SET';

export const setLocale = locale => dispatch => {
  if (!locale) {
    return;
  }
  // TODO: Remove this when we are ready to show German language
  if (!multiLanguageEnabled()) {
    locale = supportedLocales.en;
  }

  // if the user has an unsupported language, show english
  const finalLocale = supportedLocales[locale] ? locale : supportedLocales.en;

  if (finalLocale.length > 0) {
    const messages = getMessageByLocale(locale);
    dispatch({
      type: SET_LOCALE,
      locale: finalLocale,
      messages,
    });
  }
};
