import React from 'react';
import PropTypes from 'prop-types';

import RightSidebar from 'Components/RightSidebar/RightSidebar';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { StarIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

function StarButton({ isActive, onClick, numberOfStars }) {
  return (
    <DisableInBackupMode>
      <RightSidebar.Button
        openTooltip="StarredMessagesButton.Hide"
        closeTooltip="StarredMessagesButton.Show"
        open={isActive}
        onClick={onClick}
      >
        <WeTeamIcon Icon={StarIcon} active={isActive} />
        {numberOfStars > 0 && <small>{numberOfStars}</small>}
      </RightSidebar.Button>
    </DisableInBackupMode>
  );
}

StarButton.propTypes = {
  numberOfStars: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StarButton;
