import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Divider,
  Header,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCurrentSubscriptionPeriodEnd,
  getWorkspaceById,
} from 'store/Workspaces/selectors';
import { enterBillingInformationReminderHidden } from 'store/UI';

import LinkButton from 'Components/Elements/LinkButton';
import RecurlyPaymentFormContainer from 'Components/Recurly/RecurlyPaymentFormContainer';

import styles from './ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.module.css';

function ThreeMonthTeamspaceUpgradeBillingInfoReminderModal({
  workspaceId,
  visible,
  onClose,
}) {
  const [showBillingForm, setShowBillingForm] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const workspace = useSelector(state => getWorkspaceById(state, workspaceId));

  const periodEnd = useSelector(state =>
    getCurrentSubscriptionPeriodEnd(state, workspaceId)
  );

  const onComplete = () => {
    setSuccess(true);
  };

  const onHideForever = () => {
    dispatch(enterBillingInformationReminderHidden(workspaceId));
    onClose();
  };

  if (success && visible) {
    return (
      <Modal size="tiny" open onClose={onClose}>
        <Modal.Content className={styles.content}>
          {/* eslint-disable react/jsx-no-literals */}
          <div className={`${styles.emoji} flex justify-content-center mb-3`}>
            🎉
          </div>
          {/* eslint-enable react/jsx-no-literals */}
          <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.Complete" />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={onClose}>
            <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.CloseButtonText" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <Modal size="small" open={visible}>
      <Modal.Content className={styles.content}>
        {!showBillingForm && (
          <>
            <div className="pb-1">
              <Header>
                <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.Title" />
              </Header>
            </div>
            <br />
            <FormattedMessage
              id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.Message.1"
              values={{
                name: <strong>{workspace.Name}</strong>,
                date: (
                  <strong>
                    <FormattedDate
                      value={periodEnd}
                      year="numeric"
                      month="short"
                      day="2-digit"
                    />
                  </strong>
                ),
              }}
            />
            <div className="my-3">
              <Button
                primary
                size="big"
                onClick={() => {
                  setShowBillingForm(true);
                }}
              >
                <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.EnterBillingInformation" />
              </Button>
            </div>
          </>
        )}
        {showBillingForm && (
          <>
            <Header>
              <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.EnterBillingInformation" />
            </Header>
            <Segment className={styles.paymentForm}>
              {error && (
                <Message error>
                  <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.Error" />
                </Message>
              )}
              <RecurlyPaymentFormContainer
                workspaceId={workspaceId}
                onComplete={onComplete}
                onError={setError}
              />
            </Segment>
          </>
        )}
        <Button basic onClick={onClose} size="big">
          <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.MaybeLaterButtonText" />
        </Button>
        <Divider />
        <LinkButton onClick={onHideForever} underlined>
          <FormattedMessage id="ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.ForgetForeverButton" />
        </LinkButton>
      </Modal.Content>
    </Modal>
  );
}

ThreeMonthTeamspaceUpgradeBillingInfoReminderModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ThreeMonthTeamspaceUpgradeBillingInfoReminderModal;
