import { FormattedMessage } from 'react-intl';

import React from 'react';
import { Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getUnreadAllSpaceNotificationsCount } from 'store/Notifications/selectors';

import Activities from 'Components/Activities/Activities';

import NotificationBadge from '../../Badges/NotificationBadge';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';

import { NotificationIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

const ActivityLogButton = ({ unread }) => {
  const isBackupMode = useIsBackupMode();
  return (
    <Popup
      basic
      flowing
      on="click"
      disabled={isBackupMode}
      position="bottom right"
      trigger={
        <span>
          <Popup
            on="hover"
            disabled={isBackupMode}
            inverted
            size="mini"
            position="bottom right"
            trigger={
              <span className="position-relative">
                <DisableInBackupMode>
                  <WeTeamIcon Icon={NotificationIcon} />
                </DisableInBackupMode>
                <NotificationBadge
                  size="mini"
                  count={unread}
                  style={{
                    top: -18,
                  }}
                />
              </span>
            }
          >
            <FormattedMessage id="ActivityLogButton.activities" />
          </Popup>
        </span>
      }
      style={{ paddingRight: 0 }}
    >
      <Activities />
    </Popup>
  );
};

ActivityLogButton.propTypes = { unread: PropTypes.number.isRequired };

export default connect(state => ({
  unread: getUnreadAllSpaceNotificationsCount(state),
}))(ActivityLogButton);
