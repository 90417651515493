import createRepository from 'DataLayer/Repositories/create';
import { showAppsExceededModal } from 'store/Modals/actions';

import { displaySuccessToast } from '../Toast/displayToast';
import { loadRootFolders } from '../Resources/loadFolders';

// import { getRepositorNames } from './selectors';
import { isProductLimit } from '../../DataLayer/error';
import { isDesktopApp } from 'config/config';

export const ADD_REPOSITORY_SUCCESS = 'REPOSITORIES/ADDED';
/*
function isRepositoryNameInUse(names, name) {
  return names.find(n => n === name);
}
*/
const addRepository = formData => dispatch =>
  // const state = getState();

  // const names = getRepositorNames(state);
  /*
  if (isRepositoryNameInUse(names, formData.Name)) {
    return Promise.reject(new Error('toast.addRepository.name_conflict'));
  }
*/
  createRepository(formData).then(
    repository => {
      if (repository.OAuthUrl) {
        if (isDesktopApp()) {
          window.open(repository.OAuthUrl);
        } else {
          window.location = repository.OAuthUrl;
        }
      } else {
        // We need to get the associated resource for this repository
        dispatch(loadRootFolders());
        dispatch({
          type: ADD_REPOSITORY_SUCCESS,
          repository,
        });
        dispatch(
          displaySuccessToast('toast.addRepository.success', {
            name: repository.Name,
          })
        );
      }
      return repository;
    },
    error => {
      if (error.code === 'req_fmt') {
        throw new Error(error.message);
      } else if (error.code === 'repository_unauthorized') {
        throw new Error('toast.addRepository.repository_unauthorized');
      } else if (isProductLimit(error)) {
        dispatch(showAppsExceededModal());
      }
      throw new Error('toast.addRepository.error');
    }
  );

export default addRepository;
