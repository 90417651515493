import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const open = { opacity: 1, height: 'auto' };
const collapsed = { opacity: 0, height: 0, overflow: 'hidden' };

const CollapsiblePanel = ({ children, visible, ...props }) => {
  return (
    <motion.div initial={false} animate={visible ? open : collapsed} {...props}>
      {children}
    </motion.div>
  );
};

CollapsiblePanel.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default CollapsiblePanel;
