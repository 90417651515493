import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StarredMessage from './StarredMessage';
import starLinkedItem from 'store/LinkedItems/starLinkedItem';
import LinkedItem from 'Components/LinkedItem/LinkedItem';

function StarredLinkedItem({ linkedItem, showWorkspaceName, onRemoveStar }) {
  return (
    <StarredMessage
      onRemoveStar={() =>
        onRemoveStar(linkedItem.ChannelId, linkedItem.ResourceId, false)
      }
    >
      <LinkedItem
        linkedItem={linkedItem}
        showWorkspaceName={showWorkspaceName}
      />
    </StarredMessage>
  );
}

StarredLinkedItem.propTypes = {
  linkedItem: PropTypes.object.isRequired,
  showWorkspaceName: PropTypes.bool,
  onRemoveStar: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    onRemoveStar: starLinkedItem,
  }
)(StarredLinkedItem);
