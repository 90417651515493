import PropTypes from 'prop-types';
import React from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { differenceInMinutes } from 'date-fns';
import findLast from 'lodash/findLast';

import { getSpaceLink } from 'pages';
import notificationType from 'Constants/notificationType';

import {
  loadStats,
  loadConversationStats,
} from 'store/Notifications/loadStats';
import { addSpaceComment } from 'store/Comments/addSpaceComment';
import { makeGetSpaceById } from 'store/Spaces/selectors';
import {
  loadNotifications,
  loadPresentNotifications,
  loadNotificationsAround,
  loadNotificationsBefore,
  loadNotificationsAfter,
} from 'store/Notifications/loadNotifications';
import { markAsReadBySpaceId } from 'store/Notifications/actions';
import {
  getFeedNotificationsBySpaceId,
  getHasHiddenMessages,
  getHasOlderMessages,
  getHasNewerMessages,
  getLastReadBySpaceId,
  getEarliestNewNotificationIdBySpaceId,
} from 'store/Notifications/selectors';
import {
  showSpaceChatDeleteMessage,
  showTransferToOtixoDriveModal,
  showPreviewerForChatItems,
  showShareMessagesWithChannelModal,
} from 'store/Modals/actions';
import editNotification from 'store/Notifications/editNotification';
import { startDownload } from 'store/Download/startDownload';

import Comments from './Comments';

import './SpaceChat.scss';
import SpaceChatItems from './SpaceChatItems/SpaceChatItems';

import FileDrop from '../../FileExplorer/FileDrop/FileDrop';
import { getScrollPositionById } from 'store/UITemporary/selectors';
import { chatScrollPositionChanged } from 'store/UITemporary';

class SpaceChat extends React.Component {
  state = {
    fullScreenChat: false,
    loadingOlder: true,
    loadingNewer: false,
    replyId: null,
  };

  componentDidMount() {
    this.onLoadNotifications();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.spaceId !== prevProps.spaceId ||
      this.props.goToNotificationId !== prevProps.goToNotificationId
    ) {
      this.onLoadNotifications();
    }
    // if the channel is switched, remove reply
    if (this.props.spaceId !== prevProps.spaceId) {
      this.setState({ replyId: null });
    }
  }

  onDelete = id => {
    this.props.dispatch(showSpaceChatDeleteMessage(id));
  };

  onDownload = resourceId => {
    const { spaceId } = this.props;
    this.props.dispatch(startDownload([resourceId], spaceId));
  };

  onTransferToOtixoDrive = resource => {
    this.props.dispatch(showTransferToOtixoDriveModal([resource]));
  };

  onEdit = (id, value) => this.props.dispatch(editNotification(id, value));

  onShareMessage = notification => {
    const { space, dispatch } = this.props;
    dispatch(
      showShareMessagesWithChannelModal(space.WorkspaceId, [notification.Id])
    );
  };

  onMessageReply = id => {
    this.setState({
      replyId: id,
    });
  };

  onRemoveReply = () => {
    this.setState({
      replyId: null,
    });
  };

  onAddComment = (message, id, replyId) => {
    const { space, hasNewerMessages } = this.props;
    if (id) {
      return this.onEdit(id, message);
    }
    return this.props
      .dispatch(addSpaceComment(space.Id, null, message, replyId))
      .then(() => {
        if (hasNewerMessages) {
          this.onJumpToPresent();
        }
      });
  };

  onScroll = position => {
    this.props.dispatch(
      chatScrollPositionChanged(this.props.spaceId, position)
    );
  };

  onFetchLatest = () => {
    // When the browser is refocussed, we want to re-fetch all messages that are in the current chat window.
    // This helps guarantee that any missed messages (offline, computer asleep) will appear in the chat.
    if (!this.props.hasNewerMessages) {
      const { dispatch, spaceId, comments } = this.props;
      const newestComment = comments.slice(-1)[0];
      if (newestComment) {
        dispatch(loadNotificationsAfter(spaceId, newestComment.Id, 5000));
      }
    }
  };

  onJumpToPresent = () => {
    this.setState({
      loadingPresent: true,
    });
    if (this.props.goToNotificationId) {
      this.props.router.push(getSpaceLink(this.props.spaceId));
    }
    this.props
      .dispatch(loadPresentNotifications(this.props.spaceId))
      .then(() => {
        this.setState({
          loadingPresent: false,
        });
      });
  };

  onLoadNotifications = () => {
    this.setState({
      loadingOlder: true,
    });

    let loadNotificationsPromise;
    if (this.props.goToNotificationId) {
      loadNotificationsPromise = this.props.dispatch(
        loadNotificationsAround(
          this.props.spaceId,
          this.props.goToNotificationId
        )
      );
    } else {
      loadNotificationsPromise = this.props.dispatch(
        loadNotifications({ channelId: this.props.spaceId })
      );
    }

    loadNotificationsPromise.then(() => {
      this.setState({
        loadingOlder: false,
      });
    });
  };

  onFullScreenPreview = (resourceId, channelId, showCommentsSidebar) => {
    this.props.dispatch(
      showPreviewerForChatItems(resourceId, channelId, showCommentsSidebar)
    );
  };

  onLoadOlder = () => {
    const { comments } = this.props;
    if (!comments) {
      return;
    }
    const oldestComment = comments.slice(0)[0];
    if (!oldestComment) {
      return;
    }
    this.setState({
      loadingOlder: true,
    });
    this.props
      .dispatch(
        loadNotificationsBefore(
          this.props.spaceId,
          oldestComment.Id,
          oldestComment.NotificationTimestamp
        )
      )
      .then(() => {
        this.setState({
          loadingOlder: false,
        });
      });
  };

  onLoadNewer = () => {
    if (this.props.hasNewerMessages) {
      this.setState({
        loadingNewer: true,
      });
      const { comments } = this.props;
      if (!comments) {
        return;
      }
      const newestComment = comments.slice(-1)[0];
      if (!newestComment) {
        return;
      }
      this.props
        .dispatch(loadNotificationsAfter(this.props.spaceId, newestComment.Id))
        .then(() => {
          this.setState({
            loadingNewer: false,
          });
        });
    }
  };

  markAsRead = spaceId => {
    const { dispatch, space } = this.props;
    dispatch(markAsReadBySpaceId(spaceId)).then(() => {
      if (space.IsConversation) {
        dispatch(loadConversationStats());
      } else {
        dispatch(loadStats(space.WorkspaceId));
      }
    });
  };

  renderMessage = (currentComment, previousComment, ref) => {
    let firstInGroup;
    let showNewMessage = false;
    let showDate = false;
    let lastReadUsers;

    if (this.props.lastRead) {
      lastReadUsers = Object.entries(this.props.lastRead).reduce(
        (arr, [email, value]) => {
          if (value.Id === currentComment.Id) {
            arr.push(email);
          }
          return arr;
        },
        []
      );
    }

    if (!previousComment || currentComment.Day !== previousComment.Day) {
      showDate = true;
    }
    if (currentComment.Id === this.props.earliestNewNotificationId) {
      showNewMessage = true;
    }
    let differentPreviousUser = false;
    let previousMessageOver10MinutesAgo = false;
    let previousMessageIncludedPreview = false;
    let isSystemMessage = false;
    try {
      if (!previousComment) {
        firstInGroup = true;
      } else {
        isSystemMessage =
          currentComment.Type === notificationType.ReminderFired || //TODO Change to handle all system messages
          previousComment.Type === notificationType.ReminderFired;
        differentPreviousUser =
          currentComment.CreatedBy.UserID !== previousComment.CreatedBy.UserID;
        previousMessageOver10MinutesAgo =
          differenceInMinutes(
            currentComment.NotificationTimestamp,
            previousComment.NotificationTimestamp
          ) > 10;
        previousMessageIncludedPreview =
          previousComment.Type === notificationType.ChannelResourceLinked ||
          previousComment.Type === notificationType.ChannelNewResource ||
          previousComment.Type ===
            notificationType.WorkspaceNewResource_DEPRECATED;
      }

      // different user than previous comment
      if (
        differentPreviousUser ||
        previousMessageOver10MinutesAgo ||
        previousMessageIncludedPreview ||
        isSystemMessage
      ) {
        firstInGroup = true;
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    return (
      <SpaceChatItems
        innerRef={ref}
        highlight={currentComment.Id === this.props.goToNotificationId}
        showNewMessage={showNewMessage}
        showDate={showDate}
        firstInGroup={firstInGroup}
        key={currentComment.Id}
        notification={currentComment}
        onDelete={this.onDelete}
        onDownload={this.onDownload}
        onTransferToOtixoDrive={this.onTransferToOtixoDrive}
        onEdit={this.onEdit}
        onShareMessage={this.onShareMessage}
        onMessageReply={this.onMessageReply}
        onFullScreenPreview={this.onFullScreenPreview}
        lastReadUsers={lastReadUsers}
      />
    );
  };

  getReplyComment = () => {
    const comment = this.props.comments.find(
      comment => comment.Id === this.state.replyId
    );
    if (!comment) {
      return null;
    }
    return {
      id: this.state.replyId,
      text: comment.Comment.Text,
      name: `${comment.CreatedBy.FirstName} ${comment.CreatedBy.LastName}`,
      timestamp: comment.NotificationTimestamp,
    };
  };

  render() {
    const {
      spaceId,
      hasOlderMessages,
      hasNewerMessages,
      comments,
      space,
      earliestNewNotificationId,
      goToNotificationId,
      hasHiddenMessages,
      lastScrollPosition,
      autoFocus,
    } = this.props;

    const lastUserComment = findLast(
      comments,
      c => c.Type === notificationType.ChannelComment && c.IsMe
    );

    const lastUserCommentFormatted = lastUserComment
      ? {
          text: lastUserComment.Comment.Text,
          id: lastUserComment.Id,
          timestamp: lastUserComment.NotificationTimestamp,
        }
      : null;

    const replyComment = this.getReplyComment();

    return (
      <div className="flex flex-auto">
        <FileDrop
          isActive
          spaceId={spaceId}
          showChatUploadHelpMessage
          dropzoneClassName="overflow-auto"
        >
          <Comments
            scrollTo={lastScrollPosition}
            onScroll={this.onScroll}
            canAddComment={!space.ProvisionalSpace}
            canUpload
            comments={comments}
            renderMessage={this.renderMessage}
            fetchLatest={this.onFetchLatest}
            goToNotificationId={goToNotificationId || earliestNewNotificationId}
            hasHiddenMessages={hasHiddenMessages}
            hasOlderMessages={hasOlderMessages}
            hasNewerMessages={hasNewerMessages}
            loadingOlder={this.state.loadingOlder}
            loadingNewer={this.state.loadingNewer}
            loadingPresent={this.state.loadingPresent}
            markAsRead={this.markAsRead}
            onAddComment={this.onAddComment}
            onJumpToPresent={this.onJumpToPresent}
            onLoadNewer={this.onLoadNewer}
            onLoadOlder={this.onLoadOlder}
            workspaceId={space.WorkspaceId}
            spaceId={spaceId}
            lastUserComment={lastUserCommentFormatted}
            replyComment={replyComment}
            onRemoveReply={this.onRemoveReply}
            autoFocus={autoFocus}
            canRecordVoiceMessage
          />
        </FileDrop>
      </div>
    );
  }
}
SpaceChat.propTypes = {
  spaceId: PropTypes.string,
  earliestNewNotificationId: PropTypes.string,
  comments: PropTypes.array.isRequired,
  hasOlderMessages: PropTypes.bool,
  hasNewerMessages: PropTypes.bool,
  space: PropTypes.object.isRequired,
  hasHiddenMessages: PropTypes.bool,
  lastRead: PropTypes.object,
  goToNotificationId: PropTypes.string,
  lastScrollPosition: PropTypes.number,
  autoFocus: PropTypes.bool,
  dispatch: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(() => {
  const getSpaceById = makeGetSpaceById();
  return (state, props) => ({
    comments: getFeedNotificationsBySpaceId(state, props.spaceId),
    hasOlderMessages: getHasOlderMessages(state, props.spaceId),
    hasNewerMessages: getHasNewerMessages(state, props.spaceId),
    space: getSpaceById(state, props.spaceId),
    earliestNewNotificationId: getEarliestNewNotificationIdBySpaceId(
      state,
      props.spaceId
    ),
    hasHiddenMessages: getHasHiddenMessages(state, props.spaceId),
    lastRead: getLastReadBySpaceId(state, props.spaceId),
    lastScrollPosition: getScrollPositionById(state, props.spaceId),
  });
})(withRouter(SpaceChat));
