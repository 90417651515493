import { changeStatus } from 'DataLayer/Workspaces/Members/put';
import WORKSPACE_MEMBER_STATUS from 'DataLayer/Constants/workspaceMemberStatus';
import { displayErrorToast } from '../../Toast/displayToast';
import loadMembersByWorkspaceId from './loadMembersByWorkspaceId';
import { captureException } from '../../../Helpers/sentry';

const rejectMember = (workspaceId, memberId) => dispatch =>
  changeStatus(workspaceId, memberId, WORKSPACE_MEMBER_STATUS.REJECTED).then(
    () => {
      dispatch(loadMembersByWorkspaceId(workspaceId));
    },
    error => {
      dispatch(displayErrorToast('toast.rejectMember.error'));
      captureException(error);
      throw error;
    }
  );

export default rejectMember;
