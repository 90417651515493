export const BANDWIDTH_COST_PER_GIG = 0.49;

export const plans = {
  Free: {
    Title: 'Free',
    PaymentCode: null,
    level: 0,
    annual: false,
    free: true,
  },
  Transfer: {
    Title: 'Transfer',
    PaymentCode: 'otixo_transfers_first',
    level: 0,
    annual: false,
    transfer: true,
  } /*
  Transfer_Annual: {
    Title: 'Transfer',
    PaymentCode: 'otixo_transfers_annual',
    level: 0,
    annual: true,
    transfer: true,
  },*/,
  Basic_Old: {
    Title: 'Basic',
    PaymentCode: 'basic',
    level: 1,
    annual: false,
    basic: true,
    old: true,
  },
  Advanced_Old: {
    Title: 'Advanced',
    PaymentCode: 'advanced',
    level: 2,
    annual: false,
    advanced: true,
    old: true,
  },
  Pro_Old: {
    Title: 'Pro',
    PaymentCode: 'pro',
    level: 3,
    annual: false,
    pro: true,
    old: true,
  },
  Basic_Annual_Old: {
    Title: 'Basic Annual',
    PaymentCode: 'basic_annual',
    level: 1,
    annual: true,
    basic: true,
    old: true,
  },
  Advanced_Annual_Old: {
    Title: 'Advanced Annual',
    PaymentCode: 'advanced_annual',
    level: 2,
    annual: true,
    advanced: true,
    old: true,
  },
  Pro_Annual_Old: {
    Title: 'Pro Annual',
    PaymentCode: 'pro_annual',
    level: 3,
    annual: true,
    pro: true,
    old: true,
  },
  Otixo_Standard: {
    Title: 'Business (monthly)',
    PaymentCode: 'otixo_standard',
    level: 2,
    annual: false,
    standard: true,
    business: true,
    price: 8.0,
  },
  Otixo_Advanced: {
    Title: 'Education (monthly)',
    PaymentCode: 'otixo_advanced',
    level: 3,
    annual: false,
    advanced: true,
    education: true,
    price: 15.0,
  },
  Otixo_Standard_Annual: {
    Title: 'Business (yearly)',
    PaymentCode: 'otixo_standard_annual',
    level: 2,
    annual: true,
    standard: true,
    business: true,
    price: 6.67,
  },
  Otixo_Advanced_Annual: {
    Title: 'Education (yearly)',
    PaymentCode: 'otixo_advanced_annual',
    level: 3,
    annual: true,
    advanced: true,
    education: true,
    price: 12.5,
  },
};

export function getCouponCodeByPlanCode(planCode) {
  return '';
}

export function xxxgetPlanByName(name) {
  if (!name) {
    return plans.Free;
  }
  const plan = Object.values(plans).find(p => p.Title === name);
  return plan;
}

export function getPlanByPaymentCode(code) {
  const plan = Object.values(plans).find(p => p.PaymentCode === code);
  return plan;
}

export function isFree(plan) {
  return plan?.free || false;
}

export function isTransfer(plan) {
  return plan.transfer || false;
}

export function isAnnual(plan) {
  return plan.annual || false;
}

export function xxxisStandard(plan) {
  return plan.standard || false;
}

export function xxxisAdvanced(plan) {
  return plan.advanced || false;
}

export const PLAN_CHANGE_TYPE = {
  NoChange: 'NoChange',
  Upgrade: 'Upgrade',
  Downgrade: 'Downgrade',
  BillMonthly: 'BillMonthly',
  BillYearly: 'BillYearly',
};

export function getPlanChangeType(currentPlan, newPlan, billMonthly) {
  if (!currentPlan) {
    return PLAN_CHANGE_TYPE.Upgrade;
  }
  if (currentPlan.old) {
    return PLAN_CHANGE_TYPE.Upgrade;
  }
  if (currentPlan.level > newPlan.level) {
    // downgrade
    return PLAN_CHANGE_TYPE.Downgrade;
  } else if (currentPlan.level < newPlan.level) {
    // upgrade
    return PLAN_CHANGE_TYPE.Upgrade;
  } else if (billMonthly && currentPlan.annual) {
    // switch to bill monthly
    return PLAN_CHANGE_TYPE.BillMonthly;
  } else if (!billMonthly && !currentPlan.annual) {
    // switch to bill yearly
    return PLAN_CHANGE_TYPE.BillYearly;
  }
  // current
  return PLAN_CHANGE_TYPE.NoChange;
}
