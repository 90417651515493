import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router';
import { Popup, Divider, Input } from 'semantic-ui-react';
import CollaboratorCard from './CollaboratorCard';
import { getSpaceLink } from 'pages';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { SendIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

export class CollaboratorCardPopup extends React.PureComponent {
  state = { message: '', sending: false };

  onMessageChange = event => {
    this.setState({ message: event.target.value });
  };

  onMessageKeyPress = event => {
    if (event.key === 'Enter') {
      this.onAddComment();
    }
  };

  onSendButtonClick = () => {
    this.onAddComment();
  };

  onAddComment = () => {
    if (this.state.message.length === 0) {
      return;
    }
    const { directChatId, onAddSpaceComment } = this.props;
    this.setState({ sending: true });
    onAddSpaceComment(directChatId, this.state.message)
      .then(() => {
        this.navigateToDirectChat();
        this.setState({ sending: false, message: '' });
      })
      .catch(() => {
        this.setState({ sending: false });
      });
  };

  navigateToDirectChat = () => {
    const { router, directChatId } = this.props;
    router.push(getSpaceLink(directChatId));
  };

  render() {
    const { intl, collaborator, children, directChatId } = this.props;

    if (!collaborator || !children) {
      return null;
    }
    if (!collaborator.Email) {
      return children;
    }
    return (
      <Popup
        basic
        inverted
        on="click"
        wide="very"
        trigger={<span className="cursor-pointer">{children}</span>}
      >
        <CollaboratorCard collaborator={collaborator} />
        {directChatId && (
          <div className="flex column" data-test="direct-chat">
            <Divider inverted />
            <Link to={getSpaceLink(directChatId)} className="white">
              <FormattedMessage id="CollaboratorCardPopup.view-direct-chat" />
            </Link>
            <Divider inverted />
            <DisableInBackupMode>
              <div className="flex center">
                <Input
                  autoFocus
                  icon
                  loading={this.state.sending}
                  disabled={this.state.sending}
                  placeholder={intl.formatMessage({
                    id: 'CollaboratorCardPopup.send-direct-message',
                  })}
                  value={this.state.message}
                  onChange={this.onMessageChange}
                  onKeyPress={this.onMessageKeyPress}
                />
                <WeTeamIcon
                  Icon={SendIcon}
                  onClick={this.onSendButtonClick}
                  color="white"
                  className="ml-2"
                  data-testid="send"
                />
              </div>
            </DisableInBackupMode>
          </div>
        )}
      </Popup>
    );
  }
}

CollaboratorCardPopup.propTypes = {
  children: PropTypes.node.isRequired,
  collaborator: PropTypes.object.isRequired,
  directChatId: PropTypes.string,
  onAddSpaceComment: PropTypes.func,
  intl: intlShape.isRequired,
  router: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withRouter(injectIntl(CollaboratorCardPopup));
