import { update } from 'DataLayer/Spaces/update';

import * as spaceActions from './actions';

const canMigrateSpace = (space, canMigrate) => dispatch => {
  update(space.Id, {
    CanMigrate: canMigrate,
  }).then(() => {
    dispatch(spaceActions.canMigrateSpace(space.Id, canMigrate));
  });
};

export default canMigrateSpace;
