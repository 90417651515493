import PropTypes from 'prop-types';
import React from 'react';

import ReminderTarget from 'Components/Reminders/ReminderTarget';
import SnoozeReminderButton from 'Components/Reminders/SnoozeReminderButton';
import { FormattedMessage } from 'react-intl';
import LinkifyWrapper from 'Components/Linkify/Linkify';

const Reminder = ({ notification, channelId }) => {
  return (
    <div>
      <span className="light-text">
        <FormattedMessage id="Reminder.here-is-your-reminder" />
      </span>
      <blockquote className="mb-2">
        <LinkifyWrapper>{notification.Reminder.Note}</LinkifyWrapper>
        <ReminderTarget
          canClick={notification.Reminder.TargetPresent}
          target={notification.Reminder.Target}
          reminderType={notification.Reminder.TargetType}
          channelId={channelId}
          truncate={false}
        />
        {!notification.Reminder.TargetPresent && (
          <div>
            <small className="light-text">
              <FormattedMessage id="Reminder.RemovedSinceSending" />
            </small>
          </div>
        )}
      </blockquote>
      <SnoozeReminderButton id={notification.Reminder.Id} />
    </div>
  );
};

Reminder.propTypes = {
  notification: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
};

export default Reminder;
