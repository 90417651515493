import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getProvisionalWorkspaces } from 'store/Workspaces/selectors';

import WorkspaceInvitation from './WorkspaceInvitation';

const WorkspaceInvitations = ({ workspaces }) => (
  <>
    {workspaces.map(workspace => (
      <WorkspaceInvitation key={workspace.Id} workspace={workspace} />
    ))}
  </>
);

WorkspaceInvitations.propTypes = {
  workspaces: PropTypes.array.isRequired,
};

export default connect(state => ({
  workspaces: getProvisionalWorkspaces(state),
}))(WorkspaceInvitations);
