import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {
  Message,
  Button,
  Divider,
  Select,
  Form /*, Loader*/,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { getSpaceMigrateLink } from 'pages';

import { getSpaceById } from 'store/Spaces/selectors';
import get from 'DataLayer/Migrate/get';
import startMigration from 'store/Migrate/startMigration';
import canMigrateSpace from 'store/Spaces/canMigrateSpace';

import CloseIcon from '../../Icons/CloseIcon';

class ChatMigrateMessage extends React.Component {
  state = {
    hidden: false,
    selectedWorkspace: null,
    workspaces: [],
  };

  componentDidMount() {
    this.setupComponent();
  }

  componentDidUpdate(prevProps) {
    const { space } = this.props;
    if (prevProps.space.Id !== space.Id) {
      this.setupComponent();
    }
  }

  onWorkspaceSelected = workspace => {
    this.setState({
      selectedWorkspace: workspace,
    });
  };

  onStartMigration = () => {
    const { dispatch, space, router } = this.props;
    const { selectedWorkspace } = this.state;
    dispatch(
      startMigration(
        selectedWorkspace.WorkspaceId,
        space.Id,
        selectedWorkspace.ChatId
      )
    ).then(() => {
      router.push(getSpaceMigrateLink(space.Id));
    });
  };

  onDontShowAgain = () => {
    const { dispatch, space } = this.props;
    dispatch(canMigrateSpace(space, false));
  };

  onClose = () => {
    this.setState({
      hidden: true,
    });
  };

  setupComponent() {
    if (!this.props.space.CanMigrate) {
      return;
    }
    this.setState(
      {
        workspaces: [],
        selectedWorkspace: null,
        hidden: false,
      },
      () => {
        this.getWorkspaces();
      }
    );
  }

  getWorkspaces() {
    const { space } = this.props;
    get(space.Id).then(workspaces => {
      this.setState({
        workspaces,
      });
    });
  }

  render() {
    const { space } = this.props;
    const { workspaces, selectedWorkspace, hidden } = this.state;

    if (hidden) {
      return null;
    }

    if (!space.CanMigrate) {
      return null;
    }

    if (workspaces.length === 0) {
      return null;
    }

    return (
      <div className="margin-10">
        <Message info>
          <div className="flex">
            <div className="flex-auto">
              <Message.Header>
                <FormattedMessage id="ChatMigrateMessage.Title" />
              </Message.Header>
              <FormattedMessage id="ChatMigrateMessage.Message" />
              <Divider hidden />
              <Form autoComplete="off">
                <Form.Field>
                  <Select
                    style={{
                      maxWidth: 450,
                    }}
                    placeholder="Select a target Work Space"
                    search
                    name="workspace"
                    onChange={(event, data) => {
                      this.onWorkspaceSelected(data.value);
                    }}
                    options={workspaces.map(workspace => ({
                      key: workspace.WorkspaceId,
                      text: `${workspace.WorkspaceName} - ${
                        workspace.NotificationsCount
                      } messages`,
                      value: workspace,
                    }))}
                    value={selectedWorkspace}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    primary
                    disabled={!selectedWorkspace}
                    onClick={this.onStartMigration}
                  >
                    <FormattedMessage id="ChatMigrateMessage.StartButtonText" />
                  </Button>
                  <Button basic onClick={this.onDontShowAgain}>
                    <FormattedMessage id="ChatMigrateMessage.DontShowAgainButtonText" />
                  </Button>
                </Form.Field>
                <FormattedMessage id="ChatMigrateMessage.Note" />
              </Form>
            </div>
            <div>
              <CloseIcon
                onClick={this.onClose}
                className="cursor-pointer"
                width={12}
                height={12}
              />
            </div>
          </div>
        </Message>
      </div>
    );
  }
}

ChatMigrateMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  space: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, props) => ({
  space: getSpaceById(state, props.spaceId),
}))(withRouter(ChatMigrateMessage));
