import { post as postWorkspaceOffer } from 'DataLayer/Workspaces/Offers/post';
import loadOffers from 'store/Workspaces/Offer/loadOffer';
import loadWorkspaces from 'store/Workspaces/loadWorkspaces';
import { getWorkspaceById } from 'store/Workspaces/selectors';

const acceptOffer = (workspaceId, offerId) => async (dispatch, getState) => {
  const state = getState();
  const workspace = getWorkspaceById(state, workspaceId);

  await postWorkspaceOffer({
    workspaceId,
    workspaceName: workspace.Name,
    workspaceEmail: workspace.OwnerMail,
    offerId,
  });
  await dispatch(loadWorkspaces());
  dispatch(loadOffers(workspaceId));
};

export default acceptOffer;
