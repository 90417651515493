import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { networkStatusChanged } from 'store/Connectivity/index';
import useOnlineStatus from 'Hooks/useNetworkStatus';
import ActionableNotification from 'Components/Modals/ActionableNotifications/ActionableNotification';
import { FormattedMessage } from 'react-intl';

import styles from './NetworkStatus.module.css';

export const NetworkStatus = ({ onChange }) => {
  const [showOfflineMessage, setShowOfflineMessage] = useState(false);
  const online = useOnlineStatus();

  /*
    Allow user to manually hide the notification. 
    If the network comes back up, then goes away again,
    the message will re-appear
  */
  useEffect(
    () => {
      onChange(online);
      setShowOfflineMessage(!online);
    },
    [onChange, online]
  );

  return (
    <div className={styles.offline}>
      <ActionableNotification
        isOpen={showOfflineMessage}
        onClose={() => {
          setShowOfflineMessage(false);
        }}
        title={<FormattedMessage id="NetworkStatus.Offline.Title" />}
        body={<FormattedMessage id="NetworkStatus.Offline.Message" />}
      />
    </div>
  );
};

NetworkStatus.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    onChange: networkStatusChanged,
  }
)(NetworkStatus);
