import React from 'react';

import PropTypes from 'prop-types';

import styles from './WorkspaceIcon.module.scss';
import classNamesBind from 'classnames/bind';
import Avatar from 'Components/Avatar/Avatar';

function WorkspaceIcon({ workspace, size = 40, canMove }) {
  let initials;
  try {
    initials = workspace.Name.substring(0, 1).toUpperCase();
  } catch (ex) {
    initials = '';
  }

  return (
    <Avatar
      width={size}
      height={size}
      src={workspace.AvatarUrl}
      initials={initials}
      color={workspace.Color}
      noRightMargin
      data-testid="workspace-icon"
      className={classNamesBind({
        [styles.move]: canMove,
      })}
    />
  );
}

WorkspaceIcon.propTypes = {
  workspace: PropTypes.object.isRequired,
  size: PropTypes.number,
  canMove: PropTypes.bool,
};

export default WorkspaceIcon;
