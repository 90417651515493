import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'Components/Collaborators/Avatar';
import { Popup } from 'semantic-ui-react';

function ParticipantAvatar({ collaborator, ...props }) {
  return (
    <Popup
      inverted
      position="top center"
      trigger={
        <Avatar
          avatarUrl={collaborator.avatarUrl}
          color={collaborator.color}
          email={collaborator.email}
          firstName={collaborator.firstName}
          lastName={collaborator.lastName}
          width={22}
          height={22}
          showStatus={false}
          {...props}
        />
      }
      content={`${collaborator.firstName} ${collaborator.lastName}`}
      size="mini"
    />
  );
}

ParticipantAvatar.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default ParticipantAvatar;
