import axios from 'axios';
import { CONVERSATION_MEMBERS } from '../endpoints';

export const getMembers = async () => {
  const response = await axios.get(CONVERSATION_MEMBERS);
  return response.data.Members.map(member => ({
    ...member,
    Name: `${member.FirstName} ${member.LastName}`,
  }));
};
