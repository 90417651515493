export const ERROR_CODES = {
  PRODUCT_LIMIT: 'product_limit',
  UNSUPPORTED: 'unsupported',
  CRYPTO_KEY_NOT_FOUND: 'crypto_key_not_found',
  RESOURCE_ALREADY_SHARED: 'resource_already_shared',
  FORBIDDEN: 'forbidden',
  INSUFFICIENT_STORAGE: 'insufficient_storage',
  RESOURCE_NOT_FOUND: 'resource_not_found',
  GENERAL: 'general',
  TOO_MANGE_GUESTS: 'too_many_guests',
  ALREADY_GUEST: 'already_guest',
  ALREADY_MEMBER: 'already_member',
  existing_workspace_ownership: 'existing_workspace_ownership',
  existing_workspace_membership: 'existing_workspace_membership',
  mails_already_requested: 'mails_already_requested',
  mails_already_configured: 'mails_already_configured',
  WORKSPACE_NAME_ALREADY_IN_USE: 'workspace_name_already_in_use',
  active_subscription_for_workspace: 'active_subscription_for_workspace',
  ALREADY_PRESENT: 'already_present',
};

const ERROR_SUB_CODES = {
  BANDWIDTH: 'bandwidth',
  NO_QUOTA: 'no_quota',
  NO_SPACE_QUOTA: 'no_space_quota',
  WEBDAV: 'webdav',
  FILE_TRANSFER: 'file_transfer',
  SPACES: 'spaces',
  ENCRYPTION: 'encryption',
  EXTRA_CONNECTORS: 'extra_connectors',
  SHARING_CONTROLS: 'sharing_controls',
  PUBLIC_RESOURCE_SIZE_LIMIT: 'public_resource_size_limit',
  PACKAGE_INCOMPATIBLE: 'package_incompatible',
};

export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ALREADY_EXISTS: 409,
  BAD_REQUEST: 400,
};

export function isEditExpiryExceeded(error) {
  return error && error.code === ERROR_CODES.FORBIDDEN;
}

export function isProductLimit(error) {
  return error && error.code === ERROR_CODES.PRODUCT_LIMIT;
}

export function isUnsupported(error) {
  return error && error.code === ERROR_CODES.UNSUPPORTED;
}

export function isBandwidthExceeded(error) {
  return (
    error &&
    error.code === ERROR_CODES.PRODUCT_LIMIT &&
    error.subcode === ERROR_SUB_CODES.BANDWIDTH
  );
}

export function isNoQuotaException(error) {
  return (
    error &&
    error.code === ERROR_CODES.FORBIDDEN &&
    error.subcode === ERROR_SUB_CODES.NO_QUOTA
  );
}

export function isNoSpaceQuotaException(error) {
  return (
    error &&
    error.code === ERROR_CODES.INSUFFICIENT_STORAGE &&
    error.subcode === ERROR_SUB_CODES.NO_SPACE_QUOTA
  );
}

export function isCryptoKeyNotFound(error) {
  return error && error.code === ERROR_CODES.CRYPTO_KEY_NOT_FOUND;
}

export function isResourceAlreadyShared(error) {
  return error && error.code === ERROR_CODES.RESOURCE_ALREADY_SHARED;
}

export function isLinkSizeException(error) {
  return (
    error &&
    error.code === ERROR_CODES.FORBIDDEN &&
    error.subcode === ERROR_SUB_CODES.PUBLIC_RESOURCE_SIZE_LIMIT
  );
}

export function isSubscriptionChangeForbidden(error) {
  return (
    error &&
    error.code === ERROR_CODES.FORBIDDEN &&
    error.subcode === ERROR_SUB_CODES.PACKAGE_INCOMPATIBLE
  );
}

export function isTooManyGuests(error) {
  return error && error.code === ERROR_CODES.TOO_MANGE_GUESTS;
}

export function isOwnerHasTooManyGuests(error) {
  return error && error.code === ERROR_CODES.OWNER_HAS_TOO_MANGE_GUESTS;
}

export function isEmailAlreadyPresent(error) {
  return error && error.code === ERROR_CODES.ALREADY_PRESENT;
}

export function isCollaboratorAlreadyGuest(error) {
  return error && error.code === ERROR_CODES.ALREADY_GUEST;
}

export function isCollaboratorAlreadyMember(error) {
  return error && error.code === ERROR_CODES.ALREADY_MEMBER;
}

export function isWorkspaceNameAlreadyInUse(error) {
  return error && error.code === ERROR_CODES.WORKSPACE_NAME_ALREADY_IN_USE;
}

export function isActiveSubscriptionForWorkspace(error) {
  return error && error.code === ERROR_CODES.active_subscription_for_workspace;
}

export function isEmailAlreadyInUse(error) {
  return (
    error.code === ERROR_CODES.mails_already_requested ||
    error.code === ERROR_CODES.mails_already_configured
  );
}
export function isExistingWorkspaceOwnership(error) {
  return error.code === ERROR_CODES.existing_workspace_ownership;
}

export function isExistingWorkspaceMembership(error) {
  return error.code === ERROR_CODES.existing_workspace_membership;
}

export default {};
