import { get } from 'DataLayer/Conversations/get';
import { setSpacesRequestInProgress } from '../Requests/setRequestInProgress';
import { spacesLoaded } from 'store/Spaces/actions';

const loadConversations = () => dispatch => {
  dispatch(setSpacesRequestInProgress(true));

  return get().then(
    spaces => {
      dispatch(spacesLoaded(spaces));
      dispatch(setSpacesRequestInProgress(false));
      return spaces;
    },
    e => {
      dispatch(setSpacesRequestInProgress(false));
      throw e;
    }
  );
};

export default loadConversations;
