import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { motion } from 'framer-motion';

import { useMessageSelectionContext } from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionContext';

import styles from './MessageSelectionCheckbox.module.css';
import classNames from 'classnames/bind';

const open = { opacity: 1, width: 25 };
const collapsed = { opacity: 0, width: 0, overflow: 'hidden' };

const MessageSelectionCheckbox = ({
  messageId,
  canDelete,
  canShare,
  canLaunch,
  resourceId,
}) => {
  const { isSelecting, messageSelected, isMessageSelected } =
    useMessageSelectionContext();

  const canSelect = canDelete || canShare || canLaunch;

  return (
    <motion.div
      initial={false}
      animate={isSelecting ? open : collapsed}
      className={classNames(styles.container, {
        [styles.selectable]: canSelect,
      })}
      onClick={() => {
        messageSelected({
          id: messageId,
          canDelete,
          canShare,
          canLaunch,
          resourceId,
        });
      }}
    >
      {canSelect && <Checkbox checked={isMessageSelected?.(messageId)} />}
    </motion.div>
  );
};

MessageSelectionCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canShare: PropTypes.bool.isRequired,
  resourceId: PropTypes.string,
};

export default MessageSelectionCheckbox;
