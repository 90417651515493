import React from 'react';
import PropTypes from 'prop-types';
import './Banner.scss';
import { Transition, Dimmer } from 'semantic-ui-react';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { CrossIcon } from 'Components/Icons/WeTeamIcon';

function Banner({
  onClose,
  hidden,
  onHideExtended,
  showExtended,
  children,
  extended,
}) {
  if (hidden) {
    return null;
  }

  return (
    <div className="banner">
      <div className="primary">
        <div className="banner-text">{children}</div>
        <WeTeamIcon
          onClick={onClose}
          Icon={CrossIcon}
          color="white"
          width={20}
          height={20}
          data-testid="banner-close"
        />
      </div>
      <Transition visible={showExtended} animation="slide down" duration={200}>
        <div className="extended">
          {extended}
          <Dimmer
            onClickOutside={onHideExtended}
            active={showExtended}
            page
            style={{ zIndex: 900 }}
          />
        </div>
      </Transition>
    </div>
  );
}

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  extended: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  showExtended: PropTypes.bool,
  onHideExtended: PropTypes.func,
};

export default Banner;
