import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getTagsByChannelIdUrl } from 'DataLayer/endpoints';

export const post = async ({ channelId, tag }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.post(
    getTagsByChannelIdUrl(channelId),
    tag,
    config
  );

  return data;
};
