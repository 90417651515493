import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import {
  getResourceTagsByChannelIdUrl,
  getTagsByChannelIdUrl,
} from 'DataLayer/endpoints';

export async function fetchResourceTagsByChannelId(channelId) {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };
  const { data } = await axios.get(
    getResourceTagsByChannelIdUrl(channelId),
    config
  );

  return data;
}

export async function fetchTagsByChannelId(channelId) {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };
  const { data } = await axios.get(getTagsByChannelIdUrl(channelId), config);

  return data;
}
