import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { markWorkspaceAsRead } from 'store/Workspaces/markWorkspaceAsRead';

import WeTeamIcon, { CommentCheckIcon } from 'Components/Icons/WeTeamIcon';
import { makeGetUnreadNotificationsCountByWorkspaceId } from 'store/Notifications/selectors';
import { getIsWorkspaceMarkedAsUnread } from 'store/UI/selectors';

export const MarkWorkspaceAsUnreadOption = ({ workspaceId, showIcons }) => {
  const dispatch = useDispatch();

  const unreadNotificationsCount = useSelector(state =>
    makeGetUnreadNotificationsCountByWorkspaceId()(state, workspaceId)
  );

  const markedAsUnread = useSelector(state =>
    getIsWorkspaceMarkedAsUnread(state, workspaceId)
  );

  const onMarkAsRead = () => {
    dispatch(markWorkspaceAsRead(workspaceId));
  };

  if (unreadNotificationsCount === 0 && !markedAsUnread) {
    return null;
  }

  return (
    <Link
      className="item icon primary"
      activeClassName="active"
      onClick={onMarkAsRead}
    >
      {showIcons && <WeTeamIcon Icon={CommentCheckIcon} link={false} />}
      <FormattedMessage id={'WorkspaceMenuItems.MarkAsRead'} />
    </Link>
  );
};

MarkWorkspaceAsUnreadOption.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  showIcons: PropTypes.bool.isRequired,
};

export default MarkWorkspaceAsUnreadOption;
