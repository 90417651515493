import axios from 'axios';
import {
  getUnverifiedUserRequestAllowedConfig,
  MEETINGS,
  MEETINGS_SCHEDULED,
} from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from '../../config/config';

export function get() {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios.get(MEETINGS, config).then(response => response.data);
}

export const SCHEDULED_MEETING_STATE = {
  SCHEDULED: 'SCHEDULED',
  STARTED: 'STARTED',
};

export function getById(id, token) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  const params = new URLSearchParams();
  if (token) {
    params.append('token', token);
  }

  const url = `${MEETINGS_SCHEDULED}/${id}`;

  const urlWithParams =
    Array.from(params).length > 0 ? `${url}?${params.toString()}` : url;

  return axios.get(urlWithParams, config).then(response => response.data);
}
