import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

const NotificationBadge = ({ count, floating, ...others }) => {
  if (count <= 0) {
    return null;
  }
  return (
    <Label circular color="red" size="tiny" floating={floating} {...others}>
      {count}
    </Label>
  );
};

NotificationBadge.propTypes = {
  count: PropTypes.number.isRequired,
  floating: PropTypes.bool,
};

NotificationBadge.defaultProps = {
  count: 0,
  floating: true,
};

export default NotificationBadge;
