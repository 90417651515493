function doesTargetAcceptFiles(sourceItems, canCreateFile) {
  const copyingFiles = sourceItems.filter(item => !item.Folder);

  if (copyingFiles.length && !canCreateFile) {
    return false;
  }

  return true;
}

function doesTargetAcceptFolders(sourceItems, canCreateFolder) {
  const copyingFolders = sourceItems.filter(item => item.Folder);

  if (copyingFolders.length && !canCreateFolder) {
    return false;
  }

  return true;
}

function isMoveToOwnParent(sourceItems, targetId) {
  // sourceItems should NEVER be empty! But do the check anyway...
  if (sourceItems.length === 0) {
    return true;
  }
  // is the target folder a parent of the source files.
  return sourceItems[0].ParentId === targetId;
}

function isTargetInSourceItems(sourceItems, targetId) {
  // is the target folder a parent of the source files.
  return sourceItems.filter(item => item.Id === targetId).length > 0;
}

function canSourceItemsBeCopiedToTarget(
  sourceItems,
  withDelete,
  targetId,
  canCreateFile,
  canCreateFolder,
  isSpaceFolder
) {
  if (isSpaceFolder) {
    return false;
  }
  // Can't copy file if target doesn't accept files
  if (!doesTargetAcceptFiles(sourceItems, canCreateFile)) {
    return false;
  }

  // Can't copy folder if target doesn't accept folders
  if (!doesTargetAcceptFolders(sourceItems, canCreateFolder)) {
    return false;
  }

  // TODO: prevent copy/move to descendent

  // Can't copy/move an item to itself
  if (isTargetInSourceItems(sourceItems, targetId)) {
    return false;
  }

  if (withDelete) {
    // Can't move to the same parent
    if (isMoveToOwnParent(sourceItems, targetId)) {
      return false;
    }
  }
  return true;
}

export default canSourceItemsBeCopiedToTarget;
