import React from 'react';
import PropTypes from 'prop-types';

import Reminder from './Reminder';

function RemindersListByDate({
  title,
  reminders,
  showWorkspaceName = false,
  showAvatar = false,
}) {
  if (reminders.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mb-3">{title}</div>
      {reminders.map(reminder => (
        <Reminder
          reminder={reminder}
          key={reminder.Id}
          showWorkspaceName={showWorkspaceName}
          showAvatar={showAvatar}
        />
      ))}
    </>
  );
}

RemindersListByDate.propTypes = {
  title: PropTypes.element.isRequired,
  reminders: PropTypes.array.isRequired,
  showWorkspaceName: PropTypes.bool,
  showAvatar: PropTypes.bool,
};

export default RemindersListByDate;
