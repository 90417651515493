import * as Sentry from '@sentry/react';
import { get as getWorkspaces } from 'DataLayer/Workspaces/get';
import {
  workspacesRequested,
  addWorkspaces,
  workspacesRequestError,
} from './actions';

const loadWorkspaces = () => async dispatch => {
  try {
    dispatch(workspacesRequested());
    const { cachedWorkspaces, fetchedPromise } = await getWorkspaces();
    if (cachedWorkspaces) {
      dispatch(addWorkspaces(cachedWorkspaces));
    }
    const fetchedWorkspaces = await fetchedPromise;
    dispatch(addWorkspaces(fetchedWorkspaces));
    return fetchedWorkspaces;
  } catch (error) {
    Sentry.captureException(error);
    dispatch(workspacesRequestError());
  }
};

export default loadWorkspaces;
