import { searchShared } from 'DataLayer/Resources/search';
import { normalize } from 'normalizr';

import { setResourcesRequestInProgress } from '../Requests/setRequestInProgress';
import { getResourcesInProgress } from '../Requests/selectors';

import { mergeResources } from '../Entities/mergeResources';

import { resourceSchema } from './schemas';

import { displayErrorToast } from '../Toast/displayToast';

import * as actions from './actions';

export const SHARED_RESOURCES_KEY = 'SHARED';

export const loadSharedResources = () => (dispatch, getState) => {
  const state = getState();

  /* if this folder is already being requested, skip it! */
  if (getResourcesInProgress(state, SHARED_RESOURCES_KEY)) {
    return Promise.resolve();
  }

  dispatch(setResourcesRequestInProgress(SHARED_RESOURCES_KEY, true));

  return searchShared().then(
    ({ resources }) => {
      const normalizedResources = normalize(resources, [resourceSchema]);

      dispatch(mergeResources(normalizedResources.entities.resources));
      dispatch(
        actions.setResources(SHARED_RESOURCES_KEY, normalizedResources.result)
      );

      dispatch(setResourcesRequestInProgress(SHARED_RESOURCES_KEY, false));
    },
    () => {
      dispatch(displayErrorToast('toast.loadSharedResources.error'));
      dispatch(setResourcesRequestInProgress(SHARED_RESOURCES_KEY, false));
    }
  );
};

export default loadSharedResources;
