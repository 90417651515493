import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { isDesktopApp } from 'config/config';

import ThreeMonthTeamspaceUpgradeBillingInfoReminderModal from 'Components/WorkspaceOffer/ThreeMonthTeamspaceUpgradePromotionModal/BillingInformationReminder/ThreeMonthTeamspaceUpgradeBillingInfoReminderModal';

import { useShowBillingInformationReminder } from './useShowBillingInformationReminder';

function ThreeMonthTeamspaceUpgradeBillingInfoReminder({ workspaceId }) {
  const showBillingInformationReminder =
    useShowBillingInformationReminder(workspaceId);
  const [billingInformationVisible, setBillingInformationVisible] =
    useState(true);

  useEffect(() => {
    setBillingInformationVisible(true);
  }, [workspaceId]);

  if (isDesktopApp()) {
    return null;
  }

  if (showBillingInformationReminder && billingInformationVisible) {
    return (
      <ThreeMonthTeamspaceUpgradeBillingInfoReminderModal
        workspaceId={workspaceId}
        visible={billingInformationVisible}
        onClose={() => {
          setBillingInformationVisible(false);
        }}
      />
    );
  }
  return null;
}

ThreeMonthTeamspaceUpgradeBillingInfoReminder.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default ThreeMonthTeamspaceUpgradeBillingInfoReminder;
