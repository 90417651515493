export const PAGINATE_TYPES = {
  ALL_NOTIFICATIONS: 'ALL_NOTIFICATIONS',
};

export const SET_PAGINATE_LINK = 'PAGINATE/SET_LINK';

export default function(id, next) {
  return {
    type: SET_PAGINATE_LINK,
    id,
    next,
  };
}
