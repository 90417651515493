import PropTypes from 'prop-types';
import React from 'react';

import './Avatar.scss';
import { COLORS } from 'Helpers/Colors';
import ImageLoader from './ImageLoader';

export const ICON = {
  TEAM_SPACE: 'team-space',
  ACCOUNT: 'account',
};

class Avatar extends React.PureComponent {
  render() {
    const {
      src,
      initials,
      height,
      width,
      color,
      icon,
      circular,
      noRightMargin,
      className,
      ...props
    } = this.props;

    return (
      <ImageLoader src={src}>
        {(loading, error) => {
          const srcAvailable = src && !loading && !error;
          return (
            <div
              className={`initials-icon ${icon} ${className}`}
              style={{
                height,
                width,
                backgroundImage: srcAvailable ? `url(${src})` : null,
                backgroundSize: `${width}px ${height}px`,
                minWidth: width,
                fontSize: width / 2.5,
                backgroundColor: color,
                marginRight: noRightMargin ? 0 : 5,
                borderRadius: circular ? '50%' : 10,
              }}
              {...props}
            >
              {srcAvailable ? null : initials}
            </div>
          );
        }}
      </ImageLoader>
    );
  }
}

Avatar.propTypes = {
  src: PropTypes.string,
  initials: PropTypes.node,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.string,
  circular: PropTypes.bool,
  noRightMargin: PropTypes.bool,
};

Avatar.defaultProps = {
  width: 40,
  height: 40,
  color: COLORS.darkgrey,
  icon: '',
};

export default Avatar;
