import * as Sentry from '@sentry/react';
import { getNotifications, SET_TYPE } from 'DataLayer/Notifications/get';
import { addNotifications, removeNotificationBySpaceId } from './actions';
import { getHasNewerMessages } from './selectors';
import { displayErrorToast } from 'store/Toast/displayToast';
import { getSpaceById } from 'store/Spaces/selectors';

export const loadNotifications =
  ({
    channelId,
    notificationId = null,
    setType = null,
    limit,
    notificationTimestamp,
  }) =>
  (dispatch, getState) => {
    const state = getState();
    const channel = getSpaceById(state, channelId);
    return getNotifications({
      channelId,
      notificationId,
      setType,
      limit,
      workspaceId: channel.WorkspaceId,
      notificationTimestamp,
    }).then(({ cachedNotifications, fetchedPromise }) => {
      const overwriteNotifications = !setType;

      const currentHasNewerMessages = getHasNewerMessages(state, channelId);

      const dispatchNotifications = (notifications = {}) => {
        dispatch(
          addNotifications(
            channelId,
            notifications.Notifications,
            notifications.HasOlderMessages,
            /*
            We only care about HasNewerMessages when requesting
            messages "around"
          */
            setType === SET_TYPE.before
              ? currentHasNewerMessages
              : notifications.HasNewerMessages,
            notifications.HasHiddenNotifications,
            overwriteNotifications,
            notifications.LastRead
          )
        );
      };
      /*
        Use cached notifications when
          - loading first page
          - loading older pages
      */
      try {
        if ((!setType || setType === SET_TYPE.before) && cachedNotifications) {
          dispatchNotifications(cachedNotifications);
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      return fetchedPromise.then(fetchedNotifications => {
        dispatchNotifications(fetchedNotifications);
      });
    });
  };

export const loadPresentNotifications = channelId => dispatch => {
  dispatch(removeNotificationBySpaceId(channelId));
  return dispatch(loadNotifications({ channelId }));
};

export const loadNotificationsAround =
  (channelId, notificationId) => dispatch => {
    dispatch(removeNotificationBySpaceId(channelId));
    return dispatch(
      loadNotifications({ channelId, notificationId, setType: SET_TYPE.around })
    ).catch(async error => {
      dispatch(loadNotifications(channelId)).finally(() => {
        dispatch(displayErrorToast('toast.loadNotificationsAfter.error'));
      });
    });
  };

export const loadNotificationsBefore =
  (channelId, notificationId, notificationTimestamp) => dispatch =>
    dispatch(
      loadNotifications({
        channelId,
        notificationId,
        setType: SET_TYPE.before,
        notificationTimestamp,
      })
    );

export const loadNotificationsAfter =
  (channelId, notificationId, limit) => dispatch =>
    dispatch(
      loadNotifications({
        channelId,
        notificationId,
        setType: SET_TYPE.after,
        limit,
      })
    );

export default loadNotifications;
