import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import { Popup } from 'semantic-ui-react';

import { useMessageSelectionContext } from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionContext';

import WeTeamIcon, {
  DeleteIcon,
  CrossIcon,
  ShareIcon,
  UploadIcon,
} from 'Components/Icons/WeTeamIcon';

import styles from './MessageSelectionBanner.module.css';

const open = { translateY: 0 };
const collapsed = { translateY: -100 };

const MessageSelectionBanner = () => {
  const {
    isSelecting,
    messages,
    cancelled,
    share,
    launch,
    deleteSelected,
    canDeleteAllMessages,
    canLaunch,
  } = useMessageSelectionContext();

  return (
    <motion.div
      initial={false}
      animate={isSelecting ? open : collapsed}
      className={styles.messageSelectionBanner}
    >
      <strong>
        <FormattedMessage
          id="MessageSelectionBanner.Title"
          values={{ total: messages.length }}
        />
      </strong>
      <div className={styles.buttons}>
        {messages.length > 0 && canLaunch && (
          <Popup
            size="mini"
            inverted
            trigger={
              <WeTeamIcon
                Icon={UploadIcon}
                width={25}
                height={25}
                onClick={launch}
                className={styles.icon}
              />
            }
            content={
              <FormattedMessage id="MessageSelectionBanner.LaunchButtonTooltip" />
            }
            on="hover"
            position="bottom center"
          />
        )}
        {messages.length > 0 && (
          <Popup
            size="mini"
            inverted
            trigger={
              <WeTeamIcon
                Icon={ShareIcon}
                width={22}
                height={22}
                onClick={share}
                className={styles.icon}
              />
            }
            content={
              <FormattedMessage id="MessageSelectionBanner.ShareButtonTooltip" />
            }
            on="hover"
            position="bottom center"
          />
        )}
        {messages.length > 0 && canDeleteAllMessages && (
          <Popup
            size="mini"
            inverted
            trigger={
              <WeTeamIcon
                Icon={DeleteIcon}
                width={25}
                height={25}
                onClick={deleteSelected}
                className={styles.icon}
              />
            }
            content={
              <FormattedMessage id="MessageSelectionBanner.DeleteButtonTooltip" />
            }
            on="hover"
            position="bottom center"
          />
        )}
        <Popup
          size="mini"
          inverted
          trigger={
            <WeTeamIcon
              Icon={CrossIcon}
              width={18}
              height={18}
              onClick={cancelled}
              className={styles.icon}
            />
          }
          content={<FormattedMessage id="Cancel" />}
          on="hover"
          position="bottom center"
        />
      </div>
    </motion.div>
  );
};

export default MessageSelectionBanner;
