import { createContext, useContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { showShareMessagesWithChannelModal } from 'store/Modals/actions';
import { displaySuccessToast } from 'store/Toast/displayToast';

import SpaceChatDeleteMultipleMessagesModal from '../../../Modals/SpaceChat/SpaceChatDeleteMultipleMessagesModal';

import LaunchFilesInDesktop from 'Components/LaunchFileInDesktop/LaunchFilesInDesktop';

const initialState = {
  isSelecting: false,
  messages: [],
  showDeleteModal: false,
  launching: false,
};

const MessageSelectionContext = createContext(initialState);

const actions = {
  CANCEL: 'CANCEL',
  MESSAGE_SELECTED: 'MESSAGE_SELECTED',
  SELECTED_IDS_CHANGED: 'SELECTED_IDS_CHANGED',
  DELETE_MODAL_VISIBILITY_CHANGED: 'DELETE_MODAL_VISIBILITY_CHANGED',
  TRIGGER_LAUNCH: 'TRIGGER_LAUNCH',
};

const reducer = (prevState, action) => {
  switch (action.type) {
    case actions.CANCEL:
      return initialState;
    case actions.TRIGGER_LAUNCH:
      return {
        ...prevState,
        launching: action.launch,
      };
    case actions.DELETE_MODAL_VISIBILITY_CHANGED:
      return {
        ...prevState,
        showDeleteModal: action.visible,
      };
    case actions.MESSAGE_SELECTED:
      const messages =
        prevState.messages.filter(message => message.id === action.id).length >
        0
          ? prevState.messages.filter(message => message.id !== action.id)
          : prevState.messages.concat({
              id: action.id,
              canShare: action.canShare,
              canDelete: action.canDelete,
              canLaunch: action.canLaunch,
              resourceId: action.resourceId,
            });
      return {
        ...prevState,
        isSelecting: true,
        messages,
      };
    default:
      return prevState;
  }
};

export const MessageSelectionProvider = ({
  children,
  workspaceId,
  channelId,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actions.CANCEL });
  }, [channelId, workspaceId]);

  const value = {
    ...state,
    canLaunch:
      state.messages.filter(message => message.canLaunch).length ===
      state.messages.length,
    canDeleteAllMessages:
      state.messages.filter(message => message.canDelete).length ===
      state.messages.length,
    cancelled: id => {
      dispatch({ type: actions.CANCEL });
    },
    messageSelected: ({ id, canDelete, canShare, canLaunch, resourceId }) => {
      dispatch({
        type: actions.MESSAGE_SELECTED,
        id,
        canDelete,
        canShare,
        canLaunch,
        resourceId,
      });
    },
    share: () => {
      reduxDispatch(
        showShareMessagesWithChannelModal(
          workspaceId,
          state.messages.map(message => message.id)
        )
      );
    },
    launch: () => {
      reduxDispatch(displaySuccessToast('LaunchFileInDesktop.OpeningMultiple'));
      dispatch({
        type: actions.TRIGGER_LAUNCH,
        launch: true,
      });
    },
    deleteSelected: () => {
      dispatch({
        type: actions.DELETE_MODAL_VISIBILITY_CHANGED,
        visible: true,
      });
    },
    isMessageSelected: id =>
      state.messages.filter(message => message.id === id).length > 0,
  };

  const onDeleteModalCancel = () => {
    dispatch({
      type: actions.DELETE_MODAL_VISIBILITY_CHANGED,
      visible: false,
    });
  };

  const onComplete = () => {
    value.cancelled();
  };

  return (
    <>
      {state.showDeleteModal && (
        <SpaceChatDeleteMultipleMessagesModal
          messages={state.messages}
          onComplete={onComplete}
          onCancel={onDeleteModalCancel}
        />
      )}
      {state.launching && (
        <LaunchFilesInDesktop
          resourceIds={state.messages.map(message => message.resourceId)}
          onLaunchComplete={onComplete}
        />
      )}
      <MessageSelectionContext.Provider value={value}>
        {children}
      </MessageSelectionContext.Provider>
    </>
  );
};

export const useMessageSelectionContext = () => {
  return useContext(MessageSelectionContext);
};
