import { FormattedMessage } from 'react-intl';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedDate } from 'react-intl';
import differenceInMinutes from 'date-fns/difference_in_minutes';

import ChatItemActions from 'Components/Chat/ChatItemActions';

import LazyWrapper from '../Loading/LazyWrapper';
import ChatItemReminderIcon from './ChatItemReminderIcon';
import ChatItemAvatar from './ChatItemAvatar';

import './ChatItem.scss';
import ChatItemName from './ChatItemName';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { StarIcon, ShareIcon } from 'Components/Icons/WeTeamIcon';
import MessageSelectionCheckbox from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionCheckbox';
import { Loader } from 'semantic-ui-react';
import LaunchFileInDesktop from 'Components/LaunchFileInDesktop/LaunchFileInDesktop';
import { getEditCommentExpiryMinutes, isDesktopApp } from 'config/config';
import EditDisabledModal from 'Components/Chat/EditDisabledModal';

const ChatItemEdit = React.lazy(() =>
  import('Components/Chat/ChatItemEdit' /* webpackChunkName: "ChatItemEdit" */)
);

class ChatItem extends PureComponent {
  state = { editing: false, showEditDisabledModal: false, showLaunch: false };

  onSave = value => {
    this.props.onEdit(this.props.id, value);
    this.stopEditing();
  };

  stopEditing = () => {
    this.setState({ editing: false });
  };

  startEditing = () => {
    const { timestamp } = this.props;
    const minutes = differenceInMinutes(new Date(), timestamp);

    if (minutes > getEditCommentExpiryMinutes()) {
      this.setState({ showEditDisabledModal: true });
      return;
    }
    this.setState({ editing: !this.state.editing });
  };

  onClose = () => {
    this.setState({
      showEditDisabledModal: false,
    });
  };

  onLaunch = () => {
    this.setState({
      showLaunch: true,
    });
  };

  onLaunchComplete = () => {
    this.setState({
      showLaunch: false,
    });
  };

  render() {
    const {
      timestamp,
      text,
      rawText,
      name,
      email,
      showName,
      spaceId,
      id,
      onDelete,
      onDownload,
      onTransferToOtixoDrive,
      canTransferToOtixoDrive,
      onShareMessage,
      onMessageReply,
      canDownload,
      canEdit,
      canShare,
      canReply,
      canDelete,
      avatar,
      forwarded,
      showSeenBy,
      showActions,
      // temporary,
      starred,
      canStar,
      canRemind,
      reminderId,
      showGutter,
      reminderTargetId,
      reminderTarget,
      reminderType,
      isSystem,
      shouldShowFullScreenPreviewOption,
      onFullScreenPreview,
      resourceId,
    } = this.props;
    const messageClass = classNames({
      'chat_message-content': true,
      editing: this.state.editing,
    });

    const canLaunch = isDesktopApp() && shouldShowFullScreenPreviewOption;

    return (
      <React.Fragment>
        {this.state.showEditDisabledModal && (
          <EditDisabledModal onClose={this.onClose} />
        )}
        {showGutter && (
          <div className="gutter">
            {showName && (
              <ChatItemAvatar
                avatar={avatar}
                email={email}
                spaceId={spaceId}
                name={name}
                isSystem={isSystem}
              />
            )}
          </div>
        )}
        <div className="chat-item">
          {showName && (
            <div className="chat_message_header">
              <span className="chat_person">
                <ChatItemName
                  spaceId={spaceId}
                  email={email}
                  name={name}
                  isSystem={isSystem}
                />
              </span>
              <span className="lighter-text">
                <FormattedDate
                  value={timestamp}
                  hour="2-digit"
                  minute="2-digit"
                />
                {this.state.showLaunch && (
                  <>
                    <Loader active inline size="tiny" className="mx-2" />
                    <em>
                      <LaunchFileInDesktop
                        resourceId={resourceId}
                        channelId={spaceId}
                        onLaunchComplete={this.onLaunchComplete}
                        showText
                      />
                    </em>
                  </>
                )}
              </span>
            </div>
          )}
          <div className={messageClass}>
            {this.state.editing && (
              <LazyWrapper>
                <ChatItemEdit
                  id={id}
                  text={rawText}
                  onSave={this.onSave}
                  onCancel={this.stopEditing}
                />
              </LazyWrapper>
            )}
            {!this.state.editing && (
              <div>
                <div className={forwarded ? 'chat_text quoted' : 'chat_text'}>
                  {forwarded && (
                    <p className="forwarded">
                      <WeTeamIcon
                        link={false}
                        Icon={ShareIcon}
                        width={18}
                        height={18}
                        className={'mr-2'}
                      />
                      <FormattedMessage id="ChatItem.forwarded" />
                    </p>
                  )}
                  {text}
                </div>
                {(starred || reminderId) && (
                  <div>
                    {starred && <WeTeamIcon Icon={StarIcon} link={false} />}
                    {reminderId && (
                      <ChatItemReminderIcon reminderId={reminderId} />
                    )}
                  </div>
                )}
                {showActions && (
                  <ChatItemActions
                    id={id}
                    spaceId={spaceId}
                    timestamp={timestamp}
                    canDelete={canDelete}
                    onDelete={onDelete}
                    canDownload={canDownload}
                    onDownload={onDownload}
                    onTransferToOtixoDrive={onTransferToOtixoDrive}
                    canTransferToOtixoDrive={canTransferToOtixoDrive}
                    onShare={onShareMessage}
                    onReply={onMessageReply}
                    canShare={canShare}
                    canReply={canReply}
                    onEdit={this.startEditing}
                    canEdit={canEdit}
                    showSeenBy={showSeenBy}
                    starred={starred}
                    canStar={canStar}
                    canRemind={canRemind}
                    reminderId={reminderId}
                    reminderTargetId={reminderTargetId}
                    reminderTarget={reminderTarget}
                    reminderType={reminderType}
                    shouldShowFullScreenPreviewOption={
                      shouldShowFullScreenPreviewOption
                    }
                    onFullScreenPreview={onFullScreenPreview}
                    onLaunch={this.onLaunch}
                    resourceId={resourceId}
                    canLaunch={canLaunch}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <MessageSelectionCheckbox
          messageId={id}
          canDelete={canDelete}
          canShare={canShare}
          resourceId={resourceId}
          canLaunch={canLaunch}
        />
      </React.Fragment>
    );
  }
}

ChatItem.propTypes = {
  id: PropTypes.string,
  timestamp: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  text: PropTypes.node.isRequired,
  rawText: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  showName: PropTypes.bool,
  spaceId: PropTypes.string,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  canTransferToOtixoDrive: PropTypes.bool,
  onTransferToOtixoDrive: PropTypes.func,
  onEdit: PropTypes.func,
  onShareMessage: PropTypes.func,
  onMessageReply: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  canShare: PropTypes.bool,
  canReply: PropTypes.bool,
  avatar: PropTypes.string,
  forwarded: PropTypes.bool,
  showSeenBy: PropTypes.bool,
  showActions: PropTypes.bool,
  temporary: PropTypes.bool,
  starred: PropTypes.bool,
  canStar: PropTypes.bool,
  canRemind: PropTypes.bool,
  reminderId: PropTypes.string,
  reminderTargetId: PropTypes.string,
  reminderTarget: PropTypes.object,
  reminderType: PropTypes.string,
  showGutter: PropTypes.bool,
  isSystem: PropTypes.bool,
  shouldShowFullScreenPreviewOption: PropTypes.bool,
  onFullScreenPreview: PropTypes.func,
  resourceId: PropTypes.string,
};

ChatItem.defaultProps = {
  showGutter: true,
  canStar: true,
  canRemind: true,
  canDelete: false,
  canShare: false,
};

export default ChatItem;
