import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { spaceSortChanged } from 'store/UI';
import { SORT_TYPE } from 'store/Spaces';
import Sorter from 'Components/AppNavigation/Components/Sorter';
import {
  getSortTypeByWorkspaceAndListType,
  hasSortedManuallyByWorkspaceAndListType,
} from '../../../store/UI/selectors';

class SpaceSorter extends PureComponent {
  render() {
    const {
      workspaceId,
      onSort,
      listType,
      sortType,
      hasSortedManually,
      showCustom,
    } = this.props;
    let items = [
      {
        id: 1,
        onSort: () => onSort(workspaceId, listType, SORT_TYPE.name),
        text: <FormattedMessage id="SpaceSorter.Name" />,
        active: sortType.column === SORT_TYPE.name.column,
      },
      {
        id: 2,
        onSort: () =>
          onSort(workspaceId, listType, SORT_TYPE.lastUpdateTimestamp),
        text: <FormattedMessage id="SpaceSorter.LastActivity" />,
        active: sortType.column === SORT_TYPE.lastUpdateTimestamp.column,
      },
    ];
    if (showCustom && hasSortedManually) {
      items = [
        ...items,
        {
          id: 3,
          onSort: () => onSort(workspaceId, listType, SORT_TYPE.custom),
          text: <FormattedMessage id="SpaceSorter.Custom" />,
          active: sortType === SORT_TYPE.custom,
        },
      ];
    }
    return <Sorter items={items} />;
  }
}

SpaceSorter.propTypes = {
  listType: PropTypes.string,
  workspaceId: PropTypes.string,
  showCustom: PropTypes.bool,
  sortType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hasSortedManually: PropTypes.string,
  onSort: PropTypes.func,
};

export default connect(
  (state, props) => ({
    sortType: getSortTypeByWorkspaceAndListType(
      state,
      props.workspaceId,
      props.listType
    ),
    hasSortedManually: hasSortedManuallyByWorkspaceAndListType(
      state,
      props.workspaceId,
      props.listType
    ),
  }),
  {
    onSort: spaceSortChanged,
  }
)(SpaceSorter);
