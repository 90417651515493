import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isToday from 'date-fns/is_today';
import isTomorrow from 'date-fns/is_tomorrow';
import isYesterday from 'date-fns/is_yesterday';
import isThisWeek from 'date-fns/is_this_week';

import { FormattedDate, FormattedMessage } from 'react-intl';

const ReminderDate = ({ date }) => {
  if (isToday(date)) {
    return <FormattedMessage id="ReminderDate.Today" />;
  } else if (isTomorrow(date)) {
    return <FormattedMessage id="ReminderDate.Tomorrow" />;
  } else if (isYesterday(date)) {
    return <FormattedMessage id="ReminderDate.Yesterday" />;
  } else if (isThisWeek(date)) {
    return <FormattedDate value={date} weekday="long" />;
  } else {
    return (
      <FormattedDate value={date} year="numeric" month="short" day="2-digit" />
    );
  }
};

ReminderDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
};

export default memo(ReminderDate);
