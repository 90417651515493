import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { put } from 'DataLayer/UserPresence/put';
import { isUserLoggedIn } from 'store/User/selectors';
import { windowFocussed } from 'store/Window';

function ElectronBlurFocusListener({ isUserLoggedIn, onWindowFocussed }) {
  useEffect(
    () => {
      const onFocus = event => {
        if (isUserLoggedIn) {
          put(true);
        }
        onWindowFocussed(true);
      };

      const onBlur = event => {
        if (isUserLoggedIn) {
          put(false);
        }
        onWindowFocussed(false);
      };

      window.ipcRenderer?.on('browser-window-focus', onFocus);
      window.ipcRenderer?.on('browser-window-blur', onBlur);

      return () => {
        window.ipcRenderer?.removeListener('browser-window-focus', onFocus);
        window.ipcRenderer?.removeListener('browser-window-blur', onBlur);
      };
    },
    [isUserLoggedIn, onWindowFocussed]
  );

  return null;
}

ElectronBlurFocusListener.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  onWindowFocussed: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isUserLoggedIn: isUserLoggedIn(state),
  }),
  {
    onWindowFocussed: windowFocussed,
  }
)(ElectronBlurFocusListener);
