import { rename as renameLinkedItem } from 'DataLayer/LinkedItems/update';
import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

export const RENAME_LINKEDITEM_SUCCESS = 'LINKEDITEM/RENAME_SUCCESS';

export const rename = (spaceId, resourceId, name) => dispatch => {
  return renameLinkedItem(spaceId, resourceId, name).then(
    () => {
      dispatch({
        type: RENAME_LINKEDITEM_SUCCESS,
        spaceId,
        resourceId,
        name,
      });
      dispatch(displaySuccessToast('toast.renameLinkedItem.success'));
    },
    () => {
      dispatch(displayErrorToast('toast.renameLinkedItem.error'));
    }
  );
};

export default rename;
