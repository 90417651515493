import { createSelector } from 'reselect';
import UPLOAD_STATUS from './uploadStatus';

const getUploadingIdsFromState = state => state.upload.uploadingIds;
const getUploadingFilesFromState = state => state.upload.uploadingFiles;
export const getPendingFilesFromState = state => state.upload.pendingFiles;
export const getPendingIdsFromState = state => state.upload.pendingIds;

export const getnumberOfUploading = createSelector(
  [getUploadingFilesFromState, getUploadingIdsFromState],
  (files, ids) =>
    ids
      .map(id => files[id])
      .filter(
        f =>
          f.status === UPLOAD_STATUS.UPLOADING ||
          f.status === UPLOAD_STATUS.PENDING ||
          f.status === undefined
      ).length
);

export const getnumberOfComplete = createSelector(
  [getUploadingFilesFromState, getUploadingIdsFromState],
  (files, ids) =>
    ids.map(id => files[id]).filter(f => f.status === UPLOAD_STATUS.COMPLETE)
      .length
);

export const getnumberOfFailed = createSelector(
  [getUploadingFilesFromState, getUploadingIdsFromState],
  (files, ids) =>
    ids.map(id => files[id]).filter(f => f.status === UPLOAD_STATUS.FAILED)
      .length
);

export const getPendingFiles = createSelector(
  [getPendingFilesFromState, getPendingIdsFromState],
  (pendingFiles, pendingIds) => pendingIds.map(file => pendingFiles[file])
);

export function getUploadingFileById(state, id) {
  return getUploadingFilesFromState(state)[id];
}

export function getUploadingIds(state) {
  return getUploadingIdsFromState(state);
}
