import { starMessage } from 'DataLayer/Notifications/put';
import { notificationStarAdded } from 'store/Notifications/actions';
import { displayErrorToast } from 'store/Toast/displayToast';
import { starredAdded, starredRemoved } from 'store/Starred';
import { STAR_TYPE } from 'store/Starred';
import { chatMessageStarredAnalyticsEvent } from 'Components/GoogleAnalytics/events';

const starNotification = (id, starred) => dispatch => {
  dispatch(notificationStarAdded(id, starred));

  return starMessage(id, starred).then(
    notification => {
      if (starred) {
        chatMessageStarredAnalyticsEvent();
        dispatch(starredAdded(STAR_TYPE.NOTIFICATION, notification));
      } else {
        dispatch(starredRemoved(notification.Id));
      }
    },
    error => {
      dispatch(notificationStarAdded(id, !starred)); // revert to previous
      dispatch(displayErrorToast('toast.star.error'));
      throw error;
    }
  );
};

export default starNotification;
