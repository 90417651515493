import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'semantic-ui-react';

import WeTeamIcon, { TagIcon } from 'Components/Icons/WeTeamIcon';

import { showManageTagsModal } from 'store/Modals/actions';

export const ManageTagsOption = ({ channelId }) => {
  const dispatch = useDispatch();

  return (
    <Menu.Item
      onClick={() => {
        dispatch(showManageTagsModal(channelId));
      }}
    >
      <WeTeamIcon Icon={TagIcon} link={false} className="ml-1" />
      <FormattedMessage id="SpaceOptions.ManageTags" />
    </Menu.Item>
  );
};

ManageTagsOption.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ManageTagsOption;
