const RESUME = 'AUDIO_PLAYER/RESUME';
const PLAY_REQUESTED = 'AUDIO_PLAYER/PLAY_REQUESTED';
const PAUSE = 'AUDIO_PLAYER/PAUSE';
const RESUME_REQUESTED = 'AUDIO_PLAYER/RESUME_REQUESTED';
const PAUSE_REQUESTED = 'AUDIO_PLAYER/PAUSE_REQUESTED';
const CLEAR = 'AUDIO_PLAYER/CLEAR';

export const play = (id, channelId, resourceId, notificationId) => ({
  type: PLAY_REQUESTED,
  id,
  channelId,
  resourceId,
  notificationId,
});

export const resumed = id => ({ type: RESUME, id });
export const clear = () => ({ type: CLEAR });
export const paused = id => ({ type: PAUSE, id });
export const pauseRequested = id => ({ type: PAUSE_REQUESTED, id });
export const resumeRequested = id => ({ type: RESUME_REQUESTED, id });

export const getAudioPlayerState = state => {
  return state.audioPlayer || initialState;
};
export const getIsPlaying = state => {
  return state.audioPlayer.state === AUDIO_PLAYER_STATES.playing;
};
export const getCurrentTrack = state => state.audioPlayer.currentTrack;

export const AUDIO_PLAYER_STATES = {
  playing: 'playing',
  play_requsted: 'play_requsted',
  pause_requsted: 'pause_requsted',
  resume_requested: 'resume_requested',
  pausing: 'pausing',
  paused: 'paused',
};

const initialState = {
  currentTrack: null,
  resourceId: null,
  channelId: null,
  notificationId: null,
  state: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR:
      return initialState;
    case PLAY_REQUESTED:
      return {
        ...state,
        state: AUDIO_PLAYER_STATES.play_requsted,
        currentTrack: action.id,
        channelId: action.channelId,
        resourceId: action.resourceId,
        notificationId: action.notificationId,
      };
    case RESUME:
      return {
        ...state,
        state: AUDIO_PLAYER_STATES.playing,
      };
    case PAUSE:
      return {
        ...state,
        state: AUDIO_PLAYER_STATES.paused,
      };
    case RESUME_REQUESTED:
      return {
        ...state,
        state: AUDIO_PLAYER_STATES.resume_requested,
      };
    case PAUSE_REQUESTED:
      return {
        ...state,
        state: AUDIO_PLAYER_STATES.pause_requsted,
      };

    default:
      return state;
  }
}
