import getAdapters from 'DataLayer/Adapters/get';

export const LOAD_ADAPTERS_SUCCESS = 'ADAPTERS/LOAD_SUCCESS';

function loadAdapters() {
  return dispatch =>
    getAdapters().then(adapters => {
      dispatch({
        type: LOAD_ADAPTERS_SUCCESS,
        adapters,
      });
      return adapters;
    });
}

export default loadAdapters;
