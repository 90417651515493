import React, { useEffect } from 'react';
import { Popup, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import loadReminders from 'store/Reminders/loadReminders';
import { getFutureReminders } from 'store/Reminders/selectors';

import { FormattedMessage } from 'react-intl';
import RemindersList from './RemindersList';
import FullScreenPopup from 'Components/Layouts/SidebarLayout/FullScreenPopup';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { TimeSquareIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

const AllRemindersButton = ({ dispatch, futureReminders }) => {
  useEffect(() => {
    dispatch(loadReminders());
  }, [dispatch]);

  const onOpen = event => {
    dispatch(loadReminders());
  };
  const isBackupMode = useIsBackupMode();

  return (
    <Popup
      basic
      disabled={isBackupMode}
      flowing
      on="click"
      position="bottom right"
      onOpen={onOpen}
      trigger={
        <span>
          <Popup
            on="hover"
            inverted
            disabled={isBackupMode}
            size="mini"
            position="bottom right"
            trigger={
              <span className="position-relative">
                <DisableInBackupMode>
                  <WeTeamIcon Icon={TimeSquareIcon} />
                </DisableInBackupMode>
                {futureReminders.length > 0 && (
                  <Label
                    color="blue"
                    floating
                    circular
                    size="mini"
                    style={{
                      top: -18,
                    }}
                  >
                    {futureReminders.length}
                  </Label>
                )}
              </span>
            }
          >
            <FormattedMessage id="AllRemindersButton.all-reminders" />
          </Popup>
        </span>
      }
      style={{
        paddingRight: 0,
      }}
    >
      <FullScreenPopup
        header={<FormattedMessage id="AllRemindersButton.Title" />}
      >
        <RemindersList
          reminders={futureReminders}
          showWorkspaceName
          showAvatar={false}
        />
      </FullScreenPopup>
    </Popup>
  );
};

AllRemindersButton.propTypes = {
  futureReminders: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  futureReminders: getFutureReminders(state),
}))(AllRemindersButton);
