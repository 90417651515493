import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import SpaceIcon from 'Components/Spaces/SpaceIcon';

import { isUserGuestBySpaceId } from 'store/Spaces/Collaborators/selectors';
import { getSpaceByIdWithCollaboratorEmail } from 'store/Spaces/selectors';

import './SpaceChatHeader.scss';

import SpaceOptions from '../../Spaces/SpaceOptions';
import SingleSpaceOptions from '../../Spaces/SingleSpaceOptions';
import SidebarLayoutHeader from 'Components/Layouts/SidebarLayout/SidebarLayoutHeader';
import CollaboratorInfoContainer from 'Components/Collaborators/CollaboratorInfoContainer';
import { getLocale } from 'store/Locale/selectors';
import SkeletonLine from 'Views/Skeleton/SkeletonLine';
import { prepareWorkspaceTour } from 'store/Tours/prepareWorkspaceTour';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { ArrowDown2Icon } from 'Components/Icons/WeTeamIcon';
import ChannelOptions from 'Components/Chat/SpaceChat/ChannelOptions';

class SpaceChatHeader extends React.PureComponent {
  onTourSelected = tourFn => {
    const { space, onPrepareTour, locale } = this.props;
    onPrepareTour(space);
    tourFn(locale);
  };

  renderMenuOptions() {
    const { space } = this.props;
    if (space.IsChat) {
      return <SingleSpaceOptions space={space} />;
    } else if (space.IsConversation) {
      return <SingleSpaceOptions space={space} />;
    }
    return <SpaceOptions space={space} />;
  }

  render() {
    const { space, isGuest } = this.props;
    const showOnlineStatus = space.IsConversation || space.IsChat;
    return (
      <SidebarLayoutHeader
        onTourSelected={this.onTourSelected}
        left={
          <div className="flex flex-shrink align-items-center justify-content-space-between space-chat-header">
            <ChannelOptions
              space={space}
              trigger={
                <>
                  <div className="flex align-items-center mr-2">
                    <SpaceIcon space={space} />
                    <div className="flex column">
                      <strong className="space-name">
                        {space.Name ? space.Name : <SkeletonLine />}
                      </strong>
                      {showOnlineStatus && (
                        <small className="light-text">
                          <CollaboratorInfoContainer
                            email={space.CollaboratorEmail}
                            spaceId={space.Id}
                          />
                        </small>
                      )}
                    </div>
                  </div>
                  <WeTeamIcon Icon={ArrowDown2Icon} link={false} />
                </>
              }
            />
          </div>
        }
        right={
          <>
            {isGuest && (
              <Label circular>
                <FormattedMessage id="SpaceChatHeader.guest" />
              </Label>
            )}
          </>
        }
      />
    );
  }
}
SpaceChatHeader.propTypes = {
  space: PropTypes.object.isRequired,
  onPrepareTour: PropTypes.func.isRequired,
  isGuest: PropTypes.bool,
  locale: PropTypes.string.isRequired,
};

export default connect(
  (state, props) => {
    const space = getSpaceByIdWithCollaboratorEmail(state, props.spaceId);
    const isGuest = isUserGuestBySpaceId(state, props.spaceId);
    return {
      space,
      isGuest,
      locale: getLocale(state),
    };
  },
  {
    onPrepareTour: prepareWorkspaceTour,
  }
)(SpaceChatHeader);
