import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router';
import { connect } from 'react-redux';
import { Loader, Icon, Header, Divider } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { getSpaceLink } from 'pages';

import { getSpaceById } from 'store/Spaces/selectors';

import './SpaceMigration.scss';

class SpaceMigration extends Component {
  renderLocked() {
    return (
      <React.Fragment>
        <Header as="h2" icon>
          <Icon name="share square outline" />
          <Header.Content>
            <FormattedMessage id="SpaceMigration.Locked.Title" />
            &nbsp;
            <Header.Subheader>
              <FormattedMessage id="SpaceMigration.Locked.Info" />
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Loader active inline />
      </React.Fragment>
    );
  }

  renderUnlocked() {
    return (
      <React.Fragment>
        <Header as="h2" icon>
          <Icon name="check circle outline" />
          <Header.Content>
            <FormattedMessage id="SpaceMigration.Unlocked.Title" />
            <Header.Subheader>
              <FormattedMessage id="SpaceMigration.Unlocked.Info" />
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Divider hidden />
        <Link
          className="ui primary button"
          to={getSpaceLink(this.props.space.Id)}
        >
          <FormattedMessage id="SpaceMigration.ReturnToSpaceLinkText" />
        </Link>
      </React.Fragment>
    );
  }

  render() {
    const { space } = this.props;
    return (
      <div className="space-migration-container flex-auto">
        {space.Locked ? this.renderLocked() : this.renderUnlocked()}
      </div>
    );
  }
}
SpaceMigration.propTypes = {
  space: PropTypes.object.isRequired,
};

export default connect((state, props) => {
  const space = getSpaceById(state, props.params.spaceId);
  return {
    space,
  };
})(withRouter(SpaceMigration));
