import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Icon, Message } from 'semantic-ui-react';
import classNames from 'classnames/bind';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { popularTagAdded } from 'store/UI';

import { useCreateChannelTag } from 'Hooks/data/tags/useTags';

import { COLORS, getRandomColor } from 'Helpers/Colors';

import Tag from 'Components/Tagger/Tag';
import TagColorPicker from 'Components/Tagger/TagColorPicker';

import styles from './ResourceTagsInput.module.css';
import CloseIcon from 'Components/Icons/CloseIcon';

const SortableTag = SortableElement(({ children, ...props }) => (
  <Tag {...props}>{children}</Tag>
));

const SortableTagContainer = SortableContainer(({ children }) => {
  return <div>{children}</div>;
});

function ResourceTagsInput({
  intl,
  resourceId,
  channelId,
  selectedTags,
  onTagSelected,
  orderedFilteredTags,
  onFilterChange,
  filter,
  onSortEnd,
}) {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [color, setColor] = useState(COLORS.ourpink);

  const createChannelTagMutation = useCreateChannelTag(channelId);

  const onCreateTag = useCallback(
    async tagName => {
      const newTag = await createChannelTagMutation.mutateAsync({
        channelId,
        tag: { name: tagName, color },
      });
      await onTagSelected(newTag.id);
      dispatch(popularTagAdded(newTag.id));
      onFilterChange('');
    },
    [
      channelId,
      color,
      createChannelTagMutation,
      dispatch,
      onFilterChange,
      onTagSelected,
    ]
  );

  const onColorSelected = useCallback(
    color => {
      setColor(color);
      inputRef.current.focus();
    },
    [setColor]
  );

  const canCreateTag =
    filter.length > 0 &&
    orderedFilteredTags.filter(
      tag => tag.name.toLowerCase() === filter.toLowerCase()
    ).length === 0;

  return (
    <>
      <CloseIcon
        width={15}
        height={15}
        className={styles.closeIcon}
        onClick={() => {
          document.body.click();
        }}
      />
      <form
        onClick={event => {
          inputRef.current.focus();
        }}
        className={styles.form}
        onSubmit={async event => {
          event.preventDefault();
          if (canCreateTag) {
            await onCreateTag(filter);
          }
        }}
      >
        <SortableTagContainer
          onSortEnd={onSortEnd}
          axis="xy"
          helperClass={styles.draggedTag}
          distance={1} // this is required to allow clicking on the list item
        >
          {selectedTags.map((selectedTag, index) => (
            <SortableTag
              key={selectedTag.id}
              index={index}
              className={styles.tag}
              color={selectedTag.color}
            >
              {selectedTag.name}
              <Icon
                name="delete"
                onClick={() => {
                  onTagSelected(selectedTag.id);
                }}
              />
            </SortableTag>
          ))}
        </SortableTagContainer>
        <input
          autoFocus
          ref={inputRef}
          id={styles.input}
          className={classNames('my-1')}
          onChange={async event => {
            onFilterChange(event.target.value);
            if (event.target.value.length === 1) {
              setColor(getRandomColor());
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Backspace' && filter.length === 0) {
              //remove last tag
              onTagSelected(selectedTags.at(-1).id);
            }
          }}
          value={filter}
          placeholder={intl.formatMessage({
            id: 'ResourceTags.typeToAdd',
          })}
        />
      </form>
      {canCreateTag && (
        <div className={classNames(styles.createContainer)}>
          <Message info size="mini">
            <FormattedMessage id="ResourceTagsInput.EnterToCreate" />
            <Tag color={color} className="ml-2">
              {filter}
            </Tag>
          </Message>
          <div className={styles.colorContainer}>
            <TagColorPicker
              onColorSelected={onColorSelected}
              selectedColor={color}
            />
          </div>
        </div>
      )}
    </>
  );
}

ResourceTagsInput.propTypes = {
  intl: intlShape.isRequired,
  resourceId: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
};

export default injectIntl(ResourceTagsInput);
