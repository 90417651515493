import CloseIcon from 'Components/Icons/CloseIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { Segment, Header, Transition } from 'semantic-ui-react';

function ActionableNotification({ isOpen, onClose, title, body, loading }) {
  return (
    <Transition
      visible={isOpen}
      transitionOnMount
      animation="fly left"
      unmountOnHide
      duration={500}
    >
      <Segment.Group>
        <Header
          as="h3"
          attached="top"
          className="flex align-items-center space-between"
        >
          {title}
          {onClose && (
            <CloseIcon
              data-testid="actionable-notification-close-icon"
              onClick={onClose}
              width={12}
              height={12}
            />
          )}
        </Header>
        <Segment attached loading={loading}>
          {body}
        </Segment>
      </Segment.Group>
    </Transition>
  );
}

ActionableNotification.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default ActionableNotification;
