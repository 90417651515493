import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import format from 'date-fns/format';

import notificationType, {
  filterDisplayNotifications,
  MEETING_NOTIFICATION,
} from '../../Constants/notificationType';

import { getUserId } from '../User/selectors';
import { getTemplates } from '../Integrations/Templates/selectors';
import { REMINDER_TYPE } from 'DataLayer/Reminders/get';
import { remindersKeyedByTargetId } from 'store/Reminders/selectors';
import { getActiveMeetings } from 'store/Meeting/selectors';

const getNotifications = state => state.systemNotifications.notifications;
const getTemporaryNotifications = state =>
  state.systemNotifications.temporaryNotifications;
export const getStats = state => state.systemNotifications.stats;
export const getHidden = state => state.systemNotifications.hidden;
export const getPages = state => state.systemNotifications.pages;
export const getLastRead = state => state.systemNotifications.lastRead;

export function getLastReadBySpaceId(state, spaceId) {
  return getLastRead(state)[spaceId];
}
export function getHasHiddenMessages(state, spaceId) {
  return getHidden(state)[spaceId];
}

export function getHasOlderMessages(state, spaceId) {
  const page = getPages(state)[spaceId];
  if (page) {
    return page.hasOlderMessages;
  }
  return false;
}

export function getHasNewerMessages(state, spaceId) {
  const page = getPages(state)[spaceId];
  if (page) {
    return page.hasNewerMessages;
  }
  return false;
}

const getNotificationsAsArray = createSelector(
  [getNotifications, getTemporaryNotifications],
  (notifications, temporaryNotifications) =>
    Object.values(notifications).concat(Object.values(temporaryNotifications))
);

const getMeetingNotifications = createSelector(
  [getActiveMeetings, (_, channelId) => channelId],
  (activeMeetings, channelId) =>
    activeMeetings
      .filter(meeting =>
        meeting.users?.some(participant => participant.channelId === channelId)
      )
      .map(meeting => ({
        Meeting: meeting,
        NotificationReadState: true,
        Type: MEETING_NOTIFICATION,
        CreatedBy: {
          FirstName: meeting.caller?.firstName ?? '',
          UserID: meeting.caller?.email ?? '',
          LastName: meeting.caller?.lastName ?? '',
        },
        Id: meeting.roomId,
        NotificationTimestamp: meeting.meetingStartedAt,
        Workspace: {
          Id: channelId,
        },
      }))
);

const getSpaceNotifications = createSelector(
  [
    getNotificationsAsArray,
    getUserId,
    getTemplates,
    remindersKeyedByTargetId,
    getMeetingNotifications,
  ],
  (notifications, userId, templates, reminders, meetingNotifications) =>
    notifications
      .filter(filterDisplayNotifications)
      .concat(meetingNotifications)
      .map(n => {
        let applicationImageUrl;
        if (n.IsZapier) {
          const template = templates[n.TemplateId];
          if (template) {
            applicationImageUrl = template.nonOtixoStep.image;
          }
        }

        const createReminderInfoObject = (type, id, targetId, target) => {
          return {
            ReminderType: type,
            ReminderId: id,
            ReminderTargetId: targetId,
            ReminderTarget: target,
          };
        };

        const getResourceReminderInfo = () => {
          return createReminderInfoObject(
            REMINDER_TYPE.resource,
            n.Resource &&
              reminders[n.Resource.Id] &&
              reminders[n.Resource.Id].Id,
            n.Resource && n.Resource.Id,
            n.Resource
          );
        };

        const getLinkedItemReminderInfo = () => {
          return createReminderInfoObject(
            REMINDER_TYPE.linkedItem,
            n.Resource &&
              reminders[n.Resource.Id] &&
              reminders[n.Resource.Id].Id,
            n.Resource && n.Resource.Id,
            n.Resource
          );
        };

        const getCommentReminderInfo = () => {
          return createReminderInfoObject(
            REMINDER_TYPE.comment,
            n.Comment &&
              reminders[n.Comment.CommentId] &&
              reminders[n.Comment.CommentId].Id,
            n.Comment && n.Comment.CommentId,
            n.Comment
          );
        };

        const getNotificationReminderInfo = () => {
          return createReminderInfoObject(
            REMINDER_TYPE.notification,
            reminders[n.Id] && reminders[n.Id].Id,
            n.Id
          );
        };

        const getReminderInfo = () => {
          try {
            if (n.Type === notificationType.ChannelNewResource) {
              return getResourceReminderInfo();
            } else if (n.Type === notificationType.ChannelResourceLinked) {
              return getLinkedItemReminderInfo();
            } else if (n.Type === notificationType.ResourceComment) {
              return getCommentReminderInfo();
            }
            return getNotificationReminderInfo();
          } catch (e) {
            return getNotificationReminderInfo();
          }
        };

        const reminderInfo = getReminderInfo();

        return {
          ...n,
          IsMe: userId === n.CreatedBy.UserID,
          ApplicationImageUrl: applicationImageUrl,
          Day: format(n.NotificationTimestamp, 'YYYY-MM-DD'),
          ...reminderInfo,
        };
      })
);

export const getNotificationById = createSelector(
  [getSpaceNotifications, (state, id) => id],
  (notifications, id) => {
    const notification = notifications.find(n => n.Id === id);
    return notification;
  }
);

export const getUnreadAllSpaceNotificationsCount = createSelector(
  [getStats],
  totals => {
    if (totals.UnreadPerWorkspace) {
      return Object.values(totals.UnreadPerWorkspace).reduce(
        (o, v) => o + v,
        0
      );
    }
    return 0;
  }
);

export const getUnreadAllPrivateChatNotificationsCount = createSelector(
  [getStats],
  stats => {
    if (stats.UnreadPerWorkspace && stats.UnreadPerWorkspace['CONVERSATIONS']) {
      return stats.UnreadPerWorkspace['CONVERSATIONS'];
    }
    return 0;
  }
);

export const makeGetUnreadNotificationsCountBySpaceId = () =>
  createSelector([getStats, (_, spaceId) => spaceId], (stats, spaceId) => {
    if (stats.UnreadPerChannel && stats.UnreadPerChannel[spaceId]) {
      return stats.UnreadPerChannel[spaceId];
    }
    if (stats.UnreadPerConversations && stats.UnreadPerConversations[spaceId]) {
      return stats.UnreadPerConversations[spaceId];
    }
    return 0;
  });

export const getUnreadPerWorkspace = createSelector(
  [getStats],
  stats => stats.UnreadPerWorkspace
);

export const makeGetUnreadNotificationsCountByWorkspaceId = () =>
  createSelector(
    [getStats, (_, workspaceId) => workspaceId],
    (stats, workspaceId) => {
      if (stats.UnreadPerWorkspace) {
        return stats.UnreadPerWorkspace[workspaceId] || 0;
      }
      return 0;
    }
  );

export const getFeedNotificationsBySpaceId = createSelector(
  [getSpaceNotifications, (_, spaceId) => spaceId],
  (notifications, spaceId) =>
    orderBy(
      notifications.filter(n => n.Workspace.Id === spaceId),
      'NotificationTimestamp'
    )
);

export const getEarliestNewNotificationIdBySpaceId = createSelector(
  [getSpaceNotifications, (_, spaceId) => spaceId],
  (notifications, spaceId) => {
    const spaceNotifications = notifications.filter(
      n => n.Workspace.Id === spaceId && !n.NotificationReadState
    );
    const earliestNotification = minBy(
      spaceNotifications,
      n => n.NotificationTimestamp
    );
    if (earliestNotification) {
      return earliestNotification.Id;
    }
    return null;
  }
);

export const getSpaceInvitationMessage = createSelector(
  [getNotificationsAsArray, (_, spaceId) => spaceId],
  (notifications, spaceId) => {
    if (!notifications) {
      return null;
    }
    const invitationNotifications = notifications
      .filter(
        notification => notification.Type === notificationType.ChannelInvitation
      )
      .filter(invite => {
        if (invite.Workspace) {
          return invite.Workspace.Id === spaceId;
        }
        return false;
      });
    if (invitationNotifications.length === 0) {
      return null;
    }
    const latestInvitation = maxBy(
      invitationNotifications,
      n => n.NotificationTimestamp
    );
    return latestInvitation.UserMessage;
  }
);

/*
  No need to use createSelector as this is not called from a react-redux connect() function
*/
export function getNotificationByCommentId(state, commentId) {
  return getNotificationsAsArray(state).find(notification => {
    if (
      notification.Type === notificationType.ChannelComment &&
      notification.Comment
    ) {
      return notification.Comment.CommentId === commentId;
    }
    return false;
  });
}

export const getResourcesFromFeedNotificationsBySpaceId = createSelector(
  [getFeedNotificationsBySpaceId, (_, spaceId) => spaceId],
  (notifications, spaceId) => {
    const resourceNotifications = notifications
      .filter(
        notification =>
          notification.Type === notificationType.ChannelResourceLinked ||
          notification.Type === notificationType.ChannelNewResource ||
          notification.Type ===
            notificationType.WorkspaceNewResource_DEPRECATED ||
          notification.Type === notificationType.ResourceComment ||
          notification.Type === notificationType.ChannelLinkRenamed ||
          notification.Type ===
            notificationType.ChannelResourceWritabilityChanged ||
          notification.Type ===
            notificationType.GWorkspaceResourceWritabilityChanged_DEPRECATED ||
          notification.Type === notificationType.ChannelResourceDownloaded
      )
      .filter(notification => notification.Resource);

    const resources = resourceNotifications.map(notification => ({
      Id: notification.Resource.Id,
      Name: notification.Resource.Name,
      Type: notification.Resource.Type,
      Folder: notification.Resource.Folder,
      Deny: notification.Resource.Deny,
    }));

    const resourceFiles = resources.filter(r => !r.Folder);

    return uniqBy(resourceFiles, 'Id');
  }
);
