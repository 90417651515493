import { FormattedMessage } from 'react-intl';

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Loader, Transition } from 'semantic-ui-react';

import styles from './FullScreenLoading.module.scss';

import OtixoLogo from '../../assets/images/logo/Vertical/WeTeam-Logo-Vertical-WhiteFont.svg';

function FullScreenLoading({ visible }) {
  return (
    <Transition
      visible={visible}
      animation="fade"
      unmountOnHide
      duration={{ show: 0, hide: 300 }}
    >
      <div>
        <div className={styles.container}>
          <img width={250} src={OtixoLogo} alt="pulsing We.Team logo" />
          <span className="flex center letter-spacing-wide uppercase">
            <Loader active inverted inline size="mini" className="mr-2" />
            <FormattedMessage id="FullScreenLoading.connecting" />
          </span>
        </div>
      </div>
    </Transition>
  );
}

FullScreenLoading.propTypes = { visible: PropTypes.bool.isRequired };

export default memo(FullScreenLoading);
