import useIsApplicationActive from 'Hooks/useIsApplicationActive';
import React from 'react';

/*
  Allows class components to use our useIsApplicationActive hook
*/
export const withIsApplicationActive = WrappedComponent => {
  const WithIsApplicationActive = props => {
    const isApplicationActive = useIsApplicationActive();
    return (
      <WrappedComponent isApplicationActive={isApplicationActive} {...props} />
    );
  };
  return WithIsApplicationActive;
};
