export const ADD_ZAPS = 'APPSTORE/ZAPS/ADD';
//export const LOAD_ZAPS_ERROR = 'APPSTORE/ZAPS/ADD/ERROR';

export function addAvailableStoreZaps(zaps) {
  return {
    type: ADD_ZAPS,
    zaps,
  };
}
/*
export function loadZapsError(error) {
  return {
    type: LOAD_ZAPS_ERROR,
    error,
  };
}
*/
