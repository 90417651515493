export const MERGE_RESOURCES = 'ENTITIES/MERGE_RESOURCES';

export function mergeResources(resources) {
  return {
    type: MERGE_RESOURCES,
    resources,
  };
}

export function mergeResource(resource) {
  const newResource = { [resource.Id]: resource };
  return {
    type: MERGE_RESOURCES,
    resources: newResource,
  };
}
