import { push } from 'react-router-redux';
/*import { getWorkspaceLink } from 'pages'; */
import { WORKSPACES } from 'pages';
import { leave } from 'DataLayer/Workspaces/Members/put';
import { getOtixoDownloadUrl } from 'config/config';
import { setSpaceRequestInProgress } from '../Requests/setRequestInProgress';
import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';
import { addDownload } from '../Download/addDownload';
import { removeWorkspace } from './actions';

const leaveWorkspace = (workspace, exportHistory) => dispatch => {
  dispatch(setSpaceRequestInProgress(workspace.Id, true));
  return leave(workspace.Id, exportHistory).then(
    exportResourceId => {
      if (exportHistory && exportResourceId) {
        dispatch(addDownload(getOtixoDownloadUrl(exportResourceId)));
      }
      dispatch(
        displaySuccessToast('toast.leaveWorkspace.success', {
          name: workspace.Name,
        })
      );
      dispatch(setSpaceRequestInProgress(workspace.Id, false));
      dispatch(removeWorkspace(workspace.Id));
      dispatch(push(WORKSPACES));
      return workspace;
    },
    error => {
      dispatch(displayErrorToast('toast.leaveWorkspace.error'));
      dispatch(setSpaceRequestInProgress(workspace.Id, false));
      throw error;
    }
  );
};

export default leaveWorkspace;
