import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import loadMediaInfo from 'store/MediaInfo/loadMediaInfo';
import getMediaInfoById from 'store/MediaInfo/selectors';

import LaunchFileInDesktopChild from './LaunchFileInDesktopChild';

function LaunchFileInDesktop({
  resourceId,
  channelId,
  onLaunchComplete,
  showText,
}) {
  const dispatch = useDispatch();

  const mediaInfo = useSelector(state => getMediaInfoById(state, resourceId));

  useEffect(() => {
    dispatch(loadMediaInfo(resourceId, channelId));
  }, [resourceId, channelId, dispatch]);

  if (!mediaInfo?.url) {
    if (showText) {
      return <FormattedMessage id="LaunchFileInDesktop.Loading" />;
    }
    return null;
  }

  return (
    <LaunchFileInDesktopChild
      url={mediaInfo?.url}
      resourceId={resourceId}
      onLaunchComplete={onLaunchComplete}
      showText={showText}
    />
  );
}

LaunchFileInDesktop.propTypes = {
  resourceId: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  onLaunchComplete: PropTypes.func,
  showText: PropTypes.bool,
};

export default LaunchFileInDesktop;
