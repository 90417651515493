import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSpaceById } from 'store/Spaces/selectors';
import { getUnreadAllPrivateChatNotificationsCount } from 'store/Notifications/selectors';

import PrivateChatIcon from 'Components/Icons/PrivateChatIcon';
import NotificationBadge from 'Components/Badges/NotificationBadge';
import loadConversations from 'store/Conversations/loadConversations';

function PrivateChatButton({ unread, dispatch }) {
  useEffect(
    () => {
      dispatch(loadConversations());
    },
    [dispatch]
  );

  return (
    <div className="position-relative">
      <PrivateChatIcon
        style={{
          marginTop: 2,
        }}
      />
      <NotificationBadge count={unread} />
    </div>
  );
}

PrivateChatButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  unread: PropTypes.number.isRequired,
};

export default connect((state, props) => ({
  space: getSpaceById(state, props.spaceId),
  unread: getUnreadAllPrivateChatNotificationsCount(state, props.spaceId),
}))(PrivateChatButton);
