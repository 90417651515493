import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';

import { ANALYTICS } from 'DataLayer/endpoints';

export const post = async (eventName, params) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };
  // return await fetch(
  //   `https://www.google-analytics.com/mp/collect?measurement_id=G-0MGWRPGRGV&api_secret=1BkNb9ifQcG-ThT2A1J99A`,
  //   {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       client_id: '1111111111.0000000000',
  //       events: [
  //         {
  //           name: eventName,
  //           params,
  //         },
  //       ],
  //     }),
  //   }
  // );
  return await axios.post(
    ANALYTICS,
    {
      client_id: '1111111111.0000000000',
      events: [
        {
          name: eventName,
          params,
        },
      ],
    },
    config
  );
};
