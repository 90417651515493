import React from 'react';
import PropTypes from 'prop-types';

import styles from './LinkButton.module.scss';
import classNames from 'classnames/bind';

const LinkButton = ({ children, dark, underlined, className, ...props }) => (
  <span
    className={classNames(styles.linkButton, className, {
      [styles.dark]: dark,
      [styles.underlined]: underlined,
    })}
    {...props}
  >
    {children}
  </span>
);

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  underlined: PropTypes.bool,
  className: PropTypes.string,
};

LinkButton.defaultProps = {
  className: '',
};

export default LinkButton;
