import PropTypes from 'prop-types';
import React from 'react';
import filesize from 'filesize';

import './PreviewUnavailable.scss';
import FileIcon from '../../Icons/FileIcon';

function PreviewUnavailable({ openPreview, resourceName, size, type }) {
  return (
    <div className="preview-unavailable" onClick={openPreview}>
      <span className="mr-2">
        <FileIcon type={type} />
      </span>
      <div className="preview-unavailable_atr">
        <strong className="preview-unavailable_name">{resourceName}</strong>
        {size > -1 && (
          <div className="preview-unavailable_more">
            <span>{filesize(size, { round: 0 })}</span>
          </div>
        )}
      </div>
    </div>
  );
}

PreviewUnavailable.propTypes = {
  type: PropTypes.string,
  resourceName: PropTypes.string,
  openPreview: PropTypes.func,
  size: PropTypes.number,
};

export default PreviewUnavailable;
