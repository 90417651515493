import PropTypes from 'prop-types';
import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export function UserAlreadyAParticipantOfMeeting({ onCancel, onJoin }) {
  return (
    <Modal size="small" open onClose={onCancel}>
      <Header>
        <FormattedMessage id="UserAlreadyAParticipantOfMeeting.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onCancel} />
        </div>
      </Header>
      <Modal.Content>
        <FormattedMessage id="UserAlreadyAParticipantOfMeeting.Message" />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel}>
          <FormattedMessage id="Cancel" />
        </Button>
        <Button primary onClick={onJoin}>
          <FormattedMessage id="UserAlreadyAParticipantOfMeeting.YesButtonText" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

UserAlreadyAParticipantOfMeeting.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onJoin: PropTypes.func.isRequired,
};

export default UserAlreadyAParticipantOfMeeting;
