import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { makeGetSpaceById } from 'store/Spaces/selectors';
import { getSpaceSidebarPanel } from 'store/UI/selectors';

import SpaceChat from 'Components/Chat/SpaceChat/SpaceChat';
import SpaceSidebar from 'Components/SpaceSidebar/SpaceSidebar';
import { useRedirectToChannel } from 'Hooks/useRedirectToChannel';
import AppCuesWorkSpaceTour from 'Components/AppCues/AppCuesWorkSpaceTour';
import { useEffect } from 'react';
import loadLinkedItems from 'store/LinkedItems/loadLinkedItems';
import {
  showAddFirstChannelModal,
  showAddMemebersToFirstWorkSpace,
  showAddToSpaceModal,
  showAddWorkspaceMemberModal,
  showPurchaseWorkspacePlanCompleteModal,
  showPurchaseWorkspacePlanModal,
} from 'store/Modals/actions';

import {
  SELECTED_WORKSPACE_PLAN_QUERY_PARAM,
  SHARE_FROM_DRIVES,
  WORKSPACE_OWNER_ONBOARDING_STEPS,
} from 'pages';

import WorkspaceOffers from 'Components/WorkspaceOffer/WorkspaceOffers';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import ThreeMonthTeamspaceUpgradeBillingInfoReminder from 'Components/WorkspaceOffer/ThreeMonthTeamspaceUpgradePromotionModal/BillingInformationReminder/ThreeMonthTeamspaceUpgradeBillingInfoReminder';
import AcceptDownloadDesktopOffer from 'Components/WorkspaceOffer/DownloadDesktopOffer/AcceptDownloadDesktopOffer';
import TenMembersOfferAcceptedModal from 'Components/WorkspaceOffer/TenMembersOffer/TenMembersOfferAcceptedModal';

export function SpaceHome({
  space,
  isSkeletonApp = false,
  params: { workspaceId, spaceId, parentResourceId, notificationId },
  router: {
    replace,
    location: { query },
  },
  spaceSidebarPanel,
  onLoadLinkedItems,
  onShowPurchaseWorkspacePlanModal,
  onShowPurchaseWorkspacePlanCompleteModal,
  onShowMembers,
  onShowAddWorkspaceMemberModal,
  onShowAddFirstChannelModal,
  onShowAddToSpaceModal,
}) {
  useRedirectToChannel(replace, workspaceId, isSkeletonApp, query);

  useEffect(() => {
    if (spaceId) {
      onLoadLinkedItems(spaceId);
    }
  }, [onLoadLinkedItems, spaceId]);

  useEffect(() => {
    if (query[WORKSPACE_OWNER_ONBOARDING_STEPS.SELECT_PLAN]) {
      onShowPurchaseWorkspacePlanModal(
        space.WorkspaceId,
        spaceId,
        query[SELECTED_WORKSPACE_PLAN_QUERY_PARAM]
      );
    } else if (query[WORKSPACE_OWNER_ONBOARDING_STEPS.ADD_MEMBERS]) {
      onShowMembers(
        space.WorkspaceId,
        spaceId,
        query[SELECTED_WORKSPACE_PLAN_QUERY_PARAM]
      );
    } else if (query[WORKSPACE_OWNER_ONBOARDING_STEPS.ADD_CHANNEL]) {
      onShowAddFirstChannelModal(
        spaceId,
        query[SELECTED_WORKSPACE_PLAN_QUERY_PARAM]
      );
    } else if (query[WORKSPACE_OWNER_ONBOARDING_STEPS.PURCHASE_COMPLETE]) {
      onShowPurchaseWorkspacePlanCompleteModal(
        space.WorkspaceId,
        spaceId,
        query[SELECTED_WORKSPACE_PLAN_QUERY_PARAM]
      );
    } else if (query[SHARE_FROM_DRIVES]) {
      onShowAddToSpaceModal(spaceId);
    }
  }, [
    onShowAddToSpaceModal,
    onShowMembers,
    onShowAddFirstChannelModal,
    onShowPurchaseWorkspacePlanCompleteModal,
    onShowPurchaseWorkspacePlanModal,
    query,
    space.WorkspaceId,
    spaceId,
  ]);

  const isOnboarding =
    query[WORKSPACE_OWNER_ONBOARDING_STEPS.SELECT_PLAN] ||
    query[WORKSPACE_OWNER_ONBOARDING_STEPS.PURCHASE_COMPLETE] ||
    query[WORKSPACE_OWNER_ONBOARDING_STEPS.ADD_CHANNEL] ||
    query[WORKSPACE_OWNER_ONBOARDING_STEPS.ADD_MEMBERS] ||
    query[WORKSPACE_OWNER_ONBOARDING_STEPS.TOUR];

  const shouldAutoFocus = !isSkeletonApp && !isOnboarding;
  const showOffers = spaceId && !isSkeletonApp && !isOnboarding;
  const showWorkSpaceTour =
    spaceId && query[WORKSPACE_OWNER_ONBOARDING_STEPS.TOUR];

  return (
    <React.Fragment>
      {space.WorkspaceId && showOffers && (
        <ErrorBoundary showFallback={false}>
          <WorkspaceOffers workspaceId={space.WorkspaceId} />
          <AcceptDownloadDesktopOffer workspaceId={space.WorkspaceId} />
          <TenMembersOfferAcceptedModal workspaceId={space.WorkspaceId} />
          <ThreeMonthTeamspaceUpgradeBillingInfoReminder
            workspaceId={space.WorkspaceId}
          />
        </ErrorBoundary>
      )}
      {showWorkSpaceTour && (
        <AppCuesWorkSpaceTour workspaceId={space.WorkspaceId} />
      )}
      <div className="flex flex-3">
        <SpaceChat
          spaceId={spaceId}
          expandable
          goToNotificationId={notificationId}
          autoFocus={shouldAutoFocus}
        />
      </div>
      {spaceId && (
        <SpaceSidebar
          space={space}
          resourceId={parentResourceId}
          activePanel={spaceSidebarPanel}
        />
      )}
    </React.Fragment>
  );
}
SpaceHome.propTypes = {
  space: PropTypes.object,
  isSkeletonApp: PropTypes.bool,
  params: PropTypes.shape({
    workspaceId: PropTypes.string,
    spaceId: PropTypes.string,
    parentResourceId: PropTypes.string,
    notificationId: PropTypes.string,
  }),
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.object.isRequired,
    }),
  }),
  spaceSidebarPanel: PropTypes.string,
  onLoadLinkedItems: PropTypes.func.isRequired,
  onShowPurchaseWorkspacePlanModal: PropTypes.func.isRequired,
  onShowPurchaseWorkspacePlanCompleteModal: PropTypes.func.isRequired,
  onShowMembers: PropTypes.func.isRequired,
  onShowAddWorkspaceMemberModal: PropTypes.func.isRequired,
  onShowAddToSpaceModal: PropTypes.func.isRequired,
  onShowAddFirstChannelModal: PropTypes.func.isRequired,
};

export default connect(
  () => {
    const getSpaceById = makeGetSpaceById();
    return (state, props) => ({
      space: getSpaceById(state, props.params.spaceId),
      spaceSidebarPanel: getSpaceSidebarPanel(state, props.params.spaceId),
    });
  },
  {
    onLoadLinkedItems: loadLinkedItems,
    onShowPurchaseWorkspacePlanModal: showPurchaseWorkspacePlanModal,
    onShowPurchaseWorkspacePlanCompleteModal:
      showPurchaseWorkspacePlanCompleteModal,
    onShowMembers: showAddMemebersToFirstWorkSpace,
    onShowAddWorkspaceMemberModal: showAddWorkspaceMemberModal,
    onShowAddFirstChannelModal: showAddFirstChannelModal,
    onShowAddToSpaceModal: showAddToSpaceModal,
  }
)(withRouter(SpaceHome));
