import axios from 'axios';
import { STARRED } from 'DataLayer/endpoints';
import process from 'DataLayer/LinkedItems/process';

export function getStarred() {
  return axios.get(STARRED).then(response => {
    return {
      Notifications: response.data.Notifications || [],
      LinkedItems: process.all(response.data.LinkedItems),
      Resources: process.all(response.data.Resources),
    };
  });
}
