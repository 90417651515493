import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import styles from './TruncatedTagList.module.css';

function TruncatedTagList({
  items,
  width,
  renderTag,
  renderHiddenTags,
  ...props
}) {
  const tagContainer = useRef(null);
  const [maxNumberOfTags, setMaxNumberOfTags] = useState(items.length);

  useLayoutEffect(() => {
    /* 
        reset whenever the length of tag, 
        or the width of the container changes
      */
    setMaxNumberOfTags(items.length);
  }, [items, width]);

  useLayoutEffect(() => {
    /*
        if the tags overflow their container, remove a tag and try again
      */
    if (tagContainer.current?.scrollWidth > tagContainer.current?.offsetWidth) {
      setMaxNumberOfTags(maxNumberOfTags - 1);
    }
  }, [maxNumberOfTags, width, items]);

  if (items.length === 0) {
    return null;
  }

  const visibleTags = items.slice(0, maxNumberOfTags);
  const hiddenTags = items.slice(maxNumberOfTags);

  return (
    <div
      className={styles.truncatedTagList}
      ref={tagContainer}
      style={{ width }}
      {...props}
    >
      {visibleTags.map((tag, index) => {
        return renderTag({
          tag,
        });
      })}
      {hiddenTags.length > 0 && renderHiddenTags(hiddenTags)}
    </div>
  );
}

TruncatedTagList.propTypes = {
  renderTag: PropTypes.func.isRequired,
  renderHiddenTags: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
};

export default props => (
  <AutoSizer disableHeight>
    {({ width }) => <TruncatedTagList width={width} {...props} />}
  </AutoSizer>
);
