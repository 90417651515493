import { useDispatch, useSelector } from 'react-redux';

import { shouldShowOffer } from 'store/Workspaces/shouldShowInviteMemberPromotion';
import loadMembersByWorkspaceId from 'store/Workspaces/Members/loadMembersByWorkspaceId';
import loadOffers from 'store/Workspaces/Offer/loadOffer';

import useAsync from 'Hooks/useAsync';

import { useEffect } from 'react';
import {
  getWorkspaceOffer,
  isWorkspaceOfferPostponed,
} from 'store/Workspaces/Offer/selectors';
import { OFFERS } from 'DataLayer/Workspaces/Offers/config';

const getMemberLimit = offer => {
  if (offer === OFFERS.free_trial_aug_2023 || offer === OFFERS.spring_2024) {
    return Number.MAX_SAFE_INTEGER;
  } else if (offer === OFFERS.trial_test) {
    return 6;
  }
  return 0;
};
/*
  load offer from the server
  load workspace members
*/
export const useWorkspaceOfferAvailable = workspaceId => {
  const dispatch = useDispatch();

  const workspaceOffer = useSelector(state =>
    getWorkspaceOffer(state, workspaceId)
  );

  // check if the offer is already accepted
  const { loading } = useAsync(async () => {
    if (!workspaceId) {
      return;
    }
    await dispatch(loadOffers(workspaceId));
  }, [workspaceId, dispatch]);

  // load the members so we can check how many have joined
  useEffect(() => {
    dispatch(loadMembersByWorkspaceId(workspaceId));
  }, [dispatch, workspaceId]);

  const shouldShowOfferResult = useSelector(state =>
    shouldShowOffer(state, workspaceId, getMemberLimit(workspaceOffer))
  );

  const isOfferPostponed = useSelector(state =>
    isWorkspaceOfferPostponed(state, workspaceId, workspaceOffer)
  );

  if (loading || !workspaceId || isOfferPostponed) {
    return null;
  }

  if (shouldShowOfferResult) {
    return workspaceOffer;
  }

  return null;
};
