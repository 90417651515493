import { select, takeEvery } from 'redux-saga/effects';
import { put as putUi } from 'DataLayer/WebUIPreferences/put';
import { getUI } from './selectors';
import { UI_ACTION_TYPE_PREFIX } from '.';

function* saveUIPreferences(action) {
  try {
    const ui = yield select(getUI);
    yield putUi(ui);
  } catch (error) {
    // no need to do anything here
  }
}

export default function* uiSaga() {
  yield takeEvery(
    action => action.type.startsWith(UI_ACTION_TYPE_PREFIX),
    saveUIPreferences
  );
}
