import React from 'react';
import PropTypes from 'prop-types';

function PageTransition({ children }) {
  return <React.Fragment>{children}</React.Fragment>;
}

PageTransition.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTransition;
