import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Segment,
  Header,
  Divider,
  Transition,
} from 'semantic-ui-react';

import { getSpaceInvitationMessage } from 'store/Notifications/selectors';
import leaveWorkspace from 'store/Workspaces/leaveWorkspace';
import joinWorkspace from 'store/Workspaces/joinWorkpace';

class WorkspaceInvitation extends React.Component {
  state = {
    loading: false,
    open: true,
  };

  onLeave = () => {
    const { dispatch, workspace } = this.props;
    this.setState({
      loading: true,
    });
    dispatch(leaveWorkspace(workspace)).then(
      () => {
        this.close();
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  onJoin = () => {
    const { dispatch, workspace } = this.props;
    this.setState({
      loading: true,
    });
    dispatch(joinWorkspace(workspace)).then(
      () => {
        this.close();
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { workspace, message } = this.props;

    return (
      <Transition
        visible={this.state.open}
        transitionOnMount
        animation="fly left"
        duration={500}
      >
        <Segment.Group>
          <Header as="h3" attached="top">
            <FormattedMessage id="WorkspaceInvitation.work-space-invitation" />

            {/* <div className="close_modal">
              <button
                className="close_modal_button"
                onClick={() => {
                  this.close();
                }}
              />
            </div> */}
          </Header>
          <Segment attached loading={this.state.loading}>
            <FormattedMessage
              id="WorkspaceInvitation.Title"
              values={{
                name: <strong>{workspace.Name}</strong>,
              }}
            />
            {message && message.length && (
              <div>
                <p>
                  <FormattedMessage
                    id="WorkspaceInvitation.Message"
                    values={{
                      name: <strong>{workspace.OwnerName}</strong>,
                    }}
                  />
                </p>
                <p>{message}</p>
              </div>
            )}
            <Divider hidden />
            <div className="flex justify-content-space-between">
              {/* <Button basic size="mini" onClick={this.onLeave}>
                <FormattedMessage id="WorkspaceInvitation.RejectButton" />
              </Button> */}
              <Button primary onClick={this.onJoin}>
                <FormattedMessage id="WorkspaceInvitation.JoinButton" />
              </Button>
            </div>
          </Segment>
        </Segment.Group>
      </Transition>
    );
  }
}

WorkspaceInvitation.propTypes = {
  workspace: PropTypes.object.isRequired,
  message: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

export default connect((state, props) => ({
  message: getSpaceInvitationMessage(state, props.workspace.Id),
}))(WorkspaceInvitation);
