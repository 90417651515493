import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isTouchDevice from 'is-touch-device';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { makeGetSpaceById } from 'store/Spaces/selectors';
import { getActiveWorkspaces } from 'store/Workspaces/selectors';

import WorkSpaceButton from './WorkSpaceButton';
import ScrollPositionTracker from '../../ScrollPositionTracker/ScrollPositionTracker';
import ElementOutOfViewBanner from '../../ScrollPositionTracker/ElementOutOfViewBanner';

import styles from './WorkSpaceList.module.scss';
import { workspacePositionsChanged } from 'store/UI';
import { getIsWorkspaceSortTypeCustom } from 'store/UI/selectors';
import { motion } from 'framer-motion';
import { Divider, Input } from 'semantic-ui-react';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { SearchIcon } from 'Components/Icons/WeTeamIcon';
import { orderBy, partition } from 'lodash';

const SortableWorkspaceItem = SortableElement(
  ({ workspace, active, onWorkSpaceSelected, open, canSort }) => {
    return (
      <li className={styles.sortableItem}>
        <WorkSpaceButton
          key={workspace.Id}
          workspace={workspace}
          active={active}
          onWorkSpaceSelected={onWorkSpaceSelected}
          open={open}
          canSort={canSort}
        />
      </li>
    );
  }
);

const SortableWorkspaceList = SortableContainer(
  ({ items, activeWorkspaceId, onWorkSpaceSelected, canSort, open }) => {
    return (
      <ul className={styles.sortableList}>
        {items.map((workspace, index) => (
          <SortableWorkspaceItem
            workspace={workspace}
            key={workspace.Id}
            index={index}
            value={workspace}
            active={workspace.Id === activeWorkspaceId}
            onWorkSpaceSelected={onWorkSpaceSelected}
            open={open}
            disabled={!canSort}
            canSort={canSort}
          />
        ))}
      </ul>
    );
  }
);

const arrayMove = (array, from, to) => {
  const arrayCopy = array.slice();
  arrayCopy.splice(
    to < 0 ? arrayCopy.length + to : to,
    0,
    arrayCopy.splice(from, 1)[0]
  );
  return arrayCopy;
};

export function WorkSpaceList({
  workspaces,
  activeWorkspaceId,
  activeSpace,
  onWorkSpaceSelected,
  open,
  isWorkspaceSortTypeCustom,
  onWorkspacePositionsChanged,
  intl,
}) {
  const [filter, setFilter] = useState('');

  const [archivedWorkspaces, unarchivedWorkspaces] = partition(
    workspaces,
    workspace => workspace.Archived
  );

  const filteredUnarchivedWorkspaces = unarchivedWorkspaces.filter(workspace =>
    workspace.Name?.toLowerCase().includes(filter.toLowerCase())
  );

  const filteredArchivedWorkspaces = archivedWorkspaces.filter(workspace =>
    workspace.Name?.toLowerCase().includes(filter.toLowerCase())
  );

  const archivedWorkspacesOrdered = orderBy(
    filteredArchivedWorkspaces,
    workspace => workspace.Name.toLowerCase(),
    'asc'
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      onWorkspacePositionsChanged(
        arrayMove(
          unarchivedWorkspaces.map(workspace => workspace.Id),
          oldIndex,
          newIndex
        )
      );
    },
    [unarchivedWorkspaces, onWorkspacePositionsChanged]
  );

  const onSelected = () => {
    setFilter('');
    onWorkSpaceSelected();
  };

  const workspaceId = activeWorkspaceId || activeSpace.WorkspaceId;

  const bannerStyle = !open ? { width: 48 } : {};

  const canSort = isWorkspaceSortTypeCustom && filter.length === 0;

  return (
    <>
      <motion.div
        animate={
          open ? { opacity: 1, height: 'auto' } : { opacity: 0, height: 0 }
        }
      >
        <Input
          fluid
          className="mx-3 mb-3"
          icon={
            <i className="icon">
              <WeTeamIcon Icon={SearchIcon} className={styles.searchIcon} />
            </i>
          }
          iconPosition="left"
          placeholder={intl.formatMessage({
            id: 'AppNavigation.FilterWorkSpaces',
          })}
          value={filter}
          onChange={event => {
            setFilter(event.target.value);
          }}
        />
      </motion.div>
      <div className="flex column position-relative min-height-0">
        <ScrollPositionTracker
          disableBanner={filter.length > 0}
          className={styles.buttons}
          renderBanner={position => {
            return (
              <ElementOutOfViewBanner position={position} style={bannerStyle}>
                {open && <FormattedMessage id="WorkSpaceList.new" />}
              </ElementOutOfViewBanner>
            );
          }}
        >
          <SortableWorkspaceList
            activeWorkspaceId={workspaceId}
            onWorkSpaceSelected={onSelected}
            open={open}
            canSort={canSort}
            items={filteredUnarchivedWorkspaces}
            onSortEnd={onSortEnd}
            lockAxis="y"
            lockToContainerEdges
            helperClass={styles.navButtonDrag}
            distance={isTouchDevice() ? 0 : 1} // this is required to allow clicking on the list item
            pressDelay={isTouchDevice() ? 200 : 0} // allow scrolling on touch devices
          />
          {archivedWorkspacesOrdered.length > 0 && (
            <>
              {!open && <Divider />}
              {open && (
                <strong className={styles.archived}>
                  <FormattedMessage id="WorkSpaceList.archived" />
                </strong>
              )}
            </>
          )}
          {archivedWorkspacesOrdered.map(workspace => (
            <WorkSpaceButton
              key={workspace.Id}
              workspace={workspace}
              active={workspace.Id === workspaceId}
              onWorkSpaceSelected={onSelected}
              open={open}
              canSort={false}
            />
          ))}
        </ScrollPositionTracker>
      </div>
    </>
  );
}

WorkSpaceList.propTypes = {
  workspaces: PropTypes.array.isRequired,
  activeSpace: PropTypes.object.isRequired,
  activeWorkspaceId: PropTypes.string,
  onWorkSpaceSelected: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isWorkspaceSortTypeCustom: PropTypes.bool.isRequired,
  onWorkspacePositionsChanged: PropTypes.func.isRequired,
  intl: intlShape,
};

export default connect(
  () => {
    const getSpaceById = makeGetSpaceById();
    return (state, props) => ({
      workspaces: getActiveWorkspaces(state),
      activeSpace: getSpaceById(state, props.activeSpaceId),
      isWorkspaceSortTypeCustom: getIsWorkspaceSortTypeCustom(state),
    });
  },
  {
    onWorkspacePositionsChanged: workspacePositionsChanged,
  }
)(injectIntl(WorkSpaceList));
