import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadStats } from 'store/Notifications/loadStats';

import useIsApplicationActive from 'Hooks/useIsApplicationActive';

function LoadStats({ workspaceId, onLoad, isFocussed }) {
  const isApplicationActive = useIsApplicationActive();
  // when the machines comes online/wakes up/focusses
  useEffect(
    () => {
      if (isApplicationActive) {
        onLoad(workspaceId);
      }
    },
    [isApplicationActive, onLoad, workspaceId]
  );

  return null;
}

LoadStats.propTypes = {
  onLoad: PropTypes.func.isRequired,
  workspaceId: PropTypes.string,
};

export default connect(
  undefined,
  {
    onLoad: loadStats,
  }
)(LoadStats);
