export const TYPING_STARTED = 'TYPING_STARTED';
export const TYPING_STOPPED = 'TYPING_STOPPED';

export function typingStarted(email, channelId) {
  return {
    type: TYPING_STARTED,
    email,
    channelId,
  };
}
export function typingStopped(email) {
  return {
    type: TYPING_STOPPED,
    email,
  };
}
