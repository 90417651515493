import { connect } from 'react-redux';

import { makeGetCollabratorByEmailAndSpaceId } from 'store/Spaces/Collaborators/selectors';

import CollaboratorInfo from './CollaboratorInfo';

export const makeMapStateToProps = state => {
  const getCollabratorByEmailAndSpaceId = makeGetCollabratorByEmailAndSpaceId();

  const mapStateToProps = (state, props) => {
    const { spaceId, email } = props;
    return {
      collaborator: getCollabratorByEmailAndSpaceId(state, { email, spaceId }),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(CollaboratorInfo);
