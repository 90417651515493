import { FormattedMessage } from 'react-intl';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getLastReadBySpaceId } from 'store/Notifications/selectors';

import CollaboratorAvatarContainer from '../Collaborators/CollaboratorAvatarContainer';

class ChatItemSeenBy extends React.PureComponent {
  render() {
    const { spaceId, timestamp } = this.props;

    const lastReadUsers = Object.entries(this.props.lastRead).reduce(
      (arr, [email, value]) => {
        if (value.Timestamp >= timestamp) {
          arr.push(email);
        }
        return arr;
      },
      []
    );

    const cA = Object.values(lastReadUsers).map(email => (
      <CollaboratorAvatarContainer
        spaceId={spaceId}
        key={email}
        email={email}
        width={24}
        height={24}
        showStatus={false}
      />
    ));
    return (
      <div>
        {lastReadUsers.length ? (
          <React.Fragment>
            <div className="mb-3">
              <strong>
                <FormattedMessage id="ChatItemSeenBy.read-by" />
              </strong>
            </div>
            <div className="flex wrap">{cA}</div>
          </React.Fragment>
        ) : (
          <em>
            <span className="light-text">
              <FormattedMessage id="ChatItemSeenBy.not-read-by-anyone-yet" />
            </span>
          </em>
        )}
      </div>
    );
  }
}
ChatItemSeenBy.propTypes = {
  timestamp: PropTypes.number.isRequired,
  spaceId: PropTypes.string.isRequired,
  lastRead: PropTypes.object.isRequired,
};

export default connect((state, props) => ({
  lastRead: getLastReadBySpaceId(state, props.spaceId),
}))(ChatItemSeenBy);
