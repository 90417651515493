import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { getIsActiveMeetingAChannelMeeting } from 'store/Meeting/selectors';
import ChannelMembersList from 'Components/Meeting/ManageParticipants/ChannelMembersList';
import WorkspaceMembersList from 'Components/Meeting/ManageParticipants/WorkspaceMembersList';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

const ManageParticipantsModal = ({
  membersExcludingCurrentUser = [],
  onClose,
}) => {
  const isChannelMeeting = useSelector(getIsActiveMeetingAChannelMeeting);

  return (
    <Modal size="mini" open onClose={onClose}>
      <Header>
        <FormattedMessage id="ManageParticipants.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onClose} />
        </div>
      </Header>
      <Modal.Content>
        <ErrorBoundary>
          {isChannelMeeting ? <ChannelMembersList /> : <WorkspaceMembersList />}
        </ErrorBoundary>
      </Modal.Content>
    </Modal>
  );
};

ManageParticipantsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ManageParticipantsModal;
