import axios from 'axios';
import { REPOSITORIES } from 'DataLayer/endpoints';
import process from './process';

export default function(fields) {
  return axios
    .post(REPOSITORIES, fields)
    .then(response => response.data.Repository)
    .then(repository => process.single(repository));
}
