import axios from 'axios';
import { push } from 'react-router-redux';

import { REGISTRATION_ACTION } from 'DataLayer/Authentication/post';
import { getCreateFirstWorkSpaceUrl } from 'pages';

export const desktopAuthentication = (action, token) => async dispatch => {
  if (action === REGISTRATION_ACTION.CREATE_WORK_SPACE) {
    dispatch(push(getCreateFirstWorkSpaceUrl(token)));
  } else {
    axios.defaults.headers.common.Authorization = `Otixo ${token}`;
    window.localStorage.setItem(
      'token',
      axios.defaults.headers.common.Authorization
    );
    window.location = '#';
  }
};
