import { useCallback, useEffect } from 'react';

import useGetProtectedFile from 'Hooks/files/useGetProtectedFile';

function useLaunchFileInDesktop(url, id, onLaunchComplete) {
  const { data, loading, error, fetchFile } = useGetProtectedFile(url, {
    asBlob: true,
    cacheEnabled: true,
  });

  const launch = useCallback(() => {
    fetchFile();
  }, [fetchFile]);

  useEffect(() => {
    if (data && !loading) {
      function saveBlob(blob) {
        let reader = new FileReader();
        reader.onload = function () {
          if (reader.readyState === 2) {
            var buffer = new Buffer(reader.result);
            window.ipcRenderer?.send(
              'launch-file-in-native-app',
              buffer,
              url,
              id
            );
            onLaunchComplete?.();
          }
        };
        reader.readAsArrayBuffer(blob);
      }
      saveBlob(data);
    }
    /**
     * Exclude url to prevent the effect running when we page to
     * the next file
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return { data, loading, error, launch };
}

export default useLaunchFileInDesktop;
