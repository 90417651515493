import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(note) {
  try {
    await commonDb.notes.put({
      noteId: note.id,
      note,
      channelId: note.channelId,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function clearRemovedNotes(networkNotes, channelId) {
  const databaseNotes = await getNotesByChannelId(channelId);

  if (!databaseNotes) {
    return null;
  }

  const networkNoteIds = networkNotes.map(note => note.id);

  // check db note is inside response Notes
  for (const databaseNote of databaseNotes) {
    if (!networkNoteIds.includes(databaseNote.id)) {
      await remove(databaseNote.id);
    }
  }
}

export async function saveMany(networkNotes, channelId) {
  try {
    await clearRemovedNotes(networkNotes, channelId);

    await commonDb.notes.bulkPut(
      networkNotes.map(note => ({
        noteId: note.id,
        note,
        channelId,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.notes.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

// export async function getNotes() {
//   try {
//     const notes = await commonDb.notes.toArray();
//     if (Array.isArray(notes) && notes.length > 0) {
//       return notes.map(note => note.note);
//     }
//   } catch (ex) {
//     Sentry.captureException(ex);
//   }
//   return null;
// }

export async function getNotesByChannelId(channelId = '') {
  try {
    const notes = await commonDb.notes
      .where('channelId')
      .equals(channelId)
      .toArray();
    if (Array.isArray(notes) && notes.length > 0) {
      return notes.map(note => note.note);
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
