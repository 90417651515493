import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StarredMessage from './StarredMessage';
import starResource from 'store/Resources/starResource';
import LinkedItem from 'Components/LinkedItem/LinkedItem';

function StarredResource({ resource, showWorkspaceName, onRemoveStar }) {
  return (
    <StarredMessage
      onRemoveStar={() =>
        onRemoveStar(resource.ChannelId, resource.Resource.Id, false)
      }
    >
      <LinkedItem linkedItem={resource} showWorkspaceName={showWorkspaceName} />
    </StarredMessage>
  );
}

StarredResource.propTypes = {
  resource: PropTypes.object.isRequired,
  showWorkspaceName: PropTypes.bool,
  onRemoveStar: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    onRemoveStar: starResource,
  }
)(StarredResource);
