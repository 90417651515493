import React from 'react';
import PropTypes from 'prop-types';

import './SectionHeader.scss';

const SectionHeader = ({ children, hideBorder }) => (
  <div className={`section-header ${hideBorder ? 'hide-border' : ''}`}>
    {children}
  </div>
);

SectionHeader.propTypes = {
  children: PropTypes.node,
  hideBorder: PropTypes.bool,
};

export default SectionHeader;
