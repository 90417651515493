import PropTypes from 'prop-types';
/**
 * Created by user on 02/03/2017.
 */
import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Message, Progress, Button, Icon } from 'semantic-ui-react';

import taskType from 'Constants/taskType';
import {
  resumeTask,
  skipTask,
  cancelTask,
  TRANSFER_STATUS,
} from '../../store/Transfers/actions';

import './TransferItem.scss';

function getProgress(task) {
  let hasError = false;
  let hasWarning = false;
  let hasSuccess = false;
  let percent = task.ProgressValue || 0;
  switch (task.Status) {
    case TRANSFER_STATUS.ERROR:
      hasError = true;
      percent = 100;
      break;
    case TRANSFER_STATUS.RETRYING:
    case TRANSFER_STATUS.SKIPPING:
    case TRANSFER_STATUS.CANCELLING:
      hasWarning = true;
      percent = 100;
      break;
    case TRANSFER_STATUS.COMPLETED:
      hasSuccess = true;
      percent = 100;
      break;
    case TRANSFER_STATUS.CANCELLED:
      percent = 100;
      break;
    default:
      break;
  }
  return (
    <Progress
      id={task.Id}
      progress
      percent={percent}
      error={hasError}
      warning={hasWarning}
      success={hasSuccess}
    />
  );
}

function getCaption(task) {
  switch (task.Status) {
    case TRANSFER_STATUS.ERROR:
      return (
        <span>
          {task.ErrorMessage && task.ErrorMessage.length > 0
            ? task.ErrorMessage
            : 'Unknown Error'}
        </span>
      );
    case TRANSFER_STATUS.RETRYING:
      return (
        <span>
          <FormattedMessage id="TransferItem.Retrying" />
        </span>
      );
    case TRANSFER_STATUS.SKIPPING:
      return (
        <span>
          <FormattedMessage id="TransferItem.Skipping" />
        </span>
      );
    case TRANSFER_STATUS.CANCELLING:
      return (
        <span>
          <FormattedMessage id="TransferItem.Cancelling" />
        </span>
      );
    case TRANSFER_STATUS.COMPLETED:
      if (task.Type === taskType.ShareTaskEvent) {
        return (
          <span>
            <FormattedMessage id="TransferItem.ShareCompleted" />
          </span>
        );
      }
      return (
        <span>
          <FormattedMessage id="TransferItem.Completed" />
        </span>
      );
    case TRANSFER_STATUS.CANCELLED:
      return (
        <span>
          <FormattedMessage id="TransferItem.Cancelled" />
        </span>
      );
    default:
      return <span className="noerror">{task.ProgressCaption}</span>;
  }
}

const TransferItem = ({ task, hideDate, canCancel, dispatch }) => {
  const showCancel =
    canCancel &&
    (task.Status === TRANSFER_STATUS.ERROR ||
      task.Status === TRANSFER_STATUS.RUNNING);
  const showRetry = task.Status === TRANSFER_STATUS.ERROR;
  const showSkip = task.Status === TRANSFER_STATUS.ERROR;
  return (
    <div className="transfer_window">
      <div className="transfer_window_title">
        <strong>{task.Title}</strong>
        {!hideDate && (
          <small className="light-text">
            <FormattedDate
              value={task.CreatedAt}
              year="numeric"
              month="short"
              day="2-digit"
              hour="numeric"
              minute="numeric"
            />
          </small>
        )}
      </div>
      <div className="transfer_window_progress">
        {getProgress(task)}
        <div className="transfer_label">{getCaption(task)}</div>
        {task.Files && (
          <Message error>
            <p>
              <FormattedMessage id="TransferItem.there-was-a-problem-sharing-the-following-files-please-try-again" />
            </p>
            <ul>
              {task.Files.map(file => (
                <li>{file.Name}</li>
              ))}
            </ul>
          </Message>
        )}
        <div className="transfer_buttons_all">
          {showRetry && (
            <Button
              onClick={() => {
                setTimeout(() => {
                  dispatch(skipTask(task.Id));
                }, 50);
              }}
              basic
              size="tiny"
            >
              <Icon name="mail forward" />
              <FormattedMessage id="TransferItem.Skip" />
            </Button>
          )}
          {showSkip && (
            <Button
              onClick={() => {
                setTimeout(() => {
                  dispatch(resumeTask(task.Id));
                }, 50);
              }}
              basic
              size="tiny"
            >
              <Icon name="repeat" />
              <FormattedMessage id="TransferItem.Retry" />
            </Button>
          )}
          {showCancel && (
            <Button
              onClick={() => {
                setTimeout(() => {
                  dispatch(cancelTask(task.Id));
                }, 50);
              }}
              basic
              size="tiny"
            >
              <Icon name="ban" />
              <FormattedMessage id="TransferItem.Cancel" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

TransferItem.propTypes = {
  task: PropTypes.object.isRequired,
  hideDate: PropTypes.bool,
  canCancel: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

TransferItem.defaultProps = {
  hideDate: false,
  canCancel: true,
};

export default connect()(TransferItem);
