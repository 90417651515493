import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Segment, Button, Divider } from 'semantic-ui-react';

import styles from './FullPageErrorBoundary.module.scss';

function FullPageErrorBoundary() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Segment textAlign="center" size="huge" className={styles.segment}>
          <FormattedMessage id="ErrorBoundary.it-seems-we-were-unable-to-load-this-content-click-here-to-try-again" />
          <Divider hidden />
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            <FormattedMessage id="FullPageErrorBoundary.Refresh" />
          </Button>
        </Segment>
      </div>
    </div>
  );
}

export default FullPageErrorBoundary;
