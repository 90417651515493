import { remove } from 'DataLayer/Reminders/remove';
import { reminderRemoved } from './index';

export const removeReminder = reminder => dispatch => {
  return remove(reminder.Id).then(() => {
    dispatch(reminderRemoved(reminder.Id, reminder.TargetId));
  });
};

export default removeReminder;
