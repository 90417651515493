import { removeSingle, removeMultiple } from 'DataLayer/Resources/remove';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

import { SHARED_RESOURCES_KEY } from './loadSharedResources';
import { fileDeletedAnalyticsEvent } from 'Components/GoogleAnalytics/events';

export const REMOVE_RESOURCE_SUCCESS = 'RESOURCE/REMOVE_SUCCESS';
export const REMOVE_RESOURCE_REQUEST = 'RESOURCE/REMOVE_REQUEST';
export const REMOVE_RESOURCE_FAILED = 'RESOURCE/REMOVE_FAILED';

export function removeResourceSuccess(parentId, ids) {
  return {
    type: REMOVE_RESOURCE_SUCCESS,
    parentId,
    ids,
  };
}

export function removeSharedResource(id) {
  return {
    type: REMOVE_RESOURCE_SUCCESS,
    parentId: SHARED_RESOURCES_KEY,
    ids: [id],
  };
}

export function removeResourceRequest(ids) {
  return {
    type: REMOVE_RESOURCE_REQUEST,
    ids,
  };
}

export function removeResourceFailed(ids) {
  return {
    type: REMOVE_RESOURCE_FAILED,
    ids,
  };
}

const removeResource = (resources, parentId) => dispatch => {
  const ids = resources.map(resource => resource.Id);

  dispatch(removeResourceRequest(ids));

  let promise;

  if (ids.length > 1) {
    promise = removeMultiple(ids);
  } else {
    promise = removeSingle(ids[0]);
  }

  promise.then(
    () => {
      fileDeletedAnalyticsEvent();
      dispatch(displaySuccessToast('toast.removeResource.success'));
      dispatch(removeResourceSuccess(parentId, ids));
    },
    () => {
      dispatch(displayErrorToast('toast.removeResource.error'));
      dispatch(removeResourceFailed(ids));
    }
  );
};

export default removeResource;
