import PropTypes from 'prop-types';
import React from 'react';

import { FormattedDate, FormattedMessage } from 'react-intl';

function MeetingStarted({ meeting }) {
  return (
    <>
      <strong>
        <FormattedMessage id="ActiveMeeting.Started" />
      </strong>
      <FormattedDate
        value={meeting.meetingStartedAt}
        hour="2-digit"
        minute="2-digit"
      />
    </>
  );
}

MeetingStarted.propTypes = {
  meeting: PropTypes.shape({
    meetingStartedAt: PropTypes.number.isRequired,
  }).isRequired,
};

export default MeetingStarted;
