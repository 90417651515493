import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Modal, Header, Button } from 'semantic-ui-react';
import useLocalStorageState from 'use-local-storage-state';
import differenceInDays from 'date-fns/difference_in_days';
import addDays from 'date-fns/add_days';

import { hideDesktopAppReviewModal } from 'store/Modals/actions';
import WeTeamIcon, { CrossIcon } from 'Components/Icons/WeTeamIcon';
import { isMac } from 'store/Application/selectors';

import styles from './DesktopAppReviewModal.module.scss';
import { getDaysToShowDesktopAppReviewModal } from 'config/config';

import happy from './happy.svg';
import sad from './sad.svg';

function DesktopAppReviewModal({ onHide, intl }) {
  const isMacOs = useSelector(state => isMac(state));

  const [dateToShowAppReview, setDateToShowAppReview] = useLocalStorageState(
    'dateToShowAppReview',
    {
      defaultValue: addDays(Date.now(), getDaysToShowDesktopAppReviewModal()), // show 14 days after app is installed
    }
  );

  const onPostpone = () => {
    // show again in 2 weeks
    setDateToShowAppReview(addDays(Date.now(), 14));
    onHide();
  };

  const onRateNowClicked = () => {
    window.ipcRenderer?.send(
      'open-in-external-browser',
      isMacOs
        ? intl.formatMessage({
            id: 'DesktopAppStoreUrl.macOs',
          })
        : intl.formatMessage({
            id: 'DesktopAppStoreUrl.windows',
          })
    );
    // don't show again by setting the date very far in the future
    setDateToShowAppReview(addDays(Date.now(), 10000));
  };

  const shouldHideModal = differenceInDays(Date.now(), dateToShowAppReview) < 0;
  /**
   * immediately hide if the review is not due yet or we are on the web app
   */
  useEffect(() => {
    if (shouldHideModal) {
      onHide();
    }
  }, [shouldHideModal, onHide]);

  if (shouldHideModal) {
    return null;
  }

  return (
    <Modal size="small" open onClose={onPostpone}>
      <Header>
        <span>
          <FormattedMessage id="DesktopAppReviewModal.Title" />
        </span>
        <div className="close_modal">
          <WeTeamIcon Icon={CrossIcon} onClick={onPostpone} />
        </div>
      </Header>
      <Modal.Content>
        <p>
          <FormattedMessage id="DesktopAppReviewModal.SubTitle" />
        </p>
        <div className={styles.feedbackContainers}>
          <div className={styles.feedbackContainer}>
            <div className={styles.emoji}>
              <img alt="sad" src={sad} />
            </div>
            <a
              className={`${styles.badButton} ui basic button`}
              href={intl.formatMessage({
                id: 'TypeFormUrl',
              })}
              rel="noopener noreferrer"
              target="_blank"
              onClick={onPostpone}
            >
              <FormattedMessage id="DesktopAppReviewModal.BadButtonText" />
            </a>
          </div>
          <div className={styles.feedbackContainer}>
            <div className={styles.emoji}>
              <img alt="happy" src={happy} />
            </div>
            <Button primary onClick={onRateNowClicked}>
              <FormattedMessage id="DesktopAppReviewModal.GoodButtonText" />
            </Button>
            <p className={styles.goodButtonInfo}>
              <em>
                <small>
                  <FormattedMessage id="DesktopAppReviewModal.GoodButtonInfo" />
                </small>
              </em>
            </p>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
}

DesktopAppReviewModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default connect(undefined, {
  onHide: hideDesktopAppReviewModal,
})(injectIntl(DesktopAppReviewModal));
