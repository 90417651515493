import axios from 'axios';
import {
  getActiveWorkspaceOfferUrl,
  getWorkspaceOffersUrl,
} from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from 'config/config';

export const get = workspaceId => {
  return axios
    .get(getWorkspaceOffersUrl(workspaceId), {
      baseURL: getOtixoBackendApiUrl(),
    })
    .then(response => response.data.results);
};

export const getActiveOffer = workspaceId => {
  return axios
    .get(getActiveWorkspaceOfferUrl(workspaceId), {
      baseURL: getOtixoBackendApiUrl(),
    })
    .then(response => response.data);
};
