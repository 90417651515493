import axios from 'axios';
import { WORKSPACE } from 'DataLayer/endpoints';
import { saveOne as saveToDb } from 'DataLayer/database/workspaces';

export async function update(id, workspace) {
  const { data } = await axios.put(`${WORKSPACE}/${id}`, workspace);
  saveToDb(data);
  return data;
}

export function markAsSeen(workspaceId) {
  return axios
    .put(`${WORKSPACE}/unseen`, { Id: workspaceId, seen: true })
    .then(response => response.data);
}

export default function putNotifications(flags, deliveryInterval, workspaceId) {
  const flagsArray = Object.keys(flags).filter(key => !!flags[key]);
  const flagsString = [...flagsArray, deliveryInterval].join(',');
  return axios
    .put(`${WORKSPACE}/${workspaceId}/notifications?flags=${flagsString}`)
    .then(response => response.data);
}
