/*
  https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
  Map Jitsi events to Otixo events
*/

export const ACTIVE_MEETING_HEARTBEAT_INTERVAL = 5000;

export const MEETING_EVENTS = {
  meetingParticipantJoined: 'MeetingParticipantJoined',
  meetingParticipantLeft: 'MeetingParticipantLeft',
  meetingInvitationAccepted: 'MeetingInvitationAccepted',
  meetingInvitationRejected: 'MeetingInvitationRejected',
  meetingInvitationReceived: 'MeetingInvitationReceived',
  activeMeeting: 'ActiveMeeting',
};

// generic web socket shape
export const createWebSocketMessage = (type, payload) => ({
  type,
  version: '1',
  timestamp: Date.now(),
  payload,
});

export const createMeetingParticipantJoinedMessage = (userId, roomId) =>
  createWebSocketMessage(MEETING_EVENTS.meetingParticipantJoined, {
    userId,
    roomId,
  });

export const createMeetingParticipantLeftMessage = (userId, roomId) =>
  createWebSocketMessage(MEETING_EVENTS.meetingParticipantLeft, {
    userId,
    roomId,
  });

export const createMeetingInvitationAcceptedMessage = (userId, roomId) =>
  createWebSocketMessage(MEETING_EVENTS.meetingInvitationAccepted, {
    userId,
    roomId,
  });

export const createMeetingInvitationRejectedMessage = (userId, roomId) =>
  createWebSocketMessage(MEETING_EVENTS.meetingInvitationRejected, {
    userId,
    roomId,
  });

export const createMeetingInvitationReceivedMessage = (userId, roomId) =>
  createWebSocketMessage(MEETING_EVENTS.meetingInvitationRejected, {
    userId,
    roomId,
  });

export const createActiveMeetingMessage = (userId, roomId) =>
  createWebSocketMessage(MEETING_EVENTS.activeMeeting, {
    userId,
    roomId,
  });
