import { DOWNLOAD_ADDED } from './addDownload';

export default function downloadReducer(state = [], action) {
  switch (action.type) {
    case DOWNLOAD_ADDED:
      return state.concat({ url: action.url, id: action.id });
    default:
      return state;
  }
}
