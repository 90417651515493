/*
  https://developer.mozilla.org/en-US/docs/Web/API/notification
*/

function createNotification(title, message, data) {
  const options = {
    body: message,
    data,
  };
  return new Notification(title, options);
}

export function createDesktopNotification(title, message, data) {
  return new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      reject();
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      const notification = createNotification(title, message, data);
      resolve(notification);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission(permission => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          const notification = createNotification(title, message, data);
          resolve(notification);
        }
      });
    }
  });
}
