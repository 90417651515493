import { FormattedMessage } from 'react-intl';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider, Button } from 'semantic-ui-react';
import { loadOldActivities, loadNewActivities } from 'store/Activities/actions';
import {
  getActivities,
  activitiesLoading,
  activitiesHasOlder,
} from 'store/Activities/selectors';

import FullScreenPopup from 'Components/Layouts/SidebarLayout/FullScreenPopup';
import Notification from 'Components/Notification/Notification';

import EmptyImage from './Activities.png';

class Activities extends Component {
  componentDidMount() {
    this.props.dispatch(loadNewActivities());
  }

  loadOlder = () => {
    const { activities, dispatch } = this.props;
    const oldestActivity = activities[activities.length - 1];
    const oldestActivityId = oldestActivity && oldestActivity.Id;
    dispatch(loadOldActivities(oldestActivityId));
  };

  render() {
    const { loading, activities, hasOlder } = this.props;

    return (
      <FullScreenPopup header={<FormattedMessage id="Activities.activities" />}>
        {!loading && activities.length === 0 && (
          <div className="flex column center justify-content-space-between">
            <img height={300} alt="reminder illustration" src={EmptyImage} />
            <p>
              <strong>
                <FormattedMessage id="StarredMessagesList.EmptyTitle" />
              </strong>
            </p>
            <p>
              <FormattedMessage id="StarredMessagesList.EmptyMessage" />
            </p>
          </div>
        )}
        {activities.map(activity => (
          <React.Fragment key={activity.Id}>
            <Notification
              notification={activity}
              showWorkspaceName
              showAvatar={false}
            />
            <Divider />
          </React.Fragment>
        ))}
        {hasOlder && (
          <div className="mt-4 text-align-center">
            <Button
              loading={loading}
              disabled={loading}
              primary
              onClick={this.loadOlder}
            >
              <FormattedMessage id="Activities.load-previous-activities" />
            </Button>
          </div>
        )}
      </FullScreenPopup>
    );
  }
}

Activities.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activities: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  hasOlder: PropTypes.bool.isRequired,
};

export default connect(state => ({
  activities: getActivities(state),
  hasOlder: activitiesHasOlder(state),
  loading: activitiesLoading(state),
}))(Activities);
