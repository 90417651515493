import React from 'react';
import { Route, Redirect, IndexRoute } from 'react-router';

import {
  REGISTER,
  REGISTER_VERIFY_EMAIL,
  REGISTER_CREATE_FIRST_WORK_SPACE,
  PRIVATECHAT,
  DRIVES,
  SPACES,
  WORKSPACES,
  WORKSPACE_ONBOARDING,
  CREARTE_WORKSPACE,
  TRANSFER_DATA_OVERVIEW,
  TRANSFER_DATA_PURCHASE,
  TRANSFER_DATA_PURCHASE_COMPLETE,
  PROFILE,
  SECURITY,
  ACCOUNT_EMAILS,
  TRANSFER_DATA_BILLING,
  PRIVATECHAT_NOTIFICATIONS,
  USER_NOT_FOUND,
  SIGN_UP_LEGACY,
  REGISTER_WORKSPACE_INVITATION,
  CREATE_TEAM_SPACE,
  DELETE_ACCOUNT_WE_TEAM_UPSELL,
  MEETING_RINGBACK_AUDIO,
  LOAD_BACKUP,
} from 'pages';

import RedirectToArea from 'Views/RedirectToArea';
import RedirectToWorkspace from 'Views/RedirectToWorkspace';
import RedirectToDrive from 'Views/RedirectToDrive';

import App from '../App';
import AuthenticatedApp from '../AuthenticatedApp';
import Logout from 'Components/Pages/Logout';
import SentryTest from 'Views/SentryTest/SentryTest';
import SimulateCountry from 'Views/SimulateCountry/SimulateCountry';

import SpaceHomeLayoutConnected from 'Views/SpaceHome/SpaceHomeLayoutConnected';
import SpaceHome from 'Views/SpaceHome/SpaceHome';
import SpaceMigration from 'Views/SpaceMigration/SpaceMigration';

import { startAppCues } from 'Components/AppCues/AppCuesLaunch';
import { isRegistrationAvailable } from 'config/features';
import WorkspaceSidebarLayout from 'Components/Layouts/WorkspaceSidebarLayout/WorkspaceSidebarLayout';
import SkeletonLayout from 'Views/Skeleton/SkeletonLayout';

import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import FullPageErrorBoundary from 'Components/ErrorBoundaries/FullPageErrorBoundary';
import LoadBackup from 'LoadBackup';
import AutoAcceptTrial from 'Views/Campaign/AutoAcceptTrial';

const CloudDriveAuthComplete = React.lazy(() =>
  import(
    /* webpackChunkName: "CloudDriveAuthComplete" */ 'Views/CloudDriveAuthComplete'
  )
);

const UserNotFound = React.lazy(() =>
  import(/* webpackChunkName: "UserNotFound" */ 'Views/Login/UserNotFound')
);

const Login = React.lazy(() =>
  import(/* webpackChunkName: "Login" */ 'Views/Login/Login')
);

const Register = React.lazy(() =>
  import(/* webpackChunkName: "Register" */ 'Views/Login/Register')
);

const PasswordReset = React.lazy(() =>
  import(/* webpackChunkName: "PasswordReset" */ 'Views/Login/PasswordReset')
);

const ResetPassword = React.lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ 'Views/Login/ResetPassword')
);

const EmailConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "EmailConfirmation" */ 'Views/Login/EmailConfirmation'
  )
);

const OnboardingContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "OnboardingContainer" */ 'Views/Onboarding/OnboardingContainer'
  )
);

const WorkpaceOnboarding = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkpaceOnboarding" */ 'Views/Onboarding/Workspace/WorkspaceOnboarding'
  )
);

const VerifyEmail = React.lazy(() =>
  import(
    /* webpackChunkName: "VerifyEmail" */ 'Views/Onboarding/VerifyEmail/VerifyEmail'
  )
);

const CreateFirstWorkSpace = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateFirstWorkSpace" */ 'Views/Onboarding/CreateFirstWorkSpace/CreateFirstWorkSpace'
  )
);

const WorkspaceInvitationRegistration = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceInvitationRegistration" */ 'Views/Onboarding/WorkspaceInvitationRegistration/WorkspaceInvitationRegistration'
  )
);

const CreateWorkspace = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateWorkspace" */ 'Views/CreateWorkspace/CreateWorkspace'
  )
);

const CreateTeamspace = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateTeamspace" */ 'Views/CreateTeamspace/CreateTeamspace'
  )
);

const DeleteAccountWeTeamUpsell = React.lazy(() =>
  import(
    /* webpackChunkName: "DeleteAccountWeTeamUpsell" */ 'Views/DeleteAccountWeTeamUpsell/DeleteAccountWeTeamUpsell'
  )
);

const PrivateChatHome = React.lazy(() =>
  import(
    /* webpackChunkName: "PrivateChatHome" */ 'Views/PrivateChat/Home/PrivateChatHome'
  )
);

const PrivateChatNotificationSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "PrivateChatNotificationSettings" */ 'Views/PrivateChat/NotificationSettings/PrivateChatNotificationSettings'
  )
);

const DrivesLayout = React.lazy(() =>
  import(/* webpackChunkName: "DrivesLayout" */ 'Views/Drives/DrivesLayout')
);

const ResourceListContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "ResourceListContainer" */ 'Components/FileExplorer/ResourceList/ResourceListContainer'
  )
);
const SearchContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "SearchContainer" */ 'Components/FileExplorer/ResourceSearchList/SearchContainer'
  )
);

const Account = React.lazy(() =>
  import(/* webpackChunkName: "Account" */ 'Components/Pages/Account')
);

const Profile = React.lazy(() =>
  import(/* webpackChunkName: "Profile" */ 'Components/Account/Profile/Profile')
);
const Emails = React.lazy(() =>
  import(/* webpackChunkName: "Emails" */ 'Components/Account/Emails/Emails')
);
const Security = React.lazy(() =>
  import(
    /* webpackChunkName: "Security" */ 'Components/Account/Security/Security'
  )
);
const Transfers = React.lazy(() =>
  import(
    /* webpackChunkName: "Transfers" */ 'Components/Account/Transfers/Transfers'
  )
);

const TransfersContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "TransfersContainer" */ 'Components/Account/Transfers/TransfersContainer'
  )
);

const TransfersBilling = React.lazy(() =>
  import(
    /* webpackChunkName: "TransfersBilling" */ 'Components/Account/Transfers/TransfersBilling'
  )
);

const TransfersPayment = React.lazy(() =>
  import(
    /* webpackChunkName: "TransfersPayment" */ 'Components/Account/Transfers/TransfersPayment'
  )
);

const TransfersPaymentComplete = React.lazy(() =>
  import(
    /* webpackChunkName: "TransfersPaymentComplete" */ 'Components/Account/Transfers/TransfersPaymentComplete'
  )
);

const UserTransferPlan = React.lazy(() =>
  import(
    /* webpackChunkName: "UserTransferPlan" */ 'Components/Account/Plan/UserTransferPlan'
  )
);
const WorkspaceUpgrade = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceUpgrade" */ 'Components/Pages/WorkspaceUpgrade/WorkspaceUpgrade'
  )
);
const WorkspaceAdminLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceAdminLayout" */ 'Components/Pages/WorkspaceAdminLayout'
  )
);
const WorkspaceAdminWithMenu = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceAdminWithMenu" */ 'Components/Pages/WorkspaceAdminWithMenu'
  )
);

const WorkspaceGeneralSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceGeneralSettings" */ 'Components/Pages/WorkspaceGeneralSettings/WorkspaceGeneralSettings'
  )
);

const WorkspaceId = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceId" */ 'Components/Pages/WorkspaceId/WorkspaceId'
  )
);

const WorkspaceExportContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceExportContainer" */ 'Components/Pages/WorkspaceExport/WorkspaceExportContainer'
  )
);

const WorkspaceBackup = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceBackup" */ 'Components/Pages/WorkspaceBackup/WorkspaceBackup'
  )
);

const WorkspacePlanSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspacePlanSettings" */ 'Components/Pages/WorkspacePlanSettings/WorkspacePlanSettings'
  )
);

const WorkspacePlanInvoices = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspacePlanInvoices" */ 'Components/Pages/WorkspacePlanSettings/RecurlySubscription/Invoices'
  )
);

const WorkspaceMembers = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceMembers" */ 'Components/Pages/WorkspaceMembers/WorkspaceMembers'
  )
);

const WorkspaceNotificationSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceNotificationSettings" */ 'Components/Pages/WorkspaceNotificationSettings/WorkspaceNotificationSettings'
  )
);

const WorkspaceUpgradeLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceUpgradeLayout" */ 'Components/Pages/WorkspaceUpgrade/WorkspaceUpgradeLayout'
  )
);

const WorkspaceUpgradePaymentPage = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceUpgradePaymentPage" */ 'Components/Pages/WorkspaceUpgrade/WorkspaceUpgradePaymentPage'
  )
);

const WorkspaceUpgradePaymentComplete = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceUpgradePaymentComplete" */ 'Components/Pages/WorkspaceUpgrade/WorkspaceUpgradePaymentComplete'
  )
);

const IntegrationList = React.lazy(() =>
  import(
    /* webpackChunkName: "IntegrationList" */ 'Components/Integrations/IntegrationList'
  )
);

const MySpacesIntegrations = React.lazy(() =>
  import(
    /* webpackChunkName: "MySpacesIntegrations" */ 'Components/Integrations/MySpacesIntegrations'
  )
);
const SpacesIntegrations = React.lazy(() =>
  import(
    /* webpackChunkName: "SpacesIntegrations" */ 'Components/Integrations/SpacesIntegrations'
  )
);

const PublicIndex = React.lazy(() =>
  import(/* webpackChunkName: "Index" */ 'PublicSharing/Index')
);
const PublicPreview = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicPreview" */ 'PublicSharing/Preview/Preview'
  )
);
const PublicBrowse = React.lazy(() =>
  import(/* webpackChunkName: "Browse" */ 'PublicSharing/Browse')
);
const PublicView = React.lazy(() =>
  import(/* webpackChunkName: "View" */ 'PublicSharing/View')
);
const PublicPassword = React.lazy(() =>
  import(/* webpackChunkName: "Password" */ 'PublicSharing/Password')
);
const PublicExpired = React.lazy(() =>
  import(/* webpackChunkName: "Expired" */ 'PublicSharing/Expired')
);
const Meeting = React.lazy(() =>
  import(/* webpackChunkName: "Meeting" */ 'Components/Meeting/Page')
);
const GuestWaitingRoom = React.lazy(() =>
  import(
    /* webpackChunkName: "GuestWaitingRoom" */ 'Views/Public/Meeting/Scheduled/GuestWaitingRoom'
  )
);
const HostStart = React.lazy(() =>
  import(
    /* webpackChunkName: "HostStart" */ 'Views/Public/Meeting/Scheduled/HostStart'
  )
);
const ViewScheduledMeeting = React.lazy(() =>
  import(
    /* webpackChunkName: "ViewScheduledMeeting" */ 'Views/Public/Meeting/Scheduled/View'
  )
);
const PublicInstantMeetingView = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicInstantMeetingView" */ 'Views/Public/Meeting/Instant/View'
  )
);
const PublicInstantMeetingInit = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicInstantMeetingInit" */ 'Views/Public/Meeting/Instant/Init'
  )
);
const PublicInstantMeetingJoin = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicInstantMeetingJoin" */ 'Views/Public/Meeting/Instant/Join'
  )
);
const PublicInstantMeetingThanks = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicInstantMeetingThanks" */ 'Views/Public/Meeting/Instant/Thanks'
  )
);
const PublicMeetingContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicMeetingContainer" */ 'Views/Public/Meeting/Container'
  )
);
const PublicInstantMeetingStartIframe = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicInstantMeetingIframe" */ 'Views/Public/Meeting/Start/Iframe'
  )
);
const PublicInstantMeetingStart = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicInstantMeetingStart" */ 'Views/Public/Meeting/Start/Start'
  )
);
const PublicMeetingScheduled = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicMeetingScheduled" */ 'Views/Public/Meeting/Scheduled/MeetingScheduled'
  )
);
const MyWorkSpaceFilesLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "MyWorkSpaceFilesLayout" */ 'Views/MyWorkSpaceFiles/Layout'
  )
);
const MyWorkSpaceFilesWorkspace = React.lazy(() =>
  import(
    /* webpackChunkName: "MyWorkSpaceFilesWorkspace" */ 'Views/MyWorkSpaceFiles/Workspace/Workspace'
  )
);
const MyWorkSpaceFilesLinkedItems = React.lazy(() =>
  import(
    /* webpackChunkName: "MyWorkSpaceFilesLinkedItems" */ 'Views/MyWorkSpaceFiles/LinkedItems/LinkedItems'
  )
);
const MyWorkSpaceFilesResources = React.lazy(() =>
  import(
    /* webpackChunkName: "MyWorkSpaceFilesResources" */ 'Views/MyWorkSpaceFiles/Resources/Resources'
  )
);

const MeetingRingbackAudio = React.lazy(() =>
  import(
    /* webpackChunkName: "MeetingRingbackAudio" */ 'Views/MeetingRingbackAudio/MeetingRingbackAudio'
  )
);

export default () => (
  <Route path="/" component={App} onChange={() => startAppCues()}>
    <IndexRoute component={RedirectToArea} />
    <Route
      path="cloud-drive-auth-complete(/:channelId)"
      component={CloudDriveAuthComplete}
    />
    <Route
      path="sign-in"
      component={props => (
        <ErrorBoundary FallbackComponent={FullPageErrorBoundary}>
          <Login {...props} />
        </ErrorBoundary>
      )}
    />
    <Redirect from={SIGN_UP_LEGACY} to={REGISTER} />
    {isRegistrationAvailable() ? (
      <Route
        path={REGISTER}
        component={props => (
          <ErrorBoundary FallbackComponent={FullPageErrorBoundary}>
            <Register {...props} />
          </ErrorBoundary>
        )}
      />
    ) : (
      <Redirect from={REGISTER} to="sign-in" />
    )}
    <Route path="reset-password" component={PasswordReset} />
    <Route path="password-reset" component={ResetPassword} />
    <Route path="email-confirmation" component={EmailConfirmation} />
    <Route path={USER_NOT_FOUND} component={UserNotFound} />
    <Route component={AuthenticatedApp}>
      <Route path={'campaign/3-month-free'} component={AutoAcceptTrial} />
      <Route path={'campaign/auto-accept-trial'} component={AutoAcceptTrial} />
      {/*
      Pages requiring the black Work Spaces sidebar should be children of WorkspaceSidebarLayout
      */}
      <Route component={WorkspaceSidebarLayout}>
        {/* Redirect legacy urls */}
        <Redirect from={SPACES} to={WORKSPACES} />

        <Route path={WORKSPACES} component={RedirectToWorkspace} />
        <Route
          exact
          path={WORKSPACE_ONBOARDING}
          component={WorkpaceOnboarding}
        />
        <Route exact path={CREARTE_WORKSPACE} component={CreateWorkspace} />
        <Route path={PRIVATECHAT} component={PrivateChatHome} />
        <Route
          path={PRIVATECHAT_NOTIFICATIONS}
          component={PrivateChatNotificationSettings}
        />
        <Route component={SpaceHomeLayoutConnected}>
          <Route path="/spaces/:spaceId/migration" component={SpaceMigration} />
          <Route
            path="/spaces/:spaceId(/:notificationId)"
            component={SpaceHome}
          />
          <Route
            path="/spaces/:spaceId/file(/:linkedItemId)"
            component={SpaceHome}
          />
          <Route
            path="/spaces/:spaceId/notes(/:noteId)"
            component={SpaceHome}
          />
          <Route
            path="/spaces/:spaceId(/items/:parentResourceId)(/:resourceId)"
            component={SpaceHome}
          />
          <Route path={`${WORKSPACES}/:workspaceId`} component={SpaceHome} />
        </Route>
        <Route component={SkeletonLayout}>
          <Route
            path={`${WORKSPACES}/:workspaceId/integrations`}
            component={IntegrationList}
          >
            <Route path="browse" component={SpacesIntegrations} />
            <Route path="manage" component={MySpacesIntegrations} />
            <Redirect from="*" to="/" />
          </Route>
        </Route>
        <Route
          path={`${WORKSPACES}/:workspaceId/settings`}
          component={WorkspaceAdminLayout}
        >
          <Route component={WorkspaceAdminWithMenu}>
            <IndexRoute component={WorkspaceGeneralSettings} />
            <Route path="general" component={WorkspaceGeneralSettings} />
            <Route path="id" component={WorkspaceId} />
            <Route path="export" component={WorkspaceExportContainer} />
            <Route path="backup" component={WorkspaceBackup} />
            <Route path="members" component={WorkspaceMembers} />
            <Route
              path="notifications"
              component={WorkspaceNotificationSettings}
            />
          </Route>
          <Route path="plan" component={WorkspacePlanSettings} />
          <Route component={WorkspaceUpgradeLayout}>
            <Route path="plan/invoices" component={WorkspacePlanInvoices} />
            <Route path="upgrade" component={WorkspaceUpgrade} />
            <Route
              exact
              path="upgrade/payment/complete"
              component={WorkspaceUpgradePaymentComplete}
            />
            <Route
              path="upgrade/payment/:plan"
              component={WorkspaceUpgradePaymentPage}
            />
          </Route>
        </Route>
        <Route path="settings/account" component={Account}>
          <Route path={PROFILE} component={Profile} />
          <Route path={ACCOUNT_EMAILS} component={Emails} />
          <Route path={SECURITY} component={Security} />
          <Route path={TRANSFER_DATA_BILLING} component={TransfersBilling} />
        </Route>
      </Route>

      <Route path={CREATE_TEAM_SPACE} component={CreateTeamspace} />
      <Route
        path={DELETE_ACCOUNT_WE_TEAM_UPSELL}
        component={DeleteAccountWeTeamUpsell}
      />

      <Route path={DRIVES} component={RedirectToDrive} />
      {/* Your Files should display as a dialog over the Work Spacs area */}
      <Route component={SkeletonLayout}>
        <Route path={DRIVES} component={DrivesLayout}>
          <Route component={MyWorkSpaceFilesLayout}>
            <Route
              path="my-work-space-files(/:workspaceId)"
              component={MyWorkSpaceFilesWorkspace}
            />
            <Route
              path="my-work-space-files/:workspaceId/:channelId"
              component={MyWorkSpaceFilesLinkedItems}
            />
            <Route
              path="my-work-space-files/:workspaceId/:channelId/:resourceId"
              component={MyWorkSpaceFilesResources}
            />
          </Route>
          <Route path=":resourceId" component={ResourceListContainer} />
          <Route exact path="search(/:query)" component={SearchContainer} />
        </Route>
      </Route>

      <Redirect
        from={`${DRIVES}${TRANSFER_DATA_OVERVIEW}`}
        to={TRANSFER_DATA_OVERVIEW}
      />

      <Route component={TransfersContainer}>
        <Route
          exact
          path={TRANSFER_DATA_OVERVIEW}
          component={UserTransferPlan}
        />
        <Route exact path={TRANSFER_DATA_PURCHASE} component={Transfers} />
        <Route
          exact
          path={TRANSFER_DATA_PURCHASE_COMPLETE}
          component={TransfersPaymentComplete}
        />
        <Route
          path={`${TRANSFER_DATA_PURCHASE}/:amount`}
          component={TransfersPayment}
        />
      </Route>

      <Redirect from="resources/:resourceId" to="drives/:resourceId" />
    </Route>
    <Route path="/public/:publicLinkId" component={PublicIndex}>
      <Route path="/public/login/:publicLinkId" component={PublicPassword} />
      <Route path="/public/expired/:publicLinkId" component={PublicExpired} />
      <Route
        path="/public/view/:publicLinkId/:resourceId"
        component={PublicView}
      />
      <Route
        path="/public/browse/:publicLinkId/:resourceId"
        component={PublicBrowse}
      />
      <Route
        path="/public/preview/:publicLinkId/:parentResourceId/:resourceId"
        component={PublicPreview}
      />
    </Route>
    <Route path={REGISTER} component={OnboardingContainer}>
      <Route path={REGISTER_VERIFY_EMAIL} component={VerifyEmail} />
      <Route
        path={REGISTER_CREATE_FIRST_WORK_SPACE}
        component={CreateFirstWorkSpace}
      />
      <Route
        path={REGISTER_WORKSPACE_INVITATION}
        component={WorkspaceInvitationRegistration}
      />
    </Route>
    <Route path="/meeting/:roomId" component={Meeting} />
    <Route component={PublicMeetingContainer}>
      <Route
        exact
        path="/meeting/public/scheduled/created/:meetingId/:token"
        component={PublicMeetingScheduled}
      />
      <Route
        exact
        path="/meeting/public/scheduled/:locale/waiting-room/:meetingId"
        component={GuestWaitingRoom}
      />
      <Route
        exact
        path="/meeting/public/scheduled/:locale/host-start/:meetingId"
        component={HostStart}
      />
      <Route
        exact
        path="/meeting/public/scheduled/:locale/view/:meetingId(/:token)"
        component={ViewScheduledMeeting}
      />
      <Route
        path="/meeting/public/start/iframe"
        exact
        component={PublicInstantMeetingStartIframe}
      />
      <Route
        path="/meeting/public/start(/:locale)"
        component={PublicInstantMeetingStart}
      />
      <Route
        path="/meeting/public/join/:roomName(/:locale)"
        exact
        component={PublicInstantMeetingJoin}
      />
      <Route
        path="/meeting/public/thanks(/:locale)"
        exact
        component={PublicInstantMeetingThanks}
      />
      <Route
        path="/meeting/public/:roomName/:displayName/init"
        component={PublicInstantMeetingInit}
      />
      <Route
        path="/meeting/public/:roomName/:displayName"
        component={PublicInstantMeetingView}
      />
    </Route>
    <Route path={LOAD_BACKUP} component={LoadBackup} />
    <Route path="/logout" component={Logout} />
    <Route path="/sentry-test" component={SentryTest} />
    <Route path={MEETING_RINGBACK_AUDIO} component={MeetingRingbackAudio} />
    <Route path="/simulate-country/:countryCode" component={SimulateCountry} />
    <Redirect from="*" to="/" />
  </Route>
);
