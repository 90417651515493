import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'semantic-ui-react';

import {
  showDeleteSpaceModal,
  showEditSpaceModal,
  showLeaveSpaceModal,
  showExportChannelModal,
} from 'store/Modals/actions';
import { spaceSidebarPanelSelected, SIDEBAR_ITEMS } from 'store/UI/index';
import archiveSpace from 'store/Spaces/archiveSpace';
import unarchiveSpace from 'store/Spaces/unarchiveSpace';

import './SpaceOptions.scss';
import WeTeamIcon, { DownloadIcon } from 'Components/Icons/WeTeamIcon';
import { AddUserIcon } from 'Components/Icons/WeTeamIcon';
import { EditIcon } from 'Components/Icons/WeTeamIcon';
import { DeleteIcon } from 'Components/Icons/WeTeamIcon';
import { LogoutIcon } from 'Components/Icons/WeTeamIcon';
import { FolderIcon } from 'Components/Icons/WeTeamIcon';
import MarkAsUnreadOption from 'Components/Spaces/MarkAsUnreadOption';
import ManageTagsOption from 'Components/Spaces/ManageTagsOption';

export const SpaceOptions = ({
  space,
  onShowExportChannelModal,
  onShowEditSpaceModal,
  onShowDeleteSpaceModal,
  onShowLeaveSpaceModal,
  onArchiveSpace,
  onUnarchiveSpace,
  onViewChannelMembers,
}) => {
  if (!space.Id || space.ProvisionalSpace) {
    return null;
  }

  const showAddChannelMembers = space.ACL.Invite;
  const showRename = space.ACL.Rename;
  const showDelete = space.ACL.Delete;
  const showLeave = space.ACL.Leave; // && space.Type !== 'SYSTEM';
  const showArchive = !space.Filtered;

  return (
    <React.Fragment>
      {showAddChannelMembers && (
        <Menu.Item
          data-testid="view-members"
          onClick={event => {
            onViewChannelMembers(space.Id, SIDEBAR_ITEMS.members, true);
          }}
        >
          <WeTeamIcon Icon={AddUserIcon} link={false} />
          <FormattedMessage id="SpaceOptions.AddChannelMembers" />
        </Menu.Item>
      )}
      {showRename && (
        <Menu.Item
          data-testid="rename"
          onClick={event => {
            onShowEditSpaceModal(space);
          }}
        >
          <WeTeamIcon Icon={EditIcon} link={false} />
          <FormattedMessage id="SpaceOptions.Edit" />
        </Menu.Item>
      )}
      <Menu.Item
        data-testid="export"
        onClick={event => {
          onShowExportChannelModal(space);
        }}
      >
        <WeTeamIcon Icon={DownloadIcon} link={false} />
        <FormattedMessage id="SpaceOptions.Export" />
      </Menu.Item>

      {showArchive ? (
        <Menu.Item
          data-testid="archive"
          onClick={event => {
            onArchiveSpace(space);
          }}
        >
          <WeTeamIcon Icon={FolderIcon} link={false} />
          <FormattedMessage id="SpaceOptions.Archive" />
        </Menu.Item>
      ) : (
        <Menu.Item
          data-testid="unarchive"
          onClick={event => {
            onUnarchiveSpace(space);
          }}
        >
          <WeTeamIcon Icon={FolderIcon} link={false} />
          <FormattedMessage id="SpaceOptions.Unarchive" />
        </Menu.Item>
      )}
      {showDelete && (
        <Menu.Item
          data-testid="delete"
          onClick={event => {
            onShowDeleteSpaceModal(space);
          }}
        >
          <WeTeamIcon Icon={DeleteIcon} link={false} />
          <FormattedMessage id="SpaceOptions.RemoveSpace" />
        </Menu.Item>
      )}
      {showLeave && (
        <Menu.Item
          data-testid="leave"
          onClick={event => {
            onShowLeaveSpaceModal(space);
          }}
        >
          <WeTeamIcon Icon={LogoutIcon} link={false} />
          <FormattedMessage id="SpaceOptions.Leave" />
        </Menu.Item>
      )}
      <MarkAsUnreadOption
        channelId={space.Id}
        workspaceId={space.WorkspaceId}
      />
      <ManageTagsOption channelId={space.Id} />
    </React.Fragment>
  );
};

SpaceOptions.propTypes = {
  space: PropTypes.object.isRequired,
  onShowExportChannelModal: PropTypes.func.isRequired,
  onShowEditSpaceModal: PropTypes.func.isRequired,
  onShowDeleteSpaceModal: PropTypes.func.isRequired,
  onShowLeaveSpaceModal: PropTypes.func.isRequired,
  onArchiveSpace: PropTypes.func.isRequired,
  onUnarchiveSpace: PropTypes.func.isRequired,
  onViewChannelMembers: PropTypes.func.isRequired,
};

SpaceOptions.defaultProps = {
  showAddItem: false,
};

export default connect(undefined, {
  onShowExportChannelModal: showExportChannelModal,
  onShowEditSpaceModal: showEditSpaceModal,
  onShowDeleteSpaceModal: showDeleteSpaceModal,
  onShowLeaveSpaceModal: showLeaveSpaceModal,
  onArchiveSpace: archiveSpace,
  onUnarchiveSpace: unarchiveSpace,
  onViewChannelMembers: spaceSidebarPanelSelected,
})(SpaceOptions);
