import styles from './AudioWave.module.scss';

function AudioWave() {
  return (
    <div className={styles.bars}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default AudioWave;
