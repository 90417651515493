import React from 'react';
import { useSelector } from 'react-redux';

import {
  getIsMeetingJoined,
  getNumberOfActiveMeetings,
} from 'store/Meeting/selectors';

import Beacon from './Beacon';

const ActiveMeetingBeacon = ({ ...props }) => {
  const numberOfActiveMeetings = useSelector(state =>
    getNumberOfActiveMeetings(state)
  );
  const isMeetingJoined = useSelector(getIsMeetingJoined);

  if (numberOfActiveMeetings === 0) {
    return false;
  }

  return <Beacon {...props} active={isMeetingJoined} />;
};

export default ActiveMeetingBeacon;
