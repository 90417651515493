import React from 'react';
import PropTypes from 'prop-types';
import { isDesktopApp } from 'config/config';

class IfElectron extends React.PureComponent {
  render() {
    if (isDesktopApp()) {
      // always show for web app
      return this.props.children;
    }

    return null;
  }
}

IfElectron.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IfElectron;
