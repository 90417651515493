import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { Modal, Header, Form, Button, Message } from 'semantic-ui-react';

import ReminderPresets, { PRESETS } from './ReminderPresets';
import editReminder from 'store/Reminders/editReminder';
import IsDevelopmentMode from 'Components/Development/IsDevelopmentMode';
import isFuture from 'date-fns/is_future';
import useAsyncFn from 'Hooks/useAsyncFn';

function SnoozeReminderModal({ reminder, onClose, onSave }) {
  const [selectedPreset, setSelectedPreset] = useState(PRESETS.tenMinutes);
  const [date, setDate] = useState(reminder.Time);

  const [state, onSnooze] = useAsyncFn(
    async () => {
      await onSave(reminder.Id, date.getTime(), reminder.Note);
      onClose(true);
    },
    [reminder.Id, date]
  );

  const onDateChange = (value, preset) => {
    setSelectedPreset(preset);
    setDate(value);
  };

  const onCloseWithoutSnooze = () => {
    onClose(false);
  };

  const canSnooze = !state.loading && isFuture(date);

  return (
    <Modal size="small" open onClose={onCloseWithoutSnooze}>
      <Header>
        <span>
          <FormattedMessage id="SnoozeReminderModal.Title" />
        </span>
        <div className="close_modal">
          <button
            className="close_modal_button"
            onClick={onCloseWithoutSnooze}
          />
        </div>
      </Header>
      <Modal.Content>
        {/* eslint-disable react/jsx-no-literals */}
        <IsDevelopmentMode>time: {date.toString()}</IsDevelopmentMode>
        {/* eslint-enable react/jsx-no-literals */}
        <Form>
          <ReminderPresets
            onChange={onDateChange}
            presetValue={selectedPreset}
            value={date}
          />
        </Form>
        {state.error && (
          <Message error>
            <FormattedMessage id="SnoozeReminderModal.ErrorMessage" />
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div className="modal-buttons">
          <Button basic onClick={onCloseWithoutSnooze}>
            <FormattedMessage id="Close" />
          </Button>
          <Button
            primary
            disabled={!canSnooze}
            loading={state.loading}
            onClick={onSnooze}
          >
            <FormattedMessage id="SnoozeReminderModal.SaveButtonText" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

SnoozeReminderModal.propTypes = {
  reminder: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    onSave: editReminder,
  }
)(SnoozeReminderModal);
