/*
  If you want to store UI state that persists after a browser refresh,
  use store/UITemporary/index.js
*/

const CHANNEL_FILE_LOCATION_CHANGED =
  'UITemporary/CHANNEL_FILE_LOCATION_CHANGED';
export const FILE_MANAGER_LOCATION_CHANGE =
  'UITemporary/FILE_MANAGER_LOCATION_CHANGE';
export const FILE_BROWSER_LOCATION_CHANGE =
  'UITemporary/FILE_BROWSER_LOCATION_CHANGE';
export const CHAT_SCROLL_POSITION_CHANGED =
  'UITemporary/CHAT_SCROLL_POSITION_CHANGED';

export const chatScrollPositionChanged = (id, position) => ({
  type: CHAT_SCROLL_POSITION_CHANGED,
  id,
  position,
});

export const channelFileLocationChanged = (channelId, location) => ({
  type: CHANNEL_FILE_LOCATION_CHANGED,
  channelId,
  location,
});

export const fileManagerLocationChanged = location => ({
  type: FILE_MANAGER_LOCATION_CHANGE,
  location,
});

export const fileBrowserLocationChanged = location => ({
  type: FILE_BROWSER_LOCATION_CHANGE,
  location,
});

const initialState = {
  latestChannelFileLocation: {},
  latestFileManagerLocation: null,
  latestFileBrowserLocation: null,
  chatScrollPosition: {},
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case CHANNEL_FILE_LOCATION_CHANGED: {
      return {
        ...state,
        latestChannelFileLocation: {
          ...state.latestChannelFileLocation,
          [action.channelId]: action.location,
        },
      };
    }
    case FILE_MANAGER_LOCATION_CHANGE: {
      return {
        ...state,
        latestFileManagerLocation: action.location,
      };
    }
    case FILE_BROWSER_LOCATION_CHANGE: {
      return {
        ...state,
        latestFileBrowserLocation: action.location,
      };
    }
    case CHAT_SCROLL_POSITION_CHANGED: {
      return {
        ...state,
        chatScrollPosition: {
          ...state.chatScrollPosition,
          [action.id]: action.position,
        },
      };
    }

    default:
      return state;
  }
}
