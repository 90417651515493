import PropTypes from 'prop-types';
import AppNavigation from 'Components/AppNavigation/AppNavigation';

const WorkspaceSidebarLayout = ({ children }) => (
  <div className="flex flex-row flex-auto min-height-0 position-relative">
    <AppNavigation />
    {children}
  </div>
);

WorkspaceSidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WorkspaceSidebarLayout;
