import isEqual from 'lodash/isEqual';
import { postNotifications } from 'DataLayer/Notifications/post';

import { getStats, getHasNewerMessages } from './selectors';
import { chatMessageReadAnalyticsEvent } from 'Components/GoogleAnalytics/events';

export const ADD_NOTIFICATION = 'NOTIFICATIONS/ADD_SINGLE';
export const ADD_TEMPORARY_NOTIFICATION = 'NOTIFICATIONS/ADD_TEMPORARY';
export const ADD_NOTIFICATIONS = 'NOTIFICATIONS/ADD_MANY';
export const CLEAR_TEMPORARY_NOTIFICATIONS = 'NOTIFICATIONS/CLEAR_TEMPORARY';
export const CLEAR_TEMPORARY_NOTIFICATION = 'NOTIFICATION/CLEAR_TEMPORARY';
export const SET_STATS = 'SET_STATS';
export const REMOVE_NOTIFICATION = 'NOTIFICATIONS/REMOVE_NOTIFICATION';
export const REMOVE_NOTIFICATIONS = 'NOTIFICATIONS/REMOVE_NOTIFICATIONS';

export const READ_STATE_CHANGED = 'NOTIFICATIONS/READ_STATE_CHANGED';

export const UPDATE_MESSAGE = 'NOTIFICATIONS/UPDATE_MESSAGE';
export const NOTIFICATION_STAR_ADDED = 'NOTIFICATIONS/NOTIFICATION_STAR_ADDED';

const reduceNotifications = (notifications, spaceId) =>
  notifications.reduce((object, value) => {
    if (value.Workspace && value.Workspace.Id === spaceId) {
      object[value.Id] = value;
    }
    return object;
  }, {});

export function addNotifications(
  spaceId,
  notifications,
  hasOlderMessages,
  hasNewerMessages,
  hasHiddenMessages,
  overwriteNotifications,
  lastRead
) {
  return {
    type: ADD_NOTIFICATIONS,
    spaceId,
    notifications: reduceNotifications(notifications, spaceId),
    hasOlderMessages,
    hasNewerMessages,
    hasHiddenMessages,
    overwriteNotifications,
    lastRead,
  };
}

/*
  If there is a future page token, an archived set of results is being displayed
  This means we do not want to add this new notification to the store, as it would
  show at the end of the archived timeline, confusing the user.
  Only add new notifications to the store if the user is viewing the latest timeline
*/
export const addNotification = notification => (dispatch, getState) => {
  const state = getState();
  if (!notification.Workspace) {
    return;
  }
  const hasNewerMessages = getHasNewerMessages(
    state,
    notification.Workspace.Id
  );

  if (hasNewerMessages) {
    return;
  }
  dispatch({
    type: ADD_NOTIFICATION,
    notification,
  });
};

export function addTemporaryNotification(notification) {
  return {
    type: ADD_TEMPORARY_NOTIFICATION,
    notification,
  };
}

export function updateNotificationMessage(id, message) {
  return {
    type: UPDATE_MESSAGE,
    id,
    message,
  };
}

export function notificationStarAdded(id, starred) {
  return {
    type: NOTIFICATION_STAR_ADDED,
    id,
    starred,
  };
}
/*
export function clearTemporaryNotifications(spaceId, notificationsToCompare) {
  return {
    type: CLEAR_TEMPORARY_NOTIFICATIONS,
    spaceId,
    notificationsToCompare,
  };
}

export function clearTemporaryNotification(commentId) {
  return {
    type: CLEAR_TEMPORARY_NOTIFICATION,
    commentId,
  };
}
*/
/*
export function removeNotificationsBySpaceId(spaceId) {
  return {
    type: REMOVE_NOTIFICATIONS,
    spaceId,
  };
}
*/

export function removeNotificationById(id, notification) {
  return {
    type: REMOVE_NOTIFICATION,
    id,
    notification,
  };
}

export function removeNotificationBySpaceId(spaceId) {
  return {
    type: REMOVE_NOTIFICATIONS,
    spaceId,
  };
}

export function readStateChanged(spaceId, email, notificationId, timestamp) {
  return {
    type: READ_STATE_CHANGED,
    spaceId,
    email,
    notificationId,
    timestamp,
  };
}

export const setStats = stats => (dispatch, getState) => {
  const state = getState();
  const currentStats = getStats(state);
  if (!isEqual(stats, currentStats)) {
    dispatch({
      type: SET_STATS,
      stats,
    });
  }
};

export const markAsReadBySpaceId = spaceId => dispatch => {
  chatMessageReadAnalyticsEvent();
  return postNotifications(spaceId);
};
