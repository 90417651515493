import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getWorkspaces } from 'store/Workspaces/selectors';
import { Select } from 'semantic-ui-react';
import WorkspaceIcon from './WorkspaceIcon';
import PrivateChatIcon from 'Components/Icons/PrivateChatIcon';
import { isYourContactsEnabled } from 'config/features';

export const PRIVATE_CHAT = 'PRIVATE_CHAT';
class WorkspacesSelect extends React.Component {
  render() {
    const {
      workspaces,
      selectedWorkspaceId,
      onWorkspaceSelected,
      ...rest
    } = this.props;
    const options = isYourContactsEnabled()
      ? workspaces.concat([
          {
            Id: PRIVATE_CHAT,
            Name: 'Private Chats',
          },
        ])
      : workspaces;
    return (
      <Select
        selectOnBlur={false}
        search
        name="channel"
        fluid
        onChange={(event, data) => {
          onWorkspaceSelected(data.value);
        }}
        options={options.map(workspace => ({
          key: workspace.Id,
          text: workspace.Name,
          value: workspace.Id,
          content: (
            <div className="flex align-items-center">
              <span className="mr-3">
                {workspace.Id !== PRIVATE_CHAT ? (
                  <WorkspaceIcon size={30} workspace={workspace} />
                ) : (
                  <PrivateChatIcon width={30} height={30} />
                )}
              </span>
              {workspace.Name}
            </div>
          ),
        }))}
        value={selectedWorkspaceId}
        {...rest}
      />
    );
  }
}
WorkspacesSelect.propTypes = {
  workspaces: PropTypes.array.isRequired,
  onWorkspaceSelected: PropTypes.func.isRequired,
  selectedWorkspaceId: PropTypes.string,
};

export default connect(state => ({
  workspaces: getWorkspaces(state),
}))(WorkspacesSelect);
