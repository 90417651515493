import { getChannelById } from 'DataLayer/Spaces/get';

import * as spaceActions from './actions';

const loadSpace = id => async dispatch => {
  try {
    const space = await getChannelById(id);
    dispatch(spaceActions.addSpace(space));
    return space;
  } catch (ex) {
    throw ex;
  }
};

export default loadSpace;
