import PropTypes from 'prop-types';
import React from 'react';
import ParticipantAvatar from 'Components/Meeting/ParticipantAvatar';

import styles from './ParticipantAvatars.module.css';
import { FormattedHTMLMessage } from 'react-intl';

function ParticipantAvatars({ meeting }) {
  return (
    <div className={styles.onTheCall}>
      <span className="flex-no-shrink">
        <FormattedHTMLMessage id="MeetingInvitation.OnTheCall" />
      </span>
      <div className={styles.participants}>
        {meeting.participants?.map(participant => (
          <ParticipantAvatar
            key={participant.userId}
            collaborator={participant}
          />
        ))}
      </div>
    </div>
  );
}

ParticipantAvatars.propTypes = {
  meeting: PropTypes.shape({
    participants: PropTypes.array.isRequired,
  }).isRequired,
};

export default ParticipantAvatars;
