import * as Sentry from '@sentry/react';
import { removeByResourceId } from 'DataLayer/database/linkedItems';
import { DELETE_LINKEDITEM_SUCCESS } from 'store/LinkedItems/actions';

export const indexedDbLinkedItemsMiddleware = store => next => action => {
  try {
    switch (action.type) {
      case DELETE_LINKEDITEM_SUCCESS: {
        action.ids.forEach(id => removeByResourceId(id));
        break;
      }
      default:
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }

  return next(action);
};
