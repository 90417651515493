import PropTypes from 'prop-types';
import React from 'react';

const DownloadIframe = ({ src }) => <iframe src={src} title="downloads" />;

DownloadIframe.propTypes = {
  src: PropTypes.string.isRequired,
};

export default DownloadIframe;
