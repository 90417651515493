import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import UserAvatarMenu from 'Components/User/UserAvatarMenu';

import './SidebarLayoutHeader.scss';
import ActivityLogButton from './ActivityLogButton';
import AllRemindersButton from 'Components/Reminders/AllRemindersButton';
import AllStarredMessagesButton from 'Components/StarredMessages/AllStarredMessagesButton';
import HelpDropdown from './HelpDropdown';
import SectionHeader from 'Components/Header/SectionHeader';
import AllNotesIcon from 'Components/Notes/AllNotesIcon';

const AllNotesButton = React.lazy(() =>
  import(
    /* webpackChunkName: "AllNotesButton" */ 'Components/Notes/AllNotesButton'
  )
);

const SidebarLayoutHeader = ({ left, center, right, onTourSelected }) => (
  <SectionHeader>
    {left}
    {center}
    <div className="sidebar-layout-header-right">
      {right}
      <ActivityLogButton />
      <AllStarredMessagesButton />
      <Suspense fallback={<AllNotesIcon />}>
        <AllNotesButton />
      </Suspense>
      <AllRemindersButton />
      <HelpDropdown onTourSelected={onTourSelected} />
      <UserAvatarMenu hideName pointing="top right" />
    </div>
  </SectionHeader>
);

SidebarLayoutHeader.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
  onTourSelected: PropTypes.func,
};

export default SidebarLayoutHeader;
