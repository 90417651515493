import * as React from 'react';

function ShareDesktop(props) {
  return (
    <svg
      data-testid="share-desktop-icon"
      width={23}
      height={23}
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M28 22.688v-16H4v16h24zM28 4c1.438 0 2.688 1.25 2.688 2.688l-.063 16A2.64 2.64 0 0128 25.313h-6.688v2.688H10.687v-2.688H3.999c-1.438 0-2.688-1.188-2.688-2.625v-16C1.311 5.25 2.561 4 3.999 4h24z" />
    </svg>
  );
}

export default ShareDesktop;
