import { PENDING_FILES_SELECTED } from './addPendingFiles';
import {
  SET_UPLOAD_STATUS,
  CANCEL_ALL_PENDING,
  CLEAR_COMPLETED,
  SET_RESIZE as RESIZE_CHANGED,
} from './uploadSaga';

import { UPLOAD_FILES } from './uploadPendingFiles';
import UPLOAD_STATUS from './uploadStatus';
import { CANCEL_ACTIVE_UPLOAD, CANCEL_PENDING_UPLOAD } from './actions';

export function resizeChanged(id, resize) {
  return {
    type: RESIZE_CHANGED,
    id,
    resize,
  };
}

function setResize(state, id, resize) {
  const file = Object.assign({}, state.pendingFiles[id], { resize });
  const files = Object.assign({}, state.pendingFiles, {
    [file.id]: file,
  });
  return Object.assign({}, state, {
    pendingFiles: files,
  });
}

function setStatus(state, id, status) {
  const file = Object.assign({}, state.uploadingFiles[id], { status });
  const files = Object.assign({}, state.uploadingFiles, {
    [file.id]: file,
  });
  return Object.assign({}, state, {
    uploadingFiles: files,
  });
}

function addPending(state, fileIds, files) {
  return Object.assign({}, state, {
    pendingIds: fileIds,
    pendingFiles: files,
  });
}

function cancelAllPending(state) {
  let files = {};
  let file;
  state.uploadingIds.forEach(id => {
    if (state.uploadingFiles[id].status !== UPLOAD_STATUS.COMPLETE) {
      file = Object.assign({}, state.uploadingFiles[id], {
        status: UPLOAD_STATUS.FAILED,
      });
    } else {
      file = state.uploadingFiles[id];
    }
    files = Object.assign({}, files, { [id]: file });
  });
  return Object.assign({}, state, {
    uploadingFiles: files,
  });
}

function addUploading(state, files, fileIds) {
  return Object.assign({}, state, {
    uploadingFiles: { ...state.uploadingFiles, ...files },
    uploadingIds: state.uploadingIds.concat(fileIds),
    pendingIds: [],
    pendingFiles: {},
  });
}

function clearCompleted(state) {
  const nonCompleted = state.uploadingIds.filter(
    id => state.uploadingFiles[id].status !== UPLOAD_STATUS.COMPLETE
  );
  return {
    ...state,
    uploadingIds: nonCompleted,
  };
}

const initialState = {
  pendingIds: [],
  pendingFiles: {},
  uploadingIds: [],
  uploadingFiles: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PENDING_FILES_SELECTED:
      return addPending(state, action.fileIds, action.files);
    case UPLOAD_FILES:
      return addUploading(state, action.files, action.fileIds);
    case SET_UPLOAD_STATUS:
      return setStatus(state, action.id, action.status);
    case RESIZE_CHANGED:
      return setResize(state, action.id, action.resize);
    case CANCEL_ALL_PENDING:
      return cancelAllPending(state);
    case CANCEL_ACTIVE_UPLOAD:
    case CANCEL_PENDING_UPLOAD:
      return setStatus(state, action.id, UPLOAD_STATUS.CANCELLED);
    case CLEAR_COMPLETED:
      return clearCompleted(state);
    default:
      return state;
  }
}
