import axios from 'axios';
import { SHARE } from 'DataLayer/endpoints';
import process from './process';

export default function(id, password, days) {
  return axios
    .put(
      `${SHARE}?ResourceId=${id}&password=${encodeURIComponent(
        password
      )}&days=${days}`
    )
    .then(response => response.data.Share)
    .then(share => process(share));
}
