function single(template) {
  //const nonOtixoSteps = template.steps.filter(step => step.slug.toLowerCase() !== 'otixo');
  //const nonOtixoSlugs = nonOtixoSteps.map(nonOtixoStep => nonOtixoStep.slug).join('#');
  return {
    ...template,
    // Application: nonOtixoStep.slug,
    //SlugForTemplates: `#${nonOtixoSlugs}`,
  };
}

function process(templates) {
  const templateItems = [...templates.templatesList].map(template =>
    single(template)
  );
  return templateItems;
}

export default process;
