import * as Sentry from '@sentry/react';

export function getWeTeamPreLaunch() {
  if (localStorage && localStorage.WeTeamPreLaunch) {
    try {
      return JSON.parse(localStorage.WeTeamPreLaunch);
    } catch (exception) {
      Sentry.captureException(exception);
      return false;
    }
  }
  return false;
}

export function setWeTeamPreLaunch(value) {
  if (localStorage) {
    try {
      localStorage.WeTeamPreLaunch = value;
    } catch (exception) {
      Sentry.captureException(exception);
    }
  }
}

export function getBandwidthInformationDontAskAgain() {
  if (localStorage && localStorage.BandwidthInformationDontAskAgain) {
    try {
      return JSON.parse(localStorage.BandwidthInformationDontAskAgain);
    } catch (exception) {
      Sentry.captureException(exception);
      return false;
    }
  }
  return false;
}

export function setBandwidthInformationDontAskAgain(value) {
  if (localStorage) {
    try {
      localStorage.BandwidthInformationDontAskAgain = value;
    } catch (exception) {
      Sentry.captureException(exception);
    }
  }
}

export function getShareFolderInformationDontAskAgain() {
  if (localStorage && localStorage.ShareFolderInformationDontAskAgain) {
    try {
      return JSON.parse(localStorage.ShareFolderInformationDontAskAgain);
    } catch (exception) {
      Sentry.captureException(exception);
      return false;
    }
  }
  return false;
}

export function setShareFolderInformationDontAskAgain(value) {
  if (localStorage) {
    try {
      localStorage.ShareFolderInformationDontAskAgain = value;
    } catch (exception) {
      Sentry.captureException(exception);
    }
  }
}

export function getToken() {
  if (localStorage && localStorage.token) {
    try {
      return localStorage.token;
    } catch (exception) {
      Sentry.captureException(exception);
      return '';
    }
  }
  return '';
}

export function setToken(token) {
  if (window.localStorage) {
    try {
      window.localStorage.setItem('token', token);
    } catch (exception) {
      Sentry.captureException(exception);
    }
  }
}

export function getTokenWithoutBearer() {
  try {
    return getToken().split(' ')[1] || '';
  } catch (exception) {
    return '';
  }
}

export function clear() {
  if (localStorage) {
    try {
      localStorage.clear();
    } catch (exception) {
      Sentry.captureException(exception);
    }
  }
}
