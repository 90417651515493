import { MEETING_STATUS } from '.';
import { createSelector } from 'reselect';
import {
  getMaximumMeetingParticipants,
  getSpaces,
} from 'store/Spaces/selectors';
import { getUserGuid } from 'store/User/selectors';

export const MEETING_BEACON_TYPES = {
  ACTIVE: 'ACTIVE',
  JOINED: 'JOINED',
};

export const getIsMeetingStarting = state =>
  state.meeting.status === MEETING_STATUS.STARTING;

export const getMeetingStatus = state => state.meeting.status;

export const getIsMeetingJoined = state =>
  state.meeting.status === MEETING_STATUS.JOINED;

export const getIsMeetingHangUpRequested = state =>
  state.meeting.status === MEETING_STATUS.HANG_UP_REQUESTED;

export const getIsMeetingActive = state =>
  state.meeting.status === MEETING_STATUS.JOINED ||
  state.meeting.status === MEETING_STATUS.STARTING ||
  state.meeting.status === MEETING_STATUS.HANG_UP_REQUESTED;

export const getActiveMeetingRoomId = state => state.meeting.roomId;

export const isMeetingJoined = (state, roomId) =>
  state.meeting.status === MEETING_STATUS.JOINED &&
  state.meeting.roomId === roomId;

export const getActiveMeetingsObject = state => state.meeting.activeMeetings;

export const getMeetingByRoomId = (state, roomId) =>
  state.meeting.activeMeetings[roomId];

export const getJoinedMeetingRoomId = state => {
  if (state.meeting.status === MEETING_STATUS.JOINED) {
    return state.meeting.roomId;
  }
  return undefined;
};

export const isMeetingAudioMuted = state => state.meeting.isAudioMuted;

export const isMeetingVideoMuted = state => state.meeting.isVideoMuted;

export const isMeetingScreenShared = state => state.meeting.isScreenShared;

export const getMeetingServerUrl = state => state.meeting.meetingServerUrl;

export const makeGetMeetingBeaconByChannelId = () =>
  createSelector(
    [
      getActiveMeetingsObject,
      getJoinedMeetingRoomId,
      (_, channelId) => channelId,
      getUserGuid,
    ],
    (activeMeetings, joinedMeetingRoomId, channelId, currentUserGuid) => {
      /*
        Check if the channelId appears in the any active meeting
      */
      const channelHasMeeting = Object.values(activeMeetings).some(meeting =>
        meeting.users?.some(user => user.channelId === channelId)
      );

      if (!channelHasMeeting) {
        return null;
      }

      /**
       * Check if the participant has joined the meeting
       * channel meetings should only show green if the current user has joined
       */
      const participantHasJoined = Object.values(activeMeetings)
        .filter(meeting => !isChannelMeeting(meeting))
        .some(meeting => {
          /**
           * get corresponding userId of this channel and see if the userId is in the participant list i.e. they have joined the meeting
           * */
          const userId = meeting.users?.find(
            user => user.channelId === channelId
          )?.userId;

          /**
           * if we found the current user, this is the channel they share with the caller,
           * so we should instead check if the caller is in the participant list
           */
          if (userId === currentUserGuid) {
            return meeting.participantUserIds?.includes(meeting.callerUserId);
          }

          return meeting.participantUserIds?.includes(userId);
        });

      if (participantHasJoined) {
        return MEETING_BEACON_TYPES.JOINED;
      }

      /*
        Check if the channelId appears in the running meeting
      */
      if (isChannelMeeting(activeMeetings[joinedMeetingRoomId])) {
        if (
          activeMeetings[joinedMeetingRoomId]?.users?.some(
            user => user.channelId === channelId
          )
        ) {
          return MEETING_BEACON_TYPES.JOINED;
        }
      }

      return MEETING_BEACON_TYPES.ACTIVE;
    }
  );

export const makeGetMeetingBeaconByWorkspaceId = () =>
  createSelector(
    [
      getActiveMeetingsObject,
      getJoinedMeetingRoomId,
      (_, workspaceId) => workspaceId,
    ],
    (activeMeetings, joinedMeetingRoomId, workspaceId) => {
      if (activeMeetings[joinedMeetingRoomId]?.workspaceId === workspaceId) {
        return MEETING_BEACON_TYPES.JOINED;
      }
      if (
        Object.values(activeMeetings).some(
          meeting => meeting.workspaceId === workspaceId
        )
      ) {
        return MEETING_BEACON_TYPES.ACTIVE;
      }
      return null;
    }
  );

export const getActiveMeetings = createSelector(
  [getActiveMeetingsObject],
  activeMeetings =>
    Object.values(activeMeetings).map(meeting => ({
      ...meeting,
      caller: meeting.users?.find(
        participant => participant.userId === meeting.callerUserId
      ),
      participants: meeting.participantUserIds
        ?.map(participantId =>
          meeting.users?.find(user => user.userId === participantId)
        )
        //remove participants that were not found in the users array
        .filter(participant => participant),
    }))
);

export const getNumberOfActiveMeetings = createSelector(
  [getActiveMeetings],
  meetings => meetings.length
);

export const getActiveMeetingInvitations = createSelector(
  [getActiveMeetings],
  activeMeetings => {
    return activeMeetings.filter(
      activeMeeting => activeMeeting.state === MEETING_STATUS.INVITED
    );
  }
);

export const makeIsCurrentUserAParticipantInMeeting = () =>
  createSelector(
    [getActiveMeetings, getUserGuid, (_, meetingId) => meetingId],
    (activeMeetings, userGuid, meetingId) => {
      const meeting = activeMeetings.find(
        activeMeeting => activeMeeting.roomId === meetingId
      );
      return meeting?.participantUserIds.includes(userGuid);
    }
  );

export const getMeeting = createSelector(
  [getActiveMeetings, (_, roomId) => roomId],
  (activeMeetings, roomId) => {
    return activeMeetings.find(
      activeMeeting => activeMeeting.roomId === roomId
    );
  }
);

export const getNumberOfPaticipantsByRoomId = createSelector(
  [getActiveMeetingsObject, (_, roomId) => roomId],
  (activeMeetings, roomId) =>
    activeMeetings[roomId]?.participantUserIds?.length ?? 0
);

export const isMeetingRestrictedByRoomId = createSelector(
  [getNumberOfPaticipantsByRoomId, getMaximumMeetingParticipants],
  (numberOfMeetingParticipants, maximumMeetingParticipants) => {
    //TODO: Move maximumMeetingParticipants value to Workspace
    return numberOfMeetingParticipants >= maximumMeetingParticipants;
  }
);

export const isRemoteDesktopEnabledByRoomId = createSelector(
  [getMeetingByRoomId, getSpaces],
  (meeting, channels) => {
    try {
      // the caller will not have a channelId, so find the first meeting user which has a channelId.
      const channelId = meeting.users.filter(user => user.channelId)[0]
        .channelId;
      const channel = channels.find(channel => channel.Id === channelId);
      return channel?.ACL?.ShareDesktop;
    } catch (ex) {
      return false;
    }
  }
);

export const isInviteEnabledByRoomId = createSelector(
  [getMeetingByRoomId, getSpaces],
  (meeting, channels) => {
    try {
      // the caller will not have a channelId, so find the first meeting user which has a channelId.
      const channelId = meeting.users.filter(user => user.channelId)[0]
        .channelId;
      const channel = channels.find(channel => channel.Id === channelId);
      return channel?.ACL?.JitsiMeetingInvite;
    } catch (ex) {
      return false;
    }
  }
);

const isChannelMeeting = meeting => {
  try {
    /* 
    A member meeting will have multiple channelIds.
    Get unique channelIds. 
    */
    const channels = new Set(meeting.users.map(user => user.channelId));
    return channels.size === 1;
  } catch (ex) {
    return false;
  }
};

export const getIsChannelMeetingByRoomId = createSelector(
  [getMeetingByRoomId],
  isChannelMeeting
);

export const getActiveMeeting = createSelector(
  [getActiveMeetingsObject, getActiveMeetingRoomId],
  (activeMeetings, activeMeetingRoomId) => activeMeetings[activeMeetingRoomId]
);

export const getIsActiveMeetingAChannelMeeting = createSelector(
  [getActiveMeeting],
  isChannelMeeting
);

export const getActiveMeetingChannelId = createSelector(
  [getActiveMeeting],
  activeMeeting => {
    return activeMeeting.users.filter(user => user.channelId)[0].channelId;
  }
);
