// import { rest } from 'msw';
// import { getOtixoBackendApiUrl } from 'config/config';
// import {
//   getDeleteTagUrl,
//   getPutTagUrl,
//   getResourceTagsByChannelIdUrl,
//   getTagsByChannelIdPutUrl,
//   getTagsByChannelIdUrl,
// } from 'DataLayer/endpoints';

export const handlers = [
  // rest.put(
  //   `${getOtixoBackendApiUrl()}${getTagsByChannelIdPutUrl('*', '*')}`,
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(200),
  //       ctx.json([
  //         { id: 1, name: '001', color: '#ff2f7d' },
  //         { id: 2, name: '002', color: '#ffc700' },
  //         { id: 4, name: '004', color: '#5fdef4' },
  //         { id: 5, name: '005', color: '#00a1b2' },
  //       ])
  //     );
  //   }
  // ),
  // rest.post(
  //   `${getOtixoBackendApiUrl()}${getTagsByChannelIdUrl('*')}`,
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(201),
  //       ctx.json({ id: 6, name: '006', color: '#00a1b2' })
  //     );
  //   }
  // ),
  // rest.get(
  //   `${getOtixoBackendApiUrl()}${getResourceTagsByChannelIdUrl('*')}`,
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(200),
  //       ctx.json({
  //         '6bdb5b7b-abcc-426b-af4c-a6600ddb2c36': [
  //           { id: 1, name: '001', color: '#ff2f7d' },
  //           { id: 2, name: '002', color: '#ffc700' },
  //           { id: 3, name: '003', color: '#00465c' },
  //         ],
  //         '42262be4-0ab6-4093-a325-fe9e0fc33460': [
  //           { id: 1, name: '001', color: '#ff2f7d' },
  //           { id: 4, name: '004', color: '#5fdef4' },
  //           { id: 5, name: '005', color: '#00a1b2' },
  //         ],
  //       })
  //     );
  //   }
  // ),
  // rest.get(
  //   `${getOtixoBackendApiUrl()}${getTagsByChannelIdUrl('*')}`,
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(200),
  //       ctx.json([
  //         { id: 1, name: '001', color: '#ff2f7d' },
  //         { id: 2, name: '002', color: '#ffc700' },
  //         { id: 3, name: '003', color: '#00465c' },
  //         { id: 4, name: '004', color: '#5fdef4' },
  //         { id: 5, name: '005', color: '#00a1b2' },
  //       ])
  //     );
  //   }
  // ),
  // rest.put(
  //   `${getOtixoBackendApiUrl()}${getPutTagUrl('*', '*')}`,
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.delay(300),
  //       ctx.status(200),
  //       ctx.json([
  //         { id: 1, name: '001', color: '#ff2f7d' },
  //         { id: 2, name: '002', color: '#ff2f7d' },
  //         { id: 3, name: '003', color: '#ff2f7d' },
  //         { id: 4, name: '004', color: '#ff2f7d' },
  //         { id: 5, name: '005', color: '#ff2f7d' },
  //       ])
  //     );
  //   }
  // ),
  // rest.delete(
  //   `${getOtixoBackendApiUrl()}${getDeleteTagUrl('*', '*')}`,
  //   (req, res, ctx) => {
  //     return res(ctx.delay(300), ctx.status(200), ctx.json());
  //   }
  // ),
];
