import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(repository) {
  try {
    await commonDb.repositories.put({
      repositoryId: repository.Id,
      repository,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function clearRemovedRepositories(networkRepositories) {
  const databaseRepositories = await get();

  if (!databaseRepositories) {
    return null;
  }

  const networkRepositoryIds = networkRepositories.map(
    repository => repository.Id
  );

  // check db repository is inside response Repositories
  for (const databaseRepository of databaseRepositories) {
    if (!networkRepositoryIds.includes(databaseRepository.Id)) {
      await remove(databaseRepository.Id);
    }
  }
}

export async function saveMany(networkRepositories) {
  try {
    await clearRemovedRepositories(networkRepositories);

    await commonDb.repositories.bulkPut(
      networkRepositories.map(repository => ({
        repositoryId: repository.Id,
        repository,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.repositories.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function get() {
  try {
    const repositorys = await commonDb.repositories.toArray();
    if (Array.isArray(repositorys) && repositorys.length > 0) {
      return repositorys.map(repository => repository.repository);
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
