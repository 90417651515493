import axios from 'axios';
import {
  ME,
  getMeEmailsPutUrl,
  getMeEmailsDeleteUrl,
  getMeEmailsPostUrl,
} from 'DataLayer/endpoints';
import process from './process';
import { getOtixoBackendApiUrl } from 'config/config';

const getConfig = () => {
  const config = {};
  config.baseURL = getOtixoBackendApiUrl();
  return config;
};

export function update(user) {
  return axios
    .put(ME, user, getConfig())
    .then(response => process(response.data.Me));
}

export function updateAvatar(avatarUrl, color) {
  return axios
    .put(ME, {
      AvatarUrl: avatarUrl,
      color,
    })
    .then(response => response.data.Me)
    .then(user => process(user));
}

function updatePostBilling(activated) {
  return axios
    .put(
      ME,
      {
        postBilling: activated,
      },
      getConfig()
    )
    .then(response => response.data.Me)
    .then(user => process(user));
}

function updateLanguage(language) {
  return axios
    .put(
      ME,
      {
        language,
      },
      getConfig()
    )
    .then(response => response.data.Me)
    .then(user => process(user));
}

function addUserEmail(email) {
  return axios.put(getMeEmailsPutUrl(email));
}

function deleteUserEmail(userEmail) {
  return axios.delete(getMeEmailsDeleteUrl(userEmail));
}

function confirmUserEmail(userEmail) {
  return axios.post(getMeEmailsPostUrl(userEmail));
}

export default {
  updatePostBilling,
  updateLanguage,
  addUserEmail,
  deleteUserEmail,
  confirmUserEmail,
};
