import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Form, Select } from 'semantic-ui-react';

import { getSpaceLink } from 'pages';

import { getMembers } from 'DataLayer/Members/get';

import Avatar from 'Components/Avatar/Avatar';

import addPrivateChat from 'store/Conversations/addPrivateChat';

import {
  requestReducer,
  initialState,
  actionTypes,
} from 'Hooks/requestReducer';
import DismissableMessage from 'Components/DismissableMessage/DismissableMessage';
import { getUserId } from '../../../store/User/selectors';

import './PrivateChatInviteField.scss';

export function PrivateChatInviteField({
  userEmail,
  onAddPrivateChat,
  router,
  intl,
}) {
  const [{ loading, error }, dispatch] = useReducer(
    requestReducer,
    initialState
  );

  const [email, setEmail] = useState('');
  const [members, setMembers] = useState([]);

  useEffect(
    () => {
      getMembers().then(members => {
        setMembers(members.filter(member => member.Email !== userEmail));
      });
    },
    [userEmail]
  );

  const onSendInvite = async () => {
    try {
      dispatch({ type: actionTypes.REQUESTING });
      const conversation = await onAddPrivateChat(email);
      router.push(getSpaceLink(conversation.Id));
      dispatch({ type: actionTypes.SUCCESS });
      setEmail('');
    } catch {
      dispatch({ type: actionTypes.ERROR });
    } finally {
    }
  };

  const options = members.map(member => ({
    key: member.Email,
    text: member.Name,
    value: member.Email,
    content: (
      <div className="flex align-items-center">
        <Avatar
          initials={member.Name.substring(0, 1)}
          src={member.AvatarUrl}
          color={member.Color}
          circular
          width={30}
          height={30}
        />
        <div className="ml-2">
          {member.Name || member.Email}
          <br />
          <small className="light-text">{member.Email}</small>
        </div>
      </div>
    ),
  }));

  return (
    <>
      <Form onSubmit={onSendInvite} className="pb-2">
        <Form.Field>
          <Select
            data-testid="member-select"
            selectOnBlur={false}
            openOnFocus={false}
            closeOnBlur
            closeOnChange
            allowAdditions
            additionLabel="Invite "
            onAddItem={(event, data) => {
              setMembers([
                ...members,
                {
                  Email: data.value,
                  Name: data.value,
                },
              ]);
            }}
            search={(options, value) => {
              return options.filter(
                option =>
                  option.value.toLowerCase().includes(value) ||
                  option.text.toLowerCase().includes(value)
              );
            }}
            placeholder={intl.formatMessage({
              id: 'PrivateChatInviteField.type-an-email-or-select-a-name',
            })}
            noResultsMessage={null}
            name="members"
            fluid
            onChange={(event, data) => {
              setEmail(data.value);
            }}
            options={options}
            value={email}
          />
        </Form.Field>
        {email.length > 0 && (
          <Button
            data-testid="send-invitation-button"
            type="submit"
            fluid
            primary
            disabled={loading}
            loading={loading}
          >
            <FormattedMessage id="PrivateChatInviteField.send-invitation" />
          </Button>
        )}
        <DismissableMessage
          error
          visible={error}
          onClose={() => {
            dispatch({ type: actionTypes.RESET });
          }}
        >
          <FormattedMessage id="PrivateChatInviteField.there-was-a-error-sending-the-invitation-please-try-again" />
        </DismissableMessage>
      </Form>
    </>
  );
}

PrivateChatInviteField.propTypes = {
  userEmail: PropTypes.string.isRequired,
  onAddPrivateChat: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    userEmail: getUserId(state),
  }),
  {
    onAddPrivateChat: addPrivateChat,
  }
)(withRouter(injectIntl(PrivateChatInviteField)));
