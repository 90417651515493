import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Divider } from 'semantic-ui-react';

import COLLABORATOR_TYPE from 'Constants/collaboratorType';

import rejectMember from 'store/Workspaces/Members/rejectMember';
import approveMember from 'store/Workspaces/Members/approveMember';
import { captureException } from 'Helpers/sentry';

class PendingWorkspaceMemberMessage extends React.Component {
  state = {
    loading: false,
    open: true,
  };

  onReject = () => {
    const { dispatch, member, workspace } = this.props;
    this.setState({
      loading: true,
    });
    dispatch(rejectMember(workspace.Id, member.Id)).then(
      () => {
        this.props.onResolved();
      },
      error => {
        captureException(error);
        this.setState({
          loading: false,
        });
      }
    );
  };

  onApprove = () => {
    const { dispatch, member, workspace } = this.props;
    this.setState({
      loading: true,
    });
    dispatch(
      approveMember(workspace.Id, member.Id, member.InvitationMessage)
    ).then(
      () => {
        this.props.onResolved();
      },
      error => {
        captureException(error);
        this.setState({
          loading: false,
        });
      }
    );
  };

  render() {
    const { workspace, member } = this.props;

    return (
      <div>
        <FormattedMessage
          id={
            member.Type === COLLABORATOR_TYPE.REGULAR
              ? 'PendingWorkspaceMember.Member.Title'
              : 'PendingWorkspaceMember.Guest.Title'
          }
          values={{
            pendingCollaborator: <strong>{member.DisplayName}</strong>,
            member: <strong>{member.InvitedBy}</strong>,
            space: <strong>{workspace.Name}</strong>,
            break: <br />,
          }}
        />
        <Divider hidden />
        <div className="flex justify-content-space-between">
          <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            basic
            onClick={this.onReject}
          >
            <FormattedMessage id="PendingWorkspaceMember.RejectButton" />
          </Button>
          <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            primary
            onClick={this.onApprove}
          >
            <FormattedMessage id="PendingWorkspaceMember.ApproveButton" />
          </Button>
        </div>
      </div>
    );
  }
}

PendingWorkspaceMemberMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  workspace: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  onResolved: PropTypes.func,
};

PendingWorkspaceMemberMessage.defaultProps = {
  onResolved: () => {},
};

export default connect()(PendingWorkspaceMemberMessage);
