import { SET_PAGINATE_LINK } from './setPaginateLink';

function setPaginateLink(state, id, next) {
  return Object.assign({}, state, { [id]: next });
}

export default function(state = {}, action) {
  switch (action.type) {
    case SET_PAGINATE_LINK:
      return setPaginateLink(state, action.id, action.next);
    default:
      return state;
  }
}
