import { Component } from 'react';

import { initGoogleAnalytics } from 'Components/GoogleAnalytics/events';

class GoogleAnalytics extends Component {
  componentDidMount() {
    initGoogleAnalytics();
  }

  render() {
    return null;
  }
}

export default GoogleAnalytics;
