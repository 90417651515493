import { combineReducers } from 'redux';

import {
  WORKSPACES_LOADED,
  WORKSPACES_REQUESTED,
  WORKSPACES_ERROR,
  WORKSPACES_ADD,
  WORKSPACES_REMOVE,
  RECENTLY_ADDED_WORKSPACES_LOADED,
  WORKSPACES_REMOVE_ALL,
} from './actions';
import {
  WORKSPACE_MEMBERS_LOADED,
  WORKSPACE_MEMBER_ADD,
  WORKSPACE_MEMBERS_REMOVE,
  WORKSPACE_MEMBERS_MERGE,
} from './Members/actions';

const initialState = {
  workspaces: {},
  loading: true,
};

function membersReducer(state = {}, action) {
  switch (action.type) {
    case WORKSPACE_MEMBERS_LOADED: {
      const currentMembers = Object.values(state)
        .filter(member => member.WorkgroupId !== action.workspaceId)
        .reduce((object, member) => {
          object[member.Id] = member;
          return object;
        }, {});

      return {
        ...currentMembers,
        ...action.members,
      };
    }
    case WORKSPACE_MEMBERS_MERGE: {
      return {
        ...state,
        ...action.members,
      };
    }
    case WORKSPACE_MEMBER_ADD: {
      return {
        ...state,
        [action.member.Id]: action.member,
      };
    }
    case WORKSPACE_MEMBERS_REMOVE: {
      const members = { ...state };
      delete members[action.memberId];
      return members;
    }
    default:
      return state;
  }
}

function workspacesReducer(state = initialState, action) {
  switch (action.type) {
    case WORKSPACES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case WORKSPACES_LOADED:
      return {
        workspaces: action.workspaces,
        loading: false,
      };
    case WORKSPACES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case WORKSPACES_REMOVE: {
      const workspaces = Object.assign({}, state.workspaces);
      delete workspaces[action.workspaceId];
      return {
        ...state,
        workspaces,
      };
    }
    case WORKSPACES_REMOVE_ALL: {
      return {
        ...state,
        workspaces: {},
      };
    }
    case WORKSPACES_ADD: {
      return {
        ...state,
        workspaces: {
          ...state.workspaces,
          [action.workspace.Id]: action.workspace,
        },
      };
    }
    default:
      return state;
  }
}

const recentlyAddedWorkspacesInitialState = {
  invitationMessages: {},
  workspaceIds: [],
};

function recentlyAddedWorkspacesReducer(
  state = recentlyAddedWorkspacesInitialState,
  action
) {
  switch (action.type) {
    case RECENTLY_ADDED_WORKSPACES_LOADED:
      return {
        workspaceIds: action.workspaceIds,
        invitationMessages: action.invitationMessages,
      };
    default:
      return state;
  }
}

export default combineReducers({
  recentlyAddedWorkspaces: recentlyAddedWorkspacesReducer,
  workspaces: workspacesReducer,
  members: membersReducer,
});
