import { post } from 'DataLayer/Conversations/post';

import * as spaceActions from '../Spaces/actions';

const addPrivateChat = email => dispatch => {
  return post(email).then(space => {
    dispatch(spaceActions.addSpace(space));
    return space;
  });
};
export default addPrivateChat;
