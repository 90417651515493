import get from 'lodash/get';
import { push } from 'react-router-redux';
import { getLinkedItemsLink, getLinkedItemLink } from 'pages';
import { spaceSidebarPanelSelected, SIDEBAR_ITEMS } from 'store/UI/index';
import { FOLDER } from 'DataLayer/Resources/Helpers/contentTypes';

export const navigateToLinkedItem = file => dispatch => {
  const channelId = get(file, 'Workspace.Id');
  if (!channelId) {
    return;
  }
  dispatch(spaceSidebarPanelSelected(channelId, SIDEBAR_ITEMS.files, true));
  if (file.Resource.Type === FOLDER) {
    dispatch(push(getLinkedItemsLink(channelId, null, file.ResourceId)));
  } else if (file.IsChild) {
    dispatch(
      push(
        getLinkedItemsLink(
          channelId,
          null,
          file.Resource.ParentId,
          file.Resource.Id
        )
      )
    );
  } else {
    dispatch(push(getLinkedItemLink(channelId, file.ResourceId)));
  }
};
