import orderBy from 'lodash/orderBy';
import memoize from 'lodash/memoize';

const minutes = ['00', '10', '15', '20', '30', '40', '45', '50'];
const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const makeReminderOptionKey = (hour, minute) => `${hour}${minute}`;

const makeOption = (hour = '09', minute = '00') => {
  const key = makeReminderOptionKey(hour, minute);

  return {
    key: key,
    hour,
    minute,
    value: key,
    text: `${hour}:${minute}`,
  };
};

const getTimes = memoize(() => {
  return hours.reduce((times, hour) => {
    const allMinutesForHour = minutes.map(minute => makeOption(hour, minute));
    return times.concat(allMinutesForHour);
  }, []);
});

export default function getReminderOptions(hour, minute) {
  const times = getTimes();

  if (times.find(time => time.hour === hour && time.minute === minute)) {
    return times;
  }

  const timesWithCustomTime = times.concat(makeOption(hour, minute));
  return orderBy(
    timesWithCustomTime,
    ['isMorning', 'hour', 'minute'],
    ['desc']
  );
}
