import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function save(data) {
  try {
    await commonDb.webUiPreferences.put({
      id: 1, // no need for a real id here as we only store 1 record
      webUiPreferences: data,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.webUiPreferences.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function get() {
  try {
    const webUiPreferences = await commonDb.webUiPreferences.toArray();
    if (Array.isArray(webUiPreferences) && webUiPreferences.length > 0) {
      return webUiPreferences[0].webUiPreferences;
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
