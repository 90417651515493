import PropTypes from 'prop-types';
import React from 'react';
import TransferItem from 'Components/Transfers/TransferItem';

import './SpaceTask.scss';

const SpaceTask = ({ task }) => (
  <div className="space-task">
    <TransferItem task={task} hideDate canCancel={false} />
  </div>
);

SpaceTask.propTypes = {
  task: PropTypes.object.isRequired,
};

export default SpaceTask;
