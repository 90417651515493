import {
  ALL_NOTES_REACT_QUERY_KEY,
  NOTES_REACT_QUERY_KEY,
} from 'Components/Notes/NotesKeys';
import { fetchNotes } from 'DataLayer/Notes/get';
import { useQuery } from 'react-query';

export const useNotes = () =>
  useQuery([NOTES_REACT_QUERY_KEY, ALL_NOTES_REACT_QUERY_KEY], () =>
    fetchNotes()
  );

export const useNotesByChannelId = channelId =>
  useQuery(
    [NOTES_REACT_QUERY_KEY, channelId || ALL_NOTES_REACT_QUERY_KEY],
    () => fetchNotes(channelId)
  );
