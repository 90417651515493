import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import { PersistGate } from 'redux-persist/integration/react';
import routes from 'Router/Routes';

import { pageViewEvent } from 'Components/GoogleAnalytics/events';

const Root = ({ store, persistor, history }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router
        history={history}
        routes={routes(store)}
        onUpdate={pageViewEvent}
      />
    </PersistGate>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default Root;
