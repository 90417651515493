import { rest } from 'msw';
import {
  ADAPTERS,
  getAuthenticationPostUrl,
  getMembersByWorkspaceIdUrl,
  getTemplatesUrl,
  getUsersPostUrl,
  getWorkspaceNameSuggestionUrl,
  ME,
  MEETINGS,
  REPOSITORIES,
  RESOURCES,
  USERS_VERIFY,
  USERS_VERIFY_RESEND,
  USERS_WORKSPACE,
  WEB_UI_PREFERENCES,
  WORKSPACE,
  TASK,
  getWorkspaceUnseenUrl,
  getConversationUrl,
  getMyNotificationsStatsUrl,
  WORKSPACE_SUBSCRIPTION,
  EDUCATION,
  getMeDeleteRequestUrl,
  getWorkspaceOffersUrl,
} from 'DataLayer/endpoints';
import {
  firstWorkSpaceNameSuggestion,
  channels,
  unknownUser,
  testWorkspaceId,
  testWorkspaceMember,
  unverifiedUserNoCode,
  unverifiedUserNoFirstWorkSpace,
  user,
  verificationToken,
  workspaces,
  registerErrorUser,
  validVerificationCode,
  freeWorkspace,
  generalChannel,
  createFirstWorkSpaceBadName,
  integrationTemplates,
  cloudServiceAdapters,
  tasks,
  workspaceUnseen,
  conversations,
  stats,
  meetingServerUrl,
  createMeeting,
  roomId,
} from 'mocks/data';
import { getOtixoApiUrl, getOtixoBackendApiUrl } from 'config/config';
import { REGISTRATION_ACTION } from 'DataLayer/Authentication/post';

/*
  Any api calls that are triggered by our tests can be mocked here
  This ensures no api mocking is needed in our actual tests

  Add a ctx.delay(300) to give our UI time to update before the response is received. 
  Without this, there have been cases where the assertion has failed because the
  the request has finished so quickly, the UI state between request & response
  never appeared in the browser.
*/
export const handlers = [
  /*
    mock the call to our work space members endpoint and respond with a mocked work space member
  */
  rest.get(
    `${getOtixoApiUrl()}${getMembersByWorkspaceIdUrl(testWorkspaceId)}`,
    (req, res, ctx) => res(ctx.delay(300), ctx.json([testWorkspaceMember]))
  ),
  /*
     mock the call to our auth endpoint and return
     a different response for verified and unverified users
   */
  rest.post(
    `${getOtixoBackendApiUrl()}${getAuthenticationPostUrl()}`,
    (req, res, ctx) => {
      if (req.url.searchParams.get('user') === unverifiedUserNoCode.email) {
        return res(
          ctx.delay(300),
          ctx.status(200),
          ctx.json({
            token: verificationToken,
            action: REGISTRATION_ACTION.VERIFY_PIN,
          })
        );
      }
      if (
        req.url.searchParams.get('user') ===
        unverifiedUserNoFirstWorkSpace.email
      ) {
        return res(
          ctx.delay(300),
          ctx.status(200),
          ctx.json({
            token: verificationToken,
            action: REGISTRATION_ACTION.CREATE_WORK_SPACE,
          })
        );
      }
      if (req.url.searchParams.get('user') === unknownUser.email) {
        return res(ctx.status(500), ctx.text('Unknown user'));
      }
      return res(
        ctx.delay(300),
        ctx.status(201),
        ctx.json({
          Authentication: {
            AuthenticationIdentifier: 'id',
            AuthenticationToken: '123',
          },
        })
      );
    }
  ),
  rest.post(
    `${getOtixoBackendApiUrl()}${getAuthenticationPostUrl(verificationToken)}`,
    (req, res, ctx) => {
      return res(
        ctx.delay(300),
        ctx.status(201),
        ctx.json({
          Authentication: {
            AuthenticationIdentifier: 'id',
            AuthenticationToken: '123',
          },
        })
      );
    }
  ),
  rest.post(
    `${getOtixoBackendApiUrl()}${getUsersPostUrl()}`,
    (req, res, ctx) => {
      if (req.body.email === unknownUser.email) {
        return res(
          ctx.delay(300),
          ctx.status(200),
          ctx.json({
            token: verificationToken,
            action: REGISTRATION_ACTION.VERIFY_PIN,
          })
        );
      }
      if (req.body.email === registerErrorUser.email) {
        return res(ctx.delay(300), ctx.status(500));
      }
      return res(
        ctx.delay(300),
        ctx.status(201),
        ctx.json({
          Authentication: {
            AuthenticationIdentifier: 'id',
            AuthenticationToken: '123',
          },
        })
      );
    }
  ),
  rest.post(
    `${getOtixoBackendApiUrl()}${getUsersPostUrl(verificationToken)}`,
    (req, res, ctx) => {
      if (req.body.email === unknownUser.email) {
        return res(
          ctx.delay(300),
          ctx.status(200),
          ctx.json({
            token: verificationToken,
            action: REGISTRATION_ACTION.VERIFY_PIN,
          })
        );
      }
      return res(
        ctx.delay(300),
        ctx.status(201),
        ctx.json({
          Authentication: {
            AuthenticationIdentifier: 'id',
            AuthenticationToken: '123',
          },
        })
      );
    }
  ),
  rest.get(
    `${getOtixoBackendApiUrl()}${getWorkspaceNameSuggestionUrl(
      verificationToken
    )}`,
    (req, res, ctx) => {
      return res(
        ctx.delay(300),
        ctx.status(200),
        ctx.json({ name: firstWorkSpaceNameSuggestion })
      );
    }
  ),
  rest.post(`${getOtixoBackendApiUrl()}${USERS_VERIFY}`, (req, res, ctx) => {
    if (req.body.code === validVerificationCode) {
      return res(ctx.delay(300), ctx.status(200));
    }
    return res(ctx.delay(300), ctx.status(400));
  }),
  rest.post(
    `${getOtixoBackendApiUrl()}${USERS_VERIFY_RESEND}`,
    (req, res, ctx) => {
      return res(ctx.delay(300), ctx.status(200), ctx.text('OK'));
    }
  ),
  rest.get(`${getOtixoBackendApiUrl()}${ME}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(user));
  }),
  rest.get(`${getOtixoApiUrl()}${WEB_UI_PREFERENCES}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json({}));
  }),
  rest.get(`${getOtixoApiUrl()}${WORKSPACE}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(workspaces));
  }),
  rest.post(`${getOtixoBackendApiUrl()}${USERS_WORKSPACE}`, (req, res, ctx) => {
    if (req.body.name === createFirstWorkSpaceBadName) {
      return res(ctx.delay(300), ctx.status(400), ctx.text('ERROR'));
    }
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        Authentication: {
          AuthenticationIdentifier: 'Otixo',
          AuthenticationToken: 'fake-auth-token',
        },
      })
    );
  }),
  rest.get(
    `${getOtixoApiUrl()}${WORKSPACE}/${freeWorkspace.Id}/channels`,
    (req, res, ctx) => {
      return res(
        ctx.delay(300),
        ctx.status(200),
        ctx.json({ Spaces: channels })
      );
    }
  ),

  rest.post(`${getOtixoApiUrl()}${WORKSPACE}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(freeWorkspace));
  }),
  rest.post(
    `${getOtixoApiUrl()}${WORKSPACE}/${freeWorkspace.Id}/channels`,
    (req, res, ctx) => {
      return res(
        ctx.delay(300),
        ctx.status(200),
        ctx.json({ Spaces: [generalChannel] })
      );
    }
  ),
  rest.get(`${getOtixoApiUrl()}${RESOURCES}/children`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json({ Items: [] }));
  }),
  rest.get(`${getOtixoApiUrl()}${REPOSITORIES}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json({ Repositories: [] }));
  }),
  rest.get(`${getOtixoApiUrl()}${getTemplatesUrl()}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(integrationTemplates));
  }),
  rest.get(`${getOtixoApiUrl()}${ADAPTERS}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(cloudServiceAdapters));
  }),
  rest.get(`${getOtixoApiUrl()}${TASK}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(tasks));
  }),
  rest.get(`${getOtixoApiUrl()}${TASK}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(tasks));
  }),
  rest.get(`${getOtixoApiUrl()}${getWorkspaceUnseenUrl()}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(workspaceUnseen));
  }),
  rest.get(`${getOtixoApiUrl()}${getConversationUrl()}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(200), ctx.json(conversations));
  }),
  rest.get(
    `${getOtixoApiUrl()}${getMembersByWorkspaceIdUrl(freeWorkspace.Id)}`,
    (req, res, ctx) => {
      return res(
        ctx.delay(300),
        ctx.status(200),
        ctx.json([testWorkspaceMember])
      );
    }
  ),
  rest.get(
    `${getOtixoApiUrl()}${getMyNotificationsStatsUrl()}`,
    (req, res, ctx) => {
      return res(ctx.delay(300), ctx.status(200), ctx.json(stats));
    }
  ),
  rest.post(
    `${getOtixoApiUrl()}${WORKSPACE_SUBSCRIPTION}/${freeWorkspace.Id}`,
    (req, res, ctx) => {
      return res(ctx.delay(300), ctx.status(200), ctx.json(freeWorkspace));
    }
  ),
  rest.get(`${getOtixoBackendApiUrl()}${MEETINGS}`, (req, res, ctx) => {
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        activeMeetings: [],
        meetingInvitations: [],
        meetingServerUrl: meetingServerUrl,
      })
    );
  }),

  rest.post(`${getOtixoBackendApiUrl()}${MEETINGS}`, (req, res, ctx) => {
    const meeting = createMeeting(
      roomId,
      generalChannel.Id,
      'test-workspace-id'
    );
    return res(ctx.delay(300), ctx.status(201), ctx.json(meeting));
  }),

  rest.post(`${getOtixoBackendApiUrl()}${EDUCATION}`, (req, res, ctx) => {
    return res(ctx.delay(300), ctx.status(201));
  }),

  rest.post(
    `${getOtixoBackendApiUrl()}${getMeDeleteRequestUrl()}`,
    (req, res, ctx) => {
      return res(ctx.delay(300), ctx.status(201));
    }
  ),
  rest.get(
    `${getOtixoBackendApiUrl()}${getWorkspaceOffersUrl('*')}`,
    (req, res, ctx) => {
      return res(ctx.delay(300), ctx.status(200), ctx.json({ result: true }));
    }
  ),
];
