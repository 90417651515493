import { isOtixoEnvProduction } from 'config/config';

export const OFFERS = {
  download_desktop: 'download-desktop',
  add_ten_members: 'add-ten-members',
  feedback: 'feedback',
  spring_2024: 'spring-2024-free-trial',
  free_trial_aug_2023: '3-month-free-trial-aug-2023',
  trial_test: 'trial_test',
};

export const getSpring2024SubOffers = offer => [
  OFFERS.download_desktop,
  OFFERS.add_ten_members,
  OFFERS.feedback,
];
const NUMBER_OF_MILESTONES = {
  [OFFERS.spring_2024]: 4,
};

export const getNumberOfMilestones = offer => NUMBER_OF_MILESTONES[offer];

const OFFER_THRESHOLD = {
  [OFFERS.add_ten_members]: isOtixoEnvProduction() ? 10 : 1,
};

export const getOfferThreshold = offer => OFFER_THRESHOLD[offer];
