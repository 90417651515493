import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';

import { put } from 'DataLayer/Workspaces/Subscription/put';
import { get } from 'DataLayer/Workspaces/Subscription/get';

import RecurlyPaymentForm from 'Components/Recurly/RecurlyPaymentForm';
import { Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getUserCountryCode } from 'store/Application/selectors';

function RecurlyPaymentFormContainer({
  workspaceId,
  onComplete,
  onError,
  ...rest
}) {
  const countryCode = useSelector(getUserCountryCode);
  const { data, isLoading } = useQuery(
    ['subscriptions', workspaceId],
    () => get(workspaceId),
    { refetchOnWindowFocus: false, retry: false }
  );

  const mutation = useMutation({
    mutationFn: put,
  });

  const onRecurlyTokenReceived = useCallback(
    async (billingInfoToken, actionResultToken) => {
      onError(false);
      await mutation.mutateAsync({
        workspaceId,
        billingInfoToken,
        actionResultToken,
      });
      onComplete();
    },
    [mutation, onComplete, onError, workspaceId]
  );

  if (isLoading) {
    return <Loader size="small" active />;
  }

  return (
    <RecurlyPaymentForm
      billingInformation={data?.billingInfo}
      countryCode={data?.billingInfo?.country || countryCode}
      onRecurlyTokenReceived={onRecurlyTokenReceived}
      onError={onError}
      isAddingSubscription={mutation.isLoading}
      onSelectedCountryCodeChange={() => {}}
      onPostalCodeChange={() => {}}
      onVatNumberChange={() => {}}
      {...rest}
    />
  );
}

RecurlyPaymentFormContainer.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default RecurlyPaymentFormContainer;
