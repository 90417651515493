import {
  ADD_USER_MAILS_SUCCESS,
  DELETE_USER_MAILS_SUCCESS,
  UPDATE_USER_SUCCESS,
} from './updateUser';
import { ZAPIER_DISCONNECTED } from '../Integrations/Zaps/disconnectZapier';
import {
  USER_AVATAR_CHANGED,
  BANDWIDTH_CHANGED,
  USER_GET_SUCCESS,
} from './actions';

export default function userReducer(state = {}, action) {
  let features;
  let subscription;
  let emailListWithAddedMail;
  switch (action.type) {
    case USER_GET_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return Object.assign({}, state, action.user);
    case ZAPIER_DISCONNECTED:
      return {
        ...state,
        LoggedInZapier: false,
      };
    case USER_AVATAR_CHANGED: {
      if (state.UserID === action.email) {
        return {
          ...state,
          AvatarUrl: action.avatarUrl,
          Color: action.color,
        };
      }
      return state;
    }
    case BANDWIDTH_CHANGED: {
      return {
        ...state,
        BandwidthLimit: action.limit,
        Used: action.used,
      };
    }
    case 'LOCKED':
      return {
        ...state,
        Locked: true,
        LockedReason: 'paymentc',
      };
    case 'FEATURE_APP_LIMIT_CHANGED':
      features = { ...state.Features };
      features.max_integration_number = action.max_integration_number;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_CLOUD_SEARCH_CHANGED':
      features = { ...state.Features };
      features.search_in_clouds = action.search_in_clouds;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_ADD_ACCOUNT_CHANGED':
      features = { ...state.Features };
      features.add_accounts = action.add_accounts;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_LINKED_ITEM_ACCESS_RIGHTS_CHANGED':
      features = { ...state.Features };
      features.space_access_control = action.space_access_control;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_UPLOAD_TO_CLOUDS_CHANGED':
      features = { ...state.Features };
      features.upload_to_clouds_allowed = action.upload_to_clouds_allowed;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_DOWNLOAD_FROM_CLOUDS_CHANGED':
      features = { ...state.Features };
      features.download_from_clouds_allowed =
        action.download_from_clouds_allowed;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_COPY_TO_CLOUDS_CHANGED':
      features = { ...state.Features };
      features.copy_to_clouds_allowed = action.copy_to_clouds_allowed;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_EXPORT_CHAT_HISTORY_CHANGED':
      features = { ...state.Features };
      features.export_chat_history = action.export_chat_history;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'USER_CURRENTPLAN_CHANGED':
      subscription = { ...state.Subscription };
      subscription.CurrentPlan = action.CurrentPlan.Title;
      return Object.assign({}, state, {
        ...state,
        Subscription: subscription,
        Features: { ...action.CurrentPlan.Features },
      });
    case 'FEATURE_BANDWIDTH_LIMIT_CHANGED':
      features = { ...state.Features };
      features.bandwidth_limit = action.bandwidth_limit;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case 'FEATURE_POST_BILLING_CHANGED':
      features = { ...state.Features };
      features.post_limit_allowed = action.post_limit_allowed;
      return Object.assign({}, state, {
        ...state,
        Features: features,
      });
    case ADD_USER_MAILS_SUCCESS:
      emailListWithAddedMail = state.RegisteredMailsUnconfirmed.slice();
      emailListWithAddedMail.push(action.userEmail);
      return Object.assign({}, state, {
        ...state,
        RegisteredMailsUnconfirmed: emailListWithAddedMail,
      });
    case DELETE_USER_MAILS_SUCCESS:
      if (action.isConfirmed) {
        emailListWithAddedMail = state.RegisteredMailsConfirmed.slice();
        if (emailListWithAddedMail.indexOf(action.userEmail) > -1) {
          emailListWithAddedMail.splice(
            emailListWithAddedMail.indexOf(action.userEmail),
            1
          );
        }
        return Object.assign({}, state, {
          ...state,
          RegisteredMailsConfirmed: emailListWithAddedMail,
        });
      }
      emailListWithAddedMail = state.RegisteredMailsUnconfirmed.slice();
      if (emailListWithAddedMail.indexOf(action.userEmail) > -1) {
        emailListWithAddedMail.splice(
          emailListWithAddedMail.indexOf(action.userEmail),
          1
        );
      }
      return Object.assign({}, state, {
        ...state,
        RegisteredMailsUnconfirmed: emailListWithAddedMail,
      });
    default:
      return state;
  }
}
