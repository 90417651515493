import { differenceInMinutes } from 'date-fns';
import { SPACES_LIST_TYPE, WORKSPACE_SORT_TYPE } from '.';
import { SORT_TYPE } from 'store/Spaces';
import workspaceType from '../../DataLayer/Workspaces/workspaceType';
import { createSelector } from 'reselect';

export function getUI(state) {
  return state.ui;
}

export const getSpaceSidebarPanel = state => {
  if (state.ui.spaceSidebarPanel) {
    return state.ui.spaceSidebarPanel;
  }
  return null;
};

export const getLatestWorkspaceId = state => state.ui.latestWorkspaceId;

export const getLatestChannelIdByWorkspaceId = (state, workspaceId) =>
  state.ui?.latestChannelId?.[workspaceId];

export const getLatestChannelId = state => {
  const latestWorkspaceId = getLatestWorkspaceId(state);
  return state.ui?.latestChannelId?.[latestWorkspaceId];
};

export const getIsChatPreviewHidden = (state, id) =>
  state.ui?.chatPreviewHidden?.[id];

export const getWorkspacesSidebarOpen = state =>
  state.ui.workspacesSidebarOpenNew;

export const getAppNavitationMenuOpen = state => state.ui.appNavitationMenuOpen;

export const getHidePrivateChatSidebarInfo = state =>
  state.ui.hidePrivateChatSidebarInfo;

export const getFileViewType = state => state.ui.fileViewType;

export const getFilesFullscreen = state => state.ui.filesFullScreen;

export const hasSortedManuallyByWorkspaceAndListType = (
  state,
  workspaceId,
  listType
) => state.ui?.spaceSort?.[workspaceId]?.hasSortedManually;

export const getSortTypeByWorkspaceAndListType = (
  state,
  workspaceId,
  listType
) =>
  state.ui?.spaceSort?.[workspaceId]?.[listType] ||
  SORT_TYPE.lastUpdateTimestamp;

export const getTeamSpaceSortType = (state, workspaceId) =>
  state.ui?.spaceSort?.[workspaceId]?.[SPACES_LIST_TYPE.team] ||
  SORT_TYPE.lastUpdateTimestamp;

export const getSingleSpaceSortType = (state, workspaceId) =>
  state.ui?.spaceSort?.[workspaceId]?.[SPACES_LIST_TYPE.single] ||
  SORT_TYPE.lastUpdateTimestamp;

export const getArchivedSpaceSortType = (state, workspaceId) =>
  state.ui?.spaceSort?.[workspaceId]?.[SPACES_LIST_TYPE.archived] ||
  SORT_TYPE.lastUpdateTimestamp;

export const getPrivateSpaceSortType = state =>
  state.ui?.spaceSort?.[workspaceType.CONVERSATIONS]?.[
    SPACES_LIST_TYPE.private
  ] || SORT_TYPE.lastUpdateTimestamp;

export const getPrivateArchivedSpaceSortType = state =>
  state.ui?.spaceSort?.[workspaceType.CONVERSATIONS]?.[
    SPACES_LIST_TYPE.privateArchived
  ] || SORT_TYPE.lastUpdateTimestamp;

export const getAppsPromotionBannerHidden = state =>
  state.ui.appsPromotionBannerHidden;

export const getEnterBillingInformationReminderHidden = (state, workspaceId) =>
  state.ui.enterBillingInformationReminderHidden.includes(workspaceId);

export const getDesktopAppPromotionBannerHidden = state =>
  state.ui.desktopAppPromotionBannerHidden;

export const getBackupPromotionBannerHidden = state =>
  state.ui.backupPromotionBannerHidden;

export const getWorkspacePositions = state => {
  return state.ui.workspacePositions || [];
};

export const getArchivedWorkspaces = state => state.ui.archivedWorkspaces;

export const getIsWorkspaceArchived = (state, workspaceId) => {
  try {
    return state.ui.archivedWorkspaces.includes(workspaceId);
  } catch (e) {
    return false;
  }
};

export const getChannelPositions = state => {
  return state.ui.channelPositions || {};
};

export const getChannelPositionsByWorkspaceId = (state, workspaceId) => {
  return state.ui.channelPositions?.[workspaceId];
};

export const getWorkspaceSortType = state => state.ui?.workspaceSort;
export const getIsWorkspaceSortTypeCustom = state =>
  state.ui?.workspaceSort === WORKSPACE_SORT_TYPE.custom;

export const getUnreadChannels = state => {
  return state.ui.unreadChannels;
};

export const makeGetIsChannelMarkedAsUnread = () =>
  createSelector(
    [getUnreadChannels, (_, channelId) => channelId],
    (unreadChannels, channelId) => {
      return unreadChannels?.[channelId];
    }
  );

export const getIsWorkspaceMarkedAsUnread = (state, workspaceId) => {
  return state.ui.unreadWorkspaces?.[workspaceId];
};

export const getWorkspacesMarkedAsUnread = state => {
  return state.ui.unreadWorkspaces;
};

export const isBillingInformationReminderPostponed = (state, workspaceId) => {
  try {
    const datePostponed =
      state.ui.enterBillingInformationReminderPostponed[workspaceId];
    return differenceInMinutes(Date(), datePostponed) < 1440;
  } catch (ex) {
    return false;
  }
};

export const getPopularTags = state => state.ui.popularTagsv4.slice(0, 5);
