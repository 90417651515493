import { ADD_TEMPLATES } from './actions';

function addStoreTemplatesToState(state, templates) {
  return Object.assign({}, { ...state }, { ...templates });
}

export default function templatesReducer(state = {}, action) {
  switch (action.type) {
    case ADD_TEMPLATES:
      return addStoreTemplatesToState(state, action.templates);
    default:
      return state;
  }
}
