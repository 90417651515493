import axios from 'axios';
import { RESOURCES } from 'DataLayer/endpoints';

export function removeSingle(id) {
  return axios.delete(`${RESOURCES}/${id}`);
}

export function removeMultiple(ids) {
  const resourceIds = ids.join(',');
  return axios.delete(`${RESOURCES}/delete`, {
    data: {
      id: resourceIds,
    },
  });
}
