import { LOAD_LINK_SUCCESS } from './getLink';
import { REMOVE_LINK_SUCCESS } from './removeLink';
import { UPDATE_LINK_SUCCESS } from './updateLink';

function setLink(state, link) {
  return Object.assign({}, state, {
    [link.ResourceId]: link,
  });
}

function removeLink(state, id) {
  const links = Object.assign({}, state); // assuming you use Object.assign() polyfill!
  delete links[id]; // shallowly mutating a shallow copy is fine
  return links;
}

const initialState = {};

function linkReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LINK_SUCCESS:
    case LOAD_LINK_SUCCESS:
      return setLink(state, action.link);
    case REMOVE_LINK_SUCCESS:
      return removeLink(state, action.id);
    default:
      return state;
  }
}

export default linkReducer;
