import { getRecurlyPublicKey } from 'config/config';

const RECURLY_PUBLIC_KEY = getRecurlyPublicKey();

export const configureRecurly = () => {
  window.recurly.configure(RECURLY_PUBLIC_KEY);
};

export const isCardError = error => {
  try {
    if (error.code === 'validation' || error.code === 'invalid-parameter') {
      if (
        error.fields.includes('number') ||
        error.fields.includes('month') ||
        error.fields.includes('year')
      ) {
        return true;
      }
    }
    return false;
  } catch (ex) {
    return false;
  }
};

export const isVATError = error => {
  try {
    if (error.code === 'invalid-parameter') {
      if (error.fields.includes('vat_number')) {
        return true;
      }
    }
    return false;
  } catch (ex) {
    return false;
  }
};

export const isCVVError = error => {
  try {
    if (error.code === 'invalid-parameter') {
      if (error.fields.includes('cvv')) {
        return true;
      }
    }
    return false;
  } catch (ex) {
    return false;
  }
};

export const isPostcodeError = error => {
  try {
    if (error.code === 'invalid-parameter') {
      if (error.fields.includes('postal_code')) {
        return true;
      }
    }
    return false;
  } catch (ex) {
    return false;
  }
};

export const getCVVLength = error => {
  try {
    return error.message.includes('four') ? 4 : 3;
  } catch (ex) {
    return 3;
  }
};

export const CURRENCY_CODES = {
  USD: 'USD',
  EUR: 'EUR',
};

//https://otixo-test.recurly.com/configuration/taxes/eu
export const EUR_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'DE',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'XI',
];

export const getCurrenyCodeByCountry = countryCode => {
  try {
    return EUR_COUNTRY_CODES.includes(countryCode)
      ? CURRENCY_CODES.EUR
      : CURRENCY_CODES.USD;
  } catch (ex) {
    return CURRENCY_CODES.USD;
  }
};

const VATIdPlaceholders = {
  AT: 'ATU12345678',
  BE: 'BE1234567890',
  BG: 'BG123456789',
  HR: 'HR12345678901',
  CY: 'CY12345678X',
  CZ: 'CZ12345678',
  DK: 'DK12345678',
  EE: 'EE123456789',
  FI: 'FI12345678',
  FR: 'FR12345678901',
  DE: 'DE123456789',
  GR: 'EL123456789',
  HU: 'HU12345678',
  IE: 'IE1234567WA',
  IT: 'IT12345678901',
  LV: 'LV12345678901',
  LT: 'LT123456789',
  LU: 'LU12345678',
  MT: 'MT12345678',
  NL: 'NL123456789B01',
  PL: 'PL1234567890',
  PT: 'PT123456789',
  RO: 'RO1234567890',
  SK: 'SK1234567890',
  SI: 'SI12345678',
  ES: 'ESX12345678',
  SE: 'SE123456789012',
};

export const getVATNumberPlaceholderByCountryCode = countryCode => {
  try {
    return VATIdPlaceholders[countryCode];
  } catch (ex) {
    return '';
  }
};

export const isTaxableRegion = countryCode =>
  EUR_COUNTRY_CODES.includes(countryCode);

export const CURRENCY_SYMBOL = {
  USD: '$',
  EUR: '€',
};

export const getCurrencySymbol = countryCode => {
  try {
    if (!countryCode) {
      return CURRENCY_SYMBOL.EUR;
    }
    return EUR_COUNTRY_CODES.includes(countryCode)
      ? CURRENCY_SYMBOL.EUR
      : CURRENCY_SYMBOL.USD;
  } catch (ex) {
    return CURRENCY_SYMBOL.EUR;
  }
};

export const isEuro = currencySymbol => currencySymbol === CURRENCY_SYMBOL.EUR;

export const handleThreeDSecure = (target, actionTokenId) => {
  // https://developers.recurly.com/guides/3ds2.html#integration-guide
  return new Promise((resolve, reject) => {
    const risk = window.recurly.Risk();
    const threeDSecure = risk.ThreeDSecure({
      actionTokenId,
    });
    threeDSecure.on('error', error => {
      reject(error);
    });
    threeDSecure.on('token', actionResultToken => {
      resolve(actionResultToken.id);
    });
    threeDSecure.attach(target);
  });
};
