import { preflightArchive } from '../../DataLayer/Resources/download';

import { getOtixoArchiveDownloadUrl, isDesktopApp } from '../../config/config';

import { addDownload } from './addDownload';
import { getToken } from 'Helpers/BrowserApi/localStorage';

export const downloadArchive = (resourceIds, spaceId) => dispatch => {
  const ids = resourceIds.join();
  return preflightArchive(ids, spaceId).then(() => {
    if (isDesktopApp()) {
      window.ipcRenderer?.send('download', {
        url: getOtixoArchiveDownloadUrl(ids, spaceId),
        name: 'archive.zip',
        token: getToken(),
      });
    } else {
      dispatch(addDownload(getOtixoArchiveDownloadUrl(ids, spaceId)));
    }
  });
};

export default downloadArchive;
