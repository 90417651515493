import { combineReducers } from 'redux';
import lodashArray from 'lodash/array';

import { RESOURCES_MARK_COMMENTS_AS_READ } from '../Entities/actions';

import * as actions from './actions';
import { ADD_LINKEDITEMS_SUCCESS } from './addLinkedItemToSpace';
import { ADD_COMMENT } from '../Comments/actions';
import { RENAME_LINKEDITEM_SUCCESS } from './renameLinkedItem';
import { REMOVE_NOTIFICATION } from 'store/Notifications/actions';
import notificationType from 'Constants/notificationType';
import sharesReducer from './share/index';

function union(state, ids, spaceId) {
  const linkedItemsById = lodashArray.union(state[spaceId], ids);

  return Object.assign({}, state, {
    [spaceId]: linkedItemsById,
  });
}

function add(state, ids, spaceId) {
  return Object.assign({}, state, {
    [spaceId]: ids,
  });
}

function remove(state, ids, spaceId) {
  const remainingLinkedItems = state[spaceId].filter(
    id => ids.indexOf(id) === -1
  );

  return Object.assign({}, state, {
    [spaceId]: remainingLinkedItems,
  });
}

function removeByNotification(state, notification) {
  try {
    if (notification.Type === notificationType.ChannelResourceLinked) {
      return remove(
        state,
        [notification.Resource.Id],
        notification.Workspace.Id
      );
    }
  } catch (ex) {
    return state;
  }
  return state;
}

const initialState = {};

function linkedItemIds(state = initialState, action) {
  switch (action.type) {
    case REMOVE_NOTIFICATION:
      return removeByNotification(state, action.notification);
    case actions.LOAD_LINKEDITEMS_SUCCESS:
      return add(state, action.linkedItemIds, action.spaceId);
    case ADD_LINKEDITEMS_SUCCESS:
      return union(state, action.linkedItemIds, action.spaceId);
    case actions.DELETE_LINKEDITEM_SUCCESS:
      return remove(state, action.ids, action.spaceId);
    default:
      return state;
  }
}

function setLinkedItemLocked(state, id, locked) {
  const linkedItem = Object.assign({}, state[id]);
  linkedItem.Locked = locked;
  return Object.assign({}, state, {
    [id]: linkedItem,
  });
}

function setLinkedItemStarred(state, id, starred) {
  const linkedItem = Object.assign({}, state[id]);
  linkedItem.Starred = starred;
  linkedItem.StarredTimestamp = starred ? Date.now() : null;
  return Object.assign({}, state, {
    [id]: linkedItem,
  });
}

function setLinkedItemName(state, id, name) {
  const linkedItem = Object.assign({}, state[id]);
  linkedItem.Name = name;
  return Object.assign({}, state, {
    [id]: linkedItem,
  });
}

// function setLinkedItemReminder(state, id, reminderId) {
//   if (!state[id]) {
//     return state;
//   }
//   const linkedItem = Object.assign({}, state[id]);
//   linkedItem.ReminderId = reminderId;
//   return Object.assign({}, state, {
//     [id]: linkedItem,
//   });
// }

function setResourceCommentAsRead(state, spaceId, resourceId) {
  const linkedItem = Object.assign({}, state[resourceId]);
  if (linkedItem.Resource && linkedItem.Resource.Comments) {
    const comment = linkedItem.Resource.Comments.find(
      c => c.SpaceId === spaceId
    );
    if (comment) {
      comment.Unread = 0;
    }
  }
  return Object.assign({}, state, {
    [resourceId]: linkedItem,
  });
}
function setCommentTotal(state, spaceId, resourceId, isUserComment) {
  const linkedItem = Object.assign({}, state[resourceId]);
  if (linkedItem.Resource && linkedItem.Resource.Comments) {
    let comment = linkedItem.Resource.Comments.find(c => c.SpaceId === spaceId);
    if (comment) {
      comment.Total = comment.Total + 1;
      comment.Unread = isUserComment ? 0 : comment.Unread + 1;
    } else {
      comment = {
        ResourceId: resourceId,
        SpaceId: spaceId,
        Total: 1,
        Unread: isUserComment ? 0 : 1,
      };
      linkedItem.Resource.Comments = [comment];
    }
  }
  return Object.assign({}, state, {
    [resourceId]: linkedItem,
  });
}

function linkedItems(state = {}, action) {
  switch (action.type) {
    case actions.LOAD_LINKEDITEMS_SUCCESS:
      return Object.assign({}, state, action.linkedItems);
    case ADD_LINKEDITEMS_SUCCESS:
      return Object.assign({}, state, action.linkedItems);
    case actions.LINKEDITEM_SET_LOCKED:
      return setLinkedItemLocked(state, action.id, action.locked);
    case RENAME_LINKEDITEM_SUCCESS:
      return setLinkedItemName(state, action.resourceId, action.name);
    case actions.LINKEDITEM_STAR_ADDED:
      return setLinkedItemStarred(state, action.id, action.starred);
    // case REMINDERS_ADDED:
    //   return setLinkedItemReminder(
    //     state,
    //     action.reminder.TargetId,
    //     action.reminder.Id
    //   );
    // case REMINDER_REMOVED:
    //   return setLinkedItemReminder(state, action.targetId, null);
    case RESOURCES_MARK_COMMENTS_AS_READ:
      return setResourceCommentAsRead(state, action.spaceId, action.resourceId);
    case ADD_COMMENT:
      return setCommentTotal(
        state,
        action.spaceId,
        action.resourceId,
        action.isUserComment
      );
    default:
      return state;
  }
}

function fetching(state = {}, action) {
  switch (action.type) {
    case actions.LINKED_ITEMS_LOADED:
    case actions.LOAD_LINKEDITEMS_ERROR:
      return Object.assign({}, state, { [action.spaceId]: false });
    case actions.LOAD_LINKEDITEMS_REQUEST:
      return Object.assign({}, state, { [action.spaceId]: true });
    default:
      return state;
  }
}

function removing(state = {}, action) {
  switch (action.type) {
    case actions.LINKED_ITEM_REMOVE_ERROR:
      return action.ids.reduce(
        (prev, current) => ({ ...prev, [current]: false }),
        state
      );
    case actions.LINKED_ITEM_REMOVE_REQUEST:
      return action.ids.reduce(
        (prev, current) => ({ ...prev, [current]: true }),
        state
      );
    default:
      return state;
  }
}

function error(state = {}, action) {
  switch (action.type) {
    case actions.LOAD_LINKEDITEMS_SUCCESS:
    case actions.LOAD_LINKEDITEMS_REQUEST:
      return Object.assign({}, state, { [action.spaceId]: false });
    case actions.LOAD_LINKEDITEMS_ERROR:
      return Object.assign({}, state, { [action.spaceId]: true });
    default:
      return state;
  }
}

function memberFilter(state = {}, action) {
  switch (action.type) {
    case actions.LINKED_ITEM_MEMBER_FILTER_SELECTED:
      return Object.assign({}, state, { [action.spaceId]: action.memberId });
    case actions.LINKED_ITEM_FILTERS_CLEARED:
    default:
      return state;
  }
}

function tagFilter(state = {}, action) {
  switch (action.type) {
    case actions.LINKED_ITEM_TAG_FILTER_SELECTED:
      return Object.assign({}, state, {
        [action.spaceId]: action.linkedItemIds,
      });
    case actions.LINKED_ITEM_FILTERS_CLEARED:
    default:
      return state;
  }
}

export default combineReducers({
  ids: linkedItemIds,
  linkedItems,
  fetching,
  removing,
  error,
  memberFilter,
  tagFilter,
  sharing: sharesReducer,
});
