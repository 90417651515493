export default function setItemPermission(item) {
  if (!item) {
    return null;
  }
  const itemUpdated = item;

  if (itemUpdated.Deny) {
    itemUpdated.CanDelete = itemUpdated.Deny.indexOf('delete') === -1;
    itemUpdated.CanCreateFile = itemUpdated.Deny.indexOf('create-file') === -1;
    itemUpdated.CanCreateFolder =
      itemUpdated.Deny.indexOf('create-folder') === -1;
    itemUpdated.CanRename = itemUpdated.Deny.indexOf('rename') === -1;
    itemUpdated.CanEncrypt = itemUpdated.Deny.indexOf('encrypt') === -1;
    itemUpdated.CanDownload = itemUpdated.Deny.indexOf('download') === -1;
    itemUpdated.CanCopy = itemUpdated.Deny.indexOf('copy') === -1;
    itemUpdated.CanMove = itemUpdated.Deny.indexOf('move') === -1;
    itemUpdated.CanShare = itemUpdated.Deny.indexOf('share') === -1;
    itemUpdated.CanTransfer = itemUpdated.Deny.indexOf('transfer') === -1;
  }
  return itemUpdated;
}
