import React from 'react';
import PropTypes from 'prop-types';
import { isDesktopApp } from 'config/config';

class ShowIfNotAppStore extends React.PureComponent {
  render() {
    if (isDesktopApp()) {
      return null;
    }

    return this.props.all;
  }
}

ShowIfNotAppStore.propTypes = {
  all: PropTypes.node,
};

ShowIfNotAppStore.defaultProps = {
  all: null,
};

export default ShowIfNotAppStore;
