import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

const reminders = state => state.reminders;
const remindersArray = createSelector(
  [reminders],
  reminders => Object.values(reminders)
);

export const remindersKeyedByTargetId = createSelector(
  [reminders],
  reminders => {
    return Object.values(reminders).reduce((object, reminder) => {
      object[reminder.TargetId] = reminder;
      return object;
    }, {});
  }
);

export const getReminders = createSelector(
  [reminders],
  reminders => {
    return orderBy(Object.values(reminders), ['Time']);
  }
);

export const getFutureReminders = createSelector(
  [getReminders],
  reminders => reminders.filter(reminder => reminder.Active)
);

export const getRemindersByWorkspaceId = createSelector(
  [getReminders, (_, workspaceId) => workspaceId],
  (reminders, workspaceId) =>
    reminders.filter(reminder => reminder.WorkgroupId === workspaceId)
);

export const getFutureRemindersByWorkspaceId = createSelector(
  [getRemindersByWorkspaceId],
  reminders => reminders.filter(reminder => reminder.Active)
);

export const getReminderById = createSelector(
  [reminders, (_, reminderId) => reminderId],
  (reminders, reminderId) => {
    return reminders[reminderId];
  }
);

// export const makeGetReminderIdByTargetId = () =>
//   createSelector(
//     [remindersKeyedByTargetId, (_, targetId) => targetId],
//     (reminders, targetId) => {
//       console.log(reminders, targetId);
//       try {
//         return reminders[targetId].Id;
//       } catch (e) {
//         return undefined;
//       }
//     }
//   );

export const makeGetReminderIdByTargetIdAndChannelId = () =>
  createSelector(
    [
      remindersArray,
      (_, props) => props.targetId,
      (_, props) => props.channelId,
    ],
    (reminders, targetId, channelId) => {
      try {
        const reminder = reminders.find(
          reminder =>
            reminder.TargetId === targetId && reminder.ChannelId === channelId
        );
        return reminder.Id;
      } catch (e) {
        return undefined;
      }
    }
  );
