import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getReminderById } from 'store/Reminders/selectors';
import { showSaveReminderModal } from 'store/Modals/actions';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { TimeSquareIcon } from 'Components/Icons/WeTeamIcon';

function ChatItemReminderIcon({ reminder, onClick }) {
  return (
    <WeTeamIcon
      Icon={TimeSquareIcon}
      onClick={() => {
        onClick(reminder.Id);
      }}
    />
  );
}

ChatItemReminderIcon.propTypes = {
  reminder: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default connect(
  (state, props) => {
    return {
      reminder: getReminderById(state, props.reminderId),
    };
  },
  {
    onClick: showSaveReminderModal,
  }
)(ChatItemReminderIcon);
