export const testWorkspaceId = '47e5db0b-04fe-4638-97b8-2788ad68b018';

export const testChannelId = '9821d3eb-3240-4334-bdd3-784e33752e01';

export const verificationToken = 'a-token';
export const validVerificationCode = '123456';
export const invalidVerificationCode = '999999';

export const unverifiedUserNoCode = {
  email: 'unverified-user-no-code@otixo.com',
  password: 'unverified-user',
};

export const unverifiedUserNoFirstWorkSpace = {
  email: 'unverified-user-no-first-workspace@otixo.com',
  password: 'unverified-user',
  name: 'Unverified User',
};

export const verifiedUser = {
  email: 'verified-user@otixo.com',
  password: 'verified-user',
  firstName: 'Verified',
  lastName: 'User',
};

export const unknownUser = {
  email: 'not-a-user@otixo.com',
  password: 'not-a-user',
  firstName: 'Unknown',
  lastName: 'User',
};

export const invitedUser = {
  email: 'invited-user@otixo.com',
  password: 'invited-user',
  firstName: 'Invited',
  lastName: 'User',
};

export const registerErrorUser = {
  email: 'register-error-user@otixo.com',
  password: 'register-error-user',
  firstName: 'register-error',
  lastName: 'User',
};

export const firstWorkSpaceNameSuggestion = `${unverifiedUserNoFirstWorkSpace.name}'s Work Space`;

export const createFirstWorkSpaceBadName = `${unverifiedUserNoFirstWorkSpace.name}'s Bad Work Space`;

export const user = {
  Me: {
    UserID: verifiedUser.email,
    FirstName: 'Verified',
    LastName: 'User',
    AccountType: 'normal',
    UserStatusToken: null,
    Language: 'en',
    Locked: false,
    LockedReason: null,
    Guid: '8a81811473d6eb450173d840b9230000',
    RegisteredMailsConfirmed: [verifiedUser.email],
    RegisteredMailsUnconfirmed: [],
    Timezone: 'America/Fortaleza',
    TimezoneId: 65,
    UIProductArea: 'collaboration',
    HasUserOpenedDefaultSpace: false,
    DesktopAppUpdate: 'Download',
    RegistrationDate: '2020-08-10T12:03:46.737Z',
    LastLoginDate: '2020-11-09T13:37:46.754Z',
    AvatarUrl: null,
    Color: '#88c540',
    ShowWelcomeScreen: false,
    WorkSpacesAppCueTourSeen: true,
    ChatSendMessageOnEnter: true,
    PageToken: null,
    OwnsPaidWorkspaces: true,
    LoggedInZapier: false,
    TransferSubscription: true,
    UsedToday: 0,
    UsedThisPeriod: 0,
    Used: 717201,
    BandwidthLimit: 5368709120,
    EarnedBandwidth: 0,
    PastDue: false,
    PostBillingStatus: false,
    BandwidthRenewalDate: '2020-11-10T00:00:00Z',
    Subscription: null,
    Features: {
      upload_to_clouds_allowed: true,
      download_from_clouds_allowed: true,
      bandwidth_limit: 0,
      search_in_clouds: true,
      otixo_drive_size: 26843545600,
      otixo_drive_size_used_space: 16250216,
      otixo_space_drive_size: 187904819200,
      encryption: true,
      otixo_space_drive_size_used_space: 31906136,
      move_or_copy_to_clouds_full: true,
      show_data_from_last_billing_data: false,
      hide_otixo_drive: false,
      current_msg_number: 2306,
      total_msg_number: -1,
      keep_file_copy_single_spaces: false,
      allow_bandwidth_purchase: true,
    },
  },
};

export const freeWorkspace = {
  leaveLocked: false,
  provisional: false,
  fullMember: true,
  workspaceDrive: '17a4e584-2dbc-4828-96ad-eec2aae369d1',
  subscribed: true,
  Id: '17a4e584-2dbc-4828-96ad-eec2aae369d1',
  OwnerMail: verifiedUser.email,
  OwnerId: '8a81811473d6eb450173d840b9230000',
  MemberCount: 0,
  GuestCount: 0,
  Name: 'Cypress Work Space',
  CreationDate: 1603707184903,
  LastEditDate: 1603707314205,
  Active: true,
  Color: '#e66940',
  AvatarUrl: null,
  NotificationSettings: {
    activities: [
      'ITEM_DOWNLOADED',
      'ITEM_DELETED',
      'COLLABORATOR_INVITED',
      'COLLABORATOR_JOINED',
      'ITEM_RENAMED',
      'COMMENT_ADDED',
      'ITEM_CREATED',
      'COLLABORATOR_LEFT',
    ],
    deliveryInterval: 'NONE',
  },
  Subscription: null,
  SpaceDriveId: '17a4e584-2dbc-4828-96ad-eec2aae369d1',
  SpaceDriveSize: 1.073741824e10,
  SpaceDriveUsedSize: 1.3807168e7,
  Provisional: false,
  Member: true,
  ACL: {
    ChangeWorkspacePicture: true,
    ShareMessage: true,
    RenameWorkspace: true,
    CreateChannel: true,
    InviteMembers: true,
    ExportChatHistory: false,
    Leave: true,
    RemoveMember: true,
    DeleteWorkspace: true,
    InviteGuests: true,
    ChangeMemberType: true,
  },
  MembershipMail: verifiedUser.email,
  BackupEnabled: false,
  BackupMessages: true,
  BackupChannels: true,
  BackupFrequency: null,
  DestinationFolder: null,
  Channels: null,
  TrialPeriod: null,
};

export const subscribedWorkspace = {
  ...freeWorkspace,
  Subscription: {},
};

export const workspaces = [freeWorkspace];

export const testWorkspaceMember = {
  color: '#88c540',
  avatar: null,
  Id: testWorkspaceId,
  WorkgroupId: freeWorkspace.Id,
  UserId: '8a81811473d6eb450173d840b9230000',
  UserMail: verifiedUser.email,
  Type: 'REGULAR',
  Status: 'JOINED',
  InvitorId: null,
  LastEditDate: 0,
  JoinDate: 1603456697988,
  AttachedUser: {
    email: verifiedUser.email,
    presence: 'ONLINE',
    id: '8a81811473d6eb450173d840b9230000',
    firstName: verifiedUser.firstName,
    lastName: verifiedUser.lastName,
  },
  InvitedBy: null,
  InvitationMessage: null,
};

export const chatChannel = {
  Id: '11bd326d-da74-4235-9001-a7c447401a11',
  notifications: '7',
  WorkspaceId: freeWorkspace.Id,
  ProvisionalSpace: false,
  Color: '#88c540',
  AvatarEmoji: 'hashtag2.svg',
  Filtered: false,
  Locked: false,
  CanMigrate: false,
  LockReason: null,
  Name: 'Test User',
  NameLowerCase: 'test user',
  AvatarUrl: 'https://dev.otixo.com/icons/orange/BusinessFinance/hashtag2.svg',
  Owner: {
    Email: verifiedUser.email,
    UserId: '8a81811473d6eb450173d840b9230000',
    NotificationMail: verifiedUser.email,
    FirstName: 'Owner',
    LastName: 'A',
    Name: 'Owner A',
    AvatarUrl:
      'https://dev.otixo.com/avatars/Avatar_8a81811473d6eb450173d840b9230000_1604494028336.png',
    LastOnline: 1605020439986,
    Color: '#88c540',
    Presence: 'ONLINE',
  },
  Created: 1603707185000,
  Grant: ['read', 'write', 'write-collaborators'],
  Type: 'CHAT',
  IsDefault: true,
  LastUpdateTimestamp: 1603707405429,
  Archived: false,
  WelcomeScreenSeen: false,
  ACL: {
    Invite: false,
    Delete: false,
    Rename: false,
    RemoveCollaborator: true,
    Join: false,
    Leave: false,
    Create: true,
    ChangeCollaboratorType: true,
    ChangePicture: true,
    AccessControl: true,
    ShareFolder: true,
    CreateFolder: true,
    Meetings: true,
    ShareDesktop: true,
    ExportChannel: true,
  },
  Collaborators: [],
  IsChat: true,
};

export const generalChannel = {
  Id: '62bd326d-da74-4235-9001-a7c447401a80',
  notifications: '7',
  WorkspaceId: freeWorkspace.Id,
  ProvisionalSpace: false,
  Color: '#88c540',
  AvatarEmoji: 'hashtag2.svg',
  Filtered: false,
  Locked: false,
  CanMigrate: false,
  LockReason: null,
  Name: 'General',
  NameLowerCase: 'general',
  AvatarUrl: 'https://dev.otixo.com/icons/orange/BusinessFinance/hashtag2.svg',
  Owner: {
    Email: verifiedUser.email,
    UserId: '8a81811473d6eb450173d840b9230000',
    NotificationMail: verifiedUser.email,
    FirstName: 'General',
    LastName: 'Owner',
    Name: 'General Owner',
    AvatarUrl:
      'https://dev.otixo.com/avatars/Avatar_8a81811473d6eb450173d840b9230000_1604494028336.png',
    LastOnline: 1605020439986,
    Color: '#88c540',
    Presence: 'ONLINE',
  },
  Created: 1603707185000,
  Grant: ['read', 'write', 'write-collaborators'],
  Type: 'SYSTEM',
  IsDefault: true,
  LastUpdateTimestamp: 1603707405429,
  Archived: false,
  WelcomeScreenSeen: false,
  ACL: {
    Invite: false,
    Delete: false,
    Rename: false,
    RemoveCollaborator: true,
    Join: false,
    Leave: false,
    Create: true,
    ChangeCollaboratorType: true,
    ChangePicture: true,
    AccessControl: true,
    ShareFolder: true,
    CreateFolder: true,
    Meetings: true,
    ShareDesktop: true,
    ExportChannel: true,
  },
  Collaborators: [
    {
      Email: 'member-a@otixo.com',
      UserId: '8a81809f75f8fd2701771a6992281980',
      FirstName: 'Member',
      LastName: 'A',
      AvatarUrl: null,
      Color: '#f0fc43',
      LastOnline: 1616693377208,
      NotificationMail: 'member-a@otixo.com',
      Status: 'JOINED',
      JoinDate: 1611248568629,
      Type: 'REGULAR',
      SpaceFiltered: false,
      Id: '7d48e671-5c0a-11eb-b326-0aebb0914551',
      Presence: 'OFFLINE',
      ACL: {},
    },
    {
      Email: 'member-b@otixo.com',
      UserId: '1a81809f75f8fd2701771a6992281980',
      FirstName: 'Member',
      LastName: 'B',
      AvatarUrl: null,
      Color: '#f0fc43',
      LastOnline: 1616693377208,
      NotificationMail: 'member-b@otixo.com',
      Status: 'JOINED',
      JoinDate: 1611248568629,
      Type: 'REGULAR',
      SpaceFiltered: false,
      Id: '1d48e671-5c0a-11eb-b326-0aebb0914551',
      Presence: 'OFFLINE',
      ACL: {},
    },
  ],
};

export const nonGeneralChannel = {
  Id: '12bd326d-da74-4235-9001-a7c447401a80',
  notifications: '7',
  WorkspaceId: freeWorkspace.Id,
  ProvisionalSpace: false,
  Color: '#88c540',
  AvatarEmoji: 'hashtag2.svg',
  Filtered: false,
  Locked: false,
  CanMigrate: false,
  LockReason: null,
  Name: 'Channel A',
  NameLowerCase: 'channel a',
  AvatarUrl: 'https://dev.otixo.com/icons/orange/BusinessFinance/hashtag2.svg',
  Owner: {
    Email: verifiedUser.email,
    UserId: '8a81811473d6eb450173d840b9230000',
    NotificationMail: verifiedUser.email,
    FirstName: 'Channel A',
    LastName: 'Owner',
    Name: 'Channel A Owner',
    AvatarUrl:
      'https://dev.otixo.com/avatars/Avatar_8a81811473d6eb450173d840b9230000_1604494028336.png',
    LastOnline: 1605020439986,
    Color: '#88c540',
    Presence: 'ONLINE',
  },
  Created: 1603707185000,
  Grant: ['read', 'write', 'write-collaborators'],
  Type: 'SYSTEM',
  IsDefault: true,
  LastUpdateTimestamp: 1603707405429,
  Archived: false,
  WelcomeScreenSeen: false,
  ACL: {
    Invite: false,
    Delete: false,
    Rename: false,
    RemoveCollaborator: true,
    Join: false,
    Leave: false,
    Create: true,
    ChangeCollaboratorType: true,
    ChangePicture: true,
    AccessControl: true,
    ShareFolder: true,
    CreateFolder: true,
    Meetings: true,
    ShareDesktop: true,
    ExportChannel: true,
  },
  Collaborators: [
    {
      Email: 'a1@otixo.com',
      UserId: 'a1',
      FirstName: 'Channel A',
      LastName: 'Member 1',
      AvatarUrl: null,
      Color: '#f0fc43',
      LastOnline: 1616693377208,
      NotificationMail: 'a1@otixo.com',
      Status: 'JOINED',
      JoinDate: 1611248568629,
      Type: 'REGULAR',
      SpaceFiltered: false,
      Id: 'a1',
      Presence: 'OFFLINE',
      ACL: {},
    },
    {
      Email: 'a2@otixo.com',
      UserId: 'a2',
      FirstName: 'Channel A',
      LastName: 'Member 2',
      AvatarUrl: null,
      Color: '#f0fc43',
      LastOnline: 2626693377208,
      NotificationMail: 'a2@otixo.com',
      Status: 'JOINED',
      JoinDate: 2622248568629,
      Type: 'REGULAR',
      SpaceFiltered: false,
      Id: 'a2',
      Presence: 'OFFLINE',
      ACL: {},
    },
  ],
};

export const channels = [generalChannel, nonGeneralChannel, chatChannel];

export const cloudServiceAdapters = {
  CloudServiceAdapters: [
    {
      Id: 'gdata',
      Deny: [],
      Available: true,
      Caption: 'Google Drive',
      Title:
        'Enter a display name for your Google Drive account and press the "Authorize via Google Drive" button. You will be redirected to Google Drive to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 0,
      Featured: true,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/gdata-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/gdata-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/gdata.svg',
      HomeUrl: 'https://www.google.com/drive',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'amazon',
      Deny: [],
      Available: true,
      Caption: 'Amazon',
      Title:
        'Enter a display name for your Amazon account and press the "Authorize via Amazon" button. You will be redirected to Amazon to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 1,
      Featured: true,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/amazon-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/amazon-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/amazon.svg',
      HomeUrl: 'https://www.amazon.com/clouddrive',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'dropbox',
      Deny: [],
      Available: true,
      Caption: 'Dropbox',
      Title:
        'Enter a display name for your Dropbox account and press the "Authorize via Dropbox" button. You will be redirected to Dropbox to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 2,
      Featured: true,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/dropbox-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/dropbox-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/dropbox.svg',
      HomeUrl: 'https://www.dropbox.com',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'boxnet',
      Deny: [],
      Available: true,
      Caption: 'Box',
      Title:
        'Enter a display name for your Box account and press the "Authorize via Box" button. You will be redirected to Box to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 3,
      Featured: true,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/boxnet-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/boxnet-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/boxnet.svg',
      HomeUrl: 'https://www.box.com',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'sftp',
      Deny: [],
      Available: true,
      Caption: 'SFTP',
      Title: null,
      Popularity: 4,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/sftp-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/sftp-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/sftp.svg',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'ServerHost', Label: 'Server', Type: 'String', Required: true },
        {
          Id: 'HomePath',
          Label: 'Home Path',
          Type: 'String',
          Default: '/',
          Required: false,
        },
        { Id: 'Port', Label: 'Port', Type: 'Number', Required: true },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'ftp',
      Deny: [],
      Available: true,
      Caption: 'FTP',
      Title:
        'Enter your login credentials below to connect your account to Otixo. If you don\'t want Otixo to keep your password after you log out, unselect the "Remember Password" checkbox below.',
      Popularity: 5,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/ftp-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/ftp-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/ftp.svg',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'ServerHost', Label: 'Server', Type: 'String', Required: true },
        {
          Id: 'HomePath',
          Label: 'Home Path',
          Type: 'String',
          Default: '/',
          Required: false,
        },
        { Id: 'Port', Label: 'Port', Type: 'Number', Required: true },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
        {
          Id: 'PassiveMode',
          Label: 'Passive Mode',
          Type: 'Boolean',
          Default: 'true',
          Required: false,
        },
        {
          Id: 'ProtocolType',
          Label: 'Use FTPS protocol',
          Type: 'Boolean',
          Default: '',
          Required: false,
        },
      ],
    },
    {
      Id: 'webdav',
      Deny: [],
      Available: true,
      Caption: 'WebDAV',
      Title: null,
      Popularity: 6,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/webdav-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/webdav-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/webdav.svg',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'ServerHost', Label: 'Server', Type: 'String', Required: true },
        {
          Id: 'HomePath',
          Label: 'Home Path',
          Type: 'String',
          Default: '/',
          Required: false,
        },
        { Id: 'Port', Label: 'Port', Type: 'Number', Required: true },
        {
          Id: 'Secure',
          Label: 'Use HTTPS',
          Type: 'Boolean',
          Default: 'true',
          Required: false,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'amazon-s3',
      Deny: [],
      Available: true,
      Caption: 'Amazon S3',
      Title:
        'Enter a display name for your Amazon S3 account and press the "Authorize via Amazon S3" button. You will be redirected to Amazon S3 to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 7,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/amazon-s3-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/amazon-s3-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/amazon-s3.svg',
      HomeUrl: 'https://aws.amazon.com/s3/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        {
          Id: 'BucketName',
          Label: 'Bucket Name',
          Type: 'String',
          Required: true,
        },
        {
          Id: 'ServerHost',
          Label: 'Server',
          Type: 'String',
          Default: 's3.amazonaws.com',
          Required: true,
        },
        {
          Id: 'Username',
          Label: 'Access Key ID',
          Type: 'String',
          Required: true,
        },
        {
          Id: 'Password',
          Label: 'Secret Access Key',
          Type: 'String',
          Required: true,
        },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
        {
          Id: 'Secure',
          Label: 'Use HTTPS',
          Type: 'Boolean',
          Default: 'true',
          Required: false,
        },
      ],
    },
    {
      Id: 'facebook',
      Deny: [],
      Available: true,
      Caption: 'Facebook',
      Title:
        'Enter a display name for your Facebook account and press the "Authorize via Facebook" button. You will be redirected to Facebook to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 8,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/facebook-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/facebook-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/facebook.svg',
      HomeUrl: 'https://www.facebook.com',
      Notes:
        'Please Note: You can only upload pictures of format ".jpg, .png, .gif, .bmp, .tif". All other file types will be discarded.',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: '3d8f7506-9c9c-47ca-8817-e57dad67f5c0',
      Deny: [],
      Available: true,
      Caption: 'Yandex',
      Title: 'Access your Yandex online file storage account from Otixo!\n',
      Popularity: 9,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=3d8f7506-9c9c-47ca-8817-e57dad67f5c0',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=3d8f7506-9c9c-47ca-8817-e57dad67f5c0',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=3d8f7506-9c9c-47ca-8817-e57dad67f5c0',
      HomeUrl: 'https://www.yandex.com',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'evernote',
      Deny: [],
      Available: true,
      Caption: 'Evernote',
      Title:
        'Enter a display name for your Evernote account and press the "Authorize via Evernote" button. You will be redirected to Evernote to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 10,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/evernote-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/evernote-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/evernote.svg',
      HomeUrl: 'https://evernote.com/',
      Notes: 'Please note: You can upload Evernote Notes (.enote) only.',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'flickr',
      Deny: [],
      Available: true,
      Caption: 'Flickr',
      Title:
        'Enter a display name for your Flickr account and press the "Authorize via Flickr" button. You will be redirected to Flickr to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 11,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/flickr-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/flickr-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/flickr.svg',
      HomeUrl: 'https://www.flickr.com/',
      Notes:
        "Please note: only video and image files are acceptable. See <a href='https://help.yahoo.com/kb/flickr/upload-limitations-flickr-sln15628.html' target='_blank'>Upload limitations in Flickr</a>",
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: '9047d499-d3f7-4a55-ae3c-108838eb99ad',
      Deny: [],
      Available: true,
      Caption: '4Shared',
      Title:
        'Access your 4shared account from Otixo! (Only available for paid 4shared accounts.)',
      Popularity: 12,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=9047d499-d3f7-4a55-ae3c-108838eb99ad',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=9047d499-d3f7-4a55-ae3c-108838eb99ad',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=9047d499-d3f7-4a55-ae3c-108838eb99ad',
      HomeUrl: 'http://www.4shared.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'magenta',
      Deny: [],
      Available: true,
      Caption: 'Magenta',
      Title:
        'Enter a display name for your Magenta account and press the "Authorize via Magenta" button. You will be redirected to Magenta to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 13,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/magenta-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/magenta-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/magenta.svg',
      HomeUrl: 'https://cloud.telekom-dienste.de/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'skydrive',
      Deny: [],
      Available: true,
      Caption: 'OneDrive',
      Title:
        'Enter a display name for your OneDrive account and press the "Authorize via OneDrive" button. You will be redirected to OneDrive to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 14,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/skydrive-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/skydrive-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/skydrive.svg',
      HomeUrl: 'https://onedrive.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'onedrivebusiness',
      Deny: [],
      Available: true,
      Caption: 'OneDrive for Business',
      Title:
        'Enter a display name for your OneDrive for Business account and press the "Authorize via OneDrive for Business" button. You will be redirected to OneDrive for Business to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 15,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/onedrivebusiness-m.png',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/onedrivebusiness-s.png',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/onedrivebusiness.svg',
      HomeUrl: 'https://onedrive.live.com/about/business',
      Notes:
        "Please note: OneDrive for Business has folder/file name restrictions and limitations. You can learn more&nbsp;<a href='https://support.microsoft.com/en-us/kb/3125202' target='_blank'>here</a>",
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: '97582953-d5a2-4865-8782-f749824ebf66',
      Deny: [],
      Available: true,
      Caption: 'WEB.DE',
      Title: 'Access your WEB.DE online file storage account from Otixo!',
      Popularity: 16,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=97582953-d5a2-4865-8782-f749824ebf66',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=97582953-d5a2-4865-8782-f749824ebf66',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=97582953-d5a2-4865-8782-f749824ebf66',
      HomeUrl: 'https://web.de/email/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: '9e2c15dc-130f-4356-bcfd-2f4b0a5b9e0e',
      Deny: [],
      Available: true,
      Caption: 'GMX.de',
      Title:
        'Access your GMX.de online file storage account from Otixo! (Germany only)',
      Popularity: 17,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=9e2c15dc-130f-4356-bcfd-2f4b0a5b9e0e',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=9e2c15dc-130f-4356-bcfd-2f4b0a5b9e0e',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=9e2c15dc-130f-4356-bcfd-2f4b0a5b9e0e',
      HomeUrl: 'http://www.gmx.net',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'sugarsync',
      Deny: [],
      Available: true,
      Caption: 'SugarSync',
      Title:
        'Enter a display name for your SugarSync account and press the "Authorize via SugarSync" button. You will be redirected to SugarSync to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 18,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/sugarsync-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/sugarsync-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/sugarsync.svg',
      HomeUrl: 'https://www.sugarsync.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: '542ca275-2f5a-4c7c-b914-2f0316eb5b64',
      Deny: [],
      Available: true,
      Caption: 'ADrive',
      Title: 'Access your ADrive online file storage account from Otixo!',
      Popularity: 19,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=542ca275-2f5a-4c7c-b914-2f0316eb5b64',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=542ca275-2f5a-4c7c-b914-2f0316eb5b64',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=542ca275-2f5a-4c7c-b914-2f0316eb5b64',
      HomeUrl: 'http://www.adrive.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'alfresco',
      Deny: [],
      Available: true,
      Caption: 'Alfresco',
      Title:
        'Enter a display name for your Alfresco account and press the "Authorize via Alfresco" button. You will be redirected to Alfresco to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 20,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/alfresco-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/alfresco-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/alfresco.svg',
      HomeUrl: 'https://www.alfresco.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'owncloud',
      Deny: [],
      Available: true,
      Caption: 'OwnCloud',
      Title: null,
      Popularity: 21,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/owncloud-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/owncloud-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/owncloud.svg',
      HomeUrl: 'https://owncloud.org',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'ServerHost', Label: 'Server', Type: 'String', Required: true },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
        {
          Id: 'HomePath',
          Label: 'Home Path',
          Type: 'String',
          Default: 'owncloud/remote.php/webdav',
          Required: false,
        },
        { Id: 'Port', Label: 'Port', Type: 'Number', Required: true },
        {
          Id: 'Secure',
          Label: 'Use HTTPS',
          Type: 'Boolean',
          Default: 'true',
          Required: false,
        },
      ],
    },
    {
      Id: 'd77f1752-23a5-4f61-baaa-05453fd36d21',
      Deny: [],
      Available: true,
      Caption: 'LiveDrive',
      Title:
        'Access your LiveDrive for Business online file storage account from Otixo!',
      Popularity: 22,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=d77f1752-23a5-4f61-baaa-05453fd36d21',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=d77f1752-23a5-4f61-baaa-05453fd36d21',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=d77f1752-23a5-4f61-baaa-05453fd36d21',
      HomeUrl: 'https://business.livedrive.com',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: '187cd0bd-a0f3-4700-9b38-b7689ad9eb83',
      Deny: [],
      Available: true,
      Caption: 'MyDrive',
      Title: 'Access your myDrive account from Otixo!',
      Popularity: 23,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=187cd0bd-a0f3-4700-9b38-b7689ad9eb83',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=187cd0bd-a0f3-4700-9b38-b7689ad9eb83',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=187cd0bd-a0f3-4700-9b38-b7689ad9eb83',
      HomeUrl: 'https://www.mydrive.ch/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: '43ea2d89-1b1d-447b-a60f-f1f287a1f37c',
      Deny: [],
      Available: true,
      Caption: 'FilesAnywhere',
      Title:
        'Access your FilesAnywhere account from Otixo! (Access is only available for paid FileAnywhere users.)',
      Popularity: 24,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=43ea2d89-1b1d-447b-a60f-f1f287a1f37c',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=43ea2d89-1b1d-447b-a60f-f1f287a1f37c',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=43ea2d89-1b1d-447b-a60f-f1f287a1f37c',
      HomeUrl: 'https://www.filesanywhere.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'ebc35461-9141-4bf9-830b-4f55311d15b0',
      Deny: [],
      Available: true,
      Caption: 'Cubby',
      Title:
        "Access your Cubby account from Otixo.\nNote! There are some limitations with Cubby: You can't move or rename files and folders.",
      Popularity: 25,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=ebc35461-9141-4bf9-830b-4f55311d15b0',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=ebc35461-9141-4bf9-830b-4f55311d15b0',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=ebc35461-9141-4bf9-830b-4f55311d15b0',
      HomeUrl: 'https://www.cubby.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'huddle',
      Deny: [],
      Available: true,
      Caption: 'Huddle',
      Title:
        'Enter a display name for your Huddle account and press the "Authorize via Huddle" button. You will be redirected to Huddle to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 26,
      Featured: false,
      Retired: false,
      AuthType: 'OAuth',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/huddle-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/huddle-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/huddle.svg',
      HomeUrl: 'https://www.huddle.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
      ],
    },
    {
      Id: 'c95d866a-6269-4c6c-b855-d7d57ac1d16c',
      Deny: [],
      Available: true,
      Caption: 'CloudMe',
      Title: 'Access your CloudMe online file storage account from Otixo!',
      Popularity: 27,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=c95d866a-6269-4c6c-b855-d7d57ac1d16c',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=c95d866a-6269-4c6c-b855-d7d57ac1d16c',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=c95d866a-6269-4c6c-b855-d7d57ac1d16c',
      HomeUrl: 'https://www.cloudme.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'baa77925-c758-4dd5-840e-1074f37892df',
      Deny: [],
      Available: true,
      Caption: 'Online FileFolder',
      Title:
        'Access your OnlineFileFolder.com account from Otixo! (Currently only available with a paid Online File Folder account)',
      Popularity: 28,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=baa77925-c758-4dd5-840e-1074f37892df',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=baa77925-c758-4dd5-840e-1074f37892df',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=baa77925-c758-4dd5-840e-1074f37892df',
      HomeUrl: 'https://www.onlinefilefolder.com',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: '7fb74256-ed5c-4be4-8f4f-329f4d6b60f3',
      Deny: [],
      Available: true,
      Caption: 'Fabasoft',
      Title: 'Access your Fabasoft online file storage account from Otixo!',
      Popularity: 29,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=7fb74256-ed5c-4be4-8f4f-329f4d6b60f3',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=7fb74256-ed5c-4be4-8f4f-329f4d6b60f3',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=7fb74256-ed5c-4be4-8f4f-329f4d6b60f3',
      HomeUrl: 'https://www.fabasoft.com',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: '40f16d90-1a5c-4245-b8f3-d25aa31757b9',
      Deny: [],
      Available: true,
      Caption: 'HiDrive',
      Title:
        'Access your Strato HiDrive online file storage account from Otixo!',
      Popularity: 30,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=40f16d90-1a5c-4245-b8f3-d25aa31757b9',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=40f16d90-1a5c-4245-b8f3-d25aa31757b9',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=40f16d90-1a5c-4245-b8f3-d25aa31757b9',
      HomeUrl: 'https://www.free-hidrive.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: '06a31974-b8d2-4ea8-8e6f-e1cf005a800f',
      Deny: [],
      Available: true,
      Caption: 'DriveOnWeb',
      Title: 'Access your DriveOnWeb online file storage account from Otixo!',
      Popularity: 31,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=06a31974-b8d2-4ea8-8e6f-e1cf005a800f',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=06a31974-b8d2-4ea8-8e6f-e1cf005a800f',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=06a31974-b8d2-4ea8-8e6f-e1cf005a800f',
      HomeUrl: 'https://www.driveonweb.de',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'meganz',
      Deny: [],
      Available: true,
      Caption: 'MEGA',
      Title:
        'Enter a display name for your MEGA account and press the "Authorize via MEGA" button. You will be redirected to MEGA to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 32,
      Featured: false,
      Retired: true,
      AuthType: 'Plain',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/meganz-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/meganz-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/meganz.svg',
      HomeUrl: 'https://mega.nz/',
      Notes:
        "This application uses the MEGA API but is not certified or endorsed by MEGA. To use your MEGA account via a third party application like Otixo, you need to create an Access Key. The Key is then used for the encryption and decryption of your files through the application in question. Click<a href='https://support.otixo.com/customer/portal/articles/2643179-add-mega' target='_blank'>here</a> to find out more.",
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
        {
          Id: 'ApiKeyID',
          Label: 'Key ID',
          Type: 'String',
          Default: '',
          Required: false,
        },
      ],
    },
    {
      Id: 'fake',
      Deny: [],
      Available: true,
      Caption: 'Fake Repository',
      Title:
        'Enter a display name for your Fake Repository account and press the "Authorize via Fake Repository" button. You will be redirected to Fake Repository to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 33,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/fake-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/fake-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/fake.svg',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
        {
          Id: 'depth',
          Label: 'depth',
          Type: 'String',
          Default: '',
          Required: false,
        },
        {
          Id: 'FilesPrefix',
          Label: 'files-prefix',
          Type: 'String',
          Default: '',
          Required: false,
        },
        {
          Id: 'FoldersPrefix',
          Label: 'folders-prefix',
          Type: 'String',
          Default: '',
          Required: false,
        },
        {
          Id: 'delay',
          Label: 'delay',
          Type: 'String',
          Default: '',
          Required: false,
        },
        {
          Id: 'FailureRate',
          Label: 'failure-rate',
          Type: 'String',
          Default: '',
          Required: false,
        },
        {
          Id: 'FoldersPerFolder',
          Label: 'folders-per-folder',
          Type: 'String',
          Default: '',
          Required: false,
        },
        {
          Id: 'FilesPerFolder',
          Label: 'files-per-folder',
          Type: 'String',
          Default: '',
          Required: false,
        },
        {
          Id: 'FileSize',
          Label: 'file-size',
          Type: 'String',
          Default: '',
          Required: false,
        },
      ],
    },
    {
      Id: 'pcloud',
      Deny: [],
      Available: true,
      Caption: 'pCloud',
      Title:
        'Enter a display name for your pCloud account and press the "Authorize via pCloud" button. You will be redirected to pCloud to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 34,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium: 'https://dev.otixo.com/wsexp/images/providers/pcloud-m.png',
      IconSmall: 'https://dev.otixo.com/wsexp/images/providers/pcloud-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/pcloud.svg',
      HomeUrl: 'https://www.pcloud.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
      ],
    },
    {
      Id: 'a55a9f60-452a-4094-983b-e2ab4e9f9064',
      Deny: [],
      Available: true,
      Caption: 'DriveHQ',
      Title:
        'Access your LiveDrive for Business online file storage account from Otixo!',
      Popularity: 35,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=false&uid=a55a9f60-452a-4094-983b-e2ab4e9f9064',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/templates?thumbnail=true&uid=a55a9f60-452a-4094-983b-e2ab4e9f9064',
      IconSvg:
        'https://dev.otixo.com/wsexp/images/providers/templates?svg=true&uid=a55a9f60-452a-4094-983b-e2ab4e9f9064',
      HomeUrl: 'https://www.drivehq.com/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'Username', Label: 'Username', Type: 'String', Required: true },
        { Id: 'Password', Label: 'Password', Type: 'String', Required: true },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
      ],
    },
    {
      Id: 'otixo-drive',
      Deny: ['create', 'delete', 'rename'],
      Available: true,
      Caption: 'Otixo Drive',
      Title:
        'Enter a display name for your Otixo Drive account and press the "Authorize via Otixo Drive" button. You will be redirected to Otixo Drive to authorize the Otixo application to access your files. Otixo does not store your username or password for your privacy and protection.',
      Popularity: 36,
      Featured: false,
      Retired: false,
      AuthType: 'Plain',
      IconMedium:
        'https://dev.otixo.com/wsexp/images/providers/otixo-drive-m.png',
      IconSmall:
        'https://dev.otixo.com/wsexp/images/providers/otixo-drive-s.png',
      IconSvg: 'https://dev.otixo.com/wsexp/images/providers/otixo-drive.svg',
      HomeUrl: 'https://aws.amazon.com/s3/',
      Fields: [
        {
          Id: 'Name',
          Label: 'Cloud Drive Name',
          Type: 'String',
          Required: true,
        },
        { Id: 'ServerHost', Label: 'Server', Type: 'String', Required: true },
        {
          Id: 'Secure',
          Label: 'Use HTTPS',
          Type: 'Boolean',
          Default: 'true',
          Required: false,
        },
        {
          Id: 'SavePassword',
          Label: 'Remember password',
          Type: 'Boolean',
          Required: true,
        },
        {
          Id: 'BucketName',
          Label: 'Bucket Name',
          Type: 'String',
          Required: true,
        },
        {
          Id: 'Username',
          Label: 'Access Key ID',
          Type: 'String',
          Required: true,
        },
        {
          Id: 'Password',
          Label: 'Secret Access Key',
          Type: 'String',
          Required: true,
        },
      ],
    },
  ],
};

export const integrationTemplates = {
  templates: {
    templatesList: [
      {
        subcatetories: [
          { name: 'Scheduling', description: '', id: 'scheduling' },
        ],
        description_plain:
          "Staying on top of your calendar events can be a tedious process. Use this Google Calendar-Otixo integration to notify an Otixo work space when a new event is added to the Google Calendar. You're able to post the event name, location, start and end time, and more.\n\nHow this Gogole Calendar-Otixo integration works\n\nA new event is added to a Google Calendar of your choice\n\nZapier sends a notification in one of your work spaces\n\nApps involved\n\nGoogle Calendar\n\nOtixo",
        description:
          "<p>Staying on top of your calendar events can be a tedious process. Use this Google Calendar-Otixo integration to notify an Otixo work space when a new event is added to the Google Calendar. You're able to post the event name, location, start and end time, and more.</p>\n\n<h2>How this Gogole Calendar-Otixo integration works</h2>\n\n<ol>\n<li>A new event is added to a Google Calendar of your choice</li>\n<li>Zapier sends a notification in one of your work spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Calendar</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Receive Google Calendar events in an Otixo work space',
        type: 'guided_zap',
        description_raw:
          "Staying on top of your calendar events can be a tedious process. Use this Google Calendar-Otixo integration to notify an Otixo work space when a new event is added to the Google Calendar. You're able to post the event name, location, start and end time, and more.\r\n\r\n## How this Gogole Calendar-Otixo integration works\r\n\r\n1. A new event is added to a Google Calendar of your choice\r\n2. Zapier sends a notification in one of your work spaces\r\n\r\n## Apps involved\r\n\r\n- Google Calendar\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/19219',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.png',
            description:
              "Google Calendar lets you organize your schedule and share events with co-workers and friends. With Google's free online calendar, it's easy to keep track of your daily schedule.",
            id: '36',
            title: 'Google Calendar',
            hex_color: '3680f7',
            slug: 'google-calendar',
            url: 'https://zapier.com/apps/google-calendar/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-calendar/integrations/otixo/19219/receive-google-calendar-events-in-an-otixo-work-space',
        id: '19219',
        categories: [{ name: 'Calendar', description: '', id: 'calendar' }],
        slug: 'receive-google-calendar-events-in-an-otixo-work-space',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Product Management',
            description: '',
            id: 'product-management',
          },
        ],
        description_plain:
          'Get important emails directly in your Otixo workspace. This integration allows you to share new Gmail emails with your team in an Otixo work space.\n\nHow it works\n\nZapier gets the information from new emails in Gmail inbox\n\nWhen the email is received Zapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nGmail\n\nOtixo',
        description:
          '<p>Get important emails directly in your Otixo workspace. This integration allows you to share new Gmail emails with your team in an Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information from new emails in Gmail inbox</li>\n<li>When the email is received Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Gmail</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get new Gmail emails as Otixo notifications',
        type: 'guided_zap',
        description_raw:
          'Get important emails directly in your Otixo workspace. This integration allows you to share new Gmail emails with your team in an Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information from new emails in Gmail inbox\r\n2. When the email is received Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Gmail\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/19294',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.png',
            description:
              'One of the most popular email services, Gmail keeps track of all your emails with treaded conversations, tags, and Google-powered search to locate find any message you need.',
            id: '20',
            title: 'Gmail',
            hex_color: 'db4437',
            slug: 'gmail',
            url: 'https://zapier.com/apps/gmail/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/gmail/integrations/otixo/19294/get-new-google-mails-as-otixo-notifications',
        id: '19294',
        categories: [{ name: 'Email', description: '', id: 'email' }],
        slug: 'get-new-google-mails-as-otixo-notifications',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Notify your team members on as soon as new photo or video is added on Instagram. With each new post , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for new media posted from Instagram\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nInstagram\n\nOtixo',
        description:
          '<p>Notify your team members on as soon as new photo or video is added on Instagram. With each new post , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new media posted from Instagram</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Instagram</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Send new Instagram posts to Otixo as notifications',
        type: 'guided_zap',
        description_raw:
          'Notify your team members on as soon as new photo or video is added on Instagram. With each new post , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new media posted from Instagram\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Instagram\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/19295',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/30129480e943e5b3e334394168ae16a2.png',
            description:
              'Instagram is a social network which is a fast, beautiful and fun way to share your photos with friends and family.',
            id: '894',
            title: 'Instagram',
            hex_color: '3f729b',
            slug: 'instagram',
            url: 'https://zapier.com/apps/instagram/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/instagram/integrations/otixo/19295/instagram-photos-and-videos-as-otixo-notifications',
        id: '19295',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'instagram-photos-and-videos-as-otixo-notifications',
        status: 'published',
      },
      {
        subcatetories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        description_plain:
          'Get notified in a pre-configured Otixo work space whenever a successful sale registered on PayPal. A Zapier integration to instantly notify your team on payment update together with customer contact information directly in Otixo.\n\nHow this PayPal-Otixo integration works\n\nSuccessful sales are created in PayPal\n\nZapier creates a notification in pre-selected Otixo workspace\n\nApps involved\n\nPayPal\n\nOtixo',
        description:
          '<p>Get notified in a pre-configured Otixo work space whenever a successful sale registered on PayPal. A Zapier integration to instantly notify your team on payment update together with customer contact information directly in Otixo.</p>\n\n<h2>How this PayPal-Otixo integration works</h2>\n\n<ol>\n<li>Successful sales are created in PayPal</li>\n<li>Zapier creates a notification in pre-selected Otixo workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>PayPal</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Add new successful PayPal sales as Otixo notifications',
        type: 'guided_zap',
        description_raw:
          'Get notified in a pre-configured Otixo work space whenever a successful sale registered on PayPal. A Zapier integration to instantly notify your team on payment update together with customer contact information directly in Otixo.\r\n\r\n## How this PayPal-Otixo integration works\r\n\r\n1. Successful sales are created in PayPal\r\n2. Zapier creates a notification in pre-selected Otixo workspace\r\n\r\n## Apps involved\r\n\r\n- PayPal\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/19296',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/b364fde42f6ef5e115a5d92f17c01390.png',
            description:
              'PayPal is a fast, safe way to send money, make an online payment, receive money or set up a merchant account. With over 150 million active registered accounts, PayPal helps people and businesses receive and make payments in more than 100 currencies in 203 markets.',
            id: '1',
            title: 'PayPal',
            hex_color: '005ea6',
            slug: 'paypal',
            url: 'https://zapier.com/apps/paypal/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/paypal/19296/paypal-sales-as-otixo-notifications',
        id: '19296',
        categories: [{ name: 'Ecommerce', description: '', id: 'ecommerce' }],
        slug: 'paypal-sales-as-otixo-notifications',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Use this YouTube-Otixo integration to receive a notification in an Otixo Work Space when a new video is added in an YouTube channel. This integration allows you to directly link a newly shared video in your Otixo Work Space together with additional information. Now all members of your Work Space will always know about new content.\n\nHow this Google Sheets-Otixo integration works\n\nA new video is posted in YouTube channel\n\nZapier posts a notification in one of your Otixo Work Spaces\n\nApps involved\n\nYouTube\n\nOtixo',
        description:
          '<p>Use this YouTube-Otixo integration to receive a notification in an Otixo Work Space when a new video is added in an YouTube channel. This integration allows you to directly link a newly shared video in your Otixo Work Space together with additional information. Now all members of your Work Space will always know about new content. </p>\n\n<h2>How this Google Sheets-Otixo integration works</h2>\n\n<ol>\n<li>A new video is posted in YouTube channel</li>\n<li>Zapier posts a notification in one of your Otixo Work Spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>YouTube</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post new YouTube videos as notifications in Otixo',
        type: 'guided_zap',
        description_raw:
          'Use this YouTube-Otixo integration to receive a notification in an Otixo Work Space when a new video is added in an YouTube channel. This integration allows you to directly link a newly shared video in your Otixo Work Space together with additional information. Now all members of your Work Space will always know about new content. \r\n\r\n## How this Google Sheets-Otixo integration works\r\n\r\n1. A new video is posted in YouTube channel\r\n2. Zapier posts a notification in one of your Otixo Work Spaces\r\n\r\n## Apps involved\r\n\r\n- YouTube\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/19297',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.png',
            description:
              'YouTube allows billions of people to discover, watch and share originally-created videos. YouTube provides a forum for people to connect, inform, and inspire others across the globe and acts as a distribution platform for original content creators and advertisers large and small.',
            id: '311',
            title: 'YouTube',
            hex_color: 'FF0000',
            slug: 'youtube',
            url: 'https://zapier.com/apps/youtube/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/youtube/19297/new-youtube-videos-as-notifications-in-otixo',
        id: '19297',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'new-youtube-videos-as-notifications-in-otixo',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'When you need to provide your team on new changes made in GitHub you can use this integration to create notifications in Otixo. Zapier will send the relevant information to a pre-configured Otixo Workspace.\n\nHow it works\n\nA new commit is made in GitHub\n\nZapier posts an Otixo Workspace notification in a configured space.\n\nApps involved\n\nGitHub\n\nOtixo',
        description:
          '<p>When you need to provide your team on new changes made in GitHub you can use this integration to create notifications in Otixo. Zapier will send the relevant information to a pre-configured Otixo Workspace.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new commit is made in GitHub</li>\n<li>Zapier posts an Otixo Workspace notification in a configured space.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>GitHub</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Send GitHub commits to Otixo as new notifications',
        type: 'guided_zap',
        description_raw:
          'When you need to provide your team on new changes made in GitHub you can use this integration to create notifications in Otixo. Zapier will send the relevant information to a pre-configured Otixo Workspace.\r\n\r\n## How it works\r\n\r\n1. A new commit is made in GitHub\r\n2. Zapier posts an Otixo Workspace notification in a configured space.\r\n\r\n## Apps involved\r\n\r\n- GitHub\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/19298',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/5a67ba06cd2c1c87baadac29145f18a2.png',
            description:
              'GitHub is the perfect place to share code and work collaboratively on private and open source software.',
            id: '7',
            title: 'GitHub',
            hex_color: '4183c4',
            slug: 'github',
            url: 'https://zapier.com/apps/github/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/github/integrations/otixo/19298/send-new-github-commits-to-otixo-as-new-notifications',
        id: '19298',
        categories: [
          { name: 'Developer Tools', description: '', id: 'developer-tools' },
        ],
        slug: 'send-new-github-commits-to-otixo-as-new-notifications',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Notify your collaborators on new emails directly in a pre-selected work space .This Zapier automation will post in Otixo work space when new emails are received.\n\nHow this Xero-Otixo integration works\n\nA new email is received by the custom email address you've set up with Zapier\n\nZapier posts the email message and details to the Otixo work space of your choice\n\nWhat you need\n\nOtixo account",
        description:
          "<p>Notify your collaborators on new emails directly in a pre-selected work space .This Zapier automation will post in Otixo work space when new emails are received.</p>\n\n<h2>How this Xero-Otixo integration works</h2>\n\n<ol>\n<li>A new email is received by the custom email address you've set up with Zapier</li>\n<li>Zapier posts the email message and details to the Otixo work space of your choice</li>\n</ol>\n\n<h2>What you need</h2>\n\n<ul>\n<li>Otixo account</li>\n</ul>\n",
        title: 'Get new email notifications in Otixo',
        type: 'guided_zap',
        description_raw:
          "Notify your collaborators on new emails directly in a pre-selected work space .This Zapier automation will post in Otixo work space when new emails are received.\r\n\r\n## How this Xero-Otixo integration works\r\n\r\n1. A new email is received by the custom email address you've set up with Zapier\r\n2. Zapier posts the email message and details to the Otixo work space of your choice\r\n\r\n## What you need\r\n\r\n- Otixo account",
        create_url: 'https://zapier.com/partner/embed/otixo/create/20019',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/01c965c4215e4003b6dd573484563457.png',
            description:
              'Send and receive email via a custom Zapier email address at `zapiermail.com`. This is great for triggering off of emails and sending custom emails.\r\n\r\nHave direct access to your own email servers? Check out our [IMAP](/zapbook/imap/) and [SMTP](/zapbook/smtp/) services!',
            id: '2',
            title: 'Email by Zapier',
            hex_color: '13d0ab',
            slug: 'email',
            url: 'https://zapier.com/apps/email/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/email/integrations/otixo/20019/get-new-email-notifications-in-otixo-containing-subject-sender-body-preview-and-link',
        id: '20019',
        categories: [{ name: 'Email', description: '', id: 'email' }],
        slug: 'get-new-email-notifications-in-otixo-containing-subject-sender-body-preview-and-link',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Need an easy way to notify team members in an Otixo Work Space on a job completion in the Azure App Service? This Zapier automation will post notifications in a designated Work Space every time a job finishes in the Azure App Service.\n\nHow this Azure App Service-Otixo integration works\n\nA job is finished in the Azure App Service\n\nZapier creates a notification in a pre-selected Otixo Work Space\n\nApps involved\n\nAzure App Service\n\nOtixo',
        description:
          '<p>Need an easy way to notify team members in an Otixo Work Space on a job completion in the Azure App Service? This Zapier automation will post notifications in a designated Work Space every time a job finishes in the Azure App Service.</p>\n\n<h2>How this Azure App Service-Otixo integration works</h2>\n\n<ol>\n<li>A job is finished in the Azure App Service</li>\n<li>Zapier creates a notification in a pre-selected Otixo Work Space</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Azure App Service</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get Otixo Workspace notifications when your Azure App Service finishes a job',
        type: 'guided_zap',
        description_raw:
          'Need an easy way to notify team members in an Otixo Work Space on a job completion in the Azure App Service? This Zapier automation will post notifications in a designated Work Space every time a job finishes in the Azure App Service.\r\n\r\n## How this Azure App Service-Otixo integration works\r\n\r\n1. A job is finished in the Azure App Service\r\n2. Zapier creates a notification in a pre-selected Otixo Work Space\r\n\r\n## Apps involved\r\n\r\n- Azure App Service\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20020',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/3a43f2ce2ccccea008321a1189d04d14.png',
            description:
              'Windows Azure App Service is a highly scalable website hosting solution with flexible development options including integration with Git, Mercurial and Dropbox.',
            id: '570',
            title: 'Azure Web Apps',
            hex_color: '00BAF0',
            slug: 'windows-azure-web-sites',
            url: 'https://zapier.com/apps/windows-azure-web-sites/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/windows-azure-web-sites/20020/send-otixo-messages-for-new-finished-jobs-on-azure-app-service',
        id: '20020',
        categories: [],
        slug: 'send-otixo-messages-for-new-finished-jobs-on-azure-app-service',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Want to tweet your Otixo work space notifications? Use this Otixo-Twitter integration to create a new tweet as soon as a notification is posted in a selected work space in Otixo. Let all your followers know about new and important documents!\n\nHow this Otixo-Twitter integration works\n\nA new notification is created in an Otixo work space\n\nZapier automation posts a new tweet on Twitter\n\nApps involved\n\nOtixo\n\nTwitter',
        description:
          '<p>Want to tweet your Otixo work space notifications? Use this Otixo-Twitter integration to create a new tweet as soon as a notification is posted in a selected work space in Otixo. Let all your followers know about new and important documents!</p>\n\n<h2>How this Otixo-Twitter integration works</h2>\n\n<ol>\n<li>A new notification is created in an Otixo work space</li>\n<li>Zapier automation posts a new tweet on Twitter</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Otixo</li>\n<li>Twitter</li>\n</ul>\n',
        title:
          'Create Twitter tweets from new messages in an Otixo work space channel',
        type: 'guided_zap',
        description_raw:
          'Want to tweet your Otixo work space notifications? Use this Otixo-Twitter integration to create a new tweet as soon as a notification is posted in a selected work space in Otixo. Let all your followers know about new and important documents!\r\n\r\n## How this Otixo-Twitter integration works \r\n\r\n1. A new notification is created in an Otixo work space\r\n2. Zapier automation posts a new tweet on Twitter\r\n\r\n## Apps involved\r\n\r\n- Otixo\r\n- Twitter',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20021',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
            description:
              "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
            id: '4',
            title: 'Twitter',
            hex_color: '2DAAE1',
            slug: 'twitter',
            url: 'https://zapier.com/apps/twitter/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/twitter/20021/create-tweets-on-twitter-from-new-messages-in-a-channel-on-otixo',
        id: '20021',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'create-tweets-on-twitter-from-new-messages-in-a-channel-on-otixo',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Have a shared Dropbox folder that you'd like to watch for new files? This Dropbox-Otixo integration will post a new notification in a designated Otixo Work Space when a file is added to a selected Dropbox folder. Now your team will always be on top of new files.\n\nHow this Dropbox-Otixo integration works\n\nA new file is added in Dropbox folder\n\nZapier posts a notification in one of your Otixo Work Spaces\n\nApps involved\n\nDropbox\n\nOtixo",
        description:
          "<p>Have a shared Dropbox folder that you'd like to watch for new files? This Dropbox-Otixo integration will post a new notification in a designated Otixo Work Space when a file is added to a selected Dropbox folder. Now your team will always be on top of new files. </p>\n\n<h2>How this Dropbox-Otixo integration works</h2>\n\n<ol>\n<li>A new file is added in Dropbox folder</li>\n<li>Zapier posts a notification in one of your Otixo Work Spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Dropbox</li>\n<li>Otixo</li>\n</ul>\n",
        title:
          'Get notified in Otixo when a file is added to your Dropbox folder',
        type: 'guided_zap',
        description_raw:
          "Have a shared Dropbox folder that you'd like to watch for new files? This Dropbox-Otixo integration will post a new notification in a designated Otixo Work Space when a file is added to a selected Dropbox folder. Now your team will always be on top of new files. \r\n\r\n## How this Dropbox-Otixo integration works\r\n\r\n1. A new file is added in Dropbox folder\r\n2. Zapier posts a notification in one of your Otixo Work Spaces\r\n\r\n## Apps involved\r\n\r\n- Dropbox\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/20022',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.png',
            description:
              'Dropbox lets you store your files online, sync them to all your devices, and share them easily. Get started for free, then upgrade for more space and security features.',
            id: '14',
            title: 'Dropbox',
            hex_color: '2964f6',
            slug: 'dropbox',
            url: 'https://zapier.com/apps/dropbox/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/dropbox/integrations/otixo/20022/get-notified-in-otixo-when-a-file-is-added-to-your-dropbox-folder',
        id: '20022',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-notified-in-otixo-when-a-file-is-added-to-your-dropbox-folder',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Would you like to receive a notification every time a new file is added to a Box folder? With this Box-Otixo integration, a new notification will be sent to a selected Otixo Work Space when a file has been added to the designated Box folder. You'll always know exactly what folders have been added to Box.\n\nHow this Box-Otixo integration works\n\nA new file is added in Box folder\n\nZapier posts a notification in one of your Otixo Work Spaces\n\nApps involved\n\nBox\n\nOtixo",
        description:
          "<p>Would you like to receive a notification every time a new file is added to a Box folder? With this Box-Otixo integration, a new notification will be sent to a selected Otixo Work Space when a file has been added to the designated Box folder. You'll always know exactly what folders have been added to Box.</p>\n\n<h2>How this Box-Otixo integration works</h2>\n\n<ol>\n<li>A new file is added in Box folder</li>\n<li>Zapier posts a notification in one of your Otixo Work Spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Box</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Get notified in Otixo when a file is added to your Box folder',
        type: 'guided_zap',
        description_raw:
          "Would you like to receive a notification every time a new file is added to a Box folder? With this Box-Otixo integration, a new notification will be sent to a selected Otixo Work Space when a file has been added to the designated Box folder. You'll always know exactly what folders have been added to Box.\r\n\r\n## How this Box-Otixo integration works\r\n\r\n1. A new file is added in Box folder\r\n2. Zapier posts a notification in one of your Otixo Work Spaces\r\n\r\n## Apps involved\r\n\r\n- Box\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/20023',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/cdda1a3a6ffabe2f6d0fc52bc9c2df68.png',
            description:
              'Box lets you keep all your businesses files in one place for simple online collaboration.',
            id: '73',
            title: 'Box',
            hex_color: '0081c6',
            slug: 'box',
            url: 'https://zapier.com/apps/box/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/box/integrations/otixo/20023/get-notified-in-otixo-when-a-file-is-added-to-your-box-folder',
        id: '20023',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-notified-in-otixo-when-a-file-is-added-to-your-box-folder',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Sometimes you need to know immediately when a new file is added to Google Drive. Receive a notification in your Otixo Work Space with this Google Drive-Otixo integration when new files are added to a folder in Google Drive. Now everyone in your Work Space will stay informed and in the loop.\n\nHow this Google Drive-Otixo integration works\n\nA new file is added in Google Drive folder\n\nZapier posts a notification in one of your Work Spaces\n\nApps involved\n\nGoogle Drive\n\nOtixo',
        description:
          '<p>Sometimes you need to know immediately when a new file is added to Google Drive. Receive a notification in your Otixo Work Space with this Google Drive-Otixo integration when new files are added to a folder in Google Drive. Now everyone in your Work Space will stay informed and in the loop.</p>\n\n<h2>How this Google Drive-Otixo integration works</h2>\n\n<ol>\n<li>A new file is added in Google Drive folder</li>\n<li>Zapier posts a notification in one of your Work Spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Drive</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get Otixo notifications for new Google Drive files',
        type: 'guided_zap',
        description_raw:
          'Sometimes you need to know immediately when a new file is added to Google Drive. Receive a notification in your Otixo Work Space with this Google Drive-Otixo integration when new files are added to a folder in Google Drive. Now everyone in your Work Space will stay informed and in the loop.\r\n\r\n## How this Google Drive-Otixo integration works\r\n\r\n1. A new file is added in Google Drive folder\r\n2. Zapier posts a notification in one of your Work Spaces\r\n\r\n## Apps involved\r\n\r\n- Google Drive\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20024',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/bb735e567f1a1e9e3b8b7241497c2d43.png',
            description:
              "Google Drive is Google's file sync app that lets you store all of your files online alongside your Google Docs documents, and keep them synced with all of your devices.",
            id: '69',
            title: 'Google Drive',
            hex_color: 'f4b400',
            slug: 'google-drive',
            url: 'https://zapier.com/apps/google-drive/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-drive/integrations/otixo/20024/get-otixo-notifications-for-new-google-drive-files',
        id: '20024',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-otixo-notifications-for-new-google-drive-files',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Do you have important folders that you need to be immediately notified of receiving? This Dropbox-Otixo integration will notify your team automatically with a Otixo work space notification when new folders are added in Dropbox, so the files there can be accessed immediately. Stay on top of all your documents and be more efficient!\n\nHow this Dropbox-Otixo integration works\n\nA new folder is added in Dropbox\n\nZapier posts a notification in one of your work Otixo spaces\n\nApps involved\n\nDropbox\n\nOtixo',
        description:
          '<p>Do you have important folders that you need to be immediately notified of receiving? This Dropbox-Otixo integration will notify your team automatically with a Otixo work space notification when new folders are added in Dropbox, so the files there can be accessed immediately. Stay on top of all your documents and be more efficient! </p>\n\n<h2>How this Dropbox-Otixo integration works</h2>\n\n<ol>\n<li>A new folder is added in Dropbox</li>\n<li>Zapier posts a notification in one of your work Otixo spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Dropbox</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Send messages to an Otixo channel when new Dropbox folders are made',
        type: 'guided_zap',
        description_raw:
          'Do you have important folders that you need to be immediately notified of receiving? This Dropbox-Otixo integration will notify your team automatically with a Otixo work space notification when new folders are added in Dropbox, so the files there can be accessed immediately. Stay on top of all your documents and be more efficient! \r\n\r\n## How this Dropbox-Otixo integration works\r\n\r\n1. A new folder is added in Dropbox\r\n2. Zapier posts a notification in one of your work Otixo spaces\r\n\r\n## Apps involved\r\n\r\n- Dropbox\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20025',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/13ed79eef97afd56b212ece05251b2de.png',
            description:
              'Dropbox lets you store your files online, sync them to all your devices, and share them easily. Get started for free, then upgrade for more space and security features.',
            id: '14',
            title: 'Dropbox',
            hex_color: '2964f6',
            slug: 'dropbox',
            url: 'https://zapier.com/apps/dropbox/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/dropbox/integrations/otixo/20025/send-messages-to-a-otixo-channel-when-new-dropbox-folders-are-made',
        id: '20025',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'send-messages-to-a-otixo-channel-when-new-dropbox-folders-are-made',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Message your team automatically with a Otixo work space notification when new file is added in OneDrive with this OneDrive- Otixo integration. File information will be copied and accessible directly in your selected space. Now you'll never miss a folder again!\n\nHow this OneDrive-Otixo integration works\n\nA new folder is added in OneDrive\n\nZapier posts a notification in one of your Otixo work spaces\n\nApps involved\n\nOneDrive\n\nOtixo",
        description:
          "<p>Message your team automatically with a Otixo work space notification when new file is added in OneDrive with this OneDrive- Otixo integration. File information will be copied and accessible directly in your selected space. Now you'll never miss a folder again!</p>\n\n<h2>How this OneDrive-Otixo integration works</h2>\n\n<ol>\n<li>A new folder is added in OneDrive</li>\n<li>Zapier posts a notification in one of your Otixo work spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>OneDrive</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Send new One Drive files to Otixo as new messages',
        type: 'guided_zap',
        description_raw:
          "Message your team automatically with a Otixo work space notification when new file is added in OneDrive with this OneDrive- Otixo integration. File information will be copied and accessible directly in your selected space. Now you'll never miss a folder again!\r\n\r\n## How this OneDrive-Otixo integration works\r\n\r\n1. A new folder is added in OneDrive\r\n2. Zapier posts a notification in one of your Otixo work spaces\r\n\r\n## Apps involved\r\n\r\n- OneDrive\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/20026',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/e67867e9f6f5c089e243dad54467ad33.png',
            description:
              "OneDrive, Microsoft's file sync app formerly known as SkyDrive, lets you store your files online, edit Office documents in the free Office Web Apps, and easily access your online files from your PC.",
            id: '77',
            title: 'OneDrive',
            hex_color: '094ab2',
            slug: 'onedrive',
            url: 'https://zapier.com/apps/onedrive/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/onedrive/integrations/otixo/20026/send-new-one-drive-files-to-otixo-as-new-messages',
        id: '20026',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'send-new-one-drive-files-to-otixo-as-new-messages',
        status: 'published',
      },
      {
        subcatetories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        description_plain:
          'Notify every Otixo work space collaborator when a new spreadsheet is added in Google Sheets. This Google Sheets-Otixo integration allows you to directly link a newly created Google Sheet in your Otixo work space together with all the information you need.\n\nHow this Google Sheets-Otixo integration works\n\nA new Spreadsheet is created in Google Sheets\n\nZapier posts a notification in one of your work spaces\n\nApps involved\n\nGoogle Sheets\n\nOtixo',
        description:
          '<p>Notify every Otixo work space collaborator when a new spreadsheet is added in Google Sheets. This Google Sheets-Otixo integration allows you to directly link a newly created Google Sheet in your Otixo work space together with all the information you need. </p>\n\n<h2>How this Google Sheets-Otixo integration works</h2>\n\n<ol>\n<li>A new Spreadsheet is created in Google Sheets</li>\n<li>Zapier posts a notification in one of your work spaces</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Sheets</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Announce newly created Google Sheets in Otixo work spaces',
        type: 'guided_zap',
        description_raw:
          'Notify every Otixo work space collaborator when a new spreadsheet is added in Google Sheets. This Google Sheets-Otixo integration allows you to directly link a newly created Google Sheet in your Otixo work space together with all the information you need. \r\n\r\n## How this Google Sheets-Otixo integration works\r\n\r\n1. A new Spreadsheet is created in Google Sheets\r\n2. Zapier posts a notification in one of your work spaces\r\n\r\n## Apps involved\r\n\r\n- Google Sheets\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20027',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/e37713ab22d82f777575279db97cd805.png',
            description:
              'Create, edit, and share spreadsheets wherever you are with Google Sheets, and get automated insights from your data.',
            id: '1498',
            title: 'Google Sheets',
            hex_color: '00a256',
            slug: 'google-sheets',
            url: 'https://zapier.com/apps/google-sheets/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-sheets/integrations/otixo/20027/share-new-google-sheets-spreadsheets-to-otixo',
        id: '20027',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'share-new-google-sheets-spreadsheets-to-otixo',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Make sure important work space messages get disseminated as needed, even if they start on chat, with this Otixo-Gmail integration. Whenever a new notification is posted, an email will be sent out from Gmail automatically to selected e-mail addresses, including the message contents and any additional information you want your recipient(s) to know.\n\nHow this Otixo-Gmail integration works\n\nA new message is posted in an Otixo work space\n\nZapier automation sends a new email from Gmail\n\nApps involved\n\nOtixo\n\nGmail',
        description:
          '<p>Make sure important work space messages get disseminated as needed, even if they start on chat, with this Otixo-Gmail integration. Whenever a new notification is posted, an email will be sent out from Gmail automatically to selected e-mail addresses, including the message contents and any additional information you want your recipient(s) to know.</p>\n\n<h2>How this Otixo-Gmail integration works</h2>\n\n<ol>\n<li>A new message is posted in an Otixo work space</li>\n<li>Zapier automation sends a new email from Gmail</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Otixo</li>\n<li>Gmail</li>\n</ul>\n',
        title: 'Send emails from Gmail with new Otixo work space messages',
        type: 'guided_zap',
        description_raw:
          'Make sure important work space messages get disseminated as needed, even if they start on chat, with this Otixo-Gmail integration. Whenever a new notification is posted, an email will be sent out from Gmail automatically to selected e-mail addresses, including the message contents and any additional information you want your recipient(s) to know.\r\n\r\n## How this Otixo-Gmail integration works \r\n\r\n1. A new message is posted in an Otixo work space\r\n2. Zapier automation sends a new email from Gmail\r\n\r\n## Apps involved\r\n\r\n- Otixo\r\n- Gmail',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20028',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.png',
            description:
              'One of the most popular email services, Gmail keeps track of all your emails with treaded conversations, tags, and Google-powered search to locate find any message you need.',
            id: '20',
            title: 'Gmail',
            hex_color: 'db4437',
            slug: 'gmail',
            url: 'https://zapier.com/apps/gmail/integrations',
          },
        ],
        url: 'https://zapier.com/apps/gmail/integrations/otixo/20028/send-emails-from-gmail-with-new-otixo-channel-messages',
        id: '20028',
        categories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        slug: 'send-emails-from-gmail-with-new-otixo-channel-messages',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Watching Twitter for new mentions about your company or product can time consuming, keep your team members informed directly in an Otixo Workspace. Digest by Zapier will store a log of these mentions and post a notification summary in Otixo Workspace on a schedule you choose - daily, weekly, or monthly - so you can see all recent mentions at a glance.\n\nHow It works\n\nNew mention on Twitter\n\nZapier's Digest app stores a running log of those new followers\n\nAt a time you set, your digest is sent directly to pre-selected Otixo Workspace\n\nApps involved\n\nTwitter\n\nDigest by Zapier\n\nOtixo",
        description:
          "<p>Watching Twitter for new mentions about your company or product can time consuming, keep your team members informed directly in an Otixo Workspace. Digest by Zapier will store a log of these mentions and post a notification summary in Otixo Workspace on a schedule you choose - daily, weekly, or monthly - so you can see all recent mentions at a glance.</p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>New mention on Twitter</li>\n<li>Zapier's Digest app stores a running log of those new followers</li>\n<li>At a time you set, your digest is sent directly to pre-selected Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Twitter</li>\n<li>Digest by Zapier</li>\n<li>Otixo</li>\n</ul>\n",
        title:
          'Get a digest of Otixo notifications for new Twitter mentions on a daily, weekly, or monthly schedule',
        type: 'guided_zap',
        description_raw:
          "Watching Twitter for new mentions about your company or product can time consuming, keep your team members informed directly in an Otixo Workspace. Digest by Zapier will store a log of these mentions and post a notification summary in Otixo Workspace on a schedule you choose - daily, weekly, or monthly - so you can see all recent mentions at a glance.\r\n\r\n## How It works\r\n1. New mention on Twitter\r\n2. Zapier's Digest app stores a running log of those new followers\r\n3. At a time you set, your digest is sent directly to pre-selected Otixo Workspace\r\n\r\n\r\n## Apps involved\r\n\r\n- Twitter\r\n- Digest by Zapier\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/20029',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
            description:
              "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
            id: '4',
            title: 'Twitter',
            hex_color: '2DAAE1',
            slug: 'twitter',
            url: 'https://zapier.com/apps/twitter/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/c23097708bd08ff36ffc8b9293e77465_2.png',
            description:
              'Digest captures info from multiple events in your workflows and collects them in a single easily consumed summary that can be sent to any app.',
            id: '4436',
            title: 'Digest by Zapier',
            hex_color: 'ff4a00',
            slug: 'digest',
            url: 'https://zapier.com/apps/digest/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/twitter/20029/get-otixo-notifications-for-new-twitter-mentions-and-get-an-email-summary-of-those-mentions-daily-weekly-or-monthly',
        id: '20029',
        categories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        slug: 'get-otixo-notifications-for-new-twitter-mentions-and-get-an-email-summary-of-those-mentions-daily-weekly-or-monthly',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Get important emails directly in your Otixo workspace. This integration allows you to share new Gmail email threads with your team in an Otixo work space.\n\nHow it works\n\nZapier gets the information from new thread in Gmail\n\nWhen the thread is created Zapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nGmail\n\nOtixo',
        description:
          '<p>Get important emails directly in your Otixo workspace. This integration allows you to share new Gmail email threads with your team in an Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information from new thread in Gmail</li>\n<li>When the thread is created Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Gmail</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get Otixo notifications from new Gmail threads',
        type: 'guided_zap',
        description_raw:
          'Get important emails directly in your Otixo workspace. This integration allows you to share new Gmail email threads with your team in an Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information from new thread in Gmail\r\n2. When the thread is created Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Gmail\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20400',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/0f5a0d7ee18ced262570a8c831e1a04a.png',
            description:
              'One of the most popular email services, Gmail keeps track of all your emails with treaded conversations, tags, and Google-powered search to locate find any message you need.',
            id: '20',
            title: 'Gmail',
            hex_color: 'db4437',
            slug: 'gmail',
            url: 'https://zapier.com/apps/gmail/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/gmail/integrations/otixo/20400/get-otixo-notification-for-new-gmail-threads',
        id: '20400',
        categories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        slug: 'get-otixo-notification-for-new-gmail-threads',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Forward emails to Zapier and receive notifications directly in your Otixo workspace. This integration allows you to customize the content received in your Otixo Workspace based on new mails.\n\nHow it works\n\nZapier gets the information from new email\n\nWhen the email is received Zapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nEmail by Zapier\n\nOtixo',
        description:
          '<p>Forward emails to Zapier and receive notifications directly in your Otixo workspace. This integration allows you to customize the content received in your Otixo Workspace based on new mails.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information from new email</li>\n<li>When the email is received Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Email by Zapier</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Send emails into Otixo Workspace',
        type: 'guided_zap',
        description_raw:
          'Forward emails to Zapier and receive notifications directly in your Otixo workspace. This integration allows you to customize the content received in your Otixo Workspace based on new mails.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information from new email\r\n2. When the email is received Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Email by Zapier\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20589',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/01c965c4215e4003b6dd573484563457.png',
            description:
              'Send and receive email via a custom Zapier email address at `zapiermail.com`. This is great for triggering off of emails and sending custom emails.\r\n\r\nHave direct access to your own email servers? Check out our [IMAP](/zapbook/imap/) and [SMTP](/zapbook/smtp/) services!',
            id: '2',
            title: 'Email by Zapier',
            hex_color: '13d0ab',
            slug: 'email',
            url: 'https://zapier.com/apps/email/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/email/integrations/otixo/20589/send-mails-into-otixo-work-space',
        id: '20589',
        categories: [{ name: 'Email', description: '', id: 'email' }],
        slug: 'send-mails-into-otixo-work-space',
        status: 'published',
      },
      {
        subcatetories: [
          { name: 'Scheduling', description: '', id: 'scheduling' },
        ],
        description_plain:
          'Get weekly reminders with pre-defined content in your Otixo work space. This integration allows you to schedule a reminder to a work space defined by you.\n\nHow it works\n\nSchedule by Zapier is triggered weekly with content defined by you\n\nA new Otixo work space notification is created by Zapier\n\nApps involved\n\nSchedule by Zapier\n\nOtixo',
        description:
          '<p>Get weekly reminders with pre-defined content in your Otixo work space. This integration allows you to schedule a reminder to a work space defined by you.</p>\n\n<p>How it works</p>\n\n<ol>\n<li>Schedule by Zapier is triggered weekly with content defined by you</li>\n<li>A new Otixo work space notification is created by Zapier </li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Schedule by Zapier</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get Weekly reminders in Otixo',
        type: 'guided_zap',
        description_raw:
          'Get weekly reminders with pre-defined content in your Otixo work space. This integration allows you to schedule a reminder to a work space defined by you.\r\n\r\nHow it works\r\n\r\n1. Schedule by Zapier is triggered weekly with content defined by you\r\n2. A new Otixo work space notification is created by Zapier \r\n\r\n## Apps involved\r\n\r\n- Schedule by Zapier\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20590',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/9abbda159e8d9cec0e5eaa8ff1fd5205.png',
            description:
              "Schedule is a native Zapier app you can use for recurring tasks. Trigger an action every day of the month, every day of the week or every single day. You can also select the hour of the day. If you're a developer, this is similar to a crontab, cronjob or cron.",
            id: '355',
            title: 'Schedule by Zapier',
            hex_color: 'fc1c74',
            slug: 'schedule',
            url: 'https://zapier.com/apps/schedule/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/schedule/20590/get-weekly-reminders-in-otixo',
        id: '20590',
        categories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        slug: 'get-weekly-reminders-in-otixo',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Notify all collaborators in Otixo when a new note is created in Evernote.This Zapier automation will post in a pre-defined work space in Otixo when new note is added to Evernote.\n\nHow this Evernote-Otixo integration works\n\nA new note is added to Evernote\n\nZapier automatically adds a new notification to specified Otixo work space\n\nApps involved\n\nEvernote\n\nOtixo',
        description:
          '<p>Notify all collaborators in Otixo when a new note is created in Evernote.This Zapier automation will post in a pre-defined work space in Otixo when new note is added to Evernote.</p>\n\n<h2>How this Evernote-Otixo integration works</h2>\n\n<ol>\n<li>A new note is added to Evernote</li>\n<li>Zapier automatically adds a new notification to specified Otixo work space</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Evernote</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get a Otixo notifications when new notes are added in Еvernote',
        type: 'guided_zap',
        description_raw:
          'Notify all collaborators in Otixo when a new note is created in Evernote.This Zapier automation will post in a pre-defined work space in Otixo when new note is added to Evernote.\r\n\r\n## How this Evernote-Otixo integration works\r\n\r\n1. A new note is added to Evernote\r\n2. Zapier automatically adds a new notification to specified Otixo work space\r\n\r\n## Apps involved\r\n\r\n- Evernote\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20591',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/e0a30d6651780fba36903a9af2b768b6.png',
            description:
              "Evernote is a powerful note taking application that makes it easy to capture ideas, images, contacts, and anything else you need to remember. Bring your life's work together in one digital workspace\u2014available on all major mobile platforms and devices.\r\n\r\n<small>Using Evernote for work? [See Evernote Business](/zapbook/evernote-business/).</small>",
            id: '15',
            title: 'Evernote',
            hex_color: '6fb536',
            slug: 'evernote',
            url: 'https://zapier.com/apps/evernote/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/evernote/integrations/otixo/20591/get-a-otixo-notifications-when-new-notes-are-added-to-evernote',
        id: '20591',
        categories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        slug: 'get-a-otixo-notifications-when-new-notes-are-added-to-evernote',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Facebook provides a great way of generating leads, but following up depends on getting the information to you team. Using this automation Zapier will create Otixo Workspace notifications with the information on new leads.\n\nHow this Facebook Lead Ads-Otixo integration works\n\nA new lead is captured in Facebook Lead Ads\n\nZapier creates a new notification in a pre-selected Otixo Workspace\n\nApps involved\n\nFacebook Lead Ads\n\nOtixo',
        description:
          '<p>Facebook provides a great way of generating leads, but following up depends on getting  the information to you team. Using this automation Zapier will create Otixo Workspace notifications with the information on new leads.</p>\n\n<h2>How this Facebook Lead Ads-Otixo integration works</h2>\n\n<ol>\n<li>A new lead is captured in Facebook Lead Ads</li>\n<li>Zapier creates a new notification in a pre-selected Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Lead Ads</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get Otixo notifications of new Facebook Lead Ads leads',
        type: 'guided_zap',
        description_raw:
          'Facebook provides a great way of generating leads, but following up depends on getting  the information to you team. Using this automation Zapier will create Otixo Workspace notifications with the information on new leads.\r\n\r\n## How this Facebook Lead Ads-Otixo integration works\r\n\r\n1. A new lead is captured in Facebook Lead Ads\r\n2. Zapier creates a new notification in a pre-selected Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- Facebook Lead Ads\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20592',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773_2.png',
            description:
              'Facebook lead ads make signing up for business information easy for people and more valuable for businesses. The Facebook lead ad app is useful for marketers who want to automate actions on their leads.',
            id: '3535',
            title: 'Facebook Lead Ads',
            hex_color: '3b5998',
            slug: 'facebook-lead-ads',
            url: 'https://zapier.com/apps/facebook-lead-ads/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-lead-ads/integrations/otixo/20592/get-otixo-notifications-with-digests-of-new-facebook-lead-ads-leads',
        id: '20592',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'get-otixo-notifications-with-digests-of-new-facebook-lead-ads-leads',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Inform your team members on as soon as a new tweet is posted from your account. With each new tweet , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for new tweet\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nTwitter\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as a new tweet is posted from your account. With each new tweet , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new tweet</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Twitter</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Share your tweets to Otixo',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as a new tweet is posted from your account. With each new tweet , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new tweet\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Twitter\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20593',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
            description:
              "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
            id: '4',
            title: 'Twitter',
            hex_color: '2DAAE1',
            slug: 'twitter',
            url: 'https://zapier.com/apps/twitter/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/twitter/20593/share-your-tweets-excluding-replies-to-otixo',
        id: '20593',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'share-your-tweets-excluding-replies-to-otixo',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Inform your team members on as soon as you like a video from your Vimeo account. With each new like, Zapier will automatically send a notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for new like on Vimeo\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nVimeo\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as you like a video from your Vimeo account. With each new like, Zapier will automatically send a notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new like on Vimeo</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Vimeo</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Send notifications on Otixo for new liked videos on Vimeo',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as you like a video from your Vimeo account. With each new like, Zapier will automatically send a notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new like on Vimeo\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Vimeo\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20594',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/94fc1c00a8192fc59c6738693389f872.png',
            description:
              'Vimeo is a great tool for storing and distributing video content. It comes with powerful privacy and group features.',
            id: '918',
            title: 'Vimeo',
            hex_color: '1AB7EA',
            slug: 'vimeo',
            url: 'https://zapier.com/apps/vimeo/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/vimeo/20594/send-notifications-on-otixo-for-new-liked-videos-on-vimeo',
        id: '20594',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'send-notifications-on-otixo-for-new-liked-videos-on-vimeo',
        status: 'published',
      },
      {
        subcatetories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        description_plain:
          'Inform your team members on as soon as row in Google Sheets is added or modified. With each row modification , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for modified row in Google Sheets\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nGoogle Sheets\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as row in Google Sheets is added or modified. With each row modification , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for modified row in Google Sheets</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Sheets</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Post a new message to Otixo whenever a row in Google Sheets is updated',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as row in Google Sheets is added or modified. With each row modification , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for modified row in Google Sheets\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Google Sheets\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20595',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/e37713ab22d82f777575279db97cd805.png',
            description:
              'Create, edit, and share spreadsheets wherever you are with Google Sheets, and get automated insights from your data.',
            id: '1498',
            title: 'Google Sheets',
            hex_color: '00a256',
            slug: 'google-sheets',
            url: 'https://zapier.com/apps/google-sheets/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-sheets/integrations/otixo/20595/post-a-new-message-to-otixo-whenever-a-document-in-google-sheets-is-updated',
        id: '20595',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'post-a-new-message-to-otixo-whenever-a-document-in-google-sheets-is-updated',
        status: 'published',
      },
      {
        subcatetories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        description_plain:
          'Notify your team members on as soon as new row is inserted in MySQL. With each new row , Zapier will automatically send notification to Otixo work space.\n\nHow it works\n\nZapier gets the information for new row inserted in MySQL\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nMySQL\n\nOtixo',
        description:
          '<p>Notify your team members on as soon as new row is inserted in MySQL. With each new row , Zapier will automatically send notification to Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new row inserted in MySQL</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>MySQL</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post new MySQL rows as Otixo notifications',
        type: 'guided_zap',
        description_raw:
          'Notify your team members on as soon as new row is inserted in MySQL. With each new row , Zapier will automatically send notification to Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new row inserted in MySQL\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- MySQL\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20596',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/c23b6298d70e5cc925a8e795e48b6898.png',
            description:
              'MySQL is a premier database used in millions of production environments around the world. Easily plug your MySQL database into any web service.',
            id: '159',
            title: 'MySQL',
            hex_color: 'e87912',
            slug: 'mysql',
            url: 'https://zapier.com/apps/mysql/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/mysql/integrations/otixo/20596/post-new-mysql-rows-as-otixo-messages',
        id: '20596',
        categories: [{ name: 'Databases', description: '', id: 'databases' }],
        slug: 'post-new-mysql-rows-as-otixo-messages',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Notify your team members when Google Calendar event starts. With each starting event , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for starting event on Google Calendar\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nGoogle Calendar\n\nOtixo',
        description:
          '<p>Notify your team members when Google Calendar event starts. With each starting event , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for starting event on Google Calendar </li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Calendar </li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Receive Otixo notifications when Google Calendar events start',
        type: 'guided_zap',
        description_raw:
          'Notify your team members when Google Calendar event starts. With each starting event , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for starting event on Google Calendar \r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Google Calendar \r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20597',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.png',
            description:
              "Google Calendar lets you organize your schedule and share events with co-workers and friends. With Google's free online calendar, it's easy to keep track of your daily schedule.",
            id: '36',
            title: 'Google Calendar',
            hex_color: '3680f7',
            slug: 'google-calendar',
            url: 'https://zapier.com/apps/google-calendar/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-calendar/integrations/otixo/20597/post-starting-google-calendar-events-to-a-otixo-channel',
        id: '20597',
        categories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        slug: 'post-starting-google-calendar-events-to-a-otixo-channel',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'With each new Twitter follower, Zapier will automatically send notification to a designated Otixo work space. Inform your team members on new social interaction opportunities.\n\nHow it works\n\nZapier gets the information from on new follower in Twitter\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nTwitter\n\nOtixo',
        description:
          '<p>With each new Twitter follower, Zapier will automatically send notification to a designated Otixo work space. Inform your team members on new social interaction opportunities.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information from on new follower in Twitter</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Twitter</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get Otixo notification for new Twitter followers',
        type: 'guided_zap',
        description_raw:
          'With each new Twitter follower, Zapier will automatically send notification to a designated Otixo work space. Inform your team members on new social interaction opportunities.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information from on new follower in Twitter\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Twitter\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20598',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
            description:
              "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
            id: '4',
            title: 'Twitter',
            hex_color: '2DAAE1',
            slug: 'twitter',
            url: 'https://zapier.com/apps/twitter/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/twitter/20598/get-otixo-notification-for-new-twitter-followers',
        id: '20598',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'get-otixo-notification-for-new-twitter-followers',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Notify your team members on as soon as new form response is submitted on LinkedIn Lead Gen Form. With each new lead, Zapier will automatically send notification to Otixo work space.\n\nHow it works\n\nZapier gets the information for new form response on LinkedIn Lead Gen Form\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nLinkedIn Lead Gen Form\n\nOtixo',
        description:
          '<p>Notify your team members on as soon as new form response is submitted on LinkedIn Lead Gen Form. With each new lead, Zapier will automatically send notification to Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new form response on LinkedIn Lead Gen Form</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>LinkedIn Lead Gen Form</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post new LinkedIn Lead Gen Form leads to a Otixo channel',
        type: 'guided_zap',
        description_raw:
          'Notify your team members on as soon as new form response is submitted on LinkedIn Lead Gen Form. With each new lead, Zapier will automatically send notification to Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new form response on LinkedIn Lead Gen Form\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- LinkedIn Lead Gen Form\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20599',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/82e76b99c17c252653cee811b4e46123.png',
            description:
              'LinkedIn Lead Gen Forms allow marketers to collect even more quality leads from their ads on LinkedIn with seamless pre-filled forms.',
            id: '6105',
            title: 'LinkedIn Lead Gen Forms',
            hex_color: '0077b5',
            slug: 'linkedin-lead-gen-forms',
            url: 'https://zapier.com/apps/linkedin-lead-gen-forms/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/linkedin-lead-gen-forms/integrations/otixo/20599/post-new-linkedin-lead-gen-form-leads-to-a-otixo-channel',
        id: '20599',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'post-new-linkedin-lead-gen-form-leads-to-a-otixo-channel',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Inform your team members on as soon as new post is added to your Facebook Page. With each new post , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for new post on Facebook Pages\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nFacebook Pages\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as new post is added to your Facebook Page. With each new post , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new post on Facebook Pages</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Pages</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Share new Facebook Pages posts in Otixo',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as new post is added to your Facebook Page. With each new post , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new post on Facebook Pages\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Facebook Pages\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20600',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773.png',
            description:
              "Facebook Pages lets you connect with your customers, fans and followers on the world's largest social network. Share relevant content to engage people who Like your page, create events to build and grow your community, and run a Facebook ad campaign by targeting location, age and more.\r\n\r\n<small>Need to use your personal Facebook account? [See Facebook](https://zapier.com/zapbook/facebook/)</small>",
            id: '17',
            title: 'Facebook Pages',
            hex_color: '3b5998',
            slug: 'facebook-pages',
            url: 'https://zapier.com/apps/facebook-pages/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-pages/integrations/otixo/20600/share-new-facebook-pages-posts-in-otixo',
        id: '20600',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'share-new-facebook-pages-posts-in-otixo',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'Notify your team members on new commit in GitHub. With each new commit , Zapier will automatically send notification to Otixo work space.\n\nHow it works\n\nZapier gets the information for new commit in GitHub\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nGitHub\n\nOtixo',
        description:
          '<p>Notify your team members on new commit in GitHub. With each new commit , Zapier will automatically send notification to Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new commit in GitHub</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>GitHub</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Send new GitHub commits to Otixo as work space notifications',
        type: 'guided_zap',
        description_raw:
          'Notify your team members on new commit in GitHub. With each new commit , Zapier will automatically send notification to Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new commit in GitHub\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- GitHub\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20601',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/5a67ba06cd2c1c87baadac29145f18a2.png',
            description:
              'GitHub is the perfect place to share code and work collaboratively on private and open source software.',
            id: '7',
            title: 'GitHub',
            hex_color: '4183c4',
            slug: 'github',
            url: 'https://zapier.com/apps/github/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/github/integrations/otixo/20601/send-new-github-commits-to-otixo-as-work-space-notifications',
        id: '20601',
        categories: [
          {
            name: 'Product Management',
            description: '',
            id: 'product-management',
          },
        ],
        slug: 'send-new-github-commits-to-otixo-as-work-space-notifications',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Looking to keep a record of all notifications posted in an Otixo works pace? This integration allows you to store all notifications created in an Otixo works pace as new rows in Excel - enabling sorting, grouping, and filtering in a familiar way.\n\nHow this Otixo-Excel integration works\n\nA new notification is posted in an Otixo works pace\n\nZapier automation creates an Excel spreadsheet row containing the notification details\n\nApps involved\n\nOtixo\n\nExcel',
        description:
          '<p>Looking to keep a record of all notifications posted in an Otixo works pace? This integration allows you to store all notifications created in an Otixo works pace as new rows in Excel - enabling sorting, grouping, and filtering in a familiar way.</p>\n\n<h2>How this Otixo-Excel integration works</h2>\n\n<ol>\n<li>A new notification is posted in an Otixo works pace</li>\n<li>Zapier automation creates an Excel spreadsheet row containing the notification details</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Otixo</li>\n<li>Excel</li>\n</ul>\n',
        title: 'Add new Otixo work space notifications to an Excel spreadsheet',
        type: 'guided_zap',
        description_raw:
          'Looking to keep a record of all notifications posted in an Otixo works pace? This integration allows you to store all notifications created in an Otixo works pace as new rows in Excel - enabling sorting, grouping, and filtering in a familiar way.\r\n\r\n## How this Otixo-Excel integration works\r\n\r\n1. A new notification is posted in an Otixo works pace\r\n2. Zapier automation creates an Excel spreadsheet row containing the notification details\r\n\r\n## Apps involved\r\n\r\n- Otixo\r\n- Excel',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20602',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/296388d714e0dcd78105c9b165ca751e.png',
            description:
              "Microsoft's Excel is a spreadsheet application used by millions of users across the world. It is part of the Office ecosystem and is considered the industry standard for spreadsheets. \r\n\r\nOnly files on OneDrive for Business (available with Office 365 and Sharepoint server) are supported at this time with Zapier.",
            id: '2901',
            title: 'Excel',
            hex_color: '207245',
            slug: 'excel',
            url: 'https://zapier.com/apps/excel/integrations',
          },
        ],
        url: 'https://zapier.com/apps/excel/integrations/otixo/20602/add-new-otixo-work-space-notifications-to-an-excel-spreadsheet',
        id: '20602',
        categories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        slug: 'add-new-otixo-work-space-notifications-to-an-excel-spreadsheet',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Notify your team members on as soon as new sales order is added in Jasmin. With each new order, Zapier will automatically send a notification to Otixo work space.\n\nHow it works\n\nZapier gets the information for new sales order on Jasmin\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nJasmin\n\nOtixo',
        description:
          '<p>Notify your team members on as soon as new sales order is added in Jasmin. With each new order, Zapier will automatically send a notification to Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new sales order on Jasmin</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Jasmin</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Post Otixo work space notifications for new sales orders on Jasmin',
        type: 'guided_zap',
        description_raw:
          'Notify your team members on as soon as new sales order is added in Jasmin. With each new order, Zapier will automatically send a notification to Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new sales order on Jasmin\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Jasmin\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20603',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/6e206aa64c1facba8b696b204da247ae_3.png',
            description:
              'Jasmin is a Cloud Management Software by PRIMAVERA BSS that allows you to track your business any time and anywhere. Jasmin allows you to manage sales, payments, stocks, receipts, orders, etc., offering you KPIs and tips for your business.',
            id: '7004',
            title: 'Jasmin',
            hex_color: null,
            slug: 'jasmin',
            url: 'https://zapier.com/apps/jasmin/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/jasmin/integrations/otixo/20603/post-otixo-work-space-notifications-for-new-sales-orders-on-jasmin',
        id: '20603',
        categories: [
          { name: 'Management Tools', description: '', id: 'management-tools' },
        ],
        slug: 'post-otixo-work-space-notifications-for-new-sales-orders-on-jasmin',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'Create GitHub issues by sending Otixo work space notifications. This automation will allow you to add all notifications created in an Otixo work space as issues on a GitHub project. That way, you can save time to focus on your most important work.\n\nHow this Otixo-GitHub integration works\n\nA new notification is posted in an Otixo work space\n\nZapier integration posts the notification as GitHub issue\n\nApps involved\n\nOtixo\n\nGitHub',
        description:
          '<p>Create GitHub issues by sending Otixo work space notifications. This automation will allow you to add all notifications created in an Otixo work space as issues on a GitHub project. That way, you can save time to focus on your most important work. </p>\n\n<h2>How this Otixo-GitHub integration works</h2>\n\n<ol>\n<li>A new notification is posted in an Otixo work space</li>\n<li>Zapier integration posts the notification as GitHub issue</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Otixo</li>\n<li>GitHub</li>\n</ul>\n',
        title: 'Create GitHub issues from new Otixo work space notifications',
        type: 'guided_zap',
        description_raw:
          'Create GitHub issues by sending Otixo work space notifications. This automation will allow you to add all notifications created in an Otixo work space as issues on a GitHub project. That way, you can save time to focus on your most important work. \r\n\r\n## How this Otixo-GitHub integration works\r\n\r\n1. A new notification is posted in an Otixo work space\r\n2. Zapier integration posts the notification as GitHub issue\r\n\r\n## Apps involved\r\n\r\n- Otixo\r\n- GitHub',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20604',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/5a67ba06cd2c1c87baadac29145f18a2.png',
            description:
              'GitHub is the perfect place to share code and work collaboratively on private and open source software.',
            id: '7',
            title: 'GitHub',
            hex_color: '4183c4',
            slug: 'github',
            url: 'https://zapier.com/apps/github/integrations',
          },
        ],
        url: 'https://zapier.com/apps/github/integrations/otixo/20604/create-github-issues-from-new-otixo-work-space-notifications',
        id: '20604',
        categories: [
          {
            name: 'Product Management',
            description: '',
            id: 'product-management',
          },
        ],
        slug: 'create-github-issues-from-new-otixo-work-space-notifications',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'Notify your team on new payments directly in a pre-selected work space .This Zapier automation will post in Otixo work space when new payment is added to Xero.\n\nHow this Xero-Otixo integration works\n\nA new payment is added to Xero\n\nZapier automatically adds a new notification to specified Otixo work space\n\nApps involved\n\nXero\n\nOtixo',
        description:
          '<p>Notify your team on new payments directly in a pre-selected work space .This Zapier automation will post in Otixo work space when new payment is added to Xero.</p>\n\n<h2>How this Xero-Otixo integration works</h2>\n\n<ol>\n<li>A new payment is added to Xero</li>\n<li>Zapier automatically adds a new notification to specified Otixo work space</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Xero</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get notifications in Otixo when new payments are added in Xero',
        type: 'guided_zap',
        description_raw:
          'Notify your team on new payments directly in a pre-selected work space .This Zapier automation will post in Otixo work space when new payment is added to Xero.\r\n\r\n## How this Xero-Otixo integration works\r\n\r\n1. A new payment is added to Xero\r\n2. Zapier automatically adds a new notification to specified Otixo work space\r\n\r\n## Apps involved\r\n\r\n- Xero\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20605',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ef74f1fda109e37e63797cfdf182721b.png',
            description:
              'Xero is an accounting app that lets you track businesses expenses and accounting with intuitive mobile apps and web interfaces for point-of-sale, ecommerce, time-tracking, and more.\r\n\r\n<img style="" alt="Xero Connected App" src="https://zapier.cachefly.net/storage/photos/1e58f97c1db1371f2e391222518b622b.png" />',
            id: '66',
            title: 'Xero',
            hex_color: '00b7e3',
            slug: 'xero',
            url: 'https://zapier.com/apps/xero/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/xero/20605/get-a-summary-of-new-payments-from-xero-delivered-to-you-via-otixo-daily-weekly-or-monthly',
        id: '20605',
        categories: [
          {
            name: 'Product Management',
            description: '',
            id: 'product-management',
          },
        ],
        slug: 'get-a-summary-of-new-payments-from-xero-delivered-to-you-via-otixo-daily-weekly-or-monthly',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Inform your team members on as soon as new post is added to your Tumblr blog. With each new post , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for new post on Tumblr\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nTumblr\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as new post is added to your Tumblr blog. With each new post , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new post on Tumblr</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Tumblr</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Share new posts on your Tumblr blog to Otixo',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as new post is added to your Tumblr blog. With each new post , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new post on Tumblr\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Tumblr\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20606',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/11aef07e5cc45209f3e8ea44d1ca5a6c.png',
            description:
              'Tumblr is a microblogging platform and social networking website which allows users to post multimedia and other content to a short-form blog.',
            id: '936',
            title: 'Tumblr',
            hex_color: '2C4762',
            slug: 'tumblr',
            url: 'https://zapier.com/apps/tumblr/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/tumblr/20606/share-new-posts-on-your-tumblr-blog-to-otixo',
        id: '20606',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'share-new-posts-on-your-tumblr-blog-to-otixo',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Inform your team members on as soon as a new track is added by a user on SoundCloud. With each new post , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for new track from SoundCloud user that is followed by you\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nSoundCloud\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as a new track is added by a user on SoundCloud. With each new post , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new track from SoundCloud user that is followed by you</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>SoundCloud</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Send new tracks from SoundCloud users as a notification in Otixo',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as a new track is added by a user on SoundCloud. With each new post , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new track from SoundCloud user that is followed by you\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- SoundCloud\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20607',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/e833af1046e989b5d65833e955b34d4f.png',
            description:
              "The world's largest community of artists, bands, podcasters and creators of music and audio.",
            id: '954',
            title: 'SoundCloud',
            hex_color: 'fc561e',
            slug: 'soundcloud',
            url: 'https://zapier.com/apps/soundcloud/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/soundcloud/20607/send-tracks-from-soundcloud-users-to-follow-to-otixo',
        id: '20607',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'send-tracks-from-soundcloud-users-to-follow-to-otixo',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Inform your team members on as soon as new pin is added in Pinterest. With each new pin , Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nZapier gets the information for new pin on Pinterest\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nPinterest\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as new pin is added  in Pinterest. With each new pin , Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new pin on Pinterest</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Pinterest</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Send new Pinterest pins to a Otixo work space',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as new pin is added  in Pinterest. With each new pin , Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new pin on Pinterest\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Pinterest\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20608',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/e15bb249be46b70e5cd011a8731e1e93.png',
            description:
              'Pinterest is the world\u2019s catalog of ideas that more than 100 million people around the world use every month to get more creative in their everyday lives. Save images and videos ("Pins") to curated collections ("Boards") from any website, or upload your own. Build your online presence and grow your audience by sharing your visual catalog, and track what your audience thinks through their Likes and repins.',
            id: '1407',
            title: 'Pinterest',
            hex_color: 'BB232C',
            slug: 'pinterest',
            url: 'https://zapier.com/apps/pinterest/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/pinterest/20608/post-new-pinterest-pin-to-a-otixo-work-space',
        id: '20608',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'post-new-pinterest-pin-to-a-otixo-work-space',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'Inform your team members on as soon as new issue is created in GitLab. With each new issue , Zapier will automatically send notification to selected Otixo work space.\n\nHow it works\n\nZapier gets the information for new issue on GitLab\n\nZapier will post a new notification in Otixo work space selected by you\n\nApps involved\n\nGitLab\n\nOtixo',
        description:
          '<p>Inform your team members on as soon as new issue is created in GitLab. With each new issue , Zapier will automatically send notification to selected Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier gets the information for new issue on GitLab</li>\n<li>Zapier will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>GitLab</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post new GitLab issues as notifications in Otixo',
        type: 'guided_zap',
        description_raw:
          'Inform your team members on as soon as new issue is created in GitLab. With each new issue , Zapier will automatically send notification to selected Otixo work space.\r\n\r\n## How it works\r\n\r\n1. Zapier gets the information for new issue on GitLab\r\n2. Zapier will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- GitLab\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20609',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/307fa4f7f37831684fe3e3ee33ab97d3.png',
            description:
              'GitLab is an open source code collaboration tool with a rich feature set including management of repositories, reviewing tools, issue tracking, activity feeds and much more.',
            id: '3986',
            title: 'GitLab',
            hex_color: 'E14532',
            slug: 'gitlab',
            url: 'https://zapier.com/apps/gitlab/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/gitlab/integrations/otixo/20609/post-new-gitlab-issues-to-otixo',
        id: '20609',
        categories: [
          {
            name: 'Product Management',
            description: '',
            id: 'product-management',
          },
        ],
        slug: 'post-new-gitlab-issues-to-otixo',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'Get information on new applications and feature updates from Zapier directly into a work space selected by you.\n\nHow it works\n\nZapier updates info on applications and features\n\nThe automation will post a new notification in Otixo work space selected by you\n\nApps involved\n\nZapier\n\nOtixo',
        description:
          '<p>Get information on new applications and feature updates from Zapier directly into a work space selected by you. </p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>Zapier updates info on applications and features</li>\n<li>The automation will post a new notification in Otixo work space selected by you</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Zapier</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get Otixo notifications for new Zapier apps, integration enhancements and product updates',
        type: 'guided_zap',
        description_raw:
          'Get information on new applications and feature updates from Zapier directly into a work space selected by you. \r\n\r\n## How it works\r\n\r\n1. Zapier updates info on applications and features\r\n2. The automation will post a new notification in Otixo work space selected by you\r\n\r\n## Apps involved\r\n\r\n- Zapier\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20610',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/97231822ca6a5a666293070273fd6d8d.png',
            description:
              'Zapier. The one app to rule them all! Use the Zapier app to create a delayed trigger or stay in the know about new supported apps, triggers and actions.',
            id: '233',
            title: 'Zapier',
            hex_color: 'ff4a00',
            slug: 'zapier',
            url: 'https://zapier.com/apps/zapier/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/zapier/20610/get-otixo-notifications-for-new-zapier-apps-integration-enhancements-and-product-updates',
        id: '20610',
        categories: [
          {
            name: 'Product Management',
            description: '',
            id: 'product-management',
          },
        ],
        slug: 'get-otixo-notifications-for-new-zapier-apps-integration-enhancements-and-product-updates',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Got something to share with your Facebook friends? Use this integration to share new messages to your Facebook Page directly from an Otixo Workspace automatically. Zapier will automatically post the content of a notification to Facebook Page for you.\n\nHow this works\n\nA new notification is posted in an Otixo Workspace\n\nZapier creates a new message to Facebook Page\n\nApps involved\n\nOtixo\n\nFacebook Pages',
        description:
          '<p>Got something to share with your Facebook friends? Use this integration to share new messages to your Facebook Page directly from an Otixo Workspace automatically. Zapier will automatically post the content of a notification to Facebook Page for you.</p>\n\n<h2>How this works</h2>\n\n<ol>\n<li>A new notification is posted in an Otixo Workspace</li>\n<li>Zapier creates a new message to Facebook Page</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Otixo</li>\n<li>Facebook Pages</li>\n</ul>\n',
        title: 'Post Otixo work space notifications to your Facebook Pages',
        type: 'guided_zap',
        description_raw:
          'Got something to share with your Facebook friends? Use this integration to share new messages to your Facebook Page directly from an Otixo Workspace automatically. Zapier will automatically post the content of a notification to Facebook Page for you.\r\n\r\n## How this works\r\n\r\n1. A new notification is posted in an Otixo Workspace\r\n2. Zapier creates a new message to Facebook Page\r\n\r\n## Apps involved\r\n\r\n- Otixo\r\n- Facebook Pages',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20611',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773.png',
            description:
              "Facebook Pages lets you connect with your customers, fans and followers on the world's largest social network. Share relevant content to engage people who Like your page, create events to build and grow your community, and run a Facebook ad campaign by targeting location, age and more.\r\n\r\n<small>Need to use your personal Facebook account? [See Facebook](https://zapier.com/zapbook/facebook/)</small>",
            id: '17',
            title: 'Facebook Pages',
            hex_color: '3b5998',
            slug: 'facebook-pages',
            url: 'https://zapier.com/apps/facebook-pages/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-pages/integrations/otixo/20611/post-otixo-work-space-notifications-to-your-facebook-pages',
        id: '20611',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'post-otixo-work-space-notifications-to-your-facebook-pages',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'Append Otixo works pace notifications directly to Evernote. This automation will allow you to add all notifications created in an Otixo work space to a note created in Evernote.\n\nHow this Otixo-Evernote integration works\n\nA new notification is posted in an Otixo work space\n\nZapier integration appends the notification to pre-selected note in Evernote\n\nApps involved\n\nOtixo\n\nEvernote',
        description:
          '<p>Append Otixo works pace notifications directly to Evernote. This automation will allow you to add all notifications created in an Otixo work space to a note created in Evernote. </p>\n\n<h2>How this Otixo-Evernote integration works</h2>\n\n<ol>\n<li>A new notification is posted in an Otixo work space</li>\n<li>Zapier integration appends the notification to pre-selected note in Evernote</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Otixo</li>\n<li>Evernote</li>\n</ul>\n',
        title: 'Append new Otixo messages to a note on Evernote',
        type: 'guided_zap',
        description_raw:
          'Append Otixo works pace notifications directly to Evernote. This automation will allow you to add all notifications created in an Otixo work space to a note created in Evernote. \r\n\r\n## How this Otixo-Evernote integration works\r\n\r\n1. A new notification is posted in an Otixo work space\r\n2. Zapier integration appends the notification to pre-selected note in Evernote\r\n\r\n## Apps involved\r\n\r\n- Otixo\r\n- Evernote',
        create_url: 'https://zapier.com/partner/embed/otixo/create/20612',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/e0a30d6651780fba36903a9af2b768b6.png',
            description:
              "Evernote is a powerful note taking application that makes it easy to capture ideas, images, contacts, and anything else you need to remember. Bring your life's work together in one digital workspace\u2014available on all major mobile platforms and devices.\r\n\r\n<small>Using Evernote for work? [See Evernote Business](/zapbook/evernote-business/).</small>",
            id: '15',
            title: 'Evernote',
            hex_color: '6fb536',
            slug: 'evernote',
            url: 'https://zapier.com/apps/evernote/integrations',
          },
        ],
        url: 'https://zapier.com/apps/evernote/integrations/otixo/20612/append-new-otixo-message-to-a-note-on-evernote',
        id: '20612',
        categories: [
          {
            name: 'Product Management',
            description: '',
            id: 'product-management',
          },
          { name: 'Documents', description: '', id: 'documents' },
        ],
        slug: 'append-new-otixo-message-to-a-note-on-evernote',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Keep team members up to date with new documentation.This automation allows you to send an Otixo Workspace notification whenever a new presentation is created on Google Slides. Zapier will post relevant information to pre-defined workspace.\n\nHow It Works\n\nA new presentation is created on Google Slides\n\nZapier posts a notification to Otixo workspace\n\nApps involved\n\nGoogle Slides\n\nOtixo',
        description:
          '<p>Keep team members up to date with new documentation.This automation allows you to send an Otixo Workspace notification whenever a new presentation is created on Google Slides. Zapier will post relevant information to pre-defined workspace.</p>\n\n<h2>How It Works</h2>\n\n<ol>\n<li>A new presentation is created on Google Slides</li>\n<li>Zapier posts a notification to Otixo workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Slides</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get notifications in Otixo when new Google Slide presentations are created',
        type: 'guided_zap',
        description_raw:
          'Keep team members up to date with new documentation.This automation allows you to send an Otixo Workspace notification whenever a new presentation is created on Google Slides. Zapier will post relevant information to pre-defined workspace.\r\n\r\n## How It Works\r\n\r\n1. A new presentation is created on Google Slides\r\n2. Zapier posts a notification to Otixo workspace\r\n\r\n## Apps involved\r\n\r\n- Google Slides\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22793',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/d7cd7cd9ffcf2dab9f0333f7d7f61e45.png',
            description:
              'Google Slides allows you to create, edit, and share presentations with anyone, online, for free.',
            id: '4685',
            title: 'Google Slides',
            hex_color: 'F3AF00',
            slug: 'google-slides',
            url: 'https://zapier.com/apps/google-slides/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-slides/integrations/otixo/22793/notifications-in-otixo-on-new-google-slide-presentations',
        id: '22793',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'notifications-in-otixo-on-new-google-slide-presentations',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Google Forms facilitate the collection of all kinds of input from surveys to appointments. This Zapier automation will post notifications on your behalf in an Otixo Workspace. That way, you don't have to export the data by hand.\n\nHow this Google Forms-Otixo integration works\n\nYou have a new form submission in Google Forms\n\nZapier creates a new notification in a pre-defined Otixo Workspace.\n\nApps involved\n\nGoogle Forms\n\nOtixo",
        description:
          "<p>Google Forms facilitate the collection of all kinds of input from surveys to appointments. This Zapier automation will post notifications on your behalf in an Otixo Workspace. That way, you don't have to export the data by hand.</p>\n\n<h2>How this Google Forms-Otixo integration works</h2>\n\n<ol>\n<li>You have a new form submission in Google Forms</li>\n<li>Zapier creates a new notification in a pre-defined Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Forms</li>\n<li>Otixo</li>\n</ul>\n",
        title:
          'Create Otixo Workspace notifications from new Google Forms submissions',
        type: 'guided_zap',
        description_raw:
          "Google Forms facilitate the collection of all kinds of input from surveys to appointments. This Zapier automation will post notifications on your behalf in an Otixo Workspace. That way, you don't have to export the data by hand.\r\n\r\n## How this Google Forms-Otixo integration works\r\n\r\n1. You have a new form submission in Google Forms\r\n2. Zapier creates a new notification in a pre-defined Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Google Forms\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22794',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/2554f3f9544cf8375a46f68b18de0e7d.png',
            description:
              'Google Forms is an easy way to collect data from the web with a simple UI and powerful editor. Works hand-in-hand with [Google Sheets](/zapbook/google-sheets/)!',
            id: '2662',
            title: 'Google Forms',
            hex_color: '0AA35B',
            slug: 'google-forms',
            url: 'https://zapier.com/apps/google-forms/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-forms/integrations/otixo/22794/create-otixo-workspace-notifications-from-google-forms-submissions',
        id: '22794',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'create-otixo-workspace-notifications-from-google-forms-submissions',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Your team can move faster when their task lists are streamlined. Allow your team to keep track of tasks by integrating one of the simplest task managers - Google Tasks and an Otixo Workspace. After you set up this integration, Zapier will automatically post to an Otixo Workspace whenever a task is completed.\n\nHow it works\n\nA task is completed in Google Tasks\n\nZapier creates a new notification in a pre-configured Otixo Workpsace\n\nApps involved\n\nGoogle Tasks\n\nOtixo',
        description:
          '<p>Your team can move faster when their task lists are streamlined. Allow your team to keep track of tasks by integrating one of the simplest task managers - Google Tasks and an Otixo Workspace. After you set up this integration, Zapier will automatically post to an Otixo Workspace whenever a task is completed. </p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A task is completed in Google Tasks</li>\n<li>Zapier creates a new notification in a pre-configured Otixo Workpsace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Tasks</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Create Otixo Workspace notifications when Google Tasks are completed',
        type: 'guided_zap',
        description_raw:
          'Your team can move faster when their task lists are streamlined. Allow your team to keep track of tasks by integrating one of the simplest task managers - Google Tasks and an Otixo Workspace. After you set up this integration, Zapier will automatically post to an Otixo Workspace whenever a task is completed. \r\n\r\n## How it works\r\n\r\n1. A task is completed in Google Tasks\r\n2. Zapier creates a new notification in a pre-configured Otixo Workpsace\r\n\r\n## Apps involved\r\n\r\n- Google Tasks\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22795',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/09d7059874ac55089ed9bfcc7a287165.png',
            description:
              'Google Tasks is a very simple task list. Works inside Gmail, Android, and Calendar seamlessly.',
            id: '169',
            title: 'Google Tasks',
            hex_color: '0f9d58',
            slug: 'google-tasks',
            url: 'https://zapier.com/apps/google-tasks/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-tasks/integrations/otixo/22795/create-an-otixo-workspace-notification-on-task-completion-in-google-tasks',
        id: '22795',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'create-an-otixo-workspace-notification-on-task-completion-in-google-tasks',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "It's easier to get stuff done during the day when your task list is accessible and up-to-date. Use this automation to do the heavy lifting for you. After you set it up, Zapier will automatically post to an Otixo Workspace when a new task list is created in Google Tasks.\n\nHow it works\n\nA new task list is created in Google Tasks\n\nZapier creates a new notification in a pre-configured Otixo Workpsace\n\nApps involved\n\nGoogle Tasks\n\nOtixo",
        description:
          "<p>It's easier to get stuff done during the day when your task list is accessible and up-to-date. Use this automation to do the heavy lifting for you. After you set it up, Zapier will automatically post to an Otixo Workspace when a new task list is created in Google Tasks.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new task list is created in Google Tasks</li>\n<li>Zapier creates a new notification in a pre-configured Otixo Workpsace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Tasks</li>\n<li>Otixo</li>\n</ul>\n",
        title:
          'Get Otixo Workspace notifications for new Google Tasks task lists',
        type: 'guided_zap',
        description_raw:
          "It's easier to get stuff done during the day when your task list is accessible and up-to-date. Use this automation to do the heavy lifting for you. After you set it up, Zapier will automatically post to an Otixo Workspace when a new task list is created in Google Tasks.\r\n\r\n## How it works\r\n\r\n1. A new task list is created in Google Tasks\r\n2. Zapier creates a new notification in a pre-configured Otixo Workpsace\r\n\r\n## Apps involved\r\n\r\n- Google Tasks\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22796',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/09d7059874ac55089ed9bfcc7a287165.png',
            description:
              'Google Tasks is a very simple task list. Works inside Gmail, Android, and Calendar seamlessly.',
            id: '169',
            title: 'Google Tasks',
            hex_color: '0f9d58',
            slug: 'google-tasks',
            url: 'https://zapier.com/apps/google-tasks/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-tasks/integrations/otixo/22796/create-an-otixo-workspace-notification-on-google-tasks-task-list-creation',
        id: '22796',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'create-an-otixo-workspace-notification-on-google-tasks-task-list-creation',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Allow your team to keep track of tasks integrating one of the simplest task managers - Google Tasks and an Otixo Workspace. Zapier will automatically post relevant information into an Otixo Workspace on your behalf.\n\nHow it works\n\nA new task is created in Google Tasks\n\nZapier creates a new notification in a pre-configured Otixo Workpsace\n\nApps involved\n\nGoogle Tasks\n\nOtixo',
        description:
          '<p>Allow your team to keep track of tasks integrating one of the simplest task managers - Google Tasks and an Otixo Workspace. Zapier will automatically post relevant information into an Otixo Workspace on your behalf. </p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new task is created in Google Tasks</li>\n<li>Zapier creates a new notification in a pre-configured Otixo Workpsace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Tasks</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post Otixo Workspace notifications for new Google Tasks',
        type: 'guided_zap',
        description_raw:
          'Allow your team to keep track of tasks integrating one of the simplest task managers - Google Tasks and an Otixo Workspace. Zapier will automatically post relevant information into an Otixo Workspace on your behalf. \r\n\r\n## How it works\r\n\r\n1. A new task is created in Google Tasks\r\n2. Zapier creates a new notification in a pre-configured Otixo Workpsace\r\n\r\n## Apps involved\r\n\r\n- Google Tasks\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22797',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/09d7059874ac55089ed9bfcc7a287165.png',
            description:
              'Google Tasks is a very simple task list. Works inside Gmail, Android, and Calendar seamlessly.',
            id: '169',
            title: 'Google Tasks',
            hex_color: '0f9d58',
            slug: 'google-tasks',
            url: 'https://zapier.com/apps/google-tasks/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-tasks/integrations/otixo/22797/post-otixo-workspace-notification-on-google-tasks-task-creation',
        id: '22797',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'post-otixo-workspace-notification-on-google-tasks-task-creation',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'When something comes up at the last minute, this integration can help alert your team of changes to the schedule. This Zap will automatically notify your team members when Google Calendar events have been canceled. Zapier will automatically send notification to a designated Otixo work space.\n\nHow it works\n\nA Google Calendar event is being cancelled.\n\nZapier posts a Otixo notification to a pre-selected Workspace.\n\nApps involved\n\nGoogle Calendar\n\nOtixo',
        description:
          '<p>When something comes up at the last minute, this integration can help alert your team of changes to the schedule. This Zap will automatically notify your team members when Google Calendar events have been canceled.  Zapier will automatically send notification to a designated Otixo work space.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A Google Calendar event is being cancelled.</li>\n<li>Zapier posts a Otixo notification to a pre-selected Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Calendar</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get Otixo Workspace notifications when Google Calendar events are canceled',
        type: 'guided_zap',
        description_raw:
          'When something comes up at the last minute, this integration can help alert your team of changes to the schedule. This Zap will automatically notify your team members when Google Calendar events have been canceled.  Zapier will automatically send notification to a designated Otixo work space.\r\n\r\n## How it works\r\n\r\n1. A Google Calendar event is being cancelled.\r\n2. Zapier posts a Otixo notification to a pre-selected Workspace.\r\n\r\n## Apps involved\r\n\r\n- Google Calendar\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22798',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/62c82a7958c6c29736f17d0495b6635c.png',
            description:
              "Google Calendar lets you organize your schedule and share events with co-workers and friends. With Google's free online calendar, it's easy to keep track of your daily schedule.",
            id: '36',
            title: 'Google Calendar',
            hex_color: '3680f7',
            slug: 'google-calendar',
            url: 'https://zapier.com/apps/google-calendar/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-calendar/integrations/otixo/22798/get-an-otixo-workspace-notification-on-google-calendar-event-cancellation',
        id: '22798',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'get-an-otixo-workspace-notification-on-google-calendar-event-cancellation',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Waiting for an edited blog post, a contract, or another doc? Use this integration to get an alert when new files arrive. After you set up it up, Zapier will post a notification in your Otixo Workspace when new documents are added to a folder in Google Docs.\n\nHow It works\n\nA new Google document is added to folder\n\nZapier posts a notification to a pre-selected Otixo Workspace.\n\nApps involved\n\nGoogle Docs\n\nOtixo',
        description:
          '<p>Waiting for an edited blog post, a contract, or another doc? Use this integration to get an alert when new files arrive. After you set up it up, Zapier will post a notification in your Otixo Workspace when new documents are added to a folder in Google Docs. </p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>A new Google document is added to folder</li>\n<li>Zapier posts a notification to a pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Docs</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Post Otixo Workspace notifications when new Google documents are added to a specific folder',
        type: 'guided_zap',
        description_raw:
          'Waiting for an edited blog post, a contract, or another doc? Use this integration to get an alert when new files arrive. After you set up it up, Zapier will post a notification in your Otixo Workspace when new documents are added to a folder in Google Docs. \r\n\r\n## How It works\r\n\r\n1. A new Google document is added to folder\r\n2. Zapier posts a notification to a pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Google Docs\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22799',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ae42824b58d556d36b5e5b217377fc5e.png',
            description:
              'Google Docs is an online word processor that lets you create and format text documents. Collaboratively edit documents with other people in real time.\r\n\r\nWe also support [Google Sheets](/zapbook/google-sheets/)!',
            id: '12',
            title: 'Google Docs',
            hex_color: '4f8df5',
            slug: 'google-docs',
            url: 'https://zapier.com/apps/google-docs/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-docs/integrations/otixo/22799/post-an-otixo-workspace-notification-when-a-document-is-added-to-a-specific-folder-in-google-docs',
        id: '22799',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'post-an-otixo-workspace-notification-when-a-document-is-added-to-a-specific-folder-in-google-docs',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Constantly checking Google Drive for an important file? Use this automation to have Zapier be your extra set of eyes. After you set up this integration, you'll receive a notification in your Otixo Workspace when new files are added to a specific folder in Google Drive. Now everyone in your Workspace will stay informed and in the loop.\n\nHow It works\n\nA new file is added to a specified Google Drive folder.\n\n2 Zapier creates a notification in a pre-selected Otixo Workspace.\n\nApps involved\n\nGoogle Drive\n\nOtixo",
        description:
          "<p>Constantly checking Google Drive for an important file? Use this automation to have Zapier be your extra set of eyes. After you set up this integration, you'll receive a notification in your Otixo Workspace when new files are added to a specific folder in Google Drive. Now everyone in your Workspace will stay informed and in the loop.</p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>A new file is added to a specified Google Drive folder.\n2 Zapier creates a notification in a pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Drive</li>\n<li>Otixo</li>\n</ul>\n",
        title:
          'Get Otixo Workspace notifications when files are added to a specific folder in Google Drive',
        type: 'guided_zap',
        description_raw:
          "Constantly checking Google Drive for an important file? Use this automation to have Zapier be your extra set of eyes. After you set up this integration, you'll receive a notification in your Otixo Workspace when new files are added to a specific folder in Google Drive. Now everyone in your Workspace will stay informed and in the loop.\r\n\r\n## How It works\r\n\r\n1. A new file is added to a specified Google Drive folder.\r\n2 Zapier creates a notification in a pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Google Drive\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22801',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/bb735e567f1a1e9e3b8b7241497c2d43.png',
            description:
              "Google Drive is Google's file sync app that lets you store all of your files online alongside your Google Docs documents, and keep them synced with all of your devices.",
            id: '69',
            title: 'Google Drive',
            hex_color: 'f4b400',
            slug: 'google-drive',
            url: 'https://zapier.com/apps/google-drive/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-drive/integrations/otixo/22801/get-an-otixo-workspace-notification-when-a-file-is-added-to-a-specific-folder-in-google-drive',
        id: '22801',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-an-otixo-workspace-notification-when-a-file-is-added-to-a-specific-folder-in-google-drive',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Flipping back and forth between Google Drive and your other work? Use this automation instead. After you set it up, you'll get a notification in your Otixo Workspace when a file is updated in a specific folder in Google Drive. That way, you can stay focused on your most important work and let Zapier watch for your files in the background.\n\nHow it works\n\nA file is updated in a specified Google Drive folder.\n\nZapier creates a notification in a pre-selected Otixo Workspace.\n\nApps involved\n\nGoogle Drive\n\nOtixo",
        description:
          "<p>Flipping back and forth between Google Drive and your other work? Use this automation instead. After you set it up, you'll get a notification in your Otixo Workspace when a file is updated in a specific folder in Google Drive. That way, you can stay focused on your most important work and let Zapier watch for your files in the background.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A file is updated in a specified Google Drive folder. </li>\n<li>Zapier creates a notification in a pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Drive</li>\n<li>Otixo</li>\n</ul>\n",
        title:
          'Get Otixo Workspace notifications when files are updated in a specific folder in Google Drive',
        type: 'guided_zap',
        description_raw:
          "Flipping back and forth between Google Drive and your other work? Use this automation instead. After you set it up, you'll get a notification in your Otixo Workspace when a file is updated in a specific folder in Google Drive. That way, you can stay focused on your most important work and let Zapier watch for your files in the background.\r\n\r\n## How it works\r\n\r\n1. A file is updated in a specified Google Drive folder. \r\n2. Zapier creates a notification in a pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Google Drive\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22802',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/bb735e567f1a1e9e3b8b7241497c2d43.png',
            description:
              "Google Drive is Google's file sync app that lets you store all of your files online alongside your Google Docs documents, and keep them synced with all of your devices.",
            id: '69',
            title: 'Google Drive',
            hex_color: 'f4b400',
            slug: 'google-drive',
            url: 'https://zapier.com/apps/google-drive/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-drive/integrations/otixo/22802/get-an-otixo-workspace-notification-when-a-file-is-updated-in-a-specific-folder-in-google-drive',
        id: '22802',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-an-otixo-workspace-notification-when-a-file-is-updated-in-a-specific-folder-in-google-drive',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        description_plain:
          'Get the status of build jobs in Jenkins directly into your Otixo Workspace. Keep team members up to date with this Zapier automation which will post the needed information as notification in any Otixo Workspace you choose.\n\nHow this Jenkins-Otixo integration works\n\nA new job status notification is posted on Jenkins\n\nZapier creates a new Otixo Workspace notification in a pre-defined workspace.\n\nApps involved\n\nJenkins\n\nOtixo',
        description:
          '<p>Get the status of build jobs in Jenkins directly into your Otixo Workspace. Keep team members up to date with this Zapier automation which will post the needed information as notification in any Otixo Workspace you choose.</p>\n\n<h2>How this Jenkins-Otixo integration works</h2>\n\n<ol>\n<li>A new job status notification is posted on Jenkins</li>\n<li>Zapier creates a new Otixo Workspace notification in a pre-defined workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Jenkins</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get notifications in Otixo Workspace for new Jenkins job status updates',
        type: 'guided_zap',
        description_raw:
          'Get the status of build jobs in Jenkins directly into your Otixo Workspace. Keep team members up to date with this Zapier automation which will post the needed information as notification in any Otixo Workspace you choose.\r\n\r\n## How this Jenkins-Otixo integration works\r\n\r\n1. A new job status notification is posted on Jenkins\r\n2. Zapier creates a new Otixo Workspace notification in a pre-defined workspace.\r\n\r\n## Apps involved\r\n\r\n- Jenkins\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22828',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/c152d4d93c7fce4a651f5f31cdb1c394_2.png',
            description:
              'jenkins is a continuous integration server that monitors executions of repeated jobs, such as building a software project or jobs run by cron. ',
            id: '623',
            title: 'Jenkins',
            hex_color: 'D33833',
            slug: 'jenkins',
            url: 'https://zapier.com/apps/jenkins/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/jenkins/integrations/otixo/22828/get-notifications-in-otixo-workspace-for-new-jenkins-job-status',
        id: '22828',
        categories: [
          { name: 'Developer Tools', description: '', id: 'developer-tools' },
        ],
        slug: 'get-notifications-in-otixo-workspace-for-new-jenkins-job-status',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Don't be in the dark about your dev issues. Get information for new Redmine issues directly into your Otixo Workspace. Keep team members up-to-date with this Zapier automation which will post the needed information as notification in any Otixo Workspace you choose.\n\nHow this Redmine-Otixo integration works\n\nA new issue is created on Redmine\n\nZapier creates a new Otixo Workspace notification in a pre-defined workspace.\n\nApps involved\n\nRedmine\n\nOtixo",
        description:
          "<p>Don't be in the dark about your dev issues. Get information for new Redmine issues directly into your Otixo Workspace. Keep team members up-to-date with this Zapier automation which will post the needed information as notification in any Otixo Workspace you choose.</p>\n\n<h2>How this Redmine-Otixo integration works</h2>\n\n<ol>\n<li>A new issue is created on Redmine</li>\n<li>Zapier creates a new Otixo Workspace notification in a pre-defined workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Redmine</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Get Otixo Workspace notifications from new Redmine issues',
        type: 'guided_zap',
        description_raw:
          "Don't be in the dark about your dev issues. Get information for new Redmine issues directly into your Otixo Workspace. Keep team members up-to-date with this Zapier automation which will post the needed information as notification in any Otixo Workspace you choose.\r\n\r\n## How this Redmine-Otixo integration works\r\n\r\n1. A new issue is created on Redmine\r\n2. Zapier creates a new Otixo Workspace notification in a pre-defined workspace.\r\n\r\n## Apps involved\r\n\r\n- Redmine\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22829',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/cd06f9fc7a1f50c1715ef012ea897715.png',
            description:
              'Redmine is a flexible project management web application built with Ruby on Rails.',
            id: '68',
            title: 'Redmine',
            hex_color: 'c52f29',
            slug: 'redmine',
            url: 'https://zapier.com/apps/redmine/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/redmine/22829/create-otixo-workspace-notifications-from-redmine-issues',
        id: '22829',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'create-otixo-workspace-notifications-from-redmine-issues',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Don't spend time flipping back and forth between the apps you use during the day - automate it! Inform your team as soon as new Desk case is created directly in an Otixo Workspace. This Zapier automation will create a new notification containing all the relevant information in Otixo.\n\nHow it works\n\nA new case is created in Desk\n\nZapier creates a new Otixo Workspace notification in a pre-defined workspace.\n\nApps involved\n\nDesk\n\nOtixo",
        description:
          "<p>Don't spend time flipping back and forth between the apps you use during the day - automate it! Inform your team as soon as new Desk case is created directly in an Otixo Workspace. This Zapier automation will create a new notification containing all the relevant information in Otixo.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new case is created in Desk</li>\n<li>Zapier creates a new Otixo Workspace notification in a pre-defined workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Desk</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Create notifications in Otixo Workspace for new Desk cases',
        type: 'guided_zap',
        description_raw:
          "Don't spend time flipping back and forth between the apps you use during the day - automate it! Inform your team as soon as new Desk case is created directly in an Otixo Workspace. This Zapier automation will create a new notification containing all the relevant information in Otixo.\r\n\r\n## How it works\r\n\r\n1. A new case is created in Desk\r\n2. Zapier creates a new Otixo Workspace notification in a pre-defined workspace.\r\n\r\n## Apps involved\r\n\r\n- Desk\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22830',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/91397217fe81d32f3ce1378a8e8bfbf5.png',
            description:
              'Desk is the new version of Assistly under the flagship Salesforce brand.\r\n\r\nDesk is an all-in-one customer service tool allowing you to keep track of your customers across multiple channels including email, Twitter, Facebook, phone, and chat. Desk also makes it really easy to create knowledge bases about your products and services so customers can support themselves.',
            id: '58',
            title: 'Desk',
            hex_color: '683e98',
            slug: 'desk',
            url: 'https://zapier.com/apps/desk/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/desk/integrations/otixo/22830/create-notifications-in-otixo-workspace-on-new-desk-cases',
        id: '22830',
        categories: [
          { name: 'Customer Support', description: '', id: 'customer-support' },
        ],
        slug: 'create-notifications-in-otixo-workspace-on-new-desk-cases',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'If your team spends time in Otixo, bring important events right into the workspace. Get your team notified on events from Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\n\nHow this Facebook Groups-Otixo integration works\n\nNew event is created in a Facebook Group\n\nZapier automatically posts a notification in pre-selected Otixo Workspace.\n\nApps involved\n\nFacebook Groups\n\nOtixo',
        description:
          '<p>If your team spends time in Otixo, bring important events right into the workspace. Get your team notified on events from Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.</p>\n\n<h2>How this Facebook Groups-Otixo integration works</h2>\n\n<ol>\n<li>New event is created in a Facebook Group</li>\n<li>Zapier automatically posts a notification in pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Groups</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get notifications in Otixo Workspace on new Facebook Groups events',
        type: 'guided_zap',
        description_raw:
          'If your team spends time in Otixo, bring important events right into the workspace. Get your team notified on events from Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\r\n\r\n## How this Facebook Groups-Otixo integration works\r\n\r\n1. New event is created in a Facebook Group\r\n2. Zapier automatically posts a notification in pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Facebook Groups\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22836',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773_3.png',
            description:
              "Groups provide a space to communicate about shared interests with certain people. You can create a group for anything \u2014 your family reunion, your after-work sports team, your book club \u2014 and customize the group's privacy settings depending on who you want to be able to join and see the group.",
            id: '5604',
            title: 'Facebook Groups',
            hex_color: null,
            slug: 'facebook-groups',
            url: 'https://zapier.com/apps/facebook-groups/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-groups/integrations/otixo/22836/get-notifications-in-otixo-workspace-on-new-facebook-groups-events',
        id: '22836',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'get-notifications-in-otixo-workspace-on-new-facebook-groups-events',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Opening Facebook can send you down a rabbit hole of distractions. Instead, use this automation to let your team notified about new videos posted to Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\n\nHow this Facebook Groups-Otixo integration works\n\nNew video is posted in Facebook Groups\n\nZapier automatically posts a notification in pre-selected Otixo Workspace.\n\nApps involved\n\nFacebook Groups\n\nOtixo',
        description:
          '<p>Opening Facebook can send you down a rabbit hole of distractions. Instead, use this automation to let your team notified about new videos posted to Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.</p>\n\n<h2>How this Facebook Groups-Otixo integration works</h2>\n\n<ol>\n<li>New video is posted in Facebook Groups</li>\n<li>Zapier automatically posts a notification in pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Groups</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get notifications in Otixo Workspace for new Facebook Groups videos',
        type: 'guided_zap',
        description_raw:
          'Opening Facebook can send you down a rabbit hole of distractions. Instead, use this automation to let your team notified about new videos posted to Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\r\n\r\n## How this Facebook Groups-Otixo integration works\r\n\r\n1. New video is posted in Facebook Groups\r\n2. Zapier automatically posts a notification in pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Facebook Groups\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22837',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773_3.png',
            description:
              "Groups provide a space to communicate about shared interests with certain people. You can create a group for anything \u2014 your family reunion, your after-work sports team, your book club \u2014 and customize the group's privacy settings depending on who you want to be able to join and see the group.",
            id: '5604',
            title: 'Facebook Groups',
            hex_color: null,
            slug: 'facebook-groups',
            url: 'https://zapier.com/apps/facebook-groups/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-groups/integrations/otixo/22837/get-notifications-in-otixo-workspace-on-new-facebook-groups-videos',
        id: '22837',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'get-notifications-in-otixo-workspace-on-new-facebook-groups-videos',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Keep track on photos posted in Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\n\nHow this Facebook Groups-Otixo integration works\n\nNew photo is posted in Facebook Groups\n\nZapier automatically posts a notification in pre-selected Otixo Workspace.\n\nApps involved\n\nFacebook Groups\n\nOtixo',
        description:
          '<p>Keep track on photos posted in Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.</p>\n\n<h2>How this Facebook Groups-Otixo integration works</h2>\n\n<ol>\n<li>New photo is posted in Facebook Groups</li>\n<li>Zapier automatically posts a notification in pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Groups</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get notifications in Otixo Workspace for new Facebook Groups photos',
        type: 'guided_zap',
        description_raw:
          'Keep track on photos posted in Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\r\n\r\n## How this Facebook Groups-Otixo integration works\r\n\r\n1. New photo is posted in Facebook Groups\r\n2. Zapier automatically posts a notification in pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Facebook Groups\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22838',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773_3.png',
            description:
              "Groups provide a space to communicate about shared interests with certain people. You can create a group for anything \u2014 your family reunion, your after-work sports team, your book club \u2014 and customize the group's privacy settings depending on who you want to be able to join and see the group.",
            id: '5604',
            title: 'Facebook Groups',
            hex_color: null,
            slug: 'facebook-groups',
            url: 'https://zapier.com/apps/facebook-groups/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-groups/integrations/otixo/22838/get-notifications-in-otixo-workspace-on-new-facebook-groups-photos',
        id: '22838',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'get-notifications-in-otixo-workspace-on-new-facebook-groups-photos',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Stay on top of the chatter in your Facebook Group without having to leave your team chat. Get your team notified on new posts from Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\n\nHow this Facebook Groups-Otixo integration works\n\nNew post is created in Facebook Groups\n\nZapier automatically posts a notification in pre-selected Otixo Workspace.\n\nApps involved\n\nFacebook Groups\n\nOtixo',
        description:
          '<p>Stay on top of the chatter in your Facebook Group without having to leave your team chat. Get your team notified on new posts from Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.</p>\n\n<h2>How this Facebook Groups-Otixo integration works</h2>\n\n<ol>\n<li>New post is created in Facebook Groups</li>\n<li>Zapier automatically posts a notification in pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Groups</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Get notifications in Otixo Workspace for new Facebook Groups posts',
        type: 'guided_zap',
        description_raw:
          'Stay on top of the chatter in your Facebook Group without having to leave your team chat. Get your team notified on new posts from Facebook Groups directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\r\n\r\n## How this Facebook Groups-Otixo integration works\r\n\r\n1. New post is created in Facebook Groups\r\n2. Zapier automatically posts a notification in pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Facebook Groups\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22839',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773_3.png',
            description:
              "Groups provide a space to communicate about shared interests with certain people. You can create a group for anything \u2014 your family reunion, your after-work sports team, your book club \u2014 and customize the group's privacy settings depending on who you want to be able to join and see the group.",
            id: '5604',
            title: 'Facebook Groups',
            hex_color: null,
            slug: 'facebook-groups',
            url: 'https://zapier.com/apps/facebook-groups/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-groups/integrations/otixo/22839/get-notifications-in-otixo-workspace-on-new-facebook-groups-posts',
        id: '22839',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'get-notifications-in-otixo-workspace-on-new-facebook-groups-posts',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Got a rave review? Celebrate! Or react to a poor review quickly with a boost of automation. Get your team notified on new reviews on a Facebook page directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\n\nHow this Facebook Pages-Otixo integration works\n\nNew review on Facebook page\n\nZapier automatically posts a notification in pre-selected Otixo Workspace.\n\nApps involved\n\nFacebook Pages\n\nOtixo',
        description:
          '<p>Got a rave review? Celebrate! Or react to a poor review quickly with a boost of automation. Get your team notified on new reviews on a Facebook page directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.</p>\n\n<h2>How this Facebook Pages-Otixo integration works</h2>\n\n<ol>\n<li>New review on Facebook page</li>\n<li>Zapier automatically posts a notification in pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Pages</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Share new Facebook page ratings/reviews in an Otixo Wokspace',
        type: 'guided_zap',
        description_raw:
          'Got a rave review? Celebrate! Or react to a poor review quickly with a boost of automation. Get your team notified on new reviews on a Facebook page directly in Otixo. This Zapier integration will post relevant information in Otixo workspace you have configured.\r\n\r\n## How this Facebook Pages-Otixo integration works\r\n\r\n1. New review on Facebook page\r\n2. Zapier automatically posts a notification in pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Facebook Pages\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22879',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773.png',
            description:
              "Facebook Pages lets you connect with your customers, fans and followers on the world's largest social network. Share relevant content to engage people who Like your page, create events to build and grow your community, and run a Facebook ad campaign by targeting location, age and more.\r\n\r\n<small>Need to use your personal Facebook account? [See Facebook](https://zapier.com/zapbook/facebook/)</small>",
            id: '17',
            title: 'Facebook Pages',
            hex_color: '3b5998',
            slug: 'facebook-pages',
            url: 'https://zapier.com/apps/facebook-pages/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-pages/integrations/otixo/22879/share-new-facebook-page-ratingreview-in-an-otixo-wokspace',
        id: '22879',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'share-new-facebook-page-ratingreview-in-an-otixo-wokspace',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Instead of keeping a close eye on important Twitter accounts use this integration to get notifications directly in Otixo. The Zapier automation will post to a workspace that you configure.\n\nHow it works\n\nA new tweet is posted on Twitter\n\nZapier automatically posts a notification in pre-selected Otixo Workspace.\n\nApps involved\n\nTwitter\n\nOtixo',
        description:
          '<p>Instead of keeping a close eye on important Twitter accounts use this integration to get notifications directly in Otixo. The Zapier automation will post to a workspace that you configure.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new tweet is posted on Twitter</li>\n<li>Zapier automatically posts a notification in pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Twitter</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post new tweets in an Otixo Workspace',
        type: 'guided_zap',
        description_raw:
          'Instead of keeping a close eye on important Twitter accounts use this integration to get notifications directly in Otixo. The Zapier automation will post to a workspace that you configure.\r\n\r\n## How it works\r\n\r\n1. A new tweet is posted on Twitter\r\n2. Zapier automatically posts a notification in pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- Twitter\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22881',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/580b3c79622bbff634f0d764e9f813a3.png',
            description:
              "Twitter is the social network that shows what's happening around the world in real time. Share your ideas in Tweets, follow hashtags to keep up with trends, and join in the global conversation.",
            id: '4',
            title: 'Twitter',
            hex_color: '2DAAE1',
            slug: 'twitter',
            url: 'https://zapier.com/apps/twitter/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/twitter/22881/post-new-tweets-as-otixo-workspace-notifications',
        id: '22881',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'post-new-tweets-as-otixo-workspace-notifications',
        status: 'published',
      },
      {
        subcatetories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        description_plain:
          'Follow new content posted on YouTube easily without having to leave your workspace. This Zapier automation will add appropriate information in an Otixo Workspace you have configured\n\nHow it works\n\nNew video is posted on YouTube by a user you specify\n\nZapier posts a notification in pre-selected Otixo Workspace.\n\nApps involved\n\nYouTube\n\nOtixo',
        description:
          '<p>Follow new content posted on YouTube easily without having to leave your workspace. This Zapier automation will add appropriate information in an Otixo Workspace you have configured</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>New video is posted on YouTube by a user you specify</li>\n<li>Zapier posts a notification in pre-selected Otixo Workspace.</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>YouTube</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post new YouTube user videos as notifications in Otixo',
        type: 'guided_zap',
        description_raw:
          'Follow new content posted on YouTube easily without having to leave your workspace. This Zapier automation will add appropriate information in an Otixo Workspace you have configured\r\n\r\n## How it works\r\n\r\n1. New video is posted on YouTube by a user you specify\r\n2. Zapier posts a notification in pre-selected Otixo Workspace.\r\n\r\n## Apps involved\r\n\r\n- YouTube\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22882',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/7633e61620664fb3a71911729ebce5ea.png',
            description:
              'YouTube allows billions of people to discover, watch and share originally-created videos. YouTube provides a forum for people to connect, inform, and inspire others across the globe and acts as a distribution platform for original content creators and advertisers large and small.',
            id: '311',
            title: 'YouTube',
            hex_color: 'FF0000',
            slug: 'youtube',
            url: 'https://zapier.com/apps/youtube/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/youtube/22882/post-new-youtube-user-videos-as-notifications-in-otixo',
        id: '22882',
        categories: [
          { name: 'Social Media', description: '', id: 'social-media' },
        ],
        slug: 'post-new-youtube-user-videos-as-notifications-in-otixo',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Tracking your Facebook group can time consuming, keep your team members informed on new posts directly in an Otixo Workspace. Digest by Zapier will store a log of these posts and create a notification summary in Otixo Workspace on a schedule you choose - daily, weekly, or monthly - so you can see all recent content at a glance.\n\nHow It works\n\nNew post on Facebook Groups\n\nZapier's Digest app stores a running log of those new posts\n\nAt a time you set, your digest is sent directly to pre-selected Otixo Workspace\n\nApps involved\n\nFacebook Groups\n\nDigest by Zapier\n\nOtixo",
        description:
          "<p>Tracking your Facebook group can time consuming, keep your team members informed on new posts directly in an Otixo Workspace. Digest by Zapier will store a log of these posts and create a notification summary in Otixo Workspace on a schedule you choose - daily, weekly, or monthly - so you can see all recent content at a glance.</p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>New post on Facebook Groups</li>\n<li>Zapier's Digest app stores a running log of those new posts</li>\n<li>At a time you set, your digest is sent directly to pre-selected Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Facebook Groups</li>\n<li>Digest by Zapier</li>\n<li>Otixo</li>\n</ul>\n",
        title:
          'Get Otixo notifications for new Facebook Groups posts daily, weekly, or monthly',
        type: 'guided_zap',
        description_raw:
          "Tracking your Facebook group can time consuming, keep your team members informed on new posts directly in an Otixo Workspace. Digest by Zapier will store a log of these posts and create a notification summary in Otixo Workspace on a schedule you choose - daily, weekly, or monthly - so you can see all recent content at a glance.\r\n\r\n## How It works\r\n1. New post on Facebook Groups\r\n2. Zapier's Digest app stores a running log of those new posts\r\n3. At a time you set, your digest is sent directly to pre-selected Otixo Workspace\r\n\r\n\r\n## Apps involved\r\n\r\n- Facebook Groups\r\n- Digest by Zapier\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22888',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/ec44f2368b3cea5f487b69f8c6f41773_3.png',
            description:
              "Groups provide a space to communicate about shared interests with certain people. You can create a group for anything \u2014 your family reunion, your after-work sports team, your book club \u2014 and customize the group's privacy settings depending on who you want to be able to join and see the group.",
            id: '5604',
            title: 'Facebook Groups',
            hex_color: null,
            slug: 'facebook-groups',
            url: 'https://zapier.com/apps/facebook-groups/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/services/c23097708bd08ff36ffc8b9293e77465_2.png',
            description:
              'Digest captures info from multiple events in your workflows and collects them in a single easily consumed summary that can be sent to any app.',
            id: '4436',
            title: 'Digest by Zapier',
            hex_color: 'ff4a00',
            slug: 'digest',
            url: 'https://zapier.com/apps/digest/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/facebook-groups/integrations/otixo/22888/get-otixo-notifications-for-new-facebook-groups-posts-daily-weekly-or-monthly',
        id: '22888',
        categories: [
          {
            name: 'Social Media Management',
            description: '',
            id: 'social-media-management',
          },
        ],
        slug: 'get-otixo-notifications-for-new-facebook-groups-posts-daily-weekly-or-monthly',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "You can avoid spending time checking for new Braintree transactions by getting notifications about them instead. With this Zapier integration, every time a new transaction is created in Braintree, a Otixo workspace notification will be sent directly to your team. The notification will provide all relevant information provided by Braintree for the payment, so you don't have to switch between tools to stay up-to-date about your business transactions.\n\nHow it works\n\nA new transaction occurs on Braintree\n\nZapier posts a notification in Otixo\n\nApps involved\n\nBraintree\n\nOtixo",
        description:
          "<p>You can avoid spending time checking for new Braintree transactions by getting notifications about them instead. With this Zapier integration, every time a new transaction is created in Braintree, a Otixo workspace notification will be sent directly to your team. The notification will provide all relevant information provided by Braintree for the payment, so you don't have to switch between tools to stay up-to-date about your business transactions.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new transaction occurs on Braintree</li>\n<li>Zapier posts a notification in Otixo</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Braintree</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Create Otixo notifications for new Braintree transactions',
        type: 'guided_zap',
        description_raw:
          "You can avoid spending time checking for new Braintree transactions by getting notifications about them instead. With this Zapier integration, every time a new transaction is created in Braintree, a Otixo workspace notification will be sent directly to your team. The notification will provide all relevant information provided by Braintree for the payment, so you don't have to switch between tools to stay up-to-date about your business transactions.\r\n\r\n## How it works\r\n\r\n1. A new transaction occurs on Braintree\r\n2. Zapier posts a notification in Otixo\r\n\r\n## Apps involved\r\n\r\n- Braintree\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/22891',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/e8039e6400c52274f12fd1806d4ace6c.jpeg',
            description:
              'With a first class API, great rates and instant approval, Braintree is the easiest way to accept payments anywhere online and via a mobile app.',
            id: '764',
            title: 'Braintree',
            hex_color: '69d59a',
            slug: 'braintree',
            url: 'https://zapier.com/apps/braintree/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/braintree/integrations/otixo/22891/get-otixo-notifications-for-new-braintree-transactions-daily-weekly-or-monthly',
        id: '22891',
        categories: [{ name: 'Accounting', description: '', id: 'accounting' }],
        slug: 'get-otixo-notifications-for-new-braintree-transactions-daily-weekly-or-monthly',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Notify your team about new customer charges, so they can keep an eye on anything they can help with. This Zapier automation will automatically send information about new Stripe charges to Otixo as notifications. The notification will provide all relevant information provided by Stripe for the payment, so your teammates have all the information they need to help with customer issues or follow-ups.\n\nHow it works\n\nA new charge is created in Stripe\n\nZapier posts a notification in Otixo\n\nApps involved\n\nStripe\n\nOtixo',
        description:
          '<p>Notify your team about new customer charges, so they can keep an eye on anything they can help with. This Zapier automation will automatically send information about new Stripe charges to Otixo as notifications. The notification will provide all relevant information provided by Stripe for the payment, so your teammates have all the information they need to help with customer issues or follow-ups.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new charge is created in Stripe</li>\n<li>Zapier posts a notification in Otixo</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Stripe</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Create Otixo notifications for new Stripe charges',
        type: 'guided_zap',
        description_raw:
          'Notify your team about new customer charges, so they can keep an eye on anything they can help with. This Zapier automation will automatically send information about new Stripe charges to Otixo as notifications. The notification will provide all relevant information provided by Stripe for the payment, so your teammates have all the information they need to help with customer issues or follow-ups.\r\n\r\n## How it works\r\n\r\n1. A new charge is created in Stripe\r\n2. Zapier posts a notification in Otixo\r\n\r\n## Apps involved\r\n\r\n- Stripe\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/22892',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/27f5433f521806493e2c38a304e1786e.png',
            description:
              "Stripe is a developer-friendly way to accept payments online and in mobile apps. Stripe's suite of APIs powers commerce for thousands of companies of all sizes, processing billions of dollars for businesses each year.",
            id: '22',
            title: 'Stripe',
            hex_color: '00afe1',
            slug: 'stripe',
            url: 'https://zapier.com/apps/stripe/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/stripe/22892/get-otixo-notifications-for-new-braintree-transactions-daily-weekly-or-monthly-ssQ',
        id: '22892',
        categories: [{ name: 'Accounting', description: '', id: 'accounting' }],
        slug: 'get-otixo-notifications-for-new-braintree-transactions-daily-weekly-or-monthly-ssQ',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "Don't miss out on information that your team adds in Box. Instead, send that information directly to your team communication tool. This Zapier integration automatically sends Otixo notifications when new comments are created in Box. That way, no comments go unseen or unaddressed and your team can collaborate seamlessly.\n\nHow it works\n\nA new comment is added in Box\n\nZapier posts a notification to Otixo\n\nApps involved\n\nBox\n\nOtixo",
        description:
          "<p>Don't miss out on information that your team adds in Box. Instead, send that information directly to your team communication tool. This Zapier integration automatically sends Otixo notifications when new comments are created in Box. That way, no comments go unseen or unaddressed and your team can collaborate seamlessly.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new comment is added in Box</li>\n<li>Zapier posts a notification to Otixo</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Box</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Create Otixo notifications for new comments in Box',
        type: 'guided_zap',
        description_raw:
          "Don't miss out on information that your team adds in Box. Instead, send that information directly to your team communication tool. This Zapier integration automatically sends Otixo notifications when new comments are created in Box. That way, no comments go unseen or unaddressed and your team can collaborate seamlessly.\r\n\r\n## How it works\r\n\r\n1. A new comment is added in Box\r\n2. Zapier posts a notification to Otixo\r\n\r\n## Apps involved\r\n\r\n- Box\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/23022',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/cdda1a3a6ffabe2f6d0fc52bc9c2df68.png',
            description:
              'Box lets you keep all your businesses files in one place for simple online collaboration.',
            id: '73',
            title: 'Box',
            hex_color: '0081c6',
            slug: 'box',
            url: 'https://zapier.com/apps/box/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/box/integrations/otixo/23022/post-comments-from-box-as-otixo-notifications',
        id: '23022',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'post-comments-from-box-as-otixo-notifications',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Wondering what task to tackle next? This automation will keep your team in the loop. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box task is added.\n\nHow It works\n\nA new task is added in Box\n\nZapier posts notification to a pre-selected Otixo Workspace\n\nApps involved\n\nBox\n\nOtixo',
        description:
          '<p>Wondering what task to tackle next? This automation will keep your team in the loop. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box task is added.</p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>A new task is added in Box</li>\n<li>Zapier posts notification to a pre-selected Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Box</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post tasks from Box as Otixo notifications',
        type: 'guided_zap',
        description_raw:
          'Wondering what task to tackle next? This automation will keep your team in the loop. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box task is added.\r\n\r\n## How It works\r\n1. A new task is added in Box\r\n2. Zapier posts notification to a pre-selected Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- Box\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23023',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/cdda1a3a6ffabe2f6d0fc52bc9c2df68.png',
            description:
              'Box lets you keep all your businesses files in one place for simple online collaboration.',
            id: '73',
            title: 'Box',
            hex_color: '0081c6',
            slug: 'box',
            url: 'https://zapier.com/apps/box/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/box/integrations/otixo/23023/post-tasks-from-box-as-otixo-notifications',
        id: '23023',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'post-tasks-from-box-as-otixo-notifications',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Save time and facilitate collaboration amongst your team. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box event is added.\n\nHow It works\n\nA new event is added in Box\n\nZapier posts notification to a pre-selected Otixo Workspace\n\nApps involved\n\nBox\n\nOtixo',
        description:
          '<p>Save time and facilitate collaboration amongst your team. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box event is added.</p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>A new event is added in Box</li>\n<li>Zapier posts notification to a pre-selected Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Box</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Post new events in Box as Otixo notifications',
        type: 'guided_zap',
        description_raw:
          'Save time and facilitate collaboration amongst your team. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box event is added.\r\n\r\n## How It works\r\n1. A new event is added in Box\r\n2. Zapier posts notification to a pre-selected Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- Box\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23024',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/cdda1a3a6ffabe2f6d0fc52bc9c2df68.png',
            description:
              'Box lets you keep all your businesses files in one place for simple online collaboration.',
            id: '73',
            title: 'Box',
            hex_color: '0081c6',
            slug: 'box',
            url: 'https://zapier.com/apps/box/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/box/integrations/otixo/23024/post-events-from-box-as-otixo-notifications',
        id: '23024',
        categories: [
          {
            name: 'Project Management',
            description: '',
            id: 'project-management',
          },
        ],
        slug: 'post-events-from-box-as-otixo-notifications',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Save time and facilitate collaboration amongst your team. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box folder is added. Use it to kick off a project and automatically create a shared space for the team to share files and project docs.\n\nHow It works\n\nA new folder is added in Box\n\nZapier posts notification to a pre-selected Otixo Workspace\n\nApps involved\n\nBox\n\nOtixo',
        description:
          '<p>Save time and facilitate collaboration amongst your team. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box folder is added. Use it to kick off a project and automatically create a shared space for the team to share files and project docs.</p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>A new folder is added in Box</li>\n<li>Zapier posts notification to a pre-selected Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Box</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get notified in Otixo when a folder is added in Box',
        type: 'guided_zap',
        description_raw:
          'Save time and facilitate collaboration amongst your team. This Zapier integration makes it easy to get notifications in Otixo Workspace to let you and your team know that a new Box folder is added. Use it to kick off a project and automatically create a shared space for the team to share files and project docs.\r\n\r\n## How It works\r\n1. A new folder is added in Box\r\n2. Zapier posts notification to a pre-selected Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- Box\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23025',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/cdda1a3a6ffabe2f6d0fc52bc9c2df68.png',
            description:
              'Box lets you keep all your businesses files in one place for simple online collaboration.',
            id: '73',
            title: 'Box',
            hex_color: '0081c6',
            slug: 'box',
            url: 'https://zapier.com/apps/box/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/box/integrations/otixo/23025/get-notified-in-otixo-when-a-folder-is-added-in-box',
        id: '23025',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-notified-in-otixo-when-a-folder-is-added-in-box',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Save time and facilitate collaboration amongst your team. This Zapier integration automatically sends notifications in Otixo to let you and your team know that a new Google Drive folder is added. From there, your team can use the Google Drive folder to collaborate on certain projects or documents.\n\nHow it works\n\nA new folder is added in Google Drive\n\nZapier posts a notification to Otixo\n\nApps involved\n\nGoogle Drive\n\nOtixo',
        description:
          '<p>Save time and facilitate collaboration amongst your team. This Zapier integration automatically sends notifications in Otixo to let you and your team know that a new Google Drive folder is added. From there, your team can use the Google Drive folder to collaborate on certain projects or documents.</p>\n\n<h2>How it works</h2>\n\n<ol>\n<li>A new folder is added in Google Drive</li>\n<li>Zapier posts a notification to Otixo</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Google Drive</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Create Otixo notifications for new Google Drive folders',
        type: 'guided_zap',
        description_raw:
          'Save time and facilitate collaboration amongst your team. This Zapier integration automatically sends notifications in Otixo to let you and your team know that a new Google Drive folder is added. From there, your team can use the Google Drive folder to collaborate on certain projects or documents.\r\n\r\n## How it works\r\n\r\n1. A new folder is added in Google Drive\r\n2. Zapier posts a notification to Otixo\r\n\r\n## Apps involved\r\n\r\n- Google Drive\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23026',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/bb735e567f1a1e9e3b8b7241497c2d43.png',
            description:
              "Google Drive is Google's file sync app that lets you store all of your files online alongside your Google Docs documents, and keep them synced with all of your devices.",
            id: '69',
            title: 'Google Drive',
            hex_color: 'f4b400',
            slug: 'google-drive',
            url: 'https://zapier.com/apps/google-drive/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/google-drive/integrations/otixo/23026/get-notified-in-otixo-when-a-folder-is-added-in-google-drive',
        id: '23026',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-notified-in-otixo-when-a-folder-is-added-in-google-drive',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Want to keep track of folder creation in OneDrive and share that with your collaborators in a workspace? This Zapier integration makes it easy to get notifications in Otixo to let you and your team know that a new OneDrive folder is added. That way, your team can use that OneDrive folder effectively and add relevant files to it right away.\n\nHow It works\n\nA new folder is added in OneDrive\n\nZapier posts a notification to Otixo\n\nApps involved\n\nOneDrive\n\nOtixo',
        description:
          '<p>Want to keep track of folder creation in OneDrive and share that with your collaborators in a workspace? This Zapier integration makes it easy to get notifications in Otixo to let you and your team know that a new OneDrive folder is added. That way, your team can use that OneDrive folder effectively and add relevant files to it right away.</p>\n\n<h2>How It works</h2>\n\n<ol>\n<li>A new folder is added in OneDrive</li>\n<li>Zapier posts a notification to Otixo</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>OneDrive</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Create Otixo notifications for new OneDrive folders',
        type: 'guided_zap',
        description_raw:
          'Want to keep track of folder creation in OneDrive and share that with your collaborators in a workspace? This Zapier integration makes it easy to get notifications in Otixo to let you and your team know that a new OneDrive folder is added. That way, your team can use that OneDrive folder effectively and add relevant files to it right away.\r\n\r\n## How It works\r\n\r\n1. A new folder is added in OneDrive\r\n2. Zapier posts a notification to Otixo\r\n\r\n## Apps involved\r\n\r\n- OneDrive\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23027',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/e67867e9f6f5c089e243dad54467ad33.png',
            description:
              "OneDrive, Microsoft's file sync app formerly known as SkyDrive, lets you store your files online, edit Office documents in the free Office Web Apps, and easily access your online files from your PC.",
            id: '77',
            title: 'OneDrive',
            hex_color: '094ab2',
            slug: 'onedrive',
            url: 'https://zapier.com/apps/onedrive/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/onedrive/integrations/otixo/23027/get-notified-in-otixo-when-a-folder-is-added-in-onedrive',
        id: '23027',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-notified-in-otixo-when-a-folder-is-added-in-onedrive',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Do you need to keep track of new folders in your SugarSync account and notify your collaborators on Otixo? Once activated, this Zapier automation will deliver the information from SugarSync to the designated Otixo Workspace.\n\nHow this SugarSync-Otixo integration works\n\nA new folder is created in SugarSync\n\nZapier posts a notification to a pre-configured Otixo Workspace\n\nApps involved\n\nSugarSync\n\nOtixo',
        description:
          '<p>Do you need to keep track of new folders in your SugarSync account and notify your collaborators on Otixo? Once activated, this Zapier automation will deliver the information from SugarSync to the designated Otixo Workspace. </p>\n\n<h2>How this SugarSync-Otixo integration works</h2>\n\n<ol>\n<li>A new folder is created in SugarSync</li>\n<li>Zapier posts a notification to a pre-configured Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>SugarSync</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Create notifications in Otixo on new folder creations in SugarSync',
        type: 'guided_zap',
        description_raw:
          'Do you need to keep track of new folders in your SugarSync account and notify your collaborators on Otixo? Once activated, this Zapier automation will deliver the information from SugarSync to the designated Otixo Workspace. \r\n\r\n## How this SugarSync-Otixo integration works\r\n\r\n1. A new folder is created in SugarSync\r\n2. Zapier posts a notification to a pre-configured Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- SugarSync\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23530',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/d2ea88c63b7aa47b6993cbe4a103d4d1.png',
            description:
              'SugarSync is an easy-to-use file sharing, online backup and cloud storage service you can use to sync music, photos and files across any device. Sync just a few folders or all of your folders. Collaborate on team projects, distribute view-only files, or post links to documents on the web.',
            id: '228',
            title: 'SugarSync',
            hex_color: '1E884D',
            slug: 'sugarsync',
            url: 'https://zapier.com/apps/sugarsync/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/sugarsync/23530/create-notification-in-otixo-on-new-folder-creation-in-sugarsync',
        id: '23530',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'create-notification-in-otixo-on-new-folder-creation-in-sugarsync',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Want to avoid checking on a folder in SugarSync for new files and you need to share information about them with your team? This Zapier automation will post details for the newly added file directly to the configured Otixo Workspace.\n\nHow this SugarSync-Otixo integration works\n\nA new file is added to a specified SugarSync folder\n\nZapier creates a notification in the configured Otixo Workgroup\n\nApps involved\n\nSugarSync\n\nOtixo',
        description:
          '<p>Want to avoid checking on a folder in SugarSync for new files and you need to share information about them with your team? This Zapier automation will post details for the newly added file directly to the configured Otixo Workspace.</p>\n\n<h2>How this SugarSync-Otixo integration works</h2>\n\n<ol>\n<li>A new file is added to a specified SugarSync folder</li>\n<li>Zapier creates a notification in the configured Otixo Workgroup</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>SugarSync</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Receive notifications in Otixo when files are added to a SugarSync folder',
        type: 'guided_zap',
        description_raw:
          'Want to avoid checking on a folder in SugarSync for new files and you need to share information about them with your team? This Zapier automation will post details for the newly added file directly to the configured Otixo Workspace.\r\n\r\n## How this SugarSync-Otixo integration works\r\n\r\n1. A new file is added to a specified SugarSync folder\r\n2. Zapier creates a notification in the configured Otixo Workgroup\r\n\r\n## Apps involved\r\n\r\n- SugarSync\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23531',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/d2ea88c63b7aa47b6993cbe4a103d4d1.png',
            description:
              'SugarSync is an easy-to-use file sharing, online backup and cloud storage service you can use to sync music, photos and files across any device. Sync just a few folders or all of your folders. Collaborate on team projects, distribute view-only files, or post links to documents on the web.',
            id: '228',
            title: 'SugarSync',
            hex_color: '1E884D',
            slug: 'sugarsync',
            url: 'https://zapier.com/apps/sugarsync/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/sugarsync/23531/receive-notification-in-otixo-when-a-file-has-been-added-to-sugarsync-folder',
        id: '23531',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'receive-notification-in-otixo-when-a-file-has-been-added-to-sugarsync-folder',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Want to save time sharing new album photos to your team? This Zapier integration allows you to select a SugarSync album and will automatically post notifications with all the album details to configured Otixo Workspace.\n\nHow this SugarSync-Otixo integration works\n\nNew photos are added to a SugarSync album\n\nZapier posts a new notification to a pre-selected Otixo\n\nApps involved\n\nSugarSync\n\nOtixo',
        description:
          '<p>Want to save time sharing new album photos to your team? This Zapier integration allows you to select a SugarSync album and will automatically post notifications with all the album details to configured Otixo Workspace.</p>\n\n<h2>How this SugarSync-Otixo integration works</h2>\n\n<ol>\n<li>New photos are added to a SugarSync album</li>\n<li>Zapier posts a new notification to a pre-selected Otixo</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>SugarSync</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Post Otixo Workspace notifications when new photos are added to a SugarSync album',
        type: 'guided_zap',
        description_raw:
          'Want to save time sharing new album photos to your team? This Zapier integration allows you to select a SugarSync album and will automatically post notifications with all the album details to configured Otixo Workspace.\r\n\r\n## How this SugarSync-Otixo integration works\r\n\r\n1. New photos are added to a SugarSync album\r\n2. Zapier posts a new notification to a pre-selected Otixo\r\n\r\n## Apps involved\r\n\r\n- SugarSync\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23532',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/d2ea88c63b7aa47b6993cbe4a103d4d1.png',
            description:
              'SugarSync is an easy-to-use file sharing, online backup and cloud storage service you can use to sync music, photos and files across any device. Sync just a few folders or all of your folders. Collaborate on team projects, distribute view-only files, or post links to documents on the web.',
            id: '228',
            title: 'SugarSync',
            hex_color: '1E884D',
            slug: 'sugarsync',
            url: 'https://zapier.com/apps/sugarsync/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/sugarsync/23532/post-otixo-workspace-notifications-on-new-photos-in-sugarsync-album',
        id: '23532',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'post-otixo-workspace-notifications-on-new-photos-in-sugarsync-album',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          "If you don't want to check regularly for new albums added on SugarSync, this automation will deliver the notification directly to the workgroup you have configured. Once this Zapier integration is activated, it will deliver details to a pre-selected Otixo workgroup.\n\nHow this SugarSync-Otixo integration works\n\nA new album is created on SugarSync\n\nZapier will post a notification in pre-configured Otixo Workgroup\n\nApps involved\n\nSugarSync\n\nOtixo",
        description:
          "<p>If you don't want to check regularly for new albums added on SugarSync, this automation will deliver the notification directly to the workgroup you have configured. Once this Zapier integration is activated, it will deliver details to a pre-selected Otixo workgroup.</p>\n\n<h2>How this SugarSync-Otixo integration works</h2>\n\n<ol>\n<li>A new album is created on SugarSync</li>\n<li>Zapier will post a notification in pre-configured Otixo Workgroup</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>SugarSync</li>\n<li>Otixo</li>\n</ul>\n",
        title: 'Announce SugarSync album creation in Otixo Workspace',
        type: 'guided_zap',
        description_raw:
          "If you don't want to check regularly for new albums added on SugarSync, this automation will deliver the notification directly to the workgroup you have configured. Once this Zapier integration is activated, it will deliver details to a pre-selected Otixo workgroup.\r\n\r\n## How this SugarSync-Otixo integration works\r\n\r\n1. A new album is created on SugarSync\r\n2. Zapier will post a notification in pre-configured Otixo Workgroup\r\n\r\n## Apps involved\r\n\r\n- SugarSync\r\n- Otixo",
        create_url: 'https://zapier.com/partner/embed/otixo/create/23533',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/services/d2ea88c63b7aa47b6993cbe4a103d4d1.png',
            description:
              'SugarSync is an easy-to-use file sharing, online backup and cloud storage service you can use to sync music, photos and files across any device. Sync just a few folders or all of your folders. Collaborate on team projects, distribute view-only files, or post links to documents on the web.',
            id: '228',
            title: 'SugarSync',
            hex_color: '1E884D',
            slug: 'sugarsync',
            url: 'https://zapier.com/apps/sugarsync/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/otixo/integrations/sugarsync/23533/announce-sugarsync-album-creation-in-otixo-workspace',
        id: '23533',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'announce-sugarsync-album-creation-in-otixo-workspace',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Need to get your team up to date on newly created Flickr albums without having to check all the time? Once this Zapier integration is configured, the details on the albums added in Flickr will be posted to the Otixo Workspace that you choose.\n\nHow this Flickr-Otixo integration works\n\nАn album is created on Flickr\n\nZapier sends a notification in a pre-configured Otixo Workspace\n\nApps involved\n\nFlickr\n\nOtixo',
        description:
          '<p>Need to get your team up to date on newly created Flickr albums without having to check all the time? Once this Zapier integration is configured, the details on the albums added in Flickr will be posted to the Otixo Workspace that you choose.</p>\n\n<h2>How this Flickr-Otixo integration works</h2>\n\n<ol>\n<li>Аn album is created on Flickr</li>\n<li>Zapier sends a notification in a pre-configured Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Flickr</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Create Otixo Workspace notifications when new albums are added in Flickr',
        type: 'guided_zap',
        description_raw:
          'Need to get your team up to date on newly created Flickr albums without having to check all the time? Once this Zapier integration is configured, the details on the albums added in Flickr will be posted to the Otixo Workspace that you choose.\r\n\r\n## How this Flickr-Otixo integration works\r\n\r\n1. Аn album is created on Flickr\r\n2. Zapier sends a notification in a pre-configured Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- Flickr\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23534',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/8db522ba135c4e0e3cf56c761bad4bfe_2.png',
            description:
              "Flickr is a photo sharing and social media platform that allows you to host images online, and check out other users' images.",
            id: '6441',
            title: 'Flickr',
            hex_color: null,
            slug: 'flickr',
            url: 'https://zapier.com/apps/flickr/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/flickr/integrations/otixo/23534/create-otixo-workspace-notification-when-new-album-is-created-on-flickr',
        id: '23534',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'create-otixo-workspace-notification-when-new-album-is-created-on-flickr',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Need to share photos with the team without stopping to post each one? Once this Zapier integration is configured, the details on newly added photos in Flickr will be posted to the Otixo Workspace that you choose.\n\nHow this Flickr-Otixo integration works\n\nYou upload a new photo in an album on Flickr\n\nZapier sends a notification in pre-configured Otixo Workspace\n\nApps involved\n\nFlickr\n\nOtixo',
        description:
          '<p>Need to share photos with the team without stopping to post each one? Once this Zapier integration is configured, the details on newly added photos in Flickr will be posted to the Otixo Workspace that you choose.</p>\n\n<h2>How this Flickr-Otixo integration works</h2>\n\n<ol>\n<li>You upload a new photo in an album on Flickr</li>\n<li>Zapier sends a notification in pre-configured Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Flickr</li>\n<li>Otixo</li>\n</ul>\n',
        title: 'Get notifications in Otixo when new photos are added in Flickr',
        type: 'guided_zap',
        description_raw:
          'Need to share photos with the team without stopping to post each one? Once this Zapier integration is configured, the details on newly added photos in Flickr will be posted to the Otixo Workspace that you choose.\r\n\r\n## How this Flickr-Otixo integration works\r\n\r\n1. You upload a new photo in an album on Flickr\r\n2. Zapier sends a notification in pre-configured Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- Flickr\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23535',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/8db522ba135c4e0e3cf56c761bad4bfe_2.png',
            description:
              "Flickr is a photo sharing and social media platform that allows you to host images online, and check out other users' images.",
            id: '6441',
            title: 'Flickr',
            hex_color: null,
            slug: 'flickr',
            url: 'https://zapier.com/apps/flickr/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/flickr/integrations/otixo/23535/get-notification-in-otixo-when-a-new-photo-is-added-on-flickr',
        id: '23535',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'get-notification-in-otixo-when-a-new-photo-is-added-on-flickr',
        status: 'published',
      },
      {
        subcatetories: [],
        description_plain:
          'Want to keep your team up to date with new photos added to a specific album on Flickr without posting each one? Once this Zapier integration is configured, the details on added photos will be posted to the Otixo Workspace that you choose.\n\nHow this Flickr-Otixo integration works\n\nA photo is uploaded to an album on Flickr\n\nZapier sends a notification in a pre-configured Otixo Workspace\n\nApps involved\n\nFlickr\n\nOtixo',
        description:
          '<p>Want to keep your team up to date with new photos added to a specific album on Flickr without posting each one? Once this Zapier integration is configured, the details on added photos will be posted to the Otixo Workspace that you choose.</p>\n\n<h2>How this Flickr-Otixo integration works</h2>\n\n<ol>\n<li>A photo is uploaded to an album on Flickr</li>\n<li>Zapier sends a notification in a pre-configured Otixo Workspace</li>\n</ol>\n\n<h2>Apps involved</h2>\n\n<ul>\n<li>Flickr</li>\n<li>Otixo</li>\n</ul>\n',
        title:
          'Post notifications in Otixo when new photos are added to a Flickr album',
        type: 'guided_zap',
        description_raw:
          'Want to keep your team up to date with new photos added to a specific album on Flickr without posting each one? Once this Zapier integration is configured, the details on added photos will be posted to the Otixo Workspace that you choose.\r\n\r\n## How this Flickr-Otixo integration works\r\n\r\n1. A photo is uploaded to an album on Flickr\r\n2. Zapier sends a notification in a pre-configured Otixo Workspace\r\n\r\n## Apps involved\r\n\r\n- Flickr\r\n- Otixo',
        create_url: 'https://zapier.com/partner/embed/otixo/create/23536',
        steps: [
          {
            image:
              'https://cdn.zapier.com/storage/developer/8db522ba135c4e0e3cf56c761bad4bfe_2.png',
            description:
              "Flickr is a photo sharing and social media platform that allows you to host images online, and check out other users' images.",
            id: '6441',
            title: 'Flickr',
            hex_color: null,
            slug: 'flickr',
            url: 'https://zapier.com/apps/flickr/integrations',
          },
          {
            image:
              'https://cdn.zapier.com/storage/developer/c557915c8345a3dbb70aace726568bd1.png',
            description:
              'Otixo is a platform for team collaboration: everything in one place, with instant messaging, file sharing, search function and cloud access.',
            id: '8714',
            title: 'Otixo',
            hex_color: '28919a',
            slug: 'otixo',
            url: 'https://zapier.com/apps/otixo/integrations',
          },
        ],
        url: 'https://zapier.com/apps/flickr/integrations/otixo/23536/post-notification-in-otixo-when-a-photo-is-added-to-flickr-album',
        id: '23536',
        categories: [{ name: 'Documents', description: '', id: 'documents' }],
        slug: 'post-notification-in-otixo-when-a-photo-is-added-to-flickr-album',
        status: 'published',
      },
    ],
  },
};

export const tasks = { Tasks: [] };

export const workspaceUnseen = { Workspaces: [], Messages: {} };

export const conversations = { Spaces: [] };

export const stats = {
  TotalUnreadCount: 0,
  UnreadPerWorkspace: {},
  PendingApprovals: [],
};

export const meetingServerUrl = 'https://jitsi.otixo.com';
export const roomId = '1';

export const createMeeting = (roomId, channelId, workspaceId) => {
  return {
    roomId: roomId,
    meetingServerUrl: meetingServerUrl,
    callerUserId: '8a81811473d6eb450173d840b9230000',
    meetingStartedAt: '2021-04-01T13:50:33.029528',
    channelName: 'General',
    workspaceName: '1231',
    workspaceId: workspaceId,
    participantUserIds: ['8a81811473d6eb450173d840b9230000'],
    users: [
      {
        userId: '8a81811473d6eb450173d840b9230000',
        channelId: channelId,
        firstName: 'Jamie',
        email: 'jamie+hinte@otixo.com',
        lastName: 'C Yeah',
        avatarUrl:
          'https://dev.otixo.com/avatars/Avatar_8a818114740117b701740184dd34000c_1609763365431.jpeg',
        color: '#54b948',
      },
      {
        userId: '8a818114740117b701740184dd34000c',
        channelId: channelId,
        firstName: 'Xamie',
        email: 'x@o.co',
        lastName: 'X Yeah',
        avatarUrl: null,
        color: '#f09',
      },
    ],
  };
};
