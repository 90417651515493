import Dexie from 'dexie';

/**
 * If you update the store, ensure you also
 * increase the version number.
 */
export const commonDb = new Dexie('common');
commonDb.version(16).stores({
  workspaces: 'workspaceId, workspace',
  channels: 'channelId, workspaceId, channel',
  linkedItems: 'linkedItemId, resourceId, channelId, linkedItem',
  notifications:
    'notificationId, channelId, notificationTimestamp, notification',
  resources: 'resourceId, parentId, currentPage, nextPage, resource',
  user: 'userId, user',
  webUiPreferences: 'id, webUiPreferences',
  comments: 'commentId, resourceId, comment',
  repositories: 'repositoryId, repository',
  mediaInfo: 'id, mediaInfo',
  notes: 'noteId, note, channelId',
});

export const deleteCommonDb = async () => await commonDb.delete();
