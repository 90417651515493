import axios from 'axios';
import { REMINDERS } from 'DataLayer/endpoints';

export function edit(reminderId, time, note) {
  return axios
    .put(`${REMINDERS}/${reminderId}`, {
      note,
      time,
    })
    .then(response => response.data);
}
