import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Confetti from 'react-confetti';
import { Button, Header, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { showSpring2024MilestonesModal } from 'store/Modals/actions';

import PartyEmoji from 'Components/WorkspaceOffer/OfferAcceptedModal/PartyEmoji';

const style = { width: 500, height: 350 };

function OfferAcceptedModal({
  workspaceId,
  visible,
  message,
  onClose,
  showNextButton = true,
}) {
  const dispatch = useDispatch();

  const onCloseModal = () => {
    onClose();
  };

  const onViewMilestones = () => {
    dispatch(showSpring2024MilestonesModal(workspaceId));
    onClose();
  };

  return (
    <Modal style={style} size="tiny" open={visible}>
      <Header>
        <div className="close_modal">
          <button className="close_modal_button" onClick={onCloseModal} />
        </div>
      </Header>
      <Modal.Content>
        <Confetti
          width={style.width}
          height={style.height}
          recycle={false}
          numberOfPieces={500}
        />
        <PartyEmoji className="flex justify-content-center mb-3" />
        <p>{message}</p>
        <p>
          <FormattedMessage id="OfferAcceptedModal.Message" />
        </p>
      </Modal.Content>
      {showNextButton && (
        <Modal.Actions className="text-align-center">
          <Button primary onClick={onViewMilestones}>
            <FormattedMessage id="OfferAcceptedModal.ButtonText" />
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
}

OfferAcceptedModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default OfferAcceptedModal;
