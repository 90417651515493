import {
  ADD_ZAPS,
  //LOAD_ZAPS_ERROR,
} from './actions';
import { ZAPIER_DISCONNECTED } from './disconnectZapier';

function addStoreZapsToState(state, zaps) {
  return Object.assign({}, state, {
    //error: null,
    zaps,
  });
}

const initialState = {
  zaps: {},
  //error: null,
};

export default function zapsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ZAPS:
      return addStoreZapsToState(state, action.zaps);
    /* case LOAD_ZAPS_ERROR:
       return {
         ...state,
         error: action.error,
       };*/
    case ZAPIER_DISCONNECTED:
      return addStoreZapsToState(state, {});
    default:
      return state;
  }
}
