import { ADDRESS_BOOK_ADD } from './actions';

const initialState = [];

export default function adaptersReducer(state = initialState, action) {
  switch (action.type) {
    case ADDRESS_BOOK_ADD:
      return state.concat(action.emailAddress);
    default:
      return state;
  }
}
