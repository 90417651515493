import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button, Divider, Header, Progress, Segment } from 'semantic-ui-react';
import * as Sentry from '@sentry/react';

import { importDatabase } from 'DataLayer/database/importDatabase';
import useAsync from 'Hooks/useAsync';
import { WORKSPACES } from 'pages';
import { FormattedMessage } from 'react-intl';
import styles from 'LoadBackup.module.css';
function LoadBackup({ router: { push } }) {
  const [progress, setProgress] = useState(0);
  const state = useAsync(async () => {
    try {
      const databaseBlob = await window.ipcRenderer.invoke('importDatabase');
      console.debug(databaseBlob);
      await importDatabase(databaseBlob, value => {
        console.debug(value);
        setProgress(((value.completedTables + 1) / value.totalTables) * 100);
      });
      return true;
    } catch (ex) {
      Sentry.captureException(ex);
      console.debug(ex);
      throw ex;
    }
  }, []);

  const onViewBackup = () => {
    push(WORKSPACES);
  };

  return (
    <div className="full-screen-app">
      <div className="full-screen-centered-container">
        <Segment.Group className={styles.segments}>
          <Header as="h3" attached="top">
            <FormattedMessage id="LoadBackup.Title" />
          </Header>
          <Segment attached>
            <FormattedMessage id="LoadBackup.InfoText" />
            <Divider hidden />
            <Progress
              progress="percent"
              error={state.error}
              success={state.value}
              percent={state.value ? 100 : progress}
            >
              {state.loading && <FormattedMessage id="LoadBackup.Loading" />}
              {state.error && <FormattedMessage id="LoadBackup.Error" />}
              {state.value && <FormattedMessage id="LoadBackup.ReadyToView" />}
            </Progress>
            {state.value && (
              <Button onClick={onViewBackup} primary>
                <FormattedMessage id="LoadBackup.ViewBackupButtonText" />
              </Button>
            )}
          </Segment>
        </Segment.Group>
      </div>
    </div>
  );
}

LoadBackup.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(LoadBackup);
