export const REPOSITORIES = 'REPOSITORIES';
export const SPACES = 'SPACES';
export const SPACE = 'SPACE';
export const USER_LOAD = 'USER_LOAD';
export const USER_SAVE = 'USER_SAVE';
export const MEDIAINFO = 'MEDIAINFO';
export const RESOURCES = 'RESOURCES';
export const RESOURCE = 'RESOURCE';
export const FOLDERS = 'FOLDERS';
export const REPOSITORY = 'REPOSITORY';
export const LINKED_ITEMS = 'LINKED_ITEMS';
export const LINKED_ITEM = 'LINKED_ITEM';
export const ROOT_RESOURCES = 'ROOT_RESOURCES';
export const SPACE_NOTIFICATIONS = 'SPACE_NOTIFICATIONS';
export const COMMENTS = 'COMMENTS';
export const RESOURCE_LINK = 'RESOURCE_LINK';
export const PUBLIC_RESOURCE = 'PUBLIC_RESOURCE';
export const ACCOUNT = 'ACCOUNT';
export const ACCOUNTS = 'ACCOUNTS';
