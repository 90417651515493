import { commonDb } from 'DataLayer/database/indexedDb';
import { exportDatabase } from 'DataLayer/database/exportDatabase';
import { MakeAvailableOffline } from 'DataLayer/Workspaces/Offline/makeAvailableOffline';
import { removeAllButOne } from 'DataLayer/database/workspaces';

export const saveDatabase = async (
  backupFolder,
  workspaceId,
  setProgress,
  addToLog
) => {
  const makeAvailableOffline = new MakeAvailableOffline(
    backupFolder,
    workspaceId,
    setProgress,
    addToLog
  );
  await makeAvailableOffline.start();

  // remove other workspaces from database before export
  await removeAllButOne(workspaceId);
  // eslint-disable-next-line no-console
  const data = await exportDatabase(commonDb, console.log);

  // https://stackoverflow.com/a/43613242
  function sendExportedDatabaseToElectron(blob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = async function () {
        if (reader.readyState === 2) {
          var buffer = new Buffer(reader.result);
          await window.ipcRenderer.invoke(
            'saveExportedDatabase',
            backupFolder,
            buffer
          );
        }
        resolve();
      };
      reader.readAsArrayBuffer(blob);
    });
  }
  await sendExportedDatabaseToElectron(data);
  return true;
};
