import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withWindowSize from './withWindowSize';

const withCanResizePanels = ComposedComponent => {
  class canResizePanels extends Component {
    getWrappedInstance() {
      return this.wrappedInstance;
    }

    render() {
      // pass window dimensions as props to wrapped component
      return (
        <ComposedComponent
          {...this.props}
          ref={c => {
            this.wrappedInstance = c;
          }}
          canResize={this.props.windowWidth >= 1280}
        />
      );
    }
  }

  canResizePanels.propTypes = {
    windowWidth: PropTypes.number.isRequired,
  };

  const composedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component';

  canResizePanels.displayName = `canResizePanels(${composedComponentName})`;
  return withWindowSize(canResizePanels);
};

export default withCanResizePanels;
