import axios from 'axios';
import {
  CHANNEL,
  getUnverifiedUserRequestAllowedConfig,
  WORKSPACE,
} from 'DataLayer/endpoints';
import {
  saveOne,
  getChannelsByWorkspaceId as getFromDb,
  saveMany as saveToDb,
} from 'DataLayer/database/channels';

import fields from './fields';
import { isFeatureEnabled } from 'config/features';
import { FEATURES } from 'config/features.config';

export async function getChannelById(id) {
  const config = getUnverifiedUserRequestAllowedConfig();

  const { data } = await axios.get(`${CHANNEL}/${id}?fields=${fields}`, config);
  saveOne(data);
  return data;
}

export async function getFromCache(workspaceId) {
  if (isFeatureEnabled(FEATURES.CLIENT_CACHING, workspaceId)) {
    return await getFromDb(workspaceId);
  }
  return Promise.resolve(undefined);
}

export async function getFromNetwork(workspaceId) {
  const { data } = await axios.get(
    `${WORKSPACE}/${workspaceId}/channels?fields=${fields}`
  );
  saveToDb(data.Spaces, workspaceId);
  return data.Spaces;
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function getbyWorkspaceId(workspaceId) {
  const cachedChannels = await getFromCache(workspaceId);
  const fetchedPromise = getFromNetwork(workspaceId);
  return { cachedChannels, fetchedPromise };
}
