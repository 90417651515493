import axios from 'axios';
import { PUBLIC_RESOURCES } from 'DataLayer/endpoints';
import fields from './fields';
import process from '../../Resources/process';

export function getById(id, password) {
  return axios
    .get(PUBLIC_RESOURCES, {
      params: {
        linkId: id,
        linkPassword: password,
        fields,
      },
    })
    .then(response => response.data)
    .then(result => ({
      resource: process.single(result.Resource),
      token: result.Token,
    }));
}

export function getPathById(id, publicLinkId, publicLinkPassword) {
  return axios
    .get(`${PUBLIC_RESOURCES}/${id}/path`, {
      params: {
        fields,
        linkId: publicLinkId,
        linkPassword: publicLinkPassword,
      },
    })
    .then(response => response.data.Path)
    .then(path => process.all(path));
}

export function getChildrenById(id, publicLinkId, publicLinkPassword, next) {
  return axios
    .get(`${PUBLIC_RESOURCES}/${id}/children`, {
      params: {
        next,
        linkId: publicLinkId,
        linkPassword: publicLinkPassword,
        fields,
      },
    })
    .then(response => response.data)
    .then(result => ({
      resources: process.all(result.Items),
      next: result.Next,
    }));
}
