import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getLocale } from 'store/Locale/selectors';
import { update as updateUser } from 'store/User/updateUser';
import { isUserLoggedIn } from 'store/User/selectors';
import { setLocale } from 'store/Locale/setLocale';

function ElectronLanguageChange({
  locale,
  isUserLoggedIn,
  onUpdateUser,
  onSetLocale,
}) {
  useEffect(
    () => {
      const onLanguageChanged = (event, newLocale) => {
        if (locale !== newLocale) {
          if (isUserLoggedIn) {
            onUpdateUser({ language: newLocale });
          } else {
            onSetLocale(newLocale);
          }
        }
      };

      window.ipcRenderer?.on('language-changed', onLanguageChanged);

      return () => {
        window.ipcRenderer?.removeListener(
          'language-changed',
          onLanguageChanged
        );
      };
    },
    [isUserLoggedIn, locale, onSetLocale, onUpdateUser]
  );
  useEffect(
    () => {
      window.ipcRenderer?.send('language-changed', locale);
    },
    [locale]
  );

  return null;
}

ElectronLanguageChange.propTypes = {
  locale: PropTypes.string.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  onSetLocale: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    locale: getLocale(state),
    isUserLoggedIn: isUserLoggedIn(state),
  }),
  {
    onUpdateUser: updateUser,
    onSetLocale: setLocale,
  }
)(ElectronLanguageChange);
