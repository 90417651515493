import React from 'react';
import PropTypes from 'prop-types';

import { Popup } from 'semantic-ui-react';
import './contextMenu.scss';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { DotsIcon } from 'Components/Icons/WeTeamIcon';

class ContextMenu extends React.Component {
  state = { isOpen: false };

  handleOpen = event => {
    event.stopPropagation();
    //https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
    document.addEventListener('resize', this.close, { capture: true });
    document.addEventListener('contextmenu', this.close, { capture: true });
    document.addEventListener('click', this.close, { capture: true });
    document.addEventListener('scroll', this.close, { capture: true });
    this.setState({ isOpen: true });
  };

  close = event => {
    window.requestAnimationFrame(() => {
      this.handleClose(event);
    });
  };

  handleClose = event => {
    event.stopPropagation();
    //https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
    document.removeEventListener('resize', this.close, { capture: true });
    document.removeEventListener('contextmenu', this.close, { capture: true });
    document.removeEventListener('click', this.close, { capture: true });
    document.removeEventListener('scroll', this.close, { capture: true });
    this.setState({ isOpen: false });
  };
  render() {
    const { children, trigger, ...rest } = this.props;
    return (
      <Popup
        onClick={event => {
          event.stopPropagation();
        }}
        basic
        className="context-menu-popup"
        flowing
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        on="click"
        hideOnScroll
        position="bottom right"
        trigger={trigger || <WeTeamIcon Icon={DotsIcon} />}
        {...rest}
      >
        <div
          className="context-menu"
          ref={ref => {
            this.contextMenu = ref;
          }}
        >
          {children}
        </div>
      </Popup>
    );
  }
}
ContextMenu.propTypes = {
  children: PropTypes.object.isRequired,
  trigger: PropTypes.node,
};

export default ContextMenu;
