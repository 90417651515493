import { welcomeScreenSeen } from 'DataLayer/Spaces/update';
import { spacesSchema } from './schemas';
import { normalize } from 'normalizr';
import { transformSpace, transformSpaces } from 'DataLayer/Spaces/process';

export const LOAD_SPACES = 'SPACES/LOAD_SUCCESS';
export const SPACES_ADD = 'SPACES/ADD';
export const SPACES_REMOVE = 'SPACES/REMOVE';
export const SPACES_ARCHIVE = 'SPACES/ARCHIVE';
export const SPACES_CAN_MIGRATE = 'SPACES/CAN_MIGRATE';
export const SPACES_RENAME = 'SPACES/RENAME';
export const SPACES_LOCK = 'SPACES/LOCK';
export const SPACES_CHANGE_AVATAR = 'SPACES/CHANGE_AVATAR';
export const COLLABORATORS_ADD = 'COLLABORATORS/ADD';
export const COLLABORATORS_REMOVE = 'COLLABORATORS/REMOVE';
export const COLLABORATORS_CHANGE_TYPE = 'COLLABORATORS/CHANGE_TYPE';
export const COLLABORATORS_UPDATE_PRESENCE = 'COLLABORATORS/UPDATE_PRESENCE';
export const SPACE_ONBOARDING_HIDE = 'SPACES/ONBOARDING/HIDE';
export const SPACES_LAST_ACTIVITY_CHANGED = 'SPACES/ACTIVITY_DATE_CHANGED';

export function spacesLoaded(spaces = [], workspaceId = null) {
  const spacesFinal = transformSpaces(spaces);
  const normalizedSpaces = normalize(spacesFinal, [spacesSchema]);
  const users = Object.assign(
    {},
    normalizedSpaces.entities.owner,
    normalizedSpaces.entities.collaborators
  );
  return {
    type: LOAD_SPACES,
    workspaceId,
    spaces: normalizedSpaces.entities.spaces,
    collaborators: users,
  };
}

export function addSpace(space) {
  const spaceFinal = transformSpace(space);
  const normalizedSpace = normalize(spaceFinal, spacesSchema);
  return {
    type: SPACES_ADD,
    spaces: normalizedSpace.entities.spaces,
    owners: normalizedSpace.entities.owner,
    collaborators: normalizedSpace.entities.collaborators,
  };
}

export function renameSpace(spaceId, name) {
  return {
    type: SPACES_RENAME,
    name,
    spaceId,
  };
}

export function lockSpace(spaceId, locked) {
  return {
    type: SPACES_LOCK,
    spaceId,
    locked,
  };
}

export function updateLastUpdatedTimestamp(spaceId, timestamp) {
  return {
    type: SPACES_LAST_ACTIVITY_CHANGED,
    spaceId,
    timestamp,
  };
}

export function removeSpace(spaceId) {
  return {
    type: SPACES_REMOVE,
    spaceId,
  };
}

export function changeSpaceAvatar(spaceId, avatarUrl) {
  return {
    type: SPACES_CHANGE_AVATAR,
    spaceId,
    avatarUrl,
  };
}

export function archiveSpace(spaceId, archived) {
  return {
    type: SPACES_ARCHIVE,
    spaceId,
    archived,
  };
}

export function canMigrateSpace(spaceId, canMigrate) {
  return {
    type: SPACES_CAN_MIGRATE,
    spaceId,
    canMigrate,
  };
}

export function addCollaborators(spaceId, collaborators, collaboratorIds) {
  return {
    type: COLLABORATORS_ADD,
    spaceId,
    collaborators,
    collaboratorIds,
  };
}

export function removeCollaborator(spaceId, collaboratorId) {
  return {
    type: COLLABORATORS_REMOVE,
    spaceId,
    collaboratorId,
  };
}

export function changeCollaboratorType(
  collaboratorId,
  collaboratorEmail,
  collaboratorType
) {
  return {
    type: COLLABORATORS_CHANGE_TYPE,
    collaboratorId,
    collaboratorEmail,
    collaboratorType,
  };
}

export const updateCollaboratorPresence = (
  email,
  name,
  presence,
  lastOnline
) => ({
  type: COLLABORATORS_UPDATE_PRESENCE,
  email,
  presence,
  lastOnline,
});

export const hideSpaceOnboarding = spaceId => dispatch => {
  welcomeScreenSeen(spaceId);
  dispatch({
    type: SPACE_ONBOARDING_HIDE,
    spaceId,
  });
};
