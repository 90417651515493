import getInitials from 'Helpers/Strings/getInitials';

export default function(me) {
  const user = Object.assign({}, me);

  if (!user.Features) {
    me.Features = {};
  }

  user.FullName = `${user.FirstName} ${user.LastName}`;

  user.Initials = getInitials(user.FirstName, user.LastName, user.UserID);

  return user;
}
