import PropTypes from 'prop-types';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';
import addHours from 'date-fns/add_hours';
import addMinutes from 'date-fns/add_minutes';
import setHour from 'date-fns/set_hours';
import setMinute from 'date-fns/set_minutes';
import addDays from 'date-fns/add_days';
import setSeconds from 'date-fns/set_seconds';
import setMilliseconds from 'date-fns/set_milliseconds';
import isPast from 'date-fns/is_past';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { Button, Message } from 'semantic-ui-react';

import { formatDate, getDayPickeri18nProps } from 'Helpers/dates/index';
import TimePicker from './TimePicker.jsx';

export const PRESETS = {
  tenMinutes: '10 mins',
  twentyMinutes: '20 mins',
  oneHour: '1 hour',
  today: 'Today @ 04:30 PM',
  tomorrow: 'Tomorrow @ 09:30 AM',
  custom: 'custom',
};

class ReminderPresets extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: props.value,
      time: {
        hour: dateFnsFormat(props.value, 'HH'),
        minute: dateFnsFormat(props.value, 'mm'),
        //isMorning: dateFnsFormat(props.value, 'A') === 'AM',
      },
    };
  }

  componentDidMount() {
    this.props.onChange(this.getFullReminderDate(), this.props.presetValue);
  }

  // get24Hour() {
  //   const hourInteger = parseInt(this.state.time.hour);
  //   if (hourInteger === 12) {
  //     return this.state.time.isMorning ? 0 : 12;
  //   } else if (this.state.time.isMorning) {
  //     return hourInteger;
  //   } else {
  //     return hourInteger + 12;
  //   }
  // }

  getFullReminderDate(preset = this.props.presetValue) {
    let date;
    switch (preset) {
      case PRESETS.tenMinutes:
        date = addMinutes(new Date(), 10);
        break;
      case PRESETS.twentyMinutes:
        date = addMinutes(new Date(), 20);
        break;
      case PRESETS.oneHour:
        date = addHours(new Date(), 1);
        break;
      case PRESETS.today:
        let today = setHour(new Date(), 16);
        today = setMinute(today, 30);
        date = today;
        break;
      case PRESETS.tomorrow:
        let tomorrow = addDays(new Date(), 1);
        tomorrow = setHour(tomorrow, 9);
        tomorrow = setMinute(tomorrow, 30);
        date = tomorrow;
        break;
      case PRESETS.custom:
      default:
        let customDate = setHour(
          this.state.date,
          this.state.time.hour /*this.get24Hour()*/
        );
        customDate = setMinute(customDate, this.state.time.minute);
        date = customDate;
    }
    return setMilliseconds(setSeconds(date, 0), 0);
  }

  onTimeChange = (hour, minute, isMorning) => {
    const { onChange, presetValue } = this.props;
    this.setState(
      state => {
        return {
          time: {
            hour,
            minute,
            //isMorning,
          },
        };
      },
      () => {
        onChange(this.getFullReminderDate(), presetValue);
      }
    );
  };

  onDateChange = value => {
    const { onChange, presetValue } = this.props;
    this.setState(
      {
        date: value,
      },
      () => {
        onChange(this.getFullReminderDate(), presetValue);
      }
    );
  };

  render() {
    const { onChange, presetValue, value, intl } = this.props;
    const { time } = this.state;

    return (
      <>
        <div>
          {Object.entries(PRESETS).map(([key, preset]) => (
            <Button
              key={preset}
              data-testid={`preset-button-${preset}`}
              basic
              compact
              size="small"
              type="button"
              active={presetValue === preset}
              onClick={() => {
                onChange(this.getFullReminderDate(preset), preset);
              }}
            >
              <FormattedMessage id={key} />
            </Button>
          ))}
        </div>
        {presetValue === PRESETS.custom && (
          <div className="flex mt-4" data-testid="presets">
            <DayPickerInput
              dayPickerProps={{
                selectedDays: value,
                locale: intl.locale,
                ...getDayPickeri18nProps(intl),
              }}
              onDayChange={this.onDateChange}
              onChange={this.onDateChange}
              value={value}
              format="ddd DD MMM YYYY"
              formatDate={formatDate}
              inputProps={{ readOnly: true }}
            />
            <TimePicker
              onChange={this.onTimeChange}
              hour={time.hour}
              minute={time.minute}
              //isMorning={time.isMorning}
              className="ml-2"
            />
          </div>
        )}
        {isPast(value) && (
          <Message info data-testid="past-date-warning">
            <FormattedMessage id="ReminderPresets.PastDateWarningText" />
          </Message>
        )}
      </>
    );
  }
}

ReminderPresets.propTypes = {
  onChange: PropTypes.func.isRequired,
  presetValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ReminderPresets);
