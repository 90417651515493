import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Header, Message, Modal, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import RecurlyPaymentFormContainer from 'Components/Recurly/RecurlyPaymentFormContainer';

import styles from './BillingInformationModal.module.css';
function BillingInformationModal({
  workspace,
  onComplete,
  title,
  message,
  dismissButton,
}) {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onPaymentComplete = () => {
    setSuccess(true);
  };

  const onClose = () => {
    onComplete();
  };

  if (success) {
    return (
      <Modal size="tiny" open onClose={onClose}>
        <Modal.Content className={styles.content}>
          {/* eslint-disable react/jsx-no-literals */}
          <div className={`${styles.emoji} flex justify-content-center mb-3`}>
            🎉
          </div>
          {/* eslint-enable react/jsx-no-literals */}
          <FormattedMessage id="BillingInformationModal.Complete" />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={onClose}>
            <FormattedMessage id="BillingInformationModal.CloseButtonText" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <Modal size="small" open>
      <Modal.Content className={styles.content}>
        <div className="pb-1">
          <Header>{title}</Header>
        </div>
        <br />
        {message}
        <Segment className={styles.paymentForm}>
          {error && (
            <Message error>
              <FormattedMessage id="BillingInformationModal.Error" />
            </Message>
          )}
          <RecurlyPaymentFormContainer
            workspaceId={workspace.Id}
            onComplete={onPaymentComplete}
            onError={setError}
            submitButtonText={
              <FormattedMessage id="BillingInformation.SubmitButtonText" />
            }
            dismissButton={dismissButton}
          />
        </Segment>
      </Modal.Content>
    </Modal>
  );
}

BillingInformationModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  dismissButton: PropTypes.bool,
};

export default BillingInformationModal;
