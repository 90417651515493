import { schema } from 'normalizr';

export const collaboratorSchema = new schema.Entity('collaborators');
export const ownerSchema = new schema.Entity('owner');

export const spacesSchema = new schema.Entity(
  'spaces',
  {
    Collaborators: [collaboratorSchema],
    Owner: ownerSchema,
  },
  { idAttribute: 'Id' }
);
