function ManageParticipantsIcon(props) {
  return (
    <svg fill="none" height={24} width={24} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7a2 2 0 11-4 0 2 2 0 014 0zM8 7a4 4 0 108 0 4 4 0 00-8 0zm4 5c-6.014 0-8 2.25-8 6.75 0 1.5.667 2.25 2 2.25h8.758a4.5 4.5 0 103.812-7c-1.216-1.333-3.296-2-6.57-2zm4.164 2.653C15.236 14.191 13.894 14 12 14c-4.698 0-6 1.174-6 4.75 0 .11.004.191.007.25h8.02a4.498 4.498 0 012.136-4.347zM19 18h2v1h-2v2h-1v-2h-2v-1h2v-2h1v2z"
        fill="#fff"
      />
    </svg>
  );
}

export default ManageParticipantsIcon;
