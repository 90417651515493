export function getMediaInfoById(state, id) {
  if (state.mediaInfo[id]) {
    return state.mediaInfo[id].mediaInfo;
  }
  return null;
}

export function getMediaInfoErrorById(state, id) {
  if (state.mediaInfo[id]) {
    return state.mediaInfo[id].error;
  }
  return null;
}

export function isFetching(state, id) {
  if (state.mediaInfo[id]) {
    return state.mediaInfo[id].isFetching;
  }
  return null;
}

export default getMediaInfoById;
