import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import orderBy from 'lodash/orderBy';

import {
  fetchResourceTagsByChannelId,
  fetchTagsByChannelId,
} from 'DataLayer/Tags/get';
import { putByResourceId, putByTagId } from 'DataLayer/Tags/put';

import { getTagData } from 'store/Connectivity';
import { post } from 'DataLayer/Tags/post';
import { deleteByTagId } from 'DataLayer/Tags/delete';

const getResourceTagQueryKey = channelId => ['RESOURCE_TAGS', channelId];
const getChannelTagQueryKey = channelId => ['CHANNEL_TAGS', channelId];

export const useFetchResourceTagsByChannelId = channelId =>
  useQuery(
    getResourceTagQueryKey(channelId),
    () => fetchResourceTagsByChannelId(channelId),
    {
      initialData: {},
    }
  );

export const useFetchTagsByChannelId = channelId =>
  useQuery(
    getChannelTagQueryKey(channelId),
    () => fetchTagsByChannelId(channelId),
    {
      select: tags => orderBy(tags, tag => tag.name.toLowerCase(), 'asc'),
      initialData: [],
    }
  );

export const useUpdateResourceTags = (channelId, resourceId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putByResourceId,
    onSuccess: async () => {
      await queryClient.invalidateQueries(getResourceTagQueryKey(channelId));
    },
  });
};

export const useUpdateTag = channelId => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putByTagId,
    onSuccess: () => {
      queryClient.invalidateQueries(getChannelTagQueryKey(channelId));
      queryClient.invalidateQueries(getResourceTagQueryKey(channelId));
    },
  });
};

export const useDeleteTag = channelId => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteByTagId,
    onSuccess: () => {
      queryClient.invalidateQueries(getChannelTagQueryKey(channelId));
      queryClient.invalidateQueries(getResourceTagQueryKey(channelId));
    },
  });
};

export const useCreateChannelTag = channelId => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: post,
    onSuccess: () => {
      queryClient.invalidateQueries(getChannelTagQueryKey(channelId));
    },
  });
};

export const useMutateChannelTagsFromWebSocket = channelId => {
  const queryClient = useQueryClient();

  const tagData = useSelector(getTagData);

  useEffect(() => {
    queryClient.invalidateQueries(getChannelTagQueryKey(channelId));
  }, [channelId, queryClient, tagData]);
};

export const useMutateResourceTagsFromWebSocket = (channelId, resourceId) => {
  const queryClient = useQueryClient();

  const tagData = useSelector(getTagData);

  useEffect(() => {
    if (tagData.resourceId === resourceId) {
      queryClient.invalidateQueries(getResourceTagQueryKey(channelId));
    }
  }, [channelId, queryClient, resourceId, tagData]);
};
