export const ONLINE_STATUS_CHANGE = 'REPOSITORIES/ONLINE_STATUS_CHANGE';
export const REPOSITORIES_UPDATE_QUOTA = 'REPOSITORIES/UPDATE_QUOTA';

export function changeOnlineStaus(repositoryId, status) {
  return {
    type: ONLINE_STATUS_CHANGE,
    id: repositoryId,
    status,
  };
}

export function updateQuota(repositoryId, totalSpace, usedSpace) {
  return {
    type: REPOSITORIES_UPDATE_QUOTA,
    id: repositoryId,
    totalSpace,
    usedSpace,
  };
}
