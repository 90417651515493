/*
 Export all modal components available in our application
*/

import * as contentTypes from 'DataLayer/Resources/Helpers/contentTypes';
import PreviewVideo from './PreviewVideo';
import PreviewAudio from './PreviewAudio';

export default {
  /* unavailable */
  [contentTypes.BINARY]: null,
  [contentTypes.OTIXO_ENCRYPTED]: null,
  /* images */
  [contentTypes.BMP]: null,
  [contentTypes.GIF]: null,
  [contentTypes.JPEG]: null,
  [contentTypes.PNG]: null,
  [contentTypes.TGA]: null,
  [contentTypes.TIFF]: null,
  [contentTypes.SVG]: null,
  [contentTypes.ICO]: null,
  [contentTypes.PSD]: null,
  [contentTypes.AI]: null,
  [contentTypes.EPS]: null,
  [contentTypes.PS]: null,
  /* audio */
  [contentTypes.M4A]: PreviewAudio,
  [contentTypes.MIDI]: PreviewAudio,
  [contentTypes.MP3]: PreviewAudio,
  [contentTypes.OGG]: PreviewAudio,
  [contentTypes.WAV]: PreviewAudio,
  [contentTypes.AAC]: PreviewAudio,
  [contentTypes.M3U]: PreviewAudio,
  [contentTypes.RA]: PreviewAudio,
  [contentTypes.WMA]: PreviewAudio,
  [contentTypes.OGV]: PreviewAudio,
  /* video */
  [contentTypes.M2V]: PreviewVideo,
  [contentTypes.THREEGP]: PreviewVideo,
  [contentTypes.THREEG2]: PreviewVideo,
  [contentTypes.M4V]: PreviewVideo,
  [contentTypes.WEBM]: PreviewVideo,
  [contentTypes.MPEG]: PreviewVideo,
  [contentTypes.AVI]: null,
  [contentTypes.FLV]: null,
  [contentTypes.MOV]: PreviewVideo,
  [contentTypes.MP4]: PreviewVideo,
  [contentTypes.WMV]: PreviewVideo,
  [contentTypes.M3U8]: PreviewVideo,
  [contentTypes.RM]: PreviewVideo,
  [contentTypes.WMF]: PreviewVideo,
  [contentTypes.SWF]: PreviewVideo,
  /* text */
  [contentTypes.CSV]: null,
  [contentTypes.HTML]: null,
  [contentTypes.TAB]: null,
  [contentTypes.TEXT]: null,
  [contentTypes.XML]: null,
  [contentTypes.CSS]: null,
  [contentTypes.JAVASCRIPT]: null,
  [contentTypes.JSON]: null,
  /* office */
  [contentTypes.MS_PRESENTATION]: null,
  [contentTypes.MS_PRESENTATION_XML]: null,
  [contentTypes.MS_SPREADSHEET]: null,
  [contentTypes.MS_SPREADSHEET_XML]: null,
  [contentTypes.MS_WORD]: null,
  [contentTypes.MS_WORD_XML]: null,
  [contentTypes.XPS]: null,
  [contentTypes.OO_SPREADSHEET]: null,
  [contentTypes.OO_TEXT]: null,
  [contentTypes.OO_PRESENTATION]: null,
  /* text */
  [contentTypes.RTF]: null,
  [contentTypes.SUN_WRITER]: null,
  [contentTypes.GOOGLE_DOCUMENT]: null,
  [contentTypes.GOOGLE_PRESENTATION]: null,
  [contentTypes.GOOGLE_SPREADSHEET]: null,
  [contentTypes.GOOGLE_DRAWING]: null,
  [contentTypes.APPLE_PAGES]: null,
  [contentTypes.AUTOCAD]: null,
  [contentTypes.ONE_NOTE]: null,
  [contentTypes.EPUB]: null,
  [contentTypes.EVERNOTE]: null,
  [contentTypes.KINDLE]: null,
  [contentTypes.MOBI]: null,
  [contentTypes.GZIP]: null,
  [contentTypes.ZIP]: null,
  [contentTypes.Z7]: null,
  [contentTypes.DEB]: null,
  [contentTypes.RPM]: null,
  [contentTypes.PKG]: null,
  [contentTypes.RAR]: null,
  [contentTypes.TAR]: null,
  [contentTypes.FNT]: null,
  [contentTypes.FON]: null,
  [contentTypes.OTF]: null,
  [contentTypes.TTF]: null,
  [contentTypes.APP]: null,
  [contentTypes.BAT]: null,
  [contentTypes.COM]: null,
  [contentTypes.EXE]: null,
  [contentTypes.JAR]: null,
  [contentTypes.SH]: null,
};
