import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { ERROR_CODES } from 'DataLayer/error';

import { getSorting } from '../Sorting/selectors';
import { SCREENS } from '../Sorting';

import constants from './constants';

//const selectedResources = state => state.resources.ids.selectedResourceIds;
const getResources = state => state.entities.resources;

/*
export const getSelectedResources = createSelector(
  [selectedResources, getResources],
  (selectedIds, resources) => selectedIds.map(id => resources[id]),
);
*/
export const getSelectedResourcesByIds = createSelector(
  [getResources, (_, selectedIds) => selectedIds],
  (resources, selectedIds) => selectedIds.map(id => resources[id])
);

export const hasFolderInResources = createSelector(
  [getResources, (_, selectedIds) => selectedIds],
  (resources, selectedIds) => {
    return (
      Object.values(selectedIds)
        .flat()
        .filter(id => resources[id].Folder).length > 0
    );
  }
);

/*
export function getSelectedResources(state, parentId) {
  if (!state.resources)ids. {
    return [];
  }
  if (!state.resources[ids.parentId]) {
    return [];
  }
  return state.resources.ids.selectedResourceIds.map(
    id => state.entities.resources[id],
  );
}
*/
const internalGetResourcesByParentId = (state, parentResourceId) =>
  state.resources.ids[parentResourceId];

const getEntities = state => state.entities.resources;

export const getResourcesByParentId = createSelector(
  [internalGetResourcesByParentId, getEntities],
  (resourceIds, entities) => {
    if (!resourceIds) {
      return [];
    }
    return resourceIds.map(id => entities[id]);
  }
);

export function getRootResources(state) {
  return getResourcesByParentId(state, constants.ROOT_RESOURCES);
}

export function makeGetResourceFoldersByParentId() {
  return createSelector(
    [internalGetResourcesByParentId, getEntities],
    (resourceIds, entities) => {
      if (!resourceIds) {
        return [];
      }
      return resourceIds.map(id => entities[id]).filter(r => r.Folder);
    }
  );
}

// TODO: Remove use
export function getResourceFoldersByParentId(state, parentResourceId) {
  return getResourcesByParentId(state, parentResourceId).filter(r => r.Folder);
}

export const getResourceFilesByParentId = createSelector(
  [getResourcesByParentId],
  resources => resources.filter(r => !r.Folder)
);

export const getResourcesTagFilter = (state, parentResourceId) =>
  state.resources.tagFilter[parentResourceId];

export const hasFiltersApplied = createSelector(
  [getResourcesTagFilter],
  ids => {
    return ids;
  }
);

export const getResourcesByParentIdOrdered = createSelector(
  [
    internalGetResourcesByParentId,
    getResources,
    getSorting,
    getResourcesTagFilter,
  ],
  (resourceIds, allResources, sorting, taggedResourceIds) => {
    if (!resourceIds) return [];

    const finalResourceIds = taggedResourceIds
      ? taggedResourceIds.filter(id => resourceIds.includes(id))
      : resourceIds;

    const resources = finalResourceIds.map(id => allResources[id]);

    const { direction, column } = sorting[SCREENS.RESOURCES];

    return orderBy(
      resources,
      ['Folder', resource => resource[column]],
      ['desc', direction]
    );
  }
);

export const getResourceIdsByParentIdOrdered = createSelector(
  [getResourcesByParentIdOrdered],
  resources => resources.map(r => r.Id)
);

export function getResourceById(state, id) {
  return state.entities.resources[id] || {};
}

export const getResourcesBeingDeleted = state =>
  state.resources.ids.deletingResourceIds;

export const makeGetIsResourceBeingDeleted = () =>
  createSelector(
    [getResourcesBeingDeleted, (_, id) => id],
    (deletingResourceIds, id) =>
      deletingResourceIds.some(resourceId => resourceId === id)
  );

export function isFetching(state, id) {
  if (state.resources.fetching[id]) {
    return state.resources.fetching[id];
  }
  return false;
}

export function hasError(state, id) {
  if (state.resources.error[id]) {
    return state.resources.error[id];
  }
  return false;
}

export function getErrorCode(state, id) {
  const error = state.resources.error[id];
  if (error) {
    return error.code || ERROR_CODES.GENERAL;
  }
  return null;
}
