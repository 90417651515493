import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedDate, injectIntl, intlShape } from 'react-intl';

import { getNotificationLink } from 'pages';
import NotificationText from './NotificationText';
import FriendlyDate from 'Components/DateTime/FriendlyDate';
import CollaboratorAvatarContainer from 'Components/Collaborators/CollaboratorAvatarContainer';
import { Label } from 'semantic-ui-react';

class Notification extends Component {
  renderLocation() {
    const { notification, showWorkspaceName } = this.props;
    if (!notification.Workspace) {
      return null;
    }

    if (showWorkspaceName && notification.Workspace.WorkspaceName) {
      return `in ${notification.Workspace.WorkspaceName} → ${notification.Workspace.Name}`;
    }
    return `in ${notification.Workspace.Name}`;
  }

  render() {
    const {
      notification,
      canClick,
      showMetaInformation,
      showAvatar,
      highlight,
      truncate,
      router,
      intl,
    } = this.props;

    if (!notification) {
      return null;
    }

    return (
      <div
        className={`${canClick ? 'cursor-pointer' : ''} padding-right`}
        onClick={() => {
          if (canClick) {
            router.push(
              getNotificationLink(notification.Workspace.Id, notification.Id)
            );
          }
        }}
      >
        <div className="flex">
          {showAvatar && (
            <div className="mr-3">
              <CollaboratorAvatarContainer
                email={notification.CreatedBy.UserID}
                spaceId={notification.Workspace.Id}
                fallbackName={notification.CreatedBy.FirstName}
                height={30}
                width={30}
              />
            </div>
          )}
          <div className="flex-auto">
            <div className="flex align-items-center">
              <strong>
                {`${notification.CreatedBy.FirstName} ${notification.CreatedBy.LastName}`}
              </strong>
              &nbsp;&nbsp;
              <span className="light-text">
                <FormattedDate
                  value={notification.NotificationTimestamp}
                  hour="2-digit"
                  minute="2-digit"
                />
              </span>
              {!notification.NotificationReadState && (
                <Label
                  circular
                  color="red"
                  empty
                  size="tiny"
                  className="ml-2"
                />
              )}
            </div>
            <NotificationText
              notification={notification}
              intl={intl}
              truncate={truncate}
              highlight={highlight}
            />
          </div>
        </div>
        {showMetaInformation && (
          <small className="flex justify-content-space-between mt-3 light-text">
            {this.renderLocation()}
            <span>
              <FriendlyDate date={notification.NotificationTimestamp} />
            </span>
          </small>
        )}
      </div>
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  canClick: PropTypes.bool,
  showWorkspaceName: PropTypes.bool,
  showMetaInformation: PropTypes.bool,
  showAvatar: PropTypes.bool,
  highlight: PropTypes.string,
  truncate: PropTypes.bool,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

Notification.defaultProps = {
  showAvatar: true,
  canClick: true,
  showMetaInformation: true,
  truncate: false,
};

export default withRouter(injectIntl(Notification));
