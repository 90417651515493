import axios from 'axios';
import { CHANNEL, COMMENTS } from 'DataLayer/endpoints';

export function createComment(spaceId, resourceId, text) {
  if (resourceId) {
    return axios
      .post(`${CHANNEL}/${spaceId}/${COMMENTS}/${resourceId}`, {
        text,
      })
      .then(data => data.data);
  }
  return axios
    .post(`${CHANNEL}/${spaceId}/${COMMENTS}`, {
      text,
    })
    .then(data => data.data);
}
