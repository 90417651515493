import { useCallback, useState } from 'react';
import useMountedState from './useMountedState';
export default function useAsyncFn(
  fn,
  deps = [],
  initialState = { loading: false }
) {
  const [state, set] = useState(initialState);
  const isMounted = useMountedState();

  const reset = useCallback(
    () => {
      set(initialState);
    },
    [initialState]
  );

  const callback = useCallback((...args) => {
    set({ loading: true });
    return fn(...args).then(
      value => {
        if (isMounted()) {
          set({ value, loading: false });
        }
        return value;
      },
      error => {
        if (isMounted()) {
          set({ error, loading: false });
        }
        return error;
      }
    );
  }, deps); // eslint-disable-line
  return [state, callback, reset];
}
