/*
  This state is persisted to localStorage and restored after a browser refresh.
  If you want to store UI state that is reset after a browser refresh,
  use store/UITemporary/index.js
*/

import { SORT_TYPE } from 'store/Spaces';
import { get } from 'DataLayer/WebUIPreferences/get';
import isEmpty from 'lodash/isEmpty';
import { displaySuccessToast } from 'store/Toast/displayToast';
import {
  teamspaceArchivedAnalyticsEvent,
  teamspaceUnarchivedAnalyticsEvent,
} from 'Components/GoogleAnalytics/events';

export const SIDEBAR_ITEMS = {
  files: 'files',
  members: 'members',
  search: 'search',
  starredmessages: 'starredmessages',
  reminders: 'reminders',
  notes: 'notes',
};

/* 
  This is used by a redux-saga that handles all actions which start with the following prefix.
  The saga then saves the ui related slice of state to the server.
*/
export const UI_ACTION_TYPE_PREFIX = 'UI/';

const createUIActionType = type => `${UI_ACTION_TYPE_PREFIX}${type}`;

const CHANNEL_SELECTED = createUIActionType('CHANNEL_SELECTED');

const PRIVATE_CHAT_SIDEBAR_INFO_CHANGED = createUIActionType(
  'PRIVATE_CHAT_SIDEBAR_INFO_CHANGED'
);
const FILE_VIEW_TYPE_SELECTED = createUIActionType('FILE_VIEW_TYPE_SELECTED');
const FILES_FULLSCREEN_CHANGED = createUIActionType('FILES_FULLSCREEN_CHANGED');
const WORKSPACES_SIDEBAR_CHANGED = createUIActionType(
  'WORKSPACES_SIDEBAR_CHANGED'
);
const APP_NAVIGATION_MENU_CHANGED = createUIActionType(
  'APP_NAVIGATION_MENU_CHANGED'
);
const CHAT_PREVIEW_HIDDEN = createUIActionType('CHAT_PREVIEW_HIDDEN');
const SPACE_SORT_CHANGED = createUIActionType('SPACE_SORT_CHANGED');
const WORKSPACE_SORT_CHANGED = createUIActionType('WORKSPACE_SORT_CHANGED');
const SPACE_SIDEBAR_PANEL_SELECTED = createUIActionType(
  'SPACE_SIDEBAR_PANEL_SELECTED'
);

const MARK_CHANNEL_AS_UNREAD = createUIActionType('MARK_CHANNEL_AS_UNREAD');

export const MARK_CHANNEL_AS_READ = createUIActionType('MARK_CHANNEL_AS_READ');

const APPS_PROMOTION_BANNER_HIDDEN = createUIActionType(
  'APPS_PROMOTION_BANNER_HIDDEN'
);

const DESKTOP_APP_PROMOTION_BANNER_HIDDEN = createUIActionType(
  'DESKTOP_APP_PROMOTION_BANNER_HIDDEN'
);

const BACKUP_PROMOTION_BANNER_HIDDEN = createUIActionType(
  'BACKUP_PROMOTION_BANNER_HIDDEN'
);

const ENTER_BILLING_INFORMATION_REMINDER_HIDDEN = createUIActionType(
  'ENTER_BILLING_INFORMATION_REMINDER_HIDDEN'
);

const ENTER_BILLING_INFORMATION_REMINDER_POSTPONED = createUIActionType(
  'ENTER_BILLING_INFORMATION_REMINDER_POSTPONED'
);

const POPULAR_TAG_ADDED = createUIActionType('POPULAR_TAG_ADDED');

const WE_TEAM_LAUNCH_BANNER_HIDDEN = createUIActionType(
  'WE_TEAM_LAUNCH_BANNER_HIDDEN'
);

export const UI_PREFERENCES_LOADED = 'UI_PREFERENCES_LOADED';

const WORKSPACE_POSITIONS_CHANGED = createUIActionType(
  'WORKSPACE_POSITIONS_CHANGED'
);

const WORKSPACE_ARCHIVED = createUIActionType('WORKSPACE_ARCHIVED');

const CHANNEL_POSITIONS_CHANGED = createUIActionType(
  'CHANNEL_POSITIONS_CHANGED'
);

export const SPACES_LIST_TYPE = {
  archived: 'archived',
  team: 'team',
  single: 'single',
  private: 'private',
  privateArchived: 'privateArchived',
};

export const WORKSPACE_SORT_TYPE = {
  custom: 'custom',
  unread: 'unread',
};

export const getWebUiPreferences = () => dispatch => {
  return get().then(preferences => {
    dispatch({
      type: UI_PREFERENCES_LOADED,
      preferences,
    });
  });
};

export const appsPromotionBannerHidden = () => ({
  type: APPS_PROMOTION_BANNER_HIDDEN,
});

export const desktopAppPromotionBannerHidden = () => ({
  type: DESKTOP_APP_PROMOTION_BANNER_HIDDEN,
});

export const backupPromotionBannerHidden = () => ({
  type: BACKUP_PROMOTION_BANNER_HIDDEN,
});

export const enterBillingInformationReminderHidden = workspaceId => ({
  type: ENTER_BILLING_INFORMATION_REMINDER_HIDDEN,
  workspaceId,
});

export const enterBillingInformationReminderPostponed = workspaceId => ({
  type: ENTER_BILLING_INFORMATION_REMINDER_POSTPONED,
  workspaceId,
});

export const weTeamLaunchBannerHidden = () => ({
  type: WE_TEAM_LAUNCH_BANNER_HIDDEN,
});

export const popularTagAdded = tag => {
  if (!tag) return;
  return {
    type: POPULAR_TAG_ADDED,
    tag,
  };
};

export const spaceSidebarPanelSelected = (id, panel, forceOpen = false) => ({
  type: SPACE_SIDEBAR_PANEL_SELECTED,
  id,
  panel,
  forceOpen,
});

export const channelMarkedAsUnread = (channelId, workspaceId) => ({
  type: MARK_CHANNEL_AS_UNREAD,
  channelId,
  workspaceId,
});

export const workspaceSortChanged = sortType => ({
  type: WORKSPACE_SORT_CHANGED,
  sortType,
});

export const spaceSortChanged = (workspaceId, listType, sortType) => ({
  type: SPACE_SORT_CHANGED,
  workspaceId,
  listType,
  sortType,
});

export const channelSelected = (workspaceId, channelId) => ({
  type: CHANNEL_SELECTED,
  workspaceId,
  channelId,
});

export const privateChatSidebarInfoChanged = hidden => ({
  type: PRIVATE_CHAT_SIDEBAR_INFO_CHANGED,
  hidden,
});

export const fileViewTypeChanged = fileViewType => ({
  type: FILE_VIEW_TYPE_SELECTED,
  fileViewType,
});

export const filesFullscreenChanged = fullScreen => ({
  type: FILES_FULLSCREEN_CHANGED,
  fullScreen,
});

export const workspacesSidebarChanged = open => ({
  type: WORKSPACES_SIDEBAR_CHANGED,
  open,
});

export const appNavitationMenuChanged = open => ({
  type: APP_NAVIGATION_MENU_CHANGED,
  open,
});

export const chatPreviewHidden = id => ({
  type: CHAT_PREVIEW_HIDDEN,
  id,
});

export const workspacePositionsChanged =
  workspacePositions => async dispatch => {
    dispatch({
      type: WORKSPACE_POSITIONS_CHANGED,
      workspacePositions,
    });
  };

export const workspaceArchived = (workspaceId, archived) => dispatch => {
  dispatch(
    displaySuccessToast(
      archived
        ? 'toast.workspaceArchived.success'
        : 'toast.workspaceUnarchived.success'
    )
  );

  if (archived) {
    teamspaceArchivedAnalyticsEvent();
  } else {
    teamspaceUnarchivedAnalyticsEvent();
  }

  dispatch({
    type: WORKSPACE_ARCHIVED,
    workspaceId,
    archived,
  });
};

export const channelPositionsChanged = (
  workspaceId,
  listType,
  channelPositions
) => ({
  type: CHANNEL_POSITIONS_CHANGED,
  workspaceId,
  channelPositions,
  listType,
});

export const FILE_LIST_VIEW_TYPE = {
  LIST: 'LIST',
  GRID: 'GRID',
};

const initialState = {
  enterBillingInformationReminderHidden: [],
  weTeamLaunchBannerHidden: false,
  appsPromotionBannerHidden: false,
  desktopAppPromotionBannerHidden: false,
  backupPromotionBannerHidden: false,
  workspaceSort: WORKSPACE_SORT_TYPE.custom,
  workspacePositions: [],
  archivedWorkspaces: [],
  workspacesSidebarOpenNew: true,
  appNavitationMenuOpen: true,
  spaceSidebarPanel: null,
  latestWorkspaceId: null,
  latestChannelId: {},
  hidePrivateChatSidebarInfo: false,
  fileViewType: FILE_LIST_VIEW_TYPE.LIST,
  filesFullScreen: false,
  channelPositions: {},
  spaceSort: {},
  chatPreviewHidden: {},
  unreadChannels: {},
  unreadWorkspaces: {},
  enterBillingInformationReminderPostponed: {},
  popularTagsv4: [],
};

function markChannelAsUnread(state, channelId, workspaceId) {
  return {
    ...state,
    unreadChannels: {
      ...state.unreadChannels,
      [channelId]: true,
    },
    unreadWorkspaces: {
      ...state.unreadWorkspaces,
      [workspaceId]: true,
    },
  };
}

function markChannelAsRead(state, channelId, workspaceId) {
  var newState = {
    ...state,
    unreadChannels: {
      ...state.unreadChannels,
      [channelId]: false,
    },
  };
  if (workspaceId) {
    newState = {
      ...newState,
      unreadWorkspaces: {
        ...state.unreadWorkspaces,
        [workspaceId]: false,
      },
    };
  }
  return newState;
}

function toggleSpaceSidebarPanel(state, id, panel, forceOpen) {
  if (state.spaceSidebarPanel === panel && !forceOpen) {
    state.spaceSidebarPanel = null;
  } else {
    state.spaceSidebarPanel = panel;
  }
  return {
    ...state,
    spaceSidebarPanel: state.spaceSidebarPanel,
  };
}

function changeSpaceSortType(state, workspaceId, sortType, listType) {
  const hasSortedManually =
    state[workspaceId]?.hasSortedManually || sortType === SORT_TYPE.custom;
  return {
    ...state,
    [workspaceId]: {
      ...state[workspaceId],
      [listType]: sortType,
      hasSortedManually,
    },
  };
}

/*
  An item only needs to be in the object if it is hidden.
  Therefore, simply remove the item from the object when it should be shown.
*/
function updateChatPreviewHidden(state, id, hidden) {
  const chatPreviewHidden = { ...state.chatPreviewHidden };

  if (chatPreviewHidden[id]) {
    delete chatPreviewHidden[id];
  } else {
    chatPreviewHidden[id] = true;
  }

  return {
    ...state,
    chatPreviewHidden,
  };
}

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case UI_PREFERENCES_LOADED:
      return isEmpty(action.preferences)
        ? // prevent an empty object from the server overwritting our local state
          state
        : /* 
        ensure all properties are returned. 
        Needed for when new properties are added to the state, but they don't 
        exist on the object returned from the server
        */
          { ...initialState, ...action.preferences };
    case WORKSPACE_POSITIONS_CHANGED: {
      return {
        ...state,
        workspacePositions: action.workspacePositions,
      };
    }
    case WORKSPACE_ARCHIVED: {
      if (action.archived) {
        return {
          ...state,
          archivedWorkspaces: [...state.archivedWorkspaces, action.workspaceId],
        };
      }
      return {
        ...state,
        archivedWorkspaces: state.archivedWorkspaces.filter(
          workspaceId => workspaceId !== action.workspaceId
        ),
      };
    }
    case CHANNEL_POSITIONS_CHANGED: {
      /*
        When the user sorts manually, we also want to switch the sort type to Custom
      */
      const newSpaceSort = changeSpaceSortType(
        state.spaceSort,
        action.workspaceId,
        SORT_TYPE.custom,
        action.listType
      );
      return {
        ...state,
        spaceSort: newSpaceSort,
        channelPositions: {
          ...state.channelPositions,
          [action.workspaceId]: {
            ...state.channelPositions?.[action.workspaceId],
            [action.listType]: action.channelPositions,
          },
        },
      };
    }
    case APPS_PROMOTION_BANNER_HIDDEN: {
      return {
        ...state,
        appsPromotionBannerHidden: true,
      };
    }
    case DESKTOP_APP_PROMOTION_BANNER_HIDDEN: {
      return {
        ...state,
        desktopAppPromotionBannerHidden: true,
      };
    }
    case BACKUP_PROMOTION_BANNER_HIDDEN: {
      return {
        ...state,
        backupPromotionBannerHidden: true,
      };
    }
    case ENTER_BILLING_INFORMATION_REMINDER_HIDDEN: {
      return {
        ...state,
        enterBillingInformationReminderHidden: [
          ...state.enterBillingInformationReminderHidden,
          action.workspaceId,
        ],
      };
    }
    case ENTER_BILLING_INFORMATION_REMINDER_POSTPONED: {
      return {
        ...state,
        enterBillingInformationReminderPostponed: {
          ...state.enterBillingInformationReminderPostponed,
          [action.workspaceId]: new Date(),
        },
      };
    }
    case POPULAR_TAG_ADDED: {
      return {
        ...state,
        popularTagsv4: [action.tag, ...state.popularTagsv4],
      };
    }
    case WE_TEAM_LAUNCH_BANNER_HIDDEN: {
      return {
        ...state,
        weTeamLaunchBannerHidden: true,
      };
    }
    case PRIVATE_CHAT_SIDEBAR_INFO_CHANGED: {
      return {
        ...state,
        hidePrivateChatSidebarInfo: action.hidden,
      };
    }
    case FILE_VIEW_TYPE_SELECTED: {
      return {
        ...state,
        fileViewType: action.fileViewType,
      };
    }
    case FILES_FULLSCREEN_CHANGED: {
      return {
        ...state,
        filesFullScreen: action.fullScreen,
      };
    }
    case WORKSPACES_SIDEBAR_CHANGED: {
      return {
        ...state,
        workspacesSidebarOpenNew: action.open,
      };
    }
    case APP_NAVIGATION_MENU_CHANGED: {
      return {
        ...state,
        appNavitationMenuOpen: action.open,
      };
    }
    case CHAT_PREVIEW_HIDDEN: {
      return updateChatPreviewHidden(state, action.id);
    }
    case CHANNEL_SELECTED: {
      if (!action.workspaceId || !action.channelId) {
        return state;
      }
      return {
        ...state,
        latestWorkspaceId: action.workspaceId,
        latestChannelId: {
          ...state.latestChannelId,
          [action.workspaceId]: action.channelId,
        },
      };
    }
    case MARK_CHANNEL_AS_UNREAD:
      return markChannelAsUnread(state, action.channelId, action.workspaceId);
    case MARK_CHANNEL_AS_READ:
      return markChannelAsRead(state, action.channelId, action.workspaceId);
    case SPACE_SIDEBAR_PANEL_SELECTED:
      return toggleSpaceSidebarPanel(
        state,
        action.id,
        action.panel,
        action.forceOpen
      );
    case SPACE_SORT_CHANGED: {
      return {
        ...state,
        spaceSort: changeSpaceSortType(
          state.spaceSort,
          action.workspaceId,
          action.sortType,
          action.listType
        ),
      };
    }
    case WORKSPACE_SORT_CHANGED: {
      return {
        ...state,
        workspaceSort: action.sortType,
      };
    }
    default:
      return state;
  }
}
