import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import PendingWorkspaceMemberMessage from './PendingWorkspaceMemberMessage';
import ActionableNotification from '../ActionableNotification';
class PendingWorkspaceMember extends React.Component {
  state = {
    open: true,
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { workspace, member } = this.props;

    return (
      <ActionableNotification
        isOpen={this.state.open}
        loading={this.state.loading}
        onClose={this.close}
        title={
          <FormattedMessage id="PendingWorkspaceMember.member-invitation-request" />
        }
        body={
          <PendingWorkspaceMemberMessage
            workspace={workspace}
            member={member}
            onResolved={this.close}
          />
        }
      />
    );
  }
}

PendingWorkspaceMember.propTypes = {
  dispatch: PropTypes.func.isRequired,
  workspace: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
};

export default connect()(PendingWorkspaceMember);
