import { combineReducers } from 'redux';
import {
  LOAD_SPACES,
  SPACES_RENAME,
  SPACES_LAST_ACTIVITY_CHANGED,
  COLLABORATORS_UPDATE_PRESENCE,
  SPACES_ADD,
  SPACES_REMOVE,
  COLLABORATORS_ADD,
  COLLABORATORS_REMOVE,
  SPACE_ONBOARDING_HIDE,
  SPACES_ARCHIVE,
  COLLABORATORS_CHANGE_TYPE,
  SPACES_CHANGE_AVATAR,
  SPACES_LOCK,
  SPACES_CAN_MIGRATE,
} from './actions';
import { USER_AVATAR_CHANGED } from '../User/actions';
import { convertArrayToObject } from 'Helpers/Array/convertArrayToObject';

export const SORT_TYPE = {
  name: {
    column: 'NameLowerCase',
    direction: 'asc',
  },
  lastUpdateTimestamp: {
    column: 'LastUpdateTimestamp',
    direction: 'desc',
  },
  custom: 'custom',
};

function addSpaceToState(state, spaces) {
  return Object.assign({}, state, { ...spaces });
}

function removeSpaceFromState(state, id) {
  const spaces = Object.assign({}, state);
  delete spaces[id];
  return spaces;
}

function addCollaboratorToSpace(state, id, collaboratorIds) {
  const space = Object.assign({}, state[id]); // clone our object
  space.Collaborators = space.Collaborators.concat(collaboratorIds); // update our object
  return Object.assign({}, state, { [id]: space });
}

function addCollaborators(state, collaborators) {
  return Object.assign({}, state, collaborators);
}

function deleteCollaboratorFromSpace(state, spaceId, collaboratorId) {
  const space = Object.assign({}, state[spaceId]); // clone our object
  space.Collaborators = space.Collaborators.filter(c => c !== collaboratorId);
  return Object.assign({}, state, { [spaceId]: space });
}

function renameSpace(state, id, name) {
  const space = Object.assign({}, state[id]);
  space.Name = name;
  return Object.assign({}, state, { [id]: space });
}

function lockSpace(state, id, locked) {
  const space = Object.assign({}, state[id]);
  space.Locked = locked;
  return Object.assign({}, state, { [id]: space });
}

function archiveSpace(state, id, archived) {
  const space = Object.assign({}, state[id]);
  space.Filtered = archived;
  return Object.assign({}, state, { [id]: space });
}

function canMigrateSpace(state, id, canMigrate) {
  const space = Object.assign({}, state[id]);
  space.CanMigrate = canMigrate;
  return Object.assign({}, state, { [id]: space });
}

function updateLastUpdateTimestamp(state, id, timestamp) {
  if (!state[id]) {
    return state;
  }
  const space = Object.assign({}, state[id]);
  space.LastUpdateTimestamp = timestamp;
  return Object.assign({}, state, { [id]: space });
}

function updateAvatar(state, id, avatarUrl) {
  if (!state[id]) {
    return state;
  }
  const space = Object.assign({}, state[id]);
  space.AvatarUrl = avatarUrl;
  return Object.assign({}, state, { [id]: space });
}

function setWelcomeScreenSeen(state, id) {
  const space = Object.assign({}, state[id]);
  space.WelcomeScreenSeen = true;
  return Object.assign({}, state, { [id]: space });
}

function changeCollaboratorType(state, action) {
  if (action.collaboratorId) {
    return {
      ...state,
      [action.collaboratorId]: {
        ...state[action.collaboratorId],
        Type: action.collaboratorType,
      },
    };
  }

  // find collaborator using email instead
  return Object.values(state).reduce((object, collaborator) => {
    object[collaborator.id] = collaborator;
    if (collaborator.Email === action.collaboratorEmail) {
      object[collaborator.id] = {
        ...collaborator,
        Type: action.collaboratorType,
      };
    }
    return object;
  }, {});
}

function changeAvatar(state, email, avatarUrl, color) {
  return Object.values(state).reduce((object, collaborator) => {
    object[collaborator.id] = collaborator;
    if (collaborator.Email === email) {
      object[collaborator.id] = {
        ...collaborator,
        AvatarUrl: avatarUrl,
        Color: color,
      };
    }
    return object;
  }, {});
}

function changeLastOnline(state, email, lastOnline) {
  return Object.values(state).reduce((object, collaborator) => {
    object[collaborator.id] = collaborator;
    if (collaborator.Email === email) {
      object[collaborator.id] = {
        ...collaborator,
        LastOnline: lastOnline,
      };
    }
    return object;
  }, {});
}

function collaboratorsReducer(state = {}, action) {
  switch (action.type) {
    case SPACES_ADD:
      return addCollaborators(
        state,
        Object.assign({}, action.owners, action.collaborators)
      );
    case LOAD_SPACES:
      return {
        ...state,
        ...action.collaborators,
      };
    case COLLABORATORS_ADD:
      return addCollaborators(state, action.collaborators);
    case COLLABORATORS_CHANGE_TYPE: {
      return changeCollaboratorType(state, action);
    }
    case USER_AVATAR_CHANGED: {
      return changeAvatar(state, action.email, action.avatarUrl, action.color);
    }
    case COLLABORATORS_UPDATE_PRESENCE: {
      return changeLastOnline(state, action.email, action.lastOnline);
    }
    default:
      return state;
  }
}

function spacesLoaded(state, spaces, workspaceId) {
  /*
    first remove spaces for the work space
    this ensures any spaces that were since removed
    are also removed from the state
    */
  const spacesFromOtherWorkspaces = Object.values(state).filter(
    space => space.WorkspaceId !== workspaceId
  );
  const spacesFromOtherWorkspacesObject = convertArrayToObject(
    spacesFromOtherWorkspaces,
    'Id'
  );
  return {
    ...spacesFromOtherWorkspacesObject,
    ...spaces,
  };
}

function spacesReducer(state = {}, action) {
  switch (action.type) {
    case SPACES_ADD:
      return addSpaceToState(state, action.spaces);
    case SPACES_REMOVE:
      return removeSpaceFromState(state, action.spaceId);
    case SPACES_ARCHIVE:
      return archiveSpace(state, action.spaceId, action.archived);
    case SPACES_CAN_MIGRATE:
      return canMigrateSpace(state, action.spaceId, action.canMigrate);
    case SPACES_RENAME:
      return renameSpace(state, action.spaceId, action.name);
    case SPACES_LOCK:
      return lockSpace(state, action.spaceId, action.locked);
    case SPACES_LAST_ACTIVITY_CHANGED:
      return updateLastUpdateTimestamp(state, action.spaceId, action.timestamp);
    case SPACES_CHANGE_AVATAR:
      return updateAvatar(state, action.spaceId, action.avatarUrl);
    case LOAD_SPACES:
      return spacesLoaded(state, action.spaces, action.workspaceId);
    case SPACE_ONBOARDING_HIDE:
      return setWelcomeScreenSeen(state, action.spaceId);
    case COLLABORATORS_ADD:
      return addCollaboratorToSpace(
        state,
        action.spaceId,
        action.collaboratorIds
      );
    case COLLABORATORS_REMOVE:
      return deleteCollaboratorFromSpace(
        state,
        action.spaceId,
        action.collaboratorId
      );
    default:
      return state;
  }
}

function reduceCollaboratorsToPresenceObject(collaborators) {
  return Object.values(collaborators).reduce((object, value) => {
    object[value.Email] = value.Presence;
    return object;
  }, {});
}

function presenceReducer(state = {}, action) {
  let presenceObject;
  switch (action.type) {
    case SPACES_ADD:
      presenceObject = reduceCollaboratorsToPresenceObject(action.owners);
      return Object.assign({}, state, presenceObject);
    case COLLABORATORS_ADD:
      presenceObject = reduceCollaboratorsToPresenceObject(
        action.collaborators
      );
      return Object.assign({}, state, presenceObject);
    case LOAD_SPACES:
      return {
        ...state,
        ...reduceCollaboratorsToPresenceObject(action.collaborators),
      };
    case COLLABORATORS_UPDATE_PRESENCE:
      return Object.assign({}, state, {
        [action.email]: action.presence,
      });
    default:
      return state;
  }
}

export default combineReducers({
  spaces: spacesReducer,
  collaborators: collaboratorsReducer,
  presence: presenceReducer,
});
