export const WORKSPACES_REQUESTED = 'WORKSPACES/REQUESTED';
export const WORKSPACES_ERROR = 'WORKSPACES/ERROR';
export const WORKSPACES_LOADED = 'WORKSPACES/LOADED';
export const WORKSPACES_ADD = 'WORKSPACES/ADD';
export const WORKSPACES_REMOVE = 'WORKSPACES/REMOVE';
export const RECENTLY_ADDED_WORKSPACES_LOADED =
  'WORKSPACES/RECENTLY_ADDED_WORKSPACES_LOADED';
export const WORKSPACES_REMOVE_ALL = 'WORKSPACES/REMOVE_ALL';

export function addWorkspaces(workspaces = []) {
  const workspacesObject = workspaces.reduce((object, value) => {
    object[value.Id] = value;
    return object;
  }, {});

  return {
    type: WORKSPACES_LOADED,
    workspaces: workspacesObject,
  };
}

export function recentlyAddedWorkspacesLoaded(
  workspaceIds = [],
  invitationMessages = {}
) {
  return {
    type: RECENTLY_ADDED_WORKSPACES_LOADED,
    workspaceIds,
    invitationMessages,
  };
}

export function addWorkspace(workspace) {
  return {
    type: WORKSPACES_ADD,
    workspace,
  };
}

export function removeWorkspace(workspaceId) {
  return {
    type: WORKSPACES_REMOVE,
    workspaceId,
  };
}

export function removeWorkspaces() {
  return {
    type: WORKSPACES_REMOVE_ALL,
  };
}

export function workspacesRequested() {
  return {
    type: WORKSPACES_REQUESTED,
  };
}

export function workspacesRequestError() {
  return {
    type: WORKSPACES_ERROR,
  };
}
