import axios from 'axios';
import { CONVERSATION } from 'DataLayer/endpoints';
import fields from '../Spaces/fields';
import { getConversationResendUrl } from '../endpoints';

export function post(email) {
  return axios
    .post(CONVERSATION, { email, fields })
    .then(response => response.data);
}

export function resendInvitation(id) {
  return axios
    .post(getConversationResendUrl(id))
    .then(response => response.data);
}
