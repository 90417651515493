import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { spaceSidebarPanelSelected, SIDEBAR_ITEMS } from 'store/UI/index';
import RightSidebar from 'Components/RightSidebar/RightSidebar';
import StarButtonForPrivateChats from './StarButtonForPrivateChats';
import StarButtonForWorkspaces from './StarButtonForWorkspaces';
import ReminderButton from './ReminderButton';
import MeetingButton from './MeetingButton';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { SearchIcon } from 'Components/Icons/WeTeamIcon';
import { FolderIcon } from 'Components/Icons/WeTeamIcon';
import { ProfileIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import { isNotesEnabled } from 'config/features';
import NotesButtonForWorkspaces from 'Views/SpaceHome/ NotesButtonForWorkspaces';

class SpaceSidebar extends Component {
  render() {
    const { space, activePanel, dispatch } = this.props;
    const StarButton = space.IsConversation
      ? StarButtonForPrivateChats
      : StarButtonForWorkspaces;
    return (
      <RightSidebar>
        <DisableInBackupMode>
          <RightSidebar.Button
            openTooltip="SpaceSidebar.Search.Hide"
            closeTooltip="SpaceSidebar.Search.Show"
            open={activePanel === SIDEBAR_ITEMS.search}
            onClick={event => {
              event.stopPropagation();
              dispatch(
                spaceSidebarPanelSelected(space.Id, SIDEBAR_ITEMS.search)
              );
            }}
          >
            <WeTeamIcon
              active={activePanel === SIDEBAR_ITEMS.search}
              Icon={SearchIcon}
            />
          </RightSidebar.Button>
        </DisableInBackupMode>
        {isNotesEnabled() && (
          <NotesButtonForWorkspaces
            channelId={space.Id}
            isActive={activePanel === SIDEBAR_ITEMS.notes}
            onClick={event => {
              event.stopPropagation();
              dispatch(
                spaceSidebarPanelSelected(space.Id, SIDEBAR_ITEMS.notes)
              );
            }}
          />
        )}
        <RightSidebar.Button
          openTooltip="ShowFiles.Hide"
          closeTooltip="ShowFiles.Show"
          open={activePanel === SIDEBAR_ITEMS.files}
          onClick={event => {
            event.stopPropagation();
            dispatch(spaceSidebarPanelSelected(space.Id, SIDEBAR_ITEMS.files));
          }}
        >
          <WeTeamIcon
            active={activePanel === SIDEBAR_ITEMS.files}
            Icon={FolderIcon}
          />
        </RightSidebar.Button>
        {!space.IsChat && (
          <RightSidebar.Button
            openTooltip="MembersButton.Hide"
            closeTooltip="MembersButton.Show"
            open={activePanel === SIDEBAR_ITEMS.members}
            onClick={event => {
              event.stopPropagation();
              dispatch(
                spaceSidebarPanelSelected(space.Id, SIDEBAR_ITEMS.members)
              );
            }}
          >
            <WeTeamIcon
              active={activePanel === SIDEBAR_ITEMS.members}
              Icon={ProfileIcon}
            />
          </RightSidebar.Button>
        )}
        <StarButton
          workspaceId={space.WorkspaceId}
          isActive={activePanel === SIDEBAR_ITEMS.starredmessages}
          onClick={event => {
            event.stopPropagation();
            dispatch(
              spaceSidebarPanelSelected(space.Id, SIDEBAR_ITEMS.starredmessages)
            );
          }}
        />
        <ReminderButton
          workspaceId={space.WorkspaceId}
          isActive={activePanel === SIDEBAR_ITEMS.reminders}
          onClick={event => {
            event.stopPropagation();
            dispatch(
              spaceSidebarPanelSelected(space.Id, SIDEBAR_ITEMS.reminders)
            );
          }}
        />
        <MeetingButton
          workspaceId={space.WorkspaceId}
          defaultChannelId={space.Id}
        />
      </RightSidebar>
    );
  }
}

SpaceSidebar.propTypes = {
  space: PropTypes.object.isRequired,
  activePanel: PropTypes.string,
  dispatch: PropTypes.func,
};

export default connect()(SpaceSidebar);
