import axios from 'axios';
import { CHANNEL, COLLABORATORS } from 'DataLayer/endpoints';
import { saveOne as saveToDb } from 'DataLayer/database/channels';
import fields from './fields';

export async function rename(id, name) {
  const { data } = await axios.put(
    `${CHANNEL}/${id}?name=${encodeURIComponent(name)}&fields=${fields}`
  );
  saveToDb(data);
  return data;
}

export async function update(id, space) {
  const { data } = await axios.put(`${CHANNEL}/${id}?fields=${fields}`, space);
  saveToDb(data);
  return data;
}

export function changeAvatar(id, color) {
  return axios
    .put(`${CHANNEL}/${id}`, {
      //AvatarEmoji: emoji,
      color,
      fields,
    })
    .then(response => response.data);
}

export function leave(id, exportHistory) {
  const exportHistoryFlag = exportHistory ? 'exportHistory' : '';
  return axios
    .put(`${CHANNEL}/${id}/${COLLABORATORS}?status=left&${exportHistoryFlag}`)
    .then(response => response.data.PDFExport);
}

export function join(id) {
  return axios.put(`${CHANNEL}/${id}/${COLLABORATORS}?status=joined`);
}

export function archive(id) {
  return axios.put(`${CHANNEL}/${id}?Filtered=true`);
}

export function unarchive(id) {
  return axios.put(`${CHANNEL}/${id}?Filtered=false`);
}

export function welcomeScreenSeen(id) {
  return axios.put(`${CHANNEL}/${id}?WelcomeScreenSeen=true`);
}
