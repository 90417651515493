import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { SCREENS } from '../Sorting/index';
import { getSortByScreen } from '../Sorting/selectors';
import { getResourceById } from '../Resources/selectors';

import { getAdapters } from '../Adapters/selectors';
import ADAPTERS from 'Constants/adapters';

const allRepositoies = state => state.repositories.repositories;

const allQuotas = state => state.repositories.quotas;

const allOnline = state => state.repositories.online;

export const getRepositories = createSelector(
  [allRepositoies, getAdapters, allQuotas, allOnline],
  (repositories, adapters, quotas, online) =>
    repositories.map(r => {
      const adapter = adapters.find(a => a.Id === r.AdapterId);
      if (adapter) {
        return {
          ...r,
          adapterIcon: adapter.IconSvg,
          adapterName: adapter.Caption,
          IsOAuth: adapter.AuthType.toLowerCase() === 'oauth',
          ...quotas[r.Id],
          Offline: !online[r.Id],
          CanDelete: adapter.CanDelete,
          CanRename: adapter.CanRename,
        };
      }
      return {
        ...r,
        ...quotas[r.Id],
        Offline: !online[r.Id],
      };
    })
);

function getRepositorySort(state) {
  return getSortByScreen(state, SCREENS.REPOSITORIES);
}

export const getRepositoriesOrdered = createSelector(
  [getRepositories, getRepositorySort],
  (repositories, sort) => {
    const { direction, column } = sort;
    return orderBy(repositories, repository => repository[column], direction);
  }
);

export const getRepositoriesOrderedByName = createSelector(
  [getRepositories],
  repositories =>
    orderBy(repositories, repository => repository.Name.toLowerCase(), 'asc')
);

export const getOtixoDrive = createSelector(
  [getRepositories],
  repositories =>
    repositories.find(
      repository => repository.AdapterId === ADAPTERS.OTIXO_DRIVE
    )
);

export const getRepository = createSelector(
  [getRepositories, (_, id) => id],
  (repositories, id) => {
    const repository = repositories.find(r => r.Id === id);
    return repository;
  }
);

export const getRepositoryByResourceId = createSelector(
  [getRepositories, getResourceById, (_, id) => id],
  (repositories, resource, id) => {
    const repository = repositories.find(r => r.Id === resource.RepoId);
    return repository;
  }
);
