import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getReplyInfo } from 'store/Comments/utils/reply';
import LinkifyWrapper from 'Components/Linkify/Linkify';

/*
  Linkify does not work if we pass a <StringToEmojiMart />, so instead pass a string
*/

const UserMessage = ({ message }) => {
  const reply = getReplyInfo(message);
  const text = reply ? reply.reply : message;
  return (
    <LinkifyWrapper className="wrappable-text">
      {reply && <blockquote className="mb-2">{reply.original}</blockquote>}
      {text}
    </LinkifyWrapper>
  );
};

UserMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default memo(UserMessage);
