import { getbyWorkspaceId } from 'DataLayer/Spaces/get';
import { setSpacesRequestInProgress } from '../Requests/setRequestInProgress';

import { spacesLoaded } from './actions';

const loadSpaces = workspaceId => async dispatch => {
  try {
    dispatch(setSpacesRequestInProgress(true));

    const { cachedChannels, fetchedPromise } = await getbyWorkspaceId(
      workspaceId
    );
    if (cachedChannels) {
      dispatch(spacesLoaded(cachedChannels, workspaceId));
    }
    const networkChannels = await fetchedPromise;
    dispatch(spacesLoaded(networkChannels, workspaceId));
    dispatch(setSpacesRequestInProgress(false));
    return networkChannels;
  } catch (e) {
    dispatch(setSpacesRequestInProgress(false));
    throw e;
  }
};

export default loadSpaces;
