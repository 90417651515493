import PropTypes from 'prop-types';
import React from 'react';
import CollaboratorLastSeen from './CollaboratorLastSeen';

class CollaboratorInfo extends React.Component {
  render() {
    const { collaborator } = this.props;

    return (
      <span>
        <CollaboratorLastSeen
          date={collaborator.LastOnline}
          presence={collaborator.Presence}
        />
      </span>
    );
  }
}

CollaboratorInfo.propTypes = {
  collaborator: PropTypes.object,
};

export default CollaboratorInfo;
