import PropTypes from 'prop-types';
import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function InvoicePastDueDesktopApp({ workspace, onComplete }) {
  return (
    <Modal size="tiny" open onClose={onComplete}>
      <Header>
        <FormattedMessage id="InvoicePastDueDesktopApp.Title" />
      </Header>
      <Modal.Content>
        <FormattedMessage
          id="InvoicePastDueDesktopApp.Message"
          values={{
            teamspace: <strong>{workspace.Name}</strong>,
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onComplete}>
          <FormattedMessage id="InvoicePastDueDesktopApp.Refresh" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

InvoicePastDueDesktopApp.propTypes = {
  workspace: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default InvoicePastDueDesktopApp;
