import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import WeTeamIcon from 'Components/Icons/WeTeamIcon';

import './AppNavigationToggle.scss';
import { ArrowRight2Icon } from 'Components/Icons/WeTeamIcon';

function AppNavigationToggle({ open, onClick }) {
  return (
    <WeTeamIcon
      Icon={ArrowRight2Icon}
      className={classNames('app-navigation-toggle', {
        open: open,
      })}
      color="white"
      onClick={onClick}
    />
  );
}

AppNavigationToggle.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AppNavigationToggle;
