import React from 'react';
import PropTypes from 'prop-types';
import { truncateAround } from 'Helpers/Strings/truncateAround';
import CollaboratorAvatarContainer from 'Components/Collaborators/CollaboratorAvatarContainer';
import { Label } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { showPreviewerForSingleResource } from 'store/Modals/actions';

function Comment({
  comment,
  channelId,
  canClick,
  onClick,
  showAvatar,
  onLoad,
}) {
  if (!comment) {
    return null;
  }
  return (
    <div
      className={`${canClick ? 'cursor-pointer' : ''} padding-right`}
      onClick={() => {
        if (canClick) {
          onClick(comment.ResourceId, channelId);
        }
      }}
    >
      <div className="flex">
        {showAvatar && comment.CreatedBy && (
          <div className="mr-3">
            <CollaboratorAvatarContainer
              email={comment.CreatedBy.Email}
              spaceId={channelId}
            />
          </div>
        )}
        <div className="flex-auto wrappable-text">
          <div className="flex align-items-center justify-content-space-between">
            {comment.CreatedBy && (
              <strong>
                {`${comment.CreatedBy.FirstName} ${comment.CreatedBy.LastName}`}
              </strong>
            )}
            {comment.unread && <Label circular color="red" empty size="tiny" />}
          </div>
          {truncateAround(comment.Text, '', 300)}
        </div>
      </div>
    </div>
  );
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
  canClick: PropTypes.bool,
  onClick: PropTypes.func,
  showAvatar: PropTypes.bool,
};

Comment.defaultProps = {
  showAvatar: true,
  canClick: true,
  onClick: () => {},
};

export default connect(
  undefined,
  { onClick: showPreviewerForSingleResource }
)(Comment);
