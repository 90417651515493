import { ADD_INTEGRATION_APP } from './actions';

function addStoreCategoriesToState(state, categories) {
  return Object.assign({}, { ...state }, { ...categories });
}

export default function categoriesReducer(state = {}, action) {
  switch (action.type) {
    case ADD_INTEGRATION_APP:
      return addStoreCategoriesToState(state, action.intApps);
    default:
      return state;
  }
}
