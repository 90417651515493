import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUnreadAllSpaceNotificationsCount } from 'store/Notifications/selectors';

function ElectronBadge({ count }) {
  useEffect(
    () => {
      window.ipcRenderer?.send('setAppNotificationBadge', count);
    },
    [count]
  );

  return null;
}

ElectronBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

export default connect(state => ({
  count: getUnreadAllSpaceNotificationsCount(state),
}))(ElectronBadge);
