import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Header } from 'semantic-ui-react';

import deleteNotifications from 'store/Notifications/deleteNotifications';

import useAsyncFn from 'Hooks/useAsyncFn';

export const SpaceChatDeleteMultipleMessagesModal = ({
  messages,
  dispatch,
  onCancel,
  onComplete,
}) => {
  const [state, onDelete] = useAsyncFn(async () => {
    const ids = messages.map(message => message.id);
    await dispatch(deleteNotifications(ids));
    onComplete();
  }, [messages]);

  return (
    <Modal size="small" open onClose={onCancel}>
      <Header>
        <FormattedMessage id={'SpaceChatDeleteMultipleMessagesModal.Title'} />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onCancel} />
        </div>
      </Header>
      <Modal.Content>
        <p>
          <FormattedMessage
            id={'SpaceChatDeleteMultipleMessagesModal.Message'}
          />
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel} disabled={state.loading}>
          <FormattedMessage id="Cancel" />
        </Button>
        <Button
          color="red"
          onClick={onDelete}
          disabled={state.loading}
          loading={state.loading}
        >
          <FormattedMessage id="SpaceChatDeleteMultipleMessagesModal.DeleteButton" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

SpaceChatDeleteMultipleMessagesModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
};

export default connect()(SpaceChatDeleteMultipleMessagesModal);
