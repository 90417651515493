export const ADDRESS_BOOK_ADD = 'ADDRESS_BOOK/ADD';

function add(emailAddress) {
  return {
    type: ADDRESS_BOOK_ADD,
    emailAddress,
  };
}

export default add;
