import { FormattedMessage } from 'react-intl';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';
import { getReminderById } from 'store/Reminders/selectors';
import ReminderDate from './ReminderDate';
import { FormattedTime } from 'react-intl';
import SnoozeReminderModal from 'Components/Modals/Reminders/SnoozeReminderModal';

function SnoozeReminderButton({ reminder }) {
  const [showSnoozeReminderModal, setShowSnoozeReminderModal] = useState(false);
  const [snoozed, setSnoozed] = useState(false);

  useEffect(() => {
    if (reminder && !reminder.Active) {
      setSnoozed(false);
    }
  }, [reminder]);

  const onSnoozeClick = () => {
    setShowSnoozeReminderModal(true);
  };

  const onClose = isSnoozed => {
    setShowSnoozeReminderModal(false);
    setSnoozed(isSnoozed);
  };

  if (!reminder) {
    return null;
  }

  return (
    <>
      {showSnoozeReminderModal && (
        <SnoozeReminderModal reminder={reminder} onClose={onClose} />
      )}
      <Button
        basic
        compact
        size="small"
        onClick={onSnoozeClick}
        color={snoozed ? 'green' : ''}
      >
        {snoozed ? (
          <span>
            <FormattedMessage
              id="SnoozeReminderButton.snoozed-until"
              values={{
                time: (
                  <>
                    <ReminderDate date={reminder.Time} />
                    {/* eslint-disable react/jsx-no-literals */}
                    &nbsp;@&nbsp;
                    {/* eslint-enable react/jsx-no-literals */}
                    <FormattedTime
                      value={reminder.Time}
                      updateInterval={false}
                      minute="2-digit"
                      hour="2-digit"
                    />
                  </>
                ),
              }}
            />
          </span>
        ) : (
          <FormattedMessage id="SnoozeReminderButton.snooze" />
        )}
      </Button>
    </>
  );
}

SnoozeReminderButton.propTypes = {
  reminder: PropTypes.object,
};

export default connect((state, props) => {
  return {
    reminder: getReminderById(state, props.id),
  };
})(SnoozeReminderButton);
