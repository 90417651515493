import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';
import getReminderOptions, {
  makeReminderOptionKey,
} from './getReminderOptions';

import styles from './TimePicker.module.css';

const TimePicker = ({
  onChange,
  hour,
  minute,
  isMorning,
  className,
  ...props
}) => {
  const [times, setTimes] = useState([]);

  useEffect(() => {
    setTimes(getReminderOptions(hour, minute));
  }, [hour, minute]);

  const onTimeChange = (event, data) => {
    const selectedTime = times.find(time => time.value === data.value);
    onChange(selectedTime.hour, selectedTime.minute);
  };

  const time = makeReminderOptionKey(hour, minute);
  return (
    <>
      <Select
        onChange={onTimeChange}
        options={times}
        value={time}
        className={`${className} ${styles.timePicker}`}
        {...props}
      />
    </>
  );
};

TimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  hour: PropTypes.string.isRequired,
  minute: PropTypes.string.isRequired,
};

export default TimePicker;
