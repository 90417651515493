import { push } from 'react-router-redux';
import { getSpaceLink } from 'pages';
import { join } from 'DataLayer/Spaces/update';
import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';
import loadSpace from './loadSpace';

const joinPrivateChat = space => dispatch =>
  join(space.Id).then(
    () => {
      dispatch(loadSpace(space.Id));
      dispatch(
        displaySuccessToast('toast.joinPrivateChat.success', {
          name: space.Name,
        })
      );
      dispatch(push(getSpaceLink(space.Id)));
      return space;
    },
    error => {
      dispatch(displayErrorToast('toast.joinPrivateChat.error'));
      throw error;
    }
  );

export default joinPrivateChat;
