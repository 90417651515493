import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { Input } from 'semantic-ui-react';
import get from 'lodash/get';

import { getOrderedChannelsByWorkspaceId } from 'store/Spaces/selectors';
import { getWorkspaceById } from 'store/Workspaces/selectors';
import {
  showAddTeamSpaceModal,
  showAddWorkspaceMemberModal,
} from 'store/Modals/actions';
import loadSpaces from 'store/Spaces/loadSpaces';

import SectionHeader from '../../Header/SectionHeader';
import WorkspaceTitle from './WorkspaceTitle';

import ScrollPositionTracker from '../../ScrollPositionTracker/ScrollPositionTracker';
import ElementOutOfViewBanner from '../../ScrollPositionTracker/ElementOutOfViewBanner';

import LoadStats from 'Components/Stats/LoadStats';
import { SPACES_LIST_TYPE } from 'store/UI';
import styles from './WorkspaceSidebar.module.scss';
import Channels from './Channels';
import { channelPositionsChanged } from '../../../store/UI';
import { arrayMove } from 'react-sortable-hoc';
import isTouchDevice from 'is-touch-device';
import UpgradeButton from 'Components/Workspace/UpgradeButton';
import ShowIfNotAppStore from 'Components/Electron/ShowIfNotAppStore';
import SkeletonLine from 'Views/Skeleton/SkeletonLine';
import useIsApplicationActive from 'Hooks/useIsApplicationActive';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { SearchIcon } from 'Components/Icons/WeTeamIcon';
import Spring2024PromotionMilestonesButton from 'Components/WorkspaceOffer/Spring2024Trial/Spring2024PromotionMilestonesButton';

export function WorkspaceSidebar({
  workspace,
  channels,
  intl,
  onLoadSpaces,
  onShowAddWorkspaceMemberModal,
  onShowAddTeamSpaceModal,
  onChannelPositionsChanged,
}) {
  const [filter, setFilter] = useState('');
  const isApplicationActive = useIsApplicationActive();

  useEffect(() => {
    if (workspace.Id) {
      onLoadSpaces(workspace.Id);
    }
    /*
    Including isApplicationActive as a dependency ensures this is run when 
    the app changes from inactive to active. This helps keep the client and server in sync
    */
  }, [workspace.Id, isApplicationActive, onLoadSpaces]);

  const filterChannelByName = channel => {
    if (!channel.NameLowerCase) {
      return true;
    }
    return channel.NameLowerCase?.indexOf(filter.toLowerCase()) > -1;
  };

  const canCreateChannel = get(workspace, 'ACL.CreateChannel');
  const canAddMembers = !!workspace.Id;

  const channelsFiltered = channels.channels.filter(filterChannelByName);
  const chatsFiltered = channels.chats.filter(filterChannelByName);
  const archivedFiltered = channels.archived.filter(filterChannelByName);

  const onSortEnd = useCallback(
    (items, oldIndex, newIndex, listType) => {
      if (oldIndex === newIndex) {
        // if the position has not changed, do not update the list
        return;
      }
      onChannelPositionsChanged(
        workspace.Id,
        listType,
        arrayMove(
          items.map(item => item.Id),
          oldIndex,
          newIndex
        )
      );
    },
    [onChannelPositionsChanged, workspace.Id]
  );
  const isTouchDeviceResult = isTouchDevice();
  return (
    <>
      <LoadStats workspaceId={workspace.Id} />
      <SectionHeader hideBorder>
        {workspace.Name ? (
          <WorkspaceTitle workspace={workspace} />
        ) : (
          <SkeletonLine />
        )}
      </SectionHeader>
      <Input
        data-testid="filter-input"
        className="mx-3 mb-3"
        icon={
          <i className="icon">
            <WeTeamIcon Icon={SearchIcon} className={styles.searchIcon} />
          </i>
        }
        iconPosition="left"
        placeholder={intl.formatMessage({
          id: 'WorkspaceSidebar.filter',
        })}
        value={filter}
        onChange={event => {
          setFilter(event.target.value);
        }}
      />
      <div className="flex flex-auto position-relative min-height-0">
        <ScrollPositionTracker
          className="sidebar-scroller"
          disableBanner={filter.length > 0}
          renderBanner={position => {
            return (
              <ElementOutOfViewBanner position={position}>
                <FormattedMessage id="WorkspaceSidebar.new-messages" />
              </ElementOutOfViewBanner>
            );
          }}
        >
          <div className="pb-4">
            <Channels
              distance={isTouchDeviceResult ? 0 : 1} // this is required to allow clicking on the list item
              pressDelay={isTouchDeviceResult ? 200 : 0} // allow scrolling on touch devices
              workspaceId={workspace.Id}
              lockAxis="y"
              lockToContainerEdges
              onSortEnd={({ oldIndex, newIndex }) => {
                onSortEnd(
                  channels.channels,
                  oldIndex,
                  newIndex,
                  SPACES_LIST_TYPE.team
                );
              }}
              channels={channelsFiltered}
              showPlus={canCreateChannel}
              listType={SPACES_LIST_TYPE.team}
              title={<FormattedMessage id="WorkspaceSidebar.channels" />}
              plusButtonTooltipText={
                <FormattedMessage id="WorkspaceSidebar.AddNewChannel" />
              }
              onPlusClick={() => {
                onShowAddTeamSpaceModal(workspace.Id);
              }}
            />
          </div>
          <div className="pb-4">
            <Channels
              distance={isTouchDeviceResult ? 0 : 1} // this is required to allow clicking on the list item
              pressDelay={isTouchDeviceResult ? 200 : 0} // allow scrolling on touch devices
              workspaceId={workspace.Id}
              lockAxis="y"
              lockToContainerEdges
              onSortEnd={({ oldIndex, newIndex }) => {
                onSortEnd(
                  channels.chats,
                  oldIndex,
                  newIndex,
                  SPACES_LIST_TYPE.single
                );
              }}
              channels={chatsFiltered}
              showPlus={canAddMembers}
              listType={SPACES_LIST_TYPE.single}
              title={<FormattedMessage id="WorkspaceSidebar.people" />}
              plusButtonTooltipText={
                <FormattedMessage id="WorkspaceSidebar.AddMembers" />
              }
              onPlusClick={() => {
                onShowAddWorkspaceMemberModal(workspace.Id);
              }}
            />
          </div>
          {archivedFiltered.length > 0 && (
            <Channels
              distance={isTouchDeviceResult ? 0 : 1} // this is required to allow clicking on the list item
              pressDelay={isTouchDeviceResult ? 200 : 0} // allow scrolling on touch devices
              workspaceId={workspace.Id}
              lockAxis="y"
              lockToContainerEdges
              onSortEnd={({ oldIndex, newIndex }) => {
                onSortEnd(
                  channels.archived,
                  oldIndex,
                  newIndex,
                  SPACES_LIST_TYPE.archived
                );
              }}
              channels={archivedFiltered}
              showPlus={false}
              listType={SPACES_LIST_TYPE.archived}
              title={<FormattedMessage id="WorkspaceSidebar.archived" />}
            />
          )}
        </ScrollPositionTracker>
      </div>
      <ShowIfNotAppStore all={<UpgradeButton workspaceId={workspace.Id} />} />
      {workspace.Id && workspace.IsOwner && (
        <ShowIfNotAppStore
          all={
            <Spring2024PromotionMilestonesButton workspaceId={workspace.Id} />
          }
        />
      )}
    </>
  );
}

WorkspaceSidebar.propTypes = {
  onLoadSpaces: PropTypes.func.isRequired,
  onShowAddTeamSpaceModal: PropTypes.func.isRequired,
  onShowAddWorkspaceMemberModal: PropTypes.func.isRequired,
  onChannelPositionsChanged: PropTypes.func.isRequired,
  channels: PropTypes.shape({
    channels: PropTypes.array.isRequired,
    chats: PropTypes.array.isRequired,
    archived: PropTypes.array.isRequired,
  }),
  workspace: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default connect(
  (state, props) => {
    const channels = getOrderedChannelsByWorkspaceId(state, props.workspaceId);
    const workspace = getWorkspaceById(state, props.workspaceId);
    return {
      channels,
      workspace,
    };
  },
  {
    onLoadSpaces: loadSpaces,
    onShowAddTeamSpaceModal: showAddTeamSpaceModal,
    onShowAddWorkspaceMemberModal: showAddWorkspaceMemberModal,
    onChannelPositionsChanged: channelPositionsChanged,
  }
)(injectIntl(WorkspaceSidebar));
