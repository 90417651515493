import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { isUserLoggedIn } from 'store/User/selectors';
import { isUnverifiedUserRequestAllowed } from 'DataLayer/endpoints';

function RequestInterceptor({ isUserLoggedIn }) {
  useEffect(
    () => {
      if (!isUserLoggedIn) {
        const interceptorId = axios.interceptors.request.use(config => {
          if (!isUnverifiedUserRequestAllowed(config)) {
            /* 
              We don't want our promise resolved or rejected.
              https://github.com/axios/axios/issues/715#issuecomment-549639100
            */
            return new Promise(() => {});
          }
          return config;
        });
        return () => {
          axios.interceptors.request.eject(interceptorId);
        };
      }
    },
    [isUserLoggedIn]
  );

  return null;
}

RequestInterceptor.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
};

export default connect(state => ({
  isUserLoggedIn: isUserLoggedIn(state),
}))(RequestInterceptor);
