const PENDING = 'LINKED_ITEMS/SHARE/PENDING';
export const STARTED = 'LINKED_ITEMS/SHARE/STARTED';
const COMPLETED = 'LINKED_ITEMS/SHARE/COMPLETED';
const FAILED = 'LINKED_ITEMS/SHARE/FAILED';
const REMOVE_ALL = 'LINKED_ITEMS/SHARE/REMOVE_ALL';

const createShareId = (channelId, resourceId) => `${channelId}:${resourceId}`;

export const shareStarted = id => ({
  type: STARTED,
  id,
});

export const sharePending = (channelId, resourceId) => ({
  type: PENDING,
  id: createShareId(channelId, resourceId),
  timestamp: Date.now(),
  channelId,
  resourceId,
});

export const shareCompleted = id => ({
  type: COMPLETED,
  id,
});

export const shareFailed = id => ({
  type: FAILED,
  id,
});

export const removeAll = () => ({
  type: REMOVE_ALL,
});

export const SHARE_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
};

const shareReducer = (
  state = {
    id: null,
    status: SHARE_STATUS.PENDING,
    channelId: null,
    resourceId: null,
  },
  action
) => {
  switch (action.type) {
    case STARTED:
      /**
       * If we are restarting a failed share,
       * we can ignore the other action properties.
       */
      if (state.status === SHARE_STATUS.FAILED) {
        return {
          ...state,
          status: SHARE_STATUS.IN_PROGRESS,
        };
      }
      return {
        ...state,
        ...action,
        status: SHARE_STATUS.IN_PROGRESS,
      };
    case COMPLETED:
      return {
        ...state,
        status: SHARE_STATUS.COMPLETE,
      };
    case PENDING:
      return {
        ...state,
        ...action,
        status: SHARE_STATUS.PENDING,
      };
    case FAILED:
      return {
        ...state,
        status: SHARE_STATUS.FAILED,
      };
    default:
      return state;
  }
};

function sharesReducer(state = {}, action) {
  switch (action.type) {
    case REMOVE_ALL:
      return {};
    case STARTED:
    case COMPLETED:
    case FAILED:
    case PENDING:
      return {
        ...state,
        [action.id]: shareReducer(state[action.id], action),
      };
    default:
      return state;
  }
}

export default sharesReducer;
