import { FormattedMessage } from 'react-intl';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Menu, Popup } from 'semantic-ui-react';

import starNotification from 'store/Notifications/starNotification';
import ContextMenu from '../ContextMenu/ContextMenu';
import ChatItemSeenBy from './ChatItemSeenBy';
import AddReminderButton from 'Components/Reminders/AddReminderButton';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';

import './ChatItemActions.scss';
import { ShowIcon } from 'Components/Icons/WeTeamIcon';
import { StarIcon } from 'Components/Icons/WeTeamIcon';
import IconButtonGroup from 'Components/IconButtonGroup/IconButtonGroup';
import { DotsIcon } from 'Components/Icons/WeTeamIcon';
import MessageSelectionSelectOption from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionSelectOption';
import { useMessageSelectionContext } from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionContext';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

function ChatItemActions({
  spaceId,
  timestamp,
  canEdit,
  canDelete,
  canShare,
  canReply,
  canDownload,
  canTransferToOtixoDrive,
  showSeenBy,
  canStar,
  canRemind,
  reminderId,
  reminderTargetId,
  reminderTarget,
  reminderType,
  onFullScreenPreview,
  shouldShowFullScreenPreviewOption,
  id,
  starred,
  onDelete,
  onTransferToOtixoDrive,
  onDownload,
  onEdit,
  onShare,
  onReply,
  onStar,
  onLaunch,
  resourceId,
  canLaunch,
}) {
  const { isSelecting } = useMessageSelectionContext();

  const deleteItem = () => {
    onDelete(id);
  };

  const transferToOtixoDrive = () => {
    onTransferToOtixoDrive();
  };

  const download = () => {
    onDownload();
  };

  const edit = () => {
    onEdit(id);
  };

  const share = () => {
    onShare(id);
  };

  const reply = () => {
    onReply(id);
  };

  const onStarClick = () => {
    onStar(id, !starred);
  };

  const onFullScreenPreviewWithoutComments = () => {
    onFullScreenPreview({
      showCommentsSidebar: false,
    });
  };

  if (isSelecting) {
    return null;
  }
  const showMoreMenu =
    canDelete || canEdit || canShare || canDownload || canReply;
  return (
    <DisableInBackupMode>
      <IconButtonGroup
        className="chat-items-actions"
        data-testid="chat-items-actions"
      >
        {canShare && (
          <Popup
            inverted
            on="hover"
            size="mini"
            position="top center"
            trigger={
              <MessageSelectionSelectOption
                alwaysChecked
                messageId={id}
                canDelete={canDelete}
                canShare={canShare}
                canLaunch={canLaunch}
                resourceId={resourceId}
              />
            }
          >
            <FormattedMessage id="ChatItemActions.SelectMessageTooltip" />
          </Popup>
        )}
        {canStar && (
          <Popup
            inverted
            on="hover"
            size="mini"
            position="top center"
            trigger={
              <WeTeamIcon
                Icon={StarIcon}
                onClick={onStarClick}
                data-testid="chat-item-actions-star"
                active={starred}
              />
            }
          >
            <FormattedMessage id="ChatItemActions.AddFavoriteTooltip" />
          </Popup>
        )}
        {canRemind && reminderType && reminderTargetId && (
          <AddReminderButton
            spaceId={spaceId}
            reminderId={reminderId}
            targetId={reminderTargetId}
            target={reminderTarget}
            reminderType={reminderType}
          >
            {({ icon, onClick }) => (
              <Popup
                inverted
                on="hover"
                size="mini"
                position="top center"
                trigger={icon}
              >
                <FormattedMessage id="ChatItemActions.CreateReminderTooltip" />
              </Popup>
            )}
          </AddReminderButton>
        )}
        {showSeenBy && (
          <ContextMenu
            trigger={
              <span>
                <Popup
                  inverted
                  on="hover"
                  size="mini"
                  position="top center"
                  trigger={
                    <WeTeamIcon
                      className="chat-items-actions-info-icon"
                      Icon={ShowIcon}
                    />
                  }
                >
                  <FormattedMessage id="ChatItemActions.InfoTooltip" />
                </Popup>
              </span>
            }
          >
            <Menu vertical size="small">
              <Menu.Item>
                <ChatItemSeenBy timestamp={timestamp} spaceId={spaceId} />
              </Menu.Item>
            </Menu>
          </ContextMenu>
        )}
        {showMoreMenu && (
          <ContextMenu
            trigger={
              <WeTeamIcon
                Icon={DotsIcon}
                data-testid="chat-items-actions-menu-button"
              />
            }
          >
            <Menu vertical size="small">
              {canShare && (
                <Menu.Item onClick={share}>
                  <FormattedMessage id="ChatItemActions.share" />
                </Menu.Item>
              )}
              {canDelete && (
                <Menu.Item onClick={deleteItem}>
                  <FormattedMessage id="ChatItemActions.delete" />
                </Menu.Item>
              )}
              {canEdit && (
                <Menu.Item onClick={edit}>
                  <FormattedMessage id="ChatItemActions.edit" />
                </Menu.Item>
              )}
              {canReply && (
                <Menu.Item onClick={reply}>
                  <FormattedMessage id="ChatItemActions.reply" />
                </Menu.Item>
              )}
              {canDownload && (
                <Menu.Item onClick={download}>
                  <FormattedMessage id="ChatItemActions.download" />
                </Menu.Item>
              )}
              {canTransferToOtixoDrive && (
                <Menu.Item onClick={transferToOtixoDrive}>
                  <FormattedMessage id="ChatItemActions.transfer" />
                </Menu.Item>
              )}
              {shouldShowFullScreenPreviewOption && (
                <Menu.Item onClick={onFullScreenPreviewWithoutComments}>
                  <FormattedMessage id="ChatItemActions.preview" />
                </Menu.Item>
              )}
              {shouldShowFullScreenPreviewOption && (
                <Menu.Item onClick={onFullScreenPreview}>
                  <FormattedMessage id="ChatItemActions.comment" />
                </Menu.Item>
              )}
              {canLaunch && (
                <Menu.Item onClick={onLaunch}>
                  <FormattedMessage id="ChatItemActions.open" />
                </Menu.Item>
              )}
            </Menu>
          </ContextMenu>
        )}
      </IconButtonGroup>
    </DisableInBackupMode>
  );
}

ChatItemActions.propTypes = {
  id: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onTransferToOtixoDrive: PropTypes.func,
  canTransferToOtixoDrive: PropTypes.bool,
  onEdit: PropTypes.func,
  onShare: PropTypes.func,
  onReply: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  canShare: PropTypes.bool,
  canReply: PropTypes.bool,
  showSeenBy: PropTypes.bool,
  starred: PropTypes.bool,
  onStar: PropTypes.func,
  canStar: PropTypes.bool,
  canRemind: PropTypes.bool,
  reminderId: PropTypes.string,
  reminderTargetId: PropTypes.string,
  reminderTarget: PropTypes.object,
  reminderType: PropTypes.string.isRequired,
  shouldShowFullScreenPreviewOption: PropTypes.bool,
  onFullScreenPreview: PropTypes.func,
  onLaunch: PropTypes.func,
  resourceId: PropTypes.string,
  canLaunch: PropTypes.bool,
};

export default connect(undefined, { onStar: starNotification })(
  ChatItemActions
);
