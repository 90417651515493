import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import {
  getProfileLink,
  getAccountDataBillingLink,
  getAccountEmailsLink,
  getAccountSecurityLink,
} from 'pages';
import { hasTransfersSubscription } from 'store/User/selectors';
import ShieldCheckIcon from 'Components/Icons/FontAwesome/Light/ShieldCheckIcon';
import TeamspaceIdIcon from 'Components/Icons/FontAwesome/Light/TeamspaceIdIcon';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { ProfileIcon } from 'Components/Icons/WeTeamIcon';

function AccountSettingsMenuItems({
  hasTransferSubscription,
  showIcon = false,
  onClick,
}) {
  return (
    <React.Fragment>
      <Link
        className="item"
        to={getProfileLink()}
        activeClassName="active"
        onClick={onClick}
      >
        {showIcon && <WeTeamIcon Icon={ProfileIcon} />}
        <FormattedMessage id="Account.Profile" />
      </Link>
      <Link
        className="item"
        to={getAccountEmailsLink()}
        activeClassName="active"
        onClick={onClick}
      >
        {showIcon && <TeamspaceIdIcon />}
        <FormattedMessage id="Account.Email.Menu" />
      </Link>
      <Link
        className="item"
        to={getAccountSecurityLink()}
        activeClassName="active"
        onClick={onClick}
      >
        {showIcon && <ShieldCheckIcon />}
        <FormattedMessage id="Account.Security" />
      </Link>
      {hasTransferSubscription && (
        <Link
          className="item"
          to={getAccountDataBillingLink()}
          activeClassName="active"
          onClick={onClick}
        >
          <FormattedMessage id="Account.TransfersBilling" />
        </Link>
      )}
    </React.Fragment>
  );
}

AccountSettingsMenuItems.propTypes = {
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  hasTransferSubscription: PropTypes.bool.isRequired,
};

export default connect(state => ({
  hasTransferSubscription: hasTransfersSubscription(state),
}))(AccountSettingsMenuItems);
