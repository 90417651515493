import React from 'react';
import PropTypes from 'prop-types';
import { Message, Icon } from 'semantic-ui-react';

const DismissableMessage = ({ onClose, children, ...rest }) => {
  //
  return (
    <Message {...rest}>
      <div className="flex flex-auto justify-content-space-between align-items-center">
        {children}
        <Icon name="close" link onClick={onClose} />
      </div>
    </Message>
  );
};

DismissableMessage.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DismissableMessage;
