import { getTemplates } from 'DataLayer/Integrations/Templates/get';
import { addAvailableStoreTemplates } from './actions';

export const loadTemplates = () => dispatch =>
  getTemplates().then(templates => {
    const templateList = templates.reduce((object, value) => {
      object[value.id] = value;
      return object;
    }, {});
    dispatch(addAvailableStoreTemplates(templateList));
  });

export default loadTemplates;
