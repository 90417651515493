import { get } from 'DataLayer/Me/get';
import * as Sentry from '@sentry/react';
import { setLocale } from 'store/Locale/setLocale';
import logout from 'Helpers/Logout';

import { setRequestInProgress } from '../Requests/setRequestInProgress';
import { USER_LOAD } from '../Requests/requestTypes';
import { identify as appCuesIdentify } from 'Components/AppCues/AppCuesLaunch';
import { userLoaded } from 'store/User/actions';

const loadUser = () => dispatch => {
  dispatch(setRequestInProgress(USER_LOAD, true));
  return get().then(
    user => {
      dispatch(userLoaded(user));
      dispatch(setRequestInProgress(USER_LOAD, false));
      dispatch(setLocale(user.Language));
      // NOTE: These values should be specific to the current user.
      Sentry.configureScope(function (scope) {
        if (scope) {
          try {
            scope.setUser({
              id: user.Guid,
            });
          } catch {}
        }
      });
      appCuesIdentify(user.Guid, user.Language);
      return user;
    },
    error => {
      dispatch(setRequestInProgress(USER_LOAD, false));
      logout(window.location.href);
      throw error;
    }
  );
};
export default loadUser;
