import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import LoadStats from 'Components/Stats/LoadStats';

import { loadTemplates } from 'store/Integrations/Templates/loadTemplates';
import loadUser from 'store/User/loadUser';
import { finish } from 'store/Load';
import loadAdapters from 'store/Adapters/loadAdapters';
import { loadRepositories } from 'store/Repositories/loadRepositories';
import { getLoaded } from 'store/Load/selectors';
import loadWorkspaces from 'store/Workspaces/loadWorkspaces';

import ActionableNotifications from './Components/Modals/ActionableNotifications/ActionableNotifications';
import FullScreenLoading from './Components/Loading/FullScreenLoading';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';

import ResponseInterceptor from './Components/Axios/ResponseInterceptor';
import IfElectron from 'Components/Electron/IfElectron';
import ElectronBadge from 'Components/Electron/ElectronBadge';
import { getWebUiPreferences } from 'store/UI';
import loadTransfers from 'store/Transfers/loadTransfers';
import loadMeetings from './store/Meeting/loadMeetings';
import FullScreenApp from 'Views/FullScreenApp';
import useIsApplicationActive from 'Hooks/useIsApplicationActive';
import useInterval from 'Hooks/useInterval';
import { connectWebSocket } from 'store/websocket-middleware';
import UserLocationInformation from 'Components/UserLocationInformation/UserLocationInformation';
import { isCypressTestRun } from 'config/config';
import { WorkspaceBackupProvider } from 'Components/Pages/WorkspaceBackup/WorkspaceBackupContext';
import BillingInformationModalContainer from 'Components/Workspace/BillingInformationModal/BillingInformationModalContainer';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import { getWebsocketConnected } from 'store/Connectivity';

export function AuthenticatedApp({ loaded, children, dispatch }) {
  const isApplicationActive = useIsApplicationActive();
  const websocketConnected = useSelector(getWebsocketConnected);

  const fetchData = useCallback(async () => {
    dispatch(loadWorkspaces());
    dispatch(loadRepositories());
    dispatch(loadMeetings());
  }, [dispatch]);

  /*
    Due to inconsistent websocket, we should poll this to ensure the latest meeting information
    is always in sync
  */
  useInterval(() => {
    if (!websocketConnected) {
      dispatch(loadMeetings());
    }
  }, 10000);

  useEffect(() => {
    const fetchUser = async () => {
      await dispatch(loadUser());
      dispatch(connectWebSocket());
      try {
        await dispatch(getWebUiPreferences());
      } finally {
        dispatch(finish());
        fetchData();
        /*
          static data only needs to be fetched when app starts
          */
        dispatch(loadTemplates());
        dispatch(loadAdapters());
        /*
          only fetch once. we don't want the transfer window popping up after the user has closed it
          */
        dispatch(loadTransfers(true));
      }
    };
    fetchUser();
  }, [dispatch, fetchData]);

  useEffect(() => {
    /*
      wait until we know the user is loaded before fetching data
      */
    if (isApplicationActive && loaded) {
      fetchData();
    }
  }, [dispatch, fetchData, isApplicationActive, loaded]);

  return (
    <React.Fragment>
      <FullScreenLoading visible={!loaded} />
      {loaded && (
        <React.Fragment>
          <ResponseInterceptor />
          <ActionableNotifications />
          <ErrorBoundary>
            <BillingInformationModalContainer />
          </ErrorBoundary>
          <WorkspaceBackupProvider>
            <FullScreenApp>{children}</FullScreenApp>
          </WorkspaceBackupProvider>
          <DocumentTitle />
          <LoadStats />
          {/*
          To help make our test run more predictable, we turn off country
          detection during a cypress run
          */}
          {!isCypressTestRun() && <UserLocationInformation />}
          <IfElectron>
            <ElectronBadge />
          </IfElectron>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

AuthenticatedApp.propTypes = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  loaded: getLoaded(state),
}))(withRouter(AuthenticatedApp));
