import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Dimmer, Loader, Popup } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import { arrayMove } from 'react-sortable-hoc';

import { getPopularTags } from 'store/UI/selectors';
import { showManageTagsModal } from 'store/Modals/actions';

import {
  useFetchResourceTagsByChannelId,
  useFetchTagsByChannelId,
  useUpdateResourceTags,
  useMutateResourceTagsFromWebSocket,
} from 'Hooks/data/tags/useTags';

import LinkButton from 'Components/Elements/LinkButton';
import Tag from 'Components/Tagger/Tag';
import ResourceTagsInput from 'Components/Tagger/ResourceTagsInput';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

import TruncatedTagList from './TruncatedTagList';

import styles from './ResourceTags.module.css';

function ResourceTags({
  resourceId,
  channelId,
  truncateTags = true,
  clickToAddClassName,
}) {
  const dispatch = useDispatch();
  const popularTags = useSelector(getPopularTags);

  const [selectedTags, setSelectedTags] = useState([]);
  const [filter, setFilter] = useState('');

  const { data: allResourceTags, refetch } =
    useFetchResourceTagsByChannelId(channelId);
  const { data: channelTags } = useFetchTagsByChannelId(channelId);

  useEffect(() => {
    const tags = allResourceTags[resourceId] ?? [];
    setSelectedTags(tags);
  }, [allResourceTags, resourceId]);

  useMutateResourceTagsFromWebSocket(channelId, resourceId);

  const updateResourceMutation = useUpdateResourceTags(channelId, resourceId);

  const onTagSelected = useCallback(
    async selectedTagId => {
      const tagIds = selectedTags.some(
        selectedTag => selectedTag.id === selectedTagId
      )
        ? selectedTags
            .map(tag => tag.id)
            .filter(tagId => selectedTagId !== tagId)
        : [selectedTagId].concat(selectedTags.map(tag => tag.id));
      await updateResourceMutation.mutateAsync({
        channelId,
        resourceId,
        tags: tagIds,
      });
    },
    [channelId, resourceId, selectedTags, updateResourceMutation]
  );

  const orderedFilteredTags = useMemo(() => {
    const filteredTags = channelTags.filter(tag =>
      tag.name.toLowerCase().includes(filter.toLowerCase())
    );
    return orderBy(
      filteredTags,
      [tag => popularTags.includes(tag.id)],
      ['desc']
    );
  }, [channelTags, popularTags, filter]);

  const onSortEnd = useCallback(
    async ({ oldIndex, newIndex }) => {
      const orderedTagIds = arrayMove(
        selectedTags.map(tag => tag.id),
        oldIndex,
        newIndex
      );

      const newSelectedTags = orderedTagIds.map(tagId =>
        selectedTags.find(tag => tag.id === tagId)
      );

      setSelectedTags(newSelectedTags);

      await updateResourceMutation.mutateAsync({
        channelId,
        resourceId,
        tags: orderedTagIds,
      });
    },
    [selectedTags, updateResourceMutation, channelId, resourceId]
  );

  const renderTrigger = () => {
    if (!selectedTags.length) {
      return (
        <div className={clickToAddClassName}>
          <Tag size="tiny" className={styles.clickToAddTag}>
            <FormattedMessage id="ResourceTags.ClickToAddTag" />
          </Tag>
        </div>
      );
    }

    if (truncateTags) {
      return (
        <TruncatedTagList
          items={selectedTags}
          renderHiddenTags={tags => (
            <Tag size="tiny">
              <FormattedMessage
                id="ResourceTags.PlusTags"
                values={{ number: tags.length }}
              />
            </Tag>
          )}
          renderTag={({ tag }) => {
            return (
              <Tag key={tag.id} color={tag.color} size="tiny">
                {tag.name}
              </Tag>
            );
          }}
        />
      );
    }

    return (
      <div>
        {selectedTags.map(tag => (
          <Tag
            key={tag.id}
            color={tag.color}
            size="tiny"
            className={styles.wrappableTag}
          >
            {tag.name}
          </Tag>
        ))}
      </div>
    );
  };

  return (
    <Popup
      basic
      on="click"
      trigger={renderTrigger()}
      onClick={e => {
        e.stopPropagation();
      }}
      onOpen={e => {
        e.stopPropagation();
        refetch();
        setFilter('');
      }}
    >
      <Dimmer.Dimmable
        as={'div'}
        dimmed={updateResourceMutation.isLoading}
        className={styles.container}
      >
        <Dimmer active={updateResourceMutation.isLoading} inverted>
          <Loader />
        </Dimmer>
        <ResourceTagsInput
          channelId={channelId}
          resourceId={resourceId}
          selectedTags={selectedTags}
          onTagSelected={onTagSelected}
          orderedFilteredTags={orderedFilteredTags}
          onFilterChange={setFilter}
          filter={filter}
          onSortEnd={onSortEnd}
        />
        <div className={styles.availableTags}>
          {orderedFilteredTags.map(availableTag => (
            <div
              key={availableTag.id}
              className={styles.availableTag}
              onClick={(event, data) => {
                if (
                  selectedTags.some(
                    selectedTag => selectedTag.id === availableTag.id
                  )
                ) {
                  onTagSelected(availableTag.id);
                } else {
                  onTagSelected(availableTag.id);
                }
              }}
            >
              <Checkbox
                checked={selectedTags.some(
                  selectedTag => selectedTag.id === availableTag.id
                )}
                className="mr-3"
              />
              <Tag color={availableTag.color}>{availableTag.name}</Tag>
            </div>
          ))}
        </div>
        <LinkButton
          className="mt-3"
          onClick={() => {
            dispatch(showManageTagsModal(channelId));
          }}
        >
          <FormattedMessage id="ResourceTags.ManageTags" />
        </LinkButton>
      </Dimmer.Dimmable>
    </Popup>
  );
}

ResourceTags.propTypes = {
  resourceId: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
};

export default props => (
  <ErrorBoundary showFallback={false}>
    <ResourceTags {...props} />
  </ErrorBoundary>
);
