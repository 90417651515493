import { getTransfers } from 'DataLayer/Transfers/get';
import { showTransfersProgressModal } from 'store/Modals/actions';

export const LOAD_TRANSFERS_SUCCESS = 'TRANSFERS/LOAD_SUCCESS';

const loadTransfers = showProgressModal => dispatch =>
  getTransfers().then(transfers => {
    // console.log(transfers);
    if (!transfers) {
      return [];
    }
    dispatch({
      type: LOAD_TRANSFERS_SUCCESS,
      transfers,
    });
    if (showProgressModal && transfers.length > 0) {
      dispatch(showTransfersProgressModal());
    }
    // return all the transfer data
    return transfers;
  });

export default loadTransfers;
