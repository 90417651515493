import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Loader } from 'semantic-ui-react';
import { TOAST_TYPE } from 'store/Toast/displayToast';

import styles from './Toast.module.scss';
import classNamesBind from 'classnames/bind';
import useWindowFocus from 'Hooks/useWindowFocus';
import { useEffect } from 'react';

export const TOAST_DISPLAY_TIME = 5000; // ms

function Toast({ toast, onHide }) {
  const [running, setRunning] = useState(true);
  const isWindowFocussed = useWindowFocus();
  /*
    pause the progress when user is not in the app
  */
  useEffect(
    () => {
      setRunning(isWindowFocussed);
    },
    [isWindowFocussed]
  );

  /*
    pause the progress when user hovers over the toast
  */
  const onMouseOver = () => {
    setRunning(false);
  };

  const onMouseLeave = () => {
    setRunning(true);
  };

  const onClick = () => {
    onHide(toast.id);
  };

  const onAnimationEnd = () => {
    onHide(toast.id);
  };

  return (
    <div
      key={toast.id}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      data-testid="toast"
      className={classNamesBind(styles.toast, {
        [styles.error]: toast.type === TOAST_TYPE.ERROR,
      })}
    >
      <span className={styles.icon}>
        {toast.type === TOAST_TYPE.LOADING && (
          <Loader active inline size="tiny" data-testid="loading-icon" />
        )}
        {toast.type === TOAST_TYPE.SUCCESS && (
          <Icon
            name="check circle outline"
            size="large"
            data-testid="success-icon"
          />
        )}
        {toast.type === TOAST_TYPE.ERROR && (
          <Icon
            name="times circle outline"
            size="large"
            data-testid="error-icon"
          />
        )}
      </span>
      <span className="mx-2">
        <FormattedMessage
          id={toast.translationKey}
          values={toast.translationValues}
        />
      </span>
      {toast.type !== TOAST_TYPE.LOADING && (
        <div
          className={styles.progress}
          style={{
            animationDuration: `${TOAST_DISPLAY_TIME}ms`,
            animationPlayState: running ? 'running' : 'paused',
          }}
          onAnimationEnd={onAnimationEnd}
        />
      )}
    </div>
  );
}

Toast.propTypes = {
  toast: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default Toast;
