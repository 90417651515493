export const REMINDERS_LOADED = 'REMINDERS/LOADED';
export const REMINDERS_ADDED = 'REMINDERS/ADDED';
export const REMINDER_REMOVED = 'REMINDERS/REMOVED';
export const REMINDERS_CLEARED = 'REMINDERS/CLEAR';

const convertToObject = reminders =>
  reminders.reduce((obj, value) => {
    obj[value.Id] = value;
    return obj;
  }, {});

export function remindersLoaded(reminders) {
  return {
    type: REMINDERS_LOADED,
    reminders: convertToObject(reminders),
  };
}

export function reminderAdded(reminder) {
  return {
    type: REMINDERS_ADDED,
    reminder,
  };
}

export function reminderRemoved(id, targetId) {
  return {
    type: REMINDER_REMOVED,
    id,
    targetId,
  };
}

export function remindersCleared() {
  return {
    type: REMINDERS_CLEARED,
  };
}

function removeReminder(state, id) {
  const reminders = Object.assign({}, state);
  delete reminders[id];
  return reminders;
}

function clearReminders(state) {
  const filteredReminders = Object.values(state).filter(
    reminder => reminder.Active
  );
  return convertToObject(filteredReminders);
}

const intitialState = {};

export default function(state = intitialState, action) {
  switch (action.type) {
    case REMINDER_REMOVED:
      return removeReminder(state, action.id);
    case REMINDERS_CLEARED:
      return clearReminders(state);
    case REMINDERS_ADDED:
      return {
        ...state,
        [action.reminder.Id]: action.reminder,
      };
    case REMINDERS_LOADED:
      return action.reminders;
    default:
      return state;
  }
}
