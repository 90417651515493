import axios from 'axios';
import {
  NEW_WEB_UI_PREFERENCES,
  WEB_UI_PREFERENCES,
  getUnverifiedUserRequestAllowedConfig,
} from 'DataLayer/endpoints';
import {
  get as getFromDb,
  save as saveToDb,
} from 'DataLayer/database/webUiPreferences';
import { getOtixoBackendApiUrl } from 'config/config';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

export async function getFromCache() {
  return await getFromDb();
}

export const OLD_PREFERENCES_KEYS = [
  'workspaceSort',
  'workspacePositions',
  'spaceSort',
  'channelPositions',
  'latestWorkspaceId',
];

export async function getFromNetwork() {
  /* 
    TODO
    This is only here due to a race condition between the user
    logging in, and the RequestInterceptor knowing that the user is logged in    
    1. user logs in
    2. app makes this request
    3. request interceptor is told user is logged in
    We want 2 & 3 to switch places.

    Refactor required
  */

  const config = getUnverifiedUserRequestAllowedConfig();

  return Promise.all([
    axios.get(WEB_UI_PREFERENCES, config),
    axios.get(NEW_WEB_UI_PREFERENCES, {
      ...config,
      baseURL: getOtixoBackendApiUrl(),
    }),
  ]).then(([{ data: dataOld }, { data: dataNew }]) => {
    const preferences = {
      ...pick(dataOld, OLD_PREFERENCES_KEYS),
      /**
       * migration: the user's first request to the new backend will contain no preferences, so
       * we should use the preferences from the old backend.
       */
      ...omit(dataNew || dataOld, OLD_PREFERENCES_KEYS),
    };
    saveToDb(preferences);
    return preferences;
  });
}

/**
 * If the network request fails, return the user from the cache instead
 */
export async function get() {
  try {
    return await getFromNetwork();
  } catch (error) {
    const cachedPreferences = await getFromCache();
    if (!cachedPreferences) {
      throw error;
    }
    return cachedPreferences;
  }
}
