export const LOAD_MEDIAINFO_SUCCESS = 'MEDIAINFO/LOAD_SUCCESS';
export const LOAD_MEDIAINFO_REQUEST = 'MEDIAINFO/LOAD_REQUEST';
export const LOAD_MEDIAINFO_ERROR = 'MEDIAINFO/LOAD_ERROR';

export function loadMediaInfoRequest(id) {
  return {
    type: LOAD_MEDIAINFO_REQUEST,
    id,
  };
}

export function loadMediaInfoSuccess(id, mediaInfo) {
  return {
    type: LOAD_MEDIAINFO_SUCCESS,
    id,
    mediaInfo,
  };
}

export function loadMediaInfoError(id, error) {
  return {
    type: LOAD_MEDIAINFO_ERROR,
    id,
    error,
  };
}
