import axios from 'axios';
import {
  getMeUrl,
  getUnverifiedUserRequestAllowedConfig,
} from 'DataLayer/endpoints';
import process from './process';
import { get as getFromDb, save as saveToDb } from 'DataLayer/database/user';
import { getOtixoBackendApiUrl } from 'config/config';

export async function getFromCache() {
  return await getFromDb();
}

export async function getFromNetwork() {
  const config = getUnverifiedUserRequestAllowedConfig();

  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .get(getMeUrl(), config)
    .then(response => response.data.Me)
    .then(user => {
      const processedUser = process(user);
      saveToDb(processedUser);
      return processedUser;
    });
}

/**
 * If the network request fails, return the user from the cache instead
 */
export async function get() {
  try {
    const networkUser = await getFromNetwork();
    return networkUser;
  } catch (error) {
    const cachedUser = await getFromCache();
    if (!cachedUser) {
      throw error;
    }
    return cachedUser;
  }
}
