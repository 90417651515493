import { ADD_COMMENT, REMOVE_COMMENT, EDIT_COMMENT } from './actions';

const LOAD_COMMENTS_SUCCESS = 'COMMENTS/LOAD_SUCCESS';

export function commentsLoaded(comments) {
  return {
    type: LOAD_COMMENTS_SUCCESS,
    comments,
  };
}

function setComments(state, comments) {
  const commentsObject = comments.reduce((object, comment) => {
    object[comment.Id] = comment;
    return object;
  }, {});

  return {
    ...state,
    ...commentsObject,
  };
}

function addComment(state, comment) {
  return {
    ...state,
    [comment.Id]: comment,
  };
}

function removeComment(state, commentId) {
  const comments = { ...state };
  delete comments[commentId];
  return { ...comments };
}

function editComment(state, commentId, text) {
  if (!state[commentId]) {
    return state;
  }
  const comment = { ...state[commentId] };
  comment.Text = text;
  return {
    ...state,
    [commentId]: comment,
  };
}

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_COMMENTS_SUCCESS:
      return setComments(state, action.comments);
    case ADD_COMMENT:
      return addComment(state, action.newComment);
    case REMOVE_COMMENT:
      return removeComment(state, action.commentId);
    case EDIT_COMMENT:
      return editComment(state, action.commentId, action.value);
    default:
      return state;
  }
}
