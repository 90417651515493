export const products = {
  space_access_control: 'space_access_control',
  upload_to_clouds_allowed: 'upload_to_clouds_allowed',
  download_from_clouds_allowed: 'download_from_clouds_allowed',
  add_accounts: 'add_accounts',
  export_channel: 'export_channel',
  export_workspace: 'export_workspace',
  otixo_drive_size: 'otixo_drive_size',
  max_integration_number: 'max_integration_number',
  encryption: 'encryption',
  share_folder_to_space: 'share_folder_to_space',
  restricted_clouds: 'restricted_clouds',
  create_space_folder: 'create_space_folder',
  transfer_files_in_workspace: 'transfer_files_in_workspace',
  guests: 'guests',
  share_across_workspaces: 'share_across_workspaces',
  meetings_across_workspaces: 'meetings_across_workspaces',
  meeting_password: 'meeting_password',
  meeting_lobby: 'meeting_lobby',
};

export default products;
