import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';

function calculateDays(expiry) {
  return differenceInCalendarDays(format(expiry), format(new Date()));
}

export default function(share) {
  const newShare = Object.assign({}, share);
  if (share.Expires) {
    newShare.days = calculateDays(share.Expires);
  } else {
    newShare.days = null;
  }
  return newShare;
}
