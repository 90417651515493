import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userCountryDetected } from 'store/Application';

const TWO_LETTER_COUNTRY_CODE_INDEX = 1;

function UserLocationInformation({ onCountryDetected }) {
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://ip2c.org/self');
        const text = await response.text();
        //1;CD;COD;COUNTRY
        const info = text.split(';');
        const twoLetterCountryCode = info[TWO_LETTER_COUNTRY_CODE_INDEX];
        if (twoLetterCountryCode.length === 2) {
          onCountryDetected(info[TWO_LETTER_COUNTRY_CODE_INDEX]);
        }
      } catch (ex) {
        // failing to detect the country is not critical
      }
    };
    fetchCountry();
  }, [onCountryDetected]);
  return null;
}

UserLocationInformation.propTypes = {
  onCountryDetected: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onCountryDetected: userCountryDetected,
})(UserLocationInformation);
