import { FormattedMessage } from 'react-intl';

import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Message, Button } from 'semantic-ui-react';
import get from 'lodash/get';

import { resendInvitation } from 'DataLayer/Conversations/post';
import memberStatus from 'DataLayer/Constants/workspaceMemberStatus';

import {
  requestReducer,
  initialState,
  actionTypes,
} from 'Hooks/requestReducer';
import { getSpaceById } from 'store/Spaces/selectors';

import DismissableMessage from 'Components/DismissableMessage/DismissableMessage';

const PendingConversation = ({ space }) => {
  const [{ loading, error, success }, dispatch] = useReducer(
    requestReducer,
    initialState
  );

  useEffect(
    () => {
      dispatch({ type: actionTypes.RESET });
    },
    [space.Id]
  );

  if (!space.IsConversation) {
    return null;
  }
  if (get(space, 'Status.OtherUserStatus') === memberStatus.JOINED) {
    return null;
  }

  const onReSendInvitation = async () => {
    try {
      dispatch({ type: actionTypes.REQUESTING });
      await resendInvitation(space.Id);
      dispatch({ type: actionTypes.SUCCESS });
    } catch {
      dispatch({ type: actionTypes.ERROR });
    } finally {
    }
  };

  const getMessage = () => {
    switch (get(space, 'Status.OtherUserStatus')) {
      case memberStatus.INVITED:
      default:
        return (
          <>
            <FormattedMessage
              id="PendingConversation.has-been-invited-when-they-join-they-will-see-any-messages-you-have-written-below"
              values={{
                name: <strong>{space.Name}</strong>,
              }}
            />
          </>
        );
      case memberStatus.LEFT:
        return (
          <>
            <FormattedMessage
              id="PendingConversation.has-left-this-conversation"
              values={{
                name: <strong>{space.Name}</strong>,
              }}
            />
          </>
        );
      case memberStatus.REJECTED:
        return (
          <>
            <FormattedMessage
              id="PendingConversation.has-rejected-your-invitation"
              values={{
                name: <strong>{space.Name}</strong>,
              }}
            />
          </>
        );
    }
  };

  if (success) {
    return (
      <Message
        success
        className="flex flex-no-shrink m-3"
        onClose={() => {
          dispatch({ type: actionTypes.RESET });
        }}
      >
        <FormattedMessage id="PendingConversation.your-invitation-has-been-sent" />
      </Message>
    );
  }

  return (
    <Message className="flex column flex-no-shrink m-3">
      <DismissableMessage
        error
        hidden={!error}
        onClose={() => {
          dispatch({ type: actionTypes.RESET });
        }}
      >
        <FormattedMessage id="PendingConversation.there-was-a-problem-sending-the-invitation-please-try-again" />
      </DismissableMessage>
      <div className="flex flex-auto justify-content-space-between align-items-center">
        <span>{getMessage()}</span>
        <Button
          compact
          size="tiny"
          color="white"
          onClick={onReSendInvitation}
          disabled={loading}
          loading={loading}
        >
          <FormattedMessage id="PendingConversation.re-send-invitation" />
        </Button>
      </div>
    </Message>
  );
};

PendingConversation.propTypes = {
  space: PropTypes.object.isRequired,
};

export default connect((state, props) => ({
  space: getSpaceById(state, props.spaceId),
}))(PendingConversation);
