import { TRANSFER_SUBSCRIPTIONS_GET_SUCCESS } from './getTransferSubscriptions';

export default function subscriptionsReducer(state = [], action) {
  switch (action.type) {
    case TRANSFER_SUBSCRIPTIONS_GET_SUCCESS:
      return Object.assign({}, state, action.subscriptions);
    default:
      return state;
  }
}
