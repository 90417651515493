import React from 'react';
import PropTypes from 'prop-types';

import { BANNER_POSITION } from '../ScrollPositionTracker/ScrollPositionTracker';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';

import './ElementOutOfViewBanner.scss';
import { ArrowUpIcon } from 'Components/Icons/WeTeamIcon';
import { ArrowDownIcon } from 'Components/Icons/WeTeamIcon';

const ElementOutOfViewBanner = ({ children, position, ...rest }) => {
  return (
    <div className="element-out-of-view mx-3" {...rest}>
      {children}
      <WeTeamIcon
        color="white"
        link={false}
        Icon={position === BANNER_POSITION.top ? ArrowUpIcon : ArrowDownIcon}
      />
    </div>
  );
};

ElementOutOfViewBanner.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired,
};

export default ElementOutOfViewBanner;
