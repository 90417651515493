import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import isEqual from 'react-fast-compare';
import { injectIntl, intlShape } from 'react-intl';

import notificationType, {
  isFileNotification,
  MEETING_NOTIFICATION,
} from '../../../../Constants/notificationType';

import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

import ChatItem from '../../../Chat/ChatItem';
import NewMessageDivider from '../NewMessageDivider';
import DateDivider from '../DateDivider';
import NotificationText from 'Components/Notification/NotificationText';
import CollaboratorAvatarWithCardContainer from '../../../Collaborators/CollaboratorAvatarWithCardContainer';
import {
  canDeleteResource,
  canShareResource,
} from 'DataLayer/Resources/Helpers/permissions';

function getPerson(createdBy) {
  if (!createdBy) {
    return '';
  }
  return `${createdBy.FirstName} ${createdBy.LastName}`;
}

function getChannelId(notification) {
  if (notification.Workspace) {
    return notification.Workspace.Id;
  }

  return '';
}

export class SpaceChatItems extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  onDownload = () => {
    if (this.props.notification.Resource) {
      this.props.onDownload(this.props.notification.Resource.Id);
    }
  };

  onTransferToOtixoDrive = () => {
    if (this.props.notification.Resource) {
      this.props.onTransferToOtixoDrive(this.props.notification.Resource);
    }
  };

  onShareMessage = () => {
    this.props.onShareMessage(this.props.notification);
  };

  canTransferToOtixoDrive = () => {
    const { notification } = this.props;
    return isFileNotification(notification);
  };

  canDelete = () => {
    const { notification } = this.props;
    const isCurrentUser = notification.IsMe;
    const isChatMessage = notification.Type === notificationType.ChannelComment;
    const isReminder = notification.Type === notificationType.ReminderFired;

    if (isFileNotification(notification)) {
      return canDeleteResource(notification.Resource);
    }
    return isCurrentUser && (isChatMessage || isReminder);
  };

  canShare = () => {
    const { notification } = this.props;
    const isFile =
      notification.Type === notificationType.ChannelResourceLinked ||
      notification.Type === notificationType.ChannelNewResource;

    if (isFile) {
      return canShareResource(notification.Resource);
    }
    return notification.Type === notificationType.ChannelComment;
  };

  canPreview = () => {
    const { notification, showPreview } = this.props;
    if (!showPreview) {
      return false;
    }
    if (notification.ResourceRemoved) {
      return false;
    }
    return (
      notification.Type === notificationType.ChannelResourceLinked ||
      notification.Type === notificationType.ChannelNewResource ||
      notification.Type === notificationType.WorkspaceNewResource_DEPRECATED
    );
  };

  shouldShowFullScreenPreviewOption = () => {
    const { notification } = this.props;
    if (notification.ResourceRemoved) {
      return false;
    }
    if (notification.Resource?.Folder) {
      return false;
    }
    return (
      notification.Type === notificationType.ChannelResourceLinked ||
      notification.Type === notificationType.ChannelNewResource ||
      notification.Type === notificationType.WorkspaceNewResource_DEPRECATED
    );
  };

  onFullScreenPreview = ({ showCommentsSidebar }) => {
    const { onFullScreenPreview, notification } = this.props;
    const workspaceId = getChannelId(notification);
    onFullScreenPreview(
      notification.Resource?.Id,
      workspaceId,
      showCommentsSidebar
    );
  };

  render() {
    const {
      notification,
      intl,
      highlight,
      firstInGroup,
      onDelete,
      onEdit,
      onMessageReply,
      lastReadUsers,
      showActions,
      showGutter,
    } = this.props;
    const isCurrentUser = notification.IsMe;
    const isChatMessage =
      notification.Type === notificationType.ChannelComment ||
      notification.Type === notificationType.SpaceComment_DEPRECATED;

    const includePreview = this.canPreview();
    const shouldShowFullScreenPreviewOption =
      this.shouldShowFullScreenPreviewOption();

    const isReminder = notification.Type === notificationType.ReminderFired;
    const isMeeting = notification.Type === MEETING_NOTIFICATION;

    const canEdit = isCurrentUser && isChatMessage;

    const canTransferToOtixoDrive = this.canTransferToOtixoDrive();
    const canDelete = this.canDelete();
    const canShare = this.canShare();
    const canReply = isChatMessage;
    const canRemind = !isReminder;
    const canStar = !isReminder;

    const workspaceId = getChannelId(notification);

    const messageClass = classNames({
      'space-chat-message': true,
      highlight,
      first: firstInGroup,
      starred: notification.Starred,
    });

    const isSystem = isReminder;

    return (
      <React.Fragment>
        {this.props.showDate && (
          <DateDivider key={notification.Day} date={notification.Day} />
        )}
        {this.props.showNewMessage && (
          <NewMessageDivider key={'new-messages'} />
        )}
        <div ref={this.props.innerRef} className={messageClass}>
          <ChatItem
            forwarded={notification.IsForwarded}
            showSeenBy
            timestamp={notification.NotificationTimestamp}
            text={
              <ErrorBoundary>
                <NotificationText
                  notification={notification}
                  intl={intl}
                  showPreview={includePreview}
                />
              </ErrorBoundary>
            }
            rawText={notification.Comment && notification.Comment.Text}
            name={getPerson(notification.CreatedBy)}
            email={notification.CreatedBy.UserID}
            forceOnline={notification.AlwaysOnline}
            showName={firstInGroup}
            spaceId={workspaceId}
            onDelete={onDelete}
            onDownload={this.onDownload}
            onTransferToOtixoDrive={this.onTransferToOtixoDrive}
            canTransferToOtixoDrive={canTransferToOtixoDrive}
            onEdit={onEdit}
            onShareMessage={this.onShareMessage}
            onMessageReply={onMessageReply}
            id={notification.Id}
            showActions={showActions && !isMeeting}
            canEdit={canEdit}
            canShare={canShare}
            canReply={canReply}
            canDelete={canDelete}
            canDownload={includePreview}
            avatar={notification.ApplicationImageUrl}
            temporary={notification.Temporary}
            starred={notification.Starred}
            showGutter={showGutter}
            canRemind={canRemind}
            reminderId={notification.ReminderId}
            reminderTargetId={notification.ReminderTargetId}
            reminderTarget={notification.ReminderTarget}
            reminderType={notification.ReminderType}
            canStar={canStar}
            isSystem={isSystem}
            shouldShowFullScreenPreviewOption={
              shouldShowFullScreenPreviewOption
            }
            onFullScreenPreview={this.onFullScreenPreview}
            resourceId={notification.Resource?.Id}
          />
        </div>
        {lastReadUsers && lastReadUsers.length > 0 && (
          <div className="flex wrap justify-content-end">
            {lastReadUsers.map(userEmail => (
              <CollaboratorAvatarWithCardContainer
                key={userEmail}
                spaceId={workspaceId}
                email={userEmail}
                showStatus={false}
                showNameAsTooltip={true}
                title={intl.formatMessage({
                  id: 'SpaceChatItems.last-message-read-by',
                })}
                width={22}
                height={22}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}
SpaceChatItems.propTypes = {
  notification: PropTypes.object.isRequired,
  firstInGroup: PropTypes.bool,
  showPreview: PropTypes.bool,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onTransferToOtixoDrive: PropTypes.func,
  onEdit: PropTypes.func,
  onShareMessage: PropTypes.func,
  onMessageReply: PropTypes.func,
  showNewMessage: PropTypes.bool,
  showDate: PropTypes.bool,
  highlight: PropTypes.bool,
  innerRef: PropTypes.object,
  intl: intlShape.isRequired,
  lastReadUsers: PropTypes.array,
  showActions: PropTypes.bool,
  showGutter: PropTypes.bool,
  onFullScreenPreview: PropTypes.func,
};

SpaceChatItems.defaultProps = {
  showPreview: true,
  showActions: true,
};

//export default React.forwardRef((props, ref) => <SpaceChatItems innerRef={ref} {...props} />);
export default injectIntl(SpaceChatItems);
