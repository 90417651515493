import LaunchFileInDesktop from 'Components/LaunchFileInDesktop/LaunchFileInDesktop';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function LaunchFilesInDesktop({ resourceIds, channelId, onLaunchComplete }) {
  const [isComplete, setIsComplete] = useState([]);

  useEffect(() => {
    if (isComplete.length === resourceIds.length) {
      onLaunchComplete();
    }
  }, [isComplete.length, onLaunchComplete, resourceIds.length]);

  return resourceIds.map(resourceId => (
    <LaunchFileInDesktop
      resourceId={resourceId}
      channelId={channelId}
      onLaunchComplete={() => {
        setIsComplete([...isComplete, resourceId]);
      }}
    />
  ));
}

LaunchFilesInDesktop.propTypes = {
  resourceIds: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  onLaunchComplete: PropTypes.func,
};

export default LaunchFilesInDesktop;
