import axios from 'axios';
import { shouldUseAuthToken } from 'config/config';
import { setToken } from 'Helpers/BrowserApi/localStorage';

export const setAuthenticationToken = (
  authenticationIdentifier,
  authenticationToken
) => {
  const token = `${authenticationIdentifier} ${authenticationToken}`;
  if (shouldUseAuthToken()) {
    setToken(token);
    axios.defaults.headers.common.Authorization = token;
  }
};
