import axios from 'axios';
import {
  NEW_WEB_UI_PREFERENCES,
  WEB_UI_PREFERENCES,
} from 'DataLayer/endpoints';
import { save as saveToDb } from 'DataLayer/database/webUiPreferences';
import { getOtixoBackendApiUrl } from 'config/config';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { OLD_PREFERENCES_KEYS } from 'DataLayer/WebUIPreferences/get';

export async function put(preferences) {
  const oldPreferences = pick(preferences, OLD_PREFERENCES_KEYS);
  const newPreferences = omit(preferences, OLD_PREFERENCES_KEYS);

  await Promise.all([
    axios.put(WEB_UI_PREFERENCES, oldPreferences),
    axios.put(NEW_WEB_UI_PREFERENCES, newPreferences, {
      baseURL: getOtixoBackendApiUrl(),
    }),
  ]);
  const preferencesNew = {
    ...oldPreferences,
    ...newPreferences,
  };
  saveToDb(preferencesNew);
  return preferencesNew;
}
