import * as requestTypes from './requestTypes';
import createRequestKey from './createRequestKey';

export const SET_REQUEST_IN_PROGRESS = 'REQUESTS/SET_IN_PROGRESS';

export function setRequestInProgress(id, inProgress) {
  return {
    type: SET_REQUEST_IN_PROGRESS,
    id,
    inProgress,
  };
}
/*
export function setMediaRequestInProgress(id, inProgress) {
  const request = createRequestKey(requestTypes.MEDIAINFO, id);
  return setRequestInProgress(request, inProgress);
}
*/
export function setResourcesRequestInProgress(id, inProgress) {
  const request = createRequestKey(requestTypes.RESOURCES, id);
  return setRequestInProgress(request, inProgress);
}

export function setResourceRequestInProgress(id, inProgress) {
  const request = createRequestKey(requestTypes.RESOURCE, id);
  return setRequestInProgress(request, inProgress);
}

export function setFoldersRequestInProgress(id, inProgress) {
  const request = createRequestKey(requestTypes.FOLDERS, id);
  return setRequestInProgress(request, inProgress);
}

export function setRepositoryRequestInProgress(id, inProgress) {
  const request = createRequestKey(requestTypes.REPOSITORY, id);
  return setRequestInProgress(request, inProgress);
}

export function setLinkedItemRequestInProgress(
  spaceId,
  resourceId,
  inProgress
) {
  const request = createRequestKey(
    requestTypes.LINKED_ITEM,
    `${spaceId}/${resourceId}`
  );
  return setRequestInProgress(request, inProgress);
}

export function setRepositoresRequestInProgress(inProgress) {
  return setRequestInProgress(requestTypes.REPOSITORIES, inProgress);
}

export function setSpacesRequestInProgress(inProgress) {
  return setRequestInProgress(requestTypes.SPACES, inProgress);
}

export function setSpaceRequestInProgress(id, inProgress) {
  const request = createRequestKey(requestTypes.SPACE, id);
  return setRequestInProgress(request, inProgress);
}

export function setAccountRequestInProgress(id, inProgress) {
  const request = createRequestKey(requestTypes.ACCOUNT, id);
  return setRequestInProgress(request, inProgress);
}

export function setAccountsRequestInProgress(inProgress) {
  return setRequestInProgress(requestTypes.ACCOUNTS, inProgress);
}

export function setCommentsRequestInProgress(spaceId, resourceId, inProgress) {
  const request = createRequestKey(requestTypes.COMMENTS, spaceId, resourceId);
  return setRequestInProgress(request, inProgress);
}

export function setResourceLinkRequestInProgress(inProgress) {
  return setRequestInProgress(requestTypes.RESOURCE_LINK, inProgress);
}

export function setPublicResourceRequestInProgress(inProgress) {
  return setRequestInProgress(requestTypes.PUBLIC_RESOURCE, inProgress);
}
