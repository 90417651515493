import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getNoteByChannelIdUrl } from 'DataLayer/endpoints';

import {
  getNotesByChannelId,
  //getNotes,
  saveMany,
} from 'DataLayer/database/notes';

export async function getFromCache(channelId) {
  return await getNotesByChannelId(channelId);
}

export async function getFromNetwork(channelId) {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };
  const { data } = await axios.get(getNoteByChannelIdUrl(channelId), config);

  if (!data) {
    return [];
  }

  saveMany(data, channelId);
  return data;
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function fetchNotes(channelId) {
  const cachedNotes = await getFromCache(channelId);
  try {
    return await getFromNetwork(channelId);
  } catch (error) {
    return cachedNotes || [];
  }
}
