import { edit } from 'DataLayer/Reminders/edit';
import { reminderAdded } from './index';
import { displaySuccessToast } from 'store/Toast/displayToast';

export const editReminder = (reminderId, time, note) => dispatch => {
  return edit(reminderId, time, note).then(reminder => {
    dispatch(reminderAdded(reminder));
    dispatch(displaySuccessToast('createReminder.success'));
  });
};

export default editReminder;
