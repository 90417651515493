import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './NavButton.module.scss';

export default function NavButton({
  children,
  icon,
  selected,
  className,
  noBackgroundOnHover,
  ...props
}) {
  return (
    <div
      className={classNames(styles.navButton, className, {
        [styles.selected]: selected,
        [styles.noBackgroundOnHover]: noBackgroundOnHover,
      })}
      {...props}
    >
      <span className={`${styles.navButtonIcon} mr-1`}>{icon}</span>
      {children}
    </div>
  );
}

NavButton.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  noBackgroundOnHover: PropTypes.bool,
  icon: PropTypes.node,
};
