import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PreviewContainer from 'Components/Chat/Previewers/PreviewContainer';

import { chatPreviewHidden } from 'store/UI/index';
import { getIsChatPreviewHidden } from 'store/UI/selectors';
import { Icon } from 'semantic-ui-react';

import styles from './ResourceLinked.module.css';
import { intlShape } from 'react-intl';
import ResourceTags from 'Components/Tagger/ResourceTags';

export const ResourceLinked = ({
  notification,
  text,
  intl,
  showPreview,
  onToggleHide,
  isHidden,
}) => {
  const notificationId = notification.Id;
  const resourceId = notification.Resource?.Id;
  const resourceName = notification.Resource?.Name;
  const resourceType = notification.Resource?.Type;
  const resourceSize = notification.Resource?.Size;
  const resourceHeight = notification.Resource?.ImageHeight || 360;
  const workspaceId = notification.Workspace?.Id;
  const fileDeleted = notification.FileDeleted;

  const onToggleClick = () => {
    onToggleHide(resourceId);
  };

  return (
    <div className={styles.resourceLinked}>
      {text}
      {showPreview && (
        <>
          <Icon
            data-testid="toggle-icon"
            title={isHidden ? 'Show preview' : 'Hide preview'}
            className={styles.toggle}
            link
            size="small"
            name={isHidden ? 'chevron right' : 'chevron down'}
            onClick={onToggleClick}
          />
          <div className="my-2">
            <ResourceTags
              resourceId={resourceId}
              channelId={workspaceId}
              truncateTags={false}
              clickToAddClassName={'chat-items-tags'}
            />
          </div>
          {!isHidden && (
            <PreviewContainer
              notificationId={notificationId}
              resourceId={resourceId}
              resourceName={resourceName}
              resourceType={resourceType}
              resourceSize={resourceSize}
              resourceHeight={resourceHeight}
              spaceId={workspaceId}
              fileDeleted={fileDeleted}
            />
          )}
        </>
      )}
    </div>
  );
};

ResourceLinked.propTypes = {
  notification: PropTypes.object.isRequired,
  text: PropTypes.element.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isHidden: PropTypes.bool,
  onToggleHide: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  showPreview: PropTypes.bool.isRequired,
};

export default withRouter(
  connect(
    (state, props) => ({
      isHidden: getIsChatPreviewHidden(state, props.resourceId),
    }),
    {
      onToggleHide: chatPreviewHidden,
    }
  )(ResourceLinked)
);
