import { FormattedMessage } from 'react-intl';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { getSpaceLink } from 'pages';
import { withRouter } from 'react-router';
import { getWorkspaceById } from 'store/Workspaces/selectors';

import { makeGetSingleSpaceByEmailAndWorkspaceId } from 'store/Spaces/selectors';
import { hideAllModals } from 'store/Modals/actions';

export const InformTeamspaceOwnerButton = ({
  ownerDirectChatId,
  onClick = () => {},
  onHideAllModals,
  router,
}) => {
  if (!ownerDirectChatId) {
    return null;
  }
  return (
    <Button
      primary
      type="button"
      onClick={() => {
        onClick();
        onHideAllModals();
        router.push(getSpaceLink(ownerDirectChatId));
      }}
    >
      <FormattedMessage id="InformTeamspaceOwnerButton.ContactOwnerButtonText" />
    </Button>
  );
};

InformTeamspaceOwnerButton.propTypes = {
  ownerDirectChatId: PropTypes.string,
  onClick: PropTypes.func,
  onHideAllModals: PropTypes.func,
  router: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect(
  (state, props) => {
    const getSingleSpaceByEmailAndWorkspaceId = makeGetSingleSpaceByEmailAndWorkspaceId();

    return (state, props) => {
      const workspace = getWorkspaceById(state, props.workspaceId);
      const ownerDirectChat = getSingleSpaceByEmailAndWorkspaceId(state, {
        workspaceId: workspace.Id,
        email: workspace.OwnerMail,
      });
      return {
        ownerDirectChatId: ownerDirectChat?.Id,
      };
    };
  },
  {
    onHideAllModals: hideAllModals,
  }
)(withRouter(InformTeamspaceOwnerButton));
