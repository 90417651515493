import * as Sentry from '@sentry/react';

const REPLY_PRE_TEXT = 'Reply to ';
const REPLY_POST_TEXT = '--------';

export const isReplyMessage = text =>
  text.startsWith(REPLY_PRE_TEXT) && text.includes(REPLY_POST_TEXT);

export const getReplyMessage = text =>
  text.substring(text.indexOf(REPLY_POST_TEXT) + REPLY_POST_TEXT.length).trim();

export const getOriginalMessage = text =>
  text.substring(0, text.indexOf(REPLY_POST_TEXT)).trim();

export const editReplyMessage = (fullMessage, newReply) =>
  `${getOriginalMessage(fullMessage)}
  ${REPLY_POST_TEXT}
  ${newReply}`;

export const getReplyInfo = text => {
  try {
    if (isReplyMessage(text)) {
      return {
        original: getOriginalMessage(text),
        reply: getReplyMessage(text),
      };
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  return null;
};

export const buildReplyText = (
  user,
  originalText,
  replyText
) => `${REPLY_PRE_TEXT}${user}:
"${originalText}"
${REPLY_POST_TEXT}
${replyText}`;
