import PropTypes from 'prop-types';
import React from 'react';
import CollaboratorInfo from './CollaboratorInfo';
import Avatar from './Avatar';
import CollaboratorPresence from './CollaboratorPresence';

class CollaboratorCard extends React.PureComponent {
  render() {
    const { collaborator } = this.props;
    return (
      <div className="flex column align-items-center mb-2">
        <span className="mt-3">
          <Avatar
            avatarUrl={collaborator.AvatarUrl}
            color={collaborator.Color}
            email={collaborator.Email}
            firstName={collaborator.FirstName}
            lastName={collaborator.LastName}
            presence={collaborator.Presence}
            width={150}
            height={150}
            showStatus={false}
          />
        </span>
        <h2>{`${collaborator.FirstName} ${collaborator.LastName}`}</h2>
        <span>
          <span className="mr-2">
            <CollaboratorPresence presence={collaborator.Presence} />
          </span>
          <CollaboratorInfo collaborator={collaborator} />
        </span>
      </div>
    );
  }
}

CollaboratorCard.propTypes = {
  collaborator: PropTypes.object,
};

export default CollaboratorCard;
