import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { OFFERS, getOfferThreshold } from 'DataLayer/Workspaces/Offers/config';

import acceptOffer from 'store/Workspaces/Offer/acceptOffer';
import { isTenMembersOfferAvailable } from 'store/Workspaces/Offer/selectors';
import OfferAcceptedModal from 'Components/WorkspaceOffer/OfferAcceptedModal/OfferAcceptedModal';
import { useMutation } from 'react-query';
import { getNumberOfMembersForSubscription } from 'store/Workspaces/Members/selectors';
import { getPricingPageUrl } from 'config/config';
import { getLocale } from 'store/Locale/selectors';
import { trial10MembersAddedAnalyticsEvent } from 'Components/GoogleAnalytics/events';

function TenMembersOfferAcceptedModal({ workspaceId }) {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);

  const locale = useSelector(getLocale);

  const tenMembersOfferAvailable = useSelector(state =>
    isTenMembersOfferAvailable(state, workspaceId)
  );

  const numberOfMembersForSubscription = useSelector(state =>
    getNumberOfMembersForSubscription(state, workspaceId)
  );

  useEffect(() => {
    setModalVisible(false);
  }, [dispatch, workspaceId]);

  const { mutate } = useMutation({
    mutationFn: () => {
      return dispatch(acceptOffer(workspaceId, OFFERS.add_ten_members));
    },
    onSuccess: () => {
      trial10MembersAddedAnalyticsEvent();
      setModalVisible(true);
    },
  });

  useEffect(() => {
    if (
      tenMembersOfferAvailable &&
      numberOfMembersForSubscription > getOfferThreshold(OFFERS.add_ten_members)
    ) {
      mutate();
    }
  }, [
    dispatch,
    tenMembersOfferAvailable,
    mutate,
    workspaceId,
    numberOfMembersForSubscription,
  ]);

  const onClose = () => {
    setModalVisible(false);
  };

  return (
    <OfferAcceptedModal
      visible={modalVisible}
      workspaceId={workspaceId}
      onClose={onClose}
      message={
        <FormattedMessage
          id="TenMembersOfferAcceptedModal.Message"
          values={{
            pricingPageLink: (
              <a
                href={getPricingPageUrl(locale)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="OfferAcceptedModal.ButtonText.LinkText" />
              </a>
            ),
          }}
        />
      }
    />
  );
}

TenMembersOfferAcceptedModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default TenMembersOfferAcceptedModal;
