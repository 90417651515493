import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './RightSidebarPanelTitle.scss';
import { spaceSidebarPanelSelected } from 'store/UI/index';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { CrossIcon } from 'Components/Icons/WeTeamIcon';

class RightSidebarPanelTitle extends PureComponent {
  onClose = () => {
    this.props.dispatch(spaceSidebarPanelSelected(null, null));
  };
  render() {
    const { children, className } = this.props;
    return (
      <div className={`right-sidebar-panel-title ${className}`}>
        <strong>{children}</strong>
        <WeTeamIcon
          Icon={CrossIcon}
          className="flex-no-shrink"
          onClick={this.onClose}
        />
      </div>
    );
  }
}

RightSidebarPanelTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func,
};

RightSidebarPanelTitle.defaultProps = {
  className: '',
};

export default connect()(RightSidebarPanelTitle);
