import { FormattedMessage } from 'react-intl';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser } from 'store/User/selectors';

import 'PublicSharing/Password.scss';

import LightLogo from 'assets/images/logo/Horizontal/WeTeam-Logo-Horizontal.svg';
import DarkLogo from 'assets/images/logo/Horizontal/WeTeam-Logo-Horizontal-WhiteFont.svg';
import usePrefersColorScheme from 'use-prefers-color-scheme';

function Maintenance({ user }) {
  const prefersColorScheme = usePrefersColorScheme();
  return (
    <div className="full-screen-app">
      <div className="full-screen-centered-container">
        <div className="ui segment flex column align-items-center">
          <a href="https://we.team" target="_blank" rel="noopener noreferrer">
            <img
              src={prefersColorScheme === 'dark' ? DarkLogo : LightLogo}
              alt="We.Team logo"
              width={200}
            />
          </a>
          <div className="link_password_text">
            <h2 className="warning-title">
              <FormattedMessage id="Maintenance.title" />
            </h2>
            <p>
              <FormattedMessage id="Maintenance.message" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

Maintenance.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(state => ({
  user: getUser(state),
}))(Maintenance);
