import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Label } from 'semantic-ui-react';

import { sendInvitation } from 'store/Meeting/Users/sendInvitation';
import { deleteInvitation } from 'store/Meeting/Users/deleteInvitation';

import useAsyncFn from 'Hooks/useAsyncFn';

import styles from './Participant.module.scss';

const Participant = ({ activeMeeting, icon, user }) => {
  const dispatch = useDispatch();
  const isInvited = activeMeeting.users.some(
    meetingUser => meetingUser.invite && meetingUser.userId === user.userId
  );
  const hasJoined = activeMeeting.participantUserIds.some(
    participantUserId => participantUserId === user.userId
  );

  const showSendInvitationButton = !hasJoined && !isInvited;
  const showRemoveInvitationButton = !hasJoined && isInvited;

  const [sendInvitationRequest, onSendInvitation] = useAsyncFn(async () => {
    await dispatch(sendInvitation(activeMeeting.roomId, user));
  }, [activeMeeting, user]);

  const [deleteInvitationRequest, onDeleteInvitation] = useAsyncFn(async () => {
    await dispatch(deleteInvitation(activeMeeting.roomId, user.userId));
  }, [activeMeeting, user]);

  // don't allow removal if there are only 2 members.
  const removeButtonDisabled =
    deleteInvitationRequest.loading || activeMeeting.users.length < 3;

  return (
    <div className={styles.member}>
      {icon}
      <span className="mx-3">{`${user.firstName} ${user.lastName}`}</span>
      {hasJoined && (
        <Label className={styles.actionButton}>
          <FormattedMessage id="Participant.MemberJoined" />
        </Label>
      )}

      {showSendInvitationButton && (
        <Button
          onClick={onSendInvitation}
          loading={sendInvitationRequest.loading}
          disabled={sendInvitationRequest.loading}
          primary
          compact
          size="small"
          className={styles.actionButton}
        >
          <FormattedMessage id="Participant.InviteButtonText" />
        </Button>
      )}
      {showRemoveInvitationButton && (
        <Button
          onClick={onDeleteInvitation}
          loading={deleteInvitationRequest.loading}
          disabled={removeButtonDisabled}
          compact
          size="small"
          className={styles.actionButton}
        >
          <FormattedMessage id="Participant.RemoveButtonText" />
        </Button>
      )}
    </div>
  );
};

Participant.propTypes = {
  activeMeeting: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default Participant;
