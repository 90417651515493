import { REMOVE_NOTIFICATION } from 'store/Notifications/actions';

export const STARRED_LOADED = 'STARRED/LOADED';
export const STARRED_ADDED = 'STARRED/ADDED';
export const STARRED_REMOVED = 'STARRED/REMOVED';

export const STAR_TYPE = {
  NOTIFICATION: 'NOTIFICATION',
  LINKEDITEMS: 'LINKEDITEMS',
  RESOURCES: 'RESOURCES',
};

export function starredLoaded(stars) {
  const notifications = stars.Notifications.map(notification => ({
    StarType: STAR_TYPE.NOTIFICATION,
    value: notification,
  }));
  const linkedItems = stars.LinkedItems.map(linkedItem => ({
    StarType: STAR_TYPE.LINKEDITEMS,
    value: linkedItem,
  }));
  const resources = stars.Resources.map(resource => ({
    StarType: STAR_TYPE.RESOURCES,
    value: resource,
  }));

  const starsById = [...notifications, ...linkedItems, ...resources].reduce(
    (obj, value) => {
      // There is an assumption here that the object will have an Id key
      obj[value.value.Id] = value;
      return obj;
    },
    {}
  );
  return {
    type: STARRED_LOADED,
    stars: starsById,
  };
}

export function starredAdded(starType, value) {
  return {
    type: STARRED_ADDED,
    starType,
    value,
  };
}

export function starredRemoved(id) {
  return {
    type: STARRED_REMOVED,
    id,
  };
}

function removeStar(state, id) {
  const stars = Object.assign({}, state);
  delete stars[id];
  return stars;
}

const intitialState = {};

export default function(state = intitialState, action) {
  switch (action.type) {
    case REMOVE_NOTIFICATION:
      return removeStar(state, action.id);
    case STARRED_REMOVED:
      return removeStar(state, action.id);
    case STARRED_ADDED:
      return {
        [action.value.Id]: {
          StarType: action.starType,
          value: action.value,
        },
        ...state,
      };
    case STARRED_LOADED:
      return action.stars;
    default:
      return state;
  }
}
