import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { PDF, isImagePreview } from 'DataLayer/Resources/Helpers/contentTypes';

import ThumbnailIcon from 'Components/Thumbnail/ThumbnailIcon';
import ThumbnailImage from 'Components/Thumbnail/ThumbnailImage';

import styles from './Thumbnail.module.scss';

export const DEFAULT_SIZE = 32;

function Thumbnail({ id, type, size, corner }) {
  const showImage = isImagePreview(type) || type === PDF;
  const style = useMemo(() => ({ width: size, height: size }), [size]);

  return (
    <div className={`${styles.thumbnail} mx-2`} style={style}>
      {showImage ? (
        <ThumbnailImage id={id} size={size} type={type} />
      ) : (
        <ThumbnailIcon size={size} type={type} />
      )}
      {corner && <span className={styles.corner}>{corner}</span>}
    </div>
  );
}

Thumbnail.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
  corner: PropTypes.node,
};

Thumbnail.defaultProps = {
  size: DEFAULT_SIZE,
};

export default React.memo(Thumbnail, (prevProps, nextProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.size === nextProps.size &&
    prevProps.type === nextProps.type
  );
});
