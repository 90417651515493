import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './WeTeamIcon.module.scss';

export { ReactComponent as UploadFolderIcon } from 'Components/Icons/WeTeam/UploadFolder.svg';
export { ReactComponent as MeetIcon } from 'Components/Icons/WeTeam/Meet.svg';
export { ReactComponent as FilterIcon } from 'Components/Icons/WeTeam/Filter.svg';
export { ReactComponent as FilterIcon2 } from 'Components/Icons/WeTeam/Filter2.svg';
export { ReactComponent as AppsIcon } from 'Components/Icons/WeTeam/Apps.svg';
export { ReactComponent as NotificationIcon } from 'Components/Icons/WeTeam/Notification.svg';
export { ReactComponent as TimeSquareIcon } from 'Components/Icons/WeTeam/Time Square.svg';
export { ReactComponent as StarIcon } from 'Components/Icons/WeTeam/Star.svg';
export { ReactComponent as QuestionIcon } from 'Components/Icons/WeTeam/Question.svg';
export { ReactComponent as SearchIcon } from 'Components/Icons/WeTeam/Search.svg';
export { ReactComponent as ProfileIcon } from 'Components/Icons/WeTeam/Profile.svg';
export { ReactComponent as FolderIcon } from 'Components/Icons/WeTeam/Folder.svg';
export { ReactComponent as ArrowDownIcon } from 'Components/Icons/WeTeam/Arrow - Down.svg';
export { ReactComponent as ArrowUpIcon } from 'Components/Icons/WeTeam/Arrow - Up.svg';
export { ReactComponent as ArrowDown2Icon } from 'Components/Icons/WeTeam/Arrow - Down 2.svg';
export { ReactComponent as ArrowUp2Icon } from 'Components/Icons/WeTeam/Arrow - Up 2.svg';
export { ReactComponent as ArrowRight2Icon } from 'Components/Icons/WeTeam/Arrow - Right 2.svg';
export { ReactComponent as ArrowLeft2Icon } from 'Components/Icons/WeTeam/Arrow - Left 2.svg';
export { ReactComponent as SwapIcon } from 'Components/Icons/WeTeam/Swap.svg';
export { ReactComponent as SmileIcon } from 'Components/Icons/WeTeam/Smile.svg';
export { ReactComponent as DotsIcon } from 'Components/Icons/WeTeam/Dots.svg';
export { ReactComponent as SendIcon } from 'Components/Icons/WeTeam/Send.svg';
export { ReactComponent as ShowIcon } from 'Components/Icons/WeTeam/Show.svg';
export { ReactComponent as PlusIcon } from 'Components/Icons/WeTeam/Plus.svg';
export { ReactComponent as AddUserIcon } from 'Components/Icons/WeTeam/Add User.svg';
export { ReactComponent as AddIcon } from 'Components/Icons/WeTeam/Add.svg';
export { ReactComponent as CloudIcon } from 'Components/Icons/WeTeam/Cloud.svg';
export { ReactComponent as EditIcon } from 'Components/Icons/WeTeam/Edit.svg';
export { ReactComponent as DeleteIcon } from 'Components/Icons/WeTeam/Delete.svg';
export { ReactComponent as LogoutIcon } from 'Components/Icons/WeTeam/LogOut.svg';
export { ReactComponent as DownloadIcon } from 'Components/Icons/WeTeam/Download.svg';
export { ReactComponent as ThreeUserIcon } from 'Components/Icons/WeTeam/3 User.svg';
export { ReactComponent as ChartIcon } from 'Components/Icons/WeTeam/Chart.svg';
export { ReactComponent as CategoryIcon } from 'Components/Icons/WeTeam/Category.svg';
export { ReactComponent as EditSquareIcon } from 'Components/Icons/WeTeam/Edit Square.svg';
export { ReactComponent as CrossIcon } from 'Components/Icons/WeTeam/cross.svg';
export { ReactComponent as UpdateIcon } from 'Components/Icons/WeTeam/Update.svg';
export { ReactComponent as UploadIcon } from 'Components/Icons/WeTeam/Upload.svg';
export { ReactComponent as ListIcon } from 'Components/Icons/WeTeam/List.svg';
export { ReactComponent as CameraIcon } from 'Components/Icons/WeTeam/Camera.svg';
export { ReactComponent as RedoIcon } from 'Components/Icons/WeTeam/redo.svg';
export { ReactComponent as UndoIcon } from 'Components/Icons/WeTeam/undo.svg';
export { ReactComponent as SearchPlusIcon } from 'Components/Icons/WeTeam/search-plus.svg';
export { ReactComponent as SearchMinusIcon } from 'Components/Icons/WeTeam/search-minus.svg';
export { ReactComponent as MicrophoneIcon } from 'Components/Icons/WeTeam/microphone.svg';
export { ReactComponent as StopIcon } from 'Components/Icons/WeTeam/stop.svg';
export { ReactComponent as PaperClipIcon } from 'Components/Icons/WeTeam/paperclip.svg';
export { ReactComponent as PlayCircleIcon } from 'Components/Icons/WeTeam/play-circle.svg';
export { ReactComponent as PauseCircleIcon } from 'Components/Icons/WeTeam/pause-circle.svg';
export { ReactComponent as CheckSquareIcon } from 'Components/Icons/WeTeam/check-square.svg';
export { ReactComponent as SquareIcon } from 'Components/Icons/WeTeam/square.svg';
export { ReactComponent as TurnDownLeftIcon } from 'Components/Icons/WeTeam/TurnDownLeft.svg';
export { ReactComponent as ShareIcon } from 'Components/Icons/WeTeam/Share.svg';
export { ReactComponent as CommentCheckIcon } from 'Components/Icons/WeTeam/CommentCheck.svg';
export { ReactComponent as CommentSlashIcon } from 'Components/Icons/WeTeam/CommentSlash.svg';
export { ReactComponent as CommentLines } from 'Components/Icons/WeTeam/CommentLines.svg';
export { ReactComponent as StickyNoteIcon } from 'Components/Icons/WeTeam/StickyNote.svg';
export { ReactComponent as TagIcon } from 'Components/Icons/WeTeam/Tag.svg';

const WeTeamIcon = ({
  Icon,
  className,
  color,
  active,
  link = true,
  ...props
}) => {
  return (
    <Icon
      className={classNames(className, styles.icon, {
        [styles.link]: link,
        [styles.active]: active,
        [styles.white]: color === 'white',
        [styles.green]: color === 'green',
        [styles.blue]: color === 'blue',
      })}
      {...props}
    />
  );
};

WeTeamIcon.propTypes = {
  Icon: PropTypes.object.isRequired,
  className: PropTypes.string,
  link: PropTypes.bool,
  active: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'green', 'blue']),
};

export default WeTeamIcon;
