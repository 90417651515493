import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LazyWrapper from '../Loading/LazyWrapper';
import SpaceSidebarButtons from 'Views/SpaceHome/SpaceSidebarButtons';

import styles from './SpaceSidebar.module.scss';
import { SIDEBAR_ITEMS } from 'store/UI';

const NotesSidebar = React.lazy(() =>
  import(/* webpackChunkName: "NotesSidebar" */ 'Components/Notes/NotesSidebar')
);

const Files = React.lazy(() =>
  import(/* webpackChunkName: "Files" */ './Files')
);

const ChannelMembers = React.lazy(() =>
  import(/* webpackChunkName: "Members" */ './ChannelMembers/ChannelMembers')
);

const ChannelSearch = React.lazy(() =>
  import(/* webpackChunkName: "ChannelSearch" */ './Search/ChannelSearch')
);

const StarredMessages = React.lazy(() =>
  import(
    /* webpackChunkName: "StarredMessages" */ './StarredMessages/StarredMessages'
  )
);

const RemindersByWorkspace = React.lazy(() =>
  import(
    /* webpackChunkName: "RemindersByWorkspace" */ 'Components/Reminders/RemindersByWorkspace'
  )
);

class SpaceSidebar extends Component {
  render() {
    const { space, resourceId, activePanel } = this.props;
    // if the members panel is selected but they have switched to a direct chat,
    // we want to hide the members panel
    const showPanel =
      activePanel && !(space.IsChat && activePanel === SIDEBAR_ITEMS.members);

    return (
      <>
        {showPanel && (
          <div className={styles.sidebar}>
            <LazyWrapper hideLoader>
              {activePanel === SIDEBAR_ITEMS.files && (
                <Files spaceId={space.Id} resourceId={resourceId} />
              )}
              {activePanel === SIDEBAR_ITEMS.members && (
                <ChannelMembers spaceId={space.Id} />
              )}
              {activePanel === SIDEBAR_ITEMS.search && (
                <ChannelSearch spaceId={space.Id} />
              )}
              {activePanel === SIDEBAR_ITEMS.starredmessages && (
                <StarredMessages space={space} />
              )}
              {activePanel === SIDEBAR_ITEMS.reminders && (
                <RemindersByWorkspace workspaceId={space.WorkspaceId} />
              )}
              {activePanel === SIDEBAR_ITEMS.notes && (
                <NotesSidebar
                  channelId={space.Id}
                  workspaceId={space.WorkspaceId}
                />
              )}
            </LazyWrapper>
          </div>
        )}
        <SpaceSidebarButtons space={space} activePanel={activePanel} />
      </>
    );
  }
}

SpaceSidebar.propTypes = {
  space: PropTypes.object.isRequired,
  resourceId: PropTypes.string,
  activePanel: PropTypes.string,
};

export default SpaceSidebar;
