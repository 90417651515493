import axios from 'axios';
import { getMeetingUsersDeleteUrl } from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from '../../../config/config';

export function deleteUser(meetingId, userId) {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  return axios
    .delete(getMeetingUsersDeleteUrl(meetingId, userId), config)
    .then(response => response.data);
}
