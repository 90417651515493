import PropTypes from 'prop-types';
import React from 'react';
import ParticipantAvatar from 'Components/Meeting/ParticipantAvatar';
import { FormattedHTMLMessage } from 'react-intl';

import styles from './MeetingUserAvatars.module.css';

function MeetingUserAvatars({ meeting }) {
  return (
    <div className={styles.onTheCall}>
      <span className="flex-no-shrink">
        <FormattedHTMLMessage id="MeetingInvitation.Invited" />
      </span>
      <div className={styles.participants}>
        {meeting.users
          ?.filter(participant => participant.invite)
          .map(participant => (
            <ParticipantAvatar
              key={participant.userId}
              collaborator={participant}
            />
          ))}
      </div>
    </div>
  );
}

MeetingUserAvatars.propTypes = {
  meeting: PropTypes.shape({
    users: PropTypes.array.isRequired,
  }).isRequired,
};

export default MeetingUserAvatars;
