import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsMeetingHangUpRequested } from 'store/Meeting/selectors';
import { Popup, Icon } from 'semantic-ui-react';
import { meetingHangUpRequested, showMeeting } from '../../store/Meeting';
import HangUp from '../Icons/Meeting/HangUp';
import { FormattedMessage } from 'react-intl';

import styles from './MeetingToolbar.module.scss';
import MeetingIcon from 'Components/Meeting/MeetingIcon';
import classNames from 'classnames/bind';
import MeetingIconStatus from 'Components/Meeting/MeetingIconStatus';
import ManageParticipantsButton from 'Components/Meeting/ManageParticipants/ManageParticipantsButton';

export function MeetingToolbar({
  onShowHideMeetingClick,
  onEndMeetingClick,
  isMeetingHangUpRequested,
}) {
  // tell the meeting window to end the meeting
  const onEndClick = () => {
    onEndMeetingClick();
  };

  const onShowMeetingClick = () => {
    onShowHideMeetingClick();
  };

  return (
    <div className={styles.meetingToolbar}>
      <Popup
        inverted
        position="bottom center"
        trigger={
          <span className={classNames(styles.icon)}>
            <span
              data-testid="meeting-status-icon"
              className={styles.meetingStatusIcon}
            >
              <MeetingIcon />
            </span>
          </span>
        }
        content={<MeetingIconStatus />}
        size="mini"
      />
      <Popup
        inverted
        position="bottom center"
        trigger={
          <span
            role="button"
            className={classNames(styles.icon, styles.button, styles.red)}
            data-testid="hang-up-meeting-button"
            onClick={onEndClick}
          >
            <HangUp className={styles.hangUpIcon} width={24} height={24} />
          </span>
        }
        content={<FormattedMessage id="MeetingToolbar.EndMeeting" />}
        size="mini"
      />
      <Popup
        inverted
        position="bottom center"
        trigger={
          <span
            role="button"
            className={classNames(styles.icon, styles.button)}
            onClick={onShowMeetingClick}
            data-testid="show-hide-meeting-button"
          >
            <Icon
              data-testid="show-hide-meeting-icon"
              className={styles.showHideMeetingIcon}
              name="eye"
            />
          </span>
        }
        content={<FormattedMessage id="MeetingToolbar.ShowMeeting" />}
        size="mini"
      />
      <Popup
        inverted
        position="bottom center"
        trigger={
          <span
            role="button"
            className={classNames(styles.icon, styles.button, styles.green)}
            data-testid="meeting-participants-button"
          >
            <ManageParticipantsButton
              className={styles.hangUpIcon}
              width={24}
              height={24}
            />
          </span>
        }
        content={<FormattedMessage id="MeetingToolbar.ManageParticipants" />}
        size="mini"
      />
    </div>
  );
}

MeetingToolbar.propTypes = {
  onShowHideMeetingClick: PropTypes.func.isRequired,
  onEndMeetingClick: PropTypes.func.isRequired,
  isMeetingHangUpRequested: PropTypes.bool,
};

export default connect(
  state => ({
    isMeetingHangUpRequested: getIsMeetingHangUpRequested(state),
  }),
  {
    onShowHideMeetingClick: showMeeting,
    onEndMeetingClick: meetingHangUpRequested,
  }
)(MeetingToolbar);
