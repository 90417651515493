import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';

function getStyle(height) {
  return {
    height,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };
}

const PreviewError = ({ height, ...props }) => (
  <div className="preview-not-available" style={getStyle(height)} {...props}>
    <Message>
      <FormattedMessage id="PreviewError.Message" />
    </Message>
  </div>
);

PreviewError.propTypes = {
  height: PropTypes.number,
};

export default PreviewError;
