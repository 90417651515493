import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { useRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsPlaying,
  play,
  pauseRequested,
  getCurrentTrack,
  resumeRequested,
} from 'store/AudioPlayer';

import useMountedState from 'Hooks/useMountedState';

import { PlayCircleIcon, PauseCircleIcon } from 'Components/Icons/WeTeamIcon';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import AudioWave from 'Components/AudioWave/AudioWave';

import PreviewError from './PreviewError';

import './PreviewAudio.scss';

function PreviewAudio({ notificationId, channelId, id, src, error }) {
  const dispatch = useDispatch();
  const isPlaying = useSelector(getIsPlaying);
  const currentTrack = useSelector(getCurrentTrack);
  const [loaded, setLoaded] = useState(false);

  const isMounted = useMountedState();
  const audio = useRef(null);

  useEffect(() => {
    audio.current = new Audio(src);
    audio.current.onloadeddata = function (e) {
      setLoaded(true);
    };
    return () => {
      audio.current.src = null;
    };
  }, [isMounted, src]);

  const onPlay = useCallback(() => {
    if (currentTrack === src) {
      dispatch(resumeRequested(src));
    } else {
      dispatch(play(src, channelId, id, notificationId));
    }
  }, [currentTrack, src, dispatch, channelId, id, notificationId]);

  const onPause = useCallback(() => {
    dispatch(pauseRequested(src));
  }, [dispatch, src]);

  if (error) {
    return <PreviewError height={40} />;
  }

  if (!src || !loaded) {
    return (
      <div className="preview-audio placeholder">
        <Loader active />
      </div>
    );
  }

  return (
    <div className="preview-audio">
      {!(currentTrack === src && isPlaying) && (
        <WeTeamIcon onClick={onPlay} Icon={PlayCircleIcon} />
      )}
      {currentTrack === src && isPlaying && (
        <>
          <WeTeamIcon onClick={onPause} Icon={PauseCircleIcon} />
          <AudioWave />
        </>
      )}
    </div>
  );
}

PreviewAudio.propTypes = {
  notificationId: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  error: PropTypes.object,
};

export default PreviewAudio;
