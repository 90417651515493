import * as Sentry from '@sentry/react';
import { remove, saveOne } from 'DataLayer/database/comments';
import { ADD_COMMENT, REMOVE_COMMENT } from 'store/Comments/actions';

export const indexedDbCommentsMiddleware = store => next => action => {
  try {
    switch (action.type) {
      case ADD_COMMENT: {
        saveOne(action.newComment);
        break;
      }
      case REMOVE_COMMENT: {
        remove(action.commentId);
        break;
      }
      default:
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }

  return next(action);
};
