export default function (file, fileBlob) {
  const formData = new FormData();
  formData.append(
    'parameters',
    JSON.stringify({
      name: file.name,
      size: fileBlob.size,
    })
  );
  formData.append('file', fileBlob, file.name);
  return formData;
}
