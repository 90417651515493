export const USER_AVATAR_CHANGED = 'USER/AVATAR_CHANGED';
export const BANDWIDTH_CHANGED = 'USER/BANDWIDTH_CHANGED';

export const USER_GET_SUCCESS = 'USER/GET_SUCCESS';

export const userLoaded = user => ({
  type: USER_GET_SUCCESS,
  user,
});

export const userAvatarChanged = (email, avatarUrl, color) => (
  dispatch,
  getState
) => {
  dispatch({
    type: USER_AVATAR_CHANGED,
    email,
    avatarUrl,
    color,
  });
};

export const bandwidthChanged = (used, limit) => ({
  type: BANDWIDTH_CHANGED,
  used,
  limit,
});
