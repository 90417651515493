import format from 'date-fns/format';
import { createComment } from 'DataLayer/Spaces/Comments/create';

import { displayErrorToast } from '../Toast/displayToast';

import generator from '../../Helpers/Strings/generator';

import { getUser } from '../User/selectors';
import { getSpaceById } from '../Spaces/selectors';
import notificationType from '../../Constants/notificationType';
import { addTemporaryNotification } from '../Notifications/actions';
import {
  getNotificationByCommentId,
  getNotificationById,
} from '../Notifications/selectors';

import { buildReplyText, getReplyInfo } from './utils/reply';
import {
  chatMessageRepliedAnalyticsEvent,
  messageSentAnalyticsEvent,
} from 'Components/GoogleAnalytics/events';

/*
  If we are replying to a reply, extract the reply, because we don't want
  the original message appearing in the 
*/
export const getReplyText = (
  replyingToNotificationText,
  replyingToName,
  replyText
) => {
  try {
    const replyInfo = getReplyInfo(replyingToNotificationText);
    const textBeingRepliedTo = replyInfo
      ? replyInfo.reply
      : replyingToNotificationText;

    const personWeAreReplyingTo = replyingToName;
    return buildReplyText(personWeAreReplyingTo, textBeingRepliedTo, replyText);
  } catch (error) {
    return replyText;
  }
};

export const addSpaceComment =
  (spaceId, resourceId, text, replyId) => async (dispatch, getState) => {
    const state = getState();
    const user = getUser(state);
    const space = getSpaceById(state, spaceId);

    const replyingToNotification = getNotificationById(state, replyId);

    const finalText =
      replyId && replyingToNotification
        ? getReplyText(
            replyingToNotification.Comment?.Text,
            `${replyingToNotification.CreatedBy?.FirstName} ${replyingToNotification.CreatedBy?.LastName}`,
            text
          )
        : text;

    return createComment(spaceId, null, finalText).then(
      comment => {
        if (replyId) {
          chatMessageRepliedAnalyticsEvent();
        }
        messageSentAnalyticsEvent();
        // Check if this comment has already been added to the state via the web socket.
        const newState = getState();
        if (getNotificationByCommentId(newState, comment.CommentId)) {
          return;
        }

        // TODO: This is bloody madness. We need to get this object back from the server!
        const notification = {
          User: user.UserID,
          NotificationReadState: true,
          Id: generator(10),
          NotificationTimestamp: comment.CommentTimestamp,
          Type: notificationType.ChannelComment,
          Workspace: {
            Id: spaceId,
            Name: space.Name,
          },
          Comment: {
            CommentId: comment.CommentId,
            Text: finalText,
            Unread: false,
            CreatedAt: comment.CommentTimestamp,
            CreatedBy: {
              Email: user.UserID,
              FirstName: user.FirstName,
              LastName: user.LastName,
            },
          },
          CreatedBy: {
            UserID: user.UserID,
            FirstName: user.FirstName,
            LastName: user.LastName,
          },
          Day: format(comment.CommentTimestamp, 'YYYY-MM-DD'),
          Temporary: true,
        };

        dispatch(addTemporaryNotification(notification));
      },
      error => {
        dispatch(displayErrorToast('toast.addComment.error'));
        throw error;
      }
    );
  };

export default addSpaceComment;
