import React from 'react';
import PropTypes from 'prop-types';

import ImageLoader from 'Components/Avatar/ImageLoader';

import SingleSpaceIcon from './SingleSpaceIcon';

import styles from './SpaceIcon.module.scss';

export class SpaceIcon extends React.Component {
  render() {
    const { space, width, height, noRightMargin, showStatus } = this.props;
    if (space.IsChat) {
      return (
        <SingleSpaceIcon
          showStatus={showStatus}
          spaceId={space.Id}
          width={width}
          height={height}
        />
      );
    } else {
      return (
        <div
          style={{
            height,
            width,
            minWidth: width,
            marginRight: noRightMargin ? 0 : 10,
          }}
          className={styles.channelIcon}
        >
          <ImageLoader src={space.AvatarUrl}>
            {(loading, error, loaded) => {
              if (loaded) {
                return (
                  <img
                    style={{
                      height: height * 0.6,
                    }}
                    src={space.AvatarUrl}
                    alt={space.Name}
                  />
                );
              }
              return null;
            }}
          </ImageLoader>
        </div>
      );
    }
  }
}
SpaceIcon.propTypes = {
  space: PropTypes.shape({
    Color: PropTypes.string,
  }).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  noRightMargin: PropTypes.bool,
  showStatus: PropTypes.bool,
};

SpaceIcon.defaultProps = {
  width: 40,
  height: 40,
  showStatus: true,
};

export default SpaceIcon;
