import React from 'react';
import PropTypes from 'prop-types';

import RightSidebarPanelDivider from './RightSidebarPanelDivider';
import RightSidebarPanelContent from './RightSidebarPanelContent';
import RightSidebarPanelFilter from './RightSidebarPanelFilter';
import RightSidebarPanelTitle from './RightSidebarPanelTitle';
import RightSidebarButton from './RightSidebarButton';

import './RightSidebar.scss';

const RightSidebar = ({ children }) => (
  <div className="flex column right-sidebar">{children}</div>
);

RightSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

RightSidebar.Button = RightSidebarButton;
RightSidebar.Title = RightSidebarPanelTitle;
RightSidebar.Filter = RightSidebarPanelFilter;
RightSidebar.Content = RightSidebarPanelContent;
RightSidebar.Divider = RightSidebarPanelDivider;

export default RightSidebar;
