import axios from 'axios';
import { REMINDERS } from 'DataLayer/endpoints';

export const REMINDER_TYPE = {
  notification: 'notification',
  resource: 'resource',
  linkedItem: 'linkedItem',
  comment: 'comment',
};

export function getReminders() {
  return axios.get(REMINDERS).then(response => response.data || []);
}
