import { connect } from 'react-redux';
import { makeGetMeetingBeaconByChannelId } from '../../store/Meeting/selectors';
import MeetingBeacon from './MeetingBeacon';

export default connect(() => {
  const getMeetingBeaconByChannelId = makeGetMeetingBeaconByChannelId();
  return (state, props) => {
    return {
      meetingBeaconType: getMeetingBeaconByChannelId(state, props.channelId),
    };
  };
})(MeetingBeacon);
