import classNames from 'classnames/bind';
import styles from './IconButtonGroup.module.scss';

const IconButtonGroup = ({
  children,
  className,
  showBorder = true,
  ...props
}) => (
  <div
    className={classNames(styles.iconButtonGroup, className, {
      [styles.border]: showBorder,
    })}
    {...props}
  >
    {children}
  </div>
);

export default IconButtonGroup;
