import { unarchive } from 'DataLayer/Spaces/update';
import { setSpaceRequestInProgress } from '../Requests/setRequestInProgress';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

import * as spaceActions from './actions';

const unarchiveSpace = space => dispatch => {
  dispatch(setSpaceRequestInProgress(space.Id, true));
  unarchive(space.Id).then(
    () => {
      dispatch(spaceActions.archiveSpace(space.Id, false));
      dispatch(setSpaceRequestInProgress(space.Id, false));
      dispatch(
        displaySuccessToast(
          space.IsChat || space.IsConversation
            ? 'toast.unarchiveChat.success'
            : 'toast.unarchiveSpace.success'
        )
      );
    },
    () => {
      dispatch(setSpaceRequestInProgress(space.Id, false));
      dispatch(displayErrorToast('toast.unarchiveSpace.error'));
    }
  );
};

export default unarchiveSpace;
