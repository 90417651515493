import { SET_LOCALE } from './setLocale';

const initialState = {
  locale: '', // this should be blank to start, so when it is changed, IntlProvider gets a new key, and re-renders
  messages: {},
};

export default function localeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return Object.assign({}, state, {
        locale: action.locale,
        messages: action.messages,
      });
    default:
      return state;
  }
}
