import axios from 'axios';
import { SEARCH } from 'DataLayer/endpoints';
import { searchFields } from './fields';
import buildSearchQuery from './buildSearchQuery';
import process from './process';

export function search(query, type, date, size, resourceId, linked) {
  const searchQuery = buildSearchQuery(
    query,
    type,
    date,
    size,
    resourceId,
    linked
  );
  return axios
    .get(`${SEARCH}?${searchQuery}&fields=${searchFields}`)
    .then(response => response.data)
    .then(result => ({
      resources: process.all(result.Items),
    }));
}

export function searchShared() {
  return axios
    .get(`${SEARCH}?linked=true&fields=${searchFields}`)
    .then(response => response.data)
    .then(result => ({
      resources: process.all(result.Items),
    }));
}
