import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

async function clearRemovedWorkspaces(networkWorkspaces) {
  const databaseWorkspaces = await get();

  if (!databaseWorkspaces) {
    return null;
  }

  const networkWorkspaceIds = networkWorkspaces.map(workspace => workspace.Id);

  // check db workspace is inside response workspaces
  for (const databaseWorkspace of databaseWorkspaces) {
    if (!networkWorkspaceIds.includes(databaseWorkspace.Id)) {
      await remove(databaseWorkspace.Id);
    }
  }
}

export async function saveMany(networkWorkspaces) {
  try {
    await clearRemovedWorkspaces(networkWorkspaces);

    await commonDb.workspaces.bulkPut(
      networkWorkspaces.map(workspace => ({
        workspaceId: workspace.Id,
        workspace,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function saveOne(workspace) {
  try {
    await commonDb.workspaces.put({
      workspaceId: workspace.Id,
      workspace,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.workspaces.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function removeAllButOne(id) {
  try {
    await commonDb.workspaces.where('workspaceId').notEqual(id).delete();
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function get() {
  try {
    const workspaces = await commonDb.workspaces.toArray();
    if (Array.isArray(workspaces) && workspaces.length > 0) {
      return workspaces.map(workspace => workspace.workspace);
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
