import axios from 'axios';
import { getUnverifiedUserRequestAllowedConfig } from 'DataLayer/endpoints';
import { getMaintenanceModeUrl } from 'config/config';

export function get() {
  const config = getUnverifiedUserRequestAllowedConfig();

  return axios.get(getMaintenanceModeUrl(), config).then(response => {
    return response.status === 200 && response.data === 'ON';
  });
}
