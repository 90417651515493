import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(comment) {
  try {
    await commonDb.comments.put({
      commentId: comment.Id,
      resourceId: comment.ResourceId,
      comment,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function clearRemovedComments(networkComments, resourceId) {
  const databaseComments = await getCommentsByResourceId(resourceId);

  if (!databaseComments) {
    return null;
  }

  const networkCommentIds = networkComments.map(comment => comment.Id);

  // check db comment is inside response Comments
  for (const databaseComment of databaseComments) {
    if (!networkCommentIds.includes(databaseComment.Id)) {
      await remove(databaseComment.Id);
    }
  }
}

export async function saveMany(networkComments, resourceId) {
  try {
    await clearRemovedComments(networkComments, resourceId);

    await commonDb.comments.bulkPut(
      networkComments.map(comment => ({
        commentId: comment.Id,
        resourceId: comment.ResourceId,
        comment,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.comments.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function getCommentsByResourceId(resourceId) {
  try {
    const comments = await commonDb.comments
      .where('resourceId')
      .equals(resourceId)
      .toArray();
    if (Array.isArray(comments) && comments.length > 0) {
      return comments.map(comment => comment.comment);
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
