import { get as getMeetings } from 'DataLayer/Meeting/get';
import {
  meetingInvitationReceived,
  activeMeetingUpdated,
  activeMeetingEnded,
} from '.';
import { getActiveMeetingsObject } from './selectors';

const loadMeetings = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const meetings = await getMeetings();
    const activeMeetingsInApplication = getActiveMeetingsObject(state);
    for (const activeMeetingInApplicationRoomId in activeMeetingsInApplication) {
      /*
        if the meeting is no longer running, remove it from our application state
      */
      if (
        !meetings.activeMeetings.find(
          activeMeeting =>
            activeMeeting.roomId === activeMeetingInApplicationRoomId
        )
      ) {
        dispatch(activeMeetingEnded(activeMeetingInApplicationRoomId));
      }
    }

    for (const meeting of meetings.meetingInvitations) {
      dispatch(meetingInvitationReceived(meeting));
    }

    for (const meeting of meetings.activeMeetings) {
      dispatch(activeMeetingUpdated(meeting));
    }
  } catch (ex) {
    throw ex;
  }
};

export default loadMeetings;
