export const SPACE_ADD = 'SPACE_ADD';
export const TEAM_SPACE_ADD = 'TEAM_SPACE_ADD';
//export const SINGLE_SPACE_ADD = 'SINGLE_SPACE_ADD';
export const SPACE_DELETE = 'SPACE_DELETE';
export const SPACE_ARCHIVE = 'SPACE_ARCHIVE';
export const SPACE_UNARCHIVE = 'SPACE_UNARCHIVE';
export const SPACE_RENAME = 'SPACE_RENAME';
export const CHANNEL_EXPORT = 'CHANNEL_EXPORT';
export const SPACE_CHANGE_AVATAR = 'SPACE_CHANGE_AVATAR';
export const SPACE_LEAVE = 'SPACE_LEAVE';
export const PRIVATECHAT_LEAVE = 'PRIVATECHAT_LEAVE';
export const SPACE_EVENTS = 'SPACE_EVENTS';

export const WORKSPACE_ADD = 'WORKSPACE_ADD';
export const WORKSPACE_LEAVE = 'WORKSPACE_LEAVE';
export const WORKSPACE_EXPORT_STARTED = 'WORKSPACE_EXPORT_STARTED';

//export const ADD_MEMBER_TO_SPACE = 'ADD_PERSON_TO_SPACE';

export const WORKSPACE_MEMBER_ADD = 'WORKSPACE_MEMBER_ADD';
export const COLLABORATOR_REMOVE = 'COLLABORATOR_REMOVE';
export const MEMBER_CHANGE_TO_SINGLE_GUEST = 'MEMBER_CHANGE_TO_SINGLE_GUEST';
export const MEMBER_CHANGE_TO_MEMBER = 'MEMBER_CHANGE_TO_MEMBER';

export const MEMBER_CHANGE_TO_SINGLE_GUEST_ERROR =
  'COLLABORATOR_CHANGE_TO_SINGLE_GUEST_ERROR';

export const WORKSPACE_MEMBER_REMOVE = 'MEMBER_REMOVE';

export const REPOSITORY_DELETE = 'REPOSITORY_DELETE';
export const REPOSITORY_RENAME = 'REPOSITORY_RENAME';
export const REPOSITORY_EDIT = 'REPOSITORY_EDIT';
export const REPOSITORY_RECONNECT = 'REPOSITORY_RECONNECT';

export const TRANSFER_LINKEDITEM_SELECT_TARGET =
  'TRANSFER_LINKEDITEM_SELECT_TARGET';
export const LINKEDITEM_TRANSFER = 'LINKEDITEM_TRANSFER';
export const LINKEDITEM_DELETE = 'LINKEDITEM_DELETE';
export const LINKEDITEM_RENAME = 'LINKEDITEM_RENAME';

export const RESOURCE_DELETE = 'RESOURCE_DELETE';
export const RESOURCE_RENAME = 'RESOURCE_RENAME';
export const RESOURCE_ADD_FOLDER = 'RESOURCE_ADD_FOLDER';
export const RESOURCE_LINK = 'RESOURCE_LINK';
export const RESOURCE_LINK_REMOVE = 'RESOURCE_LINK_REMOVE';
export const RESOURCE_LINK_SETTINGS = 'RESOURCE_LINK_SETTINGS';
export const RESOURCE_UPLOAD_PROGRESS = 'RESOURCE_UPLOAD_PROGRESS';
export const SHARE_LINKED_ITEMS_PROGRESS = 'SHARE_LINKED_ITEMPROGRESS';
export const RESOURCE_UPLOAD_FILES = 'RESOURCE_UPLOAD_FILES';

export const TRANSFERS_PROGRESS = 'TRANSFERS_PROGRESS';

export const CHAT_PREVIEW = 'CHAT_PREVIEW';
export const SINGLE_RESOURCE_PREVIEW = 'SINGLE_RESOURCE_PREVIEW';
export const RESOURCES_PREVIEW = 'RESOURCES_PREVIEW';
export const LINKED_ITEM_PREVIEW = 'LINKED_ITEM_PREVIEW';

export const COPY = 'COPY';
export const TRANSFER_TO_OTIXO_DRIVE = 'TRANSFER_TO_OTIXO_DRIVE';

export const UPGRADE = 'UPGRADE';
export const WORKSPACE_UPGRADE = 'WORKSPACE_UPGRADE';
export const PLAN_CHANGE_NOT_ALLOWED = 'PLAN_CHANGE_NOT_ALLOWED';

export const BANDWIDTH_EXCEEDED = 'BANDWIDTH_EXCEEDED';
//export const BUY_BANDWIDTH = 'BUY_BANDWIDTH';
//export const BUY_BANDWIDTH_COMPLETE = 'BUY_BANDWIDTH_COMPLETE';
export const GET_TRANSFER_SUBSCRIPTION = 'GET_TRANSFER_SUBSCRIPTION';

export const NO_QUOTA = 'NO_QUOTA';
export const NO_SPACE_QUOTA = 'NO_SPACE_QUOTA';
export const PUBLIC_LINK_SIZE_RESTRICTED = 'PUBLIC_LINK_SIZE_RESTRICTED';
export const APPS_EXCEEDED = 'APPS_EXCEEDED';
export const ADAPTER_RETIRED = 'ADAPTER_RETIRED';
export const BANDWIDTH_INFORMATION = 'BANDWIDTH_INFORMATION';
export const SHARE_FOLDER_INFORMATION = 'SHARE_FOLDER_INFORMATION';
export const SHARE_FILES_AND_MESSAGES_WITH_CHANNEL =
  'SHARE_FILES_AND_MESSAGES_WITH_CHANNEL';

export const CHAT_MIGRATION = 'CHAT_MIGRATION';

// export const SWITCH_TO_FREE_ACCOUNT_RESTRICTED = 'SWITCH_TO_FREE_ACCOUNT_RESTRICTED';
/*
export const CLOUD_SEARCH_RESTRICTED = 'CLOUD_SEARCH_RESTRICTED';
export const LINKED_ITEM_ACCESS_RIGHTS_RESTRICTED = 'LINKED_ITEM_ACCESS_RIGHTS_RESTRICTED';
export const EXPORT_CHAT_HISTORY_RESTRICTED = 'EXPORT_CHAT_HISTORY_RESTRICTED';
export const CLOUD_UPLOAD_RESTRICTED = 'CLOUD_UPLOAD_RESTRICTED';
e xport const CLOUD_DOWNLOAD_RESTRICTED = 'CLOUD_DOWNLOAD_RESTRICTED';
*/
export const CLOUD_COPY_RESTRICTED = 'CLOUD_COPY_RESTRICTED';

export const ADAPTERS = 'ADAPTERS';
export const ADAPTER = 'ADAPTER';

export const ALERT_RECONNECT = 'ALERT/RECONNECT';

export const DECRYPT_AND_DOWNLOAD = 'DECRYPT_AND_DOWNLOAD';
export const PASSWORD_FOR_DECRYPT = 'PASSWORD_FOR_DECRYPT';

export const DECRYPT_AND_DOWNLOAD_PUBLIC = 'DECRYPT_AND_DOWNLOAD_PUBLIC';
export const PASSWORD_FOR_DECRYPT_PUBLIC = 'PASSWORD_FOR_DECRYPT_PUBLIC';

export const ADD_ITEM_TO_SPACE = 'SPACE/ADD_ITEM_TO_SPACE';

export const ONBOARDING_LEARNMORE_DRIVES = 'ONBOARDING/LEARNMORE/DRIVES';
export const ONBOARDING_LEARNMORE_OTIXO_SPACE =
  'ONBOARDING/LEARNMORE/OTIXO_SPACE';
export const ONBOARDING_LEARNMORE_OTIXO_DRIVE =
  'ONBOARDING/LEARNMORE/OTIXO_DRIVE';
export const ONBOARDING_LEARNMORE_SPACES = 'ONBOARDING/LEARNMORE/SPACES';
export const ONBOARDING_LEARNMORE_SINGLESPACES =
  'ONBOARDING/LEARNMORE/SINGLESPACES';

export const ONBOARDING_LEARNMORE_DRIVE_CHANNELS =
  'ONBOARDING/LEARNMORE/DRIVE_CHANNELS';
export const ONBOARDING_LEARNMORE_TEAM_CHANNELS =
  'ONBOARDING/LEARNMORE/TEAM_CHANNELS';
export const ONBOARDING_LEARNMORE_SINGLE_CHANNELS =
  'ONBOARDING/LEARNMORE/SINGLE_CHANNELS';

export const ONBOARDING_LEARNMORE_TEAM_CHANNEL =
  'ONBOARDING/LEARNMORE/TEAM_CHANNEL';
export const ONBOARDING_LEARNMORE_SINGLE_CHANNEL =
  'ONBOARDING/LEARNMORE/SINGLE_CHANNEL';
export const ONBOARDING_LEARNMORE_COMMENTS = 'ONBOARDING/LEARNMORE/COMMENTS';

/*
export const ACCOUNTS_ADD = 'ACCOUNTS_ADD';
export const ACCOUNTS_DISCONNECT = 'ACCOUNTS_DISCONNECT';
export const ACCOUNTS_RENAME = 'ACCOUNTS_RENAME';
export const ACCOUNTS_CHANGE_AVATAR = 'ACCOUNTS_CHANGE_AVATAR';
export const ACCOUNTS_VIEW_MEMBERS = 'ACCOUNTS_VIEW_MEMBERS';
*/

export const ADD_SPACE_FOLDER = 'ADD_SPACE_FOLDER';

export const SPACE_CHAT_DELETE_MESSAGE = 'SPACE_CHAT_DELETE_MESSAGE';

export const FILE_COMMENTS_DELETE = 'FILE_COMMENTS_DELETE';

export const CONVERSATION_FEATURE_RESTRICTED =
  'CONVERSATION_FEATURE_RESTRICTED';

export const SAVE_REMINDER = 'SAVE_REMINDER';

export const ADD_REMINDER = 'ADD_REMINDER';

export const SNOOZE_REMINDER = 'SNOOZE_REMINDER';

export const ADD_MEMBERS_TO_FIRST_WORKSPACE = 'ADD_MEMBERS_TO_FIRST_WORKSPACE';
export const ADD_FIRST_CHANNEL_MODEL = 'ADD_FIRST_CHANNEL_MODEL';

export const PURCHASE_WORKSPACE_PLAN = 'PURCHASE_WORKSPACE_PLAN';

export const PURCHASE_WORKSPACE_PLAN_COMPLETE =
  'PURCHASE_WORKSPACE_PLAN_COMPLETE';

export const MEETINGS_RESTRICTED = 'MEETINGS_RESTRICTED';

export const WORKSPACE_FOLDER_UPLOAD_UNAVAILABLE =
  'WORKSPACE_FOLDER_UPLOAD_UNAVAILABLE';

export const SELECT_MEETING_USERS = 'SELECT_MEETING_USERS';

export const EDUCATION_APPLICATION = 'EDUCATION_APPLICATION';
export const DESKTOP_APP_REVIEW = 'DESKTOP_APP_REVIEW';
export const CONGRATULATIONS_MODAL = 'CONGRATULATIONS_MODAL';
export const REMOVE_NOTE_MODAL = 'REMOVE_NOTE_MODAL';
export const SUGGESTIONS_MODAL = 'SUGGESTIONS_MODAL';

export const SPRING_2024_PROMOTION_ACCEPTED = 'SPRING_2024_PROMOTION_ACCEPTED';
export const SPRING_2024_PROMOTION_MILESTONES =
  'SPRING_2024_PROMOTION_MILESTONES';
export const DOWNLOAD_DESKTOP_OFFER = 'DOWNLOAD_DESKTOP_OFFER';
export const APP_FEEDBACK_OFFER = 'APP_FEEDBACK_OFFER';
export const APP_FEEDBACK_OFFER_ACCEPTED = 'APP_FEEDBACK_OFFER_ACCEPTED';

export const CREATE_TAG = 'CREATE_TAG';
export const MANAGE_TAGS = 'MANAGE_TAGS';
export const DELETE_TAG = 'DELETE_TAG';
