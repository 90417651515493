import axios from 'axios';
import { WORKSPACE, COLLABORATORS } from 'DataLayer/endpoints';

export function update(spaceId, memberId, type, channelId) {
  return axios
    .put(`${WORKSPACE}/${spaceId}/${COLLABORATORS}`, {
      Type: type,
      CollabId: memberId,
      ChannelId: channelId,
    })
    .then(response => response.data);
}

export function changeStatus(workspaceId, memberId, status, message) {
  return axios
    .put(`${WORKSPACE}/${workspaceId}/${COLLABORATORS}`, {
      Status: status,
      CollabId: memberId,
      Message: message,
    })
    .then(response => response.data);
}

export function join(id) {
  return axios.put(`${WORKSPACE}/${id}/${COLLABORATORS}?status=joined`);
}

export function leave(id, exportHistory) {
  const exportHistoryFlag = exportHistory ? 'exportHistory' : '';
  return axios
    .put(`${WORKSPACE}/${id}/${COLLABORATORS}?status=left&${exportHistoryFlag}`)
    .then(response => response.data.PDFExport);
}
