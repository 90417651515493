import React from 'react';
import classNames from 'classnames/bind';

import styles from './DisableInBackupMode.module.css';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

function DisableInBackupMode({ children, disableBlur }) {
  const backupModeActive = useIsBackupMode();

  return React.cloneElement(children, {
    className: classNames(children.props.className, {
      [styles.disable]: backupModeActive,
      [styles.blur]: backupModeActive && !disableBlur,
    }),
  });
}

export default DisableInBackupMode;
