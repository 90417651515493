import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

export default function LazyWrapper({ children, fallback, hideLoader }) {
  const localFallback = fallback || <Loader active={!hideLoader} />;
  return (
    <ErrorBoundary>
      <Suspense fallback={localFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}

LazyWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  hideLoader: PropTypes.bool,
};
