import PropTypes from 'prop-types';
import React from 'react';
import Avatar from './Avatar';
import CollaboratorCardPopup from './CollaboratorCardPopup';
import { injectIntl, intlShape } from 'react-intl';
import { Popup } from 'semantic-ui-react';

function CollaboratorAvatarWithCard({
  collaborator,
  fallbackName,
  width,
  height,
  showStatus,
  showNameAsTooltip = false,
  title,
  directChatId,
  onAddSpaceComment,
  intl,
}) {
  if (!collaborator.Email) {
    return (
      <Avatar
        disabled
        email={fallbackName}
        width={width}
        height={height}
        title={intl.formatMessage({
          id: 'CollaboratorAvatarWithCard.user-no-longer-member',
        })}
      />
    );
  }

  return (
    <CollaboratorCardPopup
      collaborator={collaborator}
      directChatId={directChatId}
      onAddSpaceComment={onAddSpaceComment}
    >
      <Popup
        inverted
        position="right center"
        disabled={!showNameAsTooltip}
        trigger={
          <Avatar
            avatarUrl={collaborator.AvatarUrl}
            color={collaborator.Color}
            email={collaborator.Email}
            firstName={collaborator.FirstName}
            lastName={collaborator.LastName}
            presence={collaborator.Presence}
            width={width}
            height={height}
            showStatus={showStatus}
            title={title}
          />
        }
        content={`${collaborator.FirstName} ${collaborator.LastName}`}
        size="mini"
      />
    </CollaboratorCardPopup>
  );
}

CollaboratorAvatarWithCard.propTypes = {
  collaborator: PropTypes.object,
  fallbackName: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  showStatus: PropTypes.bool,
  showNameAsTooltip: PropTypes.bool,
  title: PropTypes.string,
  directChatId: PropTypes.string,
  onAddSpaceComment: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(CollaboratorAvatarWithCard);
