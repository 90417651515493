import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { applicationInfoReceived } from 'store/Application/index';

export const PLATFORM_INFORMATION = 'platform-information';

export class ElectronPlatformListener extends React.PureComponent {
  componentDidMount() {
    window.ipcRenderer?.on(
      PLATFORM_INFORMATION,
      this.onPlatformInformationReceived
    );
  }

  componentWillUnmount() {
    window.ipcRenderer?.removeListener(
      PLATFORM_INFORMATION,
      this.onPlatformInformationReceived
    );
  }

  onPlatformInformationReceived = (event, { isAppStore, platform }) => {
    console.debug(PLATFORM_INFORMATION, event, isAppStore, platform);
    this.props.onApplicationInfoReceived(isAppStore, platform);
  };

  render() {
    return null;
  }
}

ElectronPlatformListener.propTypes = {
  onApplicationInfoReceived: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onApplicationInfoReceived: applicationInfoReceived,
})(ElectronPlatformListener);
