import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { getWorkspaceUpgradeLink } from 'pages';
import { Button } from 'semantic-ui-react';
import { getWorkspaceById, isWorkspaceFree } from 'store/Workspaces/selectors';

import styles from './UpgradeButton.module.scss';

const UpgradeButton = ({ workspace, shouldShowButton }) => {
  if (!workspace.IsOwner) {
    return null;
  }

  if (!shouldShowButton) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles.upgradeButton}
        fluid
        primary
        as={Link}
        to={getWorkspaceUpgradeLink(workspace.Id)}
      >
        <FormattedMessage id="UpgradeButton.UpgradeText" />
      </Button>
    </div>
  );
};

UpgradeButton.propTypes = {
  workspace: PropTypes.object,
  shouldShowButton: PropTypes.bool.isRequired,
};

export default connect((state, props) => ({
  workspace: getWorkspaceById(state, props.workspaceId),
  shouldShowButton: isWorkspaceFree(state, props.workspaceId),
}))(UpgradeButton);
