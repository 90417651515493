import React from 'react';
import { Portal } from 'semantic-ui-react';

import './ActionableNotifications.scss';

import PendingWorkspaceMembers from './PendingWorkspaceMembers/PendingWorkspaceMembers';
import WorkspaceInvitations from './WorkspaceInvitations/WorkspaceInvitations';
import RecentlyAddedWorkspacesContainer from './RecentlyAddedWorkspaces/RecentlyAddedWorkspacesContainer';
import PendingPrivateChats from './PendingPrivateChats/PendingPrivateChats';

const ActionableNotifications = () => (
  <Portal open>
    <div className="invitations-container">
      <RecentlyAddedWorkspacesContainer />
      <WorkspaceInvitations />
      <PendingWorkspaceMembers />
      <PendingPrivateChats />
    </div>
  </Portal>
);

export default ActionableNotifications;
