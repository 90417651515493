import process from '../../DataLayer/Spaces/Comments/process';

export const ADD_COMMENT = 'COMMENT/ADD';
export const REMOVE_COMMENT = 'COMMENT/REMOVE';
export const EDIT_COMMENT = 'COMMENT/EDIT';

export function addComment(
  spaceId,
  resourceId,
  newComment,
  isUserComment = true
) {
  return {
    type: ADD_COMMENT,
    newComment: process.single(newComment, spaceId, resourceId),
    spaceId,
    resourceId,
    isUserComment,
  };
}

export function removeComment(spaceId, resourceId, commentId) {
  return {
    type: REMOVE_COMMENT,
    spaceId,
    resourceId,
    commentId,
  };
}

export function editComment(commentId, value) {
  return {
    type: EDIT_COMMENT,
    commentId,
    value,
  };
}
