import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import MODAL_COMPONENTS from './allModals';

import { getModals } from '../../store/Modals/selectors';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

const ModalRoot = ({ modals }) => {
  if (!modals.length) {
    return null;
  }
  const allModals = modals.map(m => {
    const SpecificModal = MODAL_COMPONENTS[m.type];
    return (
      <ErrorBoundary key={m.type}>
        <SpecificModal key={m.type} {...m.props} />
      </ErrorBoundary>
    );
  });
  return (
    <Suspense
      fallback={
        <Dimmer page active>
          <div className="flex justify-content-center">
            <Loader active />
          </div>
        </Dimmer>
      }
    >
      <span>{allModals}</span>
    </Suspense>
  );
};

ModalRoot.propTypes = {
  modals: PropTypes.array.isRequired,
};

export default connect(state => ({
  modals: getModals(state),
}))(ModalRoot);
