import canSourceItemsBeCopiedToTarget from '../../Components/Modals/Copy/canSourceItemsBeCopiedToTarget';

import { getResourceById } from '../Resources/selectors';

export const TARGET_SELECTED = 'COPY/TARGET_SELECTED';

export const selectTarget = (targetId, canCreateFile, canCreateFolder) => (
  dispatch,
  getState
) => {
  const state = getState();

  const targetResource = getResourceById(state, targetId);

  const copyAllowed = canSourceItemsBeCopiedToTarget(
    state.copy.sourceItems,
    state.copy.withDelete,
    targetId,
    canCreateFile,
    canCreateFolder,
    targetResource.SpaceFolder
  );

  dispatch({
    type: TARGET_SELECTED,
    isTargetRestricted: !copyAllowed,
    targetId,
  });
};

export default selectTarget;
