import axios from 'axios';
import { getShareToGroupPutPrivilegeUrl } from 'DataLayer/endpoints';
import process from './process';
import {
  getShareToGroupPutNameUrl,
  getShareToGroupPutStarredUrl,
} from '../endpoints';
import { saveOne } from 'DataLayer/database/linkedItems';

export const SHARE_TO_GROUP_PRIVILEGE = {
  READ: 'read',
  WRITE: 'write',
};

export function lock(spaceId, resourceId, locked) {
  const privilege = locked
    ? SHARE_TO_GROUP_PRIVILEGE.WRITE
    : SHARE_TO_GROUP_PRIVILEGE.READ;
  return axios
    .put(getShareToGroupPutPrivilegeUrl(spaceId, resourceId, privilege))
    .then(result => result.data.Items[0])
    .then(linkedItem => process.single(linkedItem));
}

export async function rename(spaceId, resourceId, name) {
  const { data } = await axios.put(
    getShareToGroupPutNameUrl(spaceId, resourceId, name)
  );
  process.single(saveOne(data));
  return data;
}

export function star(spaceId, resourceId, starred) {
  return axios
    .put(getShareToGroupPutStarredUrl(spaceId, resourceId, starred))
    .then(response => {
      return response.data.Items[0];
    });
}
