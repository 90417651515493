import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Beacon.module.scss';
import classNamesBind from 'classnames/bind';

function Beacon({ className, width = 20, height = 20, active, ...props }) {
  const style = useMemo(() => ({ width, height }), [width, height]);
  return (
    <div
      className={classNamesBind(className, styles.beacon, {
        [styles.active]: active,
      })}
      style={style}
      data-testid="beacon"
      {...props}
    />
  );
}

Beacon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Beacon);
