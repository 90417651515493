import axios from 'axios';
import { single } from './process';
import { MEDIA_INFO } from '../../DataLayer/endpoints';
import {
  get as getFromDb,
  saveOne as saveToDb,
} from 'DataLayer/database/mediaInfo';
import { FEATURES } from 'config/features.config';
import { isFeatureEnabled } from 'config/features';

export async function getFromCache(id, workspaceId) {
  if (isFeatureEnabled(FEATURES.FILE_CACHING, workspaceId)) {
    return await getFromDb(id);
  }
  return Promise.resolve(null);
}

export async function getFromNetwork(id, spaceId) {
  const { data } = await axios.get(MEDIA_INFO, {
    params: {
      id,
      spaceId,
      force: true,
    },
  });
  const result = single(data.MediaInfos[0], spaceId);
  saveToDb(id, result);
  return result;
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function get(id, spaceId, workspaceId) {
  const cachedMediaInfo = await getFromCache(id, workspaceId);
  const fetchedPromise = getFromNetwork(id, spaceId);
  return { cachedMediaInfo, fetchedPromise };
}
