import PropTypes from 'prop-types';
import React from 'react';
import { Button, Header, Modal, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { OFFERS } from 'DataLayer/Workspaces/Offers/config';
import { showCongratulationsModal } from 'store/Modals/actions';
import { isDesktopApp } from 'config/config';
import { useAcceptOffer } from 'Components/WorkspaceOffer/useAcceptOffer';

function ThreeMonthTeamspaceUpgradePromotionModal({ workspaceId }) {
  const dispatch = useDispatch();

  const { postpone, accept, error, loading, visible, isAutoAccepted } =
    useAcceptOffer(workspaceId, OFFERS.free_trial_aug_2023, () => {
      dispatch(showCongratulationsModal());
    });

  if (isAutoAccepted) {
    return null;
  }

  return (
    <Modal size="tiny" open={visible}>
      <Header>
        <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={postpone} />
        </div>
      </Header>
      <Modal.Content>
        <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Message.1" />
        <ul>
          <li>
            <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Message.Point1" />
          </li>
          <li>
            <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Message.Point2" />
          </li>
          <li>
            <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Message.Point3" />
          </li>
        </ul>
        {isDesktopApp() ? (
          <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Message.2.Desktop" />
        ) : (
          <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Message.2" />
        )}
        {error && (
          <Message error>
            <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.Error" />
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={postpone} disabled={loading}>
          <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.MaybeLaterButtonText" />
        </Button>
        {!isDesktopApp() && (
          <Button primary onClick={accept} loading={loading} disabled={loading}>
            <FormattedMessage id="ThreeMonthTeamspaceUpgradePromotionModal.AcceptOfferButtonText" />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

ThreeMonthTeamspaceUpgradePromotionModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default withRouter(ThreeMonthTeamspaceUpgradePromotionModal);
