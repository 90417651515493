import React from 'react';
import usePrefersColorScheme from 'use-prefers-color-scheme';

/*
  Allows class components to use our usePrefersColorScheme hook
*/
export const withPrefersColorScheme = WrappedComponent => {
  const WithPrefersColorScheme = props => {
    const scheme = usePrefersColorScheme();
    return <WrappedComponent lightScheme={scheme === 'light'} {...props} />;
  };
  return WithPrefersColorScheme;
};
