import axios from 'axios';
import {
  MY_NOTIFICATIONS,
  getMyNotificationsStatsUrl,
} from 'DataLayer/endpoints';

import {
  getByChannelId as getFromDb,
  saveMany as saveToDb,
} from 'DataLayer/database/notifications';

import { isFeatureEnabled } from 'config/features';
import { FEATURES } from 'config/features.config';

export const SET_TYPE = {
  before: 'before',
  after: 'after',
  around: 'around',
};

export async function getFromCache({
  channelId,
  notificationId,
  setType,
  limit = 50,
  workspaceId,
  notificationTimestamp,
}) {
  if (isFeatureEnabled(FEATURES.CLIENT_CACHING, workspaceId)) {
    const notifications = await getFromDb({
      channelId,
      limit,
      notificationTimestamp,
    });
    return {
      Notifications: notifications,
    };
  }
  return Promise.resolve(undefined);
}

export async function getFromNetwork({
  channelId,
  notificationId,
  setType,
  limit = 50,
  notificationTimestamp,
}) {
  const qs = [];
  if (channelId) {
    qs.push(`spaceId=${channelId}`);
  }
  qs.push(`Limit=${limit}`);
  qs.push(`fields=imageheight`);

  if (notificationId) {
    qs.push(`${setType}=${encodeURIComponent(notificationId)}`);
  }

  const { data } = await axios.get(`${MY_NOTIFICATIONS}?${qs.join('&')}`);

  saveToDb({
    networkNotifications: data.Notifications,
    channelId,
    notificationTimestamp,
    limit,
  });

  return data;
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function getNotifications({
  channelId,
  notificationId,
  setType,
  limit = 50,
  workspaceId,
  notificationTimestamp,
}) {
  const cachedNotifications = await getFromCache({
    channelId,
    notificationId,
    setType,
    limit,
    workspaceId,
    notificationTimestamp,
  });
  const fetchedPromise = getFromNetwork({
    channelId,
    notificationId,
    setType,
    limit,
    notificationTimestamp,
  });

  return { cachedNotifications, fetchedPromise };
}

export function searchNotifications(
  workspaceId,
  query,
  channelIds,
  memberEmails,
  startDate,
  endDate
) {
  const qs = [];
  if (workspaceId) {
    qs.push(`WorkspaceId=${workspaceId}`);
  }
  qs.push('Limit=50');
  qs.push(`fields=imageheight`);

  if (query) {
    qs.push(`Query=${query}`);
  }

  if (channelIds && channelIds.length) {
    qs.push(`ChannelIds=${channelIds.join(',')}`);
  }

  if (memberEmails && memberEmails.length) {
    qs.push(`MemberEmails=${encodeURIComponent(memberEmails.join(','))}`);
  }

  if (startDate) {
    qs.push(`StartDate=${startDate}`);
  }

  if (endDate) {
    qs.push(`EndDate=${endDate}`);
  }

  return axios
    .get(`${MY_NOTIFICATIONS}/search?${qs.join('&')}`)
    .then(response => response.data);
}

export function getLatestNotifications(spaceId, timestamp) {
  return axios
    .get(`${MY_NOTIFICATIONS}?spaceId=${spaceId}&timestamp=${timestamp}`)
    .then(response => response.data);
}

export function getStats(workspaceId) {
  if (workspaceId) {
    return axios
      .get(`${MY_NOTIFICATIONS}/${workspaceId}/stats?includeChatSpaces`)
      .then(response => response.data);
  }
  return axios
    .get(getMyNotificationsStatsUrl())
    .then(response => response.data);
}

export function getConversationStats() {
  return axios
    .get(`${MY_NOTIFICATIONS}/conversations/stats?includeChatSpaces`)
    .then(response => response.data);
}
