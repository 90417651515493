import { importDB } from 'dexie-export-import';

export const importDatabase = async (databaseBlob, progressCallback) => {
  const file = new File([databaseBlob], 'dexie');
  return await importDB(file, {
    clearTablesBeforeImport: true,
    progressCallback,
    noTransaction: true,
  });
};
