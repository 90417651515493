import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { getLinkedItemsLink } from 'pages';

import { showPreviewerForChatItems } from 'store/Modals/actions';
import { spaceSidebarPanelSelected, SIDEBAR_ITEMS } from 'store/UI/index';
import LinkButton from 'Components/Elements/LinkButton';

const ResourcePreviewLink = ({ dispatch, resource, spaceId, spaceType }) => {
  if (resource.Folder) {
    return (
      <Link
        to={getLinkedItemsLink(spaceId, spaceType, resource.Id)}
        onClick={() => {
          dispatch(
            spaceSidebarPanelSelected(spaceId, SIDEBAR_ITEMS.files, true)
          );
        }}
      >
        {resource.Name}
      </Link>
    );
  }
  return (
    <LinkButton
      onClick={event => {
        event.preventDefault();
        dispatch(showPreviewerForChatItems(resource.Id, spaceId));
      }}
    >
      {resource.Name}
    </LinkButton>
  );
};

ResourcePreviewLink.propTypes = {
  dispatch: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
  spaceId: PropTypes.string.isRequired,
  spaceType: PropTypes.string,
};

export default connect()(ResourcePreviewLink);
