import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DRIVES, PRIVATECHAT, WORKSPACES } from 'pages';

import { getUIProductArea } from 'store/User/selectors';
// import loadUser from 'store/User/loadUser';
// import { PRODUCT_AREA } from 'store/User/updateUser';

export class RedirectToArea extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    /*
      Work Spaces is now the only area available. This may change in the future if we re-enable Private Chat
    */
    this.goToSpaces();
    // this.props.dispatch(loadUser()).then(() => {
    //   this.setState({
    //     loading: false,
    //   });
    // });
  }

  componentDidUpdate() {
    /*
      Work Spaces is now the only area available. This may change in the future if we re-enable Private Chat
    */
    this.goToSpaces();
    // if (this.props.UIProductArea === PRODUCT_AREA.Clouds) {
    //   this.goToDrives();
    // } else if (this.props.UIProductArea === PRODUCT_AREA.PrivateChat) {
    //   this.goToPrivateChat();
    // } else {
    //   this.goToSpaces();
    // }
  }

  goToSpaces = () => {
    this.props.router.push(WORKSPACES);
  };

  goToPrivateChat = () => {
    this.props.router.push(PRIVATECHAT);
  };

  goToDrives = () => {
    this.props.router.push(DRIVES);
  };

  render() {
    return null;
  }
}

RedirectToArea.propTypes = {
  UIProductArea: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(state => ({
  UIProductArea: getUIProductArea(state),
}))(withRouter(RedirectToArea));
