import React from 'react';

class ElectronHistoryAuthListeners extends React.PureComponent {
  componentDidMount() {
    window.ipcRenderer?.on('go-back-event', this.onBack);
    window.ipcRenderer?.on('go-forward-event', this.onForward);
  }

  componentWillUnmount() {
    window.ipcRenderer?.removeListener('go-back-event', this.onBack);
    window.ipcRenderer?.removeListener('go-forward-event', this.onForward);
  }

  onBack = (event, token) => {
    window.history.back();
  };

  onForward = event => {
    window.history.forward();
  };

  render() {
    return null;
  }
}

export default ElectronHistoryAuthListeners;
