import { FormattedMessage } from 'react-intl';
import styles from './BackupModeBanner.module.scss';

function BackupModeBanner() {
  return (
    <div className={styles.backupModeBanner}>
      <strong className="mr-4">
        <FormattedMessage id="BackupModeBanner.Title" />
      </strong>
    </div>
  );
}

export default BackupModeBanner;
