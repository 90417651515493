import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition, Segment, Header, Message } from 'semantic-ui-react';

import styles from './MeetingInvitation.module.scss';

import CallerAvatar from 'Components/Meeting/CallerAvatar';
import MeetingsRestrictedMessage from 'Components/Meeting/MeetingsRestrictedMessage';
import ParticipantAvatars from 'Components/Meeting/ParticipantAvatars';
import JoinMeetingButton from 'Components/Meeting/JoinMeetingButton';
import RejectMeetingButton from 'Components/Meeting/RejectMeetingButton';
import CurrentMeetingWillEndMessage from 'Components/Meeting/CurrentMeetingWillEndMessage';
import MeetingUserAvatars from 'Components/Meeting/MeetingUserAvatars';
import MeetingStarted from 'Components/Meeting/MeetingStarted';
import MeetingLocation from 'Components/Meeting/MeetingLocation';

export function MeetingInvitation({ activeMeeting }) {
  return (
    <Transition visible transitionOnMount animation="fly down" duration={500}>
      <Segment.Group data-testid="meeting-invitation">
        <Header
          as="h3"
          attached="top"
          className="flex center justify-content-space-between"
        >
          <FormattedMessage
            id="MeetingInvitation.Title"
            values={{
              caller: activeMeeting.caller
                ? `${activeMeeting.caller?.firstName} ${activeMeeting.caller?.lastName}`
                : '',
            }}
          />
        </Header>
        <Segment attached data-testid="segment">
          <div className={styles.meetingInvitation}>
            <span className="mr-3">
              <CallerAvatar meeting={activeMeeting} />
            </span>
            <div className={styles.info}>
              <p>
                <MeetingLocation meeting={activeMeeting} />
              </p>
              <p>
                <MeetingStarted meeting={activeMeeting} />
              </p>
              <p>
                <MeetingUserAvatars meeting={activeMeeting} />
              </p>
              <p>
                <ParticipantAvatars meeting={activeMeeting} />
              </p>
            </div>
          </div>
          <CurrentMeetingWillEndMessage roomId={activeMeeting.roomId} />
          <MeetingsRestrictedMessage
            As={Message}
            meeting={activeMeeting}
            showUpgradeButton
          />
          <div className="flex justify-content-space-between">
            <RejectMeetingButton roomId={activeMeeting.roomId} />
            <JoinMeetingButton roomId={activeMeeting.roomId} />
          </div>
        </Segment>
      </Segment.Group>
    </Transition>
  );
}

MeetingInvitation.propTypes = {
  activeMeeting: PropTypes.shape({
    roomId: PropTypes.string.isRequired,
    callerUserId: PropTypes.string.isRequired,
    participantUserIds: PropTypes.array.isRequired,
    participants: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    caller: PropTypes.object.isRequired,
    channelName: PropTypes.string.isRequired,
    workspaceName: PropTypes.string.isRequired,
  }).isRequired,
};

export default MeetingInvitation;
