import axios from 'axios';
import { getMeetingUsersPostUrl } from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from '../../../config/config';

export function post(meetingId, user) {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  return axios
    .post(getMeetingUsersPostUrl(meetingId), user, config)
    .then(response => response.data);
}
