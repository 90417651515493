import axios from 'axios';
import { MIGRATE } from 'DataLayer/endpoints';

export default function put(workspaceId, sourceChatId, targetChatId) {
  return axios.put(MIGRATE, {
    WorkspaceId: workspaceId,
    SourceChatId: sourceChatId,
    TargetChatId: targetChatId,
  });
}
