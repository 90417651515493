import { getByWorkspaceId } from 'DataLayer/Workspaces/Members/get';
import { addWorkspaceMembers } from './actions';

const loadMembersByWorkspaceId = workspaceId => async dispatch => {
  try {
    if (!workspaceId) {
      return;
    }
    const members = await getByWorkspaceId(workspaceId);
    const membersObject = members.reduce((object, value) => {
      object[value.Id] = value;
      return object;
    }, {});
    dispatch(addWorkspaceMembers(workspaceId, membersObject));
  } catch (error) {
    throw error;
  }
};

export default loadMembersByWorkspaceId;
