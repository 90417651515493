import addDays from 'date-fns/add_days';
import format from 'date-fns/format';

import { SIZE } from '../../Constants/searchOptions';

function buildLess(field, value) {
  return `${field}=less:${value}`;
}

function buildGreater(field, value) {
  return `${field}=greater:${value}`;
}

const sizeRange = {
  [SIZE.TINY]: {
    min: 0,
    max: 1000000,
  },
  [SIZE.SMALL]: {
    min: 1000000,
    max: 10000000,
  },
  [SIZE.MEDIUM]: {
    min: 10000000,
    max: 100000000,
  },
  [SIZE.LARGE]: {
    min: 100000000,
    max: 1000000000,
  },
  [SIZE.HUGE]: {
    min: 1000000000,
    max: 10000000000,
  },
};

function buildSize(size) {
  if (!size) {
    return '';
  }
  const sizes = [];
  const range = sizeRange[size];
  sizes.push(buildLess('size', range.max));
  sizes.push(buildGreater('size', range.min));
  return sizes.join('&');
}

function buildDate(date) {
  if (!date) {
    return '';
  }

  const lessDate = format(Date.now(), 'x');
  const greaterDate = format(addDays(Date.now(), -date), 'x');

  const dates = [];
  dates.push(buildLess('modified', lessDate));
  dates.push(buildGreater('modified', greaterDate));
  return dates.join('&');
}

export default function buildSearchQuery(
  query,
  type,
  date,
  size,
  resourceId,
  linked
) {
  const qs = [];
  let q = '';

  if (query) {
    q = query;
  }

  if (linked) {
    qs.push('linked=true');
  }

  if (type) {
    qs.push(`mimetype=${type}`);
  }

  if (resourceId) {
    qs.push(`ancestor=${resourceId}`);
  }

  qs.push(buildSize(size));

  qs.push(buildDate(date));

  return `name=${q}&${qs.join('&')}`;
}
