import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form, Loader } from 'semantic-ui-react';

import { getActiveMeeting } from 'store/Meeting/selectors';
import { getOrderedChatsByWorkspaceId } from 'store/Spaces/selectors';
import loadSpaces from 'store/Spaces/loadSpaces';

import useAsync from 'Hooks/useAsync';

import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import WorkspacesSelect from 'Components/Workspace/WorkspacesSelect';
import WorkspaceMember from './WorkspaceMember';

import styles from './WorkspaceMembersList.module.scss';

const WorkspaceMembersList = () => {
  const activeMeeting = useSelector(getActiveMeeting);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    activeMeeting.workspaceId
  );

  const dispatch = useDispatch();

  const channelsAsyncState = useAsync(() => {
    return dispatch(loadSpaces(selectedWorkspaceId));
  }, [selectedWorkspaceId]);

  const chats = useSelector(state =>
    getOrderedChatsByWorkspaceId(state, selectedWorkspaceId)
  );

  return (
    <>
      <Form autoComplete="off" className={styles.teamspaceSelection}>
        <Form.Field>
          <label>
            <strong>
              <FormattedMessage id="CreateMembersMeeting.TeamspaceLabel" />
            </strong>
          </label>
          <WorkspacesSelect
            onWorkspaceSelected={id => {
              setSelectedWorkspaceId(id);
            }}
            selectedWorkspaceId={selectedWorkspaceId}
          />
        </Form.Field>
      </Form>
      {chats.length === 0 && channelsAsyncState.loading && (
        <div className={styles.loader}>
          <Loader active inline />
        </div>
      )}
      {chats.map(chat => {
        return (
          <ErrorBoundary key={chat.Id}>
            <WorkspaceMember
              activeMeeting={activeMeeting}
              chatId={chat.Id}
              chat={chat}
              key={chat.Id}
            />
          </ErrorBoundary>
        );
      })}
    </>
  );
};

export default WorkspaceMembersList;
