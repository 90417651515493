import PropTypes from 'prop-types';
import { isNodeEnvDevelopment } from 'config/config';

function IsDevelopmentMode({ children }) {
  if (isNodeEnvDevelopment()) {
    return children;
  }

  return null;
}

IsDevelopmentMode.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IsDevelopmentMode;
