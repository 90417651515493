import put from 'DataLayer/Migrate/put';
import { lockSpace } from '../Spaces/actions';

const startMigration = (
  workspaceId,
  sourceChatId,
  targetChatId
) => async dispatch => {
  try {
    const space = await put(workspaceId, sourceChatId, targetChatId);
    dispatch(lockSpace(sourceChatId, true));
    return space;
  } catch (ex) {
    throw ex;
  }
};

export default startMigration;
