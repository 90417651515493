/*
  If there are spaces available, redirect to the system space
  If there are no spaces available and they are loading, wait
*/

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSpaceLink } from 'pages';
import { getSpacesOrderedByLastUpdate } from 'store/Spaces/selectors';
import loadSpaces from 'store/Spaces/loadSpaces';
import { getLatestChannelIdByWorkspaceId } from 'store/UI/selectors';

export const useRedirectToChannel = (
  replace,
  workspaceId,
  isSkeletonApp,
  query
) => {
  const dispatch = useDispatch();
  const spaces = useSelector(state =>
    getSpacesOrderedByLastUpdate(state, workspaceId)
  );
  const latestSpaceId = useSelector(state =>
    getLatestChannelIdByWorkspaceId(state, workspaceId)
  );

  const goToSpace = useCallback(
    space => {
      replace(getSpaceLink(space.Id, query));
    },
    [replace, query]
  );

  const goToLastUsedSpace = useCallback(() => {
    if (spaces.length > 0) {
      const latestSpace = spaces.find(s => s.Id === latestSpaceId);
      if (latestSpace) {
        goToSpace(latestSpace);
      } else {
        const space = spaces.find(s => !s.IsChat);
        if (space) {
          goToSpace(space);
        } else {
          const defaultSpace = spaces[0];
          if (defaultSpace) {
            goToSpace(defaultSpace);
          }
        }
      }
    }
  }, [goToSpace, latestSpaceId, spaces]);

  useEffect(() => {
    if (isSkeletonApp) {
      return;
    }
    if (workspaceId && spaces.length) {
      goToLastUsedSpace();
    }
  }, [goToLastUsedSpace, isSkeletonApp, spaces, workspaceId]);

  useEffect(() => {
    if (isSkeletonApp) {
      return;
    }
    if (workspaceId && spaces.length === 0) {
      dispatch(loadSpaces(workspaceId));
    }
  }, [dispatch, isSkeletonApp, spaces.length, workspaceId]);
};
