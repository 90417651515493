import { byId } from 'DataLayer/Resources/get';

import { mergeResource } from '../Entities/mergeResources';
import * as actions from './actions';

export const loadResource = (id, includeComments) => async dispatch => {
  const resource = await byId(id, includeComments);
  dispatch(mergeResource(resource));
  dispatch(actions.addResources(resource.ParentId, [resource.Id]));
  return resource;
};

export default loadResource;
