import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(notification) {
  try {
    await commonDb.notifications.put({
      notificationId: notification.Id,
      channelId: notification.Workspace.Id,
      notificationTimestamp: notification.NotificationTimestamp,
      notification,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function clearRemovedNotifications({
  networkNotifications,
  channelId,
  notificationTimestamp,
  limit,
}) {
  const databaseNotifications = await getByChannelId({
    channelId,
    limit,
    notificationTimestamp,
  });

  if (!databaseNotifications) {
    return null;
  }

  const networkNotificationsIds = networkNotifications.map(
    notification => notification.Id
  );

  // check db notification is inside network response
  for (const databaseNotification of databaseNotifications) {
    if (!networkNotificationsIds.includes(databaseNotification.Id)) {
      await remove(databaseNotification.Id);
    }
  }
}

export async function saveMany({
  networkNotifications,
  channelId,
  notificationTimestamp,
  limit,
}) {
  try {
    await clearRemovedNotifications({
      networkNotifications,
      channelId,
      notificationTimestamp,
      limit,
    });

    await commonDb.notifications.bulkPut(
      networkNotifications.map(notification => ({
        notificationId: notification.Id,
        channelId: channelId,
        notificationTimestamp: notification.NotificationTimestamp,
        notification,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.notifications.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function getNotificationsBeforeTimestamp({
  channelId = '',
  limit,
  notificationTimestamp,
}) {
  return await commonDb.notifications
    .where('notificationTimestamp')
    .below(notificationTimestamp)
    .filter(notification => notification.channelId === channelId)
    .reverse()
    .limit(limit)
    .toArray();
}

export async function getByChannelId({
  channelId = '',
  limit = 50,
  notificationTimestamp,
}) {
  try {
    let notifications;
    if (notificationTimestamp) {
      notifications = await getNotificationsBeforeTimestamp({
        channelId,
        limit,
        notificationTimestamp,
      });
    } else {
      notifications = await commonDb.notifications
        .orderBy('notificationTimestamp')
        .reverse()
        .filter(notification => notification.channelId === channelId)
        .limit(limit)
        .toArray();
    }
    if (Array.isArray(notifications) && notifications.length > 0) {
      return notifications.map(notification => notification.notification);
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return [];
}
