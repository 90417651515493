import { get as getWorkspaceOffer } from 'DataLayer/Workspaces/Offers/get';
import { offerLoaded } from 'store/Workspaces/Offer';

const loadOffers = workspaceId => async dispatch => {
  const result = await getWorkspaceOffer(workspaceId);
  dispatch(offerLoaded(result, workspaceId));
  return result;
};

export default loadOffers;
