export const ENTITIES_RESOURCE_LINKED = 'ENTITIES/RESOURCE/LINKED';
export const RESOURCE_STAR_ADDED = 'ENTITIES/RESOURCE/STAR_ADDED';
export const LINKED_ITEM_STAR_ADDED = 'ENTITIES/LINKED_ITEM/STAR_ADDED';
export const RESOURCES_MARK_COMMENTS_AS_READ =
  'RESOURCES/MARK_COMMENTS_AS_READ';

export function updateResourceLinked(resourceId, linked) {
  return {
    type: ENTITIES_RESOURCE_LINKED,
    resourceId,
    linked,
  };
}

export function markCommentsAsRead(spaceId, resourceId) {
  return {
    type: RESOURCES_MARK_COMMENTS_AS_READ,
    spaceId,
    resourceId,
  };
}

export function resourceStarAdded(id, starred) {
  return {
    type: RESOURCE_STAR_ADDED,
    id,
    starred,
  };
}
