import classNames from 'classnames/bind';
import styles from './PartyEmoji.module.scss';

function PartyEmoji({ className, ...props }) {
  return (
    <div className={classNames(styles.emoji, className)} {...props}>
      {/* eslint-disable react/jsx-no-literals */}
      🎉
      {/* eslint-enable react/jsx-no-literals */}
    </div>
  );
}

export default PartyEmoji;
