import { get as getMediaInfo } from 'DataLayer/MediaInfo/get';
import {
  loadMediaInfoError,
  loadMediaInfoRequest,
  loadMediaInfoSuccess,
} from 'store/MediaInfo/actions';
import { getSpaceById } from 'store/Spaces/selectors';
import { getWorkspaceById } from 'store/Workspaces/selectors';

import {
  // getMediaInfoById,
  isFetching,
} from '../MediaInfo/selectors';

export const loadMediaInfo = (id, spaceId) => async (dispatch, getState) => {
  const state = getState();
  const channel = getSpaceById(state, spaceId);
  const workspace = getWorkspaceById(state, channel.WorkspaceId);
  /* if this folder is already being requested, skip it! */
  if (isFetching(state, id)) {
    return Promise.resolve();
  }

  /*
  Using the cached media info will cause problems because the URL returned in MediaInfo is only
  valid for n minutes.
  const mediaInfoFromState = getMediaInfoById(state, id);
  if (mediaInfoFromState) {
    return Promise.resolve(mediaInfoFromState);
  }
  */
  dispatch(loadMediaInfoRequest(id));

  try {
    const { cachedMediaInfo, fetchedPromise } = await getMediaInfo(
      id,
      spaceId,
      workspace.Id
    );
    if (cachedMediaInfo) {
      dispatch(loadMediaInfoSuccess(id, cachedMediaInfo));
    }
    const networkMediaInfo = await fetchedPromise;
    dispatch(loadMediaInfoSuccess(id, networkMediaInfo));
    return cachedMediaInfo;
  } catch (error) {
    dispatch(loadMediaInfoError(id, error));
  }
};

export default loadMediaInfo;
