import axios from 'axios';
import { REPOSITORIES } from 'DataLayer/endpoints';
import process from './process';

export function rename(id, name) {
  return axios
    .put(`${REPOSITORIES}?id=${id}&name=${encodeURIComponent(name)}`)
    .then(response => response.data.Repository)
    .then(repository => process.single(repository));
}

export function update(id, fields) {
  const returnFields = 'authorized';
  return axios
    .put(`${REPOSITORIES}?id=${id}&fields=${returnFields}`, fields)
    .then(response => response.data.Repository)
    .then(repository => process.single(repository));
}
