import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getIsTypingByChannelId } from 'store/IsTyping/selectors';

import './TypingIndicator.scss';

const renderTypers = (typers, individual) => {
  let endingText = <FormattedMessage id="TypingIndicator.are-typing" />;
  if (typers.length === 1) {
    endingText = <FormattedMessage id="TypingIndicator.is-typing" />;
  }

  if (individual) {
    return endingText;
  }

  const typersString = typers.map((typer, index) => (
    <React.Fragment key={typer.Name}>
      <strong> {typer.Name} </strong> {typers.length - 1 !== index && 'and'}
    </React.Fragment>
  ));

  return (
    <div>
      {typersString}
      {endingText}
    </div>
  );
};

const TypingIndicator = ({ typers, individual }) => (
  <div className="typing-indicator-container">
    {typers.length > 0 && (
      <React.Fragment>
        <div className="typing-indicator">
          <span />
          <span />
          <span />
        </div>
        {renderTypers(typers, individual)}
      </React.Fragment>
    )}
  </div>
);

TypingIndicator.propTypes = {
  typers: PropTypes.array,
  individual: PropTypes.array,
};

export default connect((state, props) => ({
  typers: getIsTypingByChannelId(state, props.spaceId),
}))(TypingIndicator);
