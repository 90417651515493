import { FormattedMessage } from 'react-intl';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Message, Button } from 'semantic-ui-react';
import { getWorkspaceUpgradeLink } from 'pages';
import { withRouter } from 'react-router';
import { getWorkspaceById } from 'store/Workspaces/selectors';
import { makeGetSpaceById } from 'store/Spaces/selectors';

import ShowIfNotAppStore from 'Components/Electron/ShowIfNotAppStore';
import InformTeamspaceOwnerButton from 'Components/Workspace/InformTeamspaceOwnerButton';

import styles from './MessageLimitReached.module.scss';

export const MessageLimitReached = ({ workspace, router }) => (
  <ShowIfNotAppStore
    all={
      <Message className={styles.message}>
        <FormattedMessage
          id={
            workspace.IsOwner
              ? 'MessageLimitReached.OwnerMessage'
              : 'MessageLimitReached.MemberMessage'
          }
        />
        {workspace.IsOwner ? (
          <Button
            primary
            onClick={() => {
              router.push(getWorkspaceUpgradeLink(workspace.Id));
            }}
          >
            <FormattedMessage id="MessageLimitReached.UpgradeButtonText" />
          </Button>
        ) : (
          <InformTeamspaceOwnerButton workspaceId={workspace.Id} />
        )}
      </Message>
    }
  />
);

MessageLimitReached.propTypes = {
  workspace: PropTypes.object.isRequired,
  router: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect((state, props) => {
  const getSpaceById = makeGetSpaceById();

  return (state, props) => {
    const channel = getSpaceById(state, props.spaceId);
    const workspace = getWorkspaceById(state, channel.WorkspaceId);

    return {
      workspace,
    };
  };
})(withRouter(MessageLimitReached));
