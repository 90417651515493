import axios from 'axios';
import { ADAPTERS } from 'DataLayer/endpoints';
import process from './process';

export default function() {
  return axios
    .get(ADAPTERS)
    .then(response => response.data.CloudServiceAdapters)
    .then(adapters => process(adapters));
}
