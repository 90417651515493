import { connect } from 'react-redux';
import { makeGetMeetingBeaconByWorkspaceId } from '../../store/Meeting/selectors';
import MeetingBeacon from './MeetingBeacon';

export default connect(() => {
  const getMeetingBeaconByWorkspaceId = makeGetMeetingBeaconByWorkspaceId();
  return (state, props) => {
    return {
      meetingBeaconType: getMeetingBeaconByWorkspaceId(
        state,
        props.workspaceId
      ),
    };
  };
})(MeetingBeacon);
