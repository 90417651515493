import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import { Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import collaboratorPresence from 'Constants/collaboratorPresence';

import './CollaboratorPresence.scss';

const CollaboratorPresence = ({ presence, forceOnline }) => {
  let realPresence = presence;
  if (forceOnline) {
    realPresence = collaboratorPresence.ONLINE;
  }
  const presenceClass = classNames({
    'collaborator-presence': true,
    online: realPresence === collaboratorPresence.ONLINE || forceOnline,
    away: realPresence === collaboratorPresence.AWAY,
    offline: realPresence === collaboratorPresence.OFFLINE,
  });

  let translationId;

  switch (realPresence) {
    case collaboratorPresence.ONLINE:
      translationId = 'PresenceClass.Online';
      break;
    case collaboratorPresence.OFFLINE:
      translationId = 'PresenceClass.Offline';
      break;
    case collaboratorPresence.AWAY:
      translationId = 'PresenceClass.Away';
      break;
    default:
      translationId = 'PresenceClass.Offline';
      break;
  }

  return (
    <Popup
      inverted
      trigger={<span className={presenceClass} />}
      content={<FormattedMessage id={translationId} />}
      size="mini"
    />
  );
};

CollaboratorPresence.propTypes = {
  presence: PropTypes.string.isRequired,
  forceOnline: PropTypes.bool,
};

export default CollaboratorPresence;
