import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Popup } from 'semantic-ui-react';

import WorkspaceMenuItems from '../../Pages/WorkspaceMenuItems';
import ContextMenu from '../../ContextMenu/ContextMenu';
import SidebarWrapper from 'Components/Layouts/SidebarLayout/SidebarWrapper';
import { FormattedMessage } from 'react-intl';
import WeTeamIcon from 'Components/Icons/WeTeamIcon';

import styles from './WorkspaceTitle.module.scss';
import { ArrowDown2Icon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

class WorkspaceTitle extends React.Component {
  render() {
    const { workspace } = this.props;
    if (!workspace.Id) {
      return null;
    }
    return (
      <ContextMenu
        trigger={
          <div className="flex text-ellipsis cursor-pointer">
            <SidebarWrapper.SidebarTitle>
              {workspace.Name}
            </SidebarWrapper.SidebarTitle>
            <Popup
              position="bottom center"
              inverted
              trigger={
                <DisableInBackupMode>
                  <WeTeamIcon
                    Icon={ArrowDown2Icon}
                    link={false}
                    className={styles.arrowDown}
                  />
                </DisableInBackupMode>
              }
              content={<FormattedMessage id="WorkspaceTitle.Settings" />}
              size="mini"
            />
          </div>
        }
      >
        <div>
          <Menu borderless vertical fluid className={styles.menuItems}>
            <WorkspaceMenuItems
              showIcons
              workspaceId={workspace.Id}
              showArchive
              showMarkAsRead
            />
          </Menu>
        </div>
      </ContextMenu>
    );
  }
}

WorkspaceTitle.propTypes = {
  workspace: PropTypes.object,
};

export default WorkspaceTitle;
