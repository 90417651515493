import { archive } from 'DataLayer/Spaces/update';
import { setSpaceRequestInProgress } from '../Requests/setRequestInProgress';

import { displayErrorToast, displaySuccessToast } from '../Toast/displayToast';

import * as spaceActions from './actions';

const archiveSpace = space => dispatch => {
  dispatch(setSpaceRequestInProgress(space.Id, true));
  archive(space.Id).then(
    () => {
      dispatch(spaceActions.archiveSpace(space.Id, true));
      dispatch(setSpaceRequestInProgress(space.Id, false));
      dispatch(
        displaySuccessToast(
          space.IsChat || space.IsConversation
            ? 'toast.archiveChat.success'
            : 'toast.archiveSpace.success'
        )
      );
    },
    () => {
      dispatch(setSpaceRequestInProgress(space.Id, false));
      dispatch(displayErrorToast('toast.archiveSpace.error'));
    }
  );
};

export default archiveSpace;
