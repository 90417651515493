import axios from 'axios';
import { RESOURCES } from 'DataLayer/endpoints';

export function preflightSingle(id, spaceId) {
  const params = [];
  if (spaceId) params.push(`spaceId=${spaceId}`);
  const paramsString = params.join('&');
  return axios
    .get(`${RESOURCES}/${id}/content?preflight&${paramsString}`)
    .then(response => response.data);
}

export function preflightSingleEncrypted(id, spaceId) {
  const params = [];
  if (spaceId) params.push(`spaceId=${spaceId}`);
  const paramsString = params.join('&');
  return axios
    .get(`${RESOURCES}/${id}/content.decrypt?preflight&${paramsString}`)
    .then(response => response.data);
}

export function preflightArchive(id, spaceId) {
  const params = [];
  if (spaceId) params.push(`spaceId=${spaceId}`);
  params.push(`id=${id}`);
  const paramsString = params.join('&');
  return axios
    .get(`${RESOURCES}/content.archive?preflight&${paramsString}`)
    .then(response => response.data);
}

export default {
  preflightSingle,
  preflightArchive,
  preflightSingleEncrypted,
};
