import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { meetingInvitationAccepted } from 'store/Meeting';
import {
  getIsMeetingStarting,
  makeIsCurrentUserAParticipantInMeeting,
} from 'store/Meeting/selectors';
import UserAlreadyAParticipantOfMeeting from 'Components/Meeting/UserAlreadyAParticipantOfMeeting';

export function JoinMeetingButton({
  roomId,
  isMeetingStarting,
  currentUserAParticipant,
  onJoinMeeting,
}) {
  const [
    showUserAlreadyAParticipantOfMeeting,
    setShowUserAlreadyAParticipantOfMeeting,
  ] = useState(false);

  const onJoinClick = () => {
    if (currentUserAParticipant) {
      setShowUserAlreadyAParticipantOfMeeting(true);
      return;
    }
    onJoin();
  };

  const onJoin = () => {
    onJoinMeeting(roomId);
    setShowUserAlreadyAParticipantOfMeeting(false);
  };

  return (
    <>
      {showUserAlreadyAParticipantOfMeeting && (
        <UserAlreadyAParticipantOfMeeting
          onJoin={onJoin}
          onCancel={() => {
            setShowUserAlreadyAParticipantOfMeeting(false);
          }}
        />
      )}
      <Button
        color="green"
        onClick={onJoinClick}
        disabled={isMeetingStarting}
        loading={isMeetingStarting}
        data-testid="join"
      >
        <FormattedMessage id="MeetingInvitation.JoinButtonText" />
      </Button>
    </>
  );
}

JoinMeetingButton.propTypes = {
  roomId: PropTypes.string.isRequired,
  isMeetingStarting: PropTypes.bool,
  onJoinMeeting: PropTypes.func.isRequired,
};

export default connect(
  () => {
    const isCurrentUserAParticipantInMeeting = makeIsCurrentUserAParticipantInMeeting();
    return (state, props) => {
      return {
        isMeetingStarting: getIsMeetingStarting(state),
        currentUserAParticipant: isCurrentUserAParticipantInMeeting(
          state,
          props.roomId
        ),
      };
    };
  },
  {
    onJoinMeeting: meetingInvitationAccepted,
  }
)(JoinMeetingButton);
