import PropTypes from 'prop-types';
import React from 'react';

import { FormattedHTMLMessage } from 'react-intl';

function MeetingLocation({ meeting }) {
  return (
    <FormattedHTMLMessage
      id="MeetingInvitation.Location"
      values={{
        channelName: meeting.channelName,
        workspaceName: meeting.workspaceName,
      }}
    />
  );
}

MeetingLocation.propTypes = {
  meeting: PropTypes.shape({
    channelName: PropTypes.string.isRequired,
    workspaceName: PropTypes.string.isRequired,
  }).isRequired,
};

export default MeetingLocation;
