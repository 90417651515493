import { getbyId } from 'DataLayer/Share/get';
import create from 'DataLayer/Share/create';

import {
  showResourceLink,
  hideResourceLink,
  showPublicLinkSizeRestrictedModal,
} from 'store/Modals/actions';

import { setResourceLinkRequestInProgress } from '../Requests/setRequestInProgress';

import { displayErrorToast } from '../Toast/displayToast';

import { updateResourceLinked } from '../Entities/actions';

import { isLinkSizeException } from '../../DataLayer/error';

export const LOAD_LINK_SUCCESS = 'LINK/LOAD_SUCCESS';

export const getLink = item => dispatch => {
  dispatch(setResourceLinkRequestInProgress(true));

  dispatch(showResourceLink(item.Id));

  getbyId(item.Id).then(
    link => {
      if (link) {
        dispatch({
          type: LOAD_LINK_SUCCESS,
          link,
        });
        dispatch(setResourceLinkRequestInProgress(false));
      } else {
        // create a link
        create(item.Id).then(
          newLink => {
            dispatch({
              type: LOAD_LINK_SUCCESS,
              link: newLink,
            });
            dispatch(setResourceLinkRequestInProgress(false));
            dispatch(updateResourceLinked(item.Id, true));
          },
          error => {
            if (isLinkSizeException(error)) {
              dispatch(showPublicLinkSizeRestrictedModal());
            } else {
              dispatch(displayErrorToast('toast.getLink.create.error'));
            }
            dispatch(hideResourceLink());
            dispatch(setResourceLinkRequestInProgress(false));
          }
        );
      }
    },
    () => {
      dispatch(displayErrorToast('toast.getLink.error'));
      dispatch(hideResourceLink());
      dispatch(setResourceLinkRequestInProgress(false));
    }
  );
};
