import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { showIntroFlow } from '../AppCues/AppCuesLaunch';

import { getWorkSpacesAppCueTourSeen } from 'store/User/selectors';
import { updateWorkSpacesAppCueTourSeen } from 'store/User/updateUser';
import { getLocale } from 'store/Locale/selectors';
import { prepareWorkspaceTour } from 'store/Tours/prepareWorkspaceTour';

const AppCuesWorkSpaceTour = ({
  workSpacesAppCueTourSeen,
  locale,
  onTourSeen,
}) => {
  const dispatch = useDispatch();
  // show flow if never seen before
  useEffect(() => {
    /*
      this is commented out because the tour is only explicitally triggered instead of being triggerd by visiting a page
    */
    //if (!workSpacesAppCueTourSeen) {
    showIntroFlow(locale);
    onTourSeen(true);
    dispatch(prepareWorkspaceTour({}));
    //}
  }, [workSpacesAppCueTourSeen, onTourSeen, locale, dispatch]);
  return <span data-testid="app-cues-workspace-tour" />;
};

AppCuesWorkSpaceTour.propTypes = {
  workSpacesAppCueTourSeen: PropTypes.bool.isRequired,
  onTourSeen: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default connect(
  (state, props) => ({
    workSpacesAppCueTourSeen: getWorkSpacesAppCueTourSeen(state),
    locale: getLocale(state),
  }),
  {
    onTourSeen: updateWorkSpacesAppCueTourSeen,
  }
)(AppCuesWorkSpaceTour);
