import { postNotifications } from 'DataLayer/Notifications/post';
import { getSpacesOrderedByName } from 'store/Spaces/selectors';
import { MARK_CHANNEL_AS_READ } from 'store/UI';
import { getUnreadChannels } from 'store/UI/selectors';

export const channelMarkedAsRead =
  (channelId, workspaceId) => async (dispatch, getState) => {
    /*
  check no other channels in the workspace are marked as read
  */
    const state = getState();
    const workspaceChannels = getSpacesOrderedByName(state, workspaceId);
    const workspaceChannelIds = workspaceChannels.map(channel => channel.Id);
    const unreadChannels = getUnreadChannels(state);

    /**
     * if this is the only unread channel in the workspace, mark the workspace as read
     */
    const workspaceHasOtherUnreadChannels = Object.entries(unreadChannels).some(
      ([cId, unread]) => {
        if (unread && workspaceChannelIds.includes(cId) && cId !== channelId) {
          return true;
        }
        return false;
      }
    );

    dispatch({
      type: MARK_CHANNEL_AS_READ,
      channelId,
      workspaceId: workspaceHasOtherUnreadChannels ? null : workspaceId,
    });

    await postNotifications(channelId);
  };
