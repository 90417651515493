import * as Sentry from '@sentry/react';
import { commonDb } from 'DataLayer/database/indexedDb';

export async function saveOne(linkedItem, channelId) {
  try {
    await commonDb.linkedItems.put({
      linkedItemId: linkedItem.Id,
      resourceId: linkedItem.ResourceId,
      channelId: channelId,
      linkedItem,
    });
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

async function clearRemovedLinkedItems(networkLinkedItems, channelId) {
  const databaseLinkedItems = await getByChannelId(channelId);

  if (!databaseLinkedItems) {
    return null;
  }

  const networkLinkedItemIds = networkLinkedItems.map(
    linkedItem => linkedItem.Id
  );

  // check db linkeditem is inside response linkeditems
  for (const databaseLinkedItem of databaseLinkedItems) {
    if (!networkLinkedItemIds.includes(databaseLinkedItem.Id)) {
      await remove(databaseLinkedItem.Id);
    }
  }
}

export async function saveMany(networkLinkedItems, channelId) {
  try {
    await clearRemovedLinkedItems(networkLinkedItems, channelId);

    await commonDb.linkedItems.bulkPut(
      networkLinkedItems.map(linkedItem => ({
        linkedItemId: linkedItem.Id,
        resourceId: linkedItem.ResourceId,
        channelId: channelId,
        linkedItem,
      }))
    );
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function remove(id) {
  try {
    await commonDb.linkedItems.delete(id);
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function removeByResourceId(id) {
  try {
    await commonDb.linkedItems.where('resourceId').equals(id).delete();
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export async function getByChannelId(channelId) {
  try {
    const linkedItems = await commonDb.linkedItems
      .where('channelId')
      .equals(channelId)
      .toArray();
    if (Array.isArray(linkedItems) && linkedItems.length > 0) {
      return linkedItems.map(linkedItem => linkedItem.linkedItem);
    }
  } catch (ex) {
    Sentry.captureException(ex);
  }
  return null;
}
