import React from 'react';
import PropTypes from 'prop-types';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Divider from 'Components/Chat/SpaceChat/Divider';

const DateDivider = ({ date }) => {
  const dayDifference = differenceInCalendarDays(
    format(new Date()),
    format(date)
  );
  let formattedDate;
  if (dayDifference === 0) {
    formattedDate = <FormattedMessage id="DateDivider.Today" />;
  } else if (dayDifference === 1) {
    formattedDate = <FormattedMessage id="DateDivider.Yesterday" />;
  } else {
    formattedDate = (
      <FormattedDate value={date} year="numeric" month="long" day="2-digit" />
    );
  }
  return <Divider>{formattedDate}</Divider>;
};

DateDivider.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateDivider;
