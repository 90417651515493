export const getChannelFileLocation = (state, channelId) =>
  state.uiTemporary.latestChannelFileLocation[channelId];

export const getFileManagerLocation = state =>
  state.uiTemporary.latestFileManagerLocation;

export const getFileBrowserLocation = state =>
  state.uiTemporary.latestFileBrowserLocation;

export const getScrollPositionById = (state, id) => {
  return state.uiTemporary.chatScrollPosition[id];
};
