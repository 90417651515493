import React from 'react';
import PropTypes from 'prop-types';

import styles from './SidebarWrapper.module.scss';
import classNamesBind from 'classnames/bind';

const SidebarTitle = ({ children, className }) => (
  <strong
    className={classNamesBind(
      'flex mr-2 text-ellipsis',
      styles.title,
      className
    )}
  >
    {children}
  </strong>
);

SidebarTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const SidebarSubTitle = ({ children }) => (
  <strong className={styles.subTitle}>{children}</strong>
);

SidebarSubTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

const SidebarWrapper = ({ children }) => (
  <div
    className={classNamesBind('flex column flex-auto', styles.sidebarWrapper)}
  >
    {children}
  </div>
);

SidebarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

SidebarWrapper.SidebarTitle = SidebarTitle;
SidebarWrapper.SidebarSubTitle = SidebarSubTitle;

export default SidebarWrapper;
