import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getUnreadAllSpaceNotificationsCount } from 'store/Notifications/selectors';
import { useEffect } from 'react';

export function getFaviconUrl(count, isMeetingStarted) {
  if (count > 0) {
    return `${process.env.PUBLIC_URL}/favicon.ico?v=1`;
  } else {
    return `${process.env.PUBLIC_URL}/favicon.ico?v=1`;
  }
}

function Favicon({ count, isMeetingStarted }) {
  useEffect(() => {
    const src = getFaviconUrl(count, isMeetingStarted);
    const link = document.createElement('link');
    const oldLink = document.getElementById('favicon');
    link.id = 'favicon';
    link.rel = 'icon';
    link.href = src;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }, [count, isMeetingStarted]);

  return null;
}

Favicon.propTypes = {
  count: PropTypes.number.isRequired,
};

export default connect(state => ({
  count: getUnreadAllSpaceNotificationsCount(state),
}))(Favicon);
