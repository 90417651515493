import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { hideCongratulationsModal } from 'store/Modals/actions';

import styles from './CongratulationsModal.module.scss';

function CongratulationsModal() {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideCongratulationsModal());
  };
  return (
    <Modal size="tiny" open onClose={onClose}>
      <Header>
        <div className="close_modal">
          <button className="close_modal_button" onClick={onClose} />
        </div>
      </Header>
      <Modal.Content>
        {/* eslint-disable react/jsx-no-literals */}
        <div className={`${styles.emoji} flex justify-content-center mb-3`}>
          🎉
        </div>
        {/* eslint-enable react/jsx-no-literals */}
        <FormattedHTMLMessage id="CongratulationsModal.Message" />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onClose}>
          <FormattedMessage id="CongratulationsModal.CloseButtonText" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CongratulationsModal;
