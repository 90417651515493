import { removeById } from 'DataLayer/Notifications/remove';
import { removeNotificationById } from 'store/Notifications/actions';
import { getNotificationById } from 'store/Notifications/selectors';
import {
  displayErrorToast,
  displaySuccessToast,
} from 'store/Toast/displayToast';

const deleteNotifications = notificationIds => async (dispatch, getState) => {
  try {
    const state = getState();
    await removeById(notificationIds);
    notificationIds.forEach(async notificationId => {
      const notification = getNotificationById(state, notificationId);
      dispatch(removeNotificationById(notificationId, notification));
    });
    dispatch(displaySuccessToast('toast.deleteNotifications.success'));
  } catch (error) {
    dispatch(displayErrorToast('toast.deleteNotifications.error'));
    throw error;
  }
};

export default deleteNotifications;
