import { MERGE_RESOURCES } from './mergeResources';

import {
  RESOURCES_MARK_COMMENTS_AS_READ,
  ENTITIES_RESOURCE_LINKED,
  RESOURCE_STAR_ADDED,
} from './actions';

import {
  ADD_LINKEDITEMS_SUCCESS,
  LOAD_LINKEDITEMS_SUCCESS,
} from '../LinkedItems/actions';

import { ADD_COMMENT } from '../Comments/actions';
// import { REMINDERS_ADDED, REMINDER_REMOVED } from 'store/Reminders';

function mergeResources(state, resources) {
  const resourcesMerged = Object.assign({}, state.resources, resources);

  return Object.assign({}, state, {
    resources: resourcesMerged,
  });
}

function setResourceCommentAsRead(state, spaceId, resourceId) {
  let resources = Object.assign({}, state.resources);
  if (state.resources[resourceId]) {
    const resource = Object.assign({}, state.resources[resourceId]);
    if (resource.Comments) {
      const comment = resource.Comments.find(c => c.SpaceId === spaceId);
      if (comment) {
        comment.Unread = 0;
      }
      resources = Object.assign({}, state.resources, {
        [resourceId]: resource,
      });
    }
  }
  return Object.assign({}, state, {
    resources,
  });
}

function setCommentTotal(state, spaceId, resourceId, isUserComment) {
  let resources = Object.assign({}, state.resources);
  if (state.resources[resourceId]) {
    const resource = Object.assign({}, state.resources[resourceId]);
    if (resource.Comments) {
      let comment = resource.Comments.find(c => c.SpaceId === spaceId);
      if (comment) {
        comment.Total = comment.Total + 1;
        comment.Unread = isUserComment ? 0 : comment.Unread + 1;
      } else {
        comment = {
          ResourceId: resourceId,
          SpaceId: spaceId,
          Total: 1,
          Unread: isUserComment ? 0 : 1,
        };
        resource.Comments = [comment];
      }
      resources = Object.assign({}, state.resources, {
        [resourceId]: resource,
      });
    }
  }
  return Object.assign({}, state, {
    resources,
  });
}

function setResourceAsLinked(state, resourceId, linked) {
  const resource = { ...state.resources[resourceId] };
  resource.Linked = linked;
  const resources = Object.assign({}, state.resources, {
    [resourceId]: resource,
  });
  return Object.assign({}, state, {
    resources,
  });
}

function setResourceAsStarred(state, resourceId, starred) {
  const resource = { ...state.resources[resourceId] };
  resource.Starred = true;
  resource.StarredTimestamp = starred ? Date.now() : null;
  const resources = Object.assign({}, state.resources, {
    [resourceId]: resource,
  });
  return Object.assign({}, state, {
    resources,
  });
}

// function setResourceReminder(state, id, reminderId) {
//   if (!state.resources[id]) {
//     return state;
//   }
//   const resource = { ...state.resources[id] };
//   resource.ReminderId = reminderId;
//   const resources = Object.assign({}, state.resources, {
//     [id]: resource,
//   });
//   return Object.assign({}, state, {
//     resources,
//   });
// }

const initialState = {
  resources: [],
  repositories: [],
};

export default function(state = initialState, action) {
  let resources = [];
  switch (action.type) {
    case MERGE_RESOURCES:
      return mergeResources(state, action.resources);
    case ADD_LINKEDITEMS_SUCCESS:
    case LOAD_LINKEDITEMS_SUCCESS:
      resources = Object.values(action.linkedItems)
        .map(linkedItem => linkedItem.Resource)
        .reduce((object, resource) => {
          object[resource.Id] = resource;
          return object;
        }, {});
      return mergeResources(state, resources);
    case RESOURCES_MARK_COMMENTS_AS_READ:
      return setResourceCommentAsRead(state, action.spaceId, action.resourceId);
    case ADD_COMMENT:
      return setCommentTotal(
        state,
        action.spaceId,
        action.resourceId,
        action.isUserComment
      );
    case ENTITIES_RESOURCE_LINKED:
      return setResourceAsLinked(state, action.resourceId, action.linked);
    case RESOURCE_STAR_ADDED:
      return setResourceAsStarred(state, action.id, action.starred);
    // case REMINDERS_ADDED:
    //   return setResourceReminder(
    //     state,
    //     action.reminder.TargetId,
    //     action.reminder.Id
    //   );
    // case REMINDER_REMOVED:
    //   return setResourceReminder(state, action.targetId, null);
    default:
      return state;
  }
}
