import axios from 'axios';
import { REMINDERS } from 'DataLayer/endpoints';

export function remove(id) {
  return axios.delete(`${REMINDERS}/${id}`);
}

export function clear() {
  return axios.delete(`${REMINDERS}/clear`);
}
