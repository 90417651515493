import React from 'react';
import PropTypes from 'prop-types';

import Avatar from './Avatar';

function CollaboratorAvatar({ collaborator, ...props }) {
  return (
    <Avatar
      avatarUrl={collaborator.AvatarUrl}
      color={collaborator.Color}
      email={collaborator.Email}
      firstName={collaborator.FirstName}
      lastName={collaborator.LastName}
      presence={collaborator.Presence}
      disabled={!collaborator.Email}
      {...props}
    />
  );
}

CollaboratorAvatar.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorAvatar;
