import { getMeta, getSingle } from 'DataLayer/Repositories/get';

import { setRepositoryRequestInProgress } from '../Requests/setRequestInProgress';

export const LOAD_REPOSITORY_SUCCESS = 'REPOSITORY/LOADED';

export const loadRepository = id => dispatch =>
  getSingle(id).then(repository => {
    dispatch({
      type: LOAD_REPOSITORY_SUCCESS,
      repository,
    });
  });

export const loadRepositoryMeta = id => dispatch => {
  dispatch(setRepositoryRequestInProgress(id, true));
  return getMeta(id).then(
    repository => {
      dispatch({
        type: LOAD_REPOSITORY_SUCCESS,
        repository,
      });
      dispatch(setRepositoryRequestInProgress(id, false));
    },
    () => {
      dispatch(setRepositoryRequestInProgress(id, false));
    }
  );
};

export default loadRepository;
