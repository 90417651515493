import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';

class LastActivity extends React.PureComponent {
  render() {
    const { date } = this.props;
    {
      const dayDifference = differenceInCalendarDays(
        format(new Date()),
        format(date)
      );

      if (dayDifference < 1) {
        return (
          <FormattedTime
            value={date}
            updateInterval={false}
            minute="2-digit"
            hour="2-digit"
          />
        );
      } else if (dayDifference < 7) {
        return (
          <>
            <FormattedDate value={date} weekday="short" />
            {/* eslint-disable react/jsx-no-literals */}
            &nbsp;@&nbsp;
            {/* eslint-enable react/jsx-no-literals */}
            <FormattedTime
              value={date}
              updateInterval={false}
              minute="2-digit"
              hour="2-digit"
            />
          </>
        );
      }

      return (
        <>
          <FormattedDate
            value={date}
            year="2-digit"
            month="2-digit"
            day="2-digit"
          />
          {/* eslint-disable react/jsx-no-literals */}
          &nbsp;@&nbsp;
          {/* eslint-enable react/jsx-no-literals */}
          <FormattedTime
            value={date}
            updateInterval={false}
            minute="2-digit"
            hour="2-digit"
          />
        </>
      );
    }
  }
}

LastActivity.propTypes = {
  date: PropTypes.number.isRequired,
};

export default LastActivity;
