import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';

import { getWorkspaceById, isWorkspaceFree } from 'store/Workspaces/selectors';
import { FormattedMessage } from 'react-intl';
import {
  getWorkspacePlanLink,
  getWorkspaceNotificationSettingsLink,
  getWorkspaceUpgradeLink,
  getWorkspaceMembersLink,
  getWorkspaceGeneralLink,
  getWorkspaceIdLink,
  getWorkspaceBackupLink,
} from 'pages';
import {
  showLeaveWorkspaceModal,
  showAddWorkspaceMemberModal,
} from 'store/Modals/actions';
import ShowIfNotAppStore from 'Components/Electron/ShowIfNotAppStore';
import WeTeamIcon, { FolderIcon } from 'Components/Icons/WeTeamIcon';
import { LogoutIcon } from 'Components/Icons/WeTeamIcon';
import { AddUserIcon } from 'Components/Icons/WeTeamIcon';
import { ThreeUserIcon } from 'Components/Icons/WeTeamIcon';
import { NotificationIcon } from 'Components/Icons/WeTeamIcon';
import { StarIcon } from 'Components/Icons/WeTeamIcon';
import TeamspaceIdIcon from 'Components/Icons/FontAwesome/Light/TeamspaceIdIcon';
import {
  DownloadIcon,
  ChartIcon,
  CategoryIcon,
} from 'Components/Icons/WeTeamIcon';
import { isDesktopApp } from 'config/config';
import { workspaceArchived } from 'store/UI';
import { getIsWorkspaceArchived } from 'store/UI/selectors';

import MarkWorkspaceAsUnreadOption from 'Components/Pages/MarkWorkspaceAsUnreadOption';

class WorkspaceMenuItems extends React.Component {
  onLeaveClick = () => {
    this.props.onClick();
    this.props.onShowLeaveWorkspaceModal(this.props.workspace);
  };

  onWorkspaceArchived = () => {
    this.props.onClick();
    this.props.onWorkspaceArchived(
      this.props.workspace.Id,
      !this.props.workspace.Archived
    );
  };

  onAddMembersClick = () => {
    this.props.onClick();
    this.props.onAddMembers(this.props.workspace.Id);
  };

  render() {
    const {
      workspace,
      showIcons,
      showArchive,
      showMarkAsRead,
      isWorkspaceArchived,
      onClick,
    } = this.props;

    if (!workspace.Id) {
      return null;
    }

    return (
      <React.Fragment>
        {workspace.IsOwner && (
          <Link
            className="item"
            to={getWorkspaceGeneralLink(workspace.Id)}
            activeClassName="active"
            onClick={onClick}
          >
            {showIcons && <WeTeamIcon Icon={CategoryIcon} link={false} />}
            <FormattedMessage id="WorkspaceMenuItems.General" />
          </Link>
        )}
        {workspace.IsOwner && (
          <Link
            className="item"
            to={getWorkspaceIdLink(workspace.Id)}
            activeClassName="active"
            onClick={onClick}
          >
            {showIcons && <TeamspaceIdIcon />}
            <FormattedMessage id="WorkspaceMenuItems.TeamspaceId" />
          </Link>
        )}
        {workspace.IsOwner && (
          <ShowIfNotAppStore
            all={
              <Link
                className="item"
                to={getWorkspacePlanLink(workspace.Id)}
                activeClassName="active"
                onClick={onClick}
              >
                {showIcons && <WeTeamIcon Icon={ChartIcon} link={false} />}
                <FormattedMessage id="WorkspaceMenuItems.Plan" />
              </Link>
            }
          />
        )}
        {workspace.IsOwner && (
          <ShowIfNotAppStore
            all={
              <Link
                className="item icon primary"
                to={getWorkspaceUpgradeLink(workspace.Id)}
                activeClassName="active"
                onClick={onClick}
              >
                {showIcons && <WeTeamIcon Icon={StarIcon} link={false} />}
                <FormattedMessage id="WorkspaceMenuItems.Upgrade" />
              </Link>
            }
          />
        )}
        {!(isDesktopApp() && this.props.isFreeWorkspace) && (
          <Link
            className="item icon primary"
            activeClassName="active"
            onClick={onClick}
            to={getWorkspaceBackupLink(workspace.Id)}
          >
            {showIcons && <WeTeamIcon Icon={DownloadIcon} link={false} />}
            <FormattedMessage id="WorkspaceMenuItems.Offline" />
          </Link>
        )}
        <Link
          className="item"
          to={getWorkspaceMembersLink(workspace.Id)}
          activeClassName="active"
          onClick={onClick}
        >
          {showIcons && <WeTeamIcon Icon={ThreeUserIcon} link={false} />}
          <FormattedMessage id="WorkspaceMenuItems.Members" />
        </Link>
        {workspace.ACL?.InviteMembers && (
          <Link
            className="item"
            onClick={this.onAddMembersClick}
            activeClassName="active"
          >
            {showIcons && <WeTeamIcon Icon={AddUserIcon} link={false} />}
            <FormattedMessage id="WorkspaceMenuItems.AddMembers" />
          </Link>
        )}
        <Link
          className="item"
          to={getWorkspaceNotificationSettingsLink(workspace.Id)}
          activeClassName="active"
          onClick={onClick}
        >
          {showIcons && <WeTeamIcon Icon={NotificationIcon} link={false} />}
          <FormattedMessage id="WorkspaceMenuItems.SpaceNotifications" />
        </Link>
        {!workspace.IsOwner && workspace.ACL.Leave && (
          <Link
            className="item icon primary"
            activeClassName="active"
            onClick={this.onLeaveClick}
          >
            {showIcons && <WeTeamIcon Icon={LogoutIcon} link={false} />}
            <FormattedMessage id="WorkspaceMenuItems.Leave" />
          </Link>
        )}
        {showArchive && (
          <Link
            className="item icon primary"
            activeClassName="active"
            onClick={this.onWorkspaceArchived}
          >
            {showIcons && <WeTeamIcon Icon={FolderIcon} link={false} />}
            <FormattedMessage
              id={
                isWorkspaceArchived
                  ? 'WorkspaceMenuItems.Unarchive'
                  : 'WorkspaceMenuItems.Archive'
              }
            />
          </Link>
        )}
        {showMarkAsRead && (
          <MarkWorkspaceAsUnreadOption
            showIcons={showIcons}
            workspaceId={workspace.Id}
          />
        )}
      </React.Fragment>
    );
  }
}

WorkspaceMenuItems.propTypes = {
  workspace: PropTypes.object.isRequired,
  isFreeWorkspace: PropTypes.bool.isRequired,
  onAddMembers: PropTypes.func.isRequired,
  onShowLeaveWorkspaceModal: PropTypes.func.isRequired,
  onWorkspaceArchived: PropTypes.func.isRequired,
  showIcons: PropTypes.bool,
  showArchive: PropTypes.bool,
  showMarkAsRead: PropTypes.bool,
  isWorkspaceArchived: PropTypes.bool,
  onClick: PropTypes.func,
};

WorkspaceMenuItems.defaultProps = {
  workspace: {},
  showIcons: false,
  onClick: () => {},
};

export default connect(
  (state, props) => ({
    workspace: getWorkspaceById(state, props.workspaceId),
    isFreeWorkspace: isWorkspaceFree(state, props.workspaceId),
    isWorkspaceArchived: getIsWorkspaceArchived(state, props.workspaceId),
  }),
  {
    onAddMembers: showAddWorkspaceMemberModal,
    onShowLeaveWorkspaceModal: showLeaveWorkspaceModal,
    onWorkspaceArchived: workspaceArchived,
  }
)(withRouter(WorkspaceMenuItems));
