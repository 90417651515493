import dateFnsFormat from 'date-fns/format';
import dateFnsDe from 'date-fns/locale/de';
import { supportedLocales } from 'translations/getMessageByLocale.js';

export function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, {
    locale: locale === supportedLocales.de ? dateFnsDe : null,
  });
}

export function getDayPickeri18nProps(intl) {
  return {
    months: [
      intl.formatMessage({ id: 'January' }),
      intl.formatMessage({ id: 'February' }),
      intl.formatMessage({ id: 'March' }),
      intl.formatMessage({ id: 'April' }),
      intl.formatMessage({ id: 'May' }),
      intl.formatMessage({ id: 'June' }),
      intl.formatMessage({ id: 'July' }),
      intl.formatMessage({ id: 'August' }),
      intl.formatMessage({ id: 'September' }),
      intl.formatMessage({ id: 'October' }),
      intl.formatMessage({ id: 'November' }),
      intl.formatMessage({ id: 'December' }),
    ],
    weekdaysLong: [
      intl.formatMessage({ id: 'Sunday' }),
      intl.formatMessage({ id: 'Monday' }),
      intl.formatMessage({ id: 'Tuesday' }),
      intl.formatMessage({ id: 'Wednesday' }),
      intl.formatMessage({ id: 'Thursday' }),
      intl.formatMessage({ id: 'Friday' }),
      intl.formatMessage({ id: 'Saturday' }),
    ],
    weekdaysShort: [
      intl.formatMessage({ id: 'Su' }),
      intl.formatMessage({ id: 'Mo' }),
      intl.formatMessage({ id: 'Tu' }),
      intl.formatMessage({ id: 'We' }),
      intl.formatMessage({ id: 'Th' }),
      intl.formatMessage({ id: 'Fr' }),
      intl.formatMessage({ id: 'Sa' }),
    ],
  };
}
