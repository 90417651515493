import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { COLORS } from 'Helpers/Colors';

import styles from './ColorPresets.module.scss';

const ColorPresets = ({ onColorSelected, selectedColor, className }) => {
  return (
    <div className={className}>
      {Object.values(COLORS).map(color => {
        const classes = classNames(styles.color, {
          [styles.selected]: selectedColor === color,
        });
        return (
          <div
            key={color}
            style={{
              backgroundColor: color,
            }}
            className={classes}
            onClick={() => {
              onColorSelected(color);
            }}
          />
        );
      })}
    </div>
  );
};

ColorPresets.propTypes = {
  onColorSelected: PropTypes.func.isRequired,
  selectedColor: PropTypes.string,
};

export default ColorPresets;
