import { createContext, useContext, useReducer } from 'react';
import { format } from 'date-fns';

import useAsyncFn from 'Hooks/useAsyncFn';
import { saveDatabase } from 'Components/Pages/WorkspaceBackup/saveDatabase';

const initialState = {
  backupWorkspaceId: null,
  backupFolder: null,
  appProgress: 0,
  dataProgress: 0,
  log: null,
};

const WorkspaceBackupContext = createContext();

const actions = {
  RESET: 'RESET',
  SET_BACKUP_WORKSPACE_ID: 'SET_BACKUP_WORKSPACE_ID',
  SET_BACKUP_FOLDER: 'SET_BACKUP_FOLDER',
  SET_APP_PROGRESS: 'SET_APP_PROGRESS',
  SET_DATA_PROGRESS: 'SET_DATA_PROGRESS',
  ADD_TO_LOG: 'ADD_TO_LOG',
};

const reducer = (prevState, action) => {
  switch (action.type) {
    case actions.RESET:
      return initialState;
    case actions.SET_BACKUP_WORKSPACE_ID:
      return {
        ...prevState,
        backupWorkspaceId: action.backupWorkspaceId,
      };
    case actions.SET_BACKUP_FOLDER:
      return {
        ...prevState,
        backupFolder: action.backupFolder,
      };
    case actions.SET_APP_PROGRESS:
      return {
        ...prevState,
        appProgress: action.appProgress,
      };
    case actions.SET_DATA_PROGRESS:
      return {
        ...prevState,
        dataProgress: action.dataProgress,
      };
    case actions.ADD_TO_LOG:
      return {
        ...prevState,
        log: action.item,
      };
    default:
      return prevState;
  }
};

export const WorkspaceBackupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [saveApplicationState, onSaveApplication, resetSaveApplicationState] =
    useAsyncFn(async () => {
      window.ipcRenderer?.on('app-download-progress', appDownloadProgress);
      await window.ipcRenderer.invoke(
        'downloadStandAloneApp',
        state.backupFolder
      );
      window.ipcRenderer?.removeListener(
        'app-download-progress',
        appDownloadProgress
      );
      return true;
    }, [state.backupFolder]);

  const appDownloadProgress = (event, appProgress) => {
    dispatch({ type: actions.SET_APP_PROGRESS, appProgress });
  };

  const onOpenBackup = () => {
    window.ipcRenderer.send('openBackupLocation', state.backupFolder);
  };

  const onStartNewBackup = () => {
    resetSaveApplicationState();
    resetSaveDatabaseState();
    resetBackupState();
    dispatch({ type: actions.RESET });
  };

  const setDataProgress = dataProgress => {
    dispatch({ type: actions.SET_DATA_PROGRESS, dataProgress });
  };

  // eslint-disable-next-line no-unused-vars
  const [saveDatabaseState, onSaveDatabase, resetSaveDatabaseState] =
    useAsyncFn(
      async workspaceId => {
        return await saveDatabase(
          state.backupFolder,
          workspaceId,
          setDataProgress,
          item => {
            dispatch({ type: actions.ADD_TO_LOG, item });
          }
        );
      },
      [state.backupFolder]
    );

  const [backupState, onStartBackupClick, resetBackupState] = useAsyncFn(
    async backupWorkspaceId => {
      dispatch({ type: actions.SET_BACKUP_WORKSPACE_ID, backupWorkspaceId });
      await window.ipcRenderer.invoke(
        'createBackupLocation',
        state.backupFolder
      );
      await Promise.all([
        onSaveDatabase(backupWorkspaceId),
        onSaveApplication(),
      ]);
      return true;
    },
    [
      state.backupFolder,
      onSaveApplication,
      onSaveDatabase,
      resetSaveApplicationState,
      resetSaveDatabaseState,
    ]
  );

  const onSelectBackupLocation = async workspaceName => {
    const result = await window.ipcRenderer.invoke('selectBackupLocation');
    if (result) {
      dispatch({
        type: actions.SET_BACKUP_FOLDER,
        backupFolder: `${result}/we-team-backup-${workspaceName}-${format(
          Date.now(),
          'YYYY-MM-DD-HH-mm-ss'
        )}`,
      });
    }
  };

  const value = {
    backupState,
    onSelectBackupLocation,
    backupFolder: state.backupFolder,
    onStartBackupClick,
    saveApplicationState,
    saveDatabaseState,
    dataProgress: state.dataProgress,
    appProgress: state.appProgress,
    onOpenBackup,
    onStartNewBackup,
    backupWorkspaceId: state.backupWorkspaceId,
    log: state.log,
  };

  return (
    <WorkspaceBackupContext.Provider value={value}>
      {children}
    </WorkspaceBackupContext.Provider>
  );
};

export const useWorkspaceBackupContext = () => {
  return useContext(WorkspaceBackupContext);
};
