import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { WORKSPACE_SORT_TYPE, workspaceSortChanged } from 'store/UI';
import Sorter from 'Components/AppNavigation/Components/Sorter';
import { getWorkspaceSortType } from 'store/UI/selectors';

function WorkspaceSorter({ onSort, sortType }) {
  let items = [
    {
      id: 1,
      onSort: () => onSort(WORKSPACE_SORT_TYPE.unread),
      text: <FormattedMessage id="WorkspaceSorter.Unread" />,
      active: sortType === WORKSPACE_SORT_TYPE.unread,
    },
    {
      id: 2,
      onSort: () => onSort(WORKSPACE_SORT_TYPE.custom),
      text: <FormattedMessage id="WorkspaceSorter.Custom" />,
      active: sortType === WORKSPACE_SORT_TYPE.custom,
    },
  ];
  return <Sorter whiteIcon items={items} />;
}

WorkspaceSorter.propTypes = {
  sortType: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default connect(
  (state, props) => ({
    sortType: getWorkspaceSortType(state),
  }),
  {
    onSort: workspaceSortChanged,
  }
)(WorkspaceSorter);
