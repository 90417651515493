export const WORKSPACE_MEMBERS_LOADED = 'MEMBERS/ADD';
export const WORKSPACE_MEMBERS_MERGE = 'MEMBERS/MERGE';
export const WORKSPACE_MEMBER_ADD = 'MEMBER/ADD';
export const WORKSPACE_MEMBERS_REMOVE = 'MEMBERS/REMOVE';
export const WORKSPACE_MEMBERS_CHANGE_TYPE = 'MEMBERS/CHANGE_TYPE';

export function addWorkspaceMembers(workspaceId, members) {
  return {
    type: WORKSPACE_MEMBERS_LOADED,
    members,
    workspaceId,
  };
}

export function mergeWorkspaceMembers(workspaceId, members) {
  return {
    type: WORKSPACE_MEMBERS_MERGE,
    members,
    workspaceId,
  };
}

export function addWorkspaceMember(workspaceId, member) {
  return {
    type: WORKSPACE_MEMBER_ADD,
    member,
    workspaceId,
  };
}

export function removeWorkspaceMember(workspaceId, memberId) {
  return {
    type: WORKSPACE_MEMBERS_REMOVE,
    memberId,
    workspaceId,
  };
}

export function changeWorkspaceMemberType(memberId, memberEmail, memberType) {
  return {
    type: WORKSPACE_MEMBERS_CHANGE_TYPE,
    memberId,
    memberEmail,
    memberType,
  };
}
