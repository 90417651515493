import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getRecentlyAddedWorkspaces,
  getRecentlyAddedWorkspaceInvitationMessages,
} from 'store/Workspaces/selectors';

import RecentlyAddedWorkspace from './RecentlyAddedWorkspace';
import loadRecentlyAddedWorkspaces from 'store/Workspaces/loadRecentlyAddedWorkspaces';

const RecentlyAddedWorkspacesContainer = ({
  loadRecentlyAddedWorkspaces,
  workspaces,
  invitationMessages,
}) => {
  useEffect(
    () => {
      loadRecentlyAddedWorkspaces();
    },
    [loadRecentlyAddedWorkspaces]
  );
  return (
    <>
      {workspaces.map(workspace => (
        <RecentlyAddedWorkspace
          key={workspace.Id}
          workspace={workspace}
          invitationMessage={invitationMessages[workspace.Id]}
        />
      ))}
    </>
  );
};

RecentlyAddedWorkspacesContainer.propTypes = {
  workspaces: PropTypes.array.isRequired,
  invitationMessages: PropTypes.object.isRequired,
  loadRecentlyAddedWorkspaces: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    workspaces: getRecentlyAddedWorkspaces(state),
    invitationMessages: getRecentlyAddedWorkspaceInvitationMessages(state),
  }),
  {
    loadRecentlyAddedWorkspaces: loadRecentlyAddedWorkspaces,
  }
)(RecentlyAddedWorkspacesContainer);
