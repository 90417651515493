import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StarredMessage from './StarredMessage';
import starNotification from 'store/Notifications/starNotification';
import Notification from 'Components/Notification/Notification';

function StarredNotification({
  notification,
  showWorkspaceName,
  showAvatar,
  onRemoveStar,
}) {
  return (
    <StarredMessage onRemoveStar={() => onRemoveStar(notification.Id, false)}>
      <Notification
        notification={notification}
        showWorkspaceName={showWorkspaceName}
        showAvatar={showAvatar}
      />
    </StarredMessage>
  );
}

StarredNotification.propTypes = {
  notification: PropTypes.object.isRequired,
  showWorkspaceName: PropTypes.bool,
  showAvatar: PropTypes.bool,
  onRemoveStar: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    onRemoveStar: starNotification,
  }
)(StarredNotification);
