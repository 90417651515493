import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { windowFocussed } from 'store/Window/index';
import useWindowFocus from 'Hooks/useWindowFocus';
import { useEffect } from 'react';

const WindowFocus = ({ onWindowFocussed }) => {
  const focussed = useWindowFocus();
  useEffect(
    () => {
      onWindowFocussed(focussed);
    },
    [focussed, onWindowFocussed]
  );
  return null;
};

WindowFocus.propTypes = {
  onWindowFocussed: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    onWindowFocussed: windowFocussed,
  }
)(WindowFocus);
