import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { showCreateMeetingModal } from 'store/Modals/actions';

import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { CameraIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';

const StartMeetingButton = ({ workspaceId, channelId }) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(showCreateMeetingModal(workspaceId, channelId));
  }, [channelId, dispatch, workspaceId]);

  return (
    <DisableInBackupMode>
      <div className="flex center justify-content-center my-2">
        <Button
          size="small"
          compact
          primary
          className="flex center"
          basic
          icon
          onClick={onClick}
          data-testid="meeting-button"
        >
          <WeTeamIcon
            color="green"
            Icon={CameraIcon}
            link={false}
            className="mr-2"
          />
          <FormattedMessage id="SelectMeetingMembersButton.Tooltip" />
        </Button>
      </div>
    </DisableInBackupMode>
  );
};

StartMeetingButton.propTypes = {
  channelId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired,
};

export default StartMeetingButton;
