import axios from 'axios';
import { getUnverifiedUserRequestAllowedConfig } from 'DataLayer/endpoints';
import { getAssetManifestChecksumUrl } from 'config/config';

export function get() {
  const config = getUnverifiedUserRequestAllowedConfig();

  return axios
    .get(getAssetManifestChecksumUrl(), config)
    .then(response => response.data);
}
