import { convertFilesToObjects } from 'Helpers/Forms/Files/convertFilesToObjects';
import { normalize, schema } from 'normalizr';
import {
  showUploadFiles,
  showWorkspaceFolderUploadUnavailableModal,
} from 'store/Modals/actions';

const filesSchema = new schema.Entity('files', {}, { idAttribute: 'id' });

export const PENDING_FILES_SELECTED = 'UPLOAD/PENDING_FILES_SELECTED';

export const addPendingFiles = (
  files,
  resourceId,
  spaceId,
  containsFolder
) => dispatch => {
  /*
    Folder uploads in Work Spaces are not supported until
    https://otixodev.atlassian.net/browse/OT-1129
  */
  if (containsFolder && spaceId) {
    dispatch(showWorkspaceFolderUploadUnavailableModal());
    return;
  }

  const fileObjects = convertFilesToObjects(files);
  const normalizedFiles = normalize(fileObjects, [filesSchema]);

  dispatch({
    type: PENDING_FILES_SELECTED,
    fileIds: normalizedFiles.result,
    files: normalizedFiles.entities.files,
  });
  dispatch(showUploadFiles(resourceId, spaceId, fileObjects));
};
