import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { get } from 'DataLayer/MediaInfo/protected/get';

export default function useGetProtectedFile(
  url,
  options = { asBlob: true, cacheEnabled: false }
) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setData(null);
    setLoading(false);
    setError(null);
  }, [url]);

  const fetchFile = useCallback(() => {
    const setFile = async file => {
      if (options.asBlob) {
        setData(file);
      } else {
        setData(await file.text());
      }
      setLoading(false);
      setError(null);
    };
    const getFile = async () => {
      try {
        setData(null);
        setError(null);
        setLoading(true);
        const { cachedFile, fetchedPromise } = await get(url);
        const useCachedFile = cachedFile && options.cacheEnabled;
        if (useCachedFile) {
          setFile(cachedFile);
        }

        const networkFile = await fetchedPromise;
        /**
         * Only display the network file if there was no cached file available
         */
        if (!useCachedFile) {
          setFile(networkFile);
        }
      } catch (error) {
        setLoading(false);
        setError(true);
        Sentry.captureException(error);
      }
    };
    getFile();
  }, [options.asBlob, options.cacheEnabled, url]);

  return { data, loading, error, fetchFile };
}
