import { FormattedMessage } from 'react-intl';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Grid, Header, Responsive } from 'semantic-ui-react';
import Banner from './Banner';

import './AppsPromotionBanner.scss';

function AppsDownloadModal() {
  return (
    <Grid stackable columns={2}>
      <Grid.Column textAlign="center">
        <Header as="h1" className="letter-spacing-wide light-text">
          <FormattedMessage id="AppsPromotionBanner.desktop" />
        </Header>
        <Grid columns={2}>
          <Grid.Column className="app-promotion-download-card">
            <a
              href="https://apps.apple.com/us/app/mac/id1447606451?ls=1&mt=12"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon name="apple" size="massive" color="white" />
              <div className="py-2" />
              <Button primary>
                <FormattedMessage id="AppsPromotionBanner.download-macos" />
              </Button>
              <div className="mt-1">
                <small>
                  <FormattedMessage id="AppsPromotionBanner.macos-1010-yosemite-or-higher" />
                </small>
              </div>
            </a>
          </Grid.Column>
          <Grid.Column className="app-promotion-download-card">
            <a
              href="https://www.microsoft.com/store/apps/9wzdncrdh027?ocid=badge"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon name="windows" size="massive" color="white" />
              <div className="py-2" />
              <Button primary>
                <FormattedMessage id="AppsPromotionBanner.download-windows" />
              </Button>
              <div className="mt-1">
                <small>
                  <FormattedMessage id="AppsPromotionBanner.windows-7-or-higher" />
                </small>
              </div>
            </a>
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column textAlign="center">
        <Header as="h1" className="letter-spacing-wide light-text">
          <FormattedMessage id="AppsPromotionBanner.mobile" />
        </Header>
        <Grid columns={2}>
          <Grid.Column className="app-promotion-download-card">
            <a
              href="https://play.google.com/store/apps/details?id=com.otixo.workspaces"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon name="android" size="massive" color="white" />
              <div className="py-2" />
              <Button primary>
                <FormattedMessage id="AppsPromotionBanner.download-android" />
              </Button>
            </a>
          </Grid.Column>
          <Grid.Column className="app-promotion-download-card">
            <a
              href="https://itunes.apple.com/us/app/otixo-spaces/id1447606451"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon name="app store ios" size="massive" color="white" />
              <div className="py-2" />
              <Button primary>
                <FormattedMessage id="AppsPromotionBanner.download-ios" />
              </Button>
            </a>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  );
}

function AppsPromotionBanner({ onClose }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Responsive {...Responsive.onlyComputer}>
      <Banner
        onClose={() => {
          onClose();
        }}
        showExtended={showModal}
        onHideExtended={() => {
          setShowModal(false);
        }}
        extended={<AppsDownloadModal />}
      >
        <span className="mr-2">
          <FormattedMessage id="AppsPromotionBanner.download-our-new-desktop--mobile-apps" />
        </span>
        <Icon name="windows" size="large" /> <Icon name="apple" size="large" />
        <Icon name="android" size="large" />
        <Button
          icon
          primary
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          <FormattedMessage id="AppsPromotionBanner.download" />
          <Icon name={showModal ? 'chevron up' : 'chevron down'} />
        </Button>
      </Banner>
    </Responsive>
  );
}

AppsPromotionBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AppsPromotionBanner;
