import { getStats } from 'DataLayer/Notifications/get';
import { getConversationStats } from 'DataLayer/Notifications/get';
import { setStats } from './actions';

export const loadStats = workspaceId => dispatch =>
  getStats(workspaceId).then(stats => {
    dispatch(setStats(stats));
  });

export const loadConversationStats = () => dispatch =>
  getConversationStats().then(stats => {
    dispatch(setStats(stats));
  });
