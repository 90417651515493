import { Component } from 'react';

import logout from 'Helpers/Logout/index.js';

class Logout extends Component {
  componentDidMount() {
    logout();
  }

  render() {
    return null;
  }
}

export default Logout;
