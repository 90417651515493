import get from 'lodash/get';
import processResource from '../Resources/process';

function processGrant(item) {
  const grant = get(item, 'ACE.Grant');

  if (grant) {
    item.Locked = grant.indexOf('write') === -1;
  }
  // #6449: Space Owner can delete all files
  // item.CanWrite = item.IsOwner;
}

function processOwner(item) {
  if (item.Owner) {
    item.Owner.Name = `${item.Owner.FirstName} ${item.Owner.LastName}`;
    item.OwnerName = item.Owner.Name;
  } else {
    item.Owner = {};
  }
}

function single(item) {
  processGrant(item);

  item.Resource = processResource.single(item.Resource);
  item.NameLowerCase = item.Name?.toLowerCase() || '';
  if (item.Name !== item.Resource.Name) {
    item.OriginalName = item.Resource.Name;
  }

  item.selected = false;

  processOwner(item);

  return item;
}

function all(items) {
  if (!items) {
    return [];
  }

  return items.filter(item => item.Resource).map(item => single(item));
}

export default {
  single,
  all,
};
