import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CollaboratorCardPopupContainer from '../Collaborators/CollaboratorCardPopupContainer';

function ChatItemName({ email, spaceId, name, isSystem }) {
  if (isSystem) {
    // TODO: This will need to change when we introduce other types of system bots
    return 'Reminder Bot';
  }
  return (
    <CollaboratorCardPopupContainer email={email} spaceId={spaceId}>
      {name}
    </CollaboratorCardPopupContainer>
  );
}

ChatItemName.propTypes = {
  email: PropTypes.string,
  spaceId: PropTypes.string,
  name: PropTypes.string,
  isSystem: PropTypes.bool,
};

export default memo(ChatItemName);
