import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadStarred } from 'store/Starred/loadStarred';
import { getStarredByWorkspaceId } from 'store/Starred/selectors';
import StarButton from './StarButton';

function StarButtonForWorkspaces({ isActive, onClick, stars, onLoadStarred }) {
  useEffect(
    () => {
      onLoadStarred();
    },
    [onLoadStarred]
  );

  const onButtonClick = event => {
    onLoadStarred();
    onClick(event);
  };

  return (
    <StarButton
      isActive={isActive}
      onClick={onButtonClick}
      numberOfStars={stars.length}
    />
  );
}

StarButtonForWorkspaces.propTypes = {
  stars: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onLoadStarred: PropTypes.func.isRequired,
};

export default connect(
  (state, props) => ({
    stars: getStarredByWorkspaceId(state, props.workspaceId),
  }),
  {
    onLoadStarred: loadStarred,
  }
)(StarButtonForWorkspaces);
