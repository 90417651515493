import axios from 'axios';
import { CHANNEL, COMMENTS } from 'DataLayer/endpoints';
import {
  getCommentsByResourceId as getFromDb,
  saveMany as saveToDb,
} from 'DataLayer/database/comments';
import process from './process';

export async function getFromCache(spaceId, resourceId) {
  return await getFromDb(resourceId);
}

export async function getFromNetwork(spaceId, resourceId) {
  const { data } = await axios.get(
    `${CHANNEL}/${spaceId}/${COMMENTS}/${resourceId}`
  );

  if (!data.Comments) {
    return [];
  }
  const finalComments = process.all(data.Comments.Items, spaceId, resourceId);

  saveToDb(finalComments, resourceId);
  return finalComments;
}

/**
 * Always look in the cache for our data.
 * Trigger a network response in the background
 * If no cached data, return network response data
 * https://web.dev/learn/pwa/serving/#stale-while-revalidate
 */
export async function getComments(spaceId, resourceId) {
  const cachedComments = await getFromCache(spaceId, resourceId);
  const fetchedPromise = getFromNetwork(spaceId, resourceId);
  return { cachedComments, fetchedPromise };
}
