import React from 'react';
import Linkify from 'react-linkify';
import { isDesktopApp } from 'config/config';

/*
  In the desktop app, links are opened in a child electron window.
  Instead, we would like them to open in the default OS browser.
*/
const LinkifyWrapper = ({ propeties, ...props }) => {
  return (
    <Linkify
      properties={{
        target: '_blank',
        onClick: event => {
          if (isDesktopApp()) {
            event.preventDefault();
            window.ipcRenderer?.send(
              'open-in-external-browser',
              event.target.href
            );
          }
        },
        ...propeties,
      }}
      {...props}
    />
  );
};

export default LinkifyWrapper;
